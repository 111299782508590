/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectUserInfoFullName,
  selectUserNPS,
} from '../../modules/loggedUserInfo/selectors';
import { NPS_MODAL_TRANSLATIONS } from '../../const/translations';
import { NetPromoterScoreForm } from './NetPromoterScoreForm';
import Styles from './NetPromoterScoreModal.module.css';

const { APPEAL } = NPS_MODAL_TRANSLATIONS;

const propTypes = {
  handleClose: PropTypes.func,
};

export const NetPromoterScoreModal = React.memo(({ handleClose }) => {
  const userFirstName = useSelector(selectUserInfoFullName).split(' ')[0];
  const NPS = useSelector(selectUserNPS);

  const { id, title, html } = NPS;
  return (
    <>
      <h3 className={Styles.textTitle}>{title}</h3>
      <div>
        <div className={Styles.textDefault}>
          {APPEAL.replace('{{USERNAME}}', userFirstName)}
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <NetPromoterScoreForm id={id} handleClose={handleClose} />
    </>
  );
});

NetPromoterScoreModal.propTypes = propTypes;
