import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { MODAL_TYPES } from 'const/ModalContainer';
import { openModal } from 'modules/ModalContainer/actions';
import { DATE_FORMAT } from 'const';
import {
  selectUserPreValidateLimit,
  selectUserEmail,
} from 'modules/loggedUserInfo/selectors';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import moment from 'moment';
import { useInvoiceActionsData } from '../useInvoiceActionsData';

const {
  ERRORS: { CANNOT_VALIDATE_ERROR },
} = INVOICE_SEARCH_TRANSLATIONS;

const openCannotValidateErrorModal = () =>
  Modal.info({
    icon: false,
    content: (
      <div className={Styles.errorModalText}>{CANNOT_VALIDATE_ERROR}</div>
    ),
    className: Styles.infoModal,
  });

export const useValidateActionModal = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(selectUserEmail);

  const modalProps = useInvoiceActionsData();
  const preValidateLimit = useSelector(selectUserPreValidateLimit);

  return useCallback(() => {
    const {
      isPayableInvoice,
      isValidated,
      canPrevalidate,
      paidOutside,
      documentId,
      preValidated,
      dueDate,
      canValidate,
      amountWithVat,
      date,
      preValidatedBy,
    } = modalProps;

    const isActual = moment(date)
      .add(3, 'y')
      .isAfter(moment());

    if (!isPayableInvoice || !isActual) {
      return null;
    }

    if (
      isValidated ||
      (preValidated && canPrevalidate && preValidatedBy !== currentUserEmail)
    ) {
      dispatch(
        openModal({
          type: MODAL_TYPES.invoiceSearchInfoModal,
          modalProps: {
            ...modalProps,
            isValidateAction: true,
          },
        })
      );

      return null;
    }

    if (canValidate || (canPrevalidate && preValidateLimit > amountWithVat)) {
      dispatch(
        openModal({
          type: MODAL_TYPES.approveModal,
          modalProps: {
            documentId,
            defaultDate: (moment().isBefore(dueDate)
              ? moment(dueDate)
              : moment()
            ).format(DATE_FORMAT),
            preValidated,
            paidOutside,
          },
        })
      );

      return null;
    }

    if (canPrevalidate) {
      dispatch(
        openModal({
          type: MODAL_TYPES.invoiceSearchInfoModal,
          modalProps,
        })
      );

      return null;
    }

    openCannotValidateErrorModal();

    return null;
  }, [currentUserEmail, dispatch, modalProps, preValidateLimit]);
};
