import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import Styles from 'components/ui/Radio/Radio.module.css';

const RadioMain = ({ options, ...props }) => (
  <div>
    <div>
      <Radio.Group buttonStyle="solid" className={Styles.radioGroup} {...props}>
        {options.map(({ value, label }) => (
          <Radio.Button key={value} value={value}>
            {label || value}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  </div>
);

RadioMain.propTypes = {
  options: PropTypes.array.isRequired,
};

export { RadioMain as Radio };
