import { APP_MODULES } from 'const';
import { getUniqueSimpleArray } from 'modules/cart/helpers';

const {
  SEARCH_INVOICES,
  READONLY_INVOICE,
  APPROVE_INVOICE,
  APPROVE_AND_PAY_INVOICE,
  PRE_VALIDATE_INVOICE,
} = APP_MODULES;

export const validateUserModules = (currentModules, prevFormState, userId) => {
  let result = [...currentModules];
  const modulesArray = [APP_MODULES.DASHBOARD_HR];

  let prevModules;
  if (userId) {
    prevModules = prevFormState[userId] && prevFormState[userId].tableModules;
  } else {
    prevModules = prevFormState.tableModules;
  }

  /**
   * SEARCH_INVOICES access cannot be deselected without PAY accesses
   */
  if (
    prevModules.includes(SEARCH_INVOICES) &&
    !result.includes(SEARCH_INVOICES)
  ) {
    result = result.filter(
      (module) =>
        ![
          APPROVE_INVOICE,
          PRE_VALIDATE_INVOICE,
          READONLY_INVOICE,
          APPROVE_AND_PAY_INVOICE,
        ].includes(module)
    );
  }

  if (
    modulesArray.every((module) => prevModules.includes(module)) &&
    modulesArray.some((module) => !currentModules.includes(module))
  ) {
    return getUniqueSimpleArray(
      result.filter((module) => !modulesArray.includes(module))
    );
  }

  if (
    modulesArray.every((module) => !prevModules.includes(module)) &&
    modulesArray.some((module) => currentModules.includes(module))
  ) {
    return getUniqueSimpleArray([...result, ...modulesArray]);
  }

  return getUniqueSimpleArray(result);
};

export const verifyPayModulesDisabled = (userModules, currentModule) => {
  switch (currentModule) {
    case APP_MODULES.APPROVE_INVOICE:
      return (
        userModules?.includes(APP_MODULES.READONLY_INVOICE) ||
        userModules?.includes(APP_MODULES.PRE_VALIDATE_INVOICE)
      );
    case APP_MODULES.READONLY_INVOICE:
      return userModules?.includes(APP_MODULES.APPROVE_INVOICE);
    case APP_MODULES.PRE_VALIDATE_INVOICE:
      return userModules?.includes(APP_MODULES.APPROVE_INVOICE);

    default:
      return false;
  }
};
