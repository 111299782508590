import { createSelector } from 'reselect';
import { selectIsXPBCompany } from 'modules/loggedUserInfo/selectors';

export const selectCompanyUnaddedRewards = (state) =>
  state.getIn(['rewardsReducer', 'companyUnaddedRewards']);

export const selectCompanyRewardsStatus = (state) =>
  state.getIn(['rewardsReducer', 'status']);

const selectCompanyRewardsFamiliesRaw = (state) =>
  state.getIn(['rewardsReducer', 'companyRewardsFamilies']);

export const selectCompanyRewardsFamilies = createSelector(
  selectCompanyRewardsFamiliesRaw,
  (families) => families || null
);

export const selectFamilyData = (state) =>
  state.getIn(['rewardsReducer', 'familyData']);

export const selectChosenFamilyTabId = createSelector(
  selectFamilyData,
  (familyData) => familyData?.id
);

export const selectFamilyMessage = (state) =>
  state.getIn(['rewardsReducer', 'familyMessage']);

export const selectAvailableRewardsOptions = createSelector(
  selectCompanyUnaddedRewards,
  selectIsXPBCompany,
  (rewards, isXPB) => {
    if (rewards && rewards.length) {
      return rewards
        .filter((el) => (isXPB && el.tool === 'XPB') || !isXPB)
        .map(({ name, id, code }) => ({
          label: `${name} - ${code}`,
          value: id,
        }));
    }

    return [];
  }
);

export const selectFamilyRewardsMapped = createSelector(
  selectFamilyData,
  (familyData) => {
    if (familyData && familyData.companyRewards) {
      return familyData.companyRewards.map((rewardData) => ({
        ...rewardData,
        reward: {
          ...rewardData.reward,
          name: rewardData?.reward?.name || rewardData?.customName,
          type: rewardData?.reward?.type || rewardData?.customType,
        },
      }));
    }

    return [];
  }
);
