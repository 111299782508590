import { request } from 'utils/request';

export const getUploadHistory = (queryParams) =>
  request.get('/documents/history', { params: { ...queryParams, limit: 100 } });

export const uploadDocuments = (formData) =>
  request.post('/documents', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const downloadDocument = (fileId) =>
  request.get(`/documents/${fileId}`, {
    responseType: 'blob',
  });
