export const groupObjectsByProperty = (objectsArray, propertyName = 'id') =>
  objectsArray.reduce((acc, object) => {
    if (!object[propertyName]) {
      return acc;
    }
    acc[object[propertyName]] = { ...object };
    return acc;
  }, {});

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
