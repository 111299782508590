import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  componentFrom: PropTypes.node,
  componentTo: PropTypes.node,
};

export const Range = ({ componentFrom = null, componentTo = null }) => (
  <>
    {componentFrom}
    &nbsp;
    {'et'}
    &nbsp;
    {componentTo}
  </>
);

Range.propTypes = propTypes;
