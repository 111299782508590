import { takeLatest, call, put } from 'redux-saga/effects';
import {
  VOTE_NPS_REQUEST,
  voteNpsSuccess,
  voteNpsError,
} from 'modules/NPSModal/actions';
import { voteNetPromoterScore } from 'modules/NPSModal/services';

function* voteNPSWorker({ payload: { id, ...data } }) {
  try {
    yield call(voteNetPromoterScore, { data, id });
    yield put(voteNpsSuccess());
  } catch (error) {
    yield put(voteNpsError());
  }
}

export function* voteNPSMainSaga() {
  yield takeLatest(VOTE_NPS_REQUEST, voteNPSWorker);
}
