import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { TIME_TABLE_MODAL } from 'const/translations/SalaryEmployeePage';
import Text from 'antd/lib/typography/Text';
import Styles from './TimeTableModal.module.css';

const propTypes = {
  range: PropTypes.object,
  shouldRenderSeparator: PropTypes.bool,
};

export const TimeRange = ({ range, shouldRenderSeparator }) => (
  <>
    {shouldRenderSeparator && (
      <Text className={Styles.toNext}>{TIME_TABLE_MODAL.SEPARATOR}</Text>
    )}
    <div className={Styles.temeRangeWrapper}>
      <Input
        value={range.startTime}
        size="large"
        disabled
        className={Styles.input}
      />
      <Text className={Styles.separator}>-</Text>
      <Input
        value={range.endTime}
        size="large"
        disabled
        className={Styles.input}
      />
    </div>
  </>
);

TimeRange.propTypes = propTypes;
