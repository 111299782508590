import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Col, Typography } from 'antd';
import { withFormik } from 'formik';

import { omitEmptyKeys } from 'helpers/common';
import { Input, Button, Radio, Checkbox } from 'components/ui';
import {
  TimePickerFormik,
  SelectFormik,
  DatePickerFormik,
  InputNumberFormik,
} from 'components/adaptors';
import MainStyles from 'assets/styles/main.module.css';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { formikTypes } from 'const/propTypes';
import { validationSchema } from './WorkContract.yupValidationSchema';
import Styles from './WorkContract.module.css';
import { SharedNotification } from '../SharedNotification';

const {
  WORK_CONTRACT_FORM_TAB: { LABELS, PLACEHOLDERS, LISTS },
  GENERAL_LABELS,
  GENERAL_PLACEHOLDERS,
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  updateWorkContract: PropTypes.func,
  values: PropTypes.shape({
    contractByExco: PropTypes.bool,
    dpaeByExco: PropTypes.bool,
    employeePosition: PropTypes.string,
    datetimeHire: PropTypes.date,
    classification: PropTypes.string,
    levelCoefficient: PropTypes.string,
    remunerationGross: PropTypes.string,
    salaryGross: PropTypes.string,
    remunerationGrossDetails: PropTypes.string,
    typeContract: PropTypes.string,
    typeContractDetails: PropTypes.string,
    typeCDD: PropTypes.string,
    termCDD: PropTypes.date,
    workerReplaced: PropTypes.string,
  }),
  ...formikTypes,
};

/**
 * Added abstraction that consists of withFormik HOC
 * and props passed to it. Wraps component on export,
 * passing all formik functionality and props to it.
 */
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({ workContractData = {} }) => ({
    contractByExco: workContractData.contractByExco || false,
    dpaeByExco: workContractData.dpaeByExco || false,
    employeePosition: workContractData.employeePosition || '',
    datetimeHire: workContractData.datetimeHire || undefined,
    classification: workContractData.classification || undefined,
    levelCoefficient: workContractData.levelCoefficient || '',
    remunerationGross:
      workContractData.remunerationGross || GENERAL_LABELS.MONTHLY,
    salaryGross: workContractData.salaryGross || undefined,
    remunerationGrossDetails: workContractData.remunerationGrossDetails || '',
    typeContract: workContractData.typeContract || GENERAL_LABELS.OTHER,
    typeEmployment: workContractData.typeEmployment || GENERAL_LABELS.FULLTIME,
    employmentParttimeDetails: workContractData.employmentParttimeDetails || '',
    typeContractDetails: workContractData.typeContractDetails || '',
    typeCDD: workContractData.typeCDD || undefined,
    termCDD: workContractData.termCDD || '',
    workerReplaced: workContractData.workerReplaced || '',
  }),
  mapPropsToErrors: ({ workContractErrors = {} }) => workContractErrors,
});

const WorkContractTab = ({
  updateWorkContract,
  values,
  errors,
  handleChange,
  validateForm,
  setFieldValue,
  onNext,
  onPrev,
  setErrors,
  isTabValid,
  workContractData,
  resetData,
}) => {
  /**
   * Next two useEffect hooks imitate
   * componentWillUnmount lifecycle method behavior.
   */
  const valuesRef = useRef();
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);
  useEffect(
    () => () => {
      validateForm().then((foundErrors) => {
        const isValid = !Object.keys(foundErrors).length;
        updateWorkContract({
          values: {
            ...omitEmptyKeys(valuesRef.current),
            isValid,
          },
          workContractErrors: foundErrors,
          isValid,
        });
      });
    },
    [validateForm, updateWorkContract]
  );
  /**
   * Additional validation in case tab data has come from backend,
   * where only values and status are saved,
   */
  useEffect(() => {
    if (Object.values(workContractData).length && !isTabValid) {
      validateForm().then((foundErrors) => {
        setErrors(foundErrors);
      });
    }
  }, [values, isTabValid, setErrors, validateForm, workContractData]);

  return (
    <Form>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.WORK_CONTRACT_BY_EXCO}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              onChange={handleChange}
              name="contractByExco"
              checked={values.contractByExco}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.DPAE_FILLED_BY_EXCO}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              onChange={handleChange}
              name="dpaeByExco"
              checked={values.dpaeByExco}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.EMPLOYEE_POST}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.employeePosition ? 'error' : ''}
          >
            <Input
              className={Styles.formInputBig}
              placeholder={PLACEHOLDERS.POST_DESCRIPTION}
              onChange={handleChange}
              name="employeePosition"
              value={values.employeePosition}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.DATE_AND_TIME_OF_HIRING}
          </Typography.Paragraph>
          <Form.Item
            className={clsx(Styles.formItem, Styles.formItemPicker)}
            validateStatus={errors.datetimeHire ? 'error' : ''}
          >
            <DatePickerFormik
              className={Styles.formPicker}
              placeholder={GENERAL_PLACEHOLDERS.DATE_FORMAT}
              value={values.datetimeHire}
              onChange={setFieldValue}
              name="datetimeHire"
            />
          </Form.Item>
          <Form.Item
            className={clsx(Styles.formItem, Styles.formItemPicker)}
            validateStatus={errors.datetimeHire ? 'error' : ''}
          >
            <TimePickerFormik
              className={Styles.formPicker}
              placeholder={GENERAL_PLACEHOLDERS.TIME_FORMAT}
              value={values.datetimeHire}
              onChange={setFieldValue}
              name="datetimeHire"
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.CLASSIFICATION}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.classification ? 'error' : ''}
          >
            <SelectFormik
              className={Styles.formSelect}
              placeholder={GENERAL_LABELS.CHOOSE}
              onChange={setFieldValue}
              name="classification"
              dropdownMatchSelectWidth={false}
              value={values.classification}
              options={LISTS.CLASSIFICATION}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.LEVEL_AND_COEFFICIENT}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Input
              className={Styles.formInput}
              placeholder={GENERAL_PLACEHOLDERS.ENTER_INFO}
              value={values.levelCoefficient}
              onChange={handleChange}
              name="levelCoefficient"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className={MainStyles.row}>
        <Col span={24} className={clsx(MainStyles.col, Styles.colTop)}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.REMUNERATION_BRUTE}
          </Typography.Paragraph>
          <div className={Styles.formItemsColumn}>
            <Form.Item className={Styles.formItemRadio}>
              <Radio
                role="radiogroup"
                name="remunerationGross"
                onChange={handleChange}
                defaultValue={values.remunerationGross}
                options={LISTS.REMUNERATION_GROSS}
              />
            </Form.Item>
            <div className={Styles.formItemsRow}>
              <Form.Item
                className={Styles.formItem}
                validateStatus={errors.salaryGross ? 'error' : ''}
              >
                <InputNumberFormik
                  placeholder={PLACEHOLDERS.SALARY_BRUTE}
                  onChange={setFieldValue}
                  value={values.salaryGross}
                  name="salaryGross"
                />
              </Form.Item>
              {values.remunerationGross === GENERAL_LABELS.OTHER && (
                <Form.Item
                  className={clsx(
                    Styles.formItem,
                    Styles.formItemDescribe,
                    Styles.formItemDescribeLeft
                  )}
                  validateStatus={
                    errors.remunerationGrossDetails ? 'error' : ''
                  }
                >
                  <Input
                    className={clsx(Styles.formInput, Styles.formInputDescribe)}
                    placeholder={GENERAL_PLACEHOLDERS.DESCRIBE_OTHER}
                    onChange={handleChange}
                    value={values.remunerationGrossDetails}
                    name="remunerationGrossDetails"
                  />
                </Form.Item>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className={MainStyles.row}>
        <Col span={24} className={clsx(MainStyles.col, Styles.colNoMargin)}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.EMPLOYMENT_TYPE}
          </Typography.Paragraph>
          <div className={Styles.formItemsColumn}>
            <Form.Item className={Styles.formItemRadio}>
              <Radio
                role="radiogroup"
                name="typeEmployment"
                onChange={handleChange}
                value={values.typeEmployment}
                options={LISTS.EMPLOYMENT_TYPE}
              />
            </Form.Item>
          </div>
        </Col>
        {values.typeEmployment === GENERAL_LABELS.PARTTIME && (
          <Col span={24} className={clsx(MainStyles.col)}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.EMPLOYMENT_PARTTIME_DETAILS}
            </Typography.Paragraph>
            <Form.Item
              className={clsx(Styles.formItem, Styles.formItemDescribe)}
              validateStatus={errors.employmentParttimeDetails ? 'error' : ''}
            >
              <Input
                className={clsx(Styles.formInput, Styles.formInputDescribe)}
                placeholder={PLACEHOLDERS.EMPLOYMENT_PARTTIME_DETAILS}
                onChange={handleChange}
                value={values.employmentParttimeDetails}
                name="employmentParttimeDetails"
              />
            </Form.Item>
          </Col>
        )}
        <Col span={24} className={clsx(MainStyles.col, Styles.colTop)}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.CONTRACT_TYPE}
          </Typography.Paragraph>
          <div className={Styles.formItemsColumn}>
            <Form.Item className={Styles.formItemRadio}>
              <Radio
                role="radiogroup"
                name="typeContract"
                onChange={handleChange}
                value={values.typeContract}
                options={LISTS.CONTRACT_TYPE}
              />
            </Form.Item>
            {values.typeContract === GENERAL_LABELS.OTHER && (
              <Form.Item
                className={clsx(Styles.formItem, Styles.formItemDescribe)}
                validateStatus={errors.typeContractDetails ? 'error' : ''}
              >
                <Input
                  className={clsx(Styles.formInput, Styles.formInputDescribe)}
                  placeholder={GENERAL_PLACEHOLDERS.DESCRIBE_OTHER}
                  onChange={handleChange}
                  value={values.typeContractDetails}
                  name="typeContractDetails"
                />
              </Form.Item>
            )}
          </div>
        </Col>
        {values.typeContract === LABELS.CONTRACT_TYPE_CDD && (
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.TYPE_CDD}
            </Typography.Paragraph>
            <Form.Item
              className={Styles.formItem}
              validateStatus={errors.typeCDD ? 'error' : ''}
            >
              <SelectFormik
                className={Styles.formSelect}
                placeholder={GENERAL_LABELS.CHOOSE}
                onChange={setFieldValue}
                name="typeCDD"
                value={values.typeCDD}
                options={LISTS.TYPE_CDD}
              />
            </Form.Item>
          </Col>
        )}
        {values.typeContract === LABELS.CONTRACT_TYPE_CDD &&
          Boolean(values.typeCDD) && (
            <Col span={24} className={MainStyles.col}>
              <Typography.Paragraph
                className={clsx(MainStyles.formLabel, Styles.formLabel)}
              >
                {LABELS.TERM_CDD}
              </Typography.Paragraph>
              <Form.Item
                className={Styles.formItem}
                validateStatus={errors.termCDD ? 'error' : ''}
              >
                <DatePickerFormik
                  className={Styles.formPicker}
                  placeholder={GENERAL_PLACEHOLDERS.DATE_FORMAT}
                  value={values.termCDD}
                  onChange={setFieldValue}
                  name="termCDD"
                />
              </Form.Item>
            </Col>
          )}
        {values.typeContract === LABELS.CONTRACT_TYPE_CDD &&
          values.typeCDD === LABELS.WORKER_REPLACE && (
            <Col span={24} className={MainStyles.col}>
              <Typography.Paragraph
                className={clsx(MainStyles.formLabel, Styles.formLabel)}
              >
                {LABELS.REPLACED_WORKER_NAME}
              </Typography.Paragraph>
              <Form.Item
                className={Styles.formItem}
                validateStatus={errors.workerReplaced ? 'error' : ''}
              >
                <Input
                  className={clsx(Styles.formInput, Styles.formInputDescribe)}
                  placeholder={PLACEHOLDERS.REPLACED_WORKER_NAME}
                  onChange={handleChange}
                  value={values.workerReplaced}
                  name="workerReplaced"
                />
              </Form.Item>
            </Col>
          )}
      </Row>
      {/* TODO: Move out as separate component */}
      <SharedNotification />
      <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
        <Button role="button" big outlined onClick={onPrev}>
          {GENERAL_LABELS.PREV_TAB}
        </Button>
        <Button outlined role="button" big onClick={resetData}>
          {GENERAL_LABELS.RESET}
        </Button>
        <Button role="button" big onClick={onNext}>
          {GENERAL_LABELS.NEXT_TAB}
        </Button>
      </div>
    </Form>
  );
};

WorkContractTab.propTypes = propTypes;

export const WorkContractFormTab = formikEnhancer(WorkContractTab);
