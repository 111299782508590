import React, { useMemo } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { selectPackagesCosts } from 'modules/cart/selectors';
import { useSelector } from 'react-redux';
import { Button } from 'components/ui/Button';
import { SubscribeSuccess } from 'components/notifications/SubscribeSuccess';
import { EURO_SIGN } from 'const';
import { LANDING_PAGE_TRANSLATIONS } from 'const/translations/ServiceLanding';
import { PACKAGES_NAMES } from 'const/packages';
import Styles from './LPPriceBlock.module.css';

const { TAX } = LANDING_PAGE_TRANSLATIONS;

const propTypes = {
  simplified: PropTypes.bool,
  packageName: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  customCost: PropTypes.string,
  onButtonClick: PropTypes.func,
  showTooltip: PropTypes.bool,
};

export const LPPriceBlock = ({
  buttonText,
  packageName,
  text,
  customCost,
  onButtonClick,
  simplified = false,
  showTooltip,
}) => {
  const packagesPrices = useSelector(selectPackagesCosts);
  const currentPackageCost = useMemo(() => {
    if (packageName === 'HELP') {
      return 'RDV';
    }
    if (packageName === PACKAGES_NAMES.WAIBI) {
      return customCost;
    }

    return packagesPrices[packageName] || 'N/A';
  }, [packageName, packagesPrices, customCost]);

  const hasTextCost = packageName === PACKAGES_NAMES.WAIBI;

  return (
    <div
      className={clsx(MainStyles.container, {
        [Styles.simplify]: simplified,
      })}
    >
      <div className={Styles.wrapper}>
        <div className={Styles.costBlock}>
          {hasTextCost ? (
            <span className={Styles.textCost}>{currentPackageCost}</span>
          ) : (
            <>
              <span className={Styles.cost}>
                {currentPackageCost} {EURO_SIGN}
              </span>
              {!simplified && <span className={Styles.tax}>{TAX}</span>}
            </>
          )}
        </div>
        <div className={Styles.infoBlock}>
          {!simplified && <span className={Styles.text}>{text}</span>}
          <div className={Styles.buttonWrapper}>
            <Button
              wide
              variant="success"
              onClick={onButtonClick}
              className={Styles.button}
            >
              {buttonText}
            </Button>
            {showTooltip && <SubscribeSuccess />}
          </div>
        </div>
      </div>
    </div>
  );
};

LPPriceBlock.propTypes = propTypes;
