import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { ROW_BUTTONS_COUNT } from 'const/InvoiceModalContent';

const propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export const ModalContentButtons = memo(({ buttons }) => {
  const validButtons = buttons.filter(({ label }) => Boolean(label));
  const buttonsGroup = [];

  for (let i = 0; i < validButtons.length; i += ROW_BUTTONS_COUNT) {
    buttonsGroup.push(validButtons.slice(i, i + ROW_BUTTONS_COUNT));
  }

  return buttonsGroup.map((row) => (
    <div className="ant-modal-confirm-btns" key={uuidv4()}>
      {row.map(({ label, onClick }) => (
        <Button
          className="ant-btn ant-btn-primary"
          onClick={onClick}
          key={label}
        >
          {label}
        </Button>
      ))}
    </div>
  ));
});

ModalContentButtons.propTypes = propTypes;
