/*
    returns object of percentage difference between first number and second
    object contains negativity and value of percentage difference
    e.g (10,20) => {isNegative:true, percentageDiff:'50'}
        (20,10) => {isNegative:false, percentageDiff:'100'}
*/
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { MONTHS } from 'const/ui';
import { getRealYearValueFromFiscal } from '../charts';

export const getPercentageDifference = (number1, number2) => {
  const difference = number1 - number2;
  let percentageDiff = (difference / number2) * 100;
  if ([number1, number2].includes(0)) {
    percentageDiff = 100;
  }
  if (Number(number1) === 0 && Number(number2) === 0) {
    percentageDiff = 0;
  }
  return {
    isNegative: number1 < number2,
    percentageDiff: Math.round(Math.abs(percentageDiff)),
  };
};

// returns array of mapped chartData
// result data depends of firstMonth and activeMonthName
// e.g chartData => [{year:2020, values:[0  ,  1,   2,   3,  4, ... , 11]},
//                   {year:2019, values:[3  ,  4,   5,   6,  7, ... , 20]]
//                                      sept oct  nov  dec  jan      august
//     firstMonth = September, activeMonth = November, lastUpdateMonthIndex = 4(last update`s month is May)
// if countMode = single => [{year: 2020, value: 2},{year: 2019, value: 5}] single nov value
// if countMode = summary =>  [{year: 2020, value: 3},{year: 2019, value: 12}] sum from sept to nov
// if isChartDateModeAnnual => returns same values for last update`s month
// if !isChartDateModEAnnual => values array will contains 28/29/30/31 values
//                              returns the same but value will be either single of current day
//                                               or sum from 1 day to current
/**
 * @param activeMonthName {string} - current active month in month selector
 * @param firstMonth {string} - first month of financial year
 * @param isChartDateModeAnnual {boolean} - is chart view mode Annual now
 * @param lastUpdateMonthIndex {number} - used to determine which month values to show in annual view
 * @param chartData {array} - array of following structure [{year:Number, values: Array<Number>}]
 * @param countMode {string} - either "single" or "summary", decide return 1 value or sum from first to index
 * @param lastDayOfCurrentMonthData - last index of chart`s dataset of currently going financial year
 * */
export const getDataForTurnoverSummary = (
  activeMonthName,
  firstMonth,
  isChartDateModeAnnual,
  lastUpdateMonthIndex,
  chartData,
  countMode = 'single',
  lastDayOfCurrentMonthData
) => {
  let monthIndex = getMonthIndexFromName(activeMonthName);
  if (isChartDateModeAnnual) {
    monthIndex = lastUpdateMonthIndex;
  }
  const firstMonthIndex = getMonthIndexFromName(firstMonth);
  const indexesDifference = firstMonthIndex - monthIndex;
  let dataIndex;
  if (indexesDifference > 0) {
    dataIndex = MONTHS.length - indexesDifference;
  } else {
    dataIndex = Math.abs(indexesDifference);
  }
  if (!isChartDateModeAnnual) {
    dataIndex = lastDayOfCurrentMonthData - 1;
  }
  if (countMode === 'single') {
    return chartData.map(({ year, values }) => ({
      year: getRealYearValueFromFiscal(year, dataIndex, firstMonth),
      value: Number(values[dataIndex]) || 0,
    }));
  }
  return chartData.map(({ year, values }) => ({
    year,
    value: values.reduce((prev, value, index) => {
      if (index > dataIndex) return Number(prev);
      return (Number(prev) + Number(value)).toFixed(2);
    }, 0),
  }));
};
