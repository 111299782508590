import { useSelector } from 'react-redux';
import { selectInvoiceSearchResults } from 'modules/invoiceSearch/selectors';

/**
 * @param {String} documentId - id of current invoice
 * /**
 * @typedef {Object} CommentData
 * @property {String} comment - comment's text
 * @property {String} date - comment's date
 *
 * @returns {CommentData[]}
 */
export const useInvoiceComments = (documentId) => {
  const invoiceSearchResults = useSelector(selectInvoiceSearchResults);

  const currentInvoice = invoiceSearchResults.find(
    (invoice) => invoice.documentId === documentId
  );

  return currentInvoice?.comments || [];
};
