import { getType, getActionCreator } from 'utils';
const prefix = '@@app/invoiceSearch';

/**
 * Search invoices
 */
export const INVOICES_SEARCH_REQUEST = getType(
  prefix,
  'INVOICES_SEARCH_REQUEST'
);
export const invoicesSearchRequest = getActionCreator(INVOICES_SEARCH_REQUEST);

export const INVOICES_SEARCH_SUCCESS = getType(
  prefix,
  'INVOICES_SEARCH_SUCCESS'
);
export const invoicesSearchSuccess = getActionCreator(INVOICES_SEARCH_SUCCESS);

export const INVOICES_SEARCH_ERROR = getType(prefix, 'INVOICES_SEARCH_ERROR');
export const invoicesSearchError = getActionCreator(INVOICES_SEARCH_ERROR);

export const INVOICES_SEARCH_INIT = getType(prefix, 'INVOICES_SEARCH_INIT');
export const invoicesSearchInit = getActionCreator(INVOICES_SEARCH_INIT);

export const INVOICES_SEARCH_SET_SORT = getType(
  prefix,
  'INVOICES_SEARCH_SET_SORT'
);
export const invoicesSearchSetSort = getActionCreator(INVOICES_SEARCH_SET_SORT);

export const INVOICES_NEXT_PAGE_REQUEST = getType(
  prefix,
  'INVOICES_NEXT_PAGE_REQUEST'
);
export const invoicesNextPageRequest = getActionCreator(
  INVOICES_NEXT_PAGE_REQUEST
);
export const INVOICES_NEXT_PAGE_SUCCESS = getType(
  prefix,
  'INVOICES_NEXT_PAGE_SUCCESS'
);
export const invoicesNextPageSuccess = getActionCreator(
  INVOICES_NEXT_PAGE_SUCCESS
);
export const INVOICES_NEXT_PAGE_ERROR = getType(
  prefix,
  'INVOICES_NEXT_PAGE_ERROR'
);
export const invoicesNextPageError = getActionCreator(INVOICES_NEXT_PAGE_ERROR);
export const INVOICES_NEXT_PAGE_NOT_NEEDED = getType(
  prefix,
  'INVOICES_NEXT_PAGE_NOT_NEEDED'
);
export const invoicesNextPageNotNeeded = getActionCreator(
  INVOICES_NEXT_PAGE_NOT_NEEDED
);

export const INVOICES_APPROVE_REQUEST = getType(
  prefix,
  'INVOICES_APPROVE_REQUEST'
);
export const invoicesApproveRequest = getActionCreator(
  INVOICES_APPROVE_REQUEST
);
export const INVOICES_APPROVE_SUCCESS = getType(
  prefix,
  'INVOICES_APPROVE_SUCCESS'
);
export const invoicesApproveSuccess = getActionCreator(
  INVOICES_APPROVE_SUCCESS
);
export const INVOICES_APPROVE_ERROR = getType(prefix, 'INVOICES_APPROVE_ERROR');
export const invoicesApproveError = getActionCreator(INVOICES_APPROVE_ERROR);

export const INVOICES_PAID_OUTSIDE_REQUEST = getType(
  prefix,
  'INVOICES_PAID_OUTSIDE_REQUEST'
);
export const invoicesPaidOutsideRequest = getActionCreator(
  INVOICES_PAID_OUTSIDE_REQUEST
);
export const INVOICES_PAID_OUTSIDE_SUCCESS = getType(
  prefix,
  'INVOICES_PAID_OUTSIDE_SUCCESS'
);
export const invoicesPaidOutsideSuccess = getActionCreator(
  INVOICES_PAID_OUTSIDE_SUCCESS
);

export const MASS_INVOICES_PAID_OUTSIDE_REQUEST = getType(
  prefix,
  'MASS_INVOICES_PAID_OUTSIDE_REQUEST'
);
export const massInvoicesPaidOutsideRequest = getActionCreator(
  MASS_INVOICES_PAID_OUTSIDE_REQUEST
);
export const MASS_INVOICES_PAID_OUTSIDE_SUCCESS = getType(
  prefix,
  'MASS_INVOICES_PAID_OUTSIDE_SUCCESS'
);
export const massInvoicesPaidOutsideSuccess = getActionCreator(
  MASS_INVOICES_PAID_OUTSIDE_SUCCESS
);

export const INVOICES_PREVALIDATE_REQUEST = getType(
  prefix,
  'INVOICES_PREVALIDATE_REQUEST'
);
export const invoicesPrevalidateRequest = getActionCreator(
  INVOICES_PREVALIDATE_REQUEST
);
export const INVOICES_PREVALIDATE_SUCCESS = getType(
  prefix,
  'INVOICES_PREVALIDATE_SUCCESS'
);
export const invoicesPrevalidateSuccess = getActionCreator(
  INVOICES_PREVALIDATE_SUCCESS
);
export const INVOICES_PREVALIDATE_ERROR = getType(
  prefix,
  'INVOICES_PREVALIDATE_ERROR'
);
export const invoicesPrevalidateError = getActionCreator(
  INVOICES_PREVALIDATE_ERROR
);

export const CANCEL_INVOICE_PREVALIDATION_REQUEST = getType(
  prefix,
  'CANCEL_INVOICE_PREVALIDATION_REQUEST'
);
export const cancelInvoicePrevalidationRequest = getActionCreator(
  CANCEL_INVOICE_PREVALIDATION_REQUEST
);
export const CANCEL_INVOICE_PREVALIDATION_SUCCESS = getType(
  prefix,
  'CANCEL_INVOICE_PREVALIDATION_SUCCESS'
);
export const cancelInvoicePrevalidationSuccess = getActionCreator(
  CANCEL_INVOICE_PREVALIDATION_SUCCESS
);

export const MASS_INVOICES_APPROVE_REQUEST = getType(
  prefix,
  'MASS_INVOICES_APPROVE_REQUEST'
);
export const massInvoicesApproveRequest = getActionCreator(
  MASS_INVOICES_APPROVE_REQUEST
);
export const MASS_INVOICES_APPROVE_SUCCESS = getType(
  prefix,
  'MASS_INVOICES_APPROVE_SUCCESS'
);
export const massInvoicesApproveSuccess = getActionCreator(
  MASS_INVOICES_APPROVE_SUCCESS
);
const MASS_INVOICES_APPROVE_ERROR = getType(
  prefix,
  'MASS_INVOICES_APPROVE_ERROR'
);
export const massInvoicesApproveError = getActionCreator(
  MASS_INVOICES_APPROVE_ERROR
);

export const INVOICES_DEVALIDATE_REQUEST = getType(
  prefix,
  'INVOICES_DEVALIDATE_REQUEST'
);
export const invoicesDevalidateRequest = getActionCreator(
  INVOICES_DEVALIDATE_REQUEST
);
export const INVOICES_DEVALIDATE_SUCCESS = getType(
  prefix,
  'INVOICES_DEVALIDATE_SUCCESS'
);
export const invoicesDevalidateSuccess = getActionCreator(
  INVOICES_DEVALIDATE_SUCCESS
);
export const INVOICES_DEVALIDATE_ERROR = getType(
  prefix,
  'INVOICES_DEVALIDATE_ERROR'
);
export const invoicesDevalidateError = getActionCreator(
  INVOICES_DEVALIDATE_ERROR
);

export const INVOICES_DEVALIDATE_AND_APPROVE_REQUEST = getType(
  prefix,
  'INVOICES_DEVALIDATE_AND_APPROVE_REQUEST'
);
export const invoicesDevalidateAndApproveRequest = getActionCreator(
  INVOICES_DEVALIDATE_AND_APPROVE_REQUEST
);
export const INVOICES_DEVALIDATE_AND_APPROVE_SUCCESS = getType(
  prefix,
  'INVOICES_DEVALIDATE_AND_APPROVE_SUCCESS'
);
export const invoicesDevalidateAndApproveSuccess = getActionCreator(
  INVOICES_DEVALIDATE_AND_APPROVE_SUCCESS
);
export const INVOICES_DEVALIDATE_AND_APPROVE_ERROR = getType(
  prefix,
  'INVOICES_DEVALIDATE_AND_APPROVE_ERROR'
);
export const invoicesDevalidateAndApproveError = getActionCreator(
  INVOICES_DEVALIDATE_AND_APPROVE_ERROR
);

export const INVOICES_ACCEPT_REQUEST = getType(
  prefix,
  'INVOICES_ACCEPT_REQUEST'
);
export const invoicesAcceptRequest = getActionCreator(INVOICES_ACCEPT_REQUEST);
export const INVOICES_ACCEPT_SUCCESS = getType(
  prefix,
  'INVOICES_ACCEPT_SUCCESS'
);
export const invoicesAcceptSuccess = getActionCreator(INVOICES_ACCEPT_SUCCESS);
export const INVOICES_ACCEPT_ERROR = getType(prefix, 'INVOICES_ACCEPT_ERROR');
export const invoicesAcceptError = getActionCreator(INVOICES_ACCEPT_ERROR);

export const INVOICES_DELETE_REQUEST = getType(
  prefix,
  'INVOICES_DELETE_REQUEST'
);
export const invoicesDeleteRequest = getActionCreator(INVOICES_DELETE_REQUEST);
export const INVOICES_DELETE_SUCCESS = getType(
  prefix,
  'INVOICES_DELETE_SUCCESS'
);
export const invoicesDeleteSuccess = getActionCreator(INVOICES_DELETE_SUCCESS);
export const INVOICES_DELETE_ERROR = getType(prefix, 'INVOICES_DELETE_ERROR');
export const invoicesDeleteError = getActionCreator(INVOICES_DELETE_ERROR);

export const INVOICES_ADD_COMMENT_REQUEST = getType(
  prefix,
  'INVOICES_ADD_COMMENT_REQUEST'
);
export const invoicesAddCommentRequest = getActionCreator(
  INVOICES_ADD_COMMENT_REQUEST
);
export const INVOICES_ADD_COMMENT_SUCCESS = getType(
  prefix,
  'INVOICES_ADD_COMMENT_SUCCESS'
);
export const invoicesAddCommentSuccess = getActionCreator(
  INVOICES_ADD_COMMENT_SUCCESS
);
export const INVOICES_ADD_COMMENT_ERROR = getType(
  prefix,
  'INVOICES_ADD_COMMENT_ERROR'
);
export const invoicesAddCommentError = getActionCreator(
  INVOICES_ADD_COMMENT_ERROR
);

export const INVOICES_SEND_BY_EMAIL_REQUEST = getType(
  prefix,
  'INVOICES_SEND_BY_EMAIL_REQUEST'
);
export const invoicesSendByEmailRequest = getActionCreator(
  INVOICES_SEND_BY_EMAIL_REQUEST
);

export const INVOICES_SEND_BY_EMAIL_SUCCESS = getType(
  prefix,
  'INVOICES_SEND_BY_EMAIL_SUCCESS'
);
export const invoicesSendByEmailSuccess = getActionCreator(
  INVOICES_SEND_BY_EMAIL_SUCCESS
);

export const INVOICES_SEND_BY_EMAIL_ERROR = getType(
  prefix,
  'INVOICES_SEND_BY_EMAIL_ERROR'
);
export const invoicesSendByEmailError = getActionCreator(
  INVOICES_SEND_BY_EMAIL_ERROR
);

export const CREATE_INVOICE_IN_LIBEO_SUCCESS = getType(
  prefix,
  'CREATE_INVOICE_IN_LIBEO_SUCCESS'
);
export const createInvoiceInLibeoSuccess = getActionCreator(
  CREATE_INVOICE_IN_LIBEO_SUCCESS
);

export const GET_PAY_INVOICE_URL_REQUEST = getType(
  prefix,
  'GET_PAY_INVOICE_URL_REQUEST'
);
export const getPayInvoiceUrlRequest = getActionCreator(
  GET_PAY_INVOICE_URL_REQUEST
);

export const GET_PAY_INVOICE_URL_SUCCESS = getType(
  prefix,
  'GET_PAY_INVOICE_URL_SUCCESS'
);
export const getPayInvoiceUrlSuccess = getActionCreator(
  GET_PAY_INVOICE_URL_SUCCESS
);

export const RESET_PAY_INVOICE_URL = getType(prefix, 'RESET_PAY_INVOICE_URL');
export const resetPayInvoiceUrl = getActionCreator(RESET_PAY_INVOICE_URL);

export const VALIDATE_AND_PAY_INVOICE = getType(
  prefix,
  'VALIDATE_AND_PAY_INVOICE'
);
export const validateAndPayInvoice = getActionCreator(VALIDATE_AND_PAY_INVOICE);

export const DOWNLOAD_INVOICE_REQUEST = getType(
  prefix,
  'DOWNLOAD_INVOICE_REQUEST'
);
export const downloadInvoiceRequest = getActionCreator(
  DOWNLOAD_INVOICE_REQUEST
);
export const DOWNLOAD_INVOICE_SUCCESS = getType(
  prefix,
  'DOWNLOAD_INVOICE_SUCCESS'
);
export const downloadInvoiceSuccess = getActionCreator(
  DOWNLOAD_INVOICE_SUCCESS
);
export const DOWNLOAD_INVOICE_ERROR = getType(prefix, 'DOWNLOAD_INVOICE_ERROR');
export const downloadInvoiceError = getActionCreator(DOWNLOAD_INVOICE_ERROR);

export const OPEN_INVOICE_REQUEST = getType(prefix, 'OPEN_INVOICE_REQUEST');
export const openInvoiceRequest = getActionCreator(OPEN_INVOICE_REQUEST);
export const OPEN_INVOICE_SUCCESS = getType(prefix, 'OPEN_INVOICE_SUCCESS');
export const openInvoiceSuccess = getActionCreator(OPEN_INVOICE_SUCCESS);
export const OPEN_INVOICE_ERROR = getType(prefix, 'OPEN_INVOICE_ERROR');
export const openInvoiceError = getActionCreator(OPEN_INVOICE_ERROR);

export const STORE_FILE = getType(prefix, 'STORE_FILE');
export const storeFile = getActionCreator(STORE_FILE);

export const DOWNLOAD_DOCUMENTS_REQUEST = getType(
  prefix,
  'DOWNLOAD_DOCUMENTS_REQUEST'
);
export const downloadDocumentsRequest = getActionCreator(
  DOWNLOAD_DOCUMENTS_REQUEST
);
export const DOWNLOAD_DOCUMENTS_SUCCESS = getType(
  prefix,
  'DOWNLOAD_DOCUMENTS_SUCCESS'
);
export const downloadDocumentsSuccess = getActionCreator(
  DOWNLOAD_DOCUMENTS_SUCCESS
);
export const DOWNLOAD_DOCUMENTS_ERROR = getType(
  prefix,
  'DOWNLOAD_DOCUMENTS_ERROR'
);
export const downloadDocumentsError = getActionCreator(
  DOWNLOAD_DOCUMENTS_ERROR
);
