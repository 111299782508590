import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Input, message } from 'antd';

import { openSuccessNotification } from 'helpers/notifications';
import { UploadManagerMobileLayout } from 'components/UploadDocuments/FileUploadManager/UploadManagerMobileLayout';
import { contactHRRequest } from 'modules/HRModal/actions';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';

import Styles from './ModalContactHR.module.css';
import { Button } from '../../components/ui/Button';

import { CONTACT_HR_TRANSLATIONS } from '../../const/translations/ModalContactHR';
import { isFileForbidden } from '../../helpers/common';
import { useUpload } from '../../hooks/useUpload';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from '../../const/translations';
import { selectUploadPayload } from '../../helpers/UploadDocuments';

const {
  MESSAGES: { ERROR, FILE_EXISTS },
} = UPLOAD_DOCUMENT_TRANSLATIONS;

const propTypes = {
  handleClose: PropTypes.func,
};

const {
  TITLE,
  TOPIC_LABEL,
  ERRORS,
  OK_BUTTON,
  CANCEL_BUTTON,
  MESSAGE_PLACEHOLDER,
  SUBMIT_SUCCESS,
} = CONTACT_HR_TRANSLATIONS;
const { error: errorMessage } = message;

export const ContactHRModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);

  const [topic, setTopic] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [messageText, setMessage] = useState('');

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;
      if (name === 'topic') {
        setTopic(value);
      }
      if (name === 'message') {
        setMessage(value);
      }
    },
    [setTopic, setMessage]
  );

  const { handleChange, handleRemove, handleReset } = useUpload(
    uploadedFiles,
    setUploadedFiles
  );

  const beforeUpload = useCallback(
    (file) => {
      const fileNames = uploadedFiles.map((item) => item.name);
      if (isFileForbidden(file)) {
        message.error(ERROR.replace('{{FILE_NAME}}', file.name));
        return Promise.reject();
      }
      if (fileNames.includes(file.name)) {
        message.error(FILE_EXISTS.replace('{{FILE_NAME}}', file.name));
        return Promise.reject();
      }
      return false; // do not send to the backend
    },
    [uploadedFiles]
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      contactHRRequest({
        topic,
        message: messageText,
        uploadedFiles: selectUploadPayload(uploadedFiles, isPreCompta),
        onSuccess: () => openSuccessNotification({ message: SUBMIT_SUCCESS }),
        onError: (error) =>
          errorMessage(error?.message || ERRORS.DEFAULT_REQUEST_ERROR),
      })
    );
    setMessage('');
    setTopic('');
    handleClose();
  }, [dispatch, topic, messageText, uploadedFiles, isPreCompta, handleClose]);

  const listHasValidFiles = useMemo(
    () => !!selectUploadPayload(uploadedFiles, isPreCompta).length,
    [isPreCompta, uploadedFiles]
  );

  return (
    <>
      <div className={Styles.Title}>{TITLE}</div>
      <div className={Styles.TopicLabel}>{TOPIC_LABEL}</div>
      <div>
        <Input
          onChange={handleInputChange}
          value={topic}
          name="topic"
          className={Styles.Message}
          maxLength={200}
        />
      </div>
      <div>
        <UploadManagerMobileLayout
          handleChange={handleChange}
          uploadedFiles={uploadedFiles}
          beforeUpload={beforeUpload}
          handleRemove={handleRemove}
          handleReset={handleReset}
        />
      </div>
      <div>
        <Input.TextArea
          onChange={handleInputChange}
          value={messageText}
          name="message"
          placeholder={MESSAGE_PLACEHOLDER}
          maxLength={1000}
          className={Styles.Message}
          rows={6}
        />
      </div>
      <div className={Styles.buttonWrapper}>
        <Button
          onClick={handleClose}
          className={clsx(Styles.CancelButton, Styles.button)}
          variant="secondary"
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          variant="success"
          onClick={handleSubmit}
          className={Styles.button}
          disabled={
            !topic ||
            !messageText.trim() ||
            (uploadedFiles.length && !listHasValidFiles)
          }
        >
          {OK_BUTTON}
        </Button>
      </div>
    </>
  );
};

ContactHRModal.propTypes = propTypes;
