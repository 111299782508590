import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Radio } from 'antd';
import clsx from 'clsx';
import { DatePickerAdapted } from 'components/adaptors/DatePicker';
import { DATE_FORMAT, ISO_DATE_FORMAT } from 'const';
import { useFormik } from 'formik';
import MainStyles from 'assets/styles/main.module.css';
import {
  MASS_INVOICE_ACTIONS_TRANSLATIONS,
  INVOICE_SEARCH_TRANSLATIONS,
} from 'const/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  massInvoicesApproveRequest,
  massInvoicesPaidOutsideRequest,
} from 'modules/invoiceSearch/actions';
import { selectInvoiceSearchResults } from 'modules/invoiceSearch/selectors';
import moment from 'moment';
import Styles from './MassInvoiceActionsModal.module.css';
import { Button } from '../ui/Button';

const { TextArea } = Input;

const {
  APPROVE_TITLE,
  PAID_OUTSIDE_TITLE,
  SET_DATE,
  USE_DEADLINE_DATE,
  OK_BUTTON,
  CANCEL_BUTTON,
  ERROR_REQUIRED_DATE,
} = MASS_INVOICE_ACTIONS_TRANSLATIONS;
const {
  MODAL: { APPROVE_COMMENTARY, SELECT_DUE_DATE },
} = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  handleClose: PropTypes.func,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
  setSelectedRowKeys: PropTypes.func,
  paidOutside: PropTypes.bool,
};

export const MassInvoiceActionsModal = ({
  handleClose,
  selectedRowKeys,
  setSelectedRowKeys,
  paidOutside,
}) => {
  const dispatch = useDispatch();
  const invoiceSearchResults = useSelector(selectInvoiceSearchResults);

  const invoicesId = invoiceSearchResults
    .filter((invoice) => selectedRowKeys.includes(invoice.id))
    .map((invoice) => invoice.documentId);

  const initialValues = {
    paymentApprovedComment: '',
    approvedDueDate: null,
    isDateVisible: false,
  };

  const handleApprove = (valuesToSubmit) => {
    const payload = { invoicesId };

    if (valuesToSubmit.paymentApprovedComment) {
      payload.paymentApprovedComment = valuesToSubmit.paymentApprovedComment;
    }

    if (valuesToSubmit.approvedDueDate) {
      payload.approvedDueDate = valuesToSubmit.approvedDueDate;
    }

    if (paidOutside) {
      dispatch(massInvoicesPaidOutsideRequest(payload));
    } else {
      dispatch(massInvoicesApproveRequest(payload));
    }

    setSelectedRowKeys([]);
    handleClose();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleApprove,
    validate: (valuesToValidate) => {
      const errorsNew = {};
      if (
        valuesToValidate.approvedDueDate === null &&
        valuesToValidate.isDateVisible
      ) {
        errorsNew.approvedDueDate = ERROR_REQUIRED_DATE;
      }
      return errorsNew;
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleChange = ({ target: { name, value } }) =>
    formik.setFieldValue(name, value);

  const isDateIncorrect =
    formik.touched.approvedDueDate && Boolean(formik.errors.approvedDueDate);

  const disabledDateHandler = useCallback(
    (current) =>
      current &&
      current.format(ISO_DATE_FORMAT) < moment().format(ISO_DATE_FORMAT),
    []
  );

  return (
    <>
      <h3 className={Styles.textTitle}>
        {paidOutside ? PAID_OUTSIDE_TITLE : APPROVE_TITLE}
      </h3>
      <form onSubmit={formik.handleSubmit}>
        {!paidOutside && (
          <Radio.Group
            name="isDateVisible"
            defaultValue={false}
            className={Styles.radioField}
            onChange={handleChange}
          >
            <Radio className={Styles.textDefault} value={false}>
              {USE_DEADLINE_DATE}
            </Radio>
            {/* eslint-disable-next-line react/jsx-boolean-value */}
            <Radio className={Styles.textDefault} value={true}>
              {SET_DATE}
            </Radio>
          </Radio.Group>
        )}
        {formik.values.isDateVisible && (
          <div className={clsx(Styles.textDefault, Styles.field)}>
            {SELECT_DUE_DATE}
            <DatePickerAdapted
              onChange={handleChange}
              name="approvedDueDate"
              value={formik.values.approvedDueDate}
              allowClear
              storageFormat={DATE_FORMAT}
              disabledDate={disabledDateHandler}
            />
          </div>
        )}
        <div
          className={clsx(
            Styles.textDefault,
            Styles.field,
            Styles.commentaryField
          )}
        >
          {APPROVE_COMMENTARY}
          <TextArea
            onChange={handleChange}
            name="paymentApprovedComment"
            value={formik.values.paymentApprovedComment}
          />
        </div>
        {isDateIncorrect && (
          <span className={MainStyles.textError}>
            {formik.errors.approvedDueDate}
          </span>
        )}
        <div
          className={clsx(MainStyles.buttonWrapper, Styles.modalButtonWrapper)}
        >
          <Button
            onClick={handleClose}
            className={clsx(Styles.cancelButton, MainStyles.button)}
            variant="secondary"
          >
            {CANCEL_BUTTON}
          </Button>
          <Button
            outlined
            variant="secondary"
            type="submit"
            className={MainStyles.button}
            disabled={isDateIncorrect}
          >
            {OK_BUTTON}
          </Button>
        </div>
      </form>
    </>
  );
};

MassInvoiceActionsModal.propTypes = propTypes;
