import { getType, getActionCreator } from 'utils';
const prefix = '@@app/salaryCompanySettings';

export const FETCH_COMPANY_DETAIL = getType(prefix, 'FETCH_COMPANY_DETAIL');
export const fetchCompanyDetailRequest = getActionCreator(FETCH_COMPANY_DETAIL);

export const FETCH_COMPANY_DETAIL_SUCCESS = getType(
  prefix,
  'FETCH_COMPANY_DETAIL_SUCCESS'
);
export const fetchCompanyDetailSuccess = getActionCreator(
  FETCH_COMPANY_DETAIL_SUCCESS
);

export const FETCH_COMPANY_DETAIL_ERROR = getType(
  prefix,
  'FETCH_COMPANY_DETAIL_ERROR'
);
export const fetchCompanyDetailError = getActionCreator(
  FETCH_COMPANY_DETAIL_ERROR
);

export const FETCH_SCHEDULES_REQUEST = getType(
  prefix,
  'FETCH_SCHEDULES_REQUEST'
);
export const fetchSchedulesRequest = getActionCreator(FETCH_SCHEDULES_REQUEST);

export const FETCH_SCHEDULES_SUCCESS = getType(
  prefix,
  'FETCH_SCHEDULES_SUCCESS'
);
export const fetchSchedulesSuccess = getActionCreator(FETCH_SCHEDULES_SUCCESS);

export const SET_ACTIVE_BRANCH = getType(prefix, 'SET_ACTIVE_BRANCH');
export const setActiveBranch = getActionCreator(SET_ACTIVE_BRANCH);

export const FETCH_SCHEDULE_DETAIL = getType(prefix, 'FETCH_SCHEDULE_DETAIL');
export const fetchScheduleDetailRequest = getActionCreator(
  FETCH_SCHEDULE_DETAIL
);

export const FETCH_SCHEDULE_DETAIL_SUCCESS = getType(
  prefix,
  'FETCH_SCHEDULE_DETAIL_SUCCESS'
);
export const fetchScheduleDetailSuccess = getActionCreator(
  FETCH_SCHEDULE_DETAIL_SUCCESS
);

export const FETCH_SCHEDULE_DETAIL_ERROR = getType(
  prefix,
  'FETCH_SCHEDULE_DETAIL_ERROR'
);
export const fetchScheduleDetailError = getActionCreator(
  FETCH_SCHEDULE_DETAIL_ERROR
);

export const CREATE_SCHEDULE = getType(prefix, 'CREATE_SCHEDULE');
export const requestScheduleCreate = getActionCreator(CREATE_SCHEDULE);

export const UPDATE_SCHEDULE = getType(prefix, 'UPDATE_SCHEDULE');
export const requestScheduleUpdate = getActionCreator(UPDATE_SCHEDULE);

export const SCHEDULE_DETAIL_MESSAGE = getType(
  prefix,
  'SCHEDULE_DETAIL_MESSAGE'
);
export const scheduleDetailMessage = getActionCreator(SCHEDULE_DETAIL_MESSAGE);

export const REMOVE_SCHEDULE = getType(prefix, 'REMOVE_SCHEDULE');
export const removeSchedule = getActionCreator(REMOVE_SCHEDULE);

export const CLEAN_UP_SCHEDULE_DETAIL = getType(
  prefix,
  'CLEAN_UP_SCHEDULE_DETAIL'
);
export const cleanUpScheduleDetail = getActionCreator(CLEAN_UP_SCHEDULE_DETAIL);

export const CREATE_BRANCH = getType(prefix, 'CREATE_A_BRANCH');
export const createBranch = getActionCreator(CREATE_BRANCH);

export const UPDATE_BRANCH = getType(prefix, 'UPDATE_BRANCH');
export const updateBranch = getActionCreator(UPDATE_BRANCH);

export const DELETE_BRANCH = getType(prefix, 'DELETE_BRANCH');
export const deleteBranch = getActionCreator(DELETE_BRANCH);
