import moment from 'moment';
import { getStringDateWithSlashes } from 'helpers/chartTabs';
import { DAYS_OF_WEEK } from 'const/ui';
import { ABSENCES_FILTER_DATE_FORMAT, DATE_FORMAT } from 'const';

// Groups employees array, add absences property for absences and add key for table format
// e.g.  [{employeeId : 123, name: 'Alex',}]
//         and groupByProperty = 'employeeId' => {
//                                                  123:{
//                                                        employeeId:123,
//                                                         name:'Alex',
//                                                         absences: {},
//                                                         key: 123
//                                                      }
//                                                 }
// groupByProperty should be unique for every item, otherwise some items can be lost
const groupByPropertyCalendarEmployees = (
  employees,
  groupByProperty = 'id'
) => {
  if (employees.length > 0 && !(groupByProperty in employees[0])) {
    return {};
  }
  return employees.reduce((resultObj, employee) => {
    // eslint-disable-next-line no-param-reassign
    resultObj[employee[groupByProperty]] = {
      ...employee,
      absences: {},
      key: employee[groupByProperty],
    };
    return resultObj;
  }, {});
};

const unGroupEmployeesObject = (object) => Object.values(object);

export const getDatesSequence = (startDateIso, endDateIso) => {
  const datesArray = [];
  const startDate = moment(startDateIso);
  while (startDate.isSameOrBefore(moment(endDateIso))) {
    datesArray.push(getStringDateWithSlashes(startDate.toDate().toISOString()));
    startDate.add(1, 'days');
  }
  return datesArray;
};

/*
 * connects employees with their absences
 *  absences object will contain date properties with absence of vacation object inside
 * */
export const attachAbsencesToEmployees = (employees, absences) => {
  const normalizedEmployees = groupByPropertyCalendarEmployees(
    employees,
    'employeeid'
  );
  const employeesWithAbsences = absences.reduce(
    (resultEmployees, { employeeId, start, end, id, reason }) => {
      const employee = resultEmployees[employeeId];
      if (!employee) return resultEmployees;
      const absenceDatesArray = getDatesSequence(start, end);
      // eslint-disable-next-line no-param-reassign
      resultEmployees[employeeId] = absenceDatesArray.reduce(
        (resultEmployee, absenceDate) => {
          // eslint-disable-next-line no-param-reassign
          resultEmployee.absences[absenceDate] = {
            employeeId,
            start,
            end,
            id,
            type: reason?.type,
            reason,
            isCrossingAnotherAbsence: !!resultEmployee.absences[absenceDate],
          };
          return resultEmployee;
        },
        employee
      );
      return resultEmployees;
    },
    { ...normalizedEmployees }
  );

  return unGroupEmployeesObject(employeesWithAbsences);
};

const getFirstThreeLettersOfString = (string) => string.slice(0, 3);

export const getDayOfWeekNameByIndex = (index) => {
  const WEEK_DAYS_FROM_SUNDAY = [DAYS_OF_WEEK[6], ...DAYS_OF_WEEK.slice(0, 6)];
  if (index < -1 || index > 6) return null;
  return getFirstThreeLettersOfString(WEEK_DAYS_FROM_SUNDAY[index].label);
};

/**
 * @param dateWithSlashes {string} - date with format dd/MM/yyyy
 * @return {Date}
 * e.g 05/04/2021 -> new Date('2021-04-05')
 * */
export const dateStringIntoDate = (dateWithSlashes) => {
  const [day, month, year] = dateWithSlashes.split('/');
  return new Date(`${year}-${month}-${day}`);
};

export const formatEmployeeToLeaveModal = (employee) => ({
  key: employee.employeeid,
  employeeid: employee.employeeid,
  siret: employee.siret,
  schedule: employee.scheduleId,
  name: `${employee.firstname1} ${employee.lastname}`,
});

// returns mapped employees with only properties that matters to calendar page
export const mapEmployeesToCalendarPage = (employees) =>
  employees.map(
    ({
      employeeid,
      siret,
      scheduleId,
      firstname1,
      lastname,
      startdate,
      enddate,
    }) => ({
      employeeid,
      siret,
      scheduleId,
      firstname1,
      lastname,
      startdate,
      enddate,
    })
  );

export const getTooltipAddressForBranch = (branch) => {
  const wayname = branch.wayname || branch.wayname2 || '';
  const waynumber = branch.waynumber1 || branch.waynumber2 || '';
  const postcode = branch.postcode || '';
  const city = branch.city || '';
  const address = [waynumber, wayname, postcode, city].reduce(
    (acc, addressItem) => {
      if (addressItem) {
        acc.push(addressItem);
      }
      return acc;
    },
    []
  );
  return address;
};

export const mapCompanyBranchesToSelect = (branches) =>
  branches.map((branch) => ({
    ...branch,
    value: branch.siret,
    label: branch.siret,
    paleLabel: `-- ${branch.wayname}, ${branch.waynumber1}, ${branch.city}`,
    tooltipLabelsArray: getTooltipAddressForBranch(branch),
  }));

/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @returns Bool to indicate whether absence takes only morning on particular date
 */
export const isAbsenceInMorning = ({ absences, date, employeeId }) =>
  isCurrentAbsence(
    { absences, date, employeeId },
    ({ absence, start, end }) => {
      if (
        moment(formatDatesToFilter(date)).isSame(moment(start), 'day') &&
        absence.startTimeOfTheDay === 'morning'
      ) {
        return true;
      }

      if (
        moment(formatDatesToFilter(date)).isSame(moment(end), 'day') &&
        absence.endTimeOfTheDay === 'morning'
      ) {
        return true;
      }

      return false;
    }
  );
/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @returns Bool to indicate whether absence takes only afternoon on particular date
 */
export const isAbsenceInAfternoon = ({ absences, date, employeeId }) =>
  isCurrentAbsence(
    { absences, date, employeeId },
    ({ absence, start, end }) => {
      if (
        moment(formatDatesToFilter(date)).isSame(moment(start), 'day') &&
        absence.startTimeOfTheDay === 'afternoon'
      ) {
        return true;
      }

      if (
        moment(formatDatesToFilter(date)).isSame(moment(end), 'day') &&
        absence.endTimeOfTheDay === 'afternoon'
      ) {
        return true;
      }

      return false;
    }
  );
/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @returns Bool to indicate whether absence takes only half of the day on particular date
 */
export const isAbsencePartial = ({ absences, date, employeeId }) =>
  isCurrentAbsence(
    { absences, date, employeeId },
    ({ absence, start, end }) => {
      if (
        moment(formatDatesToFilter(date)).isSame(moment(start), 'day') &&
        absence.startTimeOfTheDay !== 'whole_day'
      ) {
        return true;
      }

      if (
        moment(formatDatesToFilter(date)).isSame(moment(end), 'day') &&
        absence.endTimeOfTheDay !== 'whole_day'
      ) {
        return true;
      }

      return false;
    }
  );

/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @returns Bool to indicate whether absence have an attachment
 */
export const isAbsenceWithAttachment = ({ absences, date, employeeId }) =>
  isCurrentAbsence(
    { absences, date, employeeId },
    ({ absence }) => Object.keys(absence.supportingDoc).length !== 0
  );

/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @returns Bool to indicate whether absence is loading or not
 */
export const isCurrentAbsenceLoading = ({ absences, date, employeeId }) =>
  isCurrentAbsence(
    { absences, date, employeeId },
    ({ absence }) => !absence.id
  );

/**
 * @param absences Array of all absences on page
 * @param date Current date
 * @param employeeId Id of the employee for whose cell is being processed
 * @param customComparator Function returning bool based on absence data passed to it
 * @returns Bool to indicate whether absence is current and if it satisfies custom logic
 */
const isCurrentAbsence = ({ absences, date, employeeId }, customComparator) =>
  absences?.find((absence) => {
    if (absence.employeeId === employeeId) {
      const isDayOfAbsence = moment(formatDatesToFilter(date)).isBetween(
        moment(absence.start),
        moment(absence.end),
        'day',
        '[]'
      );

      return (
        isDayOfAbsence &&
        customComparator({ absence, start: absence.start, end: absence.end })
      );
    }

    return false;
  }) !== undefined;

/**
 * @param dates Array of all dates of month
 * @param absences Array of all absences on page
 * @returns Object of shape: Array of absences by dates
 */
export const mapAbsencesToDates = ({ dates, absences }) =>
  dates.reduce(
    (acc, date) => ({
      ...acc,
      [date]: absences.filter((absence) =>
        moment(formatDatesToFilter(date)).isBetween(
          moment(absence.start),
          moment(absence.end),
          'day',
          '[]'
        )
      ),
    }),
    {}
  );

/**
 * Checks if any of the given dates intersects with absence dates.
 * @param {Object} absence Absence object (ex like of modalData.employee.absence)
 * @param {Date[]} dates Dates to check
 * @returns {Boolean} True if one of the dates is between absence's borders.
 */
export const isAbsenceInDates = (absence, dates) =>
  dates.find((date) =>
    moment(formatDatesToFilter(date)).isBetween(
      moment(absence.start),
      moment(absence.end),
      'day',
      '[]'
    )
  );

/**
 * DD/MM/YYYY -> MM/DD/YYYY
 */
export const formatDatesToFilter = (date) =>
  moment(date, DATE_FORMAT).format(ABSENCES_FILTER_DATE_FORMAT);
