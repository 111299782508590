import youTubeIcon from 'assets/icons/youtube.png';
import twitterIcon from 'assets/icons/twitter.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';
import { EXTERNAL_ROUTES, ROUTES } from 'const';
import { FOOTER_TRANSLATIONS } from '../translations/Footer';

export const FOOTER_SECTIONS = [
  {
    TITLE: FOOTER_TRANSLATIONS.TITLES.CONTACTS,
    LINKS: [
      {
        NAME: FOOTER_TRANSLATIONS.LINKS.OUR_AGENCIES,
        LINK: EXTERNAL_ROUTES.EXCO_AGENCIES,
        EXTERNAL: true,
      },
      {
        NAME: FOOTER_TRANSLATIONS.LINKS.CONTACT_US,
        LINK: EXTERNAL_ROUTES.EXCO_CONTACT,
        EXTERNAL: true,
      },
      {
        verifyHidden: (userModules, isMainUser) => !isMainUser,
        NAME: FOOTER_TRANSLATIONS.LINKS.UNSUBSCRIBE_HERE,
        LINK: ROUTES.UNSUBSCRIBE,
        EXTERNAL: false,
        activeCompanyOnly: true,
      },
    ],
  },
  // {
  //   TITLE: FOOTER_TRANSLATIONS.TITLES.HELP,
  //   LINKS: [{ NAME: FOOTER_TRANSLATIONS.LINKS.HOW_IT_WORKS, LINK: '/help' }],
  // },
  {
    TITLE: FOOTER_TRANSLATIONS.TITLES.LEGAL_NOTICES,
    LINKS: [
      {
        NAME: FOOTER_TRANSLATIONS.LINKS.WHO_ARE_WE,
        LINK: EXTERNAL_ROUTES.EXCO,
        EXTERNAL: true,
      },
      {
        NAME: FOOTER_TRANSLATIONS.LINKS.CGU,
        LINK: ROUTES.TERMS_OF_USE_LANDING,
        EXTERNAL: false,
      },
      {
        NAME: FOOTER_TRANSLATIONS.LINKS.QUESTIONS,
        LINK: EXTERNAL_ROUTES.QUESTIONS,
        EXTERNAL: true,
      },
    ],
  },
];

export const EXCO_ADDRESS = {
  TOP_PART: '2 Rue des Feuillants',
  BOTTOM_PART: '31300 Toulouse',
};

export const EXCO_SOCIALS = {
  TWITTER: { ICON: twitterIcon, LINK: EXTERNAL_ROUTES.TWITTER },
  LINKEDIN: { ICON: linkedinIcon, LINK: EXTERNAL_ROUTES.LINKEDIN },
  YOUTUBE: { ICON: youTubeIcon, LINK: EXTERNAL_ROUTES.YOUTUBE },
};
