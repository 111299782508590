import React from 'react';
import MainStyles from 'assets/styles/main.module.css';
import { useSelector } from 'react-redux';
import { selectUserEmail } from 'modules/loggedUserInfo/selectors';
import { MY_INFORMATION_PAGE_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import flowersImage from 'assets/images/loginNewBg.svg';
import { Section } from 'components/Section';
import { CompanyContact } from 'components/Contacts';
import { InformationRow } from 'components/InformationRow';
import Styles from './MyInformation.module.css';

const informationLinks = {
  CHANGE_EMAIL:
    'https://aide.myexco.net/help/fr-fr/2-questions-frequemment-posees/46-modifiez-votre-adresse-e-mail-de-connexion-a-my-exco',
  CHANGE_PASSWORD:
    'https://aide.myexco.net/help/fr-fr/2-questions-frequemment-posees/2-modifiez-votre-mot-de-passe',
};

export const MyInformation = () => {
  const currentEmail = useSelector(selectUserEmail);

  return (
    <div className={Styles.hubPageBackground}>
      <div className={clsx(MainStyles.container)}>
        <img className={Styles.flowersImg} src={flowersImage} alt="flowers" />
        <Section
          title={MY_INFORMATION_PAGE_TRANSLATIONS.TITLE}
          titleTextColor="white"
        >
          <div className={Styles.container}>
            <CompanyContact
              label={MY_INFORMATION_PAGE_TRANSLATIONS.EMAIL_MY_CURRENT_LOGIN}
              emails={currentEmail.toLowerCase()}
              hideIcon
            />
            <InformationRow
              label={MY_INFORMATION_PAGE_TRANSLATIONS.CHANGE_EMAIL}
              href={informationLinks.CHANGE_EMAIL}
            />
            <InformationRow
              label={MY_INFORMATION_PAGE_TRANSLATIONS.CHANGE_PASSWORD}
              href={informationLinks.CHANGE_PASSWORD}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};
