import { select, takeLatest, call, put } from 'redux-saga/effects';
import { selectUserCurrentCompanyTransition } from 'modules/loggedUserInfo/selectors';
import { getTurnoverData } from 'modules/turnoverPage/services';
import {
  GET_TURNOVER_DATA_REQUEST,
  getTurnoverDataError,
  getTurnoverDataSuccess,
} from 'modules/turnoverPage/actions';

function* getTurnoverDataWorker({
  payload: { currentExercice, currentCompanyId },
}) {
  try {
    if (currentExercice && currentCompanyId) {
      const transition = yield select(selectUserCurrentCompanyTransition);

      const turnoverData = yield call(getTurnoverData, {
        currentExercice,
        transition,
        currentCompanyId,
      });

      yield put(getTurnoverDataSuccess({ data: turnoverData }));
    } else {
      throw Error('currentExercice && currentCompanyId not found');
    }
  } catch (e) {
    yield put(getTurnoverDataError({ error: e }));
  }
}

export function* turnoverMainSaga() {
  yield takeLatest(GET_TURNOVER_DATA_REQUEST, getTurnoverDataWorker);
}
