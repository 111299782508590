import React from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import { Modal, Select } from 'antd';
import { Button } from 'components/ui/Button';
import PropTypes from 'prop-types';
import { ADDITIONAL_USERS_MODAL_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import { validateEmail } from 'modules/additionalUsers/helpers';
import { ADDITIONAL_USER_ROLES_LABELS } from 'const/packages';
import {
  validateUserModules,
  verifyPayModulesDisabled,
} from 'helpers/additionalUsersSelector';

import {
  MODAL_ERRORS,
  MODAL_ERRORS_BY_STATUS,
} from '../../const/additionalUsers';
import Styles from './AdditionalUserModal.module.css';
import { AdditionalUserModalError } from './AdditionalUserModalError';

const { Option } = Select;

const propTypes = {
  initialFormValues: PropTypes.object.isRequired,
  resetInitialFormValues: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onCreateUserSubmit: PropTypes.func,
  userPossibleModules: PropTypes.array,
};

const {
  CANCEL_BUTTON,
  CONFIRM_BUTTON,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  MODULES_LABEL,
  POSITION_LABEL,
  POSITION_PLACEHOLDER,
  NAME_LABEL,
  SURNAME_LABEL,
  SELECT_INPUT_TEXT,
  SELECT_PLACEHOLDER,
  TITLE,
} = ADDITIONAL_USERS_MODAL_TRANSLATIONS;
const { REQUIRED, WRONG_FORMAT, EMPTY_MOUDLES } = MODAL_ERRORS;

export const AdditionalUserModal = React.memo(
  ({
    initialFormValues,
    resetInitialFormValues,
    isVisible,
    closeModal,
    onCreateUserSubmit = () => {},
    userPossibleModules = [],
  }) => (
    <Modal
      centered
      destroyOnClose
      visible={isVisible}
      afterClose={resetInitialFormValues}
      footer={false}
      className={Styles.modal}
      closable={false}
      onCancel={closeModal}
    >
      <div className={Styles.modalTitle}>{TITLE}</div>
      <Formik
        initialValues={{
          name: initialFormValues.name,
          surname: initialFormValues.surname,
          email: initialFormValues.email,
          position: initialFormValues.position,
          tableModules: initialFormValues.tableModules,
        }}
        enableReinitialize
        onSubmit={onCreateUserSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = REQUIRED;
          }
          if (values.email && !validateEmail(values.email)) {
            errors.email = WRONG_FORMAT;
          }
          if (!values.position) {
            errors.position = REQUIRED;
          }
          if (!values.name) {
            errors.name = REQUIRED;
          }
          if (!values.surname) {
            errors.surname = REQUIRED;
          }
          if (!values.tableModules.length) {
            errors.tableModules = EMPTY_MOUDLES;
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
          setErrors,
        }) => {
          const onSelectChange = (value) => {
            setFieldValue('tableModules', validateUserModules(value, values));
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className={Styles.modalInputWrapper}>
                <label htmlFor="AddUserName" className={Styles.modalInputLabel}>
                  {NAME_LABEL}
                </label>
                <input
                  type="text"
                  placeholder={NAME_LABEL}
                  id="AddUserName"
                  value={values.name}
                  name="name"
                  onFocus={() => setErrors({})}
                  onChange={handleChange}
                  className={clsx(Styles.modalInput, {
                    [Styles.modalInputError]: errors.name,
                  })}
                />
                {errors.name && (
                  <AdditionalUserModalError
                    errorMessage={MODAL_ERRORS_BY_STATUS[errors.name]}
                  />
                )}
              </div>
              <div className={Styles.modalInputWrapper}>
                <label
                  htmlFor="AddUserSurname"
                  className={Styles.modalInputLabel}
                >
                  {SURNAME_LABEL}
                </label>
                <input
                  type="text"
                  placeholder={SURNAME_LABEL}
                  id="AddUserSurname"
                  value={values.surname}
                  name="surname"
                  onFocus={() => setErrors({})}
                  onChange={handleChange}
                  className={clsx(Styles.modalInput, {
                    [Styles.modalInputError]: errors.surname,
                  })}
                />
                {errors.surname && (
                  <AdditionalUserModalError
                    errorMessage={MODAL_ERRORS_BY_STATUS[errors.surname]}
                  />
                )}
              </div>
              <div className={Styles.modalInputWrapper}>
                <label htmlFor="AddUserMail" className={Styles.modalInputLabel}>
                  {EMAIL_LABEL}
                </label>
                <input
                  type="text"
                  id="AddUserMail"
                  name="email"
                  placeholder={EMAIL_PLACEHOLDER}
                  onFocus={() => setErrors({})}
                  disabled={false}
                  value={values.email}
                  onChange={handleChange}
                  className={clsx(Styles.modalInput, {
                    [Styles.modalInputError]: errors.email,
                  })}
                />
                {errors.email && (
                  <AdditionalUserModalError
                    errorMessage={MODAL_ERRORS_BY_STATUS[errors.email]}
                  />
                )}
              </div>
              <div className={Styles.modalInputWrapper}>
                <label
                  htmlFor="AddUserPosition"
                  className={Styles.modalInputLabel}
                >
                  {POSITION_LABEL}
                </label>
                <input
                  type="text"
                  placeholder={POSITION_PLACEHOLDER}
                  id="AddUserPosition"
                  value={values.position}
                  name="position"
                  onFocus={() => setErrors({})}
                  onChange={handleChange}
                  className={clsx(Styles.modalInput, {
                    [Styles.modalInputError]: errors.position,
                  })}
                />
                {errors.position && (
                  <AdditionalUserModalError
                    errorMessage={MODAL_ERRORS_BY_STATUS[errors.position]}
                  />
                )}
              </div>
              <div className={Styles.modalInputWrapper}>
                <div className={Styles.modalInputLabel}>{MODULES_LABEL}</div>
                {errors.tableModules && (
                  <AdditionalUserModalError
                    errorMessage={MODAL_ERRORS_BY_STATUS[errors.tableModules]}
                    isAboveField
                  />
                )}
                <Select
                  mode="multiple"
                  name="tableModules"
                  value={values.tableModules}
                  className={Styles.select}
                  showArrow
                  onChange={onSelectChange}
                  dropdownClassName={Styles.selectDropdown}
                  maxTagPlaceholder={(omittedValues) => (
                    <div>
                      {SELECT_INPUT_TEXT}
                      {' : '}
                      {omittedValues.length}
                    </div>
                  )}
                  placeholder={SELECT_PLACEHOLDER}
                  maxTagCount={0}
                >
                  {userPossibleModules.map((module) => (
                    <Option
                      disabled={verifyPayModulesDisabled(
                        values.tableModules,
                        module
                      )}
                      value={module}
                      key={module}
                    >
                      {ADDITIONAL_USER_ROLES_LABELS[module] || ''}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={Styles.modalButtonWrapper}>
                <Button
                  variant="secondary"
                  className={Styles.modalCancelButton}
                  onClick={closeModal}
                >
                  {CANCEL_BUTTON}
                </Button>
                <Button variant="success" type="submit">
                  {CONFIRM_BUTTON}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  )
);

AdditionalUserModal.propTypes = propTypes;
