import {
  SALARY_HEADER_LABELS,
  SALARY_HOMEPAGE_DESCRIPTIONS,
} from '../translations';
import employeesImage from '../../assets/images/salary-employees.png';
import newEmployeeImage from '../../assets/images/salary-newEmployee.png';
import rewardsImage from '../../assets/images/salary-rewards.png';
import calendarImage from '../../assets/images/salary-calendar.png';
import settingsImage from '../../assets/images/salary-settings.png';
import { ROUTES } from '../index';
import contactMyExco from '../../assets/images/CardsLogos/contact-my_exco.png';
import { openModal } from '../../modules/ModalContainer/actions';
import { MODAL_TYPES } from '../ModalContainer';

export const PRODUCT_CARDS = [
  {
    title: SALARY_HEADER_LABELS.EXCO_SNIFI,
    path: ROUTES.EMPLOYEE_REGISTER_TITLE,
    image: newEmployeeImage,
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.EXCO_SNIFI,
  },
  {
    title: SALARY_HEADER_LABELS.EMPLOYEES,
    path: ROUTES.SALARIES_EMPLOYEES,
    image: employeesImage,
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.EMPLOYEES,
  },
  {
    title: SALARY_HEADER_LABELS.REWARDS,
    path: ROUTES.SALARIES_REWARDS,
    image: rewardsImage,
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.REWARDS,
  },
  {
    title: SALARY_HEADER_LABELS.CALENDAR,
    path: ROUTES.SALARIES_CALENDAR,
    image: calendarImage,
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.CALENDAR,
  },
  {
    title: SALARY_HEADER_LABELS.COMPANY_SETTINGS,
    path: ROUTES.SALARIES_COMPANY_SETTINGS,
    image: settingsImage,
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.COMPANY_SETTINGS,
  },
  {
    title: SALARY_HEADER_LABELS.CONTACT_HR,
    image: contactMyExco,
    action: () => openModal({ type: MODAL_TYPES.contactHR }),
    paragraph: SALARY_HOMEPAGE_DESCRIPTIONS.CONTACT_HR,
  },
];
