import { getMonthIndexFromName } from 'helpers/monthSelector';
import { MONTHS } from 'const/ui';

export const getNumberOfDaysInMonth = (year, month) => {
  const d = new Date(year, month, 0);
  return d.getDate();
};

// Returns array of 12 month`s labels for charts from first month
// e.g ('November') => [nov,dec,jan ,..., oct]
export const get12MonthArrayFromFirstMonth = (firstMonthName) => {
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  const resultArray = [];
  for (let i = firstMonthIndex; i < MONTHS.length; i += 1) {
    resultArray.push(MONTHS[i].shortLabel.toLowerCase());
  }
  for (let i = 0; i < firstMonthIndex; i += 1) {
    resultArray.push(MONTHS[i].shortLabel.toLowerCase());
  }
  return resultArray;
};

export const getLineGradient = (
  canvas,
  color = 'rgba(0,0,0,1)',
  gradientHeight
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, gradientHeight);
  gradient.addColorStop(0, color);
  gradient.addColorStop(0.660606, 'rgba(255, 255, 255,0.5)');
  gradient.addColorStop(1, 'rgba(255, 255, 255,0.1)');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, 300, 300);
  return gradient;
};

const makeFirstLetterCapital = (str) => {
  if (typeof str === 'string') {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str;
};

/**
 * Returns real year value (calculated from fiscal year value with different time ranges)
 * @param year - fiscal year value
 * @param monthChartIndex - tooltip month index on chart (CHART ORDER INDEX, not MONTHS in YEAR INDEX)
 * @param firstMonth - first month of fiscal year
 */
export const getRealYearValueFromFiscal = (
  year,
  monthChartIndex,
  firstMonth
) => {
  const firstMonthIndex = getMonthIndexFromName(firstMonth);
  // if first month is January, then fiscal year match real year, no need to check or change anything else
  if (firstMonthIndex === 0) {
    return year;
  }
  // 12 months in year
  if (monthChartIndex + firstMonthIndex + 1 <= 12) {
    return year - 1;
  }
  return year;
};

// Function get labels for chart`s tooltip
// for Annual view mode returns Month YYYY value e.g "Sept 2019 3800"
// for Monthly view mode returns dd.mm.yy value
/** @param chartItem {object} - chartJs item
 * @param activeMonthName {string} - on of Month name currently chosen in Month Selector
 * @param activeYear {number} -  currently chosen year
 * @param data {Array<object>} - chart data with following structure [{year: number, values: Array<number>}]
 * @param isViewModeAnnual {boolean} - is chart date mode Annual
 * @param showLastDayOfMonthOnTooltipInAnnualMode -  when enabled: instead of Month Year label will be dd.mm.yyyy in tooltip
 *                                                    e.g. instead Fevr 2019  -> 28.02.2019
 * @param firstMonth - used to check if year in tooltip should be decreased
 *                     (tooltip should include real year, not fiscal year)
 * */
export const getTooltipLabel = (
  chartItem,
  activeMonthName,
  activeYear,
  data,
  isViewModeAnnual,
  showLastDayOfMonthOnTooltipInAnnualMode = false,
  firstMonth
) => {
  // index - dataset index in data array
  const index = chartItem.datasetIndex;
  let year;
  switch (index) {
    case 1: {
      year = activeYear;
      break;
    }
    case 2: {
      year = activeYear - 1;
      break;
    }
    default:
      year = activeYear - 2;
  }
  // turn fiscal year value into real year value, if necessary
  year = getRealYearValueFromFiscal(year, chartItem.index, firstMonth);
  // if ViewMode is Annual and on every month hover we need to show last day of month user hovered on
  if (isViewModeAnnual && showLastDayOfMonthOnTooltipInAnnualMode) {
    const { label } = chartItem;
    const monthIndex =
      MONTHS.findIndex((month) => month.shortLabel.toLowerCase() === label) + 1;
    const day = getNumberOfDaysInMonth(activeYear, monthIndex);
    const month = String(monthIndex).padStart(2, '0');
    return `${day}.${month}.${year}   ${chartItem.value}`;
  }
  // if ViewMode is Monthly show hovered day "dd.mm.yyyy value"
  if (!isViewModeAnnual) {
    const date = chartItem.xLabel;
    const month = String(getMonthIndexFromName(activeMonthName) + 1).padStart(
      2,
      '0'
    );
    return `  ${date}.${month}.${year}   ${chartItem.value}`;
  }
  // if ViewMode just Annual show "Month YYYY value" e.g. "Fevr 2021  3000"
  return `  ${makeFirstLetterCapital(chartItem.xLabel)} ${year}   ${
    chartItem.value
  }`;
};

export const getArrayFromOneToNumber = (number) =>
  [...new Array(number)].reduce((prev, item, index) => {
    if (index === 0) {
      prev.push(1);
      return prev;
    }
    prev.push(prev[index - 1] + 1);
    return prev;
  }, []);
