import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { Spinner } from 'components/Spinner';
import { STATUS_LOADING } from 'const';
import {
  setLeaveModalMainData,
  toggleVacationModalVisibility,
} from 'modules/SalaryModule/Vacations/actions';
import { selectActiveBranchData } from 'modules/SalaryModule/CompanySettings/selectors';
import {
  changeEmployeesFilters,
  resetEmployeesFilters,
  setDateRange,
  setEmployeeSchedule,
  setEmployeesSearch,
} from 'modules/SalaryModule/Employees/actions';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { FiltersToolbar } from 'components/SalaryComponents/FiltersToolbar';
import {
  selectClassificationOptions,
  selectContractOptions,
  selectDateRange,
  selectEmployeesStatus,
  selectFilteredEmployees,
  selectFilters,
  selectIsFilterChosen,
  selectNatureEmploymentOptions,
  selectSearchTerm,
} from 'modules/SalaryModule/Employees/selectors';
import { useSimpleMedia } from 'hooks/useMedia';
import {
  selectCalendarDateFilter,
  selectFilteredCalendarEmployees,
} from 'modules/SalaryModule/Calendar/selectors';
import { getCalendarAbsencesRequest } from 'modules/SalaryModule/Calendar/actions';
import Styles from './EmployeesPage.module.css';
import { EmployeeTable } from './EmployeeTable';
import { EmployeeList } from './EmployeeList';
import { NoDataFound } from './NoDataFound';
import { TimeTableModal } from './TimeTableModal';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 796;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const EmployeesPage = memo(() => {
  const dispatch = useDispatch();
  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleActiveMember, setScheduleActiveMember] = useState(null);
  const [isTimeModalVisible, setTimeModalVisibility] = useState(false);
  const companyId = useSelector(selectUserCompanyId);
  const loadingStatus = useSelector(selectEmployeesStatus);
  const natureEmploymentsOptions = useSelector(selectNatureEmploymentOptions);
  const classificationsOptions = useSelector(selectClassificationOptions);
  const contractsOptions = useSelector(selectContractOptions);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const employees = useSelector(selectFilteredEmployees);
  const isFiltersChosen = useSelector(selectIsFilterChosen);

  const filters = useSelector(selectFilters);
  const searchTerm = useSelector(selectSearchTerm);
  const dateRange = useSelector(selectDateRange);
  const activeBranchData = useSelector(selectActiveBranchData);

  useScrollToTop();

  useEffect(() => {
    if (activeBranchData) {
      dispatch(
        changeEmployeesFilters({
          establishments: activeBranchData.siret || '',
        })
      );
    }
  }, [dispatch, activeBranchData]);

  const hasFilters = useMemo(
    () => Object.values(filters).filter((item) => item !== '').length > 0,
    [filters]
  );

  const onSelectChange = useCallback(
    (name, value) => {
      dispatch(
        changeEmployeesFilters({
          [name]: value || '',
        })
      );
    },
    [dispatch]
  );

  const onSearchChange = useCallback(
    (e) => {
      dispatch(setEmployeesSearch(e.target.value));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values) => {
      dispatch(changeEmployeesFilters(values));
    },
    [dispatch]
  );

  const handleEmployeeScheduleChange = useCallback(() => {
    if (scheduleId && scheduleActiveMember && companyId) {
      dispatch(
        setEmployeeSchedule({
          companyId,
          scheduleId,
          employeeId: scheduleActiveMember.employeeid,
        })
      );
      setTimeModalVisibility(false);
    }
  }, [dispatch, scheduleId, scheduleActiveMember, companyId]);

  const resetFilters = useCallback(() => {
    dispatch(resetEmployeesFilters());
  }, [dispatch]);

  const resetSearch = useCallback(() => {
    dispatch(setEmployeesSearch(''));
  }, [dispatch]);

  const onRangeChange = useCallback(
    ([startDate, endDate]) => {
      dispatch(setDateRange({ startDate, endDate }));
    },
    [dispatch]
  );

  const openTimeModal = useCallback(
    (e) => {
      const { employeeid } = e.currentTarget.dataset;
      if (employeeid) {
        const employeeData = employees.find(
          (employee) => employee.employeeid === employeeid
        );
        setScheduleActiveMember(employeeData);
        setScheduleId(employeeData.schedule);
      }
      setTimeModalVisibility(true);
    },
    [employees]
  );

  const filteredEmployees = useSelector(selectFilteredCalendarEmployees);

  const monthFilter = useSelector(selectCalendarDateFilter);
  useEffect(() => {
    dispatch(getCalendarAbsencesRequest({ date: monthFilter, companyId }));
  }, [monthFilter, dispatch, companyId]);

  const openVacationModal = useCallback(
    (e) => {
      const { reason, employeeid } = e.currentTarget.dataset;
      if (employees) {
        const employeeData = employees.find(
          (employee) => employee.employeeid === employeeid
        );

        if (employeeData) {
          dispatch(
            setLeaveModalMainData({
              employee: employeeData,
              otherEmployees: filteredEmployees,
              type: reason,
            })
          );
          dispatch(toggleVacationModalVisibility());
        }
      }
    },
    [dispatch, employees, filteredEmployees]
  );

  const closeTimeModal = useCallback(() => {
    setTimeModalVisibility(false);
  }, []);

  const renderEmployeesList = useCallback(() => {
    if (isFiltersChosen && employees.length === 0) {
      return <NoDataFound onResetFilters={resetFilters} />;
    }

    if (loadingStatus === STATUS_LOADING) {
      return <Spinner />;
    }

    return isMobileVersion ? (
      <EmployeeList
        employees={employees}
        openVacationModal={openVacationModal}
        openTimeModal={openTimeModal}
      />
    ) : (
      <EmployeeTable
        employees={employees}
        openVacationModal={openVacationModal}
        openTimeModal={openTimeModal}
      />
    );
  }, [
    loadingStatus,
    resetFilters,
    openTimeModal,
    openVacationModal,
    isMobileVersion,
    employees,
    isFiltersChosen,
  ]);

  return (
    <div className={Styles.container}>
      <FiltersToolbar
        natureEmploymentsOptions={natureEmploymentsOptions}
        classificationsOptions={classificationsOptions}
        contractsOptions={contractsOptions}
        filters={filters}
        searchTerm={searchTerm}
        dateRange={dateRange}
        hasFilters={hasFilters}
        onSelectChange={onSelectChange}
        onSearchChange={onSearchChange}
        onSubmit={onSubmit}
        onRangeChange={onRangeChange}
        resetSearch={resetSearch}
        companyId={companyId}
      />
      {renderEmployeesList()}

      <TimeTableModal
        isVisible={isTimeModalVisible}
        onClose={closeTimeModal}
        onSubmit={handleEmployeeScheduleChange}
        scheduleId={scheduleId}
        setScheduleId={setScheduleId}
        scheduleActiveMember={scheduleActiveMember}
      />
    </div>
  );
});
