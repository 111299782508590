export const FRENCH_ROUTES = {
  ROOT: '/',
  LOGIN: '/connexion',
  LOGOUT: '/deconnexion',
  UNSUBSCRIBE: '/desinscription',

  MY_EXCO_LANDING: '/bouquet-my-exco',
  EXPENSES_LANDING: '/mes-depenses-pay',
  SALES_LANDING: '/mes-ventes-fact',
  WAIBI_LANDING: '/indicateurs-premium-infos',
  TERMS_OF_USE_LANDING: '/cgu-my-exco',
  TERMS_OF_SUBSCRIPTION: '/conditions-souscription-my-exco',

  HELP: '/aide',
  EXPENSES: '/mes-depenses',
  DEBTORS: '/dettes-clients',
  DEBTOR: '/dettes-clients/:code',
  CREDITORS: '/creances',
  CREDITOR: '/creances/:code',
  EMPLOYEE_REGISTER_TITLE: '/creation-salarie',
  EMPLOYEE_REGISTER_FORM: '/creation-salarie/nouveau',
  UPLOAD_DOCUMENTS: '/chargement-documents',
  INVOICE_SEARCH: '/recherche-factures',
  DOCUMENTS_CABINET: '/dossier-partage',
  NOT_AUTHORIZED: '/non-autorise',
  CHANGE_PASSWORD: '/modification-mot-de-passe',
  TEMPORARY_CHANGE_PASSWORD: '/modification-mot-de-passe-provisoire',
  FORGOT_PASSWORD: '/mot-de-passe-oublie',
  PASSWORD_SENT: '/envoi-mot-de-passe',
  PASSWORD_CHANGED: '/mot-de-passe-modifie',
  CONTACTS: '/contacts',
  TURNOVER: '/chiffre-affaires-realise',
  TREASURY: '/tresorerie-disponible',
  HR: '/ressources-humaines',
  STORE: '/store',
  CART: '/panier',
  ADDITIONAL_USERS: '/utilisateurs-supplementaires',

  // Salaries module
  SALARIES_MAIN_PAGE: '/mes-salaries',
  SALARIES_EMPLOYEES: '/mes-salaries/employes',
  SALARIES_REWARDS: '/mes-salaries/variables-de-paie',
  SALARIES_CALENDAR: '/mes-salaries-calendrier',
  SALARIES_COMPANY_SETTINGS: '/mes-salaries-parametres-entreprise',
  SALARIES_SCHEDULE_EMPTY: '/mes-salaries-parametres-entreprise/horaires',
  SALARIES_COMPANY_SETTINGS_SCHEDULE:
    '/mes-salaries-parametres-entreprise/horaires/:id?',
  SALARIES_COMPANY_SETTINGS_REWARD_CREATE:
    '/mes-salaries-parametres-entreprise/variables-paie',
  SALARIES_COMPANY_SETTINGS_REWARD:
    '/mes-salaries-parametres-entreprise/variables-paie/:id?',
};
