import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
import {
  selectStoredPayload,
  selectInvoiceSearchResults,
} from 'modules/invoiceSearch/selectors';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';

const {
  TABLE: { TOTAL_ROW_TITLE, TOTAL_ROW_TITLE_SELECT, TOTAL_ROW_SUBTITLE },
} = INVOICE_SEARCH_TRANSLATIONS;
const { DELETED } = INVOICE_SEARCH_PAYMENT_STATUSES;

// TODO: rewrite to antd summary

export const useInvoiceSearchTableFooter = (selectedRowKeys) => {
  const [mgRight, setMgRight] = useState(195);
  const { totalTVA, totalHT, totalTTC } = useSelector(
    selectInvoiceSearchResults
  )?.reduce(
    (acc, invoice) => ({
      totalHT: acc.totalHT + invoice.amount,
      totalTVA: acc.totalTVA + invoice.vat,
      totalTTC: acc.totalTTC + invoice.amountWithVat,
    }),
    {
      totalHT: 0,
      totalTVA: 0,
      totalTTC: 0,
    }
  );

  const {
    vat: dynamicTotalTVA,
    amount: dynamicTotalHT,
    amountWithVat: dynamicTotalTTC,
  } = selectedRowKeys?.dynamicTotals || {};

  const totals = selectedRowKeys?.invoicesId?.length
    ? [dynamicTotalHT, dynamicTotalTVA, dynamicTotalTTC]
    : [totalHT, totalTVA, totalTTC];

  const { paymentStatus } = useSelector(selectStoredPayload);

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelector(
        'tbody > tr:nth-child(1) > td:nth-child(9)'
      );

      if (!element) {
        return;
      }

      setMgRight(
        element.offsetParent.offsetWidth -
          element.offsetLeft -
          element.offsetWidth -
          7
      );
    }, 400);
  }, [paymentStatus]);

  return (
    paymentStatus !== DELETED &&
    (() => (
      <div className={Styles.totalFooterWrapper}>
        <div className={Styles.totalFooterTitle}>
          <span>
            {selectedRowKeys?.invoicesId?.length
              ? TOTAL_ROW_TITLE_SELECT
              : TOTAL_ROW_TITLE}
          </span>
          <span>{TOTAL_ROW_SUBTITLE}</span>
        </div>
        <div
          style={{
            display: 'flex',
            marginRight: `${mgRight}px`,
          }}
        >
          {totals.map((value) => (
            <span className={Styles.totalFooterCell} key={uuidv4()}>
              {Number(value) ? getProperLookingAmount(value) : value}
            </span>
          ))}
        </div>
      </div>
    ))
  );
};
