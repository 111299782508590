import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Input } from 'components/ui/Input';
import { Icon } from 'antd';
import { MonthPicker } from 'components/ui/MonthPicker';
import { CustomSelect } from 'components/ui/CustomSelect';
import iconFilterList from 'assets/icons/filter-list.svg';
import { EyeCheckbox } from 'components/ui/EyeCheckbox';
import {
  setCalendarActiveBranch,
  setCalendarSearchInputValue,
} from 'modules/SalaryModule/Calendar/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCalendarBranchFilter,
  selectCalendarCompanyBranchesForSelect,
  selectCalendarSearchFilter,
} from 'modules/SalaryModule/Calendar/selectors';
import { SALARY_CALENDAR_TRANSLATIONS } from 'const/translations/SalaryCalendar';
import { CalendarMobileFiltersModal } from 'components/SalaryComponents/Calendar/CalendarMobileFilters/CalendarMobileFilters';
import { getScreenWidthBreakpointMedia } from 'utils';
import { formatNameUpperLastName } from 'modules/SalaryModule/Employees/utils';
import { useSimpleMedia } from 'hooks/useMedia';
import { selectActiveBranchData } from 'modules/SalaryModule/CompanySettings/selectors';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Radio } from 'components/ui';

import MainStyles from 'assets/styles/main.module.css';
import Styles from './CalendarFilters.module.css';

const {
  ESTABLISHMENT,
  HIDE_USERS_WITHOUT_ABSENCES,
  SEARCH_BY_NAME,
  SELECT_EMPLOYEE,
  YEAR_MONTH_SWITCH_CALENDAR,
} = SALARY_CALENDAR_TRANSLATIONS;

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const SCREEN_WIDTH_BREAKPOINT_MEDIA = getScreenWidthBreakpointMedia(650);

const propTypes = {
  withAbsencesOnly: PropTypes.bool.isRequired,
  setWithAbsencesOnly: PropTypes.func.isRequired,
  monthFilter: PropTypes.any.isRequired,
  setMonthFilter: PropTypes.func.isRequired,
  monthYearToggle: PropTypes.oneOf([YEAR, MONTH]).isRequired,
  setMonthYearToggle: PropTypes.func.isRequired,
  filteredEmployees: PropTypes.array.isRequired,
  calendarEmployee: PropTypes.string.isRequired,
  setCalendarEmployee: PropTypes.func.isRequired,
};

export const CalendarFilters = React.memo(
  ({
    withAbsencesOnly,
    setWithAbsencesOnly,
    monthFilter,
    setMonthFilter,
    monthYearToggle,
    setMonthYearToggle,
    filteredEmployees,
    calendarEmployee,
    setCalendarEmployee,
  }) => {
    const dispatch = useDispatch();

    const isMonthView = monthYearToggle === MONTH;

    const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

    const branches = useSelector(selectCalendarCompanyBranchesForSelect);
    const companySettingsActiveBranch = useSelector(selectActiveBranchData);
    useEffect(() => {
      if (companySettingsActiveBranch) {
        dispatch(
          setCalendarActiveBranch({
            branchId: companySettingsActiveBranch.siret,
          })
        );
        return;
      }
      dispatch(setCalendarActiveBranch({ branchId: '' }));
    }, [dispatch, companySettingsActiveBranch]);

    // todo set default branch from company settings
    const branchFilter = useSelector(selectCalendarBranchFilter);
    const branchSelectHandler = useCallback(
      (_, value) => {
        dispatch(setCalendarActiveBranch({ branchId: value }));
      },
      [dispatch]
    );

    const searchInputFilter = useSelector(selectCalendarSearchFilter);
    const onSearchInputChange = useCallback(
      (e) => {
        const { value } = e.target;
        dispatch(setCalendarSearchInputValue({ value }));
        if (value.length === 0) {
          dispatch(setCalendarSearchInputValue({ value: '' }));
        }
      },
      [dispatch]
    );

    const toggleOnlyAbsences = useCallback(() => {
      setWithAbsencesOnly(!withAbsencesOnly);
    }, [setWithAbsencesOnly, withAbsencesOnly]);

    // filterModal
    const [isFilterModalVisible, setModalVisibility] = useState(false);

    const toggleFilterModalVisibility = useCallback(() => {
      setModalVisibility((prev) => !prev);
    }, [setModalVisibility]);

    const onFilterModalSubmit = useCallback(
      (values) => {
        dispatch(setCalendarActiveBranch({ branchId: values.activeBranch }));
        setWithAbsencesOnly(values.withAbsencesOnly);
        setCalendarEmployee('employee', values.employee);
        toggleFilterModalVisibility();
      },
      [
        dispatch,
        setCalendarEmployee,
        setWithAbsencesOnly,
        toggleFilterModalVisibility,
      ]
    );

    const filterModalInitialValues = useMemo(
      () => ({
        withAbsencesOnly,
        activeBranch: branchFilter,
      }),
      [withAbsencesOnly, branchFilter]
    );

    // mobile search input
    const [
      isMobileSearchInputVisible,
      setMobileSearchInputVisibility,
    ] = useState(false);

    const toggleMobileSearchInputVisibility = useCallback(() => {
      setMobileSearchInputVisibility((prev) => !prev);
      dispatch(setCalendarSearchInputValue({ value: '' }));
    }, [setMobileSearchInputVisibility, dispatch]);

    const toggleMonthYearToggle = useCallback(
      ({ target: { value } }) => {
        setMonthYearToggle(value);
        dispatch(setCalendarSearchInputValue({ value: '' }));
      },
      [dispatch, setMonthYearToggle]
    );

    const searchInputRef = useRef();

    useEffect(() => {
      if (searchInputRef && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [searchInputRef, isMobileSearchInputVisible]);

    const hasFilters = useMemo(() => withAbsencesOnly || !!branchFilter, [
      withAbsencesOnly,
      branchFilter,
    ]);

    return (
      <div className={Styles.filtersRow}>
        <CalendarMobileFiltersModal
          isVisible={isFilterModalVisible}
          onSubmit={onFilterModalSubmit}
          onClose={toggleFilterModalVisibility}
          branches={branches}
          initialValues={filterModalInitialValues}
          filteredEmployees={filteredEmployees}
          isMonthCalendar={isMonthView}
        />
        {isMobileSearchInputVisible && isMobileVersion && isMonthView && (
          <Input
            ref={searchInputRef}
            placeholder={SEARCH_BY_NAME}
            prefix={
              <Icon
                size="large"
                type="search"
                className={Styles.searchIconPrefix}
              />
            }
            className={Styles.searchInputMobile}
            onChange={onSearchInputChange}
            value={searchInputFilter}
            allowClear
          />
        )}
        <div className={Styles.monthPickerAndSelect}>
          <Radio
            options={YEAR_MONTH_SWITCH_CALENDAR}
            value={monthYearToggle}
            className={clsx(MainStyles.radioGroup, Styles.yearMonthSwith)}
            onChange={toggleMonthYearToggle}
          />
          <MonthPicker
            value={monthFilter}
            setValue={setMonthFilter}
            className={Styles.monthPicker}
            onlyYear={!isMonthView}
            isMobileVersion={isMobileVersion}
            isYearCalendar={!isMonthView}
          />
          <CustomSelect
            size="large"
            name="branches"
            className={Styles.select}
            options={branches}
            onChange={branchSelectHandler}
            value={branchFilter}
            placeholder={ESTABLISHMENT}
            allowClear
            hasPaleLabel
            hasTooltip
          />
          {isMobileVersion && (
            <div className={Styles.iconsWrapper}>
              <div
                className={Styles.filterIconWrapper}
                onClick={toggleFilterModalVisibility}
              >
                {hasFilters && <div className={Styles.filterIconBadge}></div>}
                <img src={iconFilterList} alt="icon-filter-list" />
              </div>
              {isMonthView && (
                <div
                  className={Styles.searchIconWrapper}
                  onClick={toggleMobileSearchInputVisibility}
                >
                  <Icon type="search" className={Styles.searchIcon} />
                </div>
              )}
            </div>
          )}
        </div>
        {!isMobileVersion && isMonthView && (
          <div className={Styles.searchAndCheckbox}>
            <Input
              size="large"
              allowClear
              placeholder={SEARCH_BY_NAME}
              prefix={
                <Icon type="search" className={Styles.searchIconPrefix} />
              }
              onChange={onSearchInputChange}
              value={searchInputFilter}
              className={Styles.searchInput}
            />
            <EyeCheckbox
              isMinified
              onChange={toggleOnlyAbsences}
              id="CalendarCheckbox"
              checked={withAbsencesOnly}
              label={HIDE_USERS_WITHOUT_ABSENCES}
              className={Styles.checkBox}
            />
          </div>
        )}
        {!isMobileVersion && !isMonthView && (
          <div className={Styles.searchAndCheckbox}>
            <CustomSelect
              size="large"
              name="employee"
              placeholder={SELECT_EMPLOYEE}
              onChange={setCalendarEmployee}
              value={calendarEmployee}
              options={filteredEmployees.map((employee) => ({
                label: formatNameUpperLastName(
                  employee.firstname1,
                  employee.lastname
                ),
                value: employee.employeeid,
              }))}
              className={Styles.selectEmployee}
            />
          </div>
        )}
      </div>
    );
  }
);

CalendarFilters.propTypes = propTypes;
