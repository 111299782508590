import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  groupObjectsByProperty,
  randomInteger,
} from 'modules/additionalUsers/helpers';
import { Modal } from 'antd';
import MainStyles from 'assets/styles/main.module.css';
import { ADDITIONAL_USERS_PAGE_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';

const {
  CONFIRM_MODAL_CANCEL,
  CONFIRM_MODAL_OK_TEXT,
  CONFIRM_MODAL_TITLE,
} = ADDITIONAL_USERS_PAGE_TRANSLATIONS;

/**
 * hook that returns states and callbacks for using additional users table
 * @param additionalUsers {object} -> additionalUsersDataset with structure {userId: {email, position, packages:[]}}
 * @param localStorageDatasetKey {string} -> local storage key to save dataset when it`s changed
 * @param deletedUsersLocalStorageKey {string} -> local storage key to save deleted users
 *
 * RETURNS addNewUserToTable {function} -> adds new user to table
 *         removeUserFromDataset {function} -> removes user from additionalUserDataset without changing current state of editable additional users table
 *         resetChanges {function} -> resets localstorage data, deleted users and additionalUsersDataset
 *         deletedUsers {array} -> array of current deleted users from dataset
 *         setDeletedUsers {function} -> setState for deletedUsers
 *         addDeletedUser {function} -> callback to addDeletedUser to localStorage and deletedUsers state
 *                                      (is using in removeUserFromDataset function)
 *         additionalUserDataset {object} -> same structure as additionalUses(argument): contains either stored locally changes,
 *                                         which persist through reload and can be either sent to backend and saved or initial additional users values.
 *         setAdditionalUserDataset -> setState for additionalUserDataset
 *         initialAdditionalUserDataset {object} -> same structure as additionalUsers, is used to reset
 *                                                      additionalUserDataset to initial values after localStorage is cleared
 * */
export const useAdditionalUsersTable = (
  additionalUsers,
  localStorageDatasetKey,
  deletedUsersLocalStorageKey
) => {
  const initialAdditionalUsersDataset = useMemo(
    () => groupObjectsByProperty(additionalUsers),
    [additionalUsers]
  );

  const [unsynchronizedUserDataset, setUnsynchronizedUserDataset] = useState(
    getFromStorage({ key: localStorageDatasetKey, shouldBeParsed: true }) || {}
  );

  useEffect(() => {
    setUnsynchronizedUserDataset((currentStorageDataset) =>
      Object.values(currentStorageDataset).length > 0 ||
      getFromStorage({ key: deletedUsersLocalStorageKey })
        ? currentStorageDataset || {}
        : initialAdditionalUsersDataset
    );
  }, [
    setUnsynchronizedUserDataset,
    initialAdditionalUsersDataset,
    deletedUsersLocalStorageKey,
  ]);

  const [deletedUsers, setDeletedUsers] = useState(
    getFromStorage({
      key: deletedUsersLocalStorageKey,
      shouldBeParsed: true,
    }) || []
  );
  const addDeletedUser = useCallback(
    (deletedUser) => {
      setDeletedUsers((prevDeletedUsers) => {
        if (deletedUser.isUserNew) {
          return prevDeletedUsers;
        }
        const newDeletedUsersDataset = [...prevDeletedUsers, deletedUser];
        setToStorage({
          key: deletedUsersLocalStorageKey,
          value: newDeletedUsersDataset,
        });
        return newDeletedUsersDataset;
      });
    },
    [setDeletedUsers, deletedUsersLocalStorageKey]
  );

  const resetChanges = useCallback(() => {
    removeFromStorage({
      key: [localStorageDatasetKey, deletedUsersLocalStorageKey],
    });
    setDeletedUsers([]);
    setUnsynchronizedUserDataset(initialAdditionalUsersDataset);
  }, [
    localStorageDatasetKey,
    deletedUsersLocalStorageKey,
    initialAdditionalUsersDataset,
    setDeletedUsers,
    setUnsynchronizedUserDataset,
  ]);

  const removeUserFromDataset = useCallback(
    (e) => {
      const { userId } = e.currentTarget.dataset;
      const { email } = e.currentTarget.dataset;
      Modal.confirm({
        title: CONFIRM_MODAL_TITLE.replace('{{EMAIL}}', email),
        icon: false,
        className: MainStyles.modal,
        okText: CONFIRM_MODAL_OK_TEXT,
        okButtonProps: {
          className: MainStyles.modalOkButton,
        },
        autoFocusButton: null,
        cancelText: CONFIRM_MODAL_CANCEL,
        cancelButtonProps: {
          className: MainStyles.modalCancelButton,
        },
        onOk() {
          setUnsynchronizedUserDataset((prevDataset) => {
            const savedDataset = getFromStorage({
              key: localStorageDatasetKey,
              shouldBeParsed: true,
            });
            const newDataset = savedDataset || { ...prevDataset };

            addDeletedUser(newDataset[userId]);
            delete newDataset[userId];

            setToStorage({
              key: localStorageDatasetKey,
              value: newDataset,
            });
            return newDataset;
          });
        },
        onCancel() {},
      });
    },
    [setUnsynchronizedUserDataset, addDeletedUser, localStorageDatasetKey]
  );

  const addNewUserToTable = useCallback(
    (formValues) => {
      let newUserId;
      do {
        const usersDatasetIds = Object.keys(unsynchronizedUserDataset);
        const lastId = usersDatasetIds[usersDatasetIds.length - 1];
        newUserId = `${lastId}${randomInteger(0, 100)}`;
      } while (unsynchronizedUserDataset[newUserId]);

      setUnsynchronizedUserDataset(() => {
        const savedDataset =
          getFromStorage({
            key: localStorageDatasetKey,
            shouldBeParsed: true,
          }) || {};

        return {
          ...savedDataset,
          [newUserId]: {
            ...formValues,
            isUserNew: true,
            id: newUserId,
          },
        };
      });
    },
    [
      unsynchronizedUserDataset,
      setUnsynchronizedUserDataset,
      localStorageDatasetKey,
    ]
  );

  return {
    addNewUserToTable,
    removeUserFromDataset,
    resetChanges,
    deletedUsers,
    addDeletedUser,
    setDeletedUsers,
    unsynchronizedUserDataset,
    setUnsynchronizedUserDataset,
    initialAdditionalUsersDataset,
  };
};
