import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import clsx from 'clsx';
import SpinnerCircle from 'assets/icons/spinner.svg';
import Styles from './Spinner.module.css';

const propTypes = {
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  circle: PropTypes.bool,
};

export const Spinner = ({ className, circle, ...restProps }) =>
  circle ? (
    <div className={clsx(className)}>
      <img src={SpinnerCircle} alt="" className={Styles.spinner} />
    </div>
  ) : (
    <div className={clsx(Styles.spinnerWrapper, className)}>
      <Spin {...restProps} />
    </div>
  );

Spinner.propTypes = propTypes;
