import { INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
const {
  DELETED,
  PAID_OUTSIDE,
  ACCEPTED,
  PREVALIDATED,
  NOT_VALIDATED,
} = INVOICE_SEARCH_PAYMENT_STATUSES;

/**
 * @typedef {Object} StatusAndFlags
 * @property {string} paymentStatus Backend-acceptable payment status.
 * @property {boolean} deleted Tells if requested invoices are deleted.
 * @property {boolean} paidOutside Tells if requested invoices are paid outside.
 */

/**
 * Creates an object with correct payment status
 * and flags to make requests with.
 * @param {string} paymentStatus Any status from INVOICE_SEARCH_PAYMENT_STATUSES const.
 * @returns {StatusAndFlags} Object with flags and status.
 */
export const separateStatusFromFlags = (paymentStatus) => {
  const isDeleted = paymentStatus === DELETED || undefined;
  const paidOutside =
    paymentStatus === ACCEPTED
      ? false
      : paymentStatus === PAID_OUTSIDE || undefined;
  const preValidated =
    paymentStatus === NOT_VALIDATED
      ? false
      : paymentStatus === PREVALIDATED || undefined;

  const newPaymentStatus =
    isDeleted || paidOutside || preValidated ? undefined : paymentStatus;

  return {
    paymentStatus: newPaymentStatus,
    deleted: isDeleted,
    paidOutside,
    preValidated,
  };
};
