import React from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, Row } from 'antd';
import Styles from 'components/MainFooter/MainFooter.module.css';
import MainStyles from 'assets/styles/main.module.css';
import { FOOTER, ROUTES } from 'const';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import logoFooter from 'assets/images/logo-footer.png';
import {
  EXCO_ADDRESS,
  EXCO_SOCIALS,
  FOOTER_SECTIONS,
} from '../../const/footer';

const propTypes = {
  userModules: PropTypes.array,
  currentCompanyActivated: PropTypes.bool,
  isMainUser: PropTypes.bool,
};

const { Footer } = Layout;

export const MainFooter = ({
  userModules,
  currentCompanyActivated,
  isMainUser,
}) => (
  <Footer className={Styles.footer}>
    <Row className={clsx(MainStyles.container, Styles.footerWrapper)}>
      <Col
        sm={12}
        xl={6}
        xs={{ order: FOOTER_SECTIONS.length + 1 }}
        className={clsx(Styles.column, Styles.logoAndAddressContainer)}
      >
        <Link to={ROUTES.ROOT}>
          <img
            className={Styles.footerLogo}
            src={logoFooter}
            alt="EXCO footer logo"
          />
        </Link>
        <div className={Styles.addressWrapper}>
          {Object.values(EXCO_ADDRESS).map((addressPart) => (
            <span key={addressPart}>{addressPart}</span>
          ))}
        </div>
      </Col>
      {FOOTER_SECTIONS.map((section) => (
        <Col sm={12} xl={6} key={section.TITLE} className={Styles.column}>
          <span className={Styles.columnTitle}>{section.TITLE}</span>
          <div className={Styles.linksWrapper}>
            {section.LINKS.map(
              ({ EXTERNAL, LINK, NAME, verifyHidden, activeCompanyOnly }) => {
                if (activeCompanyOnly && !currentCompanyActivated) {
                  return null;
                }
                if (verifyHidden && verifyHidden(userModules, isMainUser)) {
                  return null;
                }
                return EXTERNAL ? (
                  <a
                    key={NAME}
                    href={LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {NAME}
                  </a>
                ) : (
                  <Link key={NAME} to={LINK}>
                    {NAME}
                  </Link>
                );
              }
            )}
          </div>
        </Col>
      ))}
    </Row>
    <Row className={clsx(Styles.bottomFooter, MainStyles.container)}>
      <Col sm={12} xs={24} className={Styles.copyRight}>
        <span>{FOOTER.EXCO_SIGN.replace('{{YEAR}}', moment().year())}</span>
      </Col>
      <Col sm={12} xs={24} className={Styles.iconsWrapper}>
        {Object.entries(EXCO_SOCIALS).map(([socialsKey, { LINK, ICON }]) => (
          <a
            key={socialsKey}
            href={LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ICON} alt={`${socialsKey.toLowerCase()}-icon`} />
          </a>
        ))}
      </Col>
    </Row>
  </Footer>
);

MainFooter.propTypes = propTypes;
