import React, { useMemo } from 'react';
import { Bar, Chart as ChartJS } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-style';
import { roundedBarCorners } from 'components/Charts/BarChart/roundedBarCorners';
import { useChartSupportValues } from 'hooks/useChartSupportValues';
import { getTooltipLabel } from 'helpers/charts';
import commentImage from 'assets/icons/comment.png';
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { useSimpleMedia } from 'hooks/useMedia';
import { HR_TABS_IDS } from 'const/HRPage';

const { CA, TOTAL_COST } = HR_TABS_IDS;

const semiTransparentColors = {
  0: 'rgba(10,169,188,0.5)',
  1: 'rgba(162,205,111,0.5)',
  2: 'rgba(184,184,184,0.5)',
};

// This function adds to the chart border-radius option
ChartJS.elements.Rectangle.prototype.draw = roundedBarCorners;

ChartJS.Tooltip.positioners.cursor = (_, coordinates) => coordinates;

const propTypes = {
  data: PropTypes.array.isRequired,
  hiddenLinesYears: PropTypes.array,
  activeMonthName: PropTypes.string.isRequired,
  activeYear: PropTypes.number.isRequired,
  activeDateViewOption: PropTypes.string.isRequired,
  firstMonth: PropTypes.string.isRequired,
  enableGrid: PropTypes.bool,
  comments: PropTypes.array,
  openCommentsModal: PropTypes.func,
  dashboardName: PropTypes.string,
  activeTabId: PropTypes.string,
  showLastDayOfMonthOnTooltipInAnnualMode: PropTypes.bool,
};

const commentsImage = new Image();
commentsImage.src = commentImage;
commentsImage.width = 20;
commentsImage.height = 20;

const SCREEN_WIDTH_BREAKPOINT_VALUE = 950;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

/**
 * @param data {array} - data for chart with following
 *                      structure [{year:number, values:Array<number>>}]
 *                  if its Year View values starts from firstmonth
 *                  e.g first month sept -> values [ 0,  1,  2, ... , 11]
 *                                                 sep, oct,nov,      aug
 * @param hiddenLinesYears {array<Number>}
 * @param activeMonthName {string} - active month in MonthSelector from January to December
 * @param activeYear {number}
 * @param activeDateViewOption {string} - date mode either Annuels(YEAR) or Mensuels(MONTH) from const
 * @param firstMonth {string} - name of first month of financial year from January to December
 * @param comments {array} - array same as values of data above, but if comments exist value 0, if not value null
 * @param enableGrid {boolean} - is grid enabled
 * @param dashboardName {string} - one of 'treasury' 'turnover' 'debtors' 'creditors'
 * @param activeTabId {string} - current tab name, default ''
 * @param openCommentsModal {func} -> function that setup comments modal and opens it
 * @param showLastDayOfMonthOnTooltipInAnnualMode -  when enabled: instead of Month Year label will be dd.mm.yyyy in tooltip
 *                                                    e.g. instead Fevr 2019  -> 28.02.2019
 * */
export const BarChart = ({
  data = [],
  hiddenLinesYears = [],
  activeMonthName,
  activeYear,
  activeDateViewOption,
  firstMonth,
  enableGrid = false,
  comments = [],
  openCommentsModal = () => {},
  dashboardName = '',
  activeTabId = '',
  showLastDayOfMonthOnTooltipInAnnualMode = false,
  ...restProps
}) => {
  const {
    labels,
    setHoveredLineIndex,
    hoveredLineIndex,
    isViewModeAnnual,
  } = useChartSupportValues(
    activeDateViewOption,
    activeYear,
    activeMonthName,
    firstMonth
  );

  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  const dataWithComments = [{}, ...data];

  const minValueOfChartData = useMemo(() => {
    let min = 0;
    data.forEach(({ values }) => {
      values.forEach((value) => {
        if (value < min) min = value;
      });
    });
    return min;
  }, [data]);

  const commentChartValue = useMemo(() => {
    const needToAddTo1000 =
      minValueOfChartData % 1000 === 0
        ? 0
        : 1000 - Math.abs(minValueOfChartData % 1000);
    return minValueOfChartData - needToAddTo1000;
  }, [minValueOfChartData]);

  const yAxisValuesMin = useMemo(
    () =>
      minValueOfChartData < 0 && minValueOfChartData >= -1000
        ? { min: -1000 }
        : { min: undefined },
    [minValueOfChartData]
  );

  const chartData = () => ({
    labels,
    datasets: dataWithComments.map(({ id, year, values }, index) => {
      if (hiddenLinesYears.includes(year)) return { label: id, hidden: true };
      if (index === 0) {
        return {
          hidden: !!isMobileVersion,
          label: `comments${activeDateViewOption}${dashboardName}`,
          type: 'line',
          data: labels.map(() => commentChartValue),
          borderWidth: 0,
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          pointRadius: 6,
          pointHoverRadius: 6,
          pointStyle: commentsImage,
          pointBackgroundColor: labels.map((comment, commentIndex) => {
            if (comments[commentIndex] === 0) return 'rgba(0,0,0,1)';
            return 'rgba(0,0,0,0)';
          }),
          pointHoverBackgroundColor: 'rgba(0,0,0,1)',
        };
      }
      return {
        label: id,
        data: values,
        borderWidth: 0,
        borderColor: 'transparent',
        backgroundColor: semiTransparentColors[index - 1],
        hidden: false,
      };
    }),
  });

  return (
    <Bar
      {...restProps}
      data={chartData}
      getElementAtEvent={(elem) => {
        // eslint-disable-next-line no-underscore-dangle
        if (elem[0] && elem[0]._datasetIndex === 0) {
          if (labels.length === 12) {
            // eslint-disable-next-line no-underscore-dangle
            const monthDependsOfFirstMonth = elem[0]._index;
            const firstMonthIndex = getMonthIndexFromName(firstMonth);
            const month =
              firstMonthIndex + monthDependsOfFirstMonth > 11
                ? firstMonthIndex + monthDependsOfFirstMonth - 12
                : firstMonthIndex + monthDependsOfFirstMonth;
            openCommentsModal({
              month,
              day: null,
              chartType: dashboardName.toLowerCase(),
              dateMode: 'month',
            });
          } else {
            // eslint-disable-next-line no-underscore-dangle
            const day = elem[0]._index + 1;
            const month = getMonthIndexFromName(activeMonthName);
            openCommentsModal({
              month,
              day,
              chartType: dashboardName.toLowerCase(),
              dateMode: 'day',
            });
          }
        }
      }}
      options={{
        events: ['mousemove'],
        maintainAspectRatio: false,
        responsive: true,
        cornerRadius: 6,
        animation: {
          duration: 0, // general animation time
        },
        responsiveAnimationDuration: 0,
        hover: {
          mode: 'point',
          intersect: false,
          animationDuration: 0,
          onHover(e, item) {
            e.target.style.cursor = item[0] ? 'pointer' : 'default';
            if (item.length) {
              // eslint-disable-next-line no-underscore-dangle
              setHoveredLineIndex(item[0]._datasetIndex);
            } else {
              setHoveredLineIndex(null);
            }
          },
        },
        scales: {
          xAxes: [
            {
              position: data.every((dataset) =>
                dataset.values.every((value) => value <= 0)
              )
                ? 'top'
                : 'bottom',
              gridLines: {
                color: enableGrid ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, 0)',
              },
              ticks: {
                fontColor: '#959595',
                fontFamily: 'Roboto, sans-serif',
                fontSize: 13,
                maxRotation: 0,
                autoSkipPadding: 4,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1000,
                ...yAxisValuesMin,
                fontColor: '#959595',
                fontFamily: 'Roboto, sans-serif',
                fontSize: 14,
                callback(value) {
                  if (value === 0) return `${value}  `;
                  if (value === 1) return '';
                  const currencySign =
                    ['TURNOVER', 'TREASURY', 'DEBTORS', 'CREDITORS'].includes(
                      dashboardName
                    ) || [CA, TOTAL_COST].includes(activeTabId)
                      ? '€'
                      : '';
                  return `${Math.round(value / 1000)} K${currencySign}`;
                },
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: hoveredLineIndex !== 0,
          mode: 'label',
          position: 'cursor',
          intersect: true,
          backgroundColor: 'rgba(255, 255, 255, 1)',
          bodySpacing: 5,
          bodyFontFamily: 'Roboto, sans-serif',
          bodyFontSize: 13,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          cornerRadius: 2,
          callbacks: {
            labelColor(item) {
              const index = item.datasetIndex;
              if (index === 0) return '';
              const color = semiTransparentColors[index - 1];
              return {
                borderColor: color,
                backgroundColor: color,
              };
            },
            label(item) {
              if (item.value === 'NaN') return '';
              if (item.datasetIndex === 0) return '';
              return getTooltipLabel(
                item,
                activeMonthName,
                activeYear,
                dataWithComments,
                isViewModeAnnual,
                showLastDayOfMonthOnTooltipInAnnualMode,
                firstMonth
              );
            },
            labelTextColor(item) {
              const index = item.datasetIndex;
              if (index === 0) return '';
              if (index === hoveredLineIndex) return 'black';
              return '#959595';
            },
            title() {
              return '';
            },
          },
        },
      }}
    />
  );
};

BarChart.propTypes = propTypes;
