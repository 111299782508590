import React, { useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { CalendarTableCell } from 'containers/Salary/CalendarPage/CalendarTableCell/CalendarTableCell';

import { ABSENCES_FILTER_DATE_FORMAT } from 'const';
import CalendarColumnStyles from 'components/SalaryComponents/Calendar/CalendarColumnTitle/CalendarColumnTitle.module.css';
import Styles from 'containers/Salary/CalendarPage/CalendarPage.module.css';
import useGetCalendarCellOverlay from './useGetCalendarCellOverlay';

export const useGetCalendarYearViewColumn = (
  isMobileVersion,
  isDateHoliday,
  openLeaveModal,
  downloadAttachment,
  selectedRowKeys,
  yearAbsencesMappedToDates,
  monthFilter,
  calendarEmployee
) => {
  const yearViewColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'month',
        key: 'monthid',
        width: isMobileVersion ? 140 : 160,
        height: 52,
        fixed: 'left',
        ellipsis: true,
        className: Styles.nameColumn,
        render: (month) => <div className={Styles.userNameCell}>{month}</div>,
      },
      ...new Array(31).fill().map((e, i) => ({
        key: i + 1,
        title: (
          <div className={clsx(CalendarColumnStyles.dateTitle)}>
            <div className={CalendarColumnStyles.dayNumberInYear}>{i + 1}</div>
          </div>
        ),
        dataIndex: [`day-${i + 1}`],
        width: 36,
        render: (employeeData, row) => {
          const { absences: leaveObj, dateString, ...employee } =
            employeeData || {};

          const date = moment(monthFilter)
            .month(row.key - 1)
            .date(i + 1);

          const isToday = date.isSame(moment(), 'day');
          const isHoliday = isDateHoliday(date);
          const isWeekends =
            new Date(date.format(ABSENCES_FILTER_DATE_FORMAT)).getDay() === 0 ||
            new Date(date.format(ABSENCES_FILTER_DATE_FORMAT)).getDay() === 6;

          const lastDayInMonth = moment(monthFilter)
            .month(row.key - 1)
            .endOf('month')
            .date();

          if (i + 1 > lastDayInMonth) {
            return (
              <div
                className={clsx(Styles.calendarCell, {
                  [Styles.disabledCell]: true,
                })}
              ></div>
            );
          }

          return (
            <CalendarTableCell
              leaveObj={leaveObj}
              employee={employee}
              dateString={dateString}
              absencesMappedToDates={yearAbsencesMappedToDates}
              isDateHoliday={isDateHoliday}
              getCalendarCellOverlay={useGetCalendarCellOverlay}
              openLeaveModal={openLeaveModal}
              downloadAttachment={downloadAttachment}
              selectedRowKeys={selectedRowKeys}
              yearDate={new Date(date.format())}
              isYearToday={isToday}
              isYearHoliday={isHoliday}
              isYearWeekends={isWeekends}
              calendarEmployee={calendarEmployee}
            />
          );
        },
      })),
    ],
    [
      downloadAttachment,
      isDateHoliday,
      isMobileVersion,
      monthFilter,
      openLeaveModal,
      selectedRowKeys,
      yearAbsencesMappedToDates,
      calendarEmployee,
    ]
  );

  return yearViewColumns;
};
