import { request } from 'utils/request';

export const getTurnoverData = ({
  currentExercice = '',
  transition = false,
  currentCompanyId,
}) => {
  let query = currentExercice || transition ? '?' : '';
  query = currentExercice ? `${query}exercice=${currentExercice}&` : query;
  query = `${query}transition=${transition}`;
  return request.get(`/dashboards/${currentCompanyId}/turnover${query}`);
};
