import salesLandingCash from 'assets/images/LandingPagesAdvantages/sales-landing-cash.svg';
import landingSecure from 'assets/images/LandingPagesAdvantages/landing-secure.svg';
import salesLandingSimple from 'assets/images/LandingPagesAdvantages/sales-landing-simple.svg';
import expensesLandingFast from 'assets/images/LandingPagesAdvantages/expenses-landing-fast.svg';
import expensesLandingSimple from 'assets/images/LandingPagesAdvantages/expenses-landing-simple.svg';
import mesIndicatorsLogo from 'assets/images/LandingPages/mes-indicators-lp-logo.svg';
import mesVentesLogo from 'assets/images/LandingPages/mes-ventes-lp-logo.png';
import mesDepensesLogo from 'assets/images/LandingPages/mes-depenses-lp-logo.png';
import helpLogo from 'assets/images/LandingPages/help-lp-logo.png';
import myExcoLogo from 'assets/images/LandingPages/my-exco-lp-logo.png';
import Point1 from 'assets/icons/MyExcoLanding/Point1.svg';
import Point2 from 'assets/icons/MyExcoLanding/Point2.svg';
import Point3 from 'assets/icons/MyExcoLanding/Point3.svg';
import { LANDING_PAGE_TRANSLATIONS } from 'const/translations/ServiceLanding';
import { PACKAGES_NAMES } from 'const/packages';
import { HOMEPAGE_FORM_TRANSLATIONS } from '../translations';

const { CARDS_TITLES } = HOMEPAGE_FORM_TRANSLATIONS;

const {
  ADVANTAGES: {
    TITLES,
    DESCRIPTIONS,
    WAIBI_POINTS_TITLE,
    WAIBI_POINTS,
    MY_EXCO_POINTS,
    MY_EXCO_TITLES,
  },
  SALES,
  WAIBI,
  EXPENSES,
  MY_EXCO,
  HELP,
  EXPENSES_COST_BLOCK_TEXT,
  SALES_COST_BLOCK_TEXT,
  MY_EXCO_COST_BLOCK_TEXT,
  WAIBI_COST_BLOCK_TEXT,
  WAIBI_COST,
} = LANDING_PAGE_TRANSLATIONS;

export const LANDING_TOP_SECTION = {
  [PACKAGES_NAMES.FACT]: {
    title: CARDS_TITLES.MY_SALES,
    subtitle: SALES.SUBTITLE,
    text: SALES.DESCRIPTION,
    disclaimer: SALES.DISCLAIMER,
    imgUrl: mesVentesLogo,
  },
  [PACKAGES_NAMES.WAIBI]: {
    title: CARDS_TITLES.WAIBI,
    text: WAIBI.DESCRIPTION,
    disclaimer: WAIBI.DISCLAIMER,
    imgUrl: mesIndicatorsLogo,
  },
  [PACKAGES_NAMES.PAY]: {
    title: CARDS_TITLES.MY_EXPENSES,
    subtitle: EXPENSES.SUBTITLE,
    text: EXPENSES.DESCRIPTION,
    disclaimer: EXPENSES.DISCLAIMER,
    imgUrl: mesDepensesLogo,
  },
  [PACKAGES_NAMES.MY_EXCO]: {
    title: CARDS_TITLES.MY_EXCO,
    subtitle: MY_EXCO.SUBTITLE,
    text: MY_EXCO.DESCRIPTION,
    imgUrl: myExcoLogo,
  },
  HELP: {
    title: CARDS_TITLES.HELP,
    subtitle: HELP.SUBTITLE,
    text: HELP.DESCRIPTION,
    imgUrl: helpLogo,
  },
};

export const LANDING_ADVANTAGES = {
  [PACKAGES_NAMES.FACT]: [
    {
      id: 0,
      image: salesLandingSimple,
      title: `+ ${TITLES.SIMPLE}`,
      description: DESCRIPTIONS.SALES_SIMPLE,
    },
    {
      id: 1,
      image: salesLandingCash,
      title: `+ ${TITLES.CASH}`,
      description: DESCRIPTIONS.SALES_CASH,
    },
    {
      id: 2,
      image: landingSecure,
      title: `+ ${TITLES.SECURE}`,
      description: DESCRIPTIONS.SALES_SECURE,
    },
  ],
  [PACKAGES_NAMES.PAY]: [
    {
      id: 0,
      image: expensesLandingFast,
      title: `+ ${TITLES.FAST}`,
      description: DESCRIPTIONS.EXPENSES_FAST,
    },
    {
      id: 1,
      image: expensesLandingSimple,
      title: `+ ${TITLES.SIMPLE}`,
      description: DESCRIPTIONS.EXPENSES_SIMPLE,
    },
    {
      id: 2,
      image: landingSecure,
      title: `+ ${TITLES.SECURE}`,
      description: DESCRIPTIONS.EXPENSES_SECURE,
    },
  ],
  [PACKAGES_NAMES.MY_EXCO]: [
    {
      id: 0,
      title: MY_EXCO_TITLES.INVOICE_SEARCH,
      icon: Point1,
      descriptionList: [
        MY_EXCO_POINTS.INVOICE_ANALYZE,
        MY_EXCO_POINTS.INVOICE_COMPARE,
        MY_EXCO_POINTS.INVOICE_TRACK,
      ],
    },
    {
      id: 1,
      title: MY_EXCO_TITLES.HR_INDICATORS,
      icon: Point2,
      descriptionList: [
        MY_EXCO_POINTS.HR_TURNOVER,
        MY_EXCO_POINTS.HR_SALARY,
        MY_EXCO_POINTS.HR_HOURLY,
        MY_EXCO_POINTS.HR_PERCENT,
      ],
    },
    {
      id: 2,
      title: MY_EXCO_TITLES.TREASURY,
      icon: Point3,
      descriptionList: [
        MY_EXCO_POINTS.TREASURY_CASH,
        MY_EXCO_POINTS.TREASURY_OWED,
        MY_EXCO_POINTS.TREASURY_CREDITED,
      ],
    },
  ],
  [PACKAGES_NAMES.WAIBI]: [
    {
      id: 0,
      title: WAIBI_POINTS_TITLE,
      descriptionList: [
        WAIBI_POINTS.ONLINE_CONSULTATION,
        WAIBI_POINTS.COMMENTS,
        WAIBI_POINTS.RESULTS,
        // WAIBI_POINTS.PERSONALIZED_SUPPORT,
        WAIBI_POINTS.REACTIVE_MANAGEMENT,
      ],
    },
  ],
};

export const LANDING_COST_BLOCK = {
  [PACKAGES_NAMES.FACT]: {
    text: SALES_COST_BLOCK_TEXT,
  },
  [PACKAGES_NAMES.PAY]: {
    text: EXPENSES_COST_BLOCK_TEXT,
  },
  [PACKAGES_NAMES.MY_EXCO]: {
    text: MY_EXCO_COST_BLOCK_TEXT,
  },
  [PACKAGES_NAMES.WAIBI]: {
    customCost: WAIBI_COST,
    text: WAIBI_COST_BLOCK_TEXT,
  },
  HELP: {
    customCost: 'RDV',
  },
};
