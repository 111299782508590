import { Typography } from 'antd';
import React, { useCallback } from 'react';
import { Modal } from 'components/ui/Modal';
import PropTypes from 'prop-types';
import { COMPANY_SETTINGS } from '../../../../../const/translations/SalaryCompanySettings';
import Styles from '../../CompanySettingsPage.module.css';
const { Text } = Typography;

const propTypes = {
  setOpenRemoveModal: PropTypes.func,
  openRemoveModal: PropTypes.bool,
  removeScheduleHandler: PropTypes.func,
  employeeCount: PropTypes.number,
};

export const ScheduleRemoveModal = ({
  setOpenRemoveModal,
  openRemoveModal,
  removeScheduleHandler,
  employeeCount,
}) => {
  const closeDeleteModal = useCallback(() => {
    setOpenRemoveModal(false);
  }, [setOpenRemoveModal]);

  return (
    <Modal
      title={COMPANY_SETTINGS.REMOVE_MODAL_TITLE}
      visible={openRemoveModal}
      onClose={closeDeleteModal}
      onSubmit={removeScheduleHandler}
      width="384px"
      closable={false}
      okText={COMPANY_SETTINGS.REMOVE_BTN_TEXT}
      fullMobileScreen={false}
    >
      <Text className={Styles.modalText}>
        {COMPANY_SETTINGS.REMOVE_MODAL_TEXT_1.replace(
          '{{COUNT}}',
          employeeCount
        )}
      </Text>{' '}
      <Text className={Styles.modalText}>
        {COMPANY_SETTINGS.REMOVE_MODAL_TEXT_2}
      </Text>
    </Modal>
  );
};

ScheduleRemoveModal.propTypes = propTypes;
