import { CancelToken } from 'axios';
import { END, eventChannel } from 'redux-saga';

import { request } from './request';

/**
 * Event channel for support detect progress of uploading
 * channel emit object with optional fields (progress, error, data):
 *  - data exists when request complete
 *  - progress exists while loading
 *  - error exists when error happen
 *
 * @param {String} url - api end point for upload
 * @param {Object} fields - object with fields sending using multipart/form-data request
 * @param {Object} config - additional configuration for axios
 */
export const uploadChannel = (url, fields, config = {}) =>
  eventChannel((emit) => {
    const source = CancelToken.source();

    const requestConfig = {
      ...config,
      onUploadProgress: (progressEvent) =>
        emit({
          progress: Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          ),
        }),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: source.token,
    };

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    request
      .post(url, formData, requestConfig)
      .then((data) => {
        emit({ data, complete: true });
      })
      .catch((error) => {
        emit({ error });
      })
      .finally(() => {
        emit(END);
      });

    // unsubscribe function
    return () => {
      source.cancel();
    };
  });
