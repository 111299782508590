import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  GET_TREASURY_DATA_ERROR,
  GET_TREASURY_DATA_REQUEST,
  GET_TREASURY_DATA_SUCCESS,
  GET_TREASURY_SINGLE_ACCOUNT_DATA_ERROR,
  GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST,
  GET_TREASURY_SINGLE_ACCOUNT_DATA_SUCCESS,
  SET_TREASURY_SINGLE_ACCOUNT_STATUS_LOADING,
} from 'modules/treasuryPage/actions';

const initialState = fromJS({
  treasuryData: { datasets: {} },
  accounts: [],
  error: '',
  status: STATUS_NOT_REQUESTED,
  singleAccountStatus: STATUS_NOT_REQUESTED,
});

export const treasuryPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_TREASURY_DATA_REQUEST: {
      return state.setIn(['status'], STATUS_LOADING);
    }
    case SET_TREASURY_SINGLE_ACCOUNT_STATUS_LOADING: {
      return state.setIn(['singleAccountStatus'], STATUS_LOADING);
    }
    case GET_TREASURY_DATA_SUCCESS: {
      const { accounts, ...treasuryData } = action.payload.data;
      return state
        .setIn(['treasuryData'], fromJS(treasuryData))
        .setIn(['accounts'], fromJS(accounts))
        .setIn(['status'], STATUS_SUCCESS)
        .setIn(['singleAccountStatus'], STATUS_SUCCESS);
    }
    case GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST: {
      return state.setIn(['singleAccountStatus'], STATUS_LOADING);
    }
    case GET_TREASURY_SINGLE_ACCOUNT_DATA_SUCCESS: {
      const treasuryAccountData = action.payload.data;
      return state
        .setIn(['treasuryData', 'datasets'], fromJS(treasuryAccountData))
        .setIn(['singleAccountStatus'], STATUS_SUCCESS);
    }
    case GET_TREASURY_SINGLE_ACCOUNT_DATA_ERROR: {
      return state.setIn(['singleAccountStatus'], STATUS_ERROR);
    }
    case GET_TREASURY_DATA_ERROR: {
      const { error } = action.payload;
      return state
        .setIn(['error'], error)
        .setIn(['singleAccountStatus'], STATUS_ERROR)
        .setIn(['status'], STATUS_ERROR)
        .setIn(['treasuryData'], fromJS({ datasets: {} }))
        .setIn(['accounts'], fromJS([]));
    }
    default:
      return state;
  }
};
