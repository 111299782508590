export const MONTH_SELECTOR_TRANSLATIONS = {
  MONTH: 'Mois',
  JANUARY: 'Janvier',
  FEBRUARY: 'Février',
  MARCH: 'Mars',
  APRIL: 'Avril',
  MAY: 'Mai',
  JUNE: 'Juin',
  JULY: 'Juillet',
  AUGUST: 'Août',
  SEPTEMBER: 'Septembre',
  OCTOBER: 'Octobre',
  NOVEMBER: 'Novembre',
  DECEMBER: 'Décembre',
};
