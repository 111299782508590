import React, { memo, useCallback } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { setModalVisibility } from 'modules/SalaryModule/SalaryModal/actions';
import { SALARY_MAIN_PAGE } from 'const/translations/SalaryMainPage';
import { PRODUCT_CARDS } from 'const/SalaryMainPage';
import { MainPageCard } from 'components/SalaryComponents/MainPageCard';
import flowersImg from 'assets/images/loginNewBg.svg';
import { SectionTitle } from 'components/Section/SectionTitle';
import { Button } from 'components/ui';
import { SALARY_HEADER_LABELS } from 'const/translations';
import Styles from './MainPage.module.css';

export const MainPage = memo(() => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setModalVisibility(true));
  }, [dispatch]);

  const handleActionDispatch = useCallback(
    (actionToDispatch) => {
      dispatch(actionToDispatch());
    },
    [dispatch]
  );

  useScrollToTop();

  return (
    <div className={Styles.mainPage}>
      <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
      <div className={Styles.mainPageContainer}>
        <SectionTitle
          title={SALARY_MAIN_PAGE.SECTION_TITLE}
          titleTextColor="white"
          className={Styles.textTitle}
        />
        <div className={Styles.buttonWrapper}>
          <Button sizeAuto variant="secondary" onClick={handleClick}>
            {SALARY_HEADER_LABELS.SEND_INFO}
          </Button>
        </div>
        <Row gutter={21}>
          {PRODUCT_CARDS.map((item) => (
            <Col span={24} md={8} key={item.title} className={Styles.col}>
              {item.action ? (
                <MainPageCard
                  image={item.image}
                  title={item.title}
                  paragraph={item.paragraph}
                  action={item.action}
                  clickHandler={handleActionDispatch}
                />
              ) : (
                <Link to={item.path}>
                  <MainPageCard
                    image={item.image}
                    title={item.title}
                    paragraph={item.paragraph}
                  />
                </Link>
              )}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
});
