import { notification } from 'antd';
import clsx from 'clsx';
import Styles from './Notification.module.css';

export const openSuccessNotification = (params) => {
  const {
    message = 'success',
    placement = 'bottomLeft',
    duration = 3,
    wide = false,
    color = '',
  } = params;
  notification.success({
    message,
    placement,
    className: clsx(Styles.notification, Styles[color], {
      [Styles.wide]: wide,
    }),
    duration,
  });
};
