import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import checkMark from '@iconify/icons-ant-design/check-outlined';
import Styles from './GreenCheckbox.module.css';

const propTypes = {
  checked: PropTypes.bool.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  label: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const GreenCheckbox = ({
  checked,
  toggleChecked,
  label = '',
  inputId,
  className = '',
}) => (
  <div className={clsx(Styles.checkBox, className)}>
    <input
      checked={checked}
      onChange={toggleChecked}
      type="checkbox"
      id={inputId}
    />
    <label htmlFor={inputId}>
      <div
        className={clsx(Styles.box, {
          [Styles.checked]: checked,
        })}
      >
        <Icon
          className={clsx(Styles.checkMark, {
            [Styles.checked]: checked,
          })}
          icon={checkMark}
        />
      </div>
      <span className={Styles.checkBoxLabelWrapper}>{label}</span>
    </label>
  </div>
);

GreenCheckbox.propTypes = propTypes;
