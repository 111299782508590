import { useSimpleMedia } from 'hooks/useMedia';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 796;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const MobileBlocker = ({ desktop, mobile }) => {
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  if (isMobileVersion) return mobile;
  return desktop;
};
