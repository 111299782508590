import { createSelector } from 'reselect';
import { selectFamilyData } from '../../Rewards/selectors';
import { formatNameUpperLastName } from '../../Employees/utils';

const selectEmployeeRewards = (state) =>
  state.getIn(['rewardsTableReducer', 'employeeRewards'])?.data
    ?.employeeRewards;

export const selectEmployeeRewardsMeta = (state) =>
  state.getIn(['rewardsTableReducer', 'employeeRewards'])?.meta;

export const selectEmployeeSearch = (state) =>
  state.getIn(['rewardsTableReducer', 'employeeSearch']);

export const selectRewardsTableStatus = (state) =>
  state.getIn(['rewardsTableReducer', 'status']);

const selectGivenRewardsIds = createSelector(
  selectEmployeeRewards,
  (employeeRewards) =>
    employeeRewards?.reduce((acc, { rewards }) => {
      rewards.forEach(({ companyRewardId, value }) => {
        if (value && !acc.includes(companyRewardId)) {
          acc.push(companyRewardId);
        }
      });
      return acc;
    }, [])
);

/**
 * Families sorting must be the same as for selectCompanyRewardsFamilies selector
 */
export const selectFamilyRewardsTableColumns = createSelector(
  selectFamilyData,
  selectGivenRewardsIds,
  (familyData) => {
    if (!familyData || !familyData.companyRewards) {
      return [];
    }

    return familyData.companyRewards.map(
      ({ reward, id, customName, customType }) => ({
        ...reward,
        id,
        name: customName || reward.name,
        type: customType || reward.type,
      })
    );
  }
);

/**
 * Return formatted and filtered employee list with rewards
 * @param { Array } employees - employees list with rewards
 * @param { String } search - search query from toolbar
 * @returns {Array} [{
 *   id: '44441',
 *   name: 'Joe Dou'(summary of first and last names),
 *   452352355323532(reward id): 1000(reward value)
 * }]
 */
export const selectEmployeeTransformRewards = createSelector(
  selectEmployeeRewards,
  selectEmployeeSearch,
  (employees, search) => {
    if (employees && employees.length) {
      return employees
        .map(({ rewards, employee }) => {
          const rewardsObject = rewards.reduce((acc, reward) => {
            acc[reward.companyRewardId] = reward.value;

            return acc;
          }, {});
          return {
            id: employee.id,
            name: formatNameUpperLastName(
              employee.firstName,
              employee.lastName
            ),
            ...rewardsObject,
          };
        })
        .filter((employee) =>
          search
            ? employee.name.toLowerCase().includes(search.toLowerCase())
            : true
        );
    }

    return [];
  }
);
