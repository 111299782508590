import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { COMPANY_SETTINGS_REWARDS } from 'const/translations/SalaryCompanySettings';
import { getRewardTypeText } from 'modules/SalaryModule/Rewards/utils';
import { EditableContext } from './context';
import Styles from './RewardsTable.module.css';

const propTypes = {
  inputType: PropTypes.string,
  props: PropTypes.object,
  children: PropTypes.node,
  editing: PropTypes.bool,
  dataIndex: PropTypes.array,
  record: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  inputMaxLength: PropTypes.number,
};

const { Option } = Select;

/**
 * Return simple reward table cell with text or input in editing status
 * we get initial value record[dataIndex[0]][dataIndex[1]],
 * because such an object structure, it fit with column dataIndex
 * @param { String } inputType - type of input in cell(input, select)
 * @param { Boolean } editing - row editing status
 * @param { String } dataIndex - key of current editing table row
 * @param { Number } inputMaxLength - max length for input field
 * @param { Object } record - reward object with data
 * @param { Object } children - react node
 * @param { Object } props - rest of cell props
 */
export const EditableCell = ({
  inputType,
  editing,
  dataIndex,
  inputMaxLength,
  record,
  children,
  ...props
}) => {
  const form = useContext(EditableContext);

  const getInput = useCallback(() => {
    if (inputType === 'text') {
      return <Input />;
    }
    return (
      <Select className={Styles.select} dropdownClassName={Styles.dropdown}>
        {COMPANY_SETTINGS_REWARDS.REWARD_TYPE_OPTIONS.map(
          ({ label, value }) => (
            <Option key={value} value={value}>
              {label || value}
            </Option>
          )
        )}
      </Select>
    );
  }, [inputType]);

  const renderCell = ({ getFieldDecorator }) => {
    const child =
      dataIndex && dataIndex.indexOf('type') > -1
        ? getRewardTypeText(record?.reward?.type)
        : children;

    return (
      <td {...props}>
        {editing ? (
          <Form.Item style={{ margin: 0 }} className={Styles.input}>
            {getFieldDecorator(dataIndex[1], {
              rules: [
                {
                  required: true,
                  max: inputMaxLength,
                },
              ],
              initialValue: record[dataIndex[0]][dataIndex[1]],
            })(getInput())}
          </Form.Item>
        ) : (
          child
        )}
      </td>
    );
  };
  return <>{renderCell(form)}</>;
};

EditableCell.propTypes = propTypes;
