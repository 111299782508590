import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AUTH_TOKEN_KEY,
  WAIBI_ACCESS_TOKEN,
  WAIBI_REFRESH_TOKEN,
} from 'config';
import { redirect } from 'helpers/common';
import { ROUTES } from 'const';
import { RegisteredTerms } from 'containers/RegisteredTerms/RegisteredTerms';
import { TermsOfUseLanding } from 'containers/TermsOfUseLanding';
import { REGISTERED_TERMS_TRANSLATIONS } from 'const/translations/TermsOfUse';
import { removeFromStorage } from 'helpers/storage';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';
import { REGISTERED_USER_TERMS_REDIRECTS } from '../const/RoutesRedirects';

const { DATASET_KEY, DELETED_USERS_KEY } = ADDITIONAL_USERS_LOCAL_STORAGE_KEYS;

const {
  REGISTERED_SUBSCRIPTION_TERMS,
  REGISTERED_SUBSCRIPTION_TERMS_TITLE,
  REGISTERED_USAGE_TERMS,
  REGISTERED_USAGE_TERMS_TITLE,
} = REGISTERED_TERMS_TRANSLATIONS;

export const RegisteredUserTermsRoutes = () => (
  <Switch>
    <Route exact path={ROUTES.ROOT} render={() => <RegisteredTerms />} />
    <Route
      exact
      path={ROUTES.TERMS_OF_USE_LANDING}
      render={() => (
        <TermsOfUseLanding
          hasBreadcrumb={false}
          termsText={REGISTERED_USAGE_TERMS}
          title={REGISTERED_USAGE_TERMS_TITLE}
          hasLogoOnPage
        />
      )}
    />
    <Route
      exact
      path={ROUTES.TERMS_OF_SUBSCRIPTION}
      render={() => (
        <TermsOfUseLanding
          hasBreadcrumb={false}
          termsText={REGISTERED_SUBSCRIPTION_TERMS}
          title={REGISTERED_SUBSCRIPTION_TERMS_TITLE}
          hasLogoOnPage
        />
      )}
    />
    <Route
      exact
      path={ROUTES.LOGOUT}
      render={() => {
        removeFromStorage({
          key: [
            AUTH_TOKEN_KEY,
            WAIBI_ACCESS_TOKEN,
            WAIBI_REFRESH_TOKEN,
            DATASET_KEY,
            DELETED_USERS_KEY,
          ],
        });
        return redirect(ROUTES.ROOT);
      }}
    />
    {REGISTERED_USER_TERMS_REDIRECTS.map(({ to, from }) => (
      <Redirect exact key={`${from}_${to}`} to={to} from={from} />
    ))}
    <Redirect to={ROUTES.ROOT} />
  </Switch>
);
