import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  SET_COMPANY_UNADDED_REWARDS,
  SET_COMPANY_REWARDS_FAMILIES,
  SET_REWARDS_ERROR,
  SET_REWARDS_FAMILY,
  REWARD_FAMILY_MESSAGE,
  GET_REWARDS_FAMILY,
  CREATE_REWARD_FAMILY,
  ADD_REWARD_TO_FAMILY_SUCCESS,
  UPDATE_REWARD_TO_FAMILY_SUCCESS,
  INIT_REWARDS,
} from '../actions';

const initialState = fromJS({
  companyUnaddedRewards: null,
  familyData: null,
  familyMessage: null,
  companyRewardsFamilies: null,
  status: STATUS_NOT_REQUESTED,
});

export const rewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_UNADDED_REWARDS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('companyUnaddedRewards', action.payload);
    case SET_COMPANY_REWARDS_FAMILIES:
      return state
        .set('status', STATUS_SUCCESS)
        .set('companyRewardsFamilies', action.payload);
    case SET_REWARDS_ERROR:
      return state.set('status', STATUS_ERROR);
    case GET_REWARDS_FAMILY:
      return state.set('status', STATUS_LOADING);
    case CREATE_REWARD_FAMILY:
      return state.set('status', STATUS_LOADING);
    case SET_REWARDS_FAMILY:
      return state
        .set('status', STATUS_SUCCESS)
        .set('familyData', action.payload);
    case REWARD_FAMILY_MESSAGE:
      return state.set('familyMessage', action.payload);
    case ADD_REWARD_TO_FAMILY_SUCCESS:
      return state.updateIn(['familyData', 'companyRewards'], (data) => [
        action.payload,
        ...data,
      ]);
    case UPDATE_REWARD_TO_FAMILY_SUCCESS:
      return state.updateIn(['familyData', 'companyRewards'], (data) =>
        data.map((reward) =>
          reward.id === action.payload.id ? action.payload : reward
        )
      );
    case INIT_REWARDS:
      return initialState;
    default:
      return state;
  }
};
