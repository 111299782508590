import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';
import { getPaymentTooltipTitle } from 'helpers/invoiceSearchTable/pay';

const {
  TOOLTIP: { NOT_PAYABLE_INVOICE },
} = INVOICE_SEARCH_TRANSLATIONS;

export const usePayTooltipProps = () => {
  const {
    paymentStatus,
    isPayableInvoice,
    isValidated,
    isDeleted,
  } = useInvoiceActionsData();

  if (isDeleted) {
    return null;
  }

  const paymentTooltipTitle = getPaymentTooltipTitle(
    paymentStatus,
    isValidated
  );

  const tooltipTitle = isPayableInvoice
    ? paymentTooltipTitle
    : NOT_PAYABLE_INVOICE;

  return {
    title: tooltipTitle,
    overlayClassName: Styles.tooltipApprove,
    arrowPointAtCenter: true,
    placement: 'topRight',
  };
};
