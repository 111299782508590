import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip } from 'antd';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';

const propTypes = {
  tooltipProps: PropTypes.object,
  displayComponent: PropTypes.element,
  isLoading: PropTypes.bool,
};

export const TableAction = ({ tooltipProps, isLoading, displayComponent }) => (
  <Tooltip {...tooltipProps}>
    {isLoading ? (
      <span className={Styles.spinSubstitute}>
        <Spin />
      </span>
    ) : (
      displayComponent
    )}
  </Tooltip>
);

TableAction.propTypes = propTypes;
