import React from 'react';
import PropTypes from 'prop-types';
import { Col, Icon, Typography } from 'antd';
import clsx from 'clsx';

import MainStyles from '../../assets/styles/main.module.css';
import Styles from './Contacts.module.css';

const propTypes = {
  label: PropTypes.string,
  emails: PropTypes.string,
  hideIcon: PropTypes.bool,
};

const { Paragraph } = Typography;

/**
 * @param label
 * @param emails consists of single email or several, separated by '; '
 */
export const CompanyContact = ({ label, emails, hideIcon = false }) => (
  <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
    <Paragraph className={clsx(Styles.rowText, Styles.accented)}>
      {label}
    </Paragraph>
    <div className={Styles.list}>
      {emails.split('; ').map((email) => (
        <div className={Styles.line}>
          <Paragraph className={Styles.rowText}>{email}</Paragraph>
          {!hideIcon && (
            <a href={`mailto:${email}`}>
              <Icon type="mail" />
            </a>
          )}
        </div>
      ))}
    </div>
  </Col>
);

CompanyContact.propTypes = propTypes;
