import { getMonthIndexFromName } from 'helpers/monthSelector';
import { getInitialMonthArray } from 'helpers/chartDataMappers';

export const formatStringDateWithDotsToDate = (date) => {
  const stringDate = date
    .split('.')
    .reverse()
    .join('-');
  return new Date(stringDate).toISOString();
};

export const mapCommentsToRedux = (comments) =>
  comments.map((comment) => ({
    ...comment,
    date: formatStringDateWithDotsToDate(comment.date),
  }));

export const formatMonthsCommentsToYear = (monthsComments, firstMonthName) => {
  const yearCommentsArray = monthsComments.map(({ values }) => {
    if (values.some((value) => value !== null)) {
      return 0;
    }
    return null;
  });
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  return [
    ...yearCommentsArray.slice(firstMonthIndex),
    ...yearCommentsArray.slice(0, firstMonthIndex),
  ];
};

export const formatCommentsToMonths = (comments) =>
  comments.reduce((monthsComments, { date }) => {
    const monthIndex = Number(new Date(Date.parse(date)).getMonth());
    const day = Number(new Date(Date.parse(date)).getDate() - 1);
    // eslint-disable-next-line no-param-reassign
    monthsComments[monthIndex].values[day] = 0;
    return monthsComments;
  }, getInitialMonthArray(2020));

export const getActiveMonthCommentsForChart = (
  monthsComments,
  activeMonthName
) => monthsComments.find((month) => month.name === activeMonthName).values;
