import React from 'react';
import PropTypes from 'prop-types';
import Styles from './LeafyBackground.module.css';
import { Logo } from '../Logo';

const propTypes = {
  children: PropTypes.node,
  withoutLogo: PropTypes.bool,
};

export const LeafyBackground = ({ children, withoutLogo }) => (
  <>
    {withoutLogo ? null : <Logo className={Styles.logoLink} />}
    <div className={Styles.background}>{children}</div>
  </>
);

LeafyBackground.propTypes = propTypes;
