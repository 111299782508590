import { SALARY_HEADER_LABELS } from '../translations';
import { ROUTES } from '../index';

export const SALARY_HEADER_NAV = [
  {
    id: 1,
    label: SALARY_HEADER_LABELS.EXCO_SNIFI,
    path: ROUTES.EMPLOYEE_REGISTER_TITLE,
  },
  {
    id: 2,
    label: SALARY_HEADER_LABELS.EMPLOYEES,
    path: ROUTES.SALARIES_EMPLOYEES,
  },
  {
    id: 3,
    label: SALARY_HEADER_LABELS.REWARDS,
    path: ROUTES.SALARIES_REWARDS,
  },
  {
    id: 4,
    label: SALARY_HEADER_LABELS.CALENDAR,
    path: ROUTES.SALARIES_CALENDAR,
  },
  {
    id: 5,
    label: SALARY_HEADER_LABELS.COMPANY_SETTINGS,
    path: ROUTES.SALARIES_COMPANY_SETTINGS,
  },
];

export const HEADER_BUTTONS = {
  SEND_INFO: 'sendInfo',
  CONTACT_HR: 'contactHR',
};
