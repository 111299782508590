export const SALARY_CALENDAR_TRANSLATIONS = {
  CALENDAR: 'Calendrier',
  ESTABLISHMENT: 'Etablissement',
  SEARCH_BY_NAME: 'Rechercher par nom',
  HIDE_USERS_WITHOUT_ABSENCES: 'Masquer les salariés sans absences/congés',
  USERS_NOT_FOUND: 'Aucun salarié absent',
  ADD_ABSENCE: 'Saisir une absence',
  ADD_MASS_ABSENCE: 'Saisir une absence groupée',
  ADD_VACATION: 'Saisir un congé',
  ADD_MASS_VACATION: 'Saisir un congé groupé',
  MOTIF: 'Motif',
  FROM: 'Début',
  TO: 'Fin',
  DOWNLOAD: 'Télécharger',
  DOWNLOAD_ATTACHMENT: 'Consulter la PJ',
  SELECT_EMPLOYEE: 'Tous les salariés',
  YEAR_MONTH_SWITCH: [
    {
      label: 'Mensuels',
      value: 'Mensuels',
    },
    {
      label: 'Annuels',
      value: 'Annuels',
    },
  ],
  YEAR_MONTH_SWITCH_CALENDAR: [
    {
      label: 'Mensuel',
      value: 'Mensuels',
    },
    {
      label: 'Annuel',
      value: 'Annuels',
    },
  ],
  YEAR_VIEW_CALENADR_NO_DATA:
    'Veuillez sélectionner un salarié pour voir la vue annuelle',
};

export const LEAVES_TYPES = {
  LEAVE_ABSENCE: 'absence',
  LEAVE_VACATION: 'vacation',
};

export const MODIFY_LEAVE_TRANLATIONS = {
  absence: 'Modifier une absence',
  vacation: 'Modifier un congé',
};

export const CALENDAR_MOBILE_FILTER_TRANSLATIONS = {
  FILTERS: 'Les filtres',
  SAVE: 'Sauvegarder',
  CANCEL: 'Annuler',
};
