/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations/InvoiceSearch';
import { selectUserNotification } from '../../modules/loggedUserInfo/selectors';

import { Button } from '../ui/Button';
import Styles from './InformationModal.module.css';

const {
  BUTTONS: { OK },
} = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  handleClose: PropTypes.func,
};

export const InformationModal = React.memo(({ handleClose }) => {
  const notification = useSelector(selectUserNotification);

  const { title, html } = notification;

  return (
    <>
      <h3 className={Styles.textTitle}>{title}</h3>
      <div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div
        className={clsx(MainStyles.buttonWrapper, Styles.modalButtonWrapper)}
      >
        <Button className={MainStyles.button} onClick={handleClose}>
          {OK}
        </Button>
      </div>
    </>
  );
});

InformationModal.propTypes = propTypes;
