export const CHANGE_PASSWORD_TRANSLATIONS = {
  TITLES: {
    TITLE: 'Bienvenue dans votre espace client Exco !',
    SUBTITLE:
      'Pour votre sécurité veuillez changer le mot de passe temporaire pour pouvoir continuer.',
    PASSWORD_FORGOT_TITLE: 'Mot de passe oublié',
    PASSWORD_CHANGE_TITLE: 'Changement du mot de passe',
    PASSWORD_CHANGE_SUBTITLE:
      'Le nouveau mot de passe a été vous envoyé ! Veuillez vérifier votre courrier électronique.',
    PASSWORD_CHANGE_SUCCESSFULLY_SUBTITLE:
      'Votre mot de passe a été changé avec succès.',
  },
  TOOLTIP:
    'Le mot de passe doit contenir au minimum 8 caractères, dont au moins un chiffre.',
  LABELS: {
    TEMPORARY: 'Temporaire',
    NEW: 'Nouveau',
    NEW_ONCE_AGAIN: 'encore une fois',
    EMAIL: 'Votre email',
    PASSWORD: 'Mot de passe',
    FORMER: 'Ancien',
  },
  BTNS: {
    RECORD: 'Enregistrer',
    GO_TO_SITE: 'Aller sur site',
  },
  LINKS: {
    CHANGE_PASSWORD: 'Changer le mot de passe',
    FORGOT_PASSWORD: 'Mot de passe oublié',
  },
  EMAIL: 'Marie.Griffe@gmail.com',
  ERRORS: {
    NOT_SAME_PASSWORD: 'Les nouveaux mots de passes ne sont pas identiques.',
    PASSWORD_NOT_SECURE:
      'Le mot de passe doit contenir au minimum 8 caractères, dont au moins un chiffre.',
    PASSWORD_INCORRECT: 'Le mot de passe est incorrect.',
    EMAIL_AND_OR_PASSWORD_INCORRECT:
      'Votre email et / ou le mot de passe sont incorrects',
    REQUIRED_FIELD: 'Ce champ est obligatoire',
    USER_DOES_NOT_EXIST:
      "L'utilisateur avec cet email n'existe pas. Veuillez contacter l'administrateur ou corriger l'email et réessayer",
    TEMPORARY_USER_DOES_NOT_EXIST: "L'utilisateur avec cet email n'existe pas.",
  },
  ALERTS: {
    PASSWORD_HAS_BEEN_CHANGED: 'Votre mot de passe a été changé avec succès.',
  },
};
