/**
 * Email name structure: 'EMAIL_{REDUX NAME}_{DB NAME}
 */
export const MY_INFORMATION_PAGE_TRANSLATIONS = {
  TITLE: 'Mes informations',
  EMAIL_MY_CURRENT_LOGIN: 'Mon e-mail de connexion actuel',
  CHANGE_EMAIL: 'Je souhaite modifier mon e-mail',
  CHANGE_PASSWORD: 'Je souhaite modifier mon mot de passe',
  SEE_ARTICLE: 'Voir l’article dédié',
};
