import { request } from 'utils/request';

export const getTreasuryData = ({
  currentExercice = '',
  transition = false,
  currentCompanyId,
}) => {
  let query = `?exercice=${currentExercice}&`;
  query += `transition=${transition}`;
  return request.get(`/dashboards/${currentCompanyId}/treasury${query}`);
};

export const getTreasurySingleAccountData = ({
  currentExercice = '',
  transition = false,
  currentCompanyId,
  accountName = '',
}) => {
  let query = `?exercice=${currentExercice}&`;
  query += `transition=${transition}`;
  return request.get(
    `/dashboards/${currentCompanyId}/treasury/${accountName}${query}`
  );
};
