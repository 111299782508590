import React, { useCallback } from 'react';
import { InputNumber } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';

export const InputNumberAdapted = ({ onChange, name, ...props }) => {
  const handleChange = useCallback(
    (value) => onChange({ target: { name, value } }),
    [name, onChange]
  );
  return <InputNumber {...props} onChange={handleChange} />;
};

InputNumberAdapted.propTypes = formikOnChangeAdaptorTypes;
