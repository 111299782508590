import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_LOGIN_BANNERS_REQUEST,
  getLoginBannersSuccess,
  getLoginBannersError,
} from '../actions';
import { getLoginBanners } from '../services';

function* getLoginBannersWorker() {
  try {
    const { data } = yield call(getLoginBanners);

    yield put(getLoginBannersSuccess(data));
  } catch (error) {
    console.warn(error);
    yield put(getLoginBannersError());
  }
}

export function* loginPageMainSaga() {
  yield takeLatest(GET_LOGIN_BANNERS_REQUEST, getLoginBannersWorker);
}
