import { useCallback, useEffect, useState } from 'react';

/**
 * Custom input masking hook, manages input and delete separately
 * as a workaround for antd Input behavior,
 * @param {string} rawValue - unmasked value
 * @param {number} maxLength - rawValue max allowed length, optional
 * @param {function} onChange - handler for rawValue change,
 * should take value as argument, not event
 * @param {string} mask - example: '_ __ __ _ __'
 * @param placeholderChar - char to show in place of missing value,
 * default is '_'
 * @returns {{maskedValue: *, handleInput: *, handleDelete: *}}
 */

export const useSimpleMask = (
  rawValue,
  maxLength = null,
  onChange,
  mask = '',
  placeholderChar = '_'
) => {
  const [maskedValue, setMasked] = useState('');
  useEffect(() => {
    const newMaskedValue = rawValue
      .split('')
      .reduce((result, char) => result.replace(placeholderChar, char), mask);
    setMasked(newMaskedValue);
  }, [rawValue, mask, placeholderChar]);
  const onInput = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    return maxLength && newValue.length > maxLength ? null : onChange(newValue);
  };
  const onDelete = ({ keyCode }) =>
    keyCode === 8 ? onChange(rawValue.slice(0, -1)) : null;
  return {
    handleInput: useCallback(onInput, []),
    maskedValue,
    handleDelete: useCallback(onDelete, [rawValue]),
  };
};
