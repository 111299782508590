import { request } from 'utils/request';

export const fetchCalendarAbsences = ({ companyId, year, month }) => {
  const query = month ? `?year=${year}&month=${month}` : `?year=${year}`;
  return request.get(`/companies/${companyId}/absences/all${query}`);
};

export const fetchHolidays = (year) => request.get(`/holidays/?year=${year}`);

export const downloadAbsencesXLSX = ({ query }) =>
  request.get(`/absences/xlsx?${query}`, {
    responseType: 'blob',
  });

export const fetchAbsencesTypes = () => request.get(`/absence-reasons`);
