import { Col, Row, Typography } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { DatePicker, Radio } from 'components/ui';
import { CalendarMiniIcon } from 'components/ui/CalendarMiniIcon';
import { VACATION_MODAL } from '../../../../../const/translations/SalaryVacations';
import Styles from '../AddVacationModal.module.css';

const { Text } = Typography;
const {
  LABEL_TEXTS,
  WORKING_TIME,
  DATE_START_PLACEHOLDER,
  DATE_END_PLACEHOLDER,
} = VACATION_MODAL;

const propTypes = {
  formState: PropTypes.object,
  formErrors: PropTypes.array,
  setFormState: PropTypes.func,
  handleChange: PropTypes.func,
  cleanErrors: PropTypes.func,
  isSingleDayAbsence: PropTypes.bool,
};

/**
 * Add leave/vacation modal dates view and logic
 * @param { Object } formState - vacation modal form fields state
 * @param { Array } formErrors - array with fields names with errors
 * @param { Function } setFormState - function for set modal form state
 * @param { Function } handleChange - function for set state of simple form elements
 * @param { Function } cleanErrors - function for clear errors array
 */
export const VacationModalDates = memo(
  ({
    formState,
    setFormState,
    formErrors,
    handleChange,
    cleanErrors,
    isSingleDayAbsence = false,
  }) => {
    const handleDateStartChange = useCallback(
      (date) => {
        setFormState((prev) => ({ ...prev, dateStart: date }));
        cleanErrors();
      },
      [cleanErrors, setFormState]
    );

    const handleDateEndChange = useCallback(
      (date) => {
        setFormState((prev) => ({ ...prev, dateEnd: date }));
        cleanErrors();
      },
      [cleanErrors, setFormState]
    );

    /* Defines an unselectable date range */
    const disabledEndDate = useCallback(
      (endValue) => {
        if (!endValue || !formState.dateStart) {
          return false;
        }
        return endValue.isSameOrBefore(formState.dateStart);
      },
      [formState]
    );

    return (
      <>
        <Row type="flex" className={Styles.row}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
            <div className={Styles.labelWrapper}>
              <Text className={Styles.formLabel}>{LABEL_TEXTS.DATE_START}</Text>
              <Text className={Styles.formHelp}>
                {LABEL_TEXTS.DATE_START_SMALL}
              </Text>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
            <DatePicker
              size="large"
              className={clsx(
                Styles.date,
                formErrors.includes('dateStart') && Styles.errorField
              )}
              placeholder={DATE_START_PLACEHOLDER}
              suffixIcon={<CalendarMiniIcon className={Styles.calendarIcon} />}
              name="dateStart"
              value={formState.dateStart}
              onChange={handleDateStartChange}
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            className={clsx(Styles.col, Styles.textRightSm)}
          >
            <Radio
              className={Styles.radioGroup}
              options={WORKING_TIME}
              value={formState.dateStartWorkingTime}
              onChange={handleChange}
              name="dateStartWorkingTime"
            />
          </Col>
        </Row>
        {!isSingleDayAbsence && (
          <Row type="flex" className={Styles.row}>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
              <div className={Styles.labelWrapper}>
                <Text className={Styles.formLabel}>{LABEL_TEXTS.DATE_END}</Text>
                <Text className={Styles.formHelp}>
                  {LABEL_TEXTS.DATE_END_SMALL}
                </Text>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
              <DatePicker
                size="large"
                className={clsx(
                  Styles.date,
                  formErrors.includes('dateEnd') && Styles.errorField
                )}
                placeholder={DATE_END_PLACEHOLDER}
                suffixIcon={
                  <CalendarMiniIcon className={Styles.calendarIcon} />
                }
                value={formState.dateEnd}
                name="dateEnd"
                onChange={handleDateEndChange}
                disabledDate={disabledEndDate}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              className={clsx(Styles.col, Styles.textRightSm)}
            >
              <Radio
                className={Styles.radioGroup}
                options={WORKING_TIME}
                value={formState.dateEndWorkingTime}
                onChange={handleChange}
                name="dateEndWorkingTime"
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
);

VacationModalDates.propTypes = propTypes;
