import React from 'react';
import { Typography } from 'antd';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
};

export const PageTitle = ({ children, className }) => (
  <Typography.Title
    className={clsx(MainStyles.heading, MainStyles.headingH1, className)}
  >
    {children}
  </Typography.Title>
);

PageTitle.propTypes = propTypes;
