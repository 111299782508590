import { selectCalendarAbsences } from 'modules/SalaryModule/Calendar/selectors';
import { createSelector } from 'reselect';

export const selectVacationModalData = (state) =>
  state.getIn(['vacationsReducer', 'vacationModalData']);

export const selectVacationModalSubmitType = (state) =>
  state.getIn(['vacationsReducer', 'submitType']);

export const selectVacationModalLeaveObject = createSelector(
  (state) => state.getIn(['vacationsReducer', 'leaveObjId']),
  selectCalendarAbsences,
  (leaveObjId, absences) => absences.find(({ id }) => id === leaveObjId) || null
);

export const selectVacationModalVisibility = (state) =>
  state.getIn(['vacationsReducer', 'isModalVisible']);

export const selectVacationModalStartDate = (state) =>
  state.getIn(['vacationsReducer', 'startDate']);

export const selectAbsencesRequestStatus = (state) =>
  state.getIn(['vacationsReducer', 'status']);

export const selectEmployeeAbsences = createSelector(
  (state) => state.getIn(['vacationsReducer', 'employeeAbsences']),
  (absences) => (absences ? absences.toJS() : [])
);

export const selectTableDefaultPage = (state) =>
  state.getIn(['vacationsReducer', 'tableDefaultPage']);
