import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import fieldErrorIcon from 'assets/icons/error.svg';
import Styles from '../AdditionalUserModal.module.css';

export const AdditionalUserModalError = ({
  errorMessage,
  isAboveField = false,
}) => (
  <div
    className={cn(Styles.errorWrapper, { [Styles.errorAbove]: isAboveField })}
  >
    <img
      className={Styles.fieldErrorIcon}
      src={fieldErrorIcon}
      alt="field error"
    />
    <span>{errorMessage}</span>
  </div>
);

AdditionalUserModalError.propTypes = {
  errorMessage: PropTypes.string,
  isAboveField: PropTypes.bool,
};
