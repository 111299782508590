import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { EXPANDABLE_PARAGRAPH_TRANSLATIONS } from 'const/translations';
import Styles from './ExpandableParagraph.module.css';

const propTypes = {
  text: PropTypes.string,
};

const { EXPAND, COLLAPSE } = EXPANDABLE_PARAGRAPH_TRANSLATIONS;

export const ExpandableParagraph = ({ text }) => {
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(
    () => setExpanded((current) => !current),
    []
  );
  return (
    <div key={Number(isExpanded)}>
      <Typography.Paragraph
        ellipsis={{
          rows: isExpanded ? 20 : 3,
          onExpand: toggleExpanded,
        }}
      >
        {text}
      </Typography.Paragraph>
      <button
        type="button"
        onClick={toggleExpanded}
        className={Styles.closeButton}
      >
        {isExpanded ? COLLAPSE : EXPAND}
      </button>
    </div>
  );
};

ExpandableParagraph.propTypes = propTypes;
