import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import { fromJS, Map } from 'immutable';
import {
  FORM_INFOS_SUCCESS,
  FORM_INFOS_REQUEST,
  CIVIL_DATA_UPLOAD_REQUEST,
  CIVIL_DATA_UPLOAD_SUCCESS,
  CIVIL_DATA_UPLOAD_ERROR,
  CIVIL_DATA_UPDATE_LOCAL,
  SALARY_SITUATION_UPLOAD_REQUEST,
  SALARY_SITUATION_UPLOAD_SUCCESS,
  SALARY_SITUATION_UPLOAD_ERROR,
  SALARY_SITUATION_UPDATE_LOCAL,
  FORM_INFOS_ERROR,
  WORK_CONTRACT_UPLOAD_REQUEST,
  WORK_CONTRACT_UPLOAD_SUCCESS,
  WORK_CONTRACT_UPLOAD_ERROR,
  WORK_CONTRACT_UPDATE_LOCAL,
  SPECIAL_SITUATIONS_UPLOAD_REQUEST,
  SPECIAL_SITUATIONS_UPLOAD_SUCCESS,
  SPECIAL_SITUATIONS_UPLOAD_ERROR,
  SPECIAL_SITUATIONS_UPDATE_LOCAL,
  DOCUMENTS_UPDATE_LOCAL,
  DOCUMENTS_UPLOAD_PROGRESS,
  DOCUMENTS_UPLOAD_ERROR,
  SUBMIT_FORM_SUCCESS,
  DOCUMENTS_UPLOAD_REQUEST,
} from '../actions';

const getTypicalStoreStructure = (tabName) => ({
  uploadStatus: STATUS_NOT_REQUESTED,
  data: { values: {} },
  isValid: false,
  [`${tabName}Errors`]: {},
  error: '',
});
const initialState = fromJS({
  status: STATUS_NOT_REQUESTED,
  submitStatus: STATUS_NOT_REQUESTED,
  civilData: getTypicalStoreStructure('civilData'),
  salarySituation: getTypicalStoreStructure('salarySituation'),
  workContract: getTypicalStoreStructure('workContract'),
  specialSituations: getTypicalStoreStructure('specialSituations'),
  documents: getTypicalStoreStructure('documents'),
});
/**
 * TODO
 * Divide reducer by form tabs
 */
export const employeeRegister = (state = initialState, action) => {
  switch (action.type) {
    case FORM_INFOS_REQUEST:
      return state.set('status', STATUS_LOADING);
    case FORM_INFOS_SUCCESS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('submitStatus', STATUS_NOT_REQUESTED)
        .mergeDeep(action.payload);
    case FORM_INFOS_ERROR:
      return state.set('status', STATUS_ERROR);
    case CIVIL_DATA_UPLOAD_REQUEST:
      return state.setIn(['civilData', 'uploadStatus'], STATUS_LOADING);
    case CIVIL_DATA_UPLOAD_SUCCESS:
      return state.setIn(['civilData', 'uploadStatus'], STATUS_SUCCESS);
    case CIVIL_DATA_UPLOAD_ERROR: {
      return state
        .setIn(['civilData', 'uploadStatus'], STATUS_ERROR)
        .setIn(['civilData', 'error'], action.payload.error.message || 'error');
    }
    case CIVIL_DATA_UPDATE_LOCAL: {
      const { isValid, civilDataErrors, values } = action.payload;
      return state
        .setIn(['civilData', 'isValid'], isValid)
        .setIn(['civilData', 'data', 'values'], fromJS(values))
        .setIn(['civilData', 'civilDataErrors'], fromJS(civilDataErrors));
    }
    case SALARY_SITUATION_UPLOAD_REQUEST:
      return state.setIn(['salarySituation', 'uploadStatus'], STATUS_LOADING);
    case SALARY_SITUATION_UPLOAD_SUCCESS:
      return state.setIn(['salarySituation', 'uploadStatus'], STATUS_SUCCESS);
    case SALARY_SITUATION_UPLOAD_ERROR: {
      return state
        .setIn(['salarySituation', 'uploadStatus'], STATUS_ERROR)
        .setIn(
          ['salarySituation', 'error'],
          action.payload.error.message || 'error'
        );
    }
    case SALARY_SITUATION_UPDATE_LOCAL: {
      const { isValid, salarySituationErrors, values } = action.payload;
      return state
        .setIn(['salarySituation', 'isValid'], isValid)
        .setIn(['salarySituation', 'data', 'values'], fromJS(values))
        .setIn(
          ['salarySituation', 'salarySituationErrors'],
          fromJS(salarySituationErrors)
        );
    }
    case WORK_CONTRACT_UPLOAD_REQUEST:
      return state.setIn(['workContract', 'uploadStatus'], STATUS_LOADING);
    case WORK_CONTRACT_UPLOAD_SUCCESS:
      return state.setIn(['workContract', 'uploadStatus'], STATUS_SUCCESS);
    case WORK_CONTRACT_UPLOAD_ERROR: {
      return state
        .setIn(['workContract', 'uploadStatus'], STATUS_ERROR)
        .setIn(
          ['workContract', 'error'],
          action.payload.error.message || 'error'
        );
    }
    case WORK_CONTRACT_UPDATE_LOCAL: {
      const { isValid, workContractErrors, values } = action.payload;
      return state
        .setIn(['workContract', 'isValid'], isValid)
        .setIn(['workContract', 'data', 'values'], fromJS(values))
        .setIn(
          ['workContract', 'workContractErrors'],
          fromJS(workContractErrors)
        )
        .setIn(['workContract', 'error'], '');
    }
    case SPECIAL_SITUATIONS_UPLOAD_REQUEST:
      return state.setIn(['specialSituations', 'uploadStatus'], STATUS_LOADING);
    case SPECIAL_SITUATIONS_UPLOAD_SUCCESS:
      return state.setIn(['specialSituations', 'uploadStatus'], STATUS_SUCCESS);
    case SPECIAL_SITUATIONS_UPLOAD_ERROR: {
      return state
        .setIn(['specialSituations', 'uploadStatus'], STATUS_ERROR)
        .setIn(
          ['specialSituations', 'error'],
          action.payload.error.message || 'error'
        );
    }
    case SPECIAL_SITUATIONS_UPDATE_LOCAL: {
      const { isValid, specialSituationsErrors, values } = action.payload;
      return state
        .setIn(['specialSituations', 'isValid'], isValid)
        .setIn(['specialSituations', 'data', 'values'], fromJS(values))
        .setIn(
          ['specialSituations', 'specialSituationsErrors'],
          fromJS(specialSituationsErrors)
        );
    }
    case DOCUMENTS_UPLOAD_REQUEST:
      return state.setIn(['documents', 'uploadStatus'], STATUS_LOADING);
    case DOCUMENTS_UPDATE_LOCAL: {
      const {
        isValid,
        documentsErrors,
        values: { comment, ...restValues },
      } = action.payload;
      return state
        .setIn(['documents', 'isValid'], isValid)
        .setIn(['documents', 'data', 'values'], Map(restValues))
        .setIn(['documents', 'documentsErrors'], fromJS(documentsErrors));
    }
    case DOCUMENTS_UPLOAD_PROGRESS: {
      const progress = action.payload;
      return state.setIn(['documents', 'data', 'progress'], progress);
    }
    case DOCUMENTS_UPLOAD_ERROR: {
      const error = action.payload;
      return state
        .setIn(['documents', 'error'], error)
        .setIn(['documents', 'data', 'progress'], 0);
    }
    case SUBMIT_FORM_SUCCESS:
      return initialState.set('submitStatus', STATUS_SUCCESS);
    default:
      return state;
  }
};
