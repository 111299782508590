export const NPS_MODAL_TRANSLATIONS = {
  TITLE: 'Donnez-nous votre avis !',
  APPEAL: 'Bonjour {{USERNAME}},',
  INTRODUCTION:
    'Aidez-nous à enrichir votre expérience, en répondant à la question suivante :',
  QUESTION:
    'Sur une échelle de 1 à 10, recommanderiez-vous My Exco à vos collègues ou à vos pairs ?',
  COMMENT: 'Que pourrions-nous faire pour améliorer votre satisfaction ?',
  MESSAGE_PLACEHOLDER: 'Votre commentaire',
  OK_BUTTON: 'Envoyer',
  CANCEL_BUTTON: 'Annuler',
  ERROR_REQUIRED_SCORE: 'Une évaluation est requise',
};
