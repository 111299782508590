import { USEFUL_LINKS_MODAL_TRANSLATIONS } from '../translations/usefulLinks';

export const MODAL_ERRORS = {
  REQUIRED: 'required',
  WRONG_FORMAT: 'format error',
};

export const MODAL_ERRORS_BY_STATUS = {
  [MODAL_ERRORS.REQUIRED]: USEFUL_LINKS_MODAL_TRANSLATIONS.REQUIRED_ERROR,
  [MODAL_ERRORS.WRONG_FORMAT]: USEFUL_LINKS_MODAL_TRANSLATIONS.LINK_ERROR,
};
