import { Col, Form, Row, Typography } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button } from 'components/ui';
import MainStyles from 'assets/styles/main.module.css';
import { COMPANY_SETTINGS_REWARDS } from '../../../../../const/translations/SalaryCompanySettings';
import {
  createRewardFamily,
  updateRewardFamily,
} from '../../../../../modules/SalaryModule/Rewards/actions';
import Styles from '../CompanySettingsManageRewardItemPage.module.css';

const REWARD_TITLE_LIMIT = 60;

const propTypes = {
  companyId: PropTypes.string,
  familyData: PropTypes.object,
  form: PropTypes.object,
};

/**
 * Component for editing the title of the reward family
 * @param { String } companyId - current company id
 * @param { Object } familyData - data and rewards of current family
 * @param { Object } form - object from form wrap
 */
const ManageRewardFamilyTitle = memo(({ companyId, familyData, form }) => {
  const dispatch = useDispatch();

  const familyTitleBtnHandler = useCallback(
    (e) => {
      e.preventDefault();
      const { title } = form.getFieldsValue();
      if (title.length > 0) {
        if (familyData) {
          dispatch(
            updateRewardFamily({
              companyId,
              title,
              familyId: familyData.id,
            })
          );
        } else {
          dispatch(createRewardFamily({ companyId, title }));
        }
      }
    },
    [form, companyId, familyData, dispatch]
  );

  return (
    <Form onSubmit={familyTitleBtnHandler}>
      <Typography.Title
        className={clsx(MainStyles.heading, MainStyles.headingH1, Styles.title)}
      >
        {COMPANY_SETTINGS_REWARDS.FAMILY_PAGE_TITLE}
      </Typography.Title>
      <Row type="flex" align="middle" className={Styles.row}>
        <Col xs={24} sm={24}>
          <Typography.Text
            className={clsx(
              MainStyles.heading,
              MainStyles.formLabel,
              Styles.label
            )}
          >
            {COMPANY_SETTINGS_REWARDS.FAMILY_TITLE}
          </Typography.Text>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item className={Styles.inputWrap}>
            {form.getFieldDecorator('title', {
              initialValue: familyData?.title || '',
              rules: [
                { required: true, max: REWARD_TITLE_LIMIT, message: null },
              ],
            })(<Input size="large" />)}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={4} className={Styles.btnWrap}>
          <Button
            className={Styles.btn}
            variant="primary"
            onClick={familyTitleBtnHandler}
            type="submit"
          >
            {COMPANY_SETTINGS_REWARDS.SAVE_BTN_TEXT}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

ManageRewardFamilyTitle.propTypes = propTypes;

export const ManageRewardFamilyTitleForm = Form.create()(
  ManageRewardFamilyTitle
);
