import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import { EMPTY_FUNCTION, UPLOAD_DOCUMENTS_FILE_ERRORS } from 'const';
import Styles from './File.module.css';

const {
  FILES_LIST: {
    TOOLTIP_TEXT: {
      COUNT_LIMIT_ERROR_MESSAGE_10,
      COUNT_LIMIT_ERROR_MESSAGE_20,
      SIZE_LIMIT_ERROR_MESSAGE_10MO,
      SIZE_LIMIT_ERROR_MESSAGE_25MO,
      TYPE_FORBIDDEN_ERROR_MESSAGE,
    },
  },
} = UPLOAD_DOCUMENT_TRANSLATIONS;
const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

const propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.shape({
    [COUNT_LIMIT_ERROR]: PropTypes.bool,
    [SIZE_LIMIT_ERROR]: PropTypes.bool,
    [TYPE_FORBIDDEN_ERROR]: PropTypes.bool,
  }),
  onRemove: PropTypes.func,
  className: PropTypes.string,
  removeIcon: PropTypes.node,
  file: PropTypes.any,
  onFileDownload: PropTypes.func,
  hasDownload: PropTypes.bool,
};

export const File = ({
  className,
  removeIcon,
  uid,
  name = '',
  onRemove,
  onFileDownload,
  errors = [],
  file = null,
  hasDownload = false,
}) => {
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);

  const messages = [];
  if (errors[COUNT_LIMIT_ERROR])
    messages.push(
      isPreCompta ? COUNT_LIMIT_ERROR_MESSAGE_20 : COUNT_LIMIT_ERROR_MESSAGE_10
    );
  if (errors[SIZE_LIMIT_ERROR])
    messages.push(
      isPreCompta
        ? SIZE_LIMIT_ERROR_MESSAGE_25MO
        : SIZE_LIMIT_ERROR_MESSAGE_10MO
    );
  if (errors[TYPE_FORBIDDEN_ERROR]) messages.push(TYPE_FORBIDDEN_ERROR_MESSAGE);

  const tooltipTitle = messages.join('\n\n');

  const disabled = Object.values(errors).some((err) => err === true);

  const removeFile = useCallback(() => {
    if (onRemove) return onRemove(uid, name);
    return EMPTY_FUNCTION();
  }, [uid, name, onRemove]);
  const handleFileDownload = useCallback(() => {
    if (onFileDownload) onFileDownload(file, name);
  }, [file, name, onFileDownload]);

  return (
    <Tooltip
      title={disabled ? tooltipTitle : null}
      overlayClassName={Styles.tooltip}
      placement="topRight"
    >
      <div className={clsx(Styles.fileItem, className)}>
        <Icon
          type="file"
          className={clsx(Styles.Icon, {
            [Styles.disabled]: disabled,
          })}
        />
        <span
          title={name}
          className={clsx(Styles.fileName, {
            [Styles.disabled]: disabled,
            [Styles.download]: hasDownload,
          })}
          onClick={handleFileDownload}
        >
          {name.length > 70
            ? `${name.substring(0, 52)}...${name.slice(-10)}`
            : name}
        </span>
        <div>
          {disabled && (
            <Icon
              type="exclamation-circle"
              style={{ color: 'var(--red)', marginLeft: '5px' }}
            />
          )}
        </div>
        {onRemove && (
          <div onClick={removeFile}>
            {removeIcon || (
              <Icon
                type="delete"
                style={{
                  color: 'var(--darkBlue)',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
              />
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

File.propTypes = propTypes;
