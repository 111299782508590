import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { useState } from 'react';
import { getCurrentMonthNameFromConst } from 'helpers/monthSelector';

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { LINE } = CHARTS_VIEW_SWITCH_TYPES;

export const useChartFilters = (
  dateViewOptionDefaultValue = YEAR,
  chartViewDefaultActiveMode = LINE,
  currentYearFromExercice
) => {
  const [activeDateViewOption, setActiveDateViewOption] = useState(
    dateViewOptionDefaultValue
  );

  const [activeMonthName, setActiveMonthName] = useState(
    getCurrentMonthNameFromConst()
  );

  const [activeYear, setActiveYear] = useState(currentYearFromExercice);

  const [chartViewActiveMode, setChartViewActiveMode] = useState(
    chartViewDefaultActiveMode
  );

  return {
    activeDateViewOption,
    setActiveDateViewOption,
    activeMonthName,
    setActiveMonthName,
    activeYear,
    setActiveYear,
    chartViewActiveMode,
    setChartViewActiveMode,
  };
};
