import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { DatePickerAdapted } from 'components/adaptors/DatePicker';
import { DATE_FORMAT } from 'const';
import { useDispatch } from 'react-redux';
import {
  cancelInvoicePrevalidationRequest,
  invoicesApproveRequest,
  invoicesDevalidateRequest,
  invoicesPaidOutsideRequest,
} from 'modules/invoiceSearch/actions';
import Styles from './ApproveModal.module.css';

const propTypes = {
  documentId: PropTypes.string,
  defaultDate: PropTypes.string,
  preValidated: PropTypes.bool,
  paidOutside: PropTypes.bool,
  handleClose: PropTypes.func,
};

const {
  MODAL: { APPROVE_COMMENTARY, SELECT_DUE_DATE },
  BUTTONS: { APPROVE, CLOSE_MODAL, PAID_OUTSIDE, RESET_VALIDATION },
} = INVOICE_SEARCH_TRANSLATIONS;

export const ApproveModal = ({
  documentId,
  defaultDate,
  preValidated,
  paidOutside,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const handleApprove = useCallback(
    (approvedDueDate, paymentApprovedComment) => {
      dispatch(
        invoicesApproveRequest({
          approvedDueDate,
          paymentApprovedComment,
          documentId,
        })
      );
      handleClose();
    },
    [dispatch, documentId, handleClose]
  );

  const [date, setDate] = useState(defaultDate);
  const [commentary, setCommentary] = useState('');
  const handleChange = useCallback(
    ({ target: { name, value } }) =>
      name === 'date' ? setDate(value) : setCommentary(value),
    [setDate, setCommentary]
  );
  const handleOk = useCallback(() => {
    handleApprove(date || defaultDate, commentary);
  }, [commentary, date, defaultDate, handleApprove]);

  const handleResetValidation = useCallback(() => {
    if (paidOutside) {
      dispatch(invoicesDevalidateRequest({ documentId }));
    } else if (preValidated) {
      dispatch(cancelInvoicePrevalidationRequest({ documentId }));
    }
    handleClose();
  }, [dispatch, documentId, handleClose, paidOutside, preValidated]);

  const handlePaidOutside = useCallback(() => {
    dispatch(invoicesPaidOutsideRequest({ documentId, commentary }));
    handleClose();
  }, [dispatch, commentary, documentId, handleClose]);

  return (
    <>
      <div className={Styles.invoiceApproveModalRow}>
        {SELECT_DUE_DATE}
        <DatePickerAdapted
          onChange={handleChange}
          storageFormat={DATE_FORMAT}
          value={date}
          name="date"
          allowClear={false}
        />
      </div>
      <div className={Styles.invoiceApproveModalRow}>
        {APPROVE_COMMENTARY}
        <Input.TextArea
          onChange={handleChange}
          value={commentary}
          name="commentary"
        />
      </div>
      <div className={Styles.invoiceApproveModalButtonRow}>
        {(preValidated || paidOutside) && (
          <Button onClick={handleResetValidation}>{RESET_VALIDATION}</Button>
        )}
        {!paidOutside && (
          <Button onClick={handlePaidOutside}>{PAID_OUTSIDE}</Button>
        )}
      </div>
      <div className={Styles.invoiceApproveModalButtonRow}>
        <Button onClick={handleClose}>{CLOSE_MODAL}</Button>

        <Button onClick={handleOk}>{APPROVE}</Button>
      </div>
    </>
  );
};

ApproveModal.propTypes = propTypes;
