import { getType, getActionCreator } from 'utils';
const prefix = '@@app/creditors';

/**
 * Creditors
 */

export const CREDITORS_SEARCH_REQUEST = getType(
  prefix,
  'CREDITORS_SEARCH_REQUEST'
);
export const creditorsSearchRequest = getActionCreator(
  CREDITORS_SEARCH_REQUEST
);

export const CREDITORS_SEARCH_SUCCESS = getType(
  prefix,
  'CREDITORS_SEARCH_SUCCESS'
);
export const creditorsSearchSuccess = getActionCreator(
  CREDITORS_SEARCH_SUCCESS
);

export const CREDITORS_SEARCH_ERROR = getType(prefix, 'CREDITORS_SEARCH_ERROR');
export const creditorsSearchError = getActionCreator(CREDITORS_SEARCH_ERROR);

/**
 * Creditor
 */

export const CREDITOR_DATA_REQUEST = getType(prefix, 'CREDITOR_DATA_REQUEST');
export const creditorDataRequest = getActionCreator(CREDITOR_DATA_REQUEST);

export const CREDITOR_DATA_SUCCESS = getType(prefix, 'CREDITOR_DATA_SUCCESS');
export const creditorDataSuccess = getActionCreator(CREDITOR_DATA_SUCCESS);

export const CREDITOR_DATA_ERROR = getType(prefix, 'CREDITOR_DATA_ERROR');
export const creditorDataError = getActionCreator(CREDITOR_DATA_ERROR);
