import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/Spinner';
import Styles from './IframeModal.module.css';

const propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};

export const IframeModal = ({ src, title }) => {
  const [isFrameLoading, setIsFrameLoading] = useState(true);

  const handleFrameLoad = () => {
    setIsFrameLoading(false);
  };

  return (
    <div className={Styles.iframeWrapper}>
      {isFrameLoading && (
        <Spinner className={Styles.iframeLoader} size="large" />
      )}
      <iframe
        className={Styles.iframe}
        onLoad={handleFrameLoad}
        src={src}
        title={title}
      />
    </div>
  );
};

IframeModal.propTypes = propTypes;
