import { getType } from 'utils';
const prefix = '@@app/polling';

export const START_LONG_POLLING = getType(prefix, 'START_LONG_POLLING');
// export const startLongPolling = getActionCreator(START_LONG_POLLING);

export const STOP_LONG_POLLING = getType(prefix, 'STOP_LONG_POLLING');
// export const stopLongPolling = getActionCreator(STOP_LONG_POLLING);

// // export const START_ADDITIONAL_POLLING = getType(
// //   prefix,
// //   'START_ADDITIONAL_POLLING'
// // );
// // export const startAdditionalPolling = getActionCreator(
// //   START_ADDITIONAL_POLLING
// // );

// // export const END_POLLING_CYCLE = getType(prefix, 'END_POLLING_CYCLE');
// // export const endPollingCycle = getActionCreator(END_POLLING_CYCLE);
