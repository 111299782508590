import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Typography } from 'antd';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DEFAULT_SCHEDULE_INTERVALS_EMPTY } from '../../../../const/SalaryCompanySettings';
import { COMPANY_SETTINGS } from '../../../../const/translations/SalaryCompanySettings';
import {
  selectDefaultSchedule,
  selectScheduleDetailData,
} from '../../../../modules/SalaryModule/CompanySettings/selectors';
import { getSummaryTotalTime } from '../../../../modules/SalaryModule/CompanySettings/utils';
import Styles from './ScheduleForm.module.css';
import { ScheduleFormItem } from '../ScheduleFormItem';

const propTypes = {
  activeTab: PropTypes.string,
  setScheduleDay: PropTypes.func,
};
const { SCHEDULE_FORM } = COMPANY_SETTINGS;
const { Text } = Typography;
/*
 * Form has two type of states - all days have same schedule or every day has own schedule,
 * it depends on the selected tab
 * */
export const ScheduleForm = memo(({ activeTab, setScheduleDay }) => {
  const [totalTime, setTotalTime] = useState(null);
  const [sameRowData, setSameRowData] = useState(null);
  const [schedulesByDays, setSchedulesByDays] = useState(
    DEFAULT_SCHEDULE_INTERVALS_EMPTY
  );
  const [summaryTime, setSummaryTime] = useState({ hours: 0, minutes: 0 });
  const scheduleData = useSelector(selectScheduleDetailData);
  const scheduleDataDefault = useSelector(selectDefaultSchedule);
  const TOTAL_TIME_STRING = `Total : ${summaryTime.hours} h ${
    summaryTime.minutes === 0 ? '00' : summaryTime.minutes
  } min`;
  useEffect(() => {
    if (totalTime) {
      setSummaryTime(getSummaryTotalTime(totalTime));
    }
  }, [totalTime]);

  useEffect(() => {
    const updatedDays = scheduleData
      ? scheduleData.schedule.days
      : scheduleDataDefault.days;
    setSchedulesByDays((prev) => ({ ...prev, ...updatedDays }));
  }, [scheduleData, scheduleDataDefault]);

  useEffect(() => {
    if (activeTab === SCHEDULE_FORM.TAB_DIFFERENT) {
      setSameRowData(null);
    }
  }, [activeTab]);

  const setRowData = useCallback((data) => {
    setSameRowData(data);
  }, []);

  return (
    <div className={Styles.formWrapper}>
      {activeTab === SCHEDULE_FORM.TAB_SAME && (
        <ScheduleFormItem
          key="sameHours"
          activeTab={activeTab}
          withMain
          daySchedule={
            Object.values(schedulesByDays).find((gaps) => gaps.length) || []
          }
          setSameRowData={setRowData}
          sameRowData={sameRowData}
        />
      )}

      {Object.keys(schedulesByDays).map((item) => (
        <ScheduleFormItem
          activeTab={activeTab}
          key={item}
          daySchedule={schedulesByDays[item]}
          dayKey={item}
          setTotalTime={setTotalTime}
          sameRowData={sameRowData}
          setScheduleDay={setScheduleDay}
        />
      ))}
      <div className={Styles.total}>
        <Text className={clsx(MainStyles.heading, MainStyles.headingH3)}>
          {TOTAL_TIME_STRING}
        </Text>
      </div>
    </div>
  );
});

ScheduleForm.propTypes = propTypes;
