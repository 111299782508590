import { getType, getActionCreator } from 'utils';
const prefix = '@@app/unsubscribe';

export const UNSUBSCRIBE_USER_REQUEST = getType(
  prefix,
  'UNSUBSCRIBE_USER_REQUEST'
);
export const unsubscribeUserRequest = getActionCreator(
  UNSUBSCRIBE_USER_REQUEST
);

const UNSUBSCRIBE_USER_ERROR = getType(prefix, 'UNSUBSCRIBE_USER_ERROR');
export const unsubscribeUserError = getActionCreator(UNSUBSCRIBE_USER_ERROR);
