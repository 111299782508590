import { Button, Icon } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Styles from './RewardsTable.module.css';

const propTypes = {
  record: PropTypes.object,
  editable: PropTypes.bool,
  canBeEdited: PropTypes.bool,
  saveEditHandler: PropTypes.func,
  cancelEditHandler: PropTypes.func,
  // editHandler: PropTypes.func,
  onRemoveRewardsItem: PropTypes.func,
};
/**
 * Return buttons with actions for rewards table depending on row editing status
 * @param { Object } record - reward object with data
 * @param { Boolean } editable - row editing status
 * @param { String } canBeEdited - can you press edit btn
 * @param { Function } saveEditHandler - save btn click handler
 * @param { Function } cancelEditHandler - remove editing status for row handler
 * @param { Function } editHandler - add editing status for row handler
 * @param { Function } onRemoveRewardsItem - open remove modal with this reward data
 */
export const ControlsCell = ({
  record,
  editable,
  saveEditHandler,
  cancelEditHandler,
  // editHandler,
  onRemoveRewardsItem,
  canBeEdited,
}) => {
  const { id, canBeRemoved, rewardFamilyId } = record;

  return (
    <div className={Styles.actionsWrapper}>
      {editable ? (
        <>
          <Button
            size="small"
            className={Styles.action}
            data-id={id}
            data-family={rewardFamilyId}
            onClick={saveEditHandler}
          >
            <Icon
              type="check-circle"
              theme="filled"
              className={Styles.darkIcon}
            />
          </Button>
          <Button
            size="small"
            className={Styles.action}
            onClick={cancelEditHandler}
          >
            <Icon type="close" className={Styles.blackIcon} />
          </Button>
        </>
      ) : (
        <>
          {/* <Button
            disabled={!canBeEdited}
            size="small"
            className={Styles.action}
            data-id={id}
            onClick={editHandler}
          >
            <Icon type="edit" theme="filled" className={Styles.greenIcon} />
          </Button> */}
          <Button
            disabled={!canBeEdited}
            size="small"
            className={Styles.action}
            data-id={id}
            data-removable={canBeRemoved ? 'remove' : ''}
            onClick={onRemoveRewardsItem}
          >
            <Icon type="delete" theme="filled" className={Styles.greenIcon} />
          </Button>
        </>
      )}
    </div>
  );
};

ControlsCell.propTypes = propTypes;
