import React, { useEffect, memo, useMemo, useCallback } from 'react';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { Steps, Progress } from 'antd';
import { useNavigation } from 'hooks';
import { CivilDataFormTab } from 'components/EmployeeRegister/CivilData';
import { SalarySituationFormTab } from 'components/EmployeeRegister/SalarySituation';
import { WorkContractFormTab } from 'components/EmployeeRegister/WorkContract';
import { SpecialSituationsFormTab } from 'components/EmployeeRegister/SpecialSituations';
import { VouchersFormTab } from 'components/EmployeeRegister/Vouchers';
import {
  selectCivilData,
  selectCivilDataErrors,
  selectDocumentsData,
  selectDocumentsErrors,
  selectSalarySituationData,
  selectSalarySituationErrors,
  selectSpecialSituationsErrors,
  selectSpecialSituationsData,
  selectWorkContractData,
  selectWorkContractErrors,
  selectProgress,
  selectFirst4TabsOverallStatus,
  selectFirst4TabsIsValidArray,
  selectSubmitStatus,
  selectDocumentsUploadStatus,
} from 'modules/employeeRegister/selectors';
import {
  formInfosRequest,
  civilDataUploadRequest,
  documentsUploadRequest,
  salarySituationUploadRequest,
  specialSituationsUploadRequest,
  workContractUploadRequest,
  documentsUpdateLocal,
  resetFormRequest,
} from 'modules/employeeRegister/actions';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './EmployeeRegister.module.css';

const { TITLE, TABS, GENERAL_LABELS } = ADD_EMPLOYEE_FORM_TRANSLATIONS;
const { Step } = Steps;
const stepsComponents = [
  CivilDataFormTab,
  SalarySituationFormTab,
  WorkContractFormTab,
  SpecialSituationsFormTab,
  VouchersFormTab,
];

const renderTab = (currentIndex, props) => {
  const Component = stepsComponents[currentIndex];
  return <Component {...props} />;
};

const STEPS_STATUSES = {
  ERROR: 'error',
  FINISH: 'finish',
  WAIT: 'wait',
};

const stepDescriptionsByStepsStatus = {
  [STEPS_STATUSES.FINISH]: GENERAL_LABELS.COMPLETE,
  [STEPS_STATUSES.ERROR]: GENERAL_LABELS.TO_FILL,
  [STEPS_STATUSES.WAIT]: GENERAL_LABELS.TO_FILL,
};

const renderStep = (step, idx, current, isValidArray) => {
  const status = getStatus(idx, current, step.values, isValidArray[idx]);
  return (
    <Step
      key={`step-${idx}`}
      title={step.title}
      status={status}
      description={
        idx === current
          ? GENERAL_LABELS.CURRENT_TAB
          : stepDescriptionsByStepsStatus[status]
      }
    />
  );
};

const getStatus = (index, activeIndex, values, isTabValid) => {
  const existValues = Object.values(values).length;
  if (existValues) {
    if (index === 4) {
      return STEPS_STATUSES.FINISH;
    }
    return isTabValid ? STEPS_STATUSES.FINISH : STEPS_STATUSES.ERROR;
  }
  return STEPS_STATUSES.WAIT;
};

const EmployeeRegisterFormComponent = () => {
  const dispatch = useDispatch();

  const submitStatus = useSelector(selectSubmitStatus);
  const documentsUploadStatus = useSelector(selectDocumentsUploadStatus);
  const civilData = useSelector(selectCivilData);
  const civilDataErrors = useSelector(selectCivilDataErrors);
  const salarySituationData = useSelector(selectSalarySituationData);
  const salarySituationErrors = useSelector(selectSalarySituationErrors);
  const workContractData = useSelector(selectWorkContractData);
  const workContractErrors = useSelector(selectWorkContractErrors);
  const specialSituationsData = useSelector(selectSpecialSituationsData);
  const specialSituationsErrors = useSelector(selectSpecialSituationsErrors);
  const documentsData = useSelector(selectDocumentsData);
  const documentsErrors = useSelector(selectDocumentsErrors);
  const progress = useSelector(selectProgress);
  const first4TabsIsValidArray = useSelector(selectFirst4TabsIsValidArray);
  const isFirst4TabsValid = useSelector(selectFirst4TabsOverallStatus);

  const updateCivilData = useCallback(
    (props) => dispatch(civilDataUploadRequest(props)),
    [dispatch]
  );
  const updateSalarySituation = useCallback(
    (props) => dispatch(salarySituationUploadRequest(props)),
    [dispatch]
  );
  const updateWorkContract = useCallback(
    (props) => dispatch(workContractUploadRequest(props)),
    [dispatch]
  );
  const updateSpecialSituations = useCallback(
    (props) => dispatch(specialSituationsUploadRequest(props)),
    [dispatch]
  );
  const updateDocumentsLocal = useCallback(
    (props) => dispatch(documentsUpdateLocal(props)),
    [dispatch]
  );
  const initiateFormSubmit = useCallback(
    (props) => dispatch(documentsUploadRequest(props)),
    [dispatch]
  );
  const resetData = useCallback((props) => dispatch(resetFormRequest(props)), [
    dispatch,
  ]);

  const { current, onTabChange, onMoveNext, onMovePrev } = useNavigation(
    stepsComponents
  );
  const stepsProps = useMemo(
    () => [
      {
        onNext: onMoveNext,
        civilData,
        updateCivilData,
        civilDataErrors,
        isTabValid: first4TabsIsValidArray[0],
        resetData,
      },
      {
        onNext: onMoveNext,
        onPrev: onMovePrev,
        salarySituationData,
        salarySituationErrors,
        updateSalarySituation,
        isTabValid: first4TabsIsValidArray[1],
        resetData,
      },
      {
        onNext: onMoveNext,
        onPrev: onMovePrev,
        workContractData,
        workContractErrors,
        updateWorkContract,
        isTabValid: first4TabsIsValidArray[2],
        resetData,
      },
      {
        onNext: onMoveNext,
        onPrev: onMovePrev,
        specialSituationsData,
        specialSituationsErrors,
        updateSpecialSituations,
        isTabValid: first4TabsIsValidArray[3],
        resetData,
      },
      {
        onPrev: onMovePrev,
        documentsData,
        documentsErrors,
        updateDocumentsLocal,
        initiateFormSubmit,
        salarySituationData,
        specialSituationsData,
        isFirst4TabsValid,
        submitStatus,
        documentsUploadStatus,
        resetData,
      },
    ],
    [
      civilData,
      civilDataErrors,
      documentsData,
      documentsErrors,
      documentsUploadStatus,
      first4TabsIsValidArray,
      initiateFormSubmit,
      isFirst4TabsValid,
      onMoveNext,
      onMovePrev,
      resetData,
      salarySituationData,
      salarySituationErrors,
      specialSituationsData,
      specialSituationsErrors,
      submitStatus,
      updateCivilData,
      updateDocumentsLocal,
      updateSalarySituation,
      updateSpecialSituations,
      updateWorkContract,
      workContractData,
      workContractErrors,
    ]
  );
  const steps = useMemo(
    () => [
      {
        title: TABS.CIVIL_DATA,
        values: civilData,
        errors: civilDataErrors,
      },
      {
        title: TABS.SALARY_SITUATION,
        values: salarySituationData,
        errors: salarySituationErrors,
      },
      {
        title: TABS.WORK_CONTRACT,
        values: workContractData,
        errors: workContractErrors,
      },
      {
        title: TABS.SPECIAL_SITUATIONS,
        values: specialSituationsData,
        errors: specialSituationsErrors,
      },
      {
        title: TABS.VOUCHERS,
        values: documentsData,
        errors: documentsErrors,
      },
    ],
    [
      civilData,
      civilDataErrors,
      documentsData,
      documentsErrors,
      salarySituationData,
      salarySituationErrors,
      specialSituationsData,
      specialSituationsErrors,
      workContractData,
      workContractErrors,
    ]
  );

  useEffect(() => {
    dispatch(formInfosRequest());
  }, [dispatch]);

  return (
    <div className={Styles.registrationPage}>
      <div className={Styles.containerWrapper}>
        <h1 className={Styles.title}>{TITLE}</h1>
        <Steps
          size="default"
          current={current}
          onChange={onTabChange}
          className={Styles.steps}
        >
          {steps.map((step, idx) =>
            renderStep(step, idx, current, first4TabsIsValidArray)
          )}
        </Steps>
        <div className={Styles.progress}>
          <span className={Styles.progressLabel}>
            {progress}% {GENERAL_LABELS.COMPLETE}
          </span>
          <span className={Styles.progressWrapper}>
            <Progress percent={progress} showInfo={false} />
          </span>
        </div>
        <div>{renderTab(current, stepsProps[current])}</div>
      </div>
    </div>
  );
};

export const EmployeeRegisterForm = memo(EmployeeRegisterFormComponent);
