import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { Formik } from 'formik';
import clsx from 'classnames';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Button } from 'components/ui/Button';
import { AdditionalUserModalError } from 'components/AdditionalUserModal/AdditionalUserModalError';
import { ADDITIONAL_USERS_MODAL_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import { MODAL_ERRORS, MODAL_ERRORS_BY_STATUS } from 'const/usefulLinks';
import { setBookmark, editBookmark } from 'modules/usefulLinks/actions';

import Styles from 'components/AdditionalUserModal/AdditionalUserModal.module.css';

const {
  NEW_LINK,
  LINK_NAME,
  LINK_ADDRESS,
  EDIT_LINK,
} = HOMEPAGE_FORM_TRANSLATIONS;

const {
  CANCEL_BUTTON,
  CONFIRM_BUTTON,
  EDIT_BUTTON,
} = ADDITIONAL_USERS_MODAL_TRANSLATIONS;
const { REQUIRED, WRONG_FORMAT } = MODAL_ERRORS;

function validateURL(url) {
  const re = /^((http|https|ftp):\/\/)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i;
  return re.test(String(url).toLowerCase());
}

export const UsefulLinksModal = ({
  isVisible,
  closeModal,
  initialValues = { id: null, name: null, url: null },
}) => {
  const dispatch = useDispatch();
  const isEdit = Boolean(initialValues.id);

  const onSubmit = (values) => {
    if (isEdit) {
      dispatch(editBookmark(values));
    } else {
      dispatch(setBookmark(values));
    }
    closeModal();
  };

  return (
    <Modal
      centered
      destroyOnClose
      visible={isVisible}
      footer={false}
      className={Styles.modal}
      closable={false}
      onCancel={closeModal}
    >
      <div className={Styles.modalTitle}>{isEdit ? EDIT_LINK : NEW_LINK}</div>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (values.url && !validateURL(values.url)) {
            errors.url = WRONG_FORMAT;
          }
          if (!values.name) {
            errors.name = REQUIRED;
          }
          if (!values.url) {
            errors.url = REQUIRED;
          }
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ values, handleSubmit, setErrors, errors, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <div className={Styles.modalInputWrapper}>
              <label htmlFor="AddUserName" className={Styles.modalInputLabel}>
                {LINK_NAME}
              </label>
              <input
                type="text"
                placeholder={LINK_NAME}
                id="AddUserName"
                value={values.name}
                name="name"
                onFocus={() => setErrors({})}
                onChange={handleChange}
                className={clsx(Styles.modalInput, {
                  [Styles.modalInputError]: errors.name,
                })}
              />
              {errors.name && (
                <AdditionalUserModalError
                  errorMessage={MODAL_ERRORS_BY_STATUS[errors.name]}
                />
              )}
            </div>
            <div className={Styles.modalInputWrapper}>
              <label htmlFor="URL" className={Styles.modalInputLabel}>
                {LINK_ADDRESS}
              </label>
              <input
                type="text"
                placeholder={LINK_ADDRESS}
                id="URL"
                value={values.url}
                name="url"
                onFocus={() => setErrors({})}
                onChange={handleChange}
                className={clsx(Styles.modalInput, {
                  [Styles.modalInputError]: errors.name,
                })}
              />
              {errors.url && (
                <AdditionalUserModalError
                  errorMessage={MODAL_ERRORS_BY_STATUS[errors.url]}
                />
              )}
            </div>
            <div className={Styles.modalButtonWrapper}>
              <Button
                variant="secondary"
                className={Styles.modalCancelButton}
                onClick={closeModal}
              >
                {CANCEL_BUTTON}
              </Button>
              <Button variant="success" type="submit">
                {isEdit ? EDIT_BUTTON : CONFIRM_BUTTON}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

UsefulLinksModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  initialValues: PropTypes.object,
};
