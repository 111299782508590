export const HR_PAGE_TRANSLATIONS = {
  CUMUL: 'En cumul',
  EXERCICE: 'Exercice :',
  TABLE_TITLE: 'Détail',
  ERROR_TEXT:
    'Données non disponibles. Contactez nous pour découvrir notre offre « Mes ressources humaines »',
  ERROR_BUTTON_TEXT: 'Contactez nous',
  ACCEDER_BUTTON_TEXT: 'Accéder à « Mes ressources humaines »',
};

export const HR_PAGE_ERRORS = {
  NO_EVP_MODULE: 'User does not have an EVP module',
  NO_EVP_MODULE_ON_HR: 'User does not have an EVP module on HR page',
};
