import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import Styles from './RewardsTable.module.css';

const propTypes = {
  title: PropTypes.string,
};

/**
 * Return column title for rewards page table
 * @param { String } title - rewards name
 * @param { Number } code - unique reward code
 */
export const ColumnTitle = ({ title }) => (
  <div className={Styles.columnTitleWrapper}>
    <Text>{title}</Text>
  </div>
);

ColumnTitle.propTypes = propTypes;
