import React from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { SectionTitle } from 'components/Section/SectionTitle';
import { SubscribeSuccess } from 'components/notifications/SubscribeSuccess';
import { Button } from 'components/ui';
import Styles from './TopSection.module.css';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  disclaimer: PropTypes.string,
  imgUrl: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  showTooltip: PropTypes.bool,
};

export const LPTopSection = ({
  title,
  subtitle,
  text,
  disclaimer,
  imgUrl,
  onButtonClick,
  buttonText,
  showTooltip,
}) => (
  <div className={clsx(MainStyles.container, Styles.sectionWrapper)}>
    <div className={Styles.wrapper}>
      <SectionTitle title={title} />
      <div className={Styles.content}>
        <div className={Styles.subtitle}>{subtitle}</div>
        <p className={Styles.paragraph}>{text}</p>
        <div className={Styles.buttonWrapper}>
          <Button
            variant="success"
            onClick={onButtonClick}
            className={Styles.button}
          >
            {buttonText}
          </Button>
          {showTooltip && <SubscribeSuccess />}
        </div>
        {disclaimer && <p className={Styles.disclaimer}>{disclaimer}</p>}
      </div>
    </div>
    <div className={Styles.imgContainer}>
      <img src={imgUrl} alt={`${title}-logo`} />
    </div>
  </div>
);

LPTopSection.propTypes = propTypes;
