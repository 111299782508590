import React, { memo, useMemo } from 'react';
import { Table, Tooltip } from 'antd';
import { CalendarIcon } from 'components/ui/CalendarIcon';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import { EMPLOYEE_TABLE } from 'const/translations/SalaryEmployeePage';
import { DATE_FORMAT } from '../../../../const';
import Styles from './EmployeeTable.module.css';

const { ACTIONS, HEADER_TITLES } = EMPLOYEE_TABLE;

const propTypes = {
  employees: PropTypes.array,
  openTimeModal: PropTypes.func,
  openVacationModal: PropTypes.func,
};

const ITEM_PER_PAGE = 20;

const getEmployeeColumns = (openTimeModal, openVacationModal) => [
  {
    title: HEADER_TITLES.NAME,
    className: Styles.textCustom,
    width: 150,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    sorter: (a, b) => a.name.localeCompare(b.name),
    defaultSortOrder: 'ascend',
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: HEADER_TITLES.ESTABLISHMENTS,
    width: 146,
    ellipsis: true,
    render: (value, record) => {
      const { address, city, postcode, siret } = record.establishments || {};
      const tooltipAddress = [address, city, postcode, siret].filter(Boolean);

      return {
        children: (
          <Tooltip
            title={tooltipAddress.map((addressItem, index, array) => (
              <span className={Styles.tooltipAddressItem}>
                {addressItem}
                {index < array.length - 1 && ', '}
              </span>
            ))}
            arrowPointAtCenter
            placement="topLeft"
            overlayClassName={Styles.establishmentsOverlay}
          >
            {value.postIndex}
            <br />
            <span className={clsx(MainStyles.colorMuted, MainStyles.textSmall)}>
              {value.address}
            </span>
          </Tooltip>
        ),
      };
    },
    sorter: (a, b) =>
      a.establishments.postIndex.localeCompare(b.establishments.postIndex),
    dataIndex: 'establishments',
    key: 'establishments',
  },
  {
    title: HEADER_TITLES.NATURE_EMPLOYMENT,
    width: 168,
    dataIndex: 'natureEmployment',
    sorter: (a, b) => a.natureEmployment.localeCompare(b.natureEmployment),
    key: 'natureEmployment',
  },
  {
    title: HEADER_TITLES.CLASSIFICATION,
    width: 118,
    dataIndex: 'classification',
    ellipsis: true,
    sorter: (a, b) => a.classification.localeCompare(b.classification),
    key: 'classification',
  },
  {
    title: HEADER_TITLES.CONTRACT,
    width: 80,
    dataIndex: 'contract',
    ellipsis: true,
    sorter: (a, b) => a.contract.localeCompare(b.contract),
    key: 'contract',
  },
  {
    title: HEADER_TITLES.ENTRY_DATE,
    width: 110,
    dataIndex: 'dateOfEntry',
    render: (value) => <span className={Styles.nowrapCell}>{value}</span>,
    sorter: (a, b) =>
      moment
        .utc(a.dateOfEntry, DATE_FORMAT)
        .diff(moment.utc(b.dateOfEntry, DATE_FORMAT)),
    key: 'dateOfEntry',
  },
  {
    title: HEADER_TITLES.RELEASE_DATE,
    width: 110,
    render: (value) => <span className={Styles.nowrapCell}>{value}</span>,
    dataIndex: 'releaseDate',
    sorter: (a, b) => {
      const dateA =
        a.releaseDate === '...'
          ? moment()
          : moment.utc(a.releaseDate, DATE_FORMAT);
      const dateB =
        b.releaseDate === '...'
          ? moment()
          : moment.utc(b.releaseDate, DATE_FORMAT);
      return dateA.diff(dateB);
    },
    key: 'releaseDate',
  },
  {
    title: HEADER_TITLES.SCHEDULE,
    width: 80,
    dataIndex: 'employeeid',
    key: 'employeeid',
    render: (employeeid) => (
      <CalendarIcon
        className={Styles.Icon}
        data-employeeid={employeeid}
        onClick={openTimeModal}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'employeeid',
    key: 'actions',
    fixed: 'right',
    render: (employeeid) => (
      <div>
        <span
          className={clsx(Styles.Action, Styles.ActionAddLeave)}
          data-reason="absence"
          data-employeeid={employeeid}
          onClick={openVacationModal}
        >
          + {ACTIONS.ABSENCES}
        </span>
        <span
          className={clsx(Styles.Action, Styles.ActionAddVacation)}
          data-reason="vacation"
          data-employeeid={employeeid}
          onClick={openVacationModal}
        >
          + {ACTIONS.LEAVE}
        </span>
      </div>
    ),
  },
];

export const EmployeeTable = memo(
  ({ employees, openTimeModal, openVacationModal }) => {
    const columns = useMemo(
      () => getEmployeeColumns(openTimeModal, openVacationModal),
      [openTimeModal, openVacationModal]
    );

    return (
      <Table
        className={clsx(
          MainStyles.table,
          MainStyles.tableCustomRow,
          Styles.table
        )}
        dataSource={employees}
        columns={columns}
        pagination={{ pageSize: ITEM_PER_PAGE, hideOnSinglePage: true }}
        scroll={{ x: 1130 }}
      />
    );
  }
);

EmployeeTable.propTypes = propTypes;
