import {
  COMPANY_SETTINGS_REWARDS,
  COMPANY_SETTINGS_REWARDS_DETAIL,
} from '../../../../const/translations/SalaryCompanySettings';

/**
 * Get translation for family reward item
 * @param { String } type - type of reward
 * @returns {String} return text for type
 */
export const getRewardTypeText = (type) => {
  const typeText = COMPANY_SETTINGS_REWARDS.REWARD_TYPE_OPTIONS.find(
    ({ value }) => value === type
  );

  return typeText?.label || '';
};

export const getToolTypeText = (tool) => {
  const typeText = COMPANY_SETTINGS_REWARDS.TOOL_OPTIONS.find(
    ({ value }) => value === tool
  );

  return typeText?.label || tool;
};

/**
 * Get body for remove family modal
 * @param { Boolean } canBeRemoved - can the reward be removed
 * @param { String } familyId - id of reward family
 * @param { Number } familyRewardsCount - count of rewards inside family
 * @returns {String} return body with texts depending on the value canBeRemoved
 */
export const getRewardFamilyRemoveModalBody = (
  canBeRemoved = false,
  familyId = null,
  familyRewardsCount = 0
) => {
  if (canBeRemoved && familyId) {
    return {
      familyId,
      title: COMPANY_SETTINGS_REWARDS.REMOVE_APPROVE_TITLE,
      isError: false,
      text: `${COMPANY_SETTINGS_REWARDS.REMOVE_APPROVE_TEXT_1} ${familyRewardsCount} ${COMPANY_SETTINGS_REWARDS.REMOVE_APPROVE_TEXT_2}`,
    };
  }
  return {
    title: COMPANY_SETTINGS_REWARDS.REMOVE_CANCEL_TITLE,
    isError: true,
    text: COMPANY_SETTINGS_REWARDS.REMOVE_CANCEL_TEXT,
    text2: COMPANY_SETTINGS_REWARDS.REMOVE_CANCEL_TEXT2,
  };
};

/**
 * Get body for remove reward item modal
 * @param { Boolean } canBeRemoved - can the reward be removed
 * @param { String } rewardId - id of reward
 * @returns {String} return body with texts depending on the value canBeRemoved
 */
export const getRewardItemRemoveModalBody = (
  canBeRemoved = false,
  rewardId = null
) => {
  if (canBeRemoved && rewardId) {
    return {
      rewardId,
      title: COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_APPROVE_TITLE,
      isError: false,
      text: COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_APPROVE_TEXT,
    };
  }
  return {
    title: COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_CANCEL_TITLE,
    isError: true,
    text: COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_CANCEL_TEXT,
    text2: COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_CANCEL_TEXT2,
  };
};
