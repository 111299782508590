import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Icon, Typography } from 'antd';
import { Button, CustomSelect } from 'components/ui';
import { Modal } from 'components/ui/Modal';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { COMPANY_SETTINGS } from 'const/translations/SalaryCompanySettings';
import { ROUTES } from '../../../../const';
import { useSimpleMedia } from '../../../../hooks/useMedia';
import { selectUserCompanyId } from '../../../../modules/loggedUserInfo/selectors';
import { removeSchedule } from '../../../../modules/SalaryModule/CompanySettings/actions';
import {
  selectActiveBranchData,
  selectBranches,
  selectBranchesOptions,
  selectFormattedSchedules,
} from '../../../../modules/SalaryModule/CompanySettings/selectors';
import { ScheduleList } from '../ScheduleList';
import Styles from './ScheduleTable.module.css';

const { Text } = Typography;

const SCREEN_WIDTH_BREAKPOINT_VALUE = 767;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const getTableColumns = (openDeleteModal) => [
  {
    title: `${COMPANY_SETTINGS.SCHEDULE_TABLE_HEADS.TITLE}`,
    width: 360,
    dataIndex: 'title',
    key: 'title',
    render: (title, item) => {
      const { link, text } = title;
      return (
        <>
          {link ? (
            <NavLink
              exact
              key={text}
              to={`${ROUTES.SALARIES_SCHEDULE_EMPTY}${link}${
                item.siret ? `?siret=${item.siret}` : ''
              }`}
              className={MainStyles.link}
            >
              {text}
            </NavLink>
          ) : (
            <span className={MainStyles.link}>
              {' '}
              {COMPANY_SETTINGS.SCHEDULE_DEFAULT.replace(
                '{{SCHEDULE_NAME}}',
                text
              )}
            </span>
          )}
        </>
      );
    },
  },
  {
    title: `${COMPANY_SETTINGS.SCHEDULE_TABLE_HEADS.DAYS}`,
    render: (days) => ({
      children: (
        <>
          {days.map((day) => (
            <Text
              key={day.title}
              className={Styles.text}
              disabled={day.disabled}
            >
              {day.title}
            </Text>
          ))}
        </>
      ),
    }),
    dataIndex: 'weekDays',
    key: 'weekDays',
  },
  {
    title: `${COMPANY_SETTINGS.SCHEDULE_TABLE_HEADS.TOTAL}`,
    width: 80,
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: `${COMPANY_SETTINGS.SCHEDULE_TABLE_HEADS.STAFF_COUNT}`,
    width: 110,
    dataIndex: 'concerned',
    key: 'concerned',
  },
  {
    title: '',
    width: 60,
    fixed: 'right',
    dataIndex: 'id',
    key: 'id',
    render: (id, { siret }) => (
      <>
        {siret ? (
          <Icon
            type="delete"
            theme="filled"
            className={Styles.removeBtn}
            data-id={id}
            onClick={openDeleteModal}
          />
        ) : null}
      </>
    ),
  },
];

export const ScheduleTable = memo(() => {
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const schedulesList = useSelector(selectFormattedSchedules);
  const selectFormattedBranches = useSelector(selectBranchesOptions);
  const branches = useSelector(selectBranches);
  const activeBranchData = useSelector(selectActiveBranchData);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [scheduleStaffCount, setScheduleStaffCount] = useState(0);
  const dispatch = useDispatch();
  const companyId = useSelector(selectUserCompanyId);

  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [removeScheduleId, setRemoveScheduleId] = useState(null);
  const [schedulesListFiltered, setSchedulesListFiltered] = useState(
    schedulesList
  );

  useEffect(() => {
    if (schedulesList) {
      setSchedulesListFiltered(
        schedulesList.filter(
          (schedule) =>
            schedule.siret === selectedBranch ||
            !schedule.siret ||
            !selectedBranch
        )
      );
    }
  }, [selectedBranch, schedulesList]);

  const openDeleteModal = useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset;
      const schedule = schedulesList.find(
        ({ id: scheduleId }) => scheduleId === id
      );
      setScheduleStaffCount(schedule.concerned);
      setRemoveScheduleId(id);
      setOpenRemoveModal(true);
    },
    [schedulesList]
  );

  const closeDeleteModal = useCallback(() => {
    setRemoveScheduleId(null);
    setOpenRemoveModal(false);
  }, []);

  const removeScheduleHandler = useCallback(() => {
    if (removeScheduleId) {
      dispatch(removeSchedule({ companyId, scheduleId: removeScheduleId }));
      setRemoveScheduleId(null);
      setOpenRemoveModal(false);
    }
  }, [dispatch, removeScheduleId, companyId]);

  useEffect(() => {
    if (activeBranchData) {
      setSelectedBranch(activeBranchData.siret);
    }
  }, [activeBranchData]);

  const handleBranchChange = (_, data) => {
    setSelectedBranch(data);
  };

  const columns = useMemo(() => getTableColumns(openDeleteModal), [
    openDeleteModal,
  ]);

  return (
    <>
      <CustomSelect
        size="large"
        name="branches"
        className={Styles.select}
        options={selectFormattedBranches}
        onChange={handleBranchChange}
        value={selectedBranch}
        placeholder={COMPANY_SETTINGS.ESTABLISHMENTS}
        dropdownStyle={{ minWidth: 'min-content' }}
        allowClear
        hasTooltip
      />
      {isMobileVersion ? (
        <ScheduleList
          schedules={schedulesListFiltered}
          openDeleteModal={openDeleteModal}
        />
      ) : (
        <Table
          pagination={false}
          className={clsx(MainStyles.table, MainStyles.tableCustomRow)}
          dataSource={schedulesListFiltered}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1130 }}
        />
      )}

      <div className={Styles.buttonsWrapper}>
        <NavLink
          exact
          to={`${ROUTES.SALARIES_SCHEDULE_EMPTY}?siret=${selectedBranch ||
            branches[0]?.siret}`}
        >
          <Button sizeAuto variant="success" className={Styles.newButton}>
            + {COMPANY_SETTINGS.ADD_SCHEDULE}
          </Button>
        </NavLink>
      </div>

      <Modal
        title={COMPANY_SETTINGS.REMOVE_MODAL_TITLE}
        visible={openRemoveModal}
        onClose={closeDeleteModal}
        onSubmit={removeScheduleHandler}
        width="384px"
        closable={false}
        okText={COMPANY_SETTINGS.REMOVE_BTN_TEXT}
        fullMobileScreen={false}
      >
        <Text className={Styles.modalText}>
          {COMPANY_SETTINGS.REMOVE_MODAL_TEXT_1.replace(
            '{{COUNT}}',
            scheduleStaffCount
          )}
        </Text>{' '}
        <Text className={Styles.modalText}>
          {COMPANY_SETTINGS.REMOVE_MODAL_TEXT_2}
        </Text>
      </Modal>
    </>
  );
});
