export const FORGOTTEN_PASSWORD_TRANSLATIONS = {
  TITLE: 'Mot de passe oublié',
  LABELS: {
    EMAIL: 'Votre email',
  },
  BTNS: {
    SEND: 'Envoyer',
    LOG_IN: 'Se connecter',
  },
  LINKS: {
    LOG_IN: 'Se connecter',
  },
  ERRORS: {
    FIELD_REQUIRED: 'Ce champ est obligatoire',
    USER_DOES_NOT_EXIST:
      "L'utilisateur avec cet email n'existe pas. Veuillez contacter l'administrateur ou corriger l'email et réessayer",
    SERVER_ERROR: 'Erreur du serveur',
  },
  ALERTS: {
    PASSWORD_HAS_BEEN_SENT: `Votre nouveau mot de\npasse vient d'être envoyé\npar mail`,
    CHECK_YOUR_EMAIL: `Veuillez vérifier votre boîte mail :`,
  },
};
