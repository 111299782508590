import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthorisedRoutes, UnauthorisedRoutes } from 'routing';
import 'antd/dist/antd.css';
import { AppLayout } from 'components/AppLayout';
import { AUTH_TOKEN_KEY } from 'config';
import {
  selectUserAuthorizedModules,
  selectUserCompaniesListData,
  selectUserCurrentCompany,
  selectUserInfoFullName,
  selectUserInfoStatus,
  selectUserTermsOfUseAcceptance,
  selectIsMainUser,
  selectIsUserNotification,
  selectIsUserNextNotification,
} from 'modules/loggedUserInfo/selectors';
import {
  changeCurrentCompany,
  fetchIsanetRedirectUrl,
  loggedUserInfoRequest,
  setCurrentCompanyApiKeyRequest,
  userCompaniesDashboardDataRequest,
  goToNextNotification,
} from 'modules/loggedUserInfo/actions';
import { Drawer, message } from 'antd';
import { API_KEY_MODAL_TRANSLATIONS, GENERAL_ERRORS } from 'const/translations';
import {
  HELP_NAV_LINK,
  NAV_LINKS,
  SIDEBAR_CUSTOM_ACTIONS,
} from 'const/Sidebar';
import { SidebarNavLinks } from 'components/SidebarNavLinks';
import { selectCurrentRoute } from 'modules/routing/selectors';
import { TermsOfUseRoutes } from 'routing/TermsOfUseRoutes';
import { RegisteredUserTermsRoutes } from 'routing/RegisteredUserTermsRoutes';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from 'const';
import { Spinner } from 'components/Spinner';
import MainStyles from 'assets/styles/main.module.css';
import { getPackagesCostsRequest, initializeCart } from 'modules/cart/actions';
import clsx from 'clsx';
import { getScreenWidthBreakpointMedia } from 'utils';
import { useSimpleMedia } from 'hooks/useMedia';
import { selectIsNPSVisible } from 'modules/NPSModal/selectors';
import { waibiAccessRequest } from 'modules/dashboardIndicators/action';
import { selectIsWaibiLoading } from 'modules/dashboardIndicators/selectors';
import { getFromStorage } from 'helpers/storage';
import { SalaryHeader } from 'components/SalaryComponents/SalaryHeader';
import { selectPackagesCostsStatus } from 'modules/cart/selectors';
import { openModal } from 'modules/ModalContainer/actions';
import { selectModalState } from 'modules/ModalContainer/selectors';
import { MODAL_TYPES } from 'const/ModalContainer';
import { useMatomoTagManager } from 'hooks/useMatomoTagManager';
import Styles from './App.module.css';

const isLoggedIn = Boolean(getFromStorage({ key: AUTH_TOKEN_KEY }));

const SCREEN_WIDTH_BREAKPOINT_MEDIA = getScreenWidthBreakpointMedia(1300);

/**
 * All independent modals accessible anywhere are placed in header component.
 */
export const App = () => {
  const dispatch = useDispatch();

  const isLaptopVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  const registeredCompanies = useSelector(selectUserCompaniesListData);
  const userInfoStatus = useSelector(selectUserInfoStatus);
  const userInfoFullName = useSelector(selectUserInfoFullName);
  const currentCompany = useSelector(selectUserCurrentCompany);
  const userAuthorisedModules = useSelector(selectUserAuthorizedModules);
  const currentRoute = useSelector(selectCurrentRoute);
  const packagesCostsStatus = useSelector(selectPackagesCostsStatus);
  const modalProps = useSelector(selectModalState);
  const isMainUser = useSelector(selectIsMainUser);

  const nextNotification = useSelector(selectIsUserNextNotification);

  useMatomoTagManager();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loggedUserInfoRequest());
      dispatch(userCompaniesDashboardDataRequest());
      dispatch(initializeCart());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      isLoggedIn &&
      isMainUser &&
      packagesCostsStatus === STATUS_NOT_REQUESTED
    ) {
      dispatch(getPackagesCostsRequest());
    }
  }, [dispatch, isMainUser, packagesCostsStatus, userAuthorisedModules]);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const isSideBarOpenToggle = useCallback(() => {
    setIsSideBarOpen((prev) => !prev);
  }, [setIsSideBarOpen]);

  const [isSideBarFullWidth, setIsSideBarFullWidth] = useState(false);
  const isSideBarFullWidthToggle = useCallback(() => {
    setIsSideBarFullWidth((prev) => !prev);
  }, [setIsSideBarFullWidth]);

  useEffect(() => {
    setIsSideBarOpen(false);
    setIsSideBarFullWidth(false);
  }, [setIsSideBarFullWidth, setIsSideBarOpen, isLaptopVersion]);

  const handleCompanyChange = useCallback(
    (value) => dispatch(changeCurrentCompany({ companyId: value })),
    [dispatch]
  );

  const handleSetApiKey = useCallback(
    (value) => {
      if (!value) {
        return message.error(API_KEY_MODAL_TRANSLATIONS.ERROR_EMPTY);
      }

      return dispatch(
        setCurrentCompanyApiKeyRequest({
          apiKey: value,
          onError: (errorMessage) => message.error(errorMessage),
        })
      );
    },
    [dispatch]
  );

  const handleIsanetRedirect = useCallback(() => {
    if (!currentCompany.ssoEnabled) {
      message.error(GENERAL_ERRORS.SSO_DISABLED);
      return;
    }
    dispatch(
      fetchIsanetRedirectUrl({
        onError: (errorMessage) => message.error(errorMessage),
      })
    );
  }, [currentCompany.ssoEnabled, dispatch]);

  const handleCloseAndRedirectToIsanet = useCallback(() => {
    isSideBarOpenToggle();
    handleIsanetRedirect();
  }, [isSideBarOpenToggle, handleIsanetRedirect]);

  const handleWaibiRedirect = useCallback(() => {
    dispatch(
      waibiAccessRequest({
        onError: (errorMessage) => message.error(errorMessage),
      })
    );
  }, [dispatch]);

  const handleCloseAndRedirectToWaibi = useCallback(() => {
    isSideBarOpenToggle();
    handleWaibiRedirect();
  }, [isSideBarOpenToggle, handleWaibiRedirect]);

  const isWaibiLoading = useSelector(selectIsWaibiLoading);

  const handleCustomActions = useCallback(
    (event) => {
      const { actionType } = event.currentTarget.dataset;

      isSideBarOpenToggle();

      if (!actionType) {
        return;
      }

      if (actionType === SIDEBAR_CUSTOM_ACTIONS.helpRequest) {
        dispatch(openModal({ type: MODAL_TYPES.requestHelp, modalProps: {} }));
      }
      if (
        actionType === SIDEBAR_CUSTOM_ACTIONS.waibiRedirect &&
        !isWaibiLoading
      ) {
        handleCloseAndRedirectToWaibi();
      }
    },
    [
      dispatch,
      isSideBarOpenToggle,
      isWaibiLoading,
      handleCloseAndRedirectToWaibi,
    ]
  );

  const areTermsOfUseAccepted = useSelector(selectUserTermsOfUseAcceptance);
  const isUserAdditional = useMemo(() => !isMainUser, [isMainUser]);

  const isNPSVisible = useSelector(selectIsNPSVisible);
  const isNotificationsVisible = useSelector(selectIsUserNotification);

  useEffect(() => {
    if (
      areTermsOfUseAccepted &&
      isLoggedIn &&
      isNPSVisible &&
      !isNotificationsVisible
    ) {
      dispatch(openModal({ type: MODAL_TYPES.netPromoterScore }));
    }
  }, [areTermsOfUseAccepted, dispatch, isNPSVisible, isNotificationsVisible]);

  const goToNextNotificationOnClick = useCallback(
    (handleClose) => () => {
      if (!nextNotification) {
        handleClose();
      }
      dispatch(goToNextNotification());
    },
    [dispatch, nextNotification]
  );

  useEffect(() => {
    if (areTermsOfUseAccepted && isLoggedIn && isNotificationsVisible) {
      dispatch(
        openModal({
          type: MODAL_TYPES.informationModal,
          modalProps: {
            closable: true,
            onClose: goToNextNotificationOnClick,
          },
        })
      );
    }
  }, [
    areTermsOfUseAccepted,
    dispatch,
    goToNextNotificationOnClick,
    isNotificationsVisible,
  ]);

  if (
    [STATUS_NOT_REQUESTED, STATUS_LOADING].includes(userInfoStatus) &&
    isLoggedIn
  ) {
    return <Spinner size="large" className={MainStyles.appSpinner} />;
  }

  if (!areTermsOfUseAccepted && isLoggedIn) {
    return isUserAdditional ? (
      <TermsOfUseRoutes />
    ) : (
      <RegisteredUserTermsRoutes />
    );
  }

  return (
    <div className="App">
      {isLoggedIn ? (
        <AppLayout
          registeredCompanies={registeredCompanies}
          currentCompany={currentCompany}
          handleCompanyChange={handleCompanyChange}
          userModules={userAuthorisedModules}
          handleSetApiKey={handleSetApiKey}
          handleIsanetRedirect={handleIsanetRedirect}
          userInfoFullName={userInfoFullName}
          userInfoStatus={userInfoStatus}
          toggleSidebar={isSideBarOpenToggle}
          isSidebarOpen={isSideBarOpen}
          modalProps={modalProps}
          isMainUser={isMainUser}
        >
          <SalaryHeader />
          <Drawer
            placement="left"
            closable={false}
            onClose={isSideBarOpenToggle}
            visible={isLaptopVersion ? isSideBarOpen : true}
            getContainer={false}
            className={clsx(Styles.drawer, {
              [Styles.fullDrawer]: isSideBarFullWidth || isLaptopVersion,
            })}
            onMouseEnter={isSideBarFullWidthToggle}
            onMouseLeave={isSideBarFullWidthToggle}
            width={100}
            style={{ position: 'fixed' }}
            drawerStyle={{
              backgroundColor: '#23446C',
            }}
            bodyStyle={{
              paddingTop: 108,
              height: '100%',
            }}
            zIndex={5}
            mask={!!isLaptopVersion}
            maskStyle={{
              background: 'rgba(255,255,255,0.8)',
            }}
          >
            <SidebarNavLinks
              links={currentCompany.activated ? NAV_LINKS : [HELP_NAV_LINK]}
              handleCloseAndRedirect={handleCloseAndRedirectToIsanet}
              authorisedModules={userAuthorisedModules}
              closeSidebar={isSideBarOpenToggle}
              currentRoute={currentRoute}
              currentCompany={currentCompany}
              handleCustomActions={handleCustomActions}
              isSidebarFullWidth={isLaptopVersion || isSideBarFullWidth}
              isMainUser={isMainUser}
            />
          </Drawer>
          <AuthorisedRoutes
            authorizedModules={userAuthorisedModules}
            userInfoStatus={userInfoStatus}
            isPayEnable={currentCompany?.payEnabled || false}
            isCurrentCompanyActivated={!!currentCompany.activated}
            isMainUser={isMainUser}
          />
        </AppLayout>
      ) : (
        <UnauthorisedRoutes />
      )}
    </div>
  );
};
