import { fromJS } from 'immutable';
import { STATUS_LOADING, STATUS_NOT_REQUESTED, STATUS_SUCCESS } from 'const';
import {
  WAIBI_ACCESS_REQUEST,
  WAIBI_ACCESS_SUCCESS,
  WAIBI_ACCESS_ERROR,
} from '../action';

const initialState = fromJS({
  status: STATUS_NOT_REQUESTED,
});

export const dashboardIndicators = (state = initialState, action) => {
  switch (action.type) {
    case WAIBI_ACCESS_REQUEST:
      return state.set('status', STATUS_LOADING);
    case WAIBI_ACCESS_SUCCESS:
      return state.set('status', STATUS_SUCCESS);
    case WAIBI_ACCESS_ERROR:
      return state.set('status', STATUS_NOT_REQUESTED);

    default:
      return state;
  }
};
