import { request } from 'utils/request';

export const getHRDataService = ({
  companyId,
  transition = false,
  currentExercice,
  maxPeriod,
}) =>
  request.get(
    `/dashboards/${companyId}/hr/detailed?transition=${transition}&exercice=${currentExercice}&maxperiod=${maxPeriod}`
  );
