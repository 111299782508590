import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { downloadFile } from 'helpers/common';
import {
  GET_CALENDAR_ABSENCES_REQUEST,
  getCalendarAbsencesSuccess,
  getCalendarAbsencesError,
  DOWNLOAD_ABSENCES,
  GET_ABSENCES_TYPES_REQUEST,
  GET_HOLIDAYS_REQUEST,
  getHolidaysSuccess,
  getHolidaysError,
  getAbsencesTypesSuccess,
} from 'modules/SalaryModule/Calendar/actions';
import {
  fetchCalendarAbsences,
  downloadAbsencesXLSX,
  fetchHolidays,
  fetchAbsencesTypes,
} from 'modules/SalaryModule/Calendar/services';
import { getCsvDownloadQuery } from 'modules/SalaryModule/Employees/utils';
import { getFromStorage, setToStorage } from 'helpers/storage';

function* getCalendarAbsencesWorker({
  payload: { date, companyId, isYearCalendar },
}) {
  try {
    const year = Number(moment(date).format('YYYY'));
    const month = Number(moment(date).format('MM'));
    const { data } = yield call(fetchCalendarAbsences, {
      companyId,
      year,
      month: !isYearCalendar ? month : undefined,
    });
    yield put(getCalendarAbsencesSuccess({ data }));
  } catch (e) {
    yield put(getCalendarAbsencesError({ error: e }));
  }
}

function* downloadAbsencesWorker({ payload: { filter } }) {
  try {
    const query = getCsvDownloadQuery(filter);

    const { fileData, fileName } = yield call(downloadAbsencesXLSX, {
      query,
    });

    yield call(downloadFile, fileData, fileName);
  } catch (error) {
    console.warn(error);
  }
}

function* getCalendarHolidaysWorker({ payload: { year } }) {
  try {
    const fetchedHolidays = yield call(fetchHolidays, year);

    const storageHolidays =
      getFromStorage({
        key: 'holidays',
        shouldBeParsed: true,
        storage: sessionStorage,
      }) || [];

    setToStorage({
      key: 'holidays',
      value: [...storageHolidays, ...fetchedHolidays],
      storage: sessionStorage,
    });

    yield put(getHolidaysSuccess());
  } catch (error) {
    yield put(getHolidaysError(year));
  }
}

function* getAbsencesTypesRequestWorker() {
  try {
    const absencesTypes = yield call(fetchAbsencesTypes);

    yield put(getAbsencesTypesSuccess(absencesTypes));
  } catch (error) {
    console.warn(error);
  }
}

export function* calendarMainSaga() {
  yield takeLatest(GET_CALENDAR_ABSENCES_REQUEST, getCalendarAbsencesWorker);
  yield takeLatest(DOWNLOAD_ABSENCES, downloadAbsencesWorker);
  yield takeLatest(GET_HOLIDAYS_REQUEST, getCalendarHolidaysWorker);
  yield takeLatest(GET_ABSENCES_TYPES_REQUEST, getAbsencesTypesRequestWorker);
}
