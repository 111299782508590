import { MATOMO_CONTAINER_NAME } from 'config';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useMatomoTagManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle, no-multi-assign
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = MATOMO_CONTAINER_NAME;
    s.parentNode.insertBefore(g, s);
  }, []);

  // TODO\434: Handle event pushing;
  useEffect(() => {
    // console.log(pathname);

    // eslint-disable-next-line no-underscore-dangle, no-multi-assign
    const _mtm = (window._mtm = window._mtm || []);

    _mtm.push({ event: 'mtm.Start' });
  }, [pathname]);
};
