// TODO need paths from .env
export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? '/api/'
    : process.env.API_URL || process.env.REACT_APP_API_URL;

export const MATOMO_CONTAINER_NAME =
  process.env.NODE_ENV === 'development'
    ? 'https://cdn.matomo.cloud/myexco.matomo.cloud/container_HLabhyUv_dev_531a5bbb06c7c8dd9e5ad66d.js'
    : process.env.REACT_APP_MATOMO_CDN_URL;

export const AUTH_TOKEN_KEY = 'spinfi-token';

export const WAIBI_ACCESS_TOKEN = 'waibi-token';
export const WAIBI_REFRESH_TOKEN = 'waibi-refresh-token';

export const TOKEN_ENDPOINTS = {
  LOGIN: 'auth/login',
  CHANGE_PASSWORD: 'auth/change-password',
  TEMPORARY_CHANGE_PASSWORD: 'clients',
};

export const FACT_MEETING_FORM_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://outlook.office365.com/owa/calendar/TestBookinsplanning@exco.fr/bookings/'
    : 'https://outlook.office365.com/owa/calendar/ExcoFSO%40exco.fr/bookings/';
