export const EMPLOYEE_MODAL_INFO = {
  TITLE: 'Envoyer mes données paies',
  SUBTITLE: 'dernier document envoyé le ',
  LABELS: {
    MONTH_PICKER: 'Choisissez le mois :',
    STATISTICS: 'Données enregistrées :',
  },
  TEXT_AREA_PLACEHOLDER: 'Zone de commentaire (optionnel)',
  SUBMIT_BUTTON: 'Envoyer',
  CANCEL_BUTTON: 'Annuler',
  SUCCESS_MESSAGE:
    'Les informations pour la paie ont bien été envoyées à Exco !',
};

export const EXCO_INFO_MODAL_DATA_LABELS = {
  totalVacations: 'congés',
  totalAbsences: 'absences',
  totalRewards: 'variables de paie',
};

export const SALARY_MAIN_PAGE = {
  SECTION_TITLE: 'Mes Ressources Humaines',
};
