import React, { useState, useMemo, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { COMMON_LABELS } from 'const/translations';
import { EMPTY_BASE64 } from 'const';
import { getLoginBannersRequest } from 'modules/LoginPage/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAreBannersLoading,
  selectBanners,
} from 'modules/LoginPage/selectors';
import { v4 as uuidv4 } from 'uuid';
import Styles from './Banners.module.css';
import { WrappedLoader } from './WrappedLoader';
import { SwitchButtons } from './SwitchButtons';

export const Banners = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoginBannersRequest());
  }, [dispatch]);

  const banners = useSelector(selectBanners);
  const areBannersLoading = useSelector(selectAreBannersLoading);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const nextSlide = useCallback(
    () =>
      setCurrentBannerIndex((oldSlide) =>
        oldSlide < banners.length - 1 ? oldSlide + 1 : 0
      ),
    [banners.length]
  );
  const prevSlide = useCallback(
    () =>
      setCurrentBannerIndex((oldSlide) =>
        oldSlide > 0 ? oldSlide - 1 : banners.length - 1
      ),
    [banners.length]
  );

  const MILLISECONDS_COUNT = 10000;
  useEffect(() => {
    let timeoutId = setTimeout(function tick() {
      nextSlide();

      timeoutId = setTimeout(tick, MILLISECONDS_COUNT);
    }, MILLISECONDS_COUNT);

    return () => clearTimeout(timeoutId);
  }, [nextSlide, currentBannerIndex]);

  const { title, description, imageBase64 } = banners[currentBannerIndex] || {};

  const slideImage =
    imageBase64 === EMPTY_BASE64 ? (
      <WrappedLoader />
    ) : (
      <div className={Styles.bannerImageWrapper}>
        <img className={Styles.bannerImage} src={imageBase64} alt={title} />
      </div>
    );

  const bannerMarks = useMemo(
    () =>
      Array(banners.length)
        .fill()
        .map(
          (_, index) =>
            banners[index] && (
              <div
                key={uuidv4()}
                className={clsx(Styles.bannerMark, {
                  [Styles.bannerMarkCurrent]: index === currentBannerIndex,
                })}
              />
            )
        ),
    [banners, currentBannerIndex]
  );

  return areBannersLoading ? (
    <div className={Styles.imageSlider}>
      <WrappedLoader />
      <h3 className={Styles.headerText}>{COMMON_LABELS.LOADING}</h3>
    </div>
  ) : (
    <div className={Styles.imageSlider}>
      <SwitchButtons
        buttonsClass={Styles.switchBannerButtonWideScreen}
        handleNext={nextSlide}
        handlePrev={prevSlide}
        wraps={slideImage}
      />
      <h3 className={Styles.textTitle}>{title}</h3>
      <p className={Styles.textDescription}>{description}</p>
      <SwitchButtons
        buttonsClass={Styles.switchBannerButtonSmallScreen}
        handleNext={nextSlide}
        handlePrev={prevSlide}
        wraps={<div className={Styles.bannerMarkWrapper}>{bannerMarks}</div>}
      />
    </div>
  );
};
