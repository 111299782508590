import {
  getCumulatedArray,
  sortArrayFromJanuaryToArrayFromFirstMonth,
  sortChartDataByYear,
} from 'helpers/chartDataMappers';
import {
  getMonthIndexFromName,
  getMonthNameFromIndex,
} from 'helpers/monthSelector';
import { MONTHS } from 'const/ui';
import { HR_TABS_IDS } from '../../const/HRPage';

// returns total values object from HRDashboardData
/**
 * @example
 * HRDashboardData -> [
 * {
    tabId: 1,
    tabValues: [
      {
        year: 2021,
        id: '2021',
        values: [1000, 2000, 3000],
      },
  },
 ]
 RESULT -> {
    1: {
      totalValues: [{year:2021, id: 'total2021', totalValue: 6000},]
    }
 }
 * */
export const getTotalHRTableValues = ({
  HRDashboardData,
  HRTabs = [],
  maxPeriod = '202112',
  firstMonthName = 'January',
  totalCaValuesByYears,
}) => {
  const maxPeriodDataIndex = getMaxPeriodDataIndexInSortedByFiscalMonthArray(
    firstMonthName,
    maxPeriod
  );
  return HRDashboardData.reduce((resultObj, { tabId, tabValues }) => {
    const valuesSuffix = HRTabs.find(({ id }) => id === tabId)?.suffix || '';
    const totalValues = tabValues.map(({ year, id, values: annualValues }) => {
      if (tabId === HR_TABS_IDS.CA) {
        return {
          year,
          id: `total${id}`,
          totalValue: totalCaValuesByYears[year],
        };
      }
      const totalValuesFromFirstMonthToMaxPeriod = annualValues.slice(
        0,
        maxPeriodDataIndex + 1
      );
      let notNullValuesCount = 0;

      const totalValue = totalValuesFromFirstMonthToMaxPeriod.reduce(
        (sum, value) => {
          if (valuesSuffix === '%') {
            notNullValuesCount =
              value === null ? notNullValuesCount : notNullValuesCount + 1;
          }
          if (!value) return sum;
          return sum === null ? Number(value) : sum + Number(value);
        },
        null
      );

      const totalValueForPercentTab =
        notNullValuesCount === 0
          ? null
          : Number((totalValue / notNullValuesCount).toFixed(2));

      return {
        year,
        id: `total${id}`,
        totalValue: valuesSuffix === '%' ? totalValueForPercentTab : totalValue,
      };
    });
    // eslint-disable-next-line no-param-reassign
    resultObj[tabId] = totalValues;
    return resultObj;
  }, {});
};

// returns HRDashboardData with same structure, but values are cumul
/**
 * @example
 * HRDashboardData -> [
 * {
    tabId: 1,
    tabValues: [
      {
        year: 2021,
        id: '2021',
        values: [1000, 2000, 3000],
      },
  },
 ]
  @return
 Result HRDashboardData -> [
 * {
    tabId: 1,
    tabValues: [
      {
        year: 2021,
        id: '2021',
        values: [1000, 3000, 6000],
      },
  },
 ]
 * */
// returns same dashboard data structure, but values of every year are cumul(every value = prevValue + currentValue)
export const getCumulDashboardData = (dashboardData) =>
  dashboardData.map(({ id, year, values }) => {
    const cumulValues = getCumulatedArray(values);
    return {
      id: `${id}cumulative`,
      year,
      values: cumulValues,
    };
  });

export const getMaxPeriodMonthIndex = (maxPeriod) => {
  if (!maxPeriod) return null;
  const maxPeriodMonthPart = maxPeriod.slice(4);
  return maxPeriodMonthPart === '01' ? 11 : Number(maxPeriodMonthPart) - 1;
};

// returns months array in sequence from firstFiscalMonth to company maxPeriod
/**
 * @param firstMonthName {string} -> first month of financial year(e.g 'June)
 * @param maxPeriod {string} -> YYYYMM -> most recent period for company that we can take values from
 * @return e.g. firstMonthName = 'June'
 *              maxPeriod = '202112' -> December 2021
 *              => [{name:'June', shortLabel:'Juin',...rest},
 *                  {name: 'July', shortLabel:'Juil', ...rest},
 *                      ....,
 *                   {name: 'December', shortLabel: 'Dec', ...rest}]
 * */
export const getMonthsArrayFromFirstFiscalMonthToCurrent = (
  firstMonthName,
  maxPeriod = ''
) => {
  if (!maxPeriod || !firstMonthName) {
    return [];
  }
  const maxPeriodMonthIndex = getMaxPeriodMonthIndex(maxPeriod);
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  const monthsFromFirst = [
    ...MONTHS.slice(firstMonthIndex),
    ...MONTHS.slice(0, firstMonthIndex),
  ];
  const maxPeriodMonthName = getMonthNameFromIndex(maxPeriodMonthIndex);
  const currentMonthIndexInSortedArray = monthsFromFirst.findIndex(
    ({ name }) => name === maxPeriodMonthName
  );
  return monthsFromFirst.filter(
    (_, monthIndex) => monthIndex <= currentMonthIndexInSortedArray
  );
};

// calculates HR CA Tab Data from totalHours and Turnover
// value for each month of year for CA = turnover / totalHours(each value for each month and year)
// data structures for turnover and totalhours => [{year: 2021, values: [1000, 2000, 3000, ... ,1000], id: 'someId'}]
// values array is sorted from first month of financial year to last month(12 month is duration)
/**
 * @param turnoverYearData {array} -> turnover data for each year(annual values) -> data structure above
 * @param totalHoursYearData {array} -> HR total hours data for each year(annual values) -> data structure above
 * @param uniqueDatasetIndex {string} -> unique dataset index to separate datasets from each other for chart
 * */
export const calculateHRCAData = ({
  turnoverYearData,
  totalHoursYearData,
  uniqueDatasetIndex = '',
}) =>
  totalHoursYearData.map(({ values: totalHoursValues, year }, datasetIndex) => {
    const CAValues = totalHoursValues.map(
      (totalHoursValue, totalHoursValueIndex) => {
        const turnoverValue =
          turnoverYearData[datasetIndex]?.values?.[totalHoursValueIndex] ||
          null;
        if (turnoverValue === null || totalHoursValue === null) {
          return null;
        }
        if (totalHoursValue === 0) {
          return null;
        }
        return Number((turnoverValue / totalHoursValue).toFixed(2));
      }
    );
    return {
      id: `ProductiveYear${year}${uniqueDatasetIndex}`,
      values: CAValues,
      year,
    };
  });

// calculates HR CA Percentage Tab Data from totalCost and Turnover
// value for each month of year for CA Percentage = totalCost / turnover(each value for each month and year)
// data structures for turnover and totalhours => [{year: 2021, values: [1000, 2000, 3000, ... ,1000], id: 'someId'}]
// values array is sorted from first month of financial year to last month(12 month is duration)
/**
 * @param turnoverYearData {array} -> turnover data for each year(annual values) -> data structure above
 * @param totalCostYearData {array} -> HR total cost data for each year(annual values) -> data structure above
 * @param uniqueDatasetIndex {string} -> unique dataset index to separate datasets from each other for chart
 * */
export const calculateHRPercentCAData = ({
  turnoverYearData,
  totalCostYearData,
  uniqueDatasetIndex = '',
}) =>
  totalCostYearData.map(({ values: totalCostValues, year }, datasetIndex) => {
    const percentCAValues = totalCostValues.map(
      (totalCostValue, totalCostValueIndex) => {
        const turnoverValue =
          turnoverYearData[datasetIndex]?.values?.[totalCostValueIndex] || null;
        if (turnoverValue === null || totalCostValue === null) {
          return null;
        }
        if (totalCostValue === 0) {
          return null;
        }
        return Number(((totalCostValue / turnoverValue) * 100).toFixed(2));
      }
    );
    return {
      id: `ProductiviteYear${year}${uniqueDatasetIndex}`,
      values: percentCAValues,
      year,
    };
  });

// exercice(YYYYMM) -> current financial year, which contains year of current financial year and last month of financial year
// e.g exercice -> 202205 means current financial year 2022 and last month is may, so 2022 financial year takes its start in June 2021(202106)
/**
 * function filters and formatting values for HR Dashboard from incoming array with structure ->
 * -> [{month: '05', year:'2021', "datasetValueKey": 1500},
 *    {month: '08', year:'2020', "datasetValueKey": 4000},
 *    {month: '03', year:'2021', "datasetValueKey": 1500},]
 * projects needs to show HR data for last 3 financial years, so function sorts and mapping values by financial years
 * e.g exercice = 202108, so start month is less for 12 month = 202009
 *     we need 3 financial(fiscal) years so they will be : 2021 fiscal year = 202009 -> 202108
 *                                                         2020 fiscal year = 201909 -> 202008
 *                                                         2019 fiscal year = 201809 -> 201908
 * @param exercice {string} -> watch above
 * @param firstMonthName {string} -> name of first month of financial year (exercice - 12 months)
 * @param dashboardData {array} -> structure above
 * @param datasetValueKey {string} -> key for value in dahsboardData
 * */
export const formatHRDashboardData = ({
  // TODO: Remove random const value 202112
  exercice = '202112',
  firstMonthName = 'January',
  dashboardData,
  datasetValueKey,
}) => {
  const lastPeriod = exercice;
  const firstMonthIndex = String(
    getMonthIndexFromName(firstMonthName) + 1
  ).padStart(2, '0');
  const numberYearsToDecrease = firstMonthIndex === '01' ? 2 : 3;
  const firstPeriodYear = Number(exercice.slice(0, 4)) - numberYearsToDecrease;
  const firstPeriod = `${firstPeriodYear}${firstMonthIndex}`;

  const filteredFilteredDashboardData = filterHRDashboardDataByFiscalYears(
    firstPeriod,
    lastPeriod,
    dashboardData
  );
  return mapHRDashboardDataByFiscalYears({
    dashboardData: filteredFilteredDashboardData,
    firstMonthName,
    lastPeriod,
    datasetValueKey,
  });
};

// function maps HR dashboard data by financial years
// dashboardData structure -> [{month: '05', year:'2021', "datasetValueKey": 1500},
//                            {month: '04', year:'2020', "datasetValueKey": 4000},
//                            {month: '03', year:'2021', "datasetValueKey": 2500},]
/**
 * @param dashboardData {array} -> structure above
 * @param firstMonthName {string} -> name of first month of financial year(e.g 'March')
 * @param lastPeriod {string} -> YYYYMM -> YYYY(year of current financial year) MM(last month of this financial year)
 * @param datasetValueKey {string} -> key for value in dahsboardData
 * @return e.g firstMonthName = 'January', lastPeriod = '202112', dashboardData is same as above
 *         [{year:2021, values:[null,null, 1500, null, 2500, null,... ,null], id:'HR2021DatasetKey'},
 *                             jan, febr, march, apr,  may,  june, ..., dec
 *         {year:2020, values:[null,null, null, 4000, null,... ,null], id:'HR2020DatasetKey'},]
 *                             jan, febr, march, apr, may, ..., dec
 *
 * in example years properties are not calendar year, its financial years, and values starts from january
 * but if firstMonthName was 'june', array would be sorted and start would be june and last would be may
 * */
const mapHRDashboardDataByFiscalYears = ({
  dashboardData,
  firstMonthName,
  lastPeriod,
  datasetValueKey,
}) => {
  const lastMonthIndex = Number(lastPeriod.slice(4));
  const sortedByFiscalYearsDashboardData = dashboardData.reduce(
    (result, { month, year, ...rest }) => {
      // TODO: Rewrite logic not to use let
      let resultYearForData = year;
      if (Number(month) > lastMonthIndex) {
        resultYearForData = Number(year) + 1;
      }

      if (!result[resultYearForData]?.values) {
        // eslint-disable-next-line no-param-reassign
        result[resultYearForData] = {
          year: `${resultYearForData}`,
          values: [...new Array(12)].map(() => null),
        };
      }

      const currentMonthValue =
        result[resultYearForData].values[Number(month) - 1];
      // eslint-disable-next-line no-param-reassign
      result[resultYearForData].values[Number(month) - 1] = currentMonthValue
        ? Number(Number(rest[datasetValueKey]).toFixed(2)) + currentMonthValue
        : Number(Number(rest[datasetValueKey]).toFixed(2));
      return result;
    },
    {}
  );

  return Object.values(sortedByFiscalYearsDashboardData)
    .map(({ year, values }) => ({
      year,
      values: sortArrayFromJanuaryToArrayFromFirstMonth(values, firstMonthName),
      id: `HR${datasetValueKey}${year}`,
    }))
    .sort(sortChartDataByYear);
};

// filters all values so they all would be in date range between firstPeriod and lastPeriod
/**
 * @param firstPeriod {string} -> e.g '201912' -> 2019 December
 * @param lastPeriod {string} -> e.g '202112' -> 2021 December
 * @param dashboardData {array} -> [{month:'09', year: '2020', ...rest}]
 * */
const filterHRDashboardDataByFiscalYears = (
  firstPeriod,
  lastPeriod,
  dashboardData
) => {
  const firstMonth = Number(firstPeriod.slice(4));
  const firstYear = Number(firstPeriod.slice(0, 4));
  const lastMonth = Number(lastPeriod.slice(4));
  const lastYear = Number(lastPeriod.slice(0, 4));
  return dashboardData.filter(({ month, year }) => {
    if (Number(year) < firstYear || Number(year) > lastYear) {
      return false;
    }
    if (Number(year) === firstYear && Number(month) < firstMonth) {
      return false;
    }
    if (Number(year) === lastYear && Number(month) > lastMonth) {
      return false;
    }
    return true;
  });
};

// financial year can take it start from any month and duration is 12 month e.g  March -> February
// array of values for each year is sorted from first month of financial year to last
//              e.g. [1000, 2000, 5000, ...,   1000]
//                  march,  apr,  may, ...,  february
// We need to take index of maxPeriod in this array(e.g maxPeriod = '202105' -> month = 05(may) year = 2021)

// function gets array index of maxPeriod in array of values sorted from first month of financial year
/**
 * @param firstMonthName {string} -> name of first month of financial year(e.g 'June')
 * @param maxPeriod {string} -> the most recent period of company(comes from backend) -> e.g '202112' is December 2021
 * */
const getMaxPeriodDataIndexInSortedByFiscalMonthArray = (
  firstMonthName,
  maxPeriod = '202112'
) => {
  if (!maxPeriod) return 0;
  const maxPeriodMonthIndex = Number(maxPeriod.slice(4)) - 1;
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  const indexDifference = Number(maxPeriodMonthIndex) - Number(firstMonthIndex);
  if (indexDifference < 0) {
    return 12 - Math.abs(indexDifference);
  }
  return indexDifference;
};

export const getMaxPeriodValuesForEachTabFromDashboardData = (
  HRDashboardData,
  firstMonthName,
  maxPeriod
) => {
  const dataIndex = getMaxPeriodDataIndexInSortedByFiscalMonthArray(
    firstMonthName,
    maxPeriod
  );
  return HRDashboardData.reduce((maxPeriodsValues, { tabId, tabValues }) => {
    const currentExerciceDataset = tabValues[0];
    if (!currentExerciceDataset) {
      // eslint-disable-next-line no-param-reassign
      maxPeriodsValues[tabId] = null;
      return maxPeriodsValues;
    }

    const values = Array.isArray(currentExerciceDataset?.values)
      ? currentExerciceDataset?.values
      : [];

    if (values.every((value) => value === null)) {
      // eslint-disable-next-line no-param-reassign
      maxPeriodsValues[tabId] = null;
      return maxPeriodsValues;
    }
    // eslint-disable-next-line no-param-reassign
    maxPeriodsValues[tabId] = values[dataIndex];
    return maxPeriodsValues;
  }, {});
};
