import React, { memo } from 'react';
import { Col, Typography } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MainStyles from 'assets/styles/main.module.css';
import Styles from './CompanySettingsForm.module.css';

const propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.node,
};

export const FormItem = memo(({ label, children, tooltip }) => (
  <Col span={24} className={clsx(MainStyles.col, Styles.formCol)}>
    <Typography.Paragraph
      className={clsx(MainStyles.formLabel, Styles.formLabel)}
    >
      <span>{label}</span>
      {tooltip}
    </Typography.Paragraph>
    <Typography.Paragraph className={Styles.formItem}>
      {children}
    </Typography.Paragraph>
  </Col>
));

FormItem.propTypes = propTypes;
