import React from 'react';
import Styles from 'containers/TermsOfUseLanding/TermsOfUseLanding.module.css';

/*
 * formatting text for terms of use
 * returns array of paragraphs which are also arrays that contains react elements of titles, bold texts, and regular texts
 *  in input text wrap: paragraphs with "\n", titles with "\title" and bold text with "\bold"
 *  e.g. input "\n \title THERE IS SOME TITLE \title \bold THERE IS BOLD TEXT \bold just text \n"
 *       output [[<span className={title}>THERE IS SOME TITLE<span>, <span className={bold}> THERE IS BOLD TEXT <span>, 'just text']]
 *                    (not string, it is react element)                     (also react element)                         (string)
 * */
export const mapTermsOfUseText = (text = '') => {
  const resultElements = [];

  const paragraphs = text.split('\n');

  paragraphs.forEach((paragraph) => {
    if (!paragraph) {
      return;
    }
    const paragraphElements = [];
    const titlesAndTexts = paragraph.split('\title');

    titlesAndTexts.forEach((titleOrText, index) => {
      if (!titleOrText) {
        return;
      }
      if (index % 2 === 1) {
        paragraphElements.push(
          <span className={Styles.termsTitle}>{titleOrText}</span>
        );
      }
      if (index % 2 === 0) {
        const regularOrBoldTexts = titleOrText.split('\bold');

        regularOrBoldTexts.forEach((boldOrRegularText, boldIndex) => {
          if (!boldOrRegularText) {
            return;
          }
          if (boldIndex % 2 === 1) {
            paragraphElements.push(
              <span className={Styles.boldTermsOfUseText}>
                {boldOrRegularText}
              </span>
            );
          }
          if (boldIndex % 2 === 0) {
            const regularOrLinkTexts = boldOrRegularText.split('\blink');

            regularOrLinkTexts.forEach((linkOrRegularText, linkIndex) => {
              if (!linkOrRegularText) {
                return;
              }
              if (linkIndex % 2 === 1) {
                paragraphElements.push(
                  <a
                    href={`mailto:${linkOrRegularText}`}
                    className={Styles.boldTermsOfUseLink}
                  >
                    {linkOrRegularText}
                  </a>
                );
              }
              if (linkIndex % 2 === 0) {
                paragraphElements.push(linkOrRegularText);
              }
            });
          }
        });
      }
    });
    resultElements.push(paragraphElements);
  });
  return resultElements;
};
