import { takeLatest, call, put } from 'redux-saga/effects';
import {
  HELP_REQUEST,
  helpRequestError,
  helpRequestSuccess,
} from 'modules/helpModal/actions';
import { requestHelpService } from 'modules/helpModal/services';

function* requestHelpWorker({
  payload: { onSuccess, onError, ...requestBody },
}) {
  try {
    yield call(requestHelpService, requestBody);
    yield put(helpRequestSuccess());
    yield onSuccess();
  } catch (error) {
    yield put(helpRequestError({ error }));
    yield onError(error);
  }
}

export function* helpModalMainSaga() {
  yield takeLatest(HELP_REQUEST, requestHelpWorker);
}
