import moment from 'moment';
import {
  SCHEDULE_DEFAULT_TIMES,
  TIME_FORMAT,
} from 'const/SalaryCompanySettings';

import { getTooltipAddressForBranch } from 'modules/SalaryModule/Calendar/helpers';
import { WEEK_DAYS } from '../../../../const/translations';
const {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} = WEEK_DAYS;

/**
 * Chunk array to parts with chunkSize
 * @param { Array } data - array
 * @param { Number } chunkSize - elements count in each chunk
 * @returns {Array} return array with chunk sized arrays inside.
 */
export const chunkArray = (data, chunkSize) => {
  const chunkedArray = [];

  for (let i = 0; i < Math.ceil(data.length / chunkSize); i += 1) {
    chunkedArray[i] = data.slice(i * chunkSize, i * chunkSize + chunkSize);
  }

  return chunkedArray;
};

const DAY_IN_MILLISECONDS = 86400000;
const HOUR_IN_MILLISECONDS = 3600000;
const MINUTE_IN_MILLISECONDS = 60000;

/**
 * Get hours and minutes between two times
 * @param { String } firstTime - time string hh:mm
 * @param { String } secondTime - time string hh:mm
 * @returns {Object} return object with difference hours and minutes
 */
const getTimeDiff = (firstTime, secondTime) => {
  if (!firstTime || !secondTime) {
    return { hours: 0, minutes: 0 };
  }

  const getDate = (string) =>
    new Date(0, 0, 0, string.split(':')[0], string.split(':')[1]); // получение даты из строки (подставляются часы и минуты
  const different = getDate(secondTime) - getDate(firstTime);

  const hours = Math.floor(
    (different % DAY_IN_MILLISECONDS) / HOUR_IN_MILLISECONDS
  );
  const minutes = Math.round(
    ((different % DAY_IN_MILLISECONDS) % HOUR_IN_MILLISECONDS) /
      MINUTE_IN_MILLISECONDS
  );
  if (firstTime === '0:00' && secondTime === '24:00') {
    return { hours: 24, minutes: 0 };
  }
  return { hours, minutes };
};

/**
 * Correct time object if minutes is over 60
 * @param { Object } timeObject - hours and minutes
 * @returns {Array} return array with corrected hours and minutes
 */
export const minutesToHoursAndMinutes = (timeObject) => {
  const minutesHours = Math.floor(timeObject.minutes / 60);
  return [minutesHours, timeObject.minutes - minutesHours * 60];
};

/**
 * Get total hours and minutes between two times
 * @param { Object } gap1 - times object with hours and minutes
 * @param { Object } gap2 - times object with hours and minutes
 * @returns {Object} return difference between gap1 and gap2
 */
const getTotalHours = (gap1, gap2) => {
  const total = {
    hours: gap1.hours + gap2.hours,
    minutes: gap1.minutes + gap2.minutes,
  };

  if (total.minutes > 59) {
    const [plusHours, restMinutes] = minutesToHoursAndMinutes(total);
    total.hours += plusHours;
    total.minutes = restMinutes;
  }

  return total;
};

/**
 * Get total hours in schedule day row
 * @param { Object } gapState - current day intervals state
 * @param { Boolean } withLunchBreak - is two intervals in day or not
 * @returns {Object} return total hours in schedule row
 */
export const getScheduleRowTotal = (gapState, withLunchBreak) => {
  if (!gapState) return { hours: 0, minutes: 0 };
  const firstGap = getTimeDiff(gapState.gap1, gapState.gap2);
  if (!withLunchBreak) {
    return firstGap;
  }

  const secondGap = getTimeDiff(gapState.gap3, gapState.gap4);

  return getTotalHours(firstGap, secondGap);
};

/**
 * Get time string from schedule object
 * @param { Object } schedule - schedule interval object
 * @returns {String} hh:mm
 */
export const getTimeStringFromScheduleArray = (schedule) => {
  if (!schedule) {
    return null;
  }
  const minutes = schedule.minutes === 0 ? '00' : schedule.minutes;
  return `${schedule.hours}:${minutes}`;
};

/**
 * Get total time for schedule form table
 * @param { Object } totalTime - object with days total times
 * @returns {Object} {hours: hh, minutes: mm}
 */
export const getSummaryTotalTime = (totalTime) => {
  const total = Object.keys(totalTime).reduce(
    (result, key) => {
      const item = totalTime[key];

      return {
        hours: result.hours + item.hours,
        minutes: result.minutes + item.minutes,
      };
    },
    { hours: 0, minutes: 0 }
  );

  const [plusHours, restMinutes] = minutesToHoursAndMinutes(total);

  return {
    hours: total.hours + plusHours,
    minutes: restMinutes,
  };
};

/**
 * Get enable/disable work days for schedules table
 * @param { Object } schedule - schedule days intervals
 * @returns {Array} days objects with title and disabled state
 */
export const getWorkWeekDays = (schedule) => {
  const weekDays = schedule.reduce((result, { label }) => {
    // eslint-disable-next-line no-param-reassign
    result.push(label);
    return result;
  }, []);

  return [
    { title: MONDAY, disabled: !weekDays.includes(MONDAY) },
    { title: TUESDAY, disabled: !weekDays.includes(TUESDAY) },
    { title: WEDNESDAY, disabled: !weekDays.includes(WEDNESDAY) },
    { title: THURSDAY, disabled: !weekDays.includes(THURSDAY) },
    { title: FRIDAY, disabled: !weekDays.includes(FRIDAY) },
    { title: SATURDAY, disabled: !weekDays.includes(SATURDAY) },
    { title: SUNDAY, disabled: !weekDays.includes(SUNDAY) },
  ];
};

/**
 * Get moment object from time string
 * @param { String } time string
 * @returns {Object} moment object with time format
 */
const getMoment = (time) => moment(time, TIME_FORMAT);

/**
 * Add minutes to moment object
 * @param { Object } time object
 * @param { Number } minutes to add
 * @returns {Object} time object with additional minutes
 */
const momentAdd = (time, minutes = 15) => {
  const addedTime = time
    .add(minutes, 'minutes')
    .format(TIME_FORMAT)
    .toString();
  return addedTime === '0:00' ? '24:00' : addedTime;
};

/**
 * Subtract minutes to moment object
 * @param { Object } time object
 * @param { Number } minutes to subtract
 * @returns {Object} time object with subtract minutes
 */
const momentSubtract = (time, minutes = 15) =>
  time
    .subtract(minutes, 'minutes')
    .format(TIME_FORMAT)
    .toString();

/**
 * Get min and max time from schedule day intervals state values
 * @param { Object } gapState - current day intervals state
 * @param { Boolean } withLunchBreak - is two intervals in day or not
 * @returns {Object} min and max time from current intervals state
 */
export const getMinMaxValues = (gapState, withLunchBreak) => {
  let minTime = null;
  let maxTime = null;

  const availableGaps = withLunchBreak
    ? ['gap1', 'gap2', 'gap3', 'gap4']
    : ['gap1', 'gap2'];

  Object.keys(gapState).forEach((key) => {
    let value = gapState[key];
    if (key === 'gap4' || (key === 'gap2' && !withLunchBreak)) {
      value = value === '24:00' ? '23:59' : value;
    }
    const timeFormat = getMoment(value);

    if (!minTime) {
      minTime = timeFormat;
    }
    if (!maxTime) {
      maxTime = timeFormat;
    }

    if (availableGaps.includes(key)) {
      minTime = timeFormat.isBefore(minTime) ? timeFormat : minTime;
      maxTime = timeFormat.isAfter(maxTime) ? timeFormat : maxTime;
    }
  });

  return {
    min: minTime.format(TIME_FORMAT).toString(),
    max: maxTime.format(TIME_FORMAT).toString(),
  };
};

/**
 * Get available options between min and max time select
 * @param { Object } minMax - min and max intervals time state
 * @returns {Array} available options
 */
export const getAvailableOptions = (minMax) => {
  const minTime = getMoment(minMax.min);
  const maxTime = getMoment(minMax.max);

  return SCHEDULE_DEFAULT_TIMES.filter(
    ({ value }) =>
      getMoment(value).isAfter(minTime) && getMoment(value).isBefore(maxTime)
  );
};

/**
 * Get available min time select options
 * @param { Object } minMax - min and max intervals time state
 * @returns {Array} available options for min time select
 */
export const getAvailableMinOptions = (minMax) => {
  const maxTime = getMoment(minMax.max);

  // return SCHEDULE_DEFAULT_TIMES.filter(({ value }) =>
  //   getMoment(value).isBefore(maxTime)
  // );
  return SCHEDULE_DEFAULT_TIMES.filter(({ value }) => {
    let adjustedValue = value;
    if (value === '24:00') {
      adjustedValue = '23:59';
    }
    return getMoment(adjustedValue).isBefore(maxTime);
  });
};

/**
 * Get available max time select options
 * @param { Object } minMax - min and max intervals time state
 * @returns {Array} available options for max time select
 */
export const getAvailableMaxOptions = (minMax) => {
  const minTime = getMoment(minMax.min);

  return SCHEDULE_DEFAULT_TIMES.filter(({ value }) => {
    let adjustedValue = value;
    if (value === '24:00') {
      adjustedValue = '23:59';
    }
    return getMoment(adjustedValue).isAfter(minTime);
  });
};

/**
 * Get correct day intervals state
 * @param { Object } prevState - previous intervals state
 * @param { String } gapName - current interval select name
 * @param { String } gapValue - current interval select value
 * @param { Boolean } withLunchBreak - is two intervals in day or not
 * @returns {Object} correct day intervals state
 */
export const getCorrectGapState = (
  prevState,
  gapName,
  gapValue,
  withLunchBreak
) => {
  const state = {
    ...prevState,
    [gapName]: gapValue,
  };

  if (getMoment(state.gap2).isBefore(getMoment(state.gap1))) {
    state.gap2 = momentAdd(getMoment(state.gap1));
  }
  if (getMoment(state.gap3).isBefore(getMoment(state.gap1))) {
    state.gap3 = momentAdd(getMoment(state.gap1));
  }
  if (
    getMoment(state.gap3).isBefore(getMoment(state.gap2)) &&
    withLunchBreak &&
    gapName !== 'gap3'
  ) {
    state.gap3 = momentAdd(getMoment(state.gap2));
  }
  if (
    getMoment(state.gap3).isBefore(getMoment(state.gap2)) &&
    withLunchBreak &&
    gapName === 'gap3'
  ) {
    state.gap2 = momentSubtract(getMoment(state.gap3));
  }
  if (
    getMoment(state.gap2).isSameOrAfter(getMoment(state.gap4)) &&
    withLunchBreak
  ) {
    state.gap2 = momentSubtract(getMoment(state.gap4));
  }
  if (
    getMoment(state.gap3).isSameOrAfter(getMoment(state.gap4)) &&
    withLunchBreak
  ) {
    state.gap3 = momentSubtract(getMoment(state.gap4));
  }
  if (getMoment(state.gap1).isSame(getMoment(state.gap4))) {
    state.gap1 = momentSubtract(getMoment(state.gap4));
  }

  return state;
};

/**
 * Get schedule object for create/update schedule request
 * @param { Object } gapState - selected work time intervals for schedule day
 * @param { Boolean } withLunchBreak - is two intervals in day or not
 * @returns {Object} correct schedule object with hours and minutes
 */
export const getScheduleObjects = (gapState, withLunchBreak) => {
  const { gap1, gap2, gap3, gap4 } = gapState;
  const gap1Splitted = gap1.split(':');
  const gap2Splitted = gap2.split(':');
  const gap3Splitted = gap3.split(':');
  const gap4Splitted = gap4.split(':');

  const formattedState = [
    {
      start: {
        hours: +gap1Splitted[0],
        minutes: +gap1Splitted[1],
      },
      end: {
        hours: +gap2Splitted[0],
        minutes: +gap2Splitted[1],
      },
    },
  ];

  if (withLunchBreak) {
    formattedState.push({
      start: {
        hours: +gap3Splitted[0],
        minutes: +gap3Splitted[1],
      },
      end: {
        hours: +gap4Splitted[0],
        minutes: +gap4Splitted[1],
      },
    });
  }

  return formattedState;
};

/**
 * Get options for branch select
 * @param { Array } branches - current company branches
 * @returns {Array} options for branch select with formatted addresses
 */
export const getBranchesOptions = (branches) =>
  branches.map((branch) => ({
    label: `${branch.siret} - ${branch.waynumber1}, ${branch.wayname}`,
    value: branch.siret,
    tooltipLabelsArray: getTooltipAddressForBranch(branch),
  }));

/**
 * Create object with full addresses for each branch
 * @param { Array } branches - current company branches
 * @returns {Object} key - branch siret, value - coupled branch address
 */
export const getBranchesAddresses = (branches) => {
  const addresses = {};

  branches.forEach(
    ({ siret, waynumber1, waynumber2, wayname, wayname2, city, postcode }) => {
      const streetNumber = waynumber1 || waynumber2 || '';
      const streetNumberStringWithComma = streetNumber
        ? `${streetNumber},`
        : '';
      const streetName = wayname || wayname2 || '';
      addresses[siret] = {
        street: `${streetNumberStringWithComma} ${streetName}`,
        city,
        postcode,
        siret,
      };
    }
  );

  return addresses;
};

/**
 * Checking if the active branch among the current select branches options
 * @param { String } branchId - active branch id
 * @param { Array } branches - array of company branches
 * @returns {Boolean} check result
 */
export const isCurrentBranchInOptions = (branchId, branches) =>
  branches.some(({ siret }) => siret === branchId);

/**
 * Form object for create schedule request
 * @param { String } title - schedule title
 * @param { Object } scheduleDays - schedule days with intervals
 * @param { String } companyId - current company id
 * @param { String } siretNumber - current siret id
 * @param { Boolean } daysSame - flag indicates whether schedule is same for all
 * included days
 * @returns {Object} body object for schedule create request
 */
export const buildCreateScheduleBody = ({
  title,
  scheduleDays,
  siretNumber,
  companyId,
  daysSame,
}) => ({
  data: {
    title,
    days: scheduleDays,
    siret: siretNumber,
    daysSame,
  },
  companyId,
});

/**
 * Form object for update schedule request
 * @param { String } title - schedule title
 * @param { Object } scheduleDays - schedule days with intervals
 * @param { String } companyId - current company id
 * @param { String } scheduleId - schedule id
 * @param { Boolean } daysSame - flag indicates whether schedule is same for all
 * included days
 * @returns {Object} body object for schedule update request
 */
export const buildUpdateScheduleBody = ({
  title,
  scheduleDays,
  companyId,
  scheduleId,
  daysSame,
}) => ({
  data: {
    title,
    days: scheduleDays,
    daysSame,
  },
  companyId,
  scheduleId,
});
