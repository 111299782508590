import { fromJS } from 'immutable';
import {
  DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL,
  EMPLOYEE_ABSENCES_ERROR,
  EMPLOYEE_ABSENCES_REQUEST,
  EMPLOYEE_ABSENCES_SUCCESS,
  RESET_VACATION_MODAL_DATA,
  SET_EDIT_LEAVE_MODAL_DATA,
  SET_LEAVE_MODAL_MAIN_DATA,
  TOGGLE_VACATION_MODAL_VISIBILITY,
  SET_TABLE_DEFAULT_PAGE,
} from 'modules/SalaryModule/Vacations/actions';
import {
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  STATUS_ERROR,
  STATUS_LOADING,
} from 'const';

const initialState = fromJS({
  isModalVisible: false,
  vacationModalData: null,
  submitType: 'create',
  leaveObjId: null,
  startDate: null,
  employeeAbsences: [],
  status: STATUS_NOT_REQUESTED,
  tableDefaultPage: 1,
});

export const vacationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_VACATION_MODAL_VISIBILITY: {
      return state.updateIn(['isModalVisible'], (value) => !value);
    }
    case SET_LEAVE_MODAL_MAIN_DATA: {
      const { startDate, ...vacationModalData } = action.payload;
      return state
        .set('vacationModalData', vacationModalData)
        .setIn(['startDate'], startDate || null);
    }
    case SET_EDIT_LEAVE_MODAL_DATA: {
      const { submitType, leaveObjId } = action.payload;
      return state
        .setIn(['submitType'], submitType)
        .setIn(['leaveObjId'], leaveObjId);
    }
    case RESET_VACATION_MODAL_DATA: {
      return initialState;
    }
    case DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL: {
      const { leaveObjId } = action.payload;
      return state.setIn(['leaveObjId'], leaveObjId);
    }
    case EMPLOYEE_ABSENCES_REQUEST:
      return state.set('status', STATUS_LOADING);
    case EMPLOYEE_ABSENCES_SUCCESS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('employeeAbsences', fromJS(action.payload.absences));
    case EMPLOYEE_ABSENCES_ERROR:
      return state.set('status', STATUS_ERROR);
    case SET_TABLE_DEFAULT_PAGE:
      return state.set('tableDefaultPage', action.payload);
    default:
      return state;
  }
};
