import { call, put, select, takeLatest } from 'redux-saga/effects';
import { setActiveBranch } from 'modules/SalaryModule/CompanySettings/actions';
import { downloadFile } from 'helpers/common';
import { selectActiveBranchData } from 'modules/SalaryModule/CompanySettings/selectors';
import {
  downloadEmployeeTableXLSX,
  getEmployees,
  setEmployeeSchedule,
} from 'modules/SalaryModule/Employees/services';
import {
  CHANGE_EMPLOYEES_FILTERS,
  DOWNLOAD_EMPLOYEES_TABLE_XLSX,
  FETCH_COMPANY_BRANCHES_REQUEST,
  FETCH_EMPLOYEES_REQUEST,
  fetchCompanyBranchesSuccess,
  fetchEmployeesError,
  fetchEmployeesRequest,
  fetchEmployeesSuccess,
  SET_EMPLOYEE_SCHEDULE,
  setEmployeesFilters,
} from 'modules/SalaryModule/Employees/actions';
import { getCompanyDetail } from '../../CompanySettings/services';

import { selectFilters } from '../selectors';
import { getCsvDownloadQuery } from '../utils';

function* fetchEmployeesWorker({ payload }) {
  try {
    const { data } = yield call(getEmployees, payload);
    yield put(fetchEmployeesSuccess({ data: data.employees }));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* fetchCompanyBranchesWorker({ payload }) {
  try {
    const { data } = yield call(getCompanyDetail, payload);
    yield put(fetchCompanyBranchesSuccess(data.branches));
    const activeBranch = yield select(selectActiveBranchData);
    if (data.branches.length === 0) {
      yield put(setActiveBranch(null));
    }
    const shouldUpdateActiveBranch =
      !activeBranch ||
      (activeBranch &&
        !data.branches.some(({ siret }) => siret === activeBranch.siret));
    if (shouldUpdateActiveBranch) {
      yield put(setActiveBranch(data.branches[0]));
    }
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* changeEmployeesFiltersWorker({ payload }) {
  try {
    const selectedFilters = yield select(selectFilters);
    const filters = { ...selectedFilters };
    Object.keys(payload).forEach((key) => {
      filters[key] = payload[key];
    });

    yield put(setEmployeesFilters(filters));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* setEmployeeScheduleWorker({ payload }) {
  try {
    yield call(setEmployeeSchedule, payload);
    yield put(fetchEmployeesRequest(payload.companyId));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* downloadEmployeeTableXLSXWorker({ payload: { companyId, filter } }) {
  try {
    const query = getCsvDownloadQuery(filter);

    const { fileData, fileName } = yield call(downloadEmployeeTableXLSX, {
      companyId,
      query,
    });

    yield call(downloadFile, fileData, fileName);
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

export function* employeesMainSaga() {
  yield takeLatest(FETCH_EMPLOYEES_REQUEST, fetchEmployeesWorker);
  yield takeLatest(CHANGE_EMPLOYEES_FILTERS, changeEmployeesFiltersWorker);
  yield takeLatest(SET_EMPLOYEE_SCHEDULE, setEmployeeScheduleWorker);
  yield takeLatest(FETCH_COMPANY_BRANCHES_REQUEST, fetchCompanyBranchesWorker);
  yield takeLatest(
    DOWNLOAD_EMPLOYEES_TABLE_XLSX,
    downloadEmployeeTableXLSXWorker
  );
}
