/**
 * @see https://stackoverflow.com/questions/6134039/format-number-to-always-show-2-decimal-places/34796988#34796988
 * more reliable solution than accepted answer, which bugs out on certain cases (ex.: 35.30 turns into 35.29)
 */
export const roundToDecimals = (num, decimals = 2) =>
  parseFloat(Math.round(num * 10 ** decimals) / 10 ** decimals).toFixed(
    decimals
  );

/**
 * Formats float value to be decimal of exact size (without trailing zeros).
 * Skips integers.
 * Ex: formatDecimals(4.2) -> 4.2
 * Ex: formatDecimals(5, 3) -> 5
 * Ex: formatDecimals(3.141592, 3) -> 3.141
 * @param {Number} number Initial number
 * @param {Number} decimalsLength Length of returning value's decimal part
 * @returns {String} Formatted to decimals number (without trailing zeros)
 */
export const formatDecimals = (number, decimalsLength = 2) => {
  if (number.toPrecision(1) === String(number)) {
    return number;
  }

  const [num, decimals] = String(number).split('.');

  if (!decimals) {
    return num;
  }

  return [num, decimals.slice(0, decimalsLength)].join('.');
};

/**
 * Takes string and returns width as number dependant on lengthiest word in this string.
 * @param {string} title Cell text.
 * @returns {number} Width of the header cell.
 */
export const calculateHeaderCellWidth = (title) => {
  const longestWordLength = title
    .split(' ')
    .reduce(
      (maxLength, word) => (maxLength < word.length ? word.length : maxLength),
      0
    );

  // 140 - Old default value for cell width.
  // 13 - Amount of pixels per letter (got it experimentally, but less is too little, and more is too much).
  // 30 - Compensation of cell padding.
  return Math.max(140, longestWordLength * 13 + 30);
};
