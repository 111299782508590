import { createSelector } from 'reselect';
// import { BOUQUET_MY_EXCO, EXCO_SERVICES } from 'const/excoServices';

// export const selectPackagesInCart = createSelector(
//   (state) => state.getIn(['cartReducer', 'currentItems']),
//   (currentCartItems) => (currentCartItems ? currentCartItems.toJS() : [])
// );

export const selectBookmarks = createSelector(
  (state) => state.getIn(['bookmarksReducer', 'usefulLinks']),
  (usefulLinks) => (usefulLinks ? usefulLinks.toJS() : [])
);
