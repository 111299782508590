import React, { useCallback } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAuthorizedModules } from 'modules/loggedUserInfo/selectors';
import { EXCO_SERVICES } from 'const/excoServices';
import { ServiceCardDefault } from 'components/ServiceCard/ServiceCardDefault';
import {
  selectCardsWithCost,
  selectPackagesInCart,
} from 'modules/cart/selectors';
import { useLocation } from 'react-router';
import { FRENCH_ROUTES } from 'const/translations/Routes';
import { ROUTES } from 'const';

export const useServiceCards = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  if (
    ![
      ROUTES.STORE,
      FRENCH_ROUTES.STORE,
      ROUTES.ROOT,
      FRENCH_ROUTES.ROOT,
    ].includes(pathname)
  ) {
    throw Error(`Can't use service cards outside the store or home page.`);
  }

  const addedModulesToCart = useSelector(selectPackagesInCart);
  const userModules = useSelector(selectUserAuthorizedModules);
  const excoCardsWithCost = useSelector(selectCardsWithCost);

  const handleActionDispatch = useCallback(
    (actionToDispatch) => {
      dispatch(
        actionToDispatch({
          onError: (error) => message.error(error),
        })
      );
    },
    [dispatch]
  );

  const isHomePage = [ROUTES.ROOT, FRENCH_ROUTES.ROOT].includes(pathname);
  const services = isHomePage ? EXCO_SERVICES : excoCardsWithCost;

  return services.map((excoService) => {
    const isActive =
      excoService.MODULES_FOR_ACCESS.some((accessModule) =>
        userModules.includes(accessModule)
      ) || excoService.MODULES_FOR_ACCESS.length === 0;

    if (!isActive && isHomePage) return null;

    const isIncludedToCart = addedModulesToCart.includes(
      excoService.PACKAGE_NAME
    );
    const shouldDispatchActionOnClick = !!excoService.ACTION;

    return (
      <ServiceCardDefault
        key={excoService.ID}
        isAddedToCart={isIncludedToCart}
        isActive={isActive}
        serviceCardObject={excoService}
        selectIsLoading={excoService.SELECT_IS_LOADING}
        redirectHandler={
          isActive && shouldDispatchActionOnClick
            ? handleActionDispatch
            : undefined
        }
      />
    );
  });
};
