import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { DATE_FORMAT, INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import moment from 'moment';
import { UNSET_DUE_DATE_VALUE_ISO } from 'modules/invoiceSearch/const';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';
import {
  getValidationTooltipClassName,
  getValidationTooltipTitle,
} from 'helpers/invoiceSearchTable/validate';

const { IN_PROGRESS: IN_PROGRESS_STATUS } = INVOICE_SEARCH_PAYMENT_STATUSES;
const {
  TOOLTIP: {
    VALIDATION_STATUS_TOOLTIPS: { IN_PROGRESS },
    NOT_PAYABLE,
  },
} = INVOICE_SEARCH_TRANSLATIONS;

export const useValidateTooltipProps = () => {
  const {
    date,
    dueDate,
    approvedDueDate,
    isPayableInvoice,
    isValidated,
    paymentStatusOnFilter,
  } = useInvoiceActionsData();

  const isActual = moment(date)
    .add(3, 'y')
    .isAfter(moment());

  const isOverdue =
    !moment(dueDate).isSame(UNSET_DUE_DATE_VALUE_ISO, 'day') &&
    (isValidated
      ? moment(approvedDueDate, DATE_FORMAT).isAfter(moment(dueDate))
      : moment().isAfter(moment(dueDate)));

  const validationTooltipTitle =
    isPayableInvoice && isActual
      ? getValidationTooltipTitle(isValidated, isOverdue)
      : NOT_PAYABLE;

  const tooltipTitle =
    paymentStatusOnFilter === IN_PROGRESS_STATUS
      ? IN_PROGRESS
      : validationTooltipTitle;

  const validationTooltipClassName = getValidationTooltipClassName(
    isValidated,
    isOverdue
  );

  return {
    title: tooltipTitle,
    overlayClassName:
      isPayableInvoice && isActual
        ? Styles[`tooltip${validationTooltipClassName}`]
        : Styles.tooltipApprove,
    arrowPointAtCenter: true,
    placement: 'topRight',
  };
};
