import {
  object as yupObject,
  string as yupString,
  bool as yupBool,
  date as yupDate,
  number as yupNumber,
} from 'yup';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';

export const validationSchema = () =>
  yupObject().shape({
    contractByExco: yupBool(),
    dpaeByExco: yupBool(),
    employeePosition: yupString().required('Field cannot be empty'),
    datetimeHire: yupDate().required('Field cannot be empty'),
    classification: yupString().required('Field cannot be empty'),
    levelCoefficient: yupString(),
    remunerationGross: yupString().required('Field cannot be empty'),
    salaryGross: yupNumber().required('Field cannot be empty'),
    remunerationGrossDetails: yupString().when('remunerationGross', {
      is: ADD_EMPLOYEE_FORM_TRANSLATIONS.GENERAL_LABELS.OTHER,
      then: yupString().required('Field cannot be empty'),
    }),
    typeEmployment: yupString().required('Field cannot be empty'),
    employmentParttimeDetails: yupString().when('typeEmployment', {
      is: ADD_EMPLOYEE_FORM_TRANSLATIONS.GENERAL_LABELS.PARTTIME,
      then: yupString().required('Field cannot be empty'),
    }),
    typeContract: yupString().required('Field cannot be empty'),
    typeContractDetails: yupString().when('typeContract', {
      is: ADD_EMPLOYEE_FORM_TRANSLATIONS.GENERAL_LABELS.OTHER,
      then: yupString().required('Field cannot be empty'),
    }),
    typeCDD: yupString().when('typeContract', {
      is:
        ADD_EMPLOYEE_FORM_TRANSLATIONS.WORK_CONTRACT_FORM_TAB.LABELS
          .CONTRACT_TYPE_CDD,
      then: yupString().required('Field cannot be empty'),
    }),
    termCDD: yupDate().when('typeCDD', {
      is: (val) => !!val,
      then: yupDate().required('Field cannot be empty'),
    }),
    workerReplaced: yupString().when('typeCDD', {
      is:
        ADD_EMPLOYEE_FORM_TRANSLATIONS.WORK_CONTRACT_FORM_TAB.LABELS
          .WORKER_REPLACE,
      then: yupString().required('Field cannot be empty'),
    }),
  });
