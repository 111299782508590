import { APP_MODULES } from 'const/index';

export const PACKAGES_NAMES = {
  MY_EXCO: 'MY_EXCO',
  FACT: 'FACT',
  PAY: 'PAY',
  WAIBI: 'WAIBI',
};

export const PACKAGES_NAMES_TRANSLATIONS = {
  [PACKAGES_NAMES.MY_EXCO]: 'Bouquet My Exco',
  [PACKAGES_NAMES.FACT]: 'Mes ventes – FACT',
  [PACKAGES_NAMES.PAY]: 'Mes dépenses – PAY',
  [PACKAGES_NAMES.WAIBI]: 'Indicateurs avancés – WAIBI',
};

export const PACKAGES_DEPENDENCIES = {
  [PACKAGES_NAMES.MY_EXCO]: [PACKAGES_NAMES.FACT, PACKAGES_NAMES.PAY],
};

export const UNIQUE_MODULES_OF_EACH_PACKAGE = {
  [PACKAGES_NAMES.MY_EXCO]: [APP_MODULES.SEARCH_INVOICES, APP_MODULES.EVP],
  [PACKAGES_NAMES.FACT]: [APP_MODULES.ISANETFACT],
  [PACKAGES_NAMES.WAIBI]: [APP_MODULES.WAIBI],
  [PACKAGES_NAMES.PAY]: [
    APP_MODULES.PAY_INVOICE,
    APP_MODULES.APPROVE_INVOICE,
    APP_MODULES.READONLY_INVOICE,
    APP_MODULES.APPROVE_AND_PAY_INVOICE,
    APP_MODULES.PRE_VALIDATE_INVOICE,
  ],
};
// accesses below are excluded by Exco requirement
// APP_MODULES.EVP
export const ALL_MODULES_OF_EACH_PACKAGE = {
  FREE_MODULES: [
    APP_MODULES.UPLOAD_DOCUMENTS,
    APP_MODULES.DOCUMENTS_CABINET,
    APP_MODULES.ACCESS_SOCIAL_DIR,
  ],
  [PACKAGES_NAMES.MY_EXCO]: [
    APP_MODULES.SEARCH_INVOICES,
    APP_MODULES.DASHBOARD_FIN,
  ],
  [PACKAGES_NAMES.PAY]: [
    APP_MODULES.APPROVE_INVOICE,
    APP_MODULES.READONLY_INVOICE,
    APP_MODULES.APPROVE_AND_PAY_INVOICE,
    APP_MODULES.PRE_VALIDATE_INVOICE,
  ],
  [PACKAGES_NAMES.FACT]: [APP_MODULES.ISANETFACT],
  [PACKAGES_NAMES.WAIBI]: [APP_MODULES.WAIBI],
};

export const getModulesForPurchase = (isMainUser = false, isDashboardFin) => ({
  [PACKAGES_NAMES.MY_EXCO]: isDashboardFin
    ? [APP_MODULES.SEARCH_INVOICES, APP_MODULES.DASHBOARD_FIN]
    : [APP_MODULES.SEARCH_INVOICES],
  [PACKAGES_NAMES.PAY]: isMainUser
    ? [APP_MODULES.APPROVE_INVOICE]
    : [APP_MODULES.APPROVE_INVOICE, APP_MODULES.PRE_VALIDATE_INVOICE],
  [PACKAGES_NAMES.FACT]: [APP_MODULES.ISANETFACT],
  [PACKAGES_NAMES.WAIBI]: [APP_MODULES.WAIBI],
});

export const ADDITIONAL_USER_ROLES_LABELS = {
  [APP_MODULES.UPLOAD_DOCUMENTS]: 'Envoi de documents',
  [APP_MODULES.DOCUMENTS_CABINET]: 'Dossier partagé',
  [APP_MODULES.EVP]: 'Mes ressources humaines (EVP…)',
  [APP_MODULES.SEARCH_INVOICES]: 'Recherche de factures',
  [APP_MODULES.DASHBOARD_FIN]: 'Indicateurs d’activité et trésorerie',
  [APP_MODULES.DASHBOARD_HR]: 'Indicateurs RH',
  [APP_MODULES.APPROVE_INVOICE]: 'PAY : consultation + demande paiement',
  [APP_MODULES.PRE_VALIDATE_INVOICE]: 'PAY : pré-validation PAY & Déjà Payé',
  [APP_MODULES.READONLY_INVOICE]: 'PAY : consultation simple',
  [APP_MODULES.APPROVE_AND_PAY_INVOICE]: "PAY : paiement d'une facture",
  [APP_MODULES.ISANETFACT]: 'FACT',
  [APP_MODULES.WAIBI]: 'Mon tableau de bord',
  [APP_MODULES.ACCESS_SOCIAL_DIR]: 'Dossier Social (Dossier Partagé)',
};
