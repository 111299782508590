import React from 'react';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Styles from 'components/NetPromoterScoreModal/NetPromoterScoreModal.module.css';

const COLUMNS_COUNT = 10;

const getIconByIndex = (index) =>
  (index <= 5 && (
    <FrownOutlined className={Styles.icon} style={{ color: 'var(--red)' }} />
  )) ||
  (index <= 7 && (
    <MehOutlined className={Styles.icon} style={{ color: 'var(--orange)' }} />
  )) ||
  (index < COLUMNS_COUNT && (
    <SmileOutlined
      className={Styles.icon}
      style={{ color: 'var(--limeGreen)' }}
    />
  ));

export const createColumns = (currentCell) =>
  new Array(COLUMNS_COUNT).fill().map((_, index) => ({
    title: getIconByIndex(index),
    dataIndex: index + 1,
    key: index + 1,
    render: (text) => (
      <div
        className={clsx(Styles.tableCell, {
          [Styles.tableCellChecked]: currentCell === text,
        })}
      >
        {text}
      </div>
    ),
  }));
