import { createSelector } from 'reselect';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import {
  mapDashboardDataToChartView,
  mapDashboardDataToCumulLastDayAnnual,
} from 'helpers/chartDataMappers';
import { formatToTabItemsStructure } from 'helpers/treasury';
import { selectUserCurrentCompanyExercice } from '../../loggedUserInfo/selectors';

export const selectTreasuryStatus = (state) =>
  state.getIn(['treasuryPage', 'status']);

export const selectTreasurySingleAccountStatus = (state) =>
  state.getIn(['treasuryPage', 'singleAccountStatus']);

const selectTreasuryData = createSelector(
  (state) => state.getIn(['treasuryPage', 'treasuryData']),
  (treasuryData) => (treasuryData ? treasuryData.toJS() : {})
);

export const selectTreasuryLastUpdatedDate = (state) =>
  state.getIn(['treasuryPage', 'treasuryData', 'lastUpdated']) ||
  new Date(Date.now()).toISOString();

export const selectTreasuryDataForCharts = createSelector(
  selectTreasuryData,
  selectTreasuryLastUpdatedDate,
  selectUserCurrentCompanyExercice,
  (treasuryData, lastUpdated, currentExercice) =>
    mapDashboardDataToChartView(
      treasuryData.datasets,
      'balance',
      lastUpdated,
      currentExercice
    )
);

export const selectTreasuryDataForYear = createSelector(
  selectCurrentCompanyFirstFiscalMonth,
  selectTreasuryLastUpdatedDate,
  selectTreasuryDataForCharts,
  (firstMonthName, lastUpdate, treasuryChartData) =>
    mapDashboardDataToCumulLastDayAnnual(
      treasuryChartData,
      firstMonthName,
      true,
      lastUpdate
    )
);

export const selectTreasuryUserAccounts = createSelector(
  (state) => state.getIn(['treasuryPage', 'accounts']),
  (accounts) =>
    accounts
      ? formatToTabItemsStructure(accounts.toJS(), 'account', 'balance')
      : []
);
