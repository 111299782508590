import { createSelector } from 'reselect';
import { mapDocumentsDataToTable } from 'modules/documentsCabinet/helpers';

export const selectDocumentsCabinetStatus = (state) =>
  state.getIn(['documentsCabinet', 'status']);

export const selectDocumentsErrorMessage = (state) =>
  state.getIn(['documentsCabinet', 'error', 'error']);

export const selectCurrentPath = createSelector(
  (state) => state.getIn(['documentsCabinet', 'currentPath']),
  (currentPath) => (currentPath ? currentPath.toJS() : [])
);

export const selectCurrentSearch = (state) =>
  state.getIn(['documentsCabinet', 'search']);

const selectDocumentsCabinetData = createSelector(
  (state) => state.getIn(['documentsCabinet', 'data']),
  (data) => (data ? data.toJS() : {})
);

export const selectDocumentsTableData = createSelector(
  selectDocumentsCabinetData,
  (data) => mapDocumentsDataToTable(data)
);

export const selectDownloadingDocumentsList = (state) =>
  state.getIn(['documentsCabinet', 'downloadingDocumentsList']);
