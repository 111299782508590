import React, { memo, useCallback, useState } from 'react';
import { Typography } from 'antd';
import { Button } from 'components/ui';
import { Modal } from 'components/ui/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../const';
import {
  COMPANY_SETTINGS_REWARDS,
  COMPANY_SETTINGS_REWARDS_DETAIL,
} from '../../../../const/translations/SalaryCompanySettings';
import { selectUserCompanyId } from '../../../../modules/loggedUserInfo/selectors';
import { removeRewardFamily } from '../../../../modules/SalaryModule/Rewards/actions';
import { selectCompanyRewardsFamilies } from '../../../../modules/SalaryModule/Rewards/selectors';
import { getRewardFamilyRemoveModalBody } from '../../../../modules/SalaryModule/Rewards/utils';
import Styles from './CompanySettingsManageRewardsPage.module.css';
import { RewardsTable } from './RewardsTable';

const { Paragraph } = Typography;

const modalInitialState = {
  title: '',
  text: '',
  isError: false,
};

/*
 * List of company reward families
 * */
export const CSManageRewardFamilies = memo(() => {
  const families = useSelector(selectCompanyRewardsFamilies);
  const companyId = useSelector(selectUserCompanyId);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(modalInitialState);

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const showRemoveRewardsFamilyModal = useCallback((e) => {
    const { removable, id, count } = e.currentTarget.dataset;
    setModalData(getRewardFamilyRemoveModalBody(!!removable, id, count));
    setIsVisibleModal(true);
  }, []);

  const handleRemoveModalClose = useCallback(() => {
    setIsVisibleModal(false);
  }, []);

  const submitRemoveModal = useCallback(() => {
    if (!modalData.isError && modalData.familyId) {
      dispatch(
        removeRewardFamily({
          companyId,
          familyId: modalData.familyId,
        })
      );
    }
    handleRemoveModalClose();
  }, [handleRemoveModalClose, companyId, dispatch, modalData]);

  return (
    <div className={Styles.container}>
      <NavLink
        to={ROUTES.SALARIES_COMPANY_SETTINGS_REWARD_CREATE}
        className={Styles.newButtonLink}
      >
        <Button sizeAuto className={Styles.newButton}>
          {COMPANY_SETTINGS_REWARDS.ADD_FAMILY_BTN}
        </Button>
      </NavLink>
      <RewardsTable
        rewardsFamilies={families}
        onRemoveRewardsFamily={showRemoveRewardsFamilyModal}
      />
      <Modal
        fullMobileScreen={false}
        isVisible={isVisibleModal}
        width="384px"
        title={modalData.title}
        okText={COMPANY_SETTINGS_REWARDS.CONFIRM_TEXT}
        onClose={handleRemoveModalClose}
        onSubmit={submitRemoveModal}
        isError={modalData.isError}
        closable={false}
        isSmallModal
      >
        <Paragraph>
          {modalData.text}
          {modalData.isError && (
            <>
              <a
                href={ROUTES.SALARIES_REWARDS}
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.link}
              >
                {COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_CANCEL_LINK}
              </a>
              {modalData.text2}
            </>
          )}
        </Paragraph>
      </Modal>
    </div>
  );
});
