import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { useSelector } from 'react-redux';
import Styles from './SalaryBreadcrumbs.module.css';
import { selectCurrentRoute } from '../../../modules/routing/selectors';
import { SALARY_HEADER_LABELS } from '../../../const/translations';
import { ROUTES } from '../../../const';

const getCompanySettingsPath = (tab) =>
  `${ROUTES.SALARIES_COMPANY_SETTINGS}?tab=${tab}`;

const breadcrumbsRoutes = {
  'company-settings/rewards': {
    label: SALARY_HEADER_LABELS.COMPANY_SETTINGS,
    parent: getCompanySettingsPath('3'),
  },
  'company-settings/schedule': {
    label: SALARY_HEADER_LABELS.COMPANY_SETTINGS,
    parent: getCompanySettingsPath('2'),
  },
  default: {
    label: SALARY_HEADER_LABELS.MAIN,
    parent: ROUTES.SALARIES_MAIN_PAGE,
  },
};

const getBreadcrumbObject = (path) => {
  const routeKey = Object.keys(breadcrumbsRoutes).find((route) =>
    path.includes(route)
  );

  return routeKey ? breadcrumbsRoutes[routeKey] : breadcrumbsRoutes.default;
};

export const SalaryBreadcrumbs = memo(() => {
  const [prevPath, setPrevPath] = useState(null);

  const route = useSelector(selectCurrentRoute);

  useEffect(() => {
    const isMainPage = route === ROUTES.SALARIES_MAIN_PAGE;
    setPrevPath(!isMainPage ? getBreadcrumbObject(route) : null);
  }, [route]);

  if (!prevPath) {
    return null;
  }

  return (
    <div className={Styles.container}>
      <Link to={prevPath.parent} className={Styles.returnHome}>
        <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
        <span>{prevPath.label}</span>
      </Link>
    </div>
  );
});
