import React, { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { TIME_TABLE_MODAL } from 'const/translations/SalaryEmployeePage';
import { CustomSelect } from 'components/ui';
import { Modal } from 'components/ui/Modal';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getScheduleStructure,
  getScheduleTotalHours,
} from 'modules/SalaryModule/Employees/utils';
import { selectSchedules } from 'modules/SalaryModule/CompanySettings/selectors';
import Styles from './TimeTableModal.module.css';
import { TimeRange } from './TimeRange';

const { Text } = Typography;

const propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  scheduleId: PropTypes.string,
  setScheduleId: PropTypes.func,
  scheduleActiveMember: PropTypes.object,
};

export const TimeTableModal = memo(
  ({
    isVisible = true,
    onClose,
    onSubmit,
    scheduleId,
    setScheduleId,
    scheduleActiveMember,
  }) => {
    const [scheduleDays, setScheduleDays] = useState(null);
    const [totalHours, setTotalHours] = useState(null);
    const [options, setOptions] = useState([]);
    const schedules = useSelector(selectSchedules);

    useEffect(() => {
      const activeSchedule =
        schedules && schedules.find((schedule) => schedule.id === scheduleId);

      if (activeSchedule) {
        const scheduleStructure = getScheduleStructure(activeSchedule.days);
        setScheduleDays(scheduleStructure);
        setTotalHours(getScheduleTotalHours(scheduleStructure));
      } else {
        setScheduleDays(null);
      }
    }, [schedules, scheduleId]);

    useEffect(() => {
      if (schedules && scheduleActiveMember) {
        const schedulesOptions = [...schedules]
          .filter(
            (schedule) =>
              schedule.siret === scheduleActiveMember.siret || !schedule.siret
          )
          .map((schedule) => ({
            label: `${schedule.title}${!schedule.siret ? ` - défaut` : ''}`,
            value: schedule.id,
          }));

        setOptions(schedulesOptions);
      }
    }, [schedules, scheduleActiveMember]);

    const onSelectChange = useCallback(
      (_, value) => {
        setScheduleId(value);
      },
      [setScheduleId]
    );

    return (
      <Modal
        title={`${TIME_TABLE_MODAL.TITLE} :`}
        visible={isVisible}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <CustomSelect
          name="timeTableSelect"
          size="large"
          className={Styles.select}
          options={options}
          value={scheduleId}
          onChange={onSelectChange}
          placeholder=""
        />
        {scheduleDays &&
          scheduleDays.map((item) => (
            <div key={item.label} className={Styles.item}>
              <Text className={Styles.label}>{item.label}</Text>
              <div className={Styles.timeWrapper}>
                {item.ranges.map((range, index) => (
                  <TimeRange
                    key={uuidv4()}
                    range={range}
                    shouldRenderSeparator={
                      item.ranges.length > 1 && index === 1
                    }
                  />
                ))}
              </div>
              <Text className={Styles.totalItem}>{item.total}</Text>
            </div>
          ))}
        {scheduleDays && (
          <div className={Styles.total}>
            {TIME_TABLE_MODAL.TOTAL} : {totalHours}
          </div>
        )}
      </Modal>
    );
  }
);

TimeTableModal.propTypes = propTypes;
