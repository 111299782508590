/* eslint
  import/no-unused-modules: ['off']
*/

import { getType, getActionCreator } from 'utils';
const prefix = '@@app/employeeRegister';

/**
 * Request whole form infos
 */
export const FORM_INFOS_REQUEST = getType(prefix, 'FORM_INFOS_REQUEST');
export const formInfosRequest = getActionCreator(FORM_INFOS_REQUEST);

export const FORM_INFOS_SUCCESS = getType(prefix, 'FORM_INFOS_SUCCESS');
export const formInfosSuccess = getActionCreator(FORM_INFOS_SUCCESS);

export const FORM_INFOS_ERROR = getType(prefix, 'FORM_INFOS_ERROR');
export const formInfosError = getActionCreator(FORM_INFOS_ERROR);

/**
 * Civil data tab info
 */
export const CIVIL_DATA_UPLOAD_REQUEST = getType(
  prefix,
  'CIVIL_DATA_UPLOAD_REQUEST'
);
export const civilDataUploadRequest = getActionCreator(
  CIVIL_DATA_UPLOAD_REQUEST
);

export const CIVIL_DATA_UPLOAD_SUCCESS = getType(
  prefix,
  'CIVIL_DATA_UPLOAD_SUCCESS'
);
export const civilDataUploadSuccess = getActionCreator(
  CIVIL_DATA_UPLOAD_SUCCESS
);

export const CIVIL_DATA_UPLOAD_ERROR = getType(
  prefix,
  'CIVIL_DATA_UPLOAD_ERROR'
);
export const civilDataUploadError = getActionCreator(CIVIL_DATA_UPLOAD_ERROR);

export const CIVIL_DATA_UPDATE_LOCAL = getType(
  prefix,
  'CIVIL_DATA_UPDATE_LOCAL'
);
export const civilDataUpdateLocal = getActionCreator(CIVIL_DATA_UPDATE_LOCAL);

/**
 * SalarySituation tab info
 */
export const SALARY_SITUATION_UPLOAD_REQUEST = getType(
  prefix,
  'SALARY_SITUATION_UPLOAD_REQUEST'
);
export const salarySituationUploadRequest = getActionCreator(
  SALARY_SITUATION_UPLOAD_REQUEST
);

export const SALARY_SITUATION_UPLOAD_SUCCESS = getType(
  prefix,
  'SALARY_SITUATION_UPLOAD_SUCCESS'
);
export const salarySituationUploadSuccess = getActionCreator(
  SALARY_SITUATION_UPLOAD_SUCCESS
);

export const SALARY_SITUATION_UPLOAD_ERROR = getType(
  prefix,
  'SALARY_SITUATION_UPLOAD_ERROR'
);
export const salarySituationUploadError = getActionCreator(
  SALARY_SITUATION_UPLOAD_ERROR
);

export const SALARY_SITUATION_UPDATE_LOCAL = getType(
  prefix,
  'SALARY_SITUATION_UPDATE_LOCAL'
);
export const salarySituationUpdateLocal = getActionCreator(
  SALARY_SITUATION_UPDATE_LOCAL
);

/**
 * WorkContract tab
 */
export const WORK_CONTRACT_UPLOAD_REQUEST = getType(
  prefix,
  'WORK_CONTRACT_UPLOAD_REQUEST'
);
export const workContractUploadRequest = getActionCreator(
  WORK_CONTRACT_UPLOAD_REQUEST
);

export const WORK_CONTRACT_UPLOAD_SUCCESS = getType(
  prefix,
  'WORK_CONTRACT_UPLOAD_SUCCESS'
);
export const workContractUploadSuccess = getActionCreator(
  WORK_CONTRACT_UPLOAD_SUCCESS
);

export const WORK_CONTRACT_UPLOAD_ERROR = getType(
  prefix,
  'WORK_CONTRACT_UPLOAD_ERROR'
);
export const workContractUploadError = getActionCreator(
  WORK_CONTRACT_UPLOAD_ERROR
);

export const WORK_CONTRACT_UPDATE_LOCAL = getType(
  prefix,
  'WORK_CONTRACT_UPDATE_LOCAL'
);
export const workContractUpdateLocal = getActionCreator(
  WORK_CONTRACT_UPDATE_LOCAL
);

/**
 * SpecialSituation tab
 */
export const SPECIAL_SITUATIONS_UPLOAD_REQUEST = getType(
  prefix,
  'SPECIAL_SITUATIONS_UPLOAD_REQUEST'
);
export const specialSituationsUploadRequest = getActionCreator(
  SPECIAL_SITUATIONS_UPLOAD_REQUEST
);

export const SPECIAL_SITUATIONS_UPLOAD_SUCCESS = getType(
  prefix,
  'SPECIAL_SITUATIONS_UPLOAD_SUCCESS'
);
export const specialSituationsUploadSuccess = getActionCreator(
  SPECIAL_SITUATIONS_UPLOAD_SUCCESS
);

export const SPECIAL_SITUATIONS_UPLOAD_ERROR = getType(
  prefix,
  'SPECIAL_SITUATIONS_UPLOAD_ERROR'
);
export const specialSituationsUploadError = getActionCreator(
  SPECIAL_SITUATIONS_UPLOAD_ERROR
);

export const SPECIAL_SITUATIONS_UPDATE_LOCAL = getType(
  prefix,
  'SPECIAL_SITUATIONS_UPDATE_LOCAL'
);
export const specialSituationsUpdateLocal = getActionCreator(
  SPECIAL_SITUATIONS_UPDATE_LOCAL
);

/**
 * Documents tab
 */
export const DOCUMENTS_UPLOAD_REQUEST = getType(
  prefix,
  'DOCUMENTS_UPLOAD_REQUEST'
);
export const documentsUploadRequest = getActionCreator(
  DOCUMENTS_UPLOAD_REQUEST
);

export const DOCUMENTS_UPLOAD_PROGRESS = getType(
  prefix,
  'DOCUMENTS_UPLOAD_PROGRESS'
);
export const documentsUploadProgress = getActionCreator(
  DOCUMENTS_UPLOAD_PROGRESS
);

export const DOCUMENTS_UPLOAD_SUCCESS = getType(
  prefix,
  'DOCUMENTS_UPLOAD_SUCCESS'
);
export const documentsUploadSuccess = getActionCreator(
  DOCUMENTS_UPLOAD_SUCCESS
);

export const DOCUMENTS_UPLOAD_ERROR = getType(prefix, 'DOCUMENTS_UPLOAD_ERROR');
export const documentsUploadError = getActionCreator(DOCUMENTS_UPLOAD_ERROR);

export const DOCUMENTS_UPDATE_LOCAL = getType(prefix, 'DOCUMENTS_UPDATE_LOCAL');
export const documentsUpdateLocal = getActionCreator(DOCUMENTS_UPDATE_LOCAL);

/**
 * Trigger form save on backend
 */
export const SUBMIT_FORM_REQUEST = getType(prefix, 'SUBMIT_FORM_REQUEST');
export const submitFormRequest = getActionCreator(SUBMIT_FORM_REQUEST);

export const SUBMIT_FORM_SUCCESS = getType(prefix, 'SUBMIT_FORM_SUCCESS');
export const submitFormSuccess = getActionCreator(SUBMIT_FORM_SUCCESS);

export const SUBMIT_FORM_ERROR = getType(prefix, 'SUBMIT_FORM_ERROR');
export const submitFormError = getActionCreator(SUBMIT_FORM_ERROR);

/**
 * Reset form
 */
export const RESET_FORM_REQUEST = getType(prefix, 'RESET_FORM_REQUEST');
export const resetFormRequest = getActionCreator(RESET_FORM_REQUEST);

export const RESET_FORM_SUCCESS = getType(prefix, 'RESET_FORM_SUCCESS');
export const resetFormSuccess = getActionCreator(RESET_FORM_SUCCESS);

export const RESET_FORM_ERROR = getType(prefix, 'RESET_FORM_ERROR');
export const resetFormError = getActionCreator(RESET_FORM_ERROR);
