import { request } from 'utils/request';
import { TOKEN_ENDPOINTS } from 'config';

export const loginUser = ({ email, password }) =>
  request.post(TOKEN_ENDPOINTS.LOGIN, {
    email,
    password,
  });

export const changeUserPassword = (payload) =>
  request.post(TOKEN_ENDPOINTS.CHANGE_PASSWORD, payload);

export const changeTemporaryUserPassword = ({
  email,
  passwordRegister,
  passwordAuthentication,
}) =>
  request.post(TOKEN_ENDPOINTS.TEMPORARY_CHANGE_PASSWORD, {
    email,
    passwordRegister,
    passwordAuthentication,
  });

export const recoverUserPassword = ({ email }) =>
  request.post('auth/recover-password', {
    email,
  });
