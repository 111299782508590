import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_TRANSLATIONS } from 'const/translations';
import { FOOTER_TRANSLATIONS } from 'const/translations/Footer';

import { ROUTES } from 'const';
import { Button } from 'components/ui';
import { Logo } from 'components/ui/Logo';
import Styles from './FormCard.module.css';

const { LINKS } = LOGIN_PAGE_TRANSLATIONS;
const {
  LINKS: { QUESTIONS },
} = FOOTER_TRANSLATIONS;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withLogo: PropTypes.bool,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  links: PropTypes.arrayOf(PropTypes.string),
};

export const FormCard = ({
  children,
  withLogo,
  submitText,
  onSubmit,
  links,
}) => (
  <Form role="form" className={Styles.formCard} onSubmit={onSubmit}>
    {withLogo && <Logo />}
    {children}
    {links &&
      links.length > 1 &&
      links.map((link) => (
        <Link className={Styles.linkWrapper} key={link} to={ROUTES[link]}>
          {LINKS[link]}
        </Link>
      ))}
    <div className={Styles.bottomWrapper}>
      {submitText && (
        <Button variant="success" role="button" htmlType="submit" big>
          {submitText}
        </Button>
      )}
      {links && links.length === 1 && (
        <Link
          className={Styles.linkWrapper}
          key={links[0]}
          to={ROUTES[links[0]]}
        >
          {LINKS[links[0]]}
        </Link>
      )}
    </div>
    <div className={cn(Styles.bottomWrapper, Styles.questions)}>
      <a
        className={Styles.linkWrapper}
        href="https://aide.myexco.net/faq/fr-fr"
        target="_blank"
        rel="noopener noreferrer"
      >
        {QUESTIONS}
      </a>
    </div>
  </Form>
);

FormCard.propTypes = propTypes;
