import React from 'react';
import PropTypes from 'prop-types';
import { COMMON_TEXT_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { getStringUpdateDate } from 'helpers/shortPreviewCards';

const propTypes = {
  updateDate: PropTypes.string,
  addYear: PropTypes.bool,
  showCondition: PropTypes.bool,
  className: PropTypes.string,
};

export const LastUpdate = ({
  updateDate,
  addYear = true,
  showCondition = false,
  className = '',
}) =>
  showCondition ? (
    <div className={clsx(MainStyles.container, className)}>
      {COMMON_TEXT_TRANSLATIONS.LAST_UPDATE.replace(
        '{{DATE}}',
        getStringUpdateDate(updateDate, addYear)
      )}
    </div>
  ) : null;

LastUpdate.propTypes = propTypes;
