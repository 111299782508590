import { mapObjectsToItemsList } from 'helpers/common';

const NATIONALITIES_LIST = [
  { CODE: '093', NATIONALITY: 'AFGHANE' },
  { CODE: '355', NATIONALITY: 'ALBANAISE' },
  { CODE: '213', NATIONALITY: 'ALGERIENNE' },
  { CODE: '049', NATIONALITY: 'ALLEMANDE' },
  { CODE: '001', NATIONALITY: 'AMERICAINE' },
  { CODE: '376', NATIONALITY: 'ANDORRANE' },
  { CODE: '244', NATIONALITY: 'ANGOLAISE' },
  { CODE: '811', NATIONALITY: 'ANTIGUAISE ET BARBUDIENNE' },
  { CODE: '054', NATIONALITY: 'ARGENTINE' },
  { CODE: '374', NATIONALITY: 'ARMENIENNE' },
  { CODE: '061', NATIONALITY: 'AUSTRALIENNE' },
  { CODE: '043', NATIONALITY: 'AUTRICHIENNE' },
  { CODE: '994', NATIONALITY: 'AZERBAÏDJANAISE' },
  { CODE: '812', NATIONALITY: 'BAHAMIENNE' },
  { CODE: '973', NATIONALITY: 'BAHREÏNIENNE' },
  { CODE: '880', NATIONALITY: 'BANGLADAISE' },
  { CODE: '246', NATIONALITY: 'BARBADIENNE' },
  { CODE: '032', NATIONALITY: 'BELGE' },
  { CODE: '501', NATIONALITY: 'BELIZIENNE' },
  { CODE: '229', NATIONALITY: 'BENINOISE' },
  { CODE: '975', NATIONALITY: 'BHOUTANAISE' },
  { CODE: '375', NATIONALITY: 'BIELORUSSE' },
  { CODE: '095', NATIONALITY: 'BIRMANE' },
  { CODE: '245', NATIONALITY: 'BISSAU-GUINEENNE' },
  { CODE: '591', NATIONALITY: 'BOLIVIENNE' },
  { CODE: '387', NATIONALITY: 'BOSNIENNE' },
  { CODE: '267', NATIONALITY: 'BOTSWANAISE' },
  { CODE: '055', NATIONALITY: 'BRESILIENNE' },
  { CODE: '044', NATIONALITY: 'BRITANNIQUE' },
  { CODE: '673', NATIONALITY: 'BRUNEIENNE' },
  { CODE: '359', NATIONALITY: 'BULGARE' },
  { CODE: '226', NATIONALITY: 'BURKINABE' },
  { CODE: '257', NATIONALITY: 'BURUNDAISE' },
  { CODE: '855', NATIONALITY: 'CAMBODGIENNE' },
  { CODE: '237', NATIONALITY: 'CAMEROUNAISE' },
  { CODE: '010', NATIONALITY: 'CANADIENNE' },
  { CODE: '238', NATIONALITY: 'CAP-VERDIENNE' },
  { CODE: '236', NATIONALITY: 'CENTRAFRICAINE' },
  { CODE: '056', NATIONALITY: 'CHILIENNE' },
  { CODE: '086', NATIONALITY: 'CHINOISE' },
  { CODE: '886', NATIONALITY: 'CHINOISE (TAIWAN)' },
  { CODE: '357', NATIONALITY: 'CHYPRIOTE' },
  { CODE: '057', NATIONALITY: 'COLOMBIENNE' },
  { CODE: '269', NATIONALITY: 'COMORIENNE' },
  { CODE: '242', NATIONALITY: 'CONGOLAISE' },
  { CODE: '243', NATIONALITY: 'CONGOLAISE (R.D.)' },
  { CODE: '506', NATIONALITY: 'COSTARICAINE' },
  { CODE: '385', NATIONALITY: 'CROATE' },
  { CODE: '053', NATIONALITY: 'CUBAINE' },
  { CODE: '045', NATIONALITY: 'DANOISE' },
  { CODE: '253', NATIONALITY: 'DJIBOUTIENNE' },
  { CODE: '809', NATIONALITY: 'DOMINICAINE' },
  { CODE: '813', NATIONALITY: 'DOMINIQUAISE' },
  { CODE: '020', NATIONALITY: 'EGYPTIENNE' },
  { CODE: '971', NATIONALITY: 'EMIRIENNE' },
  { CODE: '240', NATIONALITY: 'EQUATO-GUINEENNE' },
  { CODE: '593', NATIONALITY: 'EQUATORIENNE' },
  { CODE: '291', NATIONALITY: 'ERYTHREENNE' },
  { CODE: '034', NATIONALITY: 'ESPAGNOLE' },
  { CODE: '372', NATIONALITY: 'ESTONIENNE' },
  { CODE: '251', NATIONALITY: 'ETHIOPIENNE' },
  { CODE: '679', NATIONALITY: 'FIDJIENNE' },
  { CODE: '358', NATIONALITY: 'FINLANDAISE' },
  { CODE: '033', NATIONALITY: 'FRANCAISE' },
  { CODE: '241', NATIONALITY: 'GABONAISE' },
  { CODE: '220', NATIONALITY: 'GAMBIENNE' },
  { CODE: '995', NATIONALITY: 'GEORGIENNE' },
  { CODE: '233', NATIONALITY: 'GHANEENNE' },
  { CODE: '814', NATIONALITY: 'GRENADIENNE' },
  { CODE: '502', NATIONALITY: 'GUATEMALTEQUE' },
  { CODE: '224', NATIONALITY: 'GUINEENNE' },
  { CODE: '592', NATIONALITY: 'GUYANIENNE' },
  { CODE: '509', NATIONALITY: 'HAÏTIENNE' },
  { CODE: '030', NATIONALITY: 'HELLENIQUE' },
  { CODE: '504', NATIONALITY: 'HONDURIENNE' },
  { CODE: '036', NATIONALITY: 'HONGROISE' },
  { CODE: 'xxx', NATIONALITY: 'inconnue' },
  { CODE: '091', NATIONALITY: 'INDIENNE' },
  { CODE: '062', NATIONALITY: 'INDONESIENNE' },
  { CODE: '964', NATIONALITY: 'IRAKIENNE' },
  { CODE: '098', NATIONALITY: 'IRANIENNE' },
  { CODE: '353', NATIONALITY: 'IRLANDAISE' },
  { CODE: '354', NATIONALITY: 'ISLANDAISE' },
  { CODE: '097', NATIONALITY: 'ISRAËLIENNE' },
  { CODE: '039', NATIONALITY: 'ITALIENNE' },
  { CODE: '225', NATIONALITY: 'IVOIRIENNE' },
  { CODE: '817', NATIONALITY: 'JAMAÏCAINE' },
  { CODE: '081', NATIONALITY: 'JAPONAISE' },
  { CODE: '962', NATIONALITY: 'JORDANIENNE' },
  { CODE: '073', NATIONALITY: 'KAZAKHSTANAISE' },
  { CODE: '254', NATIONALITY: 'KENYANE' },
  { CODE: '996', NATIONALITY: 'KIRGHIZE' },
  { CODE: '686', NATIONALITY: 'KIRIBATIENNE' },
  { CODE: '869', NATIONALITY: 'KITTITIENNE-ET-NEVICIENNE' },
  { CODE: '999', NATIONALITY: 'KOSSOVIENNE' },
  { CODE: '965', NATIONALITY: 'KOWEÏTIENNE' },
  { CODE: '856', NATIONALITY: 'LAOTIENNE' },
  { CODE: '266', NATIONALITY: 'LESOTHANE' },
  { CODE: '371', NATIONALITY: 'LETTONE' },
  { CODE: '961', NATIONALITY: 'LIBANAISE' },
  { CODE: '231', NATIONALITY: 'LIBERIENNE' },
  { CODE: '218', NATIONALITY: 'LIBYENNE' },
  { CODE: '410', NATIONALITY: 'LIECHTENSTEINOISE' },
  { CODE: '370', NATIONALITY: 'LITUANIENNE' },
  { CODE: '352', NATIONALITY: 'LUXEMBOURGEOISE' },
  { CODE: '389', NATIONALITY: 'MACEDONIENNE' },
  { CODE: '060', NATIONALITY: 'MALAISIENNE' },
  { CODE: '265', NATIONALITY: 'MALAWIENNE' },
  { CODE: '960', NATIONALITY: 'MALDIVIENNE' },
  { CODE: '261', NATIONALITY: 'MALGACHE' },
  { CODE: '223', NATIONALITY: 'MALIENNE' },
  { CODE: '356', NATIONALITY: 'MALTAISE' },
  { CODE: '212', NATIONALITY: 'MAROCAINE' },
  { CODE: '692', NATIONALITY: 'MARSHALLAISE' },
  { CODE: '230', NATIONALITY: 'MAURICIENNE' },
  { CODE: '222', NATIONALITY: 'MAURITANIENNE' },
  { CODE: '052', NATIONALITY: 'MEXICAINE' },
  { CODE: '691', NATIONALITY: 'MICRONESIENNE' },
  { CODE: '037', NATIONALITY: 'MOLDAVE' },
  { CODE: '377', NATIONALITY: 'MONEGASQUE' },
  { CODE: '976', NATIONALITY: 'MONGOLE' },
  { CODE: '382', NATIONALITY: 'MONTENEGRINE' },
  { CODE: '258', NATIONALITY: 'MOZAMBICAINE' },
  { CODE: '264', NATIONALITY: 'NAMIBIENNE' },
  { CODE: '674', NATIONALITY: 'NAURUANE' },
  { CODE: '031', NATIONALITY: 'NEERLANDAISE' },
  { CODE: '064', NATIONALITY: 'NEO-ZELANDAISE' },
  { CODE: '977', NATIONALITY: 'NEPALAISE' },
  { CODE: '505', NATIONALITY: 'NICARAGUAYENNE' },
  { CODE: '234', NATIONALITY: 'NIGERIANE' },
  { CODE: '227', NATIONALITY: 'NIGERIENNE' },
  { CODE: '850', NATIONALITY: 'NORD-CORÉENNE' },
  { CODE: '047', NATIONALITY: 'NORVEGIENNE' },
  { CODE: '968', NATIONALITY: 'OMANAISE' },
  { CODE: '256', NATIONALITY: 'OUGANDAISE' },
  { CODE: '071', NATIONALITY: 'OUZBEKE' },
  { CODE: '092', NATIONALITY: 'PAKISTANAISE' },
  { CODE: '680', NATIONALITY: 'PALAOISE' },
  { CODE: '970', NATIONALITY: 'PALESTINIENNE' },
  { CODE: '507', NATIONALITY: 'PANAMEENNE' },
  { CODE: '675', NATIONALITY: 'PAPOUANE-NEOGUINEENNE' },
  { CODE: '595', NATIONALITY: 'PARAGUAYENNE' },
  { CODE: '051', NATIONALITY: 'PERUVIENNE' },
  { CODE: '063', NATIONALITY: 'PHILIPPINE' },
  { CODE: '048', NATIONALITY: 'POLONAISE' },
  { CODE: '787', NATIONALITY: 'PORTORICAINE' },
  { CODE: '351', NATIONALITY: 'PORTUGAISE' },
  { CODE: '974', NATIONALITY: 'QATARIENNE' },
  { CODE: '040', NATIONALITY: 'ROUMAINE' },
  { CODE: '007', NATIONALITY: 'RUSSE' },
  { CODE: '250', NATIONALITY: 'RWANDAISE' },
  { CODE: '758', NATIONALITY: 'SAINT-LUCIENNE' },
  { CODE: '378', NATIONALITY: 'SAINT-MARINAISE' },
  { CODE: '818', NATIONALITY: 'SAINT-VINCENTAISE-ET-GRENADINE' },
  { CODE: '677', NATIONALITY: 'SALOMONAISE' },
  { CODE: '503', NATIONALITY: 'SALVADORIENNE' },
  { CODE: '685', NATIONALITY: 'SAMOANE' },
  { CODE: '000', NATIONALITY: 'sans nationalité' },
  { CODE: '239', NATIONALITY: 'SANTOMEENNE' },
  { CODE: '966', NATIONALITY: 'SAOUDIENNE' },
  { CODE: '221', NATIONALITY: 'SENEGALAISE' },
  { CODE: '038', NATIONALITY: 'SERBE' },
  { CODE: '381', NATIONALITY: 'SERBIE ET MONTENEGRO' },
  { CODE: '248', NATIONALITY: 'SEYCHELLOISE' },
  { CODE: '232', NATIONALITY: 'SIERRA-LEONAISE' },
  { CODE: '065', NATIONALITY: 'SINGAPOURIENNE' },
  { CODE: '042', NATIONALITY: 'SLOVAQUE' },
  { CODE: '386', NATIONALITY: 'SLOVENE' },
  { CODE: '252', NATIONALITY: 'SOMALIENNE' },
  { CODE: '249', NATIONALITY: 'SOUDANAISE' },
  { CODE: '094', NATIONALITY: 'SRI-LANKAISE' },
  { CODE: '027', NATIONALITY: 'SUD-AFRICAINE' },
  { CODE: '082', NATIONALITY: 'SUD-CORÉENNE' },
  { CODE: '211', NATIONALITY: 'SUD-SOUDANAISE' },
  { CODE: '046', NATIONALITY: 'SUEDOISE' },
  { CODE: '041', NATIONALITY: 'SUISSE' },
  { CODE: '597', NATIONALITY: 'SURINAMIENNE' },
  { CODE: '268', NATIONALITY: 'SWAZIE' },
  { CODE: '963', NATIONALITY: 'SYRIENNE' },
  { CODE: '072', NATIONALITY: 'TADJIKE' },
  { CODE: '255', NATIONALITY: 'TANZANIENNE' },
  { CODE: '235', NATIONALITY: 'TCHADIENNE' },
  { CODE: '420', NATIONALITY: 'TCHEQUE' },
  { CODE: '066', NATIONALITY: 'THAÏLANDAISE' },
  { CODE: '670', NATIONALITY: 'TIMORAISE' },
  { CODE: '228', NATIONALITY: 'TOGOLAISE' },
  { CODE: '676', NATIONALITY: 'TONGIENNE' },
  { CODE: '868', NATIONALITY: 'TRINIDADIENNE' },
  { CODE: '216', NATIONALITY: 'TUNISIENNE' },
  { CODE: '993', NATIONALITY: 'TURKMENE' },
  { CODE: '090', NATIONALITY: 'TURQUE' },
  { CODE: '688', NATIONALITY: 'TUVALUANE' },
  { CODE: '380', NATIONALITY: 'UKRAINIENNE' },
  { CODE: '598', NATIONALITY: 'URUGUAYENNE' },
  { CODE: '678', NATIONALITY: 'VANUATUANE' },
  { CODE: '058', NATIONALITY: 'VENEZUELIENNE' },
  { CODE: '084', NATIONALITY: 'VIETNAMIENNE' },
  { CODE: '967', NATIONALITY: 'YEMENITE' },
  { CODE: '260', NATIONALITY: 'ZAMBIENNE' },
  { CODE: '263', NATIONALITY: 'ZIMBABWEENNE' },
];

export const NATIONALITIES_LIST_OPTIONS = mapObjectsToItemsList(
  NATIONALITIES_LIST,
  'NATIONALITY',
  'NATIONALITY',
  'CODE'
);
