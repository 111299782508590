import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';
import { MY_INFORMATION_PAGE_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';

import MainStyles from '../../assets/styles/main.module.css';
import Styles from './InformationRow.module.css';

const propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
};

const { Paragraph } = Typography;

export const InformationRow = ({ label, href }) => (
  <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
    <Paragraph className={clsx(Styles.rowText, Styles.accented)}>
      {label}
    </Paragraph>
    <div className={Styles.list}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {MY_INFORMATION_PAGE_TRANSLATIONS.SEE_ARTICLE}
      </a>
    </div>
  </Col>
);

InformationRow.propTypes = propTypes;
