import { useState, useEffect, useCallback } from 'react';
import { SCHEDULE_TIME_INTERVALS } from 'const/SalaryCompanySettings';
import {
  getAvailableMaxOptions,
  getAvailableMinOptions,
  getAvailableOptions,
  getCorrectGapState,
  getMinMaxValues,
  getScheduleRowTotal,
  getTimeStringFromScheduleArray,
} from '../utils';

const { gap1, gap2, gap3, gap4 } = SCHEDULE_TIME_INTERVALS;

/**
 * Methods and data to control schedule day intervals
 * @param { Array } rowData - schedule day intervals
 * @param { Boolean } withMain - is there the row for set same data for all days
 * @param { Object } sameRowData - same time intervals for all days
 *
 * /**
 * @typedef {Object} HookReturnObject
 * @property {Boolean} withLunchBreak - is two intervals in day or no
 * @property {Function} setWithLunchBreak - set intervals count by change day checkbox
 * @property {Object} gapState - current state for each interval select
 * @property {Function} setGapData - func for change current interval state
 * @property {Boolean} isEditable - if true you can change time in selects
 * @property {Function} setEditable - set isEditable by change day on/off checkbox
 * @property {Object} rowTotalTime - summary work time from current interval state
 * @property {Array} availableOptions - formatted options for each interval select depending on the current state
 *
 * @return {HookReturnObject} - data and methods to use useScheduleRowHook
 */
export const useScheduleRowHook = (
  rowData = [],
  sameRowData = null,
  withMain = false
) => {
  const [withLunchBreak, setWithLunchBreak] = useState(rowData.length > 1);
  const [gapState, setGapState] = useState({
    gap1: '',
    gap2: '',
    gap3: '',
    gap4: '',
  });
  const [isEditable, setEditable] = useState(withMain);
  const [rowTotalTime, setRowTotalTime] = useState({ hours: 0, minutes: 0 });
  const [availableOptions, setAvailableOptions] = useState({
    available: [],
    availableMin: [],
    availableMax: [],
  });

  useEffect(() => {
    const isLunch = (rowData && rowData.length > 1) || withMain;
    if (rowData && rowData.length > 0 && !withMain && !sameRowData) {
      setEditable(true);
      setWithLunchBreak(isLunch);
    }
    if (!sameRowData) {
      setTimeout(() => {
        setGapState({
          gap1:
            (rowData[0] && getTimeStringFromScheduleArray(rowData[0].start)) ||
            gap1.defaultValue,
          gap2:
            (rowData[0] && getTimeStringFromScheduleArray(rowData[0].end)) ||
            (isLunch ? gap2.defaultValue : gap4.defaultValue),
          gap3:
            (rowData[1] && getTimeStringFromScheduleArray(rowData[1].start)) ||
            gap3.defaultValue,
          gap4:
            (rowData[1] && getTimeStringFromScheduleArray(rowData[1].end)) ||
            gap4.defaultValue,
        });
      }, 100);
    }
  }, [sameRowData, withMain, rowData]);

  /**
   * adddition of missing dependencies breaks the flow
   */
  // useEffect(() => {
  //   if (!sameRowData || withMain) {
  //     setGapState({
  //       gap1:
  //         (rowData[0] && getTimeStringFromScheduleArray(rowData[0].start)) ||
  //         gap1.defaultValue,
  //       gap2:
  //         (withLunchBreak && gap2.defaultValue) ||
  //         (rowData[1] && getTimeStringFromScheduleArray(rowData[1].end)) ||
  //         (rowData[0] && getTimeStringFromScheduleArray(rowData[0].end)) ||
  //         gap4.defaultValue,
  //       gap3: gap3.defaultValue,
  //       gap4:
  //         (withLunchBreak &&
  //           ((rowData[1] && getTimeStringFromScheduleArray(rowData[1].end)) ||
  //             (rowData[0] &&
  //               getTimeStringFromScheduleArray(rowData[0].end)))) ||
  //         gap4.defaultValue,
  //     });
  //   }
  // }, [rowData, sameRowData, withLunchBreak, withMain]);

  useEffect(() => {
    setWithLunchBreak(rowData.length > 1);
  }, [rowData.length, withMain]);

  useEffect(() => {
    setRowTotalTime(getScheduleRowTotal(gapState, withLunchBreak));
    const minMax = getMinMaxValues(gapState, withLunchBreak);
    setAvailableOptions({
      available: getAvailableOptions(minMax),
      availableMin: getAvailableMinOptions(minMax),
      availableMax: getAvailableMaxOptions(minMax),
    });
  }, [gapState, withLunchBreak]);

  const setGapData = useCallback(
    (name, value) => {
      setGapState((prev) =>
        getCorrectGapState(prev, name, value, withLunchBreak)
      );
    },
    [withLunchBreak]
  );

  useEffect(() => {
    if (sameRowData && !withMain) {
      setWithLunchBreak(sameRowData.withLunchBreak);
      setGapState(sameRowData.gapState);
    }
  }, [withMain, sameRowData]);

  return {
    withLunchBreak,
    setWithLunchBreak,
    gapState,
    setGapData,
    isEditable,
    setEditable,
    rowTotalTime,
    availableOptions,
  };
};
