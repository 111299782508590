import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Styles from './CurrentFiscalYear.module.css';

const propTypes = {
  currentYear: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const CurrentFiscalYear = ({ currentYear, className }) => (
  <div className={clsx(Styles.wrapper, className)}>
    <span className={Styles.text}>Exercice :</span>
    <span className={Styles.currentYear}>{currentYear}</span>
  </div>
);

CurrentFiscalYear.propTypes = propTypes;
