import { createSelector } from 'reselect';
import {
  calculateHRCAData,
  calculateHRPercentCAData,
  formatHRDashboardData,
  getCumulDashboardData,
} from 'helpers/HRhelpers';
import { selectUserCurrentCompanyExercice } from 'modules/loggedUserInfo/selectors';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import {
  groupDatasetsByMonthsAndDays,
  mapDashboardDataToYearView,
} from 'helpers/chartDataMappers';
import { HR_TABS_IDS } from 'const/HRPage';

export const selectHRStatus = (state) => state.getIn(['HRPage', 'status']);

// total hours
const selectHRTotalHours = createSelector(
  (state) => state.getIn(['HRPage', 'totalHoursData']),
  (totalHoursData) => (totalHoursData ? totalHoursData.toJS() : [])
);

const selectHRTotalHoursForYearChart = createSelector(
  selectHRTotalHours,
  selectUserCurrentCompanyExercice,
  selectCurrentCompanyFirstFiscalMonth,
  (totalHoursData, exercice, firstMonthName) =>
    formatHRDashboardData({
      exercice,
      firstMonthName,
      dashboardData: totalHoursData,
      datasetValueKey: 'totalheures',
    })
);

// total cost
const selectHRTotalCost = createSelector(
  (state) => state.getIn(['HRPage', 'totalCostData']),
  (totalCostData) => (totalCostData ? totalCostData.toJS() : [])
);

const selectHRTotalCostForYearChart = createSelector(
  selectHRTotalCost,
  selectUserCurrentCompanyExercice,
  selectCurrentCompanyFirstFiscalMonth,
  (totalCostData, exercice, firstMonthName) =>
    formatHRDashboardData({
      exercice,
      firstMonthName,
      dashboardData: totalCostData,
      datasetValueKey: 'totalcost',
    })
);

// turnover
const selectHRTurnoverData = createSelector(
  (state) => state.getIn(['HRPage', 'turnover']),
  (turnoverData) => (turnoverData ? turnoverData.toJS() : {})
);

export const selectHRLastUpdateDate = (state) =>
  state.getIn(['HRPage', 'lastUpdated']) || new Date(Date.now()).toISOString();

const selectHRTurnoverDataForChart = createSelector(
  selectHRTurnoverData,
  (turnoverData) =>
    groupDatasetsByMonthsAndDays(turnoverData.datasets, 'turnover')
);

const selectHRTurnoverDataForYear = createSelector(
  selectCurrentCompanyFirstFiscalMonth,
  selectHRTurnoverDataForChart,
  (firstMonthName, turnoverMonthsData) =>
    mapDashboardDataToYearView(turnoverMonthsData, firstMonthName)
);

const selectHRCADataForYearChart = createSelector(
  selectHRTurnoverDataForYear,
  selectHRTotalHoursForYearChart,
  (turnoverYearData, totalHoursYearData) =>
    calculateHRCAData({ turnoverYearData, totalHoursYearData })
);

const selectHRPercentCADataForYearChart = createSelector(
  selectHRTurnoverDataForYear,
  selectHRTotalCostForYearChart,
  (turnoverYearData, totalCostYearData) =>
    calculateHRPercentCAData({ turnoverYearData, totalCostYearData })
);

// hr dashboard data
export const selectHRDashboardYearData = createSelector(
  selectHRTotalCostForYearChart,
  selectHRTotalHoursForYearChart,
  selectHRCADataForYearChart,
  selectHRPercentCADataForYearChart,
  (totalCostData, totalHoursData, CAData, PercentCAData) => [
    { tabId: HR_TABS_IDS.TOTAL_COST, tabValues: totalCostData },
    { tabId: HR_TABS_IDS.TOTAL_HOURS, tabValues: totalHoursData },
    {
      tabId: HR_TABS_IDS.CA,
      tabValues: CAData,
    },
    {
      tabId: HR_TABS_IDS.CA_PERCENTAGE,
      tabValues: PercentCAData,
    },
  ]
);

const selectCumulHRTurnoverData = createSelector(
  selectHRTurnoverDataForYear,
  (turnoverData) => getCumulDashboardData(turnoverData)
);

// cumul total cost
const selectCumulHRTotalCostData = createSelector(
  selectHRTotalCostForYearChart,
  (totalCostData) => getCumulDashboardData(totalCostData)
);

// cumul total hours
const selectCumulHRTotalHoursData = createSelector(
  selectHRTotalHoursForYearChart,
  (totalHoursData) => getCumulDashboardData(totalHoursData)
);

// cumul productive
const selectCumulHRCADataForYearChart = createSelector(
  selectCumulHRTurnoverData,
  selectCumulHRTotalHoursData,
  (cumulTurnoverYearData, cumulTotalHoursYearData) =>
    calculateHRCAData({
      turnoverYearData: cumulTurnoverYearData,
      totalHoursYearData: cumulTotalHoursYearData,
      uniqueDatasetIndex: 'cumul',
    })
);

// cumul productivity
const selectCumulHRPercentCADataForYearChart = createSelector(
  selectCumulHRTurnoverData,
  selectCumulHRTotalCostData,
  (cumulTurnoverYearData, cumulTotalCostYearData) =>
    calculateHRPercentCAData({
      turnoverYearData: cumulTurnoverYearData,
      totalCostYearData: cumulTotalCostYearData,
      uniqueDatasetIndex: 'cumul',
    })
);

/**
 * Latest value of incoming array is also the correct total for CA category
 * receives [{id, year: 2022, values: [null, 1, 2, 3, null]}, {id, year: 2021, values: []}, ...]
 * Returned values should be for the same period(month) (most recent period with data of most recent year)
 * returns { 2022: 3, 2021: ... }
 */
export const selectCAYearTotal = createSelector(
  selectCumulHRCADataForYearChart,
  (yearsValues) => {
    const mostRecentYearData = yearsValues.reduce((acc, data) => {
      if (!acc.year || Number(acc.year) < Number(data.year)) {
        return data;
      }
      return acc;
    }, {});
    if (!mostRecentYearData.values) {
      return {};
    }
    const relevantPeriodIndex = mostRecentYearData.values.reduce(
      (acc, value, index) => (value ? index : acc),
      0
    );
    return yearsValues.reduce((acc, { year, values }) => {
      acc[year] = values[relevantPeriodIndex];
      return acc;
    }, {});
  }
);

// cumul dashboard data
export const selectCumulHRDashboardYearData = createSelector(
  selectCumulHRTotalCostData,
  selectCumulHRTotalHoursData,
  selectCumulHRCADataForYearChart,
  selectCumulHRPercentCADataForYearChart,
  (
    cumulTotalCostData,
    cumulTotalHoursData,
    cumulCAData,
    cumulPercentCAData
  ) => [
    { tabId: HR_TABS_IDS.TOTAL_COST, tabValues: cumulTotalCostData },
    { tabId: HR_TABS_IDS.TOTAL_HOURS, tabValues: cumulTotalHoursData },
    {
      tabId: HR_TABS_IDS.CA,
      tabValues: cumulCAData,
    },
    {
      tabId: HR_TABS_IDS.CA_PERCENTAGE,
      tabValues: cumulPercentCAData,
    },
  ]
);
