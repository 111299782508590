import { getType, getActionCreator } from 'utils';
const prefix = '@@app/uploadDocuments';

export const UPLOAD_FILES_REQUEST = getType(prefix, 'UPLOAD_FILES_REQUEST');
export const uploadFilesRequest = getActionCreator(UPLOAD_FILES_REQUEST);

export const UPLOAD_FILES_SUCCESS = getType(prefix, 'UPLOAD_FILES_SUCCESS');
export const uploadFilesSuccess = getActionCreator(UPLOAD_FILES_SUCCESS);

export const UPLOAD_FILES_ERROR = getType(prefix, 'UPLOAD_FILES_ERROR');
export const uploadFilesError = getActionCreator(UPLOAD_FILES_ERROR);

export const UPLOAD_FILES_RESET_STATUS = getType(
  prefix,
  'UPLOAD_FILES_RESET_STATUS'
);
export const uploadFilesResetStatus = getActionCreator(
  UPLOAD_FILES_RESET_STATUS
);

/**
 * History upload request, happens automatically on module page loading
 */
export const GET_UPLOAD_HISTORY_REQUEST = getType(
  prefix,
  'GET_UPLOAD_HISTORY_REQUEST'
);
export const getUploadHistoryRequest = getActionCreator(
  GET_UPLOAD_HISTORY_REQUEST
);

export const GET_UPLOAD_HISTORY_SUCCESS = getType(
  prefix,
  'GET_UPLOAD_HISTORY_SUCCESS'
);
export const getUploadHistorySuccess = getActionCreator(
  GET_UPLOAD_HISTORY_SUCCESS
);

export const GET_UPLOAD_HISTORY_ERROR = getType(
  prefix,
  'GET_UPLOAD_HISTORY_ERROR'
);
export const getUploadHistoryError = getActionCreator(GET_UPLOAD_HISTORY_ERROR);

/**
 * Request new portion of upload history, if available.
 * Happens when user clicks 'Upload more' button
 */
export const MORE_UPLOAD_HISTORY_REQUEST = getType(
  prefix,
  'MORE_UPLOAD_HISTORY_REQUEST'
);
export const moreUploadHistoryRequest = getActionCreator(
  MORE_UPLOAD_HISTORY_REQUEST
);

export const MORE_UPLOAD_HISTORY_SUCCESS = getType(
  prefix,
  'MORE_UPLOAD_HISTORY_SUCCESS'
);
export const moreUploadHistorySuccess = getActionCreator(
  MORE_UPLOAD_HISTORY_SUCCESS
);

export const MORE_UPLOAD_HISTORY_ERROR = getType(
  prefix,
  'MORE_UPLOAD_HISTORY_ERROR'
);
export const moreUploadHistoryError = getActionCreator(
  MORE_UPLOAD_HISTORY_ERROR
);

export const INIT_UPLOAD_FILES_PAGE = getType(prefix, 'INIT_UPLOAD_FILES_PAGE');
export const initUploadFilesPage = getActionCreator(INIT_UPLOAD_FILES_PAGE);

export const DOWNLOAD_DOCUMENT_REQUEST = getType(
  prefix,
  'DOWNLOAD_DOCUMENT_REQUEST'
);
export const downloadDocumentRequest = getActionCreator(
  DOWNLOAD_DOCUMENT_REQUEST
);

export const DOWNLOAD_DOCUMENT_SUCCESS = getType(
  prefix,
  'DOWNLOAD_DOCUMENT_SUCCESS'
);
export const downloadDocumentSuccess = getActionCreator(
  DOWNLOAD_DOCUMENT_SUCCESS
);

export const DOWNLOAD_DOCUMENT_ERROR = getType(prefix, 'DOWNLOAD_FIES_ERROR');
export const downloadDocumentError = getActionCreator(DOWNLOAD_DOCUMENT_ERROR);
