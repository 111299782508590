import React from 'react';
import PropTypes from 'prop-types';
import Styles from './MyExcoLandingAdvantage.module.css';

const propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  descriptionList: PropTypes.array,
};

export const MyExcoLandingAdvantage = ({ icon, title, descriptionList }) => (
  <div className={Styles.wrapper}>
    <div className={Styles.imageWrapper}>
      <img className={Styles.icon} src={icon} alt="test" />
    </div>
    <div className={Styles.textWrapper}>
      <h4 className={Styles.title}>{title}</h4>
      <ul className={Styles.list}>
        {descriptionList.map((point) => (
          <li className={Styles.description} key={point}>
            {point}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

MyExcoLandingAdvantage.propTypes = propTypes;
