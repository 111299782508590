import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';

const {
  TOOLTIP: {
    VALIDATION_STATUS_TOOLTIPS: {
      NOT_VALIDATED_NORMAL,
      NOT_VALIDATED_OVERDUE,
      VALIDATED_NORMAL,
      VALIDATED_OVERDUE,
    },
  },
} = INVOICE_SEARCH_TRANSLATIONS;

export const getValidationTooltipTitle = (isValidated, isOverdue) => {
  if (isValidated) {
    return isOverdue ? VALIDATED_OVERDUE : VALIDATED_NORMAL;
  }

  return isOverdue ? NOT_VALIDATED_OVERDUE : NOT_VALIDATED_NORMAL;
};

export const getValidationTooltipClassName = (isValidated, isOverdue) => {
  if (isValidated) {
    return isOverdue ? 'ValidatedOverdue' : 'ValidatedNormal';
  }

  return isOverdue ? 'NotValidatedOverdue' : 'NotValidatedNormal';
};
