import { request } from 'utils/request';
import { mapResponseStepToStore, mapResponseTab5ToStore } from '../mappers';

// export const getToken = () => request.get('authentication/token');

export const getNewEmployeeTab1Infos = () =>
  request
    .get('/forms/employees/step-1')
    .then(({ data }) => mapResponseStepToStore(data, 1));

export const getNewEmployeeTab2Infos = () =>
  request
    .get('/forms/employees/step-2')
    .then(({ data }) => mapResponseStepToStore(data, 2));

export const getNewEmployeeTab3Infos = () =>
  request
    .get('/forms/employees/step-3')
    .then(({ data }) => mapResponseStepToStore(data, 3));

export const getNewEmployeeTab4Infos = () =>
  request
    .get('/forms/employees/step-4')
    .then(({ data }) => mapResponseStepToStore(data, 4));

export const getNewEmployeeTab5Infos = () =>
  request
    .get('/forms/employees/step-5')
    .then((data) => mapResponseTab5ToStore(data, 4));

export const uploadNewEmployeeTab1 = (values = {}) =>
  request.post('/forms/employees/step-1', values);

export const uploadNewEmployeeTab2 = (values = {}) =>
  request.post('/forms/employees/step-2', values);

export const uploadNewEmployeeTab3 = (values = {}) =>
  request.post('/forms/employees/step-3', values);

export const uploadNewEmployeeTab4 = (values = {}) =>
  request.post('/forms/employees/step-4', values);

export const uploadNewEmployeeTab5 = (values = {}) =>
  request.post('/forms/employees/step-5', values);

export const resetFormService = () => request.post('/forms/employees/reset');
