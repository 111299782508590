import { INVOICE_TYPE_PURCHASE } from 'const';
import { useSelector } from 'react-redux';
import {
  selectCanOnlyView,
  selectCanPay,
  selectCanPrevalidate,
  selectCanValidate,
  selectCanValidateAndPay,
} from 'modules/loggedUserInfo/selectors';
import { useInvoiceActionsContext } from 'contexts/InvoiceTableActionsContext';
import { useMemo } from 'react';

export const useInvoiceActionsFlags = () => {
  const {
    invoiceType,
    isCancellation,
    paymentApprovedBy,
  } = useInvoiceActionsContext();

  const canValidate = useSelector(selectCanValidate);
  const canPay = useSelector(selectCanPay);
  const canValidateAndPay = useSelector(selectCanValidateAndPay);
  const canPrevalidate = useSelector(selectCanPrevalidate);
  const canOnlyView = useSelector(selectCanOnlyView);

  const isValidated = Boolean(paymentApprovedBy);
  const isPayableInvoice =
    invoiceType === INVOICE_TYPE_PURCHASE && !isCancellation;

  return useMemo(
    () => ({
      canValidate,
      canPay,
      canValidateAndPay,
      canPrevalidate,
      canOnlyView,
      isValidated,
      isPayableInvoice,
    }),
    [
      canValidate,
      canPay,
      canValidateAndPay,
      canPrevalidate,
      canOnlyView,
      isValidated,
      isPayableInvoice,
    ]
  );
};
