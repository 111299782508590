import { createSelector } from 'reselect';
import { getScheduleStructure } from '../../Employees/utils';
import { getBranchesOptions, getWorkWeekDays } from '../utils';

export const selectCompanyDetailData = (state) =>
  state.getIn(['companySettingsReducer', 'companyDetailData']);

export const selectActiveBranchData = (state) =>
  state.getIn(['companySettingsReducer', 'activeBranch']);

export const selectScheduleDetailData = (state) =>
  state.getIn(['companySettingsReducer', 'activeScheduleData']);

export const selectScheduleDetailMessage = (state) =>
  state.getIn(['companySettingsReducer', 'scheduleDetailMessage']);

export const selectSchedules = (state) =>
  state.getIn(['companySettingsReducer', 'schedules']);

export const selectBranches = createSelector(
  selectCompanyDetailData,
  (companyData) => (companyData && companyData.branches) || []
);

export const selectDefaultSchedule = createSelector(
  selectSchedules,
  (schedules) => {
    if (!schedules || !schedules.length) {
      return null;
    }

    return schedules.find(({ siret }) => siret === '');
  }
);

export const selectInitialPageTitle = createSelector(
  selectScheduleDetailData,
  (scheduleData) => {
    if (!scheduleData) {
      return '';
    }

    return scheduleData?.schedule?.title || '';
  }
);

export const selectDefaultPageTitle = createSelector(
  selectDefaultSchedule,
  (scheduleData) => {
    if (!scheduleData) {
      return '';
    }
    return scheduleData?.title || '';
  }
);

export const selectBranchesOptions = createSelector(
  selectCompanyDetailData,
  (companyData) => {
    if (companyData && companyData.branches.length) {
      return getBranchesOptions(companyData.branches);
    }

    return [];
  }
);

export const selectFormattedSchedules = createSelector(
  selectSchedules,
  (schedules) => {
    if (!schedules) return [];

    return schedules.map((schedule) => {
      const scheduleFormatted = getScheduleStructure(schedule.days);
      const totalHours = scheduleFormatted.reduce(
        (result, { totalInMinutes }) => result + totalInMinutes,
        0
      );
      const weekDays = getWorkWeekDays(scheduleFormatted);

      return {
        id: schedule.id,
        title: {
          text: schedule.title,
          link: schedule.id,
        },
        siret: schedule.siret || null,
        isDefault: schedule.isDefault,
        weekDays,
        total: `${Math.ceil(totalHours / 60)} h`,
        concerned: schedule.employeesCount,
      };
    });
  }
);
