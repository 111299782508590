import { createSelector } from 'reselect';
import { BOUQUET_MY_EXCO, EXCO_SERVICES } from 'const/excoServices';

export const selectPackagesInCart = createSelector(
  (state) => state.getIn(['cartReducer', 'currentItems']),
  (currentCartItems) => (currentCartItems ? currentCartItems.toJS() : [])
);

export const selectPackagesCosts = createSelector(
  (state) => state.getIn(['cartReducer', 'packagesCosts']),
  (packagesCosts) => (packagesCosts ? packagesCosts.toJS() : {})
);

export const selectPackagesCostsStatus = (state) =>
  state.getIn(['cartReducer', 'packagesCostsStatus']);

export const selectPackagesInCartWithCost = createSelector(
  selectPackagesInCart,
  selectPackagesCosts,
  (packagesNames, packagesCosts) => {
    let id = 0;
    return packagesNames.map((packageName) => {
      id += 1;
      return {
        name: packageName,
        cost: packagesCosts[packageName] || 'N/A',
        id,
      };
    });
  }
);

export const selectCardsWithCost = createSelector(
  selectPackagesCosts,
  (packagesCosts) =>
    EXCO_SERVICES.map((service) => ({
      ...service,
      PACKAGE_COST: packagesCosts[service.PACKAGE_NAME] || null,
    }))
);

export const selectMyExcoPackageWithCost = createSelector(
  selectPackagesCosts,
  (packagesCosts) => ({
    ...BOUQUET_MY_EXCO,
    PACKAGE_COST: packagesCosts[BOUQUET_MY_EXCO.PACKAGE_NAME] || null,
  })
);

export const selectSubscriptionStatus = (state) =>
  state.getIn(['cartReducer', 'status']);

export const selectShouldCartBeReseted = (state) =>
  state.getIn(['cartReducer', 'shouldBeReseted']);
