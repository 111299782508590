import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Button } from 'components/ui/Button';
import { ADDITIONAL_USERS_MODAL_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import { USEFUL_LINKS_MODAL_TRANSLATIONS } from 'const/translations/usefulLinks';
import { deleteBookmark } from 'modules/usefulLinks/actions';

import Styles from 'components/AdditionalUserModal/AdditionalUserModal.module.css';

const { DELETE_LINK } = HOMEPAGE_FORM_TRANSLATIONS;

const { CANCEL_BUTTON } = ADDITIONAL_USERS_MODAL_TRANSLATIONS;
const {
  CONFIRM_MODAL_DELETE,
  DELETE_MODAL_TEXT,
} = USEFUL_LINKS_MODAL_TRANSLATIONS;

export const DeleteUsefulLinksModal = ({ isVisible, closeModal, id }) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(deleteBookmark(id));
    closeModal();
  };

  return (
    <Modal
      centered
      destroyOnClose
      visible={isVisible}
      footer={false}
      className={Styles.modal}
      closable={false}
      onCancel={closeModal}
    >
      <div className={Styles.modalTitle}>{DELETE_LINK}</div>
      <div className={Styles.modalInputWrapper}>{DELETE_MODAL_TEXT}</div>

      <div className={Styles.modalButtonWrapper}>
        <Button
          variant="secondary"
          className={Styles.modalCancelButton}
          onClick={closeModal}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button variant="success" type="submit" onClick={onSubmit}>
          {CONFIRM_MODAL_DELETE}
        </Button>
      </div>
    </Modal>
  );
};

DeleteUsefulLinksModal.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  id: PropTypes.number,
};
