import { getType, getActionCreator } from 'utils';
const prefix = '@@app/salaryRewards';

export const GET_COMPANY_REWARDS = getType(prefix, 'GET_COMPANY_REWARDS');
export const getCompanyRewards = getActionCreator(GET_COMPANY_REWARDS);

export const GET_COMPANY_REWARDS_FAMILIES = getType(
  prefix,
  'GET_COMPANY_REWARDS_FAMILIES'
);
export const getCompanyRewardsFamilies = getActionCreator(
  GET_COMPANY_REWARDS_FAMILIES
);

export const SET_COMPANY_REWARDS_FAMILIES = getType(
  prefix,
  'SET_COMPANY_REWARDS_FAMILIES'
);
export const setCompanyRewardsFamilies = getActionCreator(
  SET_COMPANY_REWARDS_FAMILIES
);

export const SET_COMPANY_UNADDED_REWARDS = getType(
  prefix,
  'SET_COMPANY_UNADDED_REWARDS'
);
export const setCompanyUnaddedRewards = getActionCreator(
  SET_COMPANY_UNADDED_REWARDS
);

export const CREATE_REWARD_FAMILY = getType(prefix, 'CREATE_REWARD_FAMILY');
export const createRewardFamily = getActionCreator(CREATE_REWARD_FAMILY);

export const SET_REWARDS_ERROR = getType(prefix, 'SET_REWARDS_ERROR');
export const setRewardsError = getActionCreator(SET_REWARDS_ERROR);

export const GET_REWARDS_FAMILY = getType(prefix, 'GET_REWARDS_FAMILY');
export const getRewardsFamily = getActionCreator(GET_REWARDS_FAMILY);

export const SET_REWARDS_FAMILY = getType(prefix, 'SET_REWARDS_FAMILY');
export const setRewardsFamily = getActionCreator(SET_REWARDS_FAMILY);

export const ADD_REWARD_TO_FAMILY = getType(prefix, 'ADD_REWARD_TO_FAMILY');
export const addRewardToFamily = getActionCreator(ADD_REWARD_TO_FAMILY);

export const UPDATE_REWARD_TO_FAMILY = getType(
  prefix,
  'UPDATE_REWARD_TO_FAMILY'
);
export const updateRewardToFamily = getActionCreator(UPDATE_REWARD_TO_FAMILY);

export const UPDATE_REWARD_FAMILY = getType(prefix, 'UPDATE_REWARD_FAMILY');
export const updateRewardFamily = getActionCreator(UPDATE_REWARD_FAMILY);

export const REWARD_FAMILY_MESSAGE = getType(prefix, 'REWARD_FAMILY_MESSAGE');
export const setRewardFamilyMessage = getActionCreator(REWARD_FAMILY_MESSAGE);

export const REMOVE_REWARD_FAMILY = getType(prefix, 'REMOVE_REWARD_FAMILY');
export const removeRewardFamily = getActionCreator(REMOVE_REWARD_FAMILY);

export const REMOVE_REWARD_FROM_FAMILY = getType(
  prefix,
  'REMOVE_REWARD_FROM_FAMILY'
);
export const removeRewardFromFamily = getActionCreator(
  REMOVE_REWARD_FROM_FAMILY
);

export const ADD_REWARD_TO_FAMILY_SUCCESS = getType(
  prefix,
  'ADD_REWARD_TO_FAMILY_SUCCESS'
);
export const addRewardToFamilySuccess = getActionCreator(
  ADD_REWARD_TO_FAMILY_SUCCESS
);

export const UPDATE_REWARD_TO_FAMILY_SUCCESS = getType(
  prefix,
  'UPDATE_REWARD_TO_FAMILY_SUCCESS'
);
export const updateRewardToFamilySuccess = getActionCreator(
  UPDATE_REWARD_TO_FAMILY_SUCCESS
);

export const INIT_REWARDS = getType(prefix, 'INIT_REWARDS');
export const initRewards = getActionCreator(INIT_REWARDS);
