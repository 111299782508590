import React, { useCallback, useEffect } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import flowersImage from 'assets/images/loginNewBg.svg';
import { Section } from 'components/Section';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Typography } from 'antd';
import { useHistory } from 'react-router';

import Styles from './UnsubscribePage.module.css';
import { UNSUBSCRIBE_PAGE_TRANSLATIONS } from '../../const/translations/UnsubscribePage';
import { Button } from '../../components/ui/Button';
import { unsubscribeUserRequest } from '../../modules/UnsubscribePage/actions';
import { openSuccessNotification } from '../../helpers/notifications';
import { COMMON_LABELS } from '../../const/translations';
import { Radio } from '../../components/ui/Radio';
import { Input } from '../../components/ui/Input';
import { selectUserPackagesNames } from '../../modules/loggedUserInfo/selectors';
import { ROUTES } from '../../const';
import { PACKAGES_NAMES } from '../../const/packages';

const {
  LABELS,
  ERRORS,
  OPTIONS,
  TITLE,
  REASON_OTHER_PLACEHOLDER,
  TEXT: { BLOCK_1, BLOCK_2, BLOCK_3 },
  REQUEST_SUCCESS,
} = UNSUBSCRIBE_PAGE_TRANSLATIONS;

const { Paragraph } = Typography;

export const UnsubscribePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userSubscribedPackages = useSelector(selectUserPackagesNames);

  const onSuccess = useCallback(() => {
    openSuccessNotification({
      message: REQUEST_SUCCESS,
      color: 'darkGreen',
      wide: true,
    });
    history.push(ROUTES.ROOT);
  }, [history]);
  const onSubmit = useCallback(
    (values) => {
      dispatch(
        unsubscribeUserRequest({
          onSuccess,
          values: {
            ...values,
            modules: values.modules.includes(PACKAGES_NAMES.MY_EXCO)
              ? userSubscribedPackages
              : values.modules,
            reasons:
              values.reasons.includes(COMMON_LABELS.OTHER) &&
              values.customReason
                ? values.reasons.map((reason) =>
                    reason === COMMON_LABELS.OTHER
                      ? values.customReason
                      : reason
                  )
                : values.reasons,
          },
        })
      );
    },
    [userSubscribedPackages, dispatch, onSuccess]
  );

  return (
    <div className={Styles.hubPageBackground}>
      <div className={clsx(MainStyles.container)}>
        <img className={Styles.flowersImg} src={flowersImage} alt="flowers" />
        <Section title={TITLE} titleTextColor="white">
          <div className={Styles.container}>
            <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
              <div>
                <Paragraph className={Styles.rowText}>
                  <p>{BLOCK_1.LINE_1}</p>
                  <p>{BLOCK_1.LINE_2}</p>
                  <p>{BLOCK_1.LINE_3}</p>
                </Paragraph>
                <Paragraph className={Styles.rowText}>
                  <p>{BLOCK_2.LINE_1}</p>
                  <p>{BLOCK_2.LINE_2}</p>
                  <p>{BLOCK_2.LINE_3}</p>
                  <p>{BLOCK_2.LINE_4}</p>
                </Paragraph>
                <Paragraph className={clsx(Styles.rowText, Styles.bold)}>
                  <p>{BLOCK_3.LINE_1}</p>
                  <p>{BLOCK_3.LINE_2}</p>
                  <p>{BLOCK_3.LINE_3}</p>
                  <p>{BLOCK_3.LINE_4}</p>
                </Paragraph>
              </div>
            </Col>
            <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
              <Formik
                initialValues={{
                  modules: [],
                  confirmation: null,
                  reasons: [],
                  customReason: '',
                }}
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.modules.length) {
                    errors.modules = ERRORS.NO_MODULES;
                  }
                  if (values.confirmation === null) {
                    errors.confirmation = ERRORS.NO_CONFIRM;
                  }
                  if (!values.reasons.length) {
                    errors.reasons = ERRORS.NO_REASONS;
                  }
                  if (
                    values.reasons.includes(COMMON_LABELS.OTHER) &&
                    !values.customReason
                  ) {
                    errors.customReason = ERRORS.NO_REASONS;
                  }
                  return errors;
                }}
                validateOnMount={false}
              >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    {!userSubscribedPackages.length && (
                      <Paragraph className={Styles.rowText} strong>
                        {ERRORS.NO_SUBSCRIBED_MODULES}
                      </Paragraph>
                    )}
                    <Paragraph className={Styles.formQuestion}>
                      {LABELS.MODULES}{' '}
                      <span className={Styles.mandatory}>*</span>
                    </Paragraph>
                    {touched.modules && errors.modules && (
                      <p className={Styles.formEntryError}>{errors.modules}</p>
                    )}
                    {OPTIONS.MODULES.map(({ label, value, subtitle }) => (
                      <div key={`modules_${value}`} className={Styles.formRow}>
                        <input
                          type="checkbox"
                          id={`modules_${value}`}
                          name="modules"
                          value={value}
                          checked={
                            values.modules.includes(value) ||
                            (values.modules.includes(PACKAGES_NAMES.MY_EXCO) &&
                              userSubscribedPackages.includes(value))
                          }
                          onChange={handleChange}
                          className={Styles.formCheckbox}
                          disabled={
                            !userSubscribedPackages.length ||
                            !userSubscribedPackages.includes(value)
                          }
                        />
                        <span className={Styles.formLabelWrapper}>
                          <label
                            htmlFor={`modules_${value}`}
                            className={Styles.formEntryLabel}
                          >
                            {label}
                          </label>
                          {subtitle && (
                            <span className={Styles.formLabelSecondary}>
                              {subtitle}
                            </span>
                          )}
                        </span>
                      </div>
                    ))}
                    <Paragraph className={Styles.formQuestion}>
                      {LABELS.CONFIRMATION}{' '}
                      <span className={Styles.mandatory}>*</span>
                    </Paragraph>
                    {touched.confirmation && errors.confirmation && (
                      <p className={Styles.formEntryError}>
                        {errors.confirmation}
                      </p>
                    )}
                    <Radio
                      options={OPTIONS.CONFIRM}
                      onChange={handleChange}
                      value={values.confirmation}
                      name="confirmation"
                    />
                    <Paragraph className={Styles.formQuestion}>
                      {LABELS.REASONS}{' '}
                      <span className={Styles.mandatory}>*</span>
                    </Paragraph>
                    {touched.reasons && errors.reasons && (
                      <p className={Styles.formEntryError}>{errors.reasons}</p>
                    )}
                    {OPTIONS.REASONS.map(({ label, value }) => (
                      <div key={`reasons_${value}`}>
                        <input
                          type="checkbox"
                          id={`reasons_${value}`}
                          name="reasons"
                          value={value}
                          checked={values.reasons.includes(value)}
                          onChange={handleChange}
                          className={Styles.formCheckbox}
                        />
                        <label
                          htmlFor={`reasons_${value}`}
                          className={Styles.formEntryLabel}
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                    {values.reasons.includes(COMMON_LABELS.OTHER) && (
                      <Input
                        onChange={handleChange}
                        error={touched.customReason ? errors.customReason : ''}
                        name="customReason"
                        placeholder={REASON_OTHER_PLACEHOLDER}
                        value={values.customReason}
                      />
                    )}
                    <div className={Styles.formButtonWrapper}>
                      <Button variant="primary" type="submit">
                        {COMMON_LABELS.SEND}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </Col>
          </div>
        </Section>
      </div>
    </div>
  );
};
