export const HOMEPAGE_DASHBOARDS_TRANSLATIONS = {
  TITLES: {
    TURNOVER: "Chiffre d'affaires réalisé",
    TREASURY: 'Trésorerie disponible',
    HR: 'Ressources Humaines',
  },
  SUBTITLES: {
    TREASURY: 'A la fin du mois',
    HR: 'Annuel',
  },
  TREASURY_FORECAST_TEXT:
    'Données non disponibles. Contactez nous pour découvrir notre offre « Mes ventes »',
  ADVANCED_INDICATORS: {
    TITLE: 'Mon tableau de bord',
    DESCRIPTION_SUBSCRIBED: `Accédez à vos indicateurs avancés et personnalisés pour mieux piloter votre entreprise`,
    DESCRIPTION_UNSUBSCRIBED: `Profitez d'indicateurs spécifiques et d'un accompagnement personnalisé pour aller plus loin dans la gestion de votre entreprise. Souscrivez à Mon tableau de bord`,
    BUTTON_SUBSCRIBED: 'Mes Indicateurs Premium',
    BUTTON_UNSUBSCRIBED: 'Découvrez notre solution',
    AUTH_ERROR: `Une erreur s'est produite lors de la connexion au service WAIBI`,
  },
  DISCOVER_SOLUTION: 'Découvrez notre solution',
  EVOLUTION: 'Evolution journalière',
  DASHBOARD_ERROR_TEXT: 'Données non disponibles. Essayez de recharger la page',
  DASHBOARD_ERROR_BUTTON_TEXT: 'Contactez nous',
};
