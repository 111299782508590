import { getType, getActionCreator } from 'utils';
const prefix = '@@app/cart';

export const ADD_PACKAGES_TO_CART = getType(prefix, 'ADD_PACKAGES_TO_CART');
export const addPackagesToCart = getActionCreator(ADD_PACKAGES_TO_CART);

export const ADD_WAIBI_TO_CART = getType(prefix, 'ADD_WAIBI_TO_CART');
export const addWaibiToCart = getActionCreator(ADD_WAIBI_TO_CART);

export const REMOVE_PACKAGE_FROM_CART = getType(
  prefix,
  'REMOVE_PACKAGE_FROM_CART'
);
export const removePackageFromCart = getActionCreator(REMOVE_PACKAGE_FROM_CART);

export const INITIALIZE_CART = getType(prefix, 'INITIALIZE_CART');
export const initializeCart = getActionCreator(INITIALIZE_CART);

export const CLEAR_CART = getType(prefix, 'CLEAR_CART');
export const clearCart = getActionCreator(CLEAR_CART);

export const SET_SUBSCRIPTION_STATUS = getType(
  prefix,
  'SET_SUBSCRIPTION_STATUS'
);
export const setSubscriptionStatus = getActionCreator(SET_SUBSCRIPTION_STATUS);

export const RESET_CART_AND_ADDITIONAL_USERS = getType(
  prefix,
  'RESET_CART_AND_ADDITIONAL_USERS'
);
export const resetCartAndAdditionalUsers = getActionCreator(
  RESET_CART_AND_ADDITIONAL_USERS
);

export const SUBSCRIBE_TO_PACKAGES_REQUEST = getType(
  prefix,
  'SUBSCRIBE_TO_PACKAGES_REQUEST'
);
export const subscribeToPackagesRequest = getActionCreator(
  SUBSCRIBE_TO_PACKAGES_REQUEST
);

export const SUBSCRIBE_TO_PACKAGES_SUCCESS = getType(
  prefix,
  'SUBSCRIBE_TO_PACKAGES_SUCCESS'
);
export const subscribeToPackagesSuccess = getActionCreator(
  SUBSCRIBE_TO_PACKAGES_SUCCESS
);

export const GET_PACKAGES_COSTS_REQUEST = getType(
  prefix,
  'GET_PACKAGES_COSTS_REQUEST'
);
export const getPackagesCostsRequest = getActionCreator(
  GET_PACKAGES_COSTS_REQUEST
);

export const GET_PACKAGES_COSTS_SUCCESS = getType(
  prefix,
  'GET_PACKAGES_COSTS_SUCCESS'
);
export const getPackagesCostsSuccess = getActionCreator(
  GET_PACKAGES_COSTS_SUCCESS
);

export const GET_PACKAGES_COSTS_ERROR = getType(
  prefix,
  'GET_PACKAGES_COSTS_ERROR'
);
export const getPackagesCostsError = getActionCreator(GET_PACKAGES_COSTS_ERROR);

export const PUT_USER_SUBSCRIBED_PACKAGES = getType(
  prefix,
  'PUT_USER_SUBSCRIBED_PACKAGES'
);
export const putUserSubscribedPackages = getActionCreator(
  PUT_USER_SUBSCRIBED_PACKAGES
);
