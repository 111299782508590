import { SIDEBAR_LABELS } from 'const/translations';
import { APP_MODULES, ROUTES } from 'const/index';

const {
  HOME,
  DOCUMENTS_CABINET,
  INVOICE_SEARCH,
  MY_DOCUMENTS,
  MY_EXPENSES,
  MY_SALES,
  UPLOAD_DOCUMENTS,
  SALARIES,
  STORE,
  HELP,
  WAIBI,
} = SIDEBAR_LABELS;

const {
  APPROVE_INVOICE,
  PAY_INVOICE,
  READONLY_INVOICE,
  APPROVE_AND_PAY_INVOICE,
  PRE_VALIDATE_INVOICE,
} = APP_MODULES;

export const SIDEBAR_CUSTOM_ACTIONS = {
  waibiRedirect: 'waibiRedirect',
  helpRequest: 'helpRequest',
};

export const HELP_NAV_LINK = {
  id: 10,
  label: HELP,
  iconId: 'help',
  customActionType: SIDEBAR_CUSTOM_ACTIONS.helpRequest,
};

export const NAV_LINKS = [
  {
    id: 0,
    label: HOME,
    route: ROUTES.ROOT,
    iconId: 'homePage',
  },
  {
    id: 3,
    label: MY_DOCUMENTS,
    iconId: 'mesDocuments',
    routesForMultiLink: [
      ROUTES.UPLOAD_DOCUMENTS,
      ROUTES.INVOICE_SEARCH,
      ROUTES.DOCUMENTS_CABINET,
    ],
    childrenLinks: [
      {
        id: 4,
        label: UPLOAD_DOCUMENTS,
        route: ROUTES.UPLOAD_DOCUMENTS,
        verifyHidden: (authorisedModules = []) =>
          !authorisedModules.includes(APP_MODULES.UPLOAD_DOCUMENTS),
      },
      {
        id: 5,
        label: INVOICE_SEARCH,
        route: ROUTES.INVOICE_SEARCH,
        verifyHidden: (authorisedModules = []) =>
          [APP_MODULES.SEARCH_INVOICES].every(
            (module) => !authorisedModules.includes(module)
          ),
      },
      {
        id: 6,
        label: DOCUMENTS_CABINET,
        route: ROUTES.DOCUMENTS_CABINET,
        verifyHidden: (authorisedModules = []) =>
          !authorisedModules.includes(APP_MODULES.DOCUMENTS_CABINET),
      },
    ],
    verifyHidden: (authorisedModules = []) =>
      [
        APP_MODULES.SEARCH_INVOICES,
        APP_MODULES.DOCUMENTS_CABINET,
        APP_MODULES.UPLOAD_DOCUMENTS,
      ].every((module) => !authorisedModules.includes(module)),
  },
  {
    id: 1,
    label: WAIBI,
    conditional: true,
    iconId: 'waibi',
    verifyAccess: (authorisedModules = []) =>
      authorisedModules.includes(APP_MODULES.WAIBI),
    verifyHidden: (authorisedModules = []) =>
      !authorisedModules.includes(APP_MODULES.WAIBI),
    customActionType: SIDEBAR_CUSTOM_ACTIONS.waibiRedirect,
  },
  {
    id: 2,
    label: MY_SALES,
    route: ROUTES.SALES_LANDING,
    conditional: true,
    iconId: 'mesVentes',
    verifyAccess: (authorisedModules = []) =>
      authorisedModules.includes(APP_MODULES.ISANETFACT),
    verifyHidden: (authorisedModules = [], currentCompany) =>
      !currentCompany?.ssoEnabled ||
      !authorisedModules.includes(APP_MODULES.ISANETFACT),
  },
  {
    id: 7,
    label: MY_EXPENSES,
    route: ROUTES.EXPENSES,
    iconId: 'mesDepenses',
    verifyCurrent: (currentRoute) =>
      [ROUTES.EXPENSES, ROUTES.EXPENSES_LANDING].includes(currentRoute),
    verifyHidden: (authorisedModules = []) =>
      [
        APPROVE_INVOICE,
        PAY_INVOICE,
        READONLY_INVOICE,
        APPROVE_AND_PAY_INVOICE,
        PRE_VALIDATE_INVOICE,
      ].every((module) => !authorisedModules.includes(module)),
  },
  {
    id: 8,
    label: SALARIES,
    iconId: 'mesSalaries',
    route: ROUTES.SALARIES_MAIN_PAGE,
    verifyHidden: (authorisedModules = []) =>
      !authorisedModules.includes(APP_MODULES.EVP),
    verifyCurrent: (currentRoute) =>
      [
        ROUTES.SALARIES_MAIN_PAGE,
        ROUTES.SALARIES_EMPLOYEES,
        ROUTES.SALARIES_CALENDAR,
        ROUTES.SALARIES_COMPANY_SETTINGS,
        ROUTES.SALARIES_COMPANY_SETTINGS_REWARD,
        ROUTES.SALARIES_COMPANY_SETTINGS_REWARD_CREATE,
        ROUTES.SALARIES_COMPANY_SETTINGS_SCHEDULE,
        ROUTES.SALARIES_SCHEDULE_EMPTY,
        ROUTES.SALARIES_REWARDS,
        ROUTES.EMPLOYEE_REGISTER_TITLE,
        ROUTES.EMPLOYEE_REGISTER_FORM,
      ].includes(currentRoute),
    isPayRelated: true,
  },
  {
    id: 9,
    label: STORE,
    route: ROUTES.STORE,
    iconId: 'cart',
    verifyHidden: (authorisedModules, currentCompany, isMainUser) =>
      !isMainUser,
  },
  HELP_NAV_LINK,
];
