import { getType, getActionCreator } from 'utils';
const prefix = '@@app/salaryEmployees';

export const FETCH_EMPLOYEES_REQUEST = getType(
  prefix,
  'FETCH_EMPLOYEES_REQUEST'
);
export const fetchEmployeesRequest = getActionCreator(FETCH_EMPLOYEES_REQUEST);

export const FETCH_EMPLOYEES_SUCCESS = getType(
  prefix,
  'FETCH_EMPLOYEES_SUCCESS'
);
export const fetchEmployeesSuccess = getActionCreator(FETCH_EMPLOYEES_SUCCESS);

export const FETCH_EMPLOYEES_ERROR = getType(prefix, 'FETCH_EMPLOYEES_ERROR');
export const fetchEmployeesError = getActionCreator(FETCH_EMPLOYEES_ERROR);

export const CHANGE_EMPLOYEES_FILTERS = getType(
  prefix,
  'CHANGE_EMPLOYEES_FILTERS'
);
export const changeEmployeesFilters = getActionCreator(
  CHANGE_EMPLOYEES_FILTERS
);

export const SET_EMPLOYEES_FILTERS = getType(prefix, 'SET_EMPLOYEES_FILTERS');
export const setEmployeesFilters = getActionCreator(SET_EMPLOYEES_FILTERS);

export const SET_EMPLOYEES_SEARCH = getType(prefix, 'SET_EMPLOYEES_SEARCH');
export const setEmployeesSearch = getActionCreator(SET_EMPLOYEES_SEARCH);

export const SET_DATE_RANGE = getType(prefix, 'SET_DATE_RANGE');
export const setDateRange = getActionCreator(SET_DATE_RANGE);

export const RESET_EMPLOYEES_FILTERS = getType(
  prefix,
  'RESET_EMPLOYEES_FILTERS'
);
export const resetEmployeesFilters = getActionCreator(RESET_EMPLOYEES_FILTERS);

/* ========== SCHEDULES =========== */

export const SET_SCHEDULE_ID = getType(prefix, 'SET_SCHEDULE_ID');
export const setScheduleId = getActionCreator(SET_SCHEDULE_ID);

export const SET_EMPLOYEE_SCHEDULE = getType(prefix, 'SET_EMPLOYEE_SCHEDULE');
export const setEmployeeSchedule = getActionCreator(SET_EMPLOYEE_SCHEDULE);

export const FETCH_COMPANY_BRANCHES_REQUEST = getType(
  prefix,
  'FETCH_COMPANY_BRANCHES_REQUEST'
);
export const fetchCompanyBranchesRequest = getActionCreator(
  FETCH_COMPANY_BRANCHES_REQUEST
);

export const FETCH_COMPANY_BRANCHES_SUCCESS = getType(
  prefix,
  'FETCH_COMPANY_BRANCHES_SUCCESS'
);
export const fetchCompanyBranchesSuccess = getActionCreator(
  FETCH_COMPANY_BRANCHES_SUCCESS
);

export const DOWNLOAD_EMPLOYEES_TABLE_XLSX = getType(
  prefix,
  'DOWNLOAD_EMPLOYEES_TABLE_XLSX'
);
export const downloadEmployeesTableXlsx = getActionCreator(
  DOWNLOAD_EMPLOYEES_TABLE_XLSX
);
