import React, { memo } from 'react';
import { Row, Col, Typography, Icon } from 'antd';
import { NO_DATA_FOUND } from 'const/translations/SalaryEmployeePage';
import Styles from './NoDataFound.module.css';

const { Paragraph } = Typography;

const propTypes = {};

export const NoRewardsDataFound = memo(() => (
  <Row type="flex" justify="center">
    <Col className={Styles.content}>
      <Icon className={Styles.icon} type="search" />
      <Paragraph className={Styles.text}>
        {NO_DATA_FOUND.TEXT_FIRST_LINE}
      </Paragraph>
    </Col>
  </Row>
));

NoRewardsDataFound.propTypes = propTypes;
