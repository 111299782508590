/*
 * ABSENCE_REASONS - used on the backend, do not change them without reconciliation
 * */
export const VACATION_MODAL = {
  DATE_START_PLACEHOLDER: 'Début',
  DATE_END_PLACEHOLDER: 'Fin',
  WORKING_TIME: [
    {
      label: 'Toute la journée',
      value: 'whole_day',
    },
    {
      label: 'Matin',
      value: 'morning',
    },
    {
      label: 'Après-midi',
      value: 'afternoon',
    },
  ],
  ACCEPT_FORMATS: '.jpg,.png,.pdf,.doc,.docx',
  LABEL_TEXTS: {
    IN_BULK: 'En masse',
    IN_BULK_VACATION: 'Congé groupé',
    IN_BULK_ABSENCE: 'Absence groupée',
    IN_BULK_VACATION_ADDITIONL: 'Déclarer ce congé pour plusieurs salariés',
    IN_BULK_ABSENCE_ADDITIONL: 'Déclarer cette absence pour plusieurs salariés',
    SELECT_ADDITIONAL_USERS: 'Sélectionnez des utilisateurs supplémentaires',
    VACATION_TITLE_BULK: 'Déclarer un congé pour tous les salariés',
    ABSENCE_TITLE_BULK:
      'Déclarer une absence ou un arrêt de travail pour tous les salariés',
    TYPE: 'Type',
    DATE_START: 'Date de début',
    DATE_START_SMALL: 'Premier jour de travail',
    DATE_END: 'Date de fin',
    DATE_END_SMALL: 'Veille de la reprise',
    DAYS: 'Nombre de jour(s)',
    HOURS: 'Nombre d’heures',
    FILE: 'Joindre le justificatif',
    FILE_BTN: 'Télécharger',
    COMMENT: 'Commentaire',
    TOOLTIP:
      'Indiquez dans cette zone une absence inférieure à la demi-journée (ex : Absence non-payée (autorisée) = 2 h 00) ou toute autre information à porter à notre connaissance',
    OPTIONAL: 'Optionnel',
    ERROR_MESSAGE: 'Veuillez saisir tous les champs correctement',
    ERROR_DATES_OVERLAP:
      'La période sélectionnée contient déjà une absence ou un congé pour ce salarié. Merci de modifier les dates ou de modifier les congés/absences déjà enregistré.e.s',
  },
  UI: {
    ONE_ABSENCE: 'Une absence',
    ONE_VACATION: 'Un congé',
    CONFIRM_MODAL_TITLE: "Suppression d'absences / congés",
    CONFIRM_MODAL_CONTENT:
      "Êtes-vous sûr de vouloir supprimer l'absence ou le congé ?",
    DELETE: 'Supprimer',
    CANCEL: 'Annuler',
  },
  CREATE_LEAVE_TITLES: {
    absence: 'Déclarer une absence ou un arrêt de travail pour',
    vacation: 'Déclarer un congé pour',
  },
  EDIT_LEAVE_TITLES: {
    absence: 'Modifier une absence ou un arrêt de travail pour',
    vacation: 'Modifier un congé pour',
  },
  SINGLE_VACATION_DAY: 'Congé ≤ 1',
  SINGLE_ABSENCE_DAY: 'Absence  ≤ 1',
  SINGLE_VACATION_DAY_ADDITIONAL: 'Cocher si congé = 0,5 ou 1 jour',
  SINGLE_ABSENCE_DAY_ADDITIONAL: 'Cocher si absence = 0,5 ou 1 jour',
};

export const VACATION_NOTIFICATION_TEXT = `{{PRE_NOTIFICATION_TEXT}} pour {{EMPLOYEE_NAME}} a été déclaré`;
