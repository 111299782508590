import { VACATION_NOTIFICATION_TEXT } from 'const/translations/SalaryVacations';
import { LEAVES_TYPES } from 'const/translations/SalaryCalendar';

// returns notification string const with replaced text from arguments
// preNotificationText replaces {{PRE_NOTIFICATION_TEXT}}
// employeeName replaces {{EMPLOYEE_NAME}}
/**
 * @param leaveType {string} - Either 'absence' or 'vacation'
 * @param preNotificationText {string} - Pre notification text
 * @param messageAbout {string} - Employee name or array of names
 * */
export const getLeaveModalNotificationMessage = (
  leaveType,
  preNotificationText,
  messageAbout
) => {
  const text = `${VACATION_NOTIFICATION_TEXT}${
    leaveType === LEAVES_TYPES.LEAVE_ABSENCE ? 'e' : ''
  }`;

  const texts = {
    PRE_NOTIFICATION_TEXT: preNotificationText,
    EMPLOYEE_NAME: Array.isArray(messageAbout)
      ? messageAbout.join(', ')
      : messageAbout,
  };

  return Object.entries(texts).reduce(
    (resultText, [key, textValue]) =>
      resultText.replaceAll(`{{${key}}}`, textValue),
    text
  );
};
