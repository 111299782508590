import { getType, getActionCreator } from 'utils';
const prefix = '@@app/usefulLinks';

export const GET_BOOKMARKS = getType(prefix, 'GET_BOOKMARKS');
export const getBookmarks = getActionCreator(GET_BOOKMARKS);

export const GET_BOOKMARKS_SUCCES = getType(prefix, 'GET_BOOKMARKS_SUCCES');
export const getBookmarksSucces = getActionCreator(GET_BOOKMARKS_SUCCES);

export const SET_BOOKMARK = getType(prefix, 'SET_BOOKMARK');
export const setBookmark = getActionCreator(SET_BOOKMARK);

export const EDIT_BOOKMARK = getType(prefix, 'EDIT_BOOKMARK');
export const editBookmark = getActionCreator(EDIT_BOOKMARK);

export const DELETE_BOOKMARK = getType(prefix, 'DELETE_BOOKMARK');
export const deleteBookmark = getActionCreator(DELETE_BOOKMARK);
