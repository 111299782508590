/**
 * creates type const to be used in action creators
 * @param prefix
 * @param typeName
 * @returns {string}
 */
export const getType = (prefix = 'app', typeName) => `${prefix}/${typeName}`;

/**
 * creates action creator function with specified type.
 * @param type
 * @returns {function(*=): {type: *}}
 */
export const getActionCreator = (type) => (payload = {}) => ({
  type,
  payload,
});

/**
 * creates screen width breakpoint media string for useMedia hook
 * @param screenWidthBreakpointValue{number} - screen width
 * @return {string}
 * */
export const getScreenWidthBreakpointMedia = (screenWidthBreakpointValue) =>
  `(max-width: ${screenWidthBreakpointValue}px)`;
