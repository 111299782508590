import { getType, getActionCreator } from 'utils';
const prefix = '@@app/helpModal';

export const HELP_REQUEST = getType(prefix, 'HELP_REQUEST');
export const helpRequest = getActionCreator(HELP_REQUEST);

export const HELP_REQUEST_SUCCESS = getType(prefix, 'HELP_REQUEST_SUCCESS');
export const helpRequestSuccess = getActionCreator(HELP_REQUEST_SUCCESS);

export const HELP_REQUEST_ERROR = getType(prefix, 'HELP_REQUEST_ERROR');
export const helpRequestError = getActionCreator(HELP_REQUEST_ERROR);
