import clsx from 'clsx';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';

export const usePayIconClassName = () => {
  const {
    isPayableInvoice,
    canPay,
    isValidated,
    paymentStatus,
    paidOutside,
    canValidateAndPay,
    isDeleted,
  } = useInvoiceActionsData();

  const isValidAndApproved = isPayableInvoice && canPay && isValidated;

  const paymentStatusIconStyle = paymentStatus
    ? `paymentIcon-${paymentStatus}`
    : '';
  const paymentIconStyle = paidOutside
    ? 'paymentIcon-paidOutside'
    : paymentStatusIconStyle;

  const notValidatedIconStyle =
    isPayableInvoice && canValidateAndPay
      ? 'IconCanValidateAndPay'
      : 'IconDisabled';

  return clsx(
    Styles.Icon,
    isValidAndApproved
      ? Styles[paymentIconStyle]
      : Styles[notValidatedIconStyle],
    isDeleted && Styles.IconDisabled
  );
};
