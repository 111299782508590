import React, { useMemo } from 'react';
import moment from 'moment';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import { DATE_FORMAT } from 'const';
import { DownloadButton } from 'components/DocumentsCabinet/DocumentsList/DownloadButton';
import { DataNode } from 'components/DocumentsCabinet/DocumentsList/DataNode';

const {
  DOCUMENTS_LIST: {
    TABLE_HEADER_CAPTIONS: { FILE_NAME, SIZE, DATE, DOWNLOAD },
  },
} = DOCUMENTS_CABINET_TRANSLATIONS;

export const useDocumentsListColumns = () =>
  useMemo(
    () => [
      {
        title: FILE_NAME,
        dataIndex: 'name',
        key: 'name',
        render: (name, { size, extension }) => (
          <DataNode name={name} size={size} extension={extension} />
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: SIZE,
        dataIndex: 'size',
        key: 'size',
        render: (size) => size || '-',
      },
      {
        title: DATE,
        dataIndex: 'date',
        key: 'date',
        render: (momentDate) => momentDate.format(DATE_FORMAT),
        sorter: (a, b) => moment(a.date).diff(moment(b.date)),
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: DOWNLOAD,
        dataIndex: 'name',
        key: 'actions',
        render: (fileName, { size, key }) => (
          <DownloadButton size={size} fileId={key} fileName={fileName} />
        ),
      },
    ],
    []
  );
