import React, { useCallback } from 'react';
import { InputNumber } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';

export const InputNumberFormik = ({ onChange, name, ...props }) => {
  // TODO remove convert to string when API wil be fixed and use useFormikOnChange hook from hooks dir
  // Update 04.05.23 hasn't been fixed yet (?)
  const handleChange = useCallback((value) => onChange(name, String(value)), [
    onChange,
    name,
  ]);

  return <InputNumber {...props} onChange={handleChange} />;
};

InputNumberFormik.propTypes = formikOnChangeAdaptorTypes;
