import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { ROUTES, STATUS_ERROR, STATUS_LOADING } from 'const';
import { useChartFilters } from 'hooks/useChartFilters';
import MainStyles from 'assets/styles/main.module.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { Section } from 'components/Section';
import { Col, Row } from 'antd';
import { YearMonthSwitch } from 'components/ui/YearMonthSwitch';
import { MonthSelector } from 'components/ui/MonthSelector';
import { ViewSwitch } from 'components/ui/ViewSwitch';
import { Legends } from 'components/Charts/Legends';
import { ChartTabs } from 'components/ChartTabs';
import { DASHBOARD_NAMES } from 'const/charts';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTreasuryDataRequest,
  getTreasurySingleAccountDataRequest,
} from 'modules/treasuryPage/actions';
import {
  selectTreasuryDataForCharts,
  selectTreasuryDataForYear,
  selectTreasuryLastUpdatedDate,
  selectTreasurySingleAccountStatus,
  selectTreasuryStatus,
  selectTreasuryUserAccounts,
} from 'modules/treasuryPage/selectors';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import {
  fillEmptyDates,
  getActiveMonthChartData,
} from 'helpers/chartDataMappers';
import {
  selectUserCurrentCompanyDosId,
  selectUserCurrentCompanyExerciceWithYear,
} from 'modules/loggedUserInfo/selectors';
import { getCurrentMonthNameFromConst } from 'helpers/monthSelector';
import { CommentsModal } from 'components/CommentsModal';
import {
  selectCommentsStatus,
  selectTreasuryCommentsForChart,
  selectTreasuryCommentsForYearChart,
} from 'modules/commentsModal/selectors';
import { getAllCommentsRequest } from 'modules/commentsModal/actions';
import { useChartComments } from 'hooks/useChartComments';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { LineChart } from 'components/Charts/LineChart';
import { BarChart } from 'components/Charts/BarChart';
import { ChartSelect } from 'components/ui/ChartSelect';
import { useSimpleMedia } from 'hooks/useMedia';
import { PillarLoader } from 'components/ui/PillarLoader';
import Styles from './TreasuryPage.module.css';
import { LastUpdate } from '../../components/LastUpdate';

const { TITLES, EVOLUTION } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { LINE } = CHARTS_VIEW_SWITCH_TYPES;

const { MY_INDICATORS_SECTION_TITLE } = HOMEPAGE_FORM_TRANSLATIONS;

const { ROOT } = ROUTES;

const { TREASURY } = DASHBOARD_NAMES;

const SCREEN_WIDTH_BREAKPOINT_VALUE = 950;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const TreasuryPage = React.memo(() => {
  const { currentExercice, currentYearFromExercice } = useSelector(
    selectUserCurrentCompanyExerciceWithYear
  );
  const {
    activeYear,
    setActiveYear,
    setActiveMonthName,
    activeMonthName,
    chartViewActiveMode,
    setChartViewActiveMode,
    activeDateViewOption,
    setActiveDateViewOption,
  } = useChartFilters(undefined, undefined, currentYearFromExercice);

  const isTabletVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  const dispatch = useDispatch();
  const firstMonth = useSelector(selectCurrentCompanyFirstFiscalMonth);
  const updateDate = useSelector(selectTreasuryLastUpdatedDate);

  const userAccounts = useSelector(selectTreasuryUserAccounts);

  const [hiddenLinesYears, setHiddenLinesYears] = useState([]);

  const [activeTabId, setActiveTabId] = useState('total');

  const currentCompanyId = useSelector(selectUserCurrentCompanyDosId);

  useEffect(() => {
    setActiveYear(Number(currentYearFromExercice));
    setActiveMonthName(getCurrentMonthNameFromConst());
  }, [
    currentYearFromExercice,
    currentCompanyId,
    setActiveYear,
    setActiveMonthName,
  ]);

  useEffect(() => {
    setActiveTabId('total');
  }, [userAccounts, setActiveTabId]);

  useEffect(() => {
    if (activeTabId === 'total') {
      dispatch(getTreasuryDataRequest({ singleAccount: true }));
    } else {
      dispatch(
        getTreasurySingleAccountDataRequest({
          accountName: activeTabId,
        })
      );
    }
  }, [dispatch, activeTabId, currentExercice]);

  useEffect(() => {
    dispatch(getTreasuryDataRequest());
    dispatch(getAllCommentsRequest({ currentExercice }));
  }, [dispatch, currentCompanyId, currentExercice]);

  const treasuryYearData = useSelector(selectTreasuryDataForYear);
  const treasuryData = useSelector(selectTreasuryDataForCharts);
  const treasuryCumulDataForMonthsView = useMemo(
    () => fillEmptyDates(treasuryData, firstMonth, updateDate),
    [updateDate, treasuryData, firstMonth]
  );

  const chartData = useMemo(
    () =>
      activeDateViewOption === YEAR
        ? treasuryYearData
        : getActiveMonthChartData(
            treasuryCumulDataForMonthsView,
            activeMonthName
          ),
    [
      activeDateViewOption,
      activeMonthName,
      treasuryYearData,
      treasuryCumulDataForMonthsView,
    ]
  );

  const Chart = chartViewActiveMode === LINE ? LineChart : BarChart;

  const treasuryCommentsForMonths = useSelector(selectTreasuryCommentsForChart);
  const treasuryCommentsForYear = useSelector(
    selectTreasuryCommentsForYearChart
  );
  const treasuryComments = useChartComments(
    treasuryCommentsForMonths,
    treasuryCommentsForYear,
    activeMonthName,
    activeDateViewOption
  );

  const {
    isModalOpen,
    onCloseCommentsModal,
    onOpenCommentsModal,
  } = useCommentsModalCallbacks();

  const treasurySingleAccountStatus = useSelector(
    selectTreasurySingleAccountStatus
  );
  const treasuryDataFetchStatus = useSelector(selectTreasuryStatus);
  const commentsFetchStatus = useSelector(selectCommentsStatus);
  const isError = [
    treasurySingleAccountStatus,
    treasuryDataFetchStatus,
    commentsFetchStatus,
  ].some((status) => status === STATUS_ERROR);
  const isLoading = [
    treasurySingleAccountStatus,
    treasuryDataFetchStatus,
    commentsFetchStatus,
  ].some((status) => status === STATUS_LOADING);

  const yearSelectCallback = useCallback(
    (value) => setActiveDateViewOption(value),
    [setActiveDateViewOption]
  );

  const changeActiveTab = useCallback((value) => setActiveTabId(value), [
    setActiveTabId,
  ]);

  return (
    <section className={Styles.page}>
      <CommentsModal isOpen={isModalOpen} closeModal={onCloseCommentsModal} />
      <div className={MainStyles.container}>
        <Link to={ROOT} className={Styles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
          <span>{MY_INDICATORS_SECTION_TITLE}</span>
        </Link>
      </div>
      <Section title={TITLES.TREASURY}>
        <ChartSelect
          options={userAccounts}
          activeValue={activeTabId}
          onChange={changeActiveTab}
          className={Styles.chartSelect}
          addTotalOption
          itemsHasValue
        />
        <Row className={Styles.contentRow}>
          {!isError && (
            <ChartTabs
              dashboardName={TREASURY}
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
              maxItemsOutsideSelector={isTabletVersion ? 3 : 4}
              tabItems={userAccounts}
              horizontal
              className={Styles.chartTabs}
              updateDate={updateDate}
            />
          )}
          <Col span={24} className={Styles.filtersWrapper}>
            <div className={Styles.dateSwitchAndMonthSelector}>
              <YearMonthSwitch
                setActiveOption={setActiveDateViewOption}
                activeOption={activeDateViewOption}
                className={Styles.yearMonthSwitcher}
              />
              <ChartSelect
                activeValue={activeDateViewOption}
                onChange={yearSelectCallback}
                className={Styles.yearMonthSelector}
              />
              {activeDateViewOption === YEAR ? (
                <div className={Styles.currentYear}>
                  {currentYearFromExercice}
                </div>
              ) : (
                <MonthSelector
                  activeMonthName={activeMonthName}
                  activeYear={activeYear}
                  firstMonth={firstMonth}
                  setActiveMonthName={setActiveMonthName}
                  setActiveYear={setActiveYear}
                  className={Styles.monthSelector}
                  bordered
                />
              )}
              {activeDateViewOption === YEAR && (
                <span className={Styles.evolution}>{EVOLUTION}</span>
              )}
            </div>
            <div className={Styles.chartFilters}>
              <ViewSwitch
                activeOption={chartViewActiveMode}
                setActiveOption={setChartViewActiveMode}
              />
              <Legends
                chartData={chartData}
                hiddenLinesYears={hiddenLinesYears}
                setHiddenLineYears={setHiddenLinesYears}
                className={Styles.chartLegends}
              />
            </div>
          </Col>
          {isLoading && (
            <Col span={24} className={Styles.loaderWrapper}>
              <PillarLoader colored />
            </Col>
          )}
          {!isLoading &&
            (isError ? (
              <Col span={24} className={Styles.loaderWrapper}>
                <PillarLoader />
                <div className={Styles.errorText}>
                  {HOMEPAGE_DASHBOARDS_TRANSLATIONS.DASHBOARD_ERROR_TEXT}
                </div>
              </Col>
            ) : (
              <Col span={24} className={Styles.contentCol}>
                <div className={Styles.chartWrapper}>
                  <Chart
                    firstMonth={firstMonth}
                    activeYear={activeYear}
                    data={chartData}
                    activeDateViewOption={activeDateViewOption}
                    activeMonthName={activeMonthName}
                    hiddenLinesYears={hiddenLinesYears}
                    gradientHeight={600}
                    enableGrid
                    comments={treasuryComments}
                    openCommentsModal={onOpenCommentsModal}
                    dashboardName={DASHBOARD_NAMES.TREASURY}
                  />
                </div>
              </Col>
            ))}
        </Row>
        <LastUpdate
          updateDate={updateDate}
          showCondition={Boolean(currentExercice)}
          className={Styles.updateDate}
        />
      </Section>
    </section>
  );
});
