import React from 'react';
import diagonalArrow from 'assets/icons/ReliabilityIndexCard/diagonal-arrow.svg';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Tooltip } from 'antd';
import Styles from './ReliabilityIndexCard.module.css';

const { RELIABILITY_INDEX_CARD } = HOMEPAGE_FORM_TRANSLATIONS;
const { DESCRIPTION, DISABLED_INDEX } = RELIABILITY_INDEX_CARD;

const getTitleAndPopupText = (index) => {
  const { TITLE, POPUP } = RELIABILITY_INDEX_CARD[index];
  return { TITLE, POPUP };
};

const propTypes = {
  index: PropTypes.oneOf(['high', 'medium', 'low', 'N/A']),
  className: PropTypes.string,
};

const isIndexDisabledByCustomer = true; // https://trello.com/c/PMydWfiR/1469-modify-text-content-associated-to-indicators

export const ReliabilityIndexCard = React.memo(({ index, className }) => {
  const { TITLE, POPUP } = getTitleAndPopupText(index);

  const isIndexNA = isIndexDisabledByCustomer ? true : index === 'N/A';

  return (
    <Tooltip
      placement="bottomLeft"
      overlayClassName={clsx(Styles.tooltip, {
        [Styles.hidden]: isIndexNA,
      })}
      title={POPUP}
      visible={!isIndexDisabledByCustomer}
    >
      <div
        className={clsx(Styles.wrapper, className, {
          [Styles[index]]: !isIndexNA,
          [Styles.nA]: isIndexNA,
        })}
      >
        {isIndexDisabledByCustomer ? (
          <>{DISABLED_INDEX}</>
        ) : (
          <>
            <div className={Styles.icon}>
              {!isIndexNA && <img src={diagonalArrow} alt="arrow" />}
            </div>
            <div className={Styles.textWrapper}>
              <div className={Styles.title}>{TITLE}</div>
              <div className={Styles.description}>{DESCRIPTION}</div>
            </div>
          </>
        )}
      </div>
    </Tooltip>
  );
});

ReliabilityIndexCard.propTypes = propTypes;
