import { useState, useCallback } from 'react';

export const useNavigation = (stepsArray = []) => {
  const [current, setCurrent] = useState(0);
  const onNext = () =>
    setCurrent((currentVal) => Math.min(currentVal + 1, stepsArray.length - 1));
  const onPrev = () => setCurrent((currentVal) => Math.max(currentVal - 1, 0));
  return {
    current,
    onTabChange: useCallback(setCurrent, []),
    onMoveNext: useCallback(onNext, []),
    onMovePrev: useCallback(onPrev, []),
  };
};
