export const COMMENTS_MODAL_TRANSLATIONS = {
  ADD_COMMENTARY: 'Ajouter commentaire',
  MODIFY_COMMENT: 'Modifier commentaire',
  SEND: 'Envoyer',
  CANCEL: 'Annuler',
  NO_SYMBOLS_ERROR: 'Veuillez saisir tous les champs',
  TO_MANY_SYMBOLS_ERROR: 'Caractères de plus de 1000',
  COMMENT: 'Commentaire',
  ADD_BUTTON: 'Ajouter',
  NO_COMMENTS: "Aucun commentaire pour l'instant",
  TYPE_COMMENTARY: 'Votre commentaire',
};
