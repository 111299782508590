import React, { useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { CHART_TABS_TRANSLATIONS } from 'const/translations/ChartTabs';
import Styles from './ChartSelect.module.css';

const { Option } = Select;

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const yearSelectDataOptions = [
  { id: YEAR, name: YEAR, value: YEAR },
  { id: MONTH, name: MONTH, value: MONTH },
];

const { ALL_ACCOUNTS_FOR_SELECT } = CHART_TABS_TRANSLATIONS;

const propTypes = {
  options: PropTypes.array,
  activeValue: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  itemsHasValue: PropTypes.bool,
  className: PropTypes.string,
  addTotalOption: PropTypes.bool,
};

export const ChartSelect = React.memo(
  ({
    options = yearSelectDataOptions,
    activeValue,
    onChange,
    itemsHasValue = false,
    className,
    addTotalOption = false,
  }) => {
    const formattedOptions = useMemo(() => {
      if (addTotalOption) {
        const totalValue = options.reduce(
          (sum, { value }) => Number(value) + sum,
          0
        );

        const totalOption = {
          id: 'total',
          name: ALL_ACCOUNTS_FOR_SELECT,
          value: totalValue,
          suffix: '€',
        };
        return [totalOption, ...options];
      }

      return options;
    }, [addTotalOption, options]);

    return (
      <Select
        value={activeValue}
        className={clsx(Styles.coloredDropdown, className, {
          [Styles.dropDownWithValues]: itemsHasValue,
        })}
        dropdownClassName={Styles.dropdownMenu}
        onChange={onChange}
      >
        {formattedOptions.map(({ name, value, id, suffix }) => {
          if (activeValue === value || activeValue === id) {
            return null;
          }

          return (
            <Option
              className={Styles.option}
              key={id}
              value={itemsHasValue ? id : value}
            >
              <div
                className={clsx(Styles.optionValue, {
                  [Styles.longOption]: itemsHasValue,
                })}
              >
                <div className={Styles.optionName}>{name}</div>
                {itemsHasValue &&
                  (value === null || !value ? (
                    <div>N/A</div>
                  ) : (
                    <div>{`${getProperLookingAmount(value)} ${suffix}`}</div>
                  ))}
              </div>
            </Option>
          );
        })}
      </Select>
    );
  }
);

ChartSelect.propTypes = propTypes;
