import React, { useCallback, useState } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import { Button } from 'components/ui/Button';
import flowersImg from 'assets/images/loginNewBg.svg';
import logo from 'assets/images/LOGO_MyExco.svg';
import { useDispatch } from 'react-redux';
import { acceptTermsOfUseRequest } from 'modules/loggedUserInfo/actions';
import checkMarkImage from 'assets/icons/checkmark-green-circle.svg';
import { Icon } from '@iconify/react';
import checkMark from '@iconify/icons-ant-design/check-outlined';
import { ROUTES } from 'const';
import {
  REGISTERED_TERMS_TRANSLATIONS,
  DESCRIPTION_POINTS,
  REGISTERED_TERMS_CHECKBOX,
} from 'const/translations/TermsOfUse';
import Styles from './RegisteredTerms.module.css';

const {
  MODAL_TITLE,
  SUBTITLE_DESCRIPTION,
  AGREE_BUTTON,
} = REGISTERED_TERMS_TRANSLATIONS;

export const RegisteredTerms = () => {
  const dispatch = useDispatch();

  const onAcceptClick = useCallback(() => {
    dispatch(acceptTermsOfUseRequest());
  }, [dispatch]);

  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const toggleCheckboxValue = useCallback(() => {
    setCheckBoxValue((prev) => !prev);
  }, [setCheckBoxValue]);

  return (
    <div className={Styles.termsOfUseWrapper}>
      <div className={clsx(MainStyles.container, Styles.wrapper)}>
        <img src={logo} alt="Exco logo" className={Styles.mainLogo} />
        <div className={Styles.modalWrapper}>
          <SectionTitle title={MODAL_TITLE} className={Styles.title} />
          <div className={Styles.descriptionWrapper}>
            <span className={Styles.optionsTitle}>{SUBTITLE_DESCRIPTION}</span>
            <div className={Styles.freePeriodOptionsWrapper}>
              {DESCRIPTION_POINTS.map(({ id, optionText, options }) => (
                <React.Fragment key={id}>
                  <div className={Styles.freePeriodOption}>
                    <span className={Styles.checkMarkImage}>
                      <img src={checkMarkImage} alt="check-mark" />
                    </span>
                    <span className={Styles.freePeriodOptionText}>
                      {optionText}
                    </span>
                  </div>
                  <div className={Styles.subOptionsWrapper}>
                    {options?.map((subOptions) => (
                      <div
                        key={subOptions.id}
                        className={Styles.freePeriodOption}
                      >
                        <span className={Styles.checkMarkImage}>
                          <img src={checkMarkImage} alt="check-mark" />
                        </span>
                        <span className={Styles.freePeriodOptionText}>
                          {subOptions.optionText}
                        </span>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className={Styles.checkBox}>
              <input
                checked={checkBoxValue}
                onChange={toggleCheckboxValue}
                type="checkbox"
                id="RegisteredTermsCheckbox"
              />
              <label htmlFor="RegisteredTermsCheckbox">
                <div
                  className={clsx(Styles.box, {
                    [Styles.checked]: checkBoxValue,
                  })}
                >
                  <Icon
                    className={clsx(Styles.checkMark, {
                      [Styles.checked]: checkBoxValue,
                    })}
                    icon={checkMark}
                  />
                </div>
                <span className={Styles.checkBoxLabelWrapper}>
                  {REGISTERED_TERMS_CHECKBOX.CHECKBOX_1_TEXT}{' '}
                  <a
                    href={ROUTES.TERMS_OF_SUBSCRIPTION}
                    className={Styles.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {REGISTERED_TERMS_CHECKBOX.CHECKBOX_2_LINK}
                  </a>{' '}
                  {REGISTERED_TERMS_CHECKBOX.CHECKBOX_3_TEXT}{' '}
                  <a
                    href={ROUTES.TERMS_OF_USE_LANDING}
                    className={Styles.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {REGISTERED_TERMS_CHECKBOX.CHECKBOX_4_LINK}
                  </a>
                </span>
              </label>
            </div>
            <Button
              variant="success"
              onClick={onAcceptClick}
              disabled={!checkBoxValue}
              className={Styles.agreeButton}
            >
              {AGREE_BUTTON}
            </Button>
          </div>
        </div>
      </div>
      <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
    </div>
  );
};
