import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MainStyles from 'assets/styles/main.module.css';
import { HomePageDashboard } from 'components/HomePageDashboard';
import { CHARTS_VIEW_SWITCH_TYPES, MONTHS } from 'const/ui';
// import { message } from 'antd';
import { DASHBOARD_NAMES } from 'const/charts';
import PropTypes from 'prop-types';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
// import { NavLink } from 'react-router-dom';
// import { Button } from 'components/ui/Button';
import {
  APP_MODULES,
  ROUTES,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
} from 'const';
// import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import {
  selectTurnoverDataForCharts,
  selectTurnoverDataForYear,
  selectTurnoverDataStatus,
  selectTurnoverLastUpdatedDate,
} from 'modules/turnoverPage/selectors';
import {
  fillEmptyDates,
  getActiveMonthChartData,
} from 'helpers/chartDataMappers';
import {
  selectTreasuryDataForCharts,
  selectTreasuryDataForYear,
  selectTreasuryLastUpdatedDate,
  selectTreasurySingleAccountStatus,
  selectTreasuryStatus,
} from 'modules/treasuryPage/selectors';
import {
  selectCommentsStatus,
  selectHRCommentsForYearChart,
  selectTreasuryCommentsForChart,
  selectTreasuryCommentsForYearChart,
  selectTurnoverCommentsForChart,
  selectTurnoverCommentsForYearChart,
} from 'modules/commentsModal/selectors';
import { CommentsModal } from 'components/CommentsModal';
import { useChartComments } from 'hooks/useChartComments';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { getMaxPeriodValuesForEachTabFromDashboardData } from 'helpers/HRhelpers';
import {
  selectHRDashboardYearData,
  selectHRStatus,
} from 'modules/HRPage/selectors';
import { selectUserCurrentCompanyMaxPeriod } from 'modules/loggedUserInfo/selectors';
import { HR_TABS } from 'const/HRPage';
import { HR_PAGE_TRANSLATIONS } from 'const/translations/HRPage';
import { DashboardIndicators } from 'components/DashboardIndicators';
// import { EXCO_SERVICES } from 'const/excoServices';

// ______________;

// import checkMark from 'assets/icons/checkmark-icon.svg';
// import { Spinner } from 'components/Spinner';
// import { handleInternalRedirect } from 'helpers/common';
// import StylesDesc from 'components/ServiceCard/ServiceCardDescription/ServiceCardDescription.module.css';

// _____________;
// import TEmpStyles from 'components/ServiceCard/ServiceCard.module.css';
import Styles from './DashboardsSection.module.css';

const { LINE, BAR } = CHARTS_VIEW_SWITCH_TYPES;

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { DASHBOARD_FIN, DASHBOARD_HR, EVP } = APP_MODULES;

const {
  ERROR_BUTTON_TEXT,
  ERROR_TEXT,
  ACCEDER_BUTTON_TEXT,
} = HR_PAGE_TRANSLATIONS;

// const {
//   BUTTONS_TEXT: { GET_ACCESS },
// } = HOMEPAGE_FORM_TRANSLATIONS;
// const { DISCOVER_SOLUTION } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const propTypes = {
  firstMonth: PropTypes.oneOf(MONTHS.map((month) => month.name)).isRequired,
  activeMonthName: PropTypes.oneOf(MONTHS.map((month) => month.name))
    .isRequired,
  activeDateViewOption: PropTypes.oneOf([YEAR, MONTH]).isRequired,
  activeYear: PropTypes.number.isRequired,
  chartViewActiveMode: PropTypes.oneOf([LINE, BAR]),
  userModules: PropTypes.array,
  treasuryAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      suffix: PropTypes.string,
    })
  ),
  treasuryActiveTabId: PropTypes.string,
  setTreasuryActiveTabId: PropTypes.func,
};

export const DashboardsSection = ({
  firstMonth,
  activeMonthName,
  activeDateViewOption,
  activeYear,
  chartViewActiveMode,
  userModules = [],
  treasuryAccounts,
  treasuryActiveTabId,
  setTreasuryActiveTabId,
}) => {
  // const dispatch = useDispatch();
  const updateDate = useSelector(selectTreasuryLastUpdatedDate);
  const turnoverLastUpdate = useSelector(selectTurnoverLastUpdatedDate);
  const treasuryYearData = useSelector(selectTreasuryDataForYear);
  const treasuryData = useSelector(selectTreasuryDataForCharts);
  const treasuryCumulDataForMonthsView = useMemo(
    () => fillEmptyDates(treasuryData, firstMonth, updateDate),
    [updateDate, treasuryData, firstMonth]
  );
  const treasuryChartData = useMemo(
    () =>
      activeDateViewOption === YEAR
        ? treasuryYearData
        : getActiveMonthChartData(
            treasuryCumulDataForMonthsView,
            activeMonthName
          ),
    [
      activeDateViewOption,
      activeMonthName,
      treasuryYearData,
      treasuryCumulDataForMonthsView,
    ]
  );

  const treasuryUpdatedData = useSelector(selectTreasuryLastUpdatedDate);

  const turnoverYearData = useSelector(selectTurnoverDataForYear);
  const turnoverData = useSelector(selectTurnoverDataForCharts);

  const turnoverChartData = useMemo(
    () =>
      activeDateViewOption === YEAR
        ? turnoverYearData
        : getActiveMonthChartData(turnoverData, activeMonthName),
    [activeDateViewOption, activeMonthName, turnoverYearData, turnoverData]
  );

  const treasuryCommentsForMonths = useSelector(selectTreasuryCommentsForChart);
  const treasuryCommentsForYear = useSelector(
    selectTreasuryCommentsForYearChart
  );
  const treasuryComments = useChartComments(
    treasuryCommentsForMonths,
    treasuryCommentsForYear,
    activeMonthName,
    activeDateViewOption
  );

  const turnoverCommentsForMonths = useSelector(selectTurnoverCommentsForChart);
  const turnoverCommentsForYear = useSelector(
    selectTurnoverCommentsForYearChart
  );
  const turnoverComments = useChartComments(
    turnoverCommentsForMonths,
    turnoverCommentsForYear,
    activeMonthName,
    activeDateViewOption
  );

  const turnoverDataFetchStatus = useSelector(selectTurnoverDataStatus);
  const turnoverCommentsFetchStatus = useSelector(selectCommentsStatus);
  const isTurnoverDataLoading = [
    turnoverDataFetchStatus,
    turnoverCommentsFetchStatus,
  ].some((status) => status === STATUS_LOADING);
  const isTurnoverError = [
    turnoverDataFetchStatus,
    turnoverCommentsFetchStatus,
  ].some((status) => status === STATUS_ERROR);

  const { isModalOpen, onCloseCommentsModal } = useCommentsModalCallbacks();

  const treasurySingleAccountStatus = useSelector(
    selectTreasurySingleAccountStatus
  );
  const treasuryDataFetchStatus = useSelector(selectTreasuryStatus);
  const commentsFetchStatus = useSelector(selectCommentsStatus);
  const isTreasuryDataLoading = [
    treasuryDataFetchStatus,
    commentsFetchStatus,
    treasurySingleAccountStatus,
  ].some((status) => status === STATUS_LOADING);
  const isTreasuryError = [
    treasuryDataFetchStatus,
    treasurySingleAccountStatus,
    commentsFetchStatus,
  ].some((status) => status === STATUS_ERROR);

  const HRDashboardData = useSelector(selectHRDashboardYearData);

  const HRTabs = useMemo(() => HR_TABS, []);
  const [activeHRTabId, setActiveHRTabId] = useState('');
  useEffect(() => {
    setActiveHRTabId(HRTabs[0]?.id || '');
  }, [HRTabs, setActiveHRTabId]);

  const HRChartData = useMemo(
    () =>
      HRDashboardData.find(({ tabId }) => tabId === activeHRTabId)?.tabValues ||
      [],
    [activeHRTabId, HRDashboardData]
  );

  const maxPeriod = useSelector(selectUserCurrentCompanyMaxPeriod);
  const maxPeriodValues = useMemo(
    () =>
      getMaxPeriodValuesForEachTabFromDashboardData(
        HRDashboardData,
        firstMonth,
        maxPeriod
      ),
    [HRDashboardData, firstMonth, maxPeriod]
  );

  const HRTabsWithValuesInMaxPeriod = useMemo(
    () =>
      HRTabs.map(({ id, ...rest }) => ({
        ...rest,
        value: maxPeriodValues[id],
        id,
      })),
    [maxPeriodValues, HRTabs]
  );

  const HRCommentsForYear = useSelector(selectHRCommentsForYearChart);

  const HRRequestStatus = useSelector(selectHRStatus);
  const isHRLoading = [HRRequestStatus, commentsFetchStatus].some(
    (status) => status === STATUS_LOADING || status === STATUS_NOT_REQUESTED
  );
  const isHRError =
    [HRRequestStatus, commentsFetchStatus].some(
      (status) => status === STATUS_ERROR
    ) || !userModules.includes(EVP);

  // __________

  // const isLoading = useSelector(() => {});

  // const isActive =
  //   EXCO_SERVICES[0].MODULES_FOR_ACCESS.some((accessModule) =>
  //     userModules.includes(accessModule)
  //   ) || EXCO_SERVICES[0].MODULES_FOR_ACCESS.length === 0;

  // const handleActionDispatch = useCallback(
  //   (actionToDispatch) => {
  //     dispatch(
  //       actionToDispatch({
  //         onError: (error) => message.error(error),
  //       })
  //     );
  //   },
  //   [dispatch]
  // );
  // const shouldDispatchActionOnClick = !!EXCO_SERVICES[0].ACTION;

  // const onCtaClick = useCallback(() => {
  //   const url = isActive
  //     ? EXCO_SERVICES[0].LINK_WITH_ACCESS
  //     : EXCO_SERVICES[0].SERVICE_LP_LINK;

  //   if (!url && !EXCO_SERVICES[0].ACTION) {
  //     return;
  //   }

  //   (isActive && shouldDispatchActionOnClick
  //     ? handleActionDispatch
  //     : handleInternalRedirect)(url || EXCO_SERVICES[0].ACTION);
  // }, [handleActionDispatch, isActive, shouldDispatchActionOnClick]);
  // __________

  return (
    <section className={MainStyles.container}>
      <CommentsModal isOpen={isModalOpen} closeModal={onCloseCommentsModal} />
      {userModules.includes(DASHBOARD_FIN) && (
        <div className={Styles.turnoverDashboardWrapper}>
          <HomePageDashboard
            firstMonth={firstMonth}
            activeMonthName={activeMonthName}
            activeDateViewOption={activeDateViewOption}
            activeYear={activeYear}
            chartData={turnoverChartData}
            chartViewActiveMode={chartViewActiveMode}
            addTurnoverSummary
            dashboardName={DASHBOARD_NAMES.TURNOVER}
            className={Styles.turnoverDashboard}
            linkUrl={ROUTES.TURNOVER}
            comments={turnoverComments}
            isLoading={isTurnoverDataLoading}
            isError={isTurnoverError}
            updatedDate={turnoverLastUpdate}
          />
          <DashboardIndicators userModules={userModules} />
          {/* <div className={Styles.turnoverForecastWrapper}>
            <span className={TEmpStyles.title} style={{ textAlign: 'left' }}>
              FACT : Solution de facturation pour être conforme à la
              règlementation
            </span>
            <div
              className={StylesDesc.functionalItem}
              style={{ marginTop: '10px' }}
            >
              <img src={checkMark} alt="check-mark" />
              <div className={StylesDesc.LinkAndTextWrapper}>
                <span style={{ textAlign: 'left' }}>
                  Transformez facilement vos devis, en bons de commande, bons de
                  livraison ou en facture
                </span>
              </div>
            </div>
            <div className={StylesDesc.functionalItem}>
              <img src={checkMark} alt="check-mark" />
              <div className={StylesDesc.LinkAndTextWrapper}>
                <span style={{ textAlign: 'left' }}>
                  Visualisez et relancez rapidement vos factures impayées.
                </span>
              </div>
            </div>
            <div className={StylesDesc.functionalItem}>
              <img src={checkMark} alt="check-mark" />
              <div className={StylesDesc.LinkAndTextWrapper}>
                <span style={{ textAlign: 'left' }}>
                  Contrôlez le solde de vos clients et prévoyez les règlements à
                  venir pour une meilleure gestion de votre trésorerie.
                </span>
              </div>
            </div>
            {isActive ? (
              <Button
                variant="success"
                disabled={isLoading}
                onClick={onCtaClick}
              >
                {isLoading ? (
                  <Spinner className={Styles.accessButtonSpinner} />
                ) : (
                  <span>{GET_ACCESS}</span>
                )}
              </Button>
            ) : (
              <NavLink
                to={ROUTES.SALES_LANDING}
                className={Styles.forecastButtonLink}
                style={{ marginTop: '0px' }}
              >
                <Button variant="secondary" sizeAuto>
                  {DISCOVER_SOLUTION}
                </Button>
              </NavLink>
            )}
          </div> */}
        </div>
      )}
      {userModules.includes(DASHBOARD_FIN) && (
        <div className={Styles.treasuryDashboardWrapper}>
          <HomePageDashboard
            firstMonth={firstMonth}
            activeMonthName={activeMonthName}
            activeDateViewOption={activeDateViewOption}
            activeYear={activeYear}
            chartData={treasuryChartData}
            chartViewActiveMode={chartViewActiveMode}
            tabItems={treasuryAccounts}
            activeTabId={treasuryActiveTabId}
            setActiveTabId={setTreasuryActiveTabId}
            dashboardName={DASHBOARD_NAMES.TREASURY}
            chartBlockClassName={Styles.treasuryChart}
            className={Styles.treasuryDashboard}
            linkUrl={ROUTES.TREASURY}
            updatedDate={treasuryUpdatedData}
            comments={treasuryComments}
            isLoading={isTreasuryDataLoading}
            isSingleAccountLoading={
              treasurySingleAccountStatus === STATUS_LOADING
            }
            isError={isTreasuryError}
          />
        </div>
      )}
      {userModules.includes(DASHBOARD_HR) && (
        <div className={Styles.treasuryDashboardWrapper}>
          <HomePageDashboard
            firstMonth={firstMonth}
            activeMonthName={activeMonthName}
            activeYear={activeYear}
            chartData={HRChartData}
            chartViewActiveMode={chartViewActiveMode}
            tabItems={HRTabsWithValuesInMaxPeriod}
            activeTabId={activeHRTabId}
            setActiveTabId={setActiveHRTabId}
            dashboardName={DASHBOARD_NAMES.HR}
            chartBlockClassName={Styles.treasuryChart}
            className={Styles.treasuryDashboard}
            linkUrl={ROUTES.HR}
            disableMainLinkCondition={!userModules.includes(EVP)}
            hasAccederButton
            accederButtonLink={ROUTES.SALARIES_MAIN_PAGE}
            accederButtonLabel={ACCEDER_BUTTON_TEXT}
            isLoading={isHRLoading}
            isError={isHRError}
            errorMessage={ERROR_TEXT}
            hasErrorButton
            errorButtonText={ERROR_BUTTON_TEXT}
            errorLink={ROUTES.CONTACTS}
            comments={HRCommentsForYear}
          />
        </div>
      )}
    </section>
  );
};

DashboardsSection.propTypes = propTypes;
