import { REQUEST_HELP_TRANSLATIONS } from '../translations/RequestHelpModal';
const { TOPICS } = REQUEST_HELP_TRANSLATIONS;

export const REQUEST_MODAL_TOPICS = [
  {
    value: 0,
    label: TOPICS.GENERAL,
  },
  {
    value: 1,
    label: TOPICS.FACT,
  },
  {
    value: 2,
    label: TOPICS.PAY,
  },
  {
    value: 3,
    label: TOPICS.EMPLOYEES,
  },
  {
    value: 4,
    label: TOPICS.SEND_DOCUMENTS,
  },
  {
    value: 5,
    label: TOPICS.SHAREPOINT,
  },
  {
    value: 6,
    label: TOPICS.SEARCH_INVOICES,
  },
  {
    value: 7,
    label: TOPICS.OTHER,
  },
];
