import { fromJS } from 'immutable';
import {
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from '../../../../const';
import {
  GET_EMPLOYEE_REWARDS,
  INIT_REWARDS_TABLE_DATA,
  SET_EMPLOYEE_REWARD_ITEM,
  SET_EMPLOYEE_REWARDS,
  SET_EMPLOYEE_REWARDS_SEARCH,
  SET_EMPLOYEE_REWARDS_STATUS,
} from '../actions';

const initialState = fromJS({
  employeeRewards: [],
  employeeSearch: '',
  status: STATUS_NOT_REQUESTED,
});

export const rewardsTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_REWARDS:
      return state.set('status', STATUS_LOADING);
    case SET_EMPLOYEE_REWARDS_STATUS:
      return state.set('status', action.payload);
    case SET_EMPLOYEE_REWARDS_SEARCH:
      return state.set('employeeSearch', action.payload);
    case SET_EMPLOYEE_REWARDS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('employeeRewards', action.payload);
    case SET_EMPLOYEE_REWARD_ITEM:
      return state.updateIn(
        ['employeeRewards', 'data', 'employeeRewards'],
        (data) =>
          data.map((item) => {
            const { payload } = action;
            if (item.employee.id === payload.employeeId) {
              const isEmployeeReward = item.rewards.some(
                ({ companyRewardId }) =>
                  companyRewardId === payload.companyRewardId
              );
              return {
                ...item,
                rewards: isEmployeeReward
                  ? item.rewards.map((reward) =>
                      reward.companyRewardId === payload.companyRewardId
                        ? payload
                        : reward
                    )
                  : [...item.rewards, payload],
              };
            }
            return item;
          })
      );
    case INIT_REWARDS_TABLE_DATA:
      return initialState;
    default:
      return state;
  }
};
