import React, { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import MainStyles from 'assets/styles/main.module.css';
import { HEADER_BUTTONS, SALARY_HEADER_NAV } from 'const/SalaryHeader';
import { ROUTES } from 'const';
import { SALARY_HEADER_LABELS } from 'const/translations';
import { InfoModal } from 'containers/Salary/MainPage/InfoModal/InfoModal';
import { openModal } from 'modules/ModalContainer/actions';
import { MODAL_TYPES } from 'const/ModalContainer';
import { Button } from 'components/ui';
import { usePathOverlapWith } from 'hooks/usePathOverlapWith';
import { SalaryBreadcrumbs } from '../SalaryBreadcrumbs';
import HeaderStyles from './SalaryHeader.module.css';
import { SendInfoButton } from '../SendInfoButton';

const { CONTACT_HR } = HEADER_BUTTONS;

export const SalaryHeader = memo(() => {
  const dispatch = useDispatch();

  const isHeaderVisible = usePathOverlapWith([
    ROUTES.SALARIES_MAIN_PAGE,
    ROUTES.EMPLOYEE_REGISTER_TITLE,
  ]);

  const handleShowContactHRModal = useCallback(() => {
    dispatch(openModal({ type: MODAL_TYPES.contactHR }));
  }, [dispatch]);

  if (!isHeaderVisible) {
    return null;
  }

  return (
    <>
      <header
        className={clsx(
          MainStyles.headerMenu,
          MainStyles.container,
          HeaderStyles.header
        )}
      >
        <Menu
          mode="horizontal"
          role="navigation"
          className={clsx(MainStyles.headerMenu, HeaderStyles.headerMenu)}
        >
          {SALARY_HEADER_NAV.map((item) => (
            <Menu.Item key={item.id} className={HeaderStyles.menuItem}>
              <NavLink
                exact
                to={item.path}
                activeClassName={HeaderStyles.menuItemSelected}
              >
                {item.label}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
        <Button
          className={HeaderStyles.btn}
          sizeAuto
          big
          variant="secondary"
          name={CONTACT_HR}
          onClick={handleShowContactHRModal}
        >
          {SALARY_HEADER_LABELS.CONTACT_HR_SHORT}
        </Button>
        <SendInfoButton />
      </header>
      <SalaryBreadcrumbs />
      <InfoModal />
    </>
  );
});
