import { useDispatch, useSelector } from 'react-redux';
import { selectIsCommentsModalOpen } from 'modules/commentsModal/selectors';
import { useCallback } from 'react';
import {
  closeCommentsModal,
  openCommentsModal,
} from 'modules/commentsModal/actions';

export const useCommentsModalCallbacks = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectIsCommentsModalOpen);

  const onOpenCommentsModal = useCallback(
    ({ month, day, chartType, dateMode }) => {
      dispatch(openCommentsModal({ month, day, chartType, dateMode }));
    },
    [dispatch]
  );

  const onCloseCommentsModal = useCallback(() => {
    dispatch(closeCommentsModal());
  }, [dispatch]);
  return { isModalOpen, onOpenCommentsModal, onCloseCommentsModal };
};
