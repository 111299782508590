import { getType, getActionCreator } from 'utils';
const prefix = '@@app/treasuryPage';

export const SET_TREASURY_SINGLE_ACCOUNT_STATUS_LOADING = getType(
  prefix,
  'SET_TREASURY_SINGLE_ACCOUNT_STATUS_LOADING'
);
export const setTreausrySingleAccountStatusLoading = getActionCreator(
  SET_TREASURY_SINGLE_ACCOUNT_STATUS_LOADING
);

export const GET_TREASURY_DATA_REQUEST = getType(
  prefix,
  'GET_TREASURY_DATA_REQUEST'
);
export const getTreasuryDataRequest = getActionCreator(
  GET_TREASURY_DATA_REQUEST
);

export const GET_TREASURY_DATA_SUCCESS = getType(
  prefix,
  'GET_TREASURY_DATA_SUCCESS'
);
export const getTreasuryDataSuccess = getActionCreator(
  GET_TREASURY_DATA_SUCCESS
);

export const GET_TREASURY_DATA_ERROR = getType(
  prefix,
  'GET_TREASURY_DATA_ERROR'
);
export const getTreasuryDataError = getActionCreator(GET_TREASURY_DATA_ERROR);

export const GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST = getType(
  prefix,
  'GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST'
);
export const getTreasurySingleAccountDataRequest = getActionCreator(
  GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST
);

export const GET_TREASURY_SINGLE_ACCOUNT_DATA_SUCCESS = getType(
  prefix,
  'GET_TREASURY_SINGLE_ACCOUNT_DATA_SUCCESS'
);
export const getTreasurySingleAccountDataSuccess = getActionCreator(
  GET_TREASURY_SINGLE_ACCOUNT_DATA_SUCCESS
);

export const GET_TREASURY_SINGLE_ACCOUNT_DATA_ERROR = getType(
  prefix,
  'GET_TREASURY_SINGLE_ACCOUNT_DATA_ERROR'
);
export const getTreasurySingleAccountDataError = getActionCreator(
  GET_TREASURY_SINGLE_ACCOUNT_DATA_ERROR
);
