import { takeLatest, put, call } from 'redux-saga/effects';
import {
  ADD_WAIBI_TO_CART,
  // addPackagesToCart,
  GET_PACKAGES_COSTS_REQUEST,
  getPackagesCostsError,
  getPackagesCostsSuccess,
  SUBSCRIBE_TO_PACKAGES_REQUEST,
  subscribeToPackagesSuccess,
} from 'modules/cart/actions';
import {
  getPackagesCostsService,
  subscribeToPackagesService,
  subscribeToWaibiService,
} from 'modules/cart/services';
import { PACKAGES_NAMES, getModulesForPurchase } from 'const/packages';
import { getUniqueSimpleArray } from 'modules/cart/helpers';
import { openSuccessNotification } from 'helpers/notifications';
import { LANDING_PAGE_TRANSLATIONS } from 'const/translations/ServiceLanding';

const {
  WAIBI: { CONSULTANT_CONTACT_SUCCESS },
} = LANDING_PAGE_TRANSLATIONS;

function* subscribeToPackagesWorker({
  payload: { currentUsers = {}, deletedUsers = [], cartPackages },
}) {
  try {
    const modulesToSubscribe = cartPackages.reduce((acc, pack) => {
      const MODULES_OF_PACKAGE = getModulesForPurchase(true)[pack];

      if (!MODULES_OF_PACKAGE) {
        return acc;
      }

      return getUniqueSimpleArray([...acc, ...MODULES_OF_PACKAGE]);
    }, []);
    const mappedDeletedUsers = deletedUsers.map(({ email }) => ({
      name: 'N/A',
      surname: 'N/A',
      email,
      modules: [],
      position: 'supprimé',
    }));

    const mappedCurrentUsers = Object.values(currentUsers).map(
      ({ tableModules, email, position, name, surname }) => ({
        name,
        surname,
        email,
        position,
        modules: tableModules,
      })
    );
    const additionalUsers = [...mappedDeletedUsers, ...mappedCurrentUsers];
    yield call(subscribeToPackagesService, {
      modules: modulesToSubscribe,
      additionalUsers,
    });
    yield put(subscribeToPackagesSuccess());
  } catch (error) {
    console.warn(error);
  }
}

function* addWaibiToCartWorker() {
  // { payload: { packageName } }
  try {
    yield call(subscribeToWaibiService);
    yield call(openSuccessNotification, {
      message: CONSULTANT_CONTACT_SUCCESS,
    });
    // yield put(addPackagesToCart({ packageName }));
  } catch (error) {
    console.warn(error);
  }
}

function* getPackagesCostsWorker() {
  try {
    const costs = yield call(getPackagesCostsService);
    const mappedCostsToPackagesNames = Object.entries(costs).reduce(
      (acc, [BOname, cost]) => {
        const packageName = PACKAGES_NAMES[BOname];
        if (packageName) {
          acc[packageName] = cost;
        }
        return acc;
      },
      {}
    );
    yield put(getPackagesCostsSuccess({ costs: mappedCostsToPackagesNames }));
  } catch (error) {
    yield put(getPackagesCostsError());
    console.warn(error);
  }
}

export function* cartMainSaga() {
  yield takeLatest(SUBSCRIBE_TO_PACKAGES_REQUEST, subscribeToPackagesWorker);
  yield takeLatest(ADD_WAIBI_TO_CART, addWaibiToCartWorker);
  yield takeLatest(GET_PACKAGES_COSTS_REQUEST, getPackagesCostsWorker);
}
