import { useState, useEffect } from 'react';
import { chunkArray } from '../../CompanySettings/utils';

/**
 * Hook divides data into parts and gives methods for pagination
 * @param { Array } data - array of data objects
 * @param { Number } countOnPage - count of item on the page
 *
 * /**
 * @typedef {Object} HookReturnObject
 * @property {Array} sortedData - part of initial data to current page
 * @property {Function} setPage - set page number
 * @property {Number} page - current page number
 * @property {Number} currentDataLength - length of data, used for display conditions
 *
 * @return {HookReturnObject} - data and methods to use usePaginationHook
 */
export const usePaginationHook = (data, countOnPage = 10) => {
  const [sortedData, setSortedData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [splitedData, setSplitedData] = useState([]);
  const [currentDataLength, setCurrentDataLength] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!data) {
      setInitialData([]);
    } else {
      setCurrentDataLength(data.length);
      setInitialData(data);
    }
  }, [data]);

  useEffect(() => {
    setSplitedData(chunkArray(initialData, countOnPage));
  }, [countOnPage, initialData]);

  useEffect(() => {
    setSortedData(splitedData[page - 1] || []);
  }, [splitedData, page]);

  return {
    sortedData,
    setPage,
    page,
    currentDataLength,
  };
};
