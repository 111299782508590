import { useState, useEffect } from 'react';
import { chunkArray } from '../utils';

/**
 * Hook divides data into parts and gives methods for pagination and search in items
 * @param { Array } data - array of data objects
 * @param { Number } countOnPage - count of item on the page
 *
 * /**
 * @typedef {Object} HookReturnObject
 * @property {Array} sortedData - part of initial data to current page
 * @property {Function} setPage - set page number
 * @property {Number} page - current page number
 * @property {String} search - string to search in data by name
 * @property {Function} setSearch - set search string
 * @property {Number} currentDataLength - length of data, used for display conditions
 *
 * @return {HookReturnObject} - data and methods to use useSortHook
 */
export const useSortHook = (data, countOnPage = 10) => {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [splitedData, setSplitedData] = useState([]);
  const [currentDataLength, setCurrentDataLength] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!data) {
      setInitialData([]);
    } else {
      setCurrentDataLength(data.length);
      setInitialData(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
    }
  }, [data]);

  useEffect(() => {
    setSplitedData(chunkArray(initialData, countOnPage));
  }, [countOnPage, initialData]);

  useEffect(() => {
    setSortedData(splitedData[page - 1]);
  }, [splitedData, page]);

  useEffect(() => {
    if (initialData.length) {
      const filteredData = initialData.filter((item) =>
        search.length
          ? item.firstName.toLowerCase().includes(search.toLowerCase()) ||
            item.lastName.toLowerCase().includes(search.toLowerCase())
          : true
      );

      setPage(1);
      setCurrentDataLength(filteredData.length);
      setSplitedData(chunkArray(filteredData, countOnPage));
    }
  }, [countOnPage, initialData, search]);

  return {
    sortedData,
    search,
    setSearch,
    setPage,
    page,
    currentDataLength,
  };
};
