import React from 'react';
import moment from 'moment';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { DATE_FORMAT } from 'const';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import {
  OpenInNewTabAction,
  DownloadAction,
  ValidateInvoiceAction,
  PayInvoiceAction,
  SendByEmailAction,
  AddCommentAction,
} from 'components/InvoiceSearch/SearchResultsTable/actions';

import { InvoiceTableActionsContext } from 'contexts/InvoiceTableActionsContext';

const {
  TABLE: { COLUMNS_HEADERS },
} = INVOICE_SEARCH_TRANSLATIONS;

/**
 * Allows table sorts to change cyclically like:
 * currentSort -> -currentSort -> null -> defaultSort
 * @param {string} currentSort Current sort
 * @param {string} defaultSort Default sort
 * Usually you want your defaultSort to be equal
 * to your currentSort at the first step
 * @returns Next sort (key with its direction)
 */
export const getNextSortOrder = (currentSort, defaultSort) => {
  if (currentSort === null) {
    return defaultSort;
  }

  if (defaultSort.at(0) === '-') {
    if (currentSort.at(0) === '-') {
      return currentSort.slice(1);
    }

    return null;
  }

  if (currentSort.at(0) === '-') {
    return null;
  }

  return `-${currentSort}`;
};

export const getResultsTableColumns = () => [
  {
    title: COLUMNS_HEADERS.DATE,
    dataIndex: 'date',
    key: 'date',
    width: '110px',
    render: (rawDate) => moment(rawDate).format(DATE_FORMAT),
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: COLUMNS_HEADERS.INVOICE_NUMBER,
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    width: '130px',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: COLUMNS_HEADERS.DUE_DATE,
    dataIndex: 'dueDate',
    key: 'dueDate',
    width: '120px',
    render: (rawDate) => moment(rawDate).format(DATE_FORMAT),
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: COLUMNS_HEADERS.PROVIDER,
    dataIndex: 'provider',
    key: 'provider',
    width: '160px',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: COLUMNS_HEADERS.INVOICE_TYPE,
    dataIndex: 'invoiceType',
    key: 'invoiceType',
    width: '70px',
  },
  {
    title: COLUMNS_HEADERS.AMOUNT,
    dataIndex: 'amount',
    key: 'amount',
    render: getProperLookingAmount,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    align: 'right',
    width: '94px',
  },
  {
    title: COLUMNS_HEADERS.VAT,
    dataIndex: 'vat',
    key: 'vat',
    render: getProperLookingAmount,
    align: 'right',
    width: '90px',
  },
  {
    title: COLUMNS_HEADERS.AMOUNT_WITH_VAT,
    dataIndex: 'amountWithVat',
    key: 'amountWithVat',
    render: getProperLookingAmount,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    align: 'right',
    width: '94px',
  },
  {
    title: COLUMNS_HEADERS.ACTIONS,
    dataIndex: 'path',
    key: 'actions',
    align: 'center',
    render: (path, invoiceData) => (
      <InvoiceTableActionsContext.Provider value={invoiceData}>
        <span className={Styles.noWrapCell}>
          <OpenInNewTabAction filePath={path} />
          <DownloadAction />
          <ValidateInvoiceAction />
          <PayInvoiceAction />
          <SendByEmailAction />
          <AddCommentAction />
        </span>
      </InvoiceTableActionsContext.Provider>
    ),
  },
];
