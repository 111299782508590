import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Styles from './PillarLoader.module.css';

const propTypes = {
  className: PropTypes.string,
  colored: PropTypes.bool,
};

export const PillarLoader = ({ className, colored = false }) => (
  <div
    className={clsx(Styles.wrapper, className, {
      [Styles.colored]: colored,
    })}
  >
    <div className={Styles.centralPillar} />
  </div>
);

PillarLoader.propTypes = propTypes;
