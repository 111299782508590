import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import arrowUp from '@iconify/icons-ant-design/arrow-up';
import {
  getDataForTurnoverSummary,
  getPercentageDifference,
} from 'helpers/turnoverSummary';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { MONTHS } from 'const/ui';
import Styles from './TurnoverSummary.module.css';

const {
  FISCAL_YEAR_ACCUMULATION,
  FISCAL_MONTH_ACCUMULATION,
} = HOMEPAGE_FORM_TRANSLATIONS;

const mapTurnoverData = (items) =>
  items.map(({ year, value }, index, array) => {
    const stringValue = getProperLookingAmount(value);
    const isNotLastArrayItem = index < array.length - 1;
    const { isNegative, percentageDiff } = isNotLastArrayItem
      ? getPercentageDifference(value, array[index + 1].value)
      : {};
    return (
      <div key={year} className={Styles.valueWrapper}>
        <div
          className={clsx(Styles.value, {
            [Styles.current]: index === 0,
            [Styles.prev]: index === 1,
            [Styles.prevPrev]: index === 2,
          })}
        >
          <span>{`${year}    -`}</span>
          <span className={Styles.valueNumber}>{stringValue} €</span>
        </div>
        {isNotLastArrayItem && (
          <div
            className={clsx(Styles.differenceWrapper, {
              [Styles.negative]: isNegative,
              [Styles.positive]: !isNegative,
            })}
          >
            <div className={Styles.arrow}>
              <Icon icon={arrowUp} />
            </div>
            <div className={Styles.diffValue}>{percentageDiff}%</div>
          </div>
        )}
      </div>
    );
  });

const propTypes = {
  chartData: PropTypes.array,
  activeMonthName: PropTypes.string.isRequired,
  activeDateViewOption: PropTypes.string.isRequired,
  firstMonth: PropTypes.string.isRequired,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  updatedDate: PropTypes.string,
};

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

export const TurnoverSummary = React.memo(
  ({
    chartData,
    activeMonthName,
    activeDateViewOption,
    firstMonth,
    className,
    vertical,
    updatedDate,
  }) => {
    const lastUpdateMonthIndex = useMemo(
      () => new Date(updatedDate).getMonth() || 0,
      [updatedDate]
    );

    const lastUpdateMonthNameLabel = useMemo(
      () => MONTHS[lastUpdateMonthIndex].label,
      [lastUpdateMonthIndex]
    );

    const activeMonthnameLabel = useMemo(
      () => MONTHS[getMonthIndexFromName(activeMonthName)].label,
      [activeMonthName]
    );

    const isChartDateModeAnnual = useMemo(() => activeDateViewOption === YEAR, [
      activeDateViewOption,
    ]);

    const defaultDay = 1;

    /**
     * Calculates the last index of chart`s dataset of currently going financial year
     *
     * @param {Array} chartData - The chart data used to calculate the index in current month.
     * @returns {number} The last index of chart`s dataset.
     */
    const lastDayOfCurrentMonthData = useMemo(() => {
      if (chartData?.length === 0) return defaultDay;

      const currentYearChartDataReversed = [...chartData[0].values].reverse();
      const notNullElementIndexFromTail = currentYearChartDataReversed.findIndex(
        (value) => value !== null
      );

      return notNullElementIndexFromTail > -1
        ? chartData[0].values.length - notNullElementIndexFromTail
        : chartData[0].values.length;
    }, [chartData]);

    const monthData = useMemo(
      () =>
        getDataForTurnoverSummary(
          activeMonthName,
          firstMonth,
          isChartDateModeAnnual,
          lastUpdateMonthIndex,
          chartData,
          'single',
          lastDayOfCurrentMonthData
        ),
      [
        activeMonthName,
        chartData,
        firstMonth,
        isChartDateModeAnnual,
        lastUpdateMonthIndex,
        lastDayOfCurrentMonthData,
      ]
    );

    const yearData = useMemo(
      () =>
        getDataForTurnoverSummary(
          activeMonthName,
          firstMonth,
          isChartDateModeAnnual,
          lastUpdateMonthIndex,
          chartData,
          'summary',
          lastDayOfCurrentMonthData
        ),
      [
        activeMonthName,
        chartData,
        firstMonth,
        isChartDateModeAnnual,
        lastUpdateMonthIndex,
        lastDayOfCurrentMonthData,
      ]
    );

    return (
      <div
        className={clsx(Styles.wrapper, className, {
          [Styles.vertical]: vertical,
        })}
      >
        <div className={Styles.monthDataWrapper}>
          <div className={Styles.title}>
            {isChartDateModeAnnual
              ? lastUpdateMonthNameLabel
              : `${lastDayOfCurrentMonthData} ${activeMonthnameLabel}`}
          </div>
          {mapTurnoverData(monthData)}
        </div>
        <div className={Styles.yearDataWrapper}>
          <div className={Styles.title}>
            {isChartDateModeAnnual
              ? FISCAL_YEAR_ACCUMULATION.replace(
                  '{{LAST_UPDATE_MONTH}}',
                  lastUpdateMonthNameLabel
                )
              : FISCAL_MONTH_ACCUMULATION.replace(
                  '{{MONTH}}',
                  activeMonthnameLabel
                )}
          </div>
          {mapTurnoverData(yearData)}
        </div>
      </div>
    );
  }
);

TurnoverSummary.propTypes = propTypes;
