import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as AntBreadcrumb, Menu, Icon } from 'antd';

import Styles from './Breadcrumb.module.css';

export const Breadcrumb = ({ currentPath, goToParent }) => {
  const displayMenu = currentPath.length > 3;
  return (
    <AntBreadcrumb separator=">" className={Styles.breadcrumb}>
      {displayMenu ? (
        <>
          <AntBreadcrumb.Item onClick={() => goToParent(0)}>
            <Icon type="folder" className={Styles.icon} />
            <span>{currentPath[0]}</span>
          </AntBreadcrumb.Item>
          <AntBreadcrumb.Item
            onClick={() => goToParent(currentPath.length - 2)}
            overlay={
              <Menu className={Styles.dropdownMenu}>
                {currentPath
                  .slice(1, currentPath.length - 2)
                  .map((text, index) => (
                    <Menu.Item key={text} onClick={() => goToParent(index + 1)}>
                      <Icon type="folder" className={Styles.icon} />
                      <span>{text}</span>
                    </Menu.Item>
                  ))}
              </Menu>
            }
          >
            <span>...</span>
          </AntBreadcrumb.Item>
          <AntBreadcrumb.Item
            onClick={() => goToParent(currentPath.length - 2)}
          >
            <Icon type="folder" className={Styles.icon} />
            <span>{currentPath[currentPath.length - 2]}</span>
          </AntBreadcrumb.Item>
          <AntBreadcrumb.Item
            onClick={() => goToParent(currentPath.length - 1)}
          >
            <Icon type="folder" className={Styles.icon} />
            <span>{currentPath[currentPath.length - 1]}</span>
          </AntBreadcrumb.Item>
        </>
      ) : (
        currentPath.map((text, index) => (
          <AntBreadcrumb.Item key={text} onClick={() => goToParent(index)}>
            <Icon type="folder" className={Styles.icon} />
            <span>{text}</span>
          </AntBreadcrumb.Item>
        ))
      )}
    </AntBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  currentPath: PropTypes.arrayOf(PropTypes.string),
  goToParent: PropTypes.func,
};
