import { request } from 'utils/request';

export const getBookmarksRequest = () => request.get('/bookmarks');

export const setBookmarkRequest = (title, url) =>
  request.post('/bookmarks', {
    title,
    url,
  });

export const editBookmarkRequest = (id, title, url) =>
  request.put(`/bookmarks/${id}`, {
    title,
    url,
  });

export const deleteBookmarkRequest = (id) => request.delete(`/bookmarks/${id}`);
