import React from 'react';
import { Icon } from 'antd';

const CalendarSvg = () => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 17.2188C0 18.2021 0.815848 19 1.82143 19H15.1786C16.1842 19 17 18.2021 17 17.2188V7.125H0V17.2188ZM12.1429 9.94531C12.1429 9.70039 12.3478 9.5 12.5982 9.5H14.1161C14.3665 9.5 14.5714 9.70039 14.5714 9.94531V11.4297C14.5714 11.6746 14.3665 11.875 14.1161 11.875H12.5982C12.3478 11.875 12.1429 11.6746 12.1429 11.4297V9.94531ZM12.1429 14.6953C12.1429 14.4504 12.3478 14.25 12.5982 14.25H14.1161C14.3665 14.25 14.5714 14.4504 14.5714 14.6953V16.1797C14.5714 16.4246 14.3665 16.625 14.1161 16.625H12.5982C12.3478 16.625 12.1429 16.4246 12.1429 16.1797V14.6953ZM7.28571 9.94531C7.28571 9.70039 7.49062 9.5 7.74107 9.5H9.25893C9.50938 9.5 9.71429 9.70039 9.71429 9.94531V11.4297C9.71429 11.6746 9.50938 11.875 9.25893 11.875H7.74107C7.49062 11.875 7.28571 11.6746 7.28571 11.4297V9.94531ZM7.28571 14.6953C7.28571 14.4504 7.49062 14.25 7.74107 14.25H9.25893C9.50938 14.25 9.71429 14.4504 9.71429 14.6953V16.1797C9.71429 16.4246 9.50938 16.625 9.25893 16.625H7.74107C7.49062 16.625 7.28571 16.4246 7.28571 16.1797V14.6953ZM2.42857 9.94531C2.42857 9.70039 2.63348 9.5 2.88393 9.5H4.40179C4.65223 9.5 4.85714 9.70039 4.85714 9.94531V11.4297C4.85714 11.6746 4.65223 11.875 4.40179 11.875H2.88393C2.63348 11.875 2.42857 11.6746 2.42857 11.4297V9.94531ZM2.42857 14.6953C2.42857 14.4504 2.63348 14.25 2.88393 14.25H4.40179C4.65223 14.25 4.85714 14.4504 4.85714 14.6953V16.1797C4.85714 16.4246 4.65223 16.625 4.40179 16.625H2.88393C2.63348 16.625 2.42857 16.4246 2.42857 16.1797V14.6953ZM15.1786 2.375H13.3571V0.59375C13.3571 0.267188 13.0839 0 12.75 0H11.5357C11.2018 0 10.9286 0.267188 10.9286 0.59375V2.375H6.07143V0.59375C6.07143 0.267188 5.79821 0 5.46429 0H4.25C3.91607 0 3.64286 0.267188 3.64286 0.59375V2.375H1.82143C0.815848 2.375 0 3.17285 0 4.15625V5.9375H17V4.15625C17 3.17285 16.1842 2.375 15.1786 2.375Z" />
  </svg>
);

export const CalendarIcon = (props) => (
  <Icon component={CalendarSvg} {...props} />
);
