import { object as yupObject, string as yupString, bool as yupBool } from 'yup';

export const validationSchema = () =>
  yupObject().shape({
    statusEmployee: yupString().required('Field cannot be empty'),
    isEmployerRelative: yupBool(),
    relationshipParentWithEmployerDenote: yupString().when(
      'isEmployerRelative',
      {
        is: true,
        then: yupString().required('Field cannot be empty'),
      }
    ),
    isHandicapped: yupBool(),
  });
