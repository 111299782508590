import atJs from 'core-js/stable/array/at';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { App } from 'containers/App';
import { ConfigProvider } from 'antd';
import frFr from 'antd/lib/locale-provider/fr_FR';
import './index.css';
import './assets/styles/variables.css';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { history } from 'routing/history';
import { configureStore } from './configureStore';

// polyfills
// eslint-disable-next-line no-unused-expressions
atJs;

// Create redux store with history
const initialState = fromJS({});
const store = configureStore(initialState);

ReactDOM.render(
  <ConfigProvider locale={frFr}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);
