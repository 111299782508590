export const UPLOAD_DOCUMENT_TRANSLATIONS = {
  TITLE: 'Envoi de documents',
  DROPDOWN_AREA: {
    TITLE: 'Faites glisser ou cliquez pour télécharger vos documents',
    DESCRIPTION_10MO: 'maximum 10 documents, taille maximale du lot : 10 Mo',
    DESCRIPTION_25MO: 'maximum 20 documents, taille maximale du lot : 25 Mo',
  },
  UPLOAD_MOBILE: {
    BUTTON: 'Cliquez pour télécharger vos fichiers',
    DESCRIPTION: 'maximum 20 documents, taille maximale du lot : 25 Mo',
  },
  FILES_LIST: {
    TOTAL_SINGLE: '1 document est prêt à être envoyé',
    TOTAL_MULTIPLE: '{{AMOUNT}} documents sont prêts à être envoyés',
    REMOVE_ALL: 'Supprimer tout',
    EMPTY: "Aucun document n'est encore téléchargé",
    TOOLTIP_TEXT: {
      COUNT_LIMIT_ERROR_MESSAGE_10:
        "Impossible de télécharger ce document car vous avez plus de 10 documents. Veuillez d'abord envoyer 10 documents pour télécharger les suivants",
      COUNT_LIMIT_ERROR_MESSAGE_20:
        "Impossible de télécharger ce document car vous avez plus de 20 documents. Veuillez d'abord envoyer 20 documents pour télécharger les suivants",
      SIZE_LIMIT_ERROR_MESSAGE_10MO:
        "Impossible de télécharger ce document car la taille de l'envoi dépasse les 10Mo.",
      SIZE_LIMIT_ERROR_MESSAGE_25MO:
        "Impossible de télécharger ce document car la taille de l'envoi dépasse les 25Mo.",
      TYPE_FORBIDDEN_ERROR_MESSAGE:
        "Ce type de document ñ'est pas pris en charge",
    },
  },
  MESSAGES: {
    ERROR:
      "Il est impossible de télécharger {{FILE_NAME}}, ce type de document n'est pas pris en charge",
    FILE_EXISTS: '{{FILE_NAME}} le document existe',
    REMOVED: "{{FILE_NAME}} Le document a été retiré de l'envoi",
    SUCCESS: 'Vos documents ont été bien envoyés !',
    ERROR_DURING_UPLOAD: 'Erreur inconnue lors du téléchargement',
    UPLOAD_PLEASE_WAIT: "Fichiers en cours d'envoi, merci de patienter",
  },
  MODAL: {
    TITLE: 'Suppression du document',
    TITLE_REMOVE_ALL: 'Suppression des documents',
    TEXT: 'Êtes-vous sûr de vouloir supprimer le document {{FILE_NAME}} ?',
    TEXT_REMOVE_ALL: 'Êtes-vous sûr de vouloir supprimer tous les documents ?',
    CANCEL_BUTTON_TEXT: 'Annuler',
    APPLY_BUTTON_TEXT: 'Oui, supprimer',
  },
  BUTTON_CAPTION: 'Envoyer',
  BUTTON_DOWNLOAD_MORE: 'Plus de résultats',
  DOCUMENTS_HISTORY: {
    TITLE: 'Historique des documents envoyés',
    PLACEHOLDER: 'Rechercher',
    TABLE_HEADER_CAPTIONS: {
      FILE_NAME: 'Nom du document',
      FILE_SEND_BY: 'Utilisateur',
      DATE: 'Date',
      SIZE: 'Taille',
    },
    DATE_PICKER_PLACEHOLDER: 'Sélectionner une date',
  },
  PRECOMPTA_EMAIL_TEXT:
    'Votre référent Exco a créé une adresse e-mail dédiée pour faciliter la transmission de vos factures. Vous pouvez l’utiliser à tout moment et transférer vos factures en pièce jointe.',
};
