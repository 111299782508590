import React from 'react';
import MainStyle from 'assets/styles/main.module.css';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import logo from 'assets/images/LOGO_MyExco.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'const';
import PropTypes from 'prop-types';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { mapTermsOfUseText } from 'helpers/termsOfUse';
import Styles from './TermsOfUseLanding.module.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  termsText: PropTypes.string.isRequired,
  hasBreadcrumb: PropTypes.bool,
  breadcrumbLink: PropTypes.string,
  breadcrumbText: PropTypes.string,
  hasLogoOnPage: PropTypes.bool,
};

let paragraphIndex = 0;
let spansIndex = 0;

export const TermsOfUseLanding = React.memo(
  ({
    termsText,
    hasBreadcrumb = true,
    breadcrumbLink = ROUTES.ROOT,
    breadcrumbText = '',
    title,
    hasLogoOnPage = false,
  }) => {
    useScrollToTop();

    const formattedText = mapTermsOfUseText(termsText);

    return (
      <div className={clsx(Styles.wrapper, MainStyle.container)}>
        {hasLogoOnPage && (
          <div className={Styles.logoWrapper}>
            <img src={logo} alt="My exco logo" className={Styles.logo} />
          </div>
        )}
        {hasBreadcrumb && (
          <Link to={breadcrumbLink} className={Styles.returnHome}>
            <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
            <span>{breadcrumbText}</span>
          </Link>
        )}
        <div className={Styles.miniContainer}>
          <SectionTitle title={title} />
          <div className={Styles.content}>
            {formattedText.map((paragraph) => {
              paragraphIndex += 1;
              return (
                <div className={Styles.paragraph} key={paragraphIndex}>
                  {paragraph.map((spanElement) => {
                    spansIndex += 1;
                    return (
                      <React.Fragment key={spansIndex}>
                        {spanElement}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

TermsOfUseLanding.propTypes = propTypes;
