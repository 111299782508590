import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import { fromJS } from 'immutable';
import {
  CREDITORS_SEARCH_REQUEST,
  CREDITORS_SEARCH_SUCCESS,
  CREDITORS_SEARCH_ERROR,
  CREDITOR_DATA_REQUEST,
  CREDITOR_DATA_SUCCESS,
  CREDITOR_DATA_ERROR,
} from '../actions';

const initialState = fromJS({
  data: [],
  datasets: [],
  meta: {},
  lastUpdated: '',
  error: '',
  current: {
    status: STATUS_NOT_REQUESTED,
    creditor: '',
    data: [],
    error: '',
  },
});

export const creditors = (state = initialState, action) => {
  switch (action.type) {
    case CREDITORS_SEARCH_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('storedPayload', initialState.storedPayload);
    case CREDITORS_SEARCH_SUCCESS:
      return state
        .set('data', fromJS(action.payload.data))
        .set('datasets', fromJS(action.payload.datasets))
        .set('meta', fromJS(action.payload.meta))
        .set('lastUpdated', fromJS(action.payload.lastUpdated))
        .set('storedPayload', fromJS(action.payload.payload))
        .set('status', STATUS_SUCCESS);
    case CREDITORS_SEARCH_ERROR:
      return state
        .set('error', action.payload.error.message || 'error')
        .set('status', STATUS_ERROR)
        .setIn(['datasets'], fromJS([]))
        .setIn(['data'], fromJS([]));
    case CREDITOR_DATA_REQUEST:
      return state
        .setIn(['current', 'status'], STATUS_LOADING)
        .setIn(['current', 'data'], initialState.getIn(['current', 'data']));
    case CREDITOR_DATA_SUCCESS: {
      const { data, accountName } = action.payload;
      return state.set('current', {
        status: STATUS_SUCCESS,
        data: fromJS(data),
        creditor: accountName,
      });
    }
    case CREDITOR_DATA_ERROR:
      return state
        .setIn(['current', 'error'], action.payload.error.message || 'error')
        .setIn(['current', 'status'], STATUS_ERROR);
    default:
      return state;
  }
};
