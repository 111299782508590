import moment from 'moment';
import { DATE_FORMAT, ISO_DATE_FORMAT } from 'const';
import { SCHEDULE_DAY_FULLTEXT } from 'const/SalaryCompanySettings';
import { minutesToHoursAndMinutes } from 'modules/SalaryModule/CompanySettings/utils';

/**
 * Format array of employees for output to the table
 * @param { Array } employeesList - array of employees
 * @param { Object } branchAdresses - object with each branch full address
 * @returns {Array} return formatted array for easier data output
 */
export const mapEmployerToTable = (employeesList, branchAdresses) =>
  employeesList.map((employee) => {
    const name = formatNameUpperLastName(
      employee.firstname1,
      employee.lastname
    );

    const natureEmployment = formatStringToFirstLetterCapital(
      employee.employeeposition
    );

    return {
      key: employee.employeeid,
      employeeid: employee.employeeid,
      siret: employee.siret,
      schedule: employee.scheduleId,
      name,
      establishments: {
        postIndex: employee.siret,
        address: branchAdresses[employee.siret]?.street || '',
        city: branchAdresses[employee.siret]?.city || '',
        postcode: branchAdresses[employee.siret]?.postcode || '',
        siret: branchAdresses[employee.siret]?.siret || '',
      },
      natureEmployment,
      classification: employee.classification,
      contract: employee.typecontract,
      dateOfEntry: moment(employee.startdate)
        .format(DATE_FORMAT)
        .toString(),
      releaseDate: employee.enddate
        ? moment(employee.enddate)
            .format(DATE_FORMAT)
            .toString()
        : '...',
    };
  });

export const formatStringToFirstLetterCapital = (name = '') => {
  if (typeof name !== 'string') {
    console.error('Wrong argument type in formatStringToFirstLetterCapital');
    return '';
  }

  return name ? name[0].toUpperCase() + name.slice(1).toLowerCase() : '';
};

/**
 * Both firstname and lastname can possibly have more than 1 word in them, and each word
 * must be capitalized.
 * @param firstname
 * @param lastname
 * @returns {string}
 */
const formatName = (firstname = '', lastname = '') => {
  if (typeof firstname !== 'string' || typeof lastname !== 'string') {
    console.error('Wrong parameters in formatName function');
    return '';
  }

  return [firstname.trim(), lastname.trim()]
    .map((string) => {
      if (string.includes(' ')) {
        return string
          .split(' ')
          .map((namePart) => formatStringToFirstLetterCapital(namePart))
          .join(' ');
      }

      if (string.includes('-')) {
        return string
          .split('-')
          .map((namePart) => formatStringToFirstLetterCapital(namePart))
          .join('-');
      }

      return formatStringToFirstLetterCapital(string);
    })
    .join(' ');
};

/**
 * Make lastname uppercased and capitalize firstname
 * @param firstname
 * @param lastname
 * @returns {string}
 */
export const formatNameUpperLastName = (firstname = '', lastname = '') => {
  if (typeof firstname !== 'string' || typeof lastname !== 'string') {
    console.error('Wrong parameters in formatName function');
    return '';
  }

  return `${lastname.toUpperCase()} ${formatName(firstname)}`;
};

const COMPARE_DATE_TYPE = 'day';

const isDateBetween = (date, start, end) =>
  moment(date).isBetween(start, end, COMPARE_DATE_TYPE, '[]');
/**
 * Determines if employee and filter dates intersect
 * @param { String } employeeDateStart - employee hire date
 * @param { String } employeeDateEnd - employee dismissal date
 * @param { Object } range - employee table filter date start and end
 * @returns {Boolean} return true if range is between employee hire and dismissal dates
 */
export const getIsBetween = (employeeDateStart, employeeDateEnd, range) => {
  const { startDate, endDate } = range;
  if (!employeeDateEnd) {
    return (
      isDateBetween(employeeDateStart, startDate, endDate) ||
      moment(startDate).isSameOrAfter(employeeDateStart, COMPARE_DATE_TYPE) ||
      moment(endDate).isSameOrAfter(employeeDateStart, COMPARE_DATE_TYPE)
    );
  }
  return (
    isDateBetween(employeeDateStart, startDate, endDate) ||
    isDateBetween(employeeDateEnd, startDate, endDate) ||
    isDateBetween(startDate, employeeDateStart, employeeDateEnd) ||
    isDateBetween(endDate, employeeDateStart, employeeDateEnd)
  );
};

/**
 * Format schedule day intervals to strings
 * @param { Array } day - schedule day intervals
 * @returns {Array} [{startTime: hh:mm, endTime: hh:mm}]
 */
const getRanges = (day) =>
  day.map(({ start, end }) => ({
    startTime: `${start.hours}:${start.minutes || '00'}`,
    endTime: `${end.hours}:${end.minutes || '00'}`,
  }));

/**
 * Calculate total work hours and minutes per day
 * @param { Array } day - schedule day intervals
 * @returns {Object} object with sum of hours and minutes per day.
 */
const calculateTotalHoursAndMinutes = (day) => {
  const initialTime = { hours: 0, minutes: 0, totalInMinutes: 0 };
  return day.reduce((result, { start, end }) => {
    const startMinutes = start.hours * 60 + start.minutes;
    const endMinutes = end.hours * 60 + end.minutes;
    const diffMinutes = endMinutes - startMinutes;

    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.floor(diffMinutes % 60);

    return {
      hours: result.hours + hours,
      minutes: result.minutes + minutes,
      totalInMinutes: result.totalInMinutes + diffMinutes,
    };
  }, initialTime);
};

/**
 * Format date time object to string
 * @param { Object } total - object with total hours and minutes
 * @returns {String} total time in string format
 */
const formatTotalTime = (total) =>
  `${total.hours || '00'} h ${total.minutes || '00'} min`;

/**
 * Structure for schedule view in employee table
 * @param { Object } days - object with schedule days interval arrays
 * @returns {Array} - array of formatted schedule days objects for easier data output
 */
export const getScheduleStructure = (days) => {
  const result = Object.keys(days)
    .filter((key) => days[key].length)
    .reduce((list, key) => {
      const total = calculateTotalHoursAndMinutes(days[key]);

      if (total.minutes > 59) {
        const [plusHours, restMinutes] = minutesToHoursAndMinutes(total);
        total.hours += plusHours;
        total.minutes = restMinutes;
      }

      const obj = {
        label: SCHEDULE_DAY_FULLTEXT[key],
        ranges: getRanges(days[key]),
        total: formatTotalTime(total),
        totalInMinutes: total.totalInMinutes,
      };
      list.push(obj);
      return list;
    }, []);

  return result;
};

/**
 * Calculate total time of the schedule
 * @param { Array } schedule - schedule days with work intervals
 * @returns {String} - total schedule work hours and minutes as a string
 */
export const getScheduleTotalHours = (schedule) => {
  const initialTime = { hours: 0, minutes: 0 };
  const total = schedule.reduce((result, { totalInMinutes }) => {
    const hours = Math.floor(totalInMinutes / 60);
    const minutes = Math.floor(totalInMinutes % 60);

    return {
      hours: result.hours + hours,
      minutes: result.minutes + minutes,
    };
  }, initialTime);

  if (total.minutes > 59) {
    const [plusHours, restMinutes] = minutesToHoursAndMinutes(total);
    total.hours += plusHours;
    total.minutes = restMinutes;
  }

  return formatTotalTime(total);
};

/**
 * Get filter object from employee table filters
 * @param { Object } filters - establishments, contract, classification,
 * natureEmployments from filter toolbar
 * @param { String } searchTerm - employee search by name
 * @param { Object } dateRange - filter start and end dates
 * @returns {Object} - formatted object
 */
export const getEmployeeTableCsvCommonFilter = (
  filters,
  searchTerm,
  dateRange
) => ({
  siret: filters.establishments,
  contract: filters.contracts,
  classification: filters.classifications,
  position: filters.natureEmployments,
  name: searchTerm,
  startDate: dateRange.startDate.format(ISO_DATE_FORMAT).toString(),
  endDate: dateRange.endDate.format(ISO_DATE_FORMAT).toString(),
});

/**
 * Get query for request from employee table filter object
 * @param { Object } filter - all employee table filters
 * @returns {String} - siret=77558363600052?contract=CDD?name='Firstname [LastName]`?classification=Employé(e)?position=PLONGEUSE
 */
export const getCsvDownloadQuery = (filter) =>
  Object.entries(filter).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc}${key}=${value}&`;
    }

    return acc;
  }, '');
