import React from 'react';
import { ServiceCardDefault } from 'components/ServiceCard/ServiceCardDefault';
import PropTypes from 'prop-types';
import { ServiceCardButtons } from 'components/ServiceCard/ServiceCardButtons';
import { EURO_SIGN } from 'const';

import { STORE_TRANSLATIONS } from 'const/Store';
import Styles from './ServiceMultiCard.module.css';

const propTypes = {
  isActive: PropTypes.bool,
  userModules: PropTypes.array,
  mainService: PropTypes.object,
  isAddedToCart: PropTypes.bool,
};

export const ServiceMultiCard = React.memo(
  ({
    isActive,
    userModules,
    mainService: { TITLE, services, PACKAGE_COST, PACKAGE_NAME, LANDING_PAGE },
    isAddedToCart = false,
  }) => (
    <div className={Styles.bigCardWrapper}>
      <div className={Styles.bigCardTitleWrapper}>
        <span className={Styles.bigCardTitle}>{TITLE}</span>
        {isActive ? (
          <span className={Styles.moduleActive}>
            {STORE_TRANSLATIONS.ACTIVE_MODULE}
          </span>
        ) : (
          PACKAGE_COST && (
            <span className={Styles.costWrapper}>
              <span className={Styles.cost}>
                {PACKAGE_COST}
                {EURO_SIGN}
              </span>
              <span>{STORE_TRANSLATIONS.PER_MONTH}</span>
            </span>
          )
        )}
      </div>
      <div className={Styles.bigCardContent}>
        {services.map((excoService) => {
          const isServiceActive =
            excoService.MODULES_FOR_ACCESS.some((accessModule) =>
              userModules.includes(accessModule)
            ) || excoService.MODULES_FOR_ACCESS.length === 0;

          return (
            <ServiceCardDefault
              isMinified
              key={excoService.ID}
              isActive={isServiceActive}
              serviceCardObject={excoService}
            />
          );
        })}
      </div>
      <div className={Styles.buttonsWrapper}>
        <ServiceCardButtons
          isAccessButtonHidden
          isActive={isActive}
          isAddedToCart={isAddedToCart}
          packageName={PACKAGE_NAME}
          landingPage={LANDING_PAGE}
        />
      </div>
    </div>
  )
);

ServiceMultiCard.propTypes = propTypes;
