import axios from 'axios';
import { AUTH_TOKEN_KEY, BASE_URL } from 'config';

import {
  errorHandler,
  responseMapper,
  setJWTHeader,
  setJWTLocalStorage,
} from './requestHelpers';

const request = axios.create({
  baseURL: BASE_URL,
});
request.interceptors.request.use(setJWTHeader(AUTH_TOKEN_KEY));
request.interceptors.response.use(setJWTLocalStorage(AUTH_TOKEN_KEY));
request.interceptors.response.use(responseMapper, errorHandler);

export { request };
