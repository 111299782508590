import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import { Dropdown } from 'antd';
import { Icon } from '@iconify/react';
import downOutlined from '@iconify/icons-ant-design/down-outlined';

import { CHART_TABS_TRANSLATIONS } from 'const/translations/ChartTabs';
import { getStringDateWithSlashes } from 'helpers/chartTabs';
import { DASHBOARD_NAMES } from 'const/charts';
import Styles from './ChartTabs.module.css';

const {
  ALL_MY_ACCOUNTS,
  HORIZONTAL_ALL_ACCOUNTS,
  SINGLE_BANK_ACCOUNT,
  OTHER_ACCOUNTS,
} = CHART_TABS_TRANSLATIONS;

const { TREASURY } = DASHBOARD_NAMES;

const propTypes = {
  tabItems: PropTypes.array.isRequired,
  updateDate: PropTypes.string,
  activeTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setActiveTabId: PropTypes.func.isRequired,
  maxItemsOutsideSelector: PropTypes.number,
  dashboardName: PropTypes.oneOf(Object.values(DASHBOARD_NAMES)),
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  tabNamesInFullWidth: PropTypes.bool,
};

/*
 * tabItems structure {array} -> [{id:any,name:string,value: number|string}]
 * */
export const ChartTabs = ({
  tabItems,
  updateDate = new Date().toISOString(),
  activeTabId,
  setActiveTabId,
  maxItemsOutsideSelector = 3,
  dashboardName,
  className,
  horizontal = false,
  tabNamesInFullWidth = false,
}) => {
  const [activeTabNameInSelect, setActiveTabNameInSelect] = useState(null);

  const stringUpdateDate = getStringDateWithSlashes(updateDate);

  const tabItemsOutsideSelect = useMemo(
    () => tabItems.slice(0, maxItemsOutsideSelector),
    [tabItems, maxItemsOutsideSelector]
  );

  const tabItemsInSelect = useMemo(
    () => tabItems.slice(maxItemsOutsideSelector),
    [tabItems, maxItemsOutsideSelector]
  );

  const onTabClickHandlerCreator = (tabId) => () => {
    setActiveTabId(tabId);
  };

  //  Creates handler that sets tabs inside select as an active
  /*
   *  tabId(number) -> tab id
   *  tabName(string) -> name of the tab
   *  stop(bool) -> value true activates preventDefault, so that event will not bubble
   *    ATTENTION
   *    pass argument stop = true in case this tab has another tab as a parent,
   *    since they both have same handler,
   *    parent tab is always gonna be active on clicks if you dont pass argument stop
   * */
  const onTabInSelectClickHandlerCreator = (tabId, tabName, stop) => (e) => {
    if (e.defaultPrevented || !tabId) return;
    if (stop) e.preventDefault();
    setActiveTabId(tabId);
    setActiveTabNameInSelect(tabName);
  };

  useEffect(() => {
    const activeTabIndex = tabItems.findIndex((tab) => activeTabId === tab.id);
    if (activeTabIndex >= maxItemsOutsideSelector) {
      setActiveTabNameInSelect(tabItems[activeTabIndex].name);
      return;
    }
    setActiveTabNameInSelect(null);
  }, [
    maxItemsOutsideSelector,
    activeTabId,
    tabItems,
    setActiveTabNameInSelect,
  ]);

  const activeTabIdInSelect = useMemo(() => {
    const { id } =
      tabItemsInSelect.find(
        (tabItem) => tabItem.name === activeTabNameInSelect
      ) || {};
    return id;
  }, [activeTabNameInSelect, tabItemsInSelect]);

  const activeTabValueInSelect = useMemo(() => {
    const { value } =
      tabItemsInSelect.find(({ id }) => id === activeTabIdInSelect) || {};
    return getProperLookingAmount(value);
  }, [activeTabIdInSelect, tabItemsInSelect]);

  const activeTabSuffixInSelect = useMemo(() => {
    const { suffix } =
      tabItemsInSelect.find(({ id }) => id === activeTabIdInSelect) || {};
    return suffix;
  }, [activeTabIdInSelect, tabItemsInSelect]);

  const totalAccountsAmount = useMemo(
    () =>
      getProperLookingAmount(
        tabItems.reduce((prev, { value }) => prev + Number(value), 0)
      ),
    [tabItems]
  );

  return (
    <div
      className={clsx(Styles.wrapper, className, {
        [Styles.horizontal]: horizontal,
      })}
    >
      {dashboardName === TREASURY &&
        tabItems.length === 1 &&
        tabItems.map(({ id, value, suffix }) => {
          const stringAmount = getProperLookingAmount(value);
          return (
            <div key={id} className={Styles.aloneTab}>
              <div className={Styles.tabValue}>
                {stringAmount} {suffix}
              </div>
              <div className={Styles.tabName}>
                <span className={Styles.greenDot} />
                <span className={Styles.tabNameText}>
                  {SINGLE_BANK_ACCOUNT.replace(
                    '{{UPDATE_DATE}}',
                    stringUpdateDate
                  )}
                </span>
              </div>
            </div>
          );
        })}
      {dashboardName === TREASURY && tabItems.length > 1 && (
        <div
          className={clsx(Styles.tabItem, {
            [Styles.active]: activeTabId === 'total',
          })}
          onClick={onTabClickHandlerCreator('total')}
        >
          <div className={Styles.tabName}>
            <span className={Styles.greenDot} />
            {horizontal
              ? HORIZONTAL_ALL_ACCOUNTS
              : ALL_MY_ACCOUNTS(tabItems.length, stringUpdateDate)}
          </div>
          <div className={Styles.tabValue}>{totalAccountsAmount} €</div>
        </div>
      )}
      {tabItems.length > 1 &&
        tabItemsOutsideSelect.map(({ id, name, value, suffix }) => {
          const stringAmount = getProperLookingAmount(value);
          return (
            <div
              key={id}
              className={clsx(Styles.tabItem, {
                [Styles.active]: id === activeTabId,
                [Styles.fullWidthTabName]: tabNamesInFullWidth,
              })}
              onClick={onTabClickHandlerCreator(id)}
            >
              <div className={Styles.tabName}>
                <span className={Styles.greenDot} />
                <span className={Styles.tabNameText}>{name}</span>
                <div className={Styles.fullTabNamePopup}>{name}</div>
              </div>
              {value === null ? (
                <div className={Styles.tabValue}>N/A</div>
              ) : (
                <div className={Styles.tabValue}>
                  {stringAmount} {suffix}
                </div>
              )}
            </div>
          );
        })}
      {tabItems.length > maxItemsOutsideSelector && (
        <div
          className={clsx(Styles.tabItem, Styles.selectTabItem, {
            [Styles.active]: activeTabId === activeTabIdInSelect,
          })}
          onClick={onTabInSelectClickHandlerCreator(
            activeTabIdInSelect,
            activeTabNameInSelect
          )}
        >
          <Dropdown
            placement={horizontal ? 'bottomRight' : 'bottomLeft'}
            overlay={
              <div
                className={clsx(Styles.popup, {
                  [Styles.horizontalPopup]: horizontal,
                })}
              >
                {tabItemsInSelect.map(({ id, name, value, suffix }) => {
                  const stringAmount = getProperLookingAmount(value);
                  return (
                    <div
                      key={id}
                      className={clsx(Styles.optionTabItem, {
                        [Styles.active]: id === activeTabId,
                      })}
                      onClick={onTabInSelectClickHandlerCreator(id, name, true)}
                    >
                      <div className={Styles.tabName}>{name}</div>
                      <div className={Styles.tabValue}>
                        {stringAmount} {suffix}
                      </div>
                    </div>
                  );
                })}
              </div>
            }
            trigger={['click']}
          >
            <div className={clsx(Styles.select)}>
              <div className={Styles.tabName}>
                <span className={Styles.greenDot} />
                <span className={Styles.tabNameText}>
                  {activeTabNameInSelect ||
                    OTHER_ACCOUNTS.replace(
                      '{{COMPANIES_COUNT}}',
                      tabItems.length - maxItemsOutsideSelector
                    )}
                </span>
                {activeTabNameInSelect && (
                  <div className={Styles.fullTabNamePopup}>
                    {activeTabNameInSelect}
                  </div>
                )}
              </div>
              <div className={Styles.icon}>
                <Icon icon={downOutlined} />
              </div>
            </div>
          </Dropdown>
          <div className={Styles.tabValue}>
            {activeTabIdInSelect &&
              `${activeTabValueInSelect} ${activeTabSuffixInSelect}`}
          </div>
        </div>
      )}
    </div>
  );
};

ChartTabs.propTypes = propTypes;
