export const REWARDS_PAGE = {
  TITLE: 'Eléments variables de paie',
  SEARCH_PLACEHOLDER: 'Rechercher par nom',
  BRANCH_PLACEHOLDER: 'Etablissement',
  DOWNLOAD_BTN: 'Télécharger',
  FAMILIES_TITLE_MOBILE: 'Choisissez la famille de primes',
  MORE_BTN_TEXT: 'Autres …',
  TABLE_NAME_COLUMN: 'Nom Prénom',
  TABLE_GENERAL_TAB_TITLE: 'Récapitulatif EVP',
};
