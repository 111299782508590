import {
  object as yupObject,
  array as yupArray,
  string as yupString,
} from 'yup';

export const validationSchema = () =>
  yupObject().shape({
    idOrPassport: yupArray(),
    workContract: yupArray(),
    CPAM: yupArray(),
    disabledCertificate: yupArray(),
    DPAE: yupArray(),
    mutualExclusion: yupArray(),
    comment: yupString(),
  });
