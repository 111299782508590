export const COUNTERPARTY_TABLE_TRANSLATIONS = {
  COLUMNS_HEADERS: {
    CLIENT_NAME: 'Nom du client',
    REMAINING_AMOUNT: 'Montant restant dû',
    RELAUNCH_DONE: 'Relance effectuée',
    COMMENTARIES: 'Commentaires',
    ACTIONS: 'Actions',
    DATE: 'Date',
    OP_NUMBER: 'N',
    DESCRIPTION: 'Libellé',
    DEBIT: 'Debit',
    CREDIT: 'Credit',
    AMOUNT: 'Solde',
  },
  TOTAL: 'Solde',
};

export const CREDITORS_TRANSLATIONS = {
  TITLE: 'Vous devez à vos fournisseurs',
  LABELS: {
    DETAIL: 'Détail',
  },
  TABLE: {
    RESULTS_COUNTER: '{{TOTAL}} Résultat(s)',
    COLUMNS_HEADERS: {
      CLIENT_NAME: 'Nom du fournisseur',
      REMAINING_AMOUNT: 'Montant restant dû',
      ACTIONS: 'Actions',
    },
    NO_RESULTS_MESSAGE:
      'Aucun document trouvé. Veuillez changer vos critères de recherche ci-dessus',
    TABLE_SIGNATURE: "Affichage de l'élément [X] à [Y] sur [Z] documents",
    BUTTON_LOAD_MORE: 'Plus de résultats',
  },
};

export const CREDITOR_TRANSLATIONS = {
  BUTTON_BACK: 'Vous devez à vos fournisseurs',
  TITLE: 'Le fournisseur {{NAME}}',
};

export const DEBTORS_TRANSLATIONS = {
  TITLE: 'Vos clients vous doivent',
  LABELS: {
    DETAIL: 'Détail',
    TOTAL: 'Solde',
    CLIENT: 'Le client',
  },
  TABLE: {
    RESULTS_COUNTER: '{{TOTAL}} Résultat(s)',
    COLUMNS_HEADERS: {
      CLIENT_NAME: 'Nom du client',
      REMAINING_AMOUNT: 'Montant restant dû',
      RELAUNCH_DONE: 'Relance effectuée',
      COMMENTARIES: 'Commentaires',
      ACTIONS: 'Actions',
      DATE: 'Date',
      OP_NUMBER: 'N',
      DESCRIPTION: 'Libellé',
      DEBIT: 'Debit',
      CREDIT: 'Credit',
      AMOUNT: 'Solde',
    },
    NO_RESULTS_MESSAGE:
      'Aucun document trouvé. Veuillez changer vos critères de recherche ci-dessus',
    TABLE_SIGNATURE: "Affichage de l'élément [X] à [Y] sur [Z] documents",
    BUTTON_LOAD_MORE: 'Plus de résultats',
  },
};

export const DEBTOR_TRANSLATIONS = {
  BUTTON_BACK: 'Vos clients vous doivent',
  TITLE: 'Le client {{NAME}}',
};

export const COUNTERPARTIES_TABLE_DEFAULT_FILTER = {
  id: 'all',
  title: 'Tous',
};
