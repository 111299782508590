import { createSelector } from 'reselect';

export const selectAreIndicatorsLoading = createSelector(
  (state) => state.getIn(['treasuryPage', 'status']),
  (state) => state.getIn(['turnoverPage', 'status']),
  (state) => state.getIn(['HRPage', 'status']),
  (treasuryStatus, turnoverStatus, HRStatus) =>
    [treasuryStatus, turnoverStatus, HRStatus].some(
      (status) => status === 'LOADING'
    )
);
