import { FRENCH_ROUTES } from '../translations/Routes';
import { ROUTES } from '../index';

export const UNAUTHORIZED_REDIRECTS = [
  { from: FRENCH_ROUTES.LOGIN, to: ROUTES.LOGIN },
  { from: FRENCH_ROUTES.CHANGE_PASSWORD, to: ROUTES.CHANGE_PASSWORD },
  {
    from: FRENCH_ROUTES.TEMPORARY_CHANGE_PASSWORD,
    to: ROUTES.TEMPORARY_CHANGE_PASSWORD,
  },
  { from: FRENCH_ROUTES.FORGOT_PASSWORD, to: ROUTES.FORGOT_PASSWORD },
  { from: FRENCH_ROUTES.PASSWORD_SENT, to: ROUTES.PASSWORD_SENT },
  { from: FRENCH_ROUTES.PASSWORD_CHANGED, to: ROUTES.PASSWORD_CHANGED },
];

export const AUTHORIZED_REDIRECTS = [
  { from: FRENCH_ROUTES.CONTACTS, to: ROUTES.CONTACTS },
  { from: FRENCH_ROUTES.TERMS_OF_USE_LANDING, to: ROUTES.TERMS_OF_USE_LANDING },
  { from: FRENCH_ROUTES.LOGOUT, to: ROUTES.LOGOUT },
  { from: FRENCH_ROUTES.SALES_LANDING, to: ROUTES.SALES_LANDING },
  { from: FRENCH_ROUTES.EXPENSES_LANDING, to: ROUTES.EXPENSES_LANDING },
  { from: FRENCH_ROUTES.HELP, to: ROUTES.HELP },
  { from: FRENCH_ROUTES.MY_EXCO_LANDING, to: ROUTES.MY_EXCO_LANDING },
  { from: FRENCH_ROUTES.TURNOVER, to: ROUTES.TURNOVER },
  { from: FRENCH_ROUTES.TREASURY, to: ROUTES.TREASURY },
  { from: FRENCH_ROUTES.HR, to: ROUTES.HR },
  { from: FRENCH_ROUTES.DEBTORS, to: ROUTES.DEBTORS },
  { from: FRENCH_ROUTES.DEBTOR, to: ROUTES.DEBTOR },
  { from: FRENCH_ROUTES.CREDITORS, to: ROUTES.CREDITORS },
  { from: FRENCH_ROUTES.CREDITOR, to: ROUTES.CREDITOR },
  {
    from: FRENCH_ROUTES.EMPLOYEE_REGISTER_TITLE,
    to: ROUTES.EMPLOYEE_REGISTER_TITLE,
  },
  {
    from: FRENCH_ROUTES.EMPLOYEE_REGISTER_FORM,
    to: ROUTES.EMPLOYEE_REGISTER_FORM,
  },
  { from: FRENCH_ROUTES.UPLOAD_DOCUMENTS, to: ROUTES.UPLOAD_DOCUMENTS },
  { from: FRENCH_ROUTES.DOCUMENTS_CABINET, to: ROUTES.DOCUMENTS_CABINET },
  { from: FRENCH_ROUTES.ADDITIONAL_USERS, to: ROUTES.ADDITIONAL_USERS },
  { from: FRENCH_ROUTES.INVOICE_SEARCH, to: ROUTES.INVOICE_SEARCH },
  { from: FRENCH_ROUTES.EXPENSES, to: ROUTES.EXPENSES },
  {
    from: FRENCH_ROUTES.TERMS_OF_SUBSCRIPTION,
    to: ROUTES.TERMS_OF_SUBSCRIPTION,
  },
  { from: FRENCH_ROUTES.CART, to: ROUTES.CART },
  { from: FRENCH_ROUTES.UNSUBSCRIBE, to: ROUTES.UNSUBSCRIBE },
  { from: FRENCH_ROUTES.NOT_AUTHORIZED, to: ROUTES.NOT_AUTHORIZED },
];

export const PAY_ROUTES_REDIRECTS = [
  { from: FRENCH_ROUTES.SALARIES_MAIN_PAGE, to: ROUTES.SALARIES_MAIN_PAGE },
  { from: FRENCH_ROUTES.SALARIES_EMPLOYEES, to: ROUTES.SALARIES_EMPLOYEES },
  { from: FRENCH_ROUTES.SALARIES_REWARDS, to: ROUTES.SALARIES_REWARDS },
  { from: FRENCH_ROUTES.SALARIES_CALENDAR, to: ROUTES.SALARIES_CALENDAR },
  {
    from: FRENCH_ROUTES.SALARIES_COMPANY_SETTINGS,
    to: ROUTES.SALARIES_COMPANY_SETTINGS,
  },
  {
    from: FRENCH_ROUTES.SALARIES_SCHEDULE_EMPTY,
    to: ROUTES.SALARIES_SCHEDULE_EMPTY,
  },
  {
    from: FRENCH_ROUTES.SALARIES_COMPANY_SETTINGS_SCHEDULE,
    to: ROUTES.SALARIES_COMPANY_SETTINGS_SCHEDULE,
  },
  {
    from: FRENCH_ROUTES.SALARIES_COMPANY_SETTINGS_REWARD_CREATE,
    to: ROUTES.SALARIES_COMPANY_SETTINGS_REWARD_CREATE,
  },
  {
    from: FRENCH_ROUTES.SALARIES_COMPANY_SETTINGS_REWARD,
    to: ROUTES.SALARIES_COMPANY_SETTINGS_REWARD,
  },
];

export const REGISTERED_USER_TERMS_REDIRECTS = [
  { from: FRENCH_ROUTES.TERMS_OF_USE_LANDING, to: ROUTES.TERMS_OF_USE_LANDING },
  {
    from: FRENCH_ROUTES.TERMS_OF_SUBSCRIPTION,
    to: ROUTES.TERMS_OF_SUBSCRIPTION,
  },
  { from: FRENCH_ROUTES.LOGOUT, to: ROUTES.LOGOUT },
];
