import React from 'react';
import PropTypes from 'prop-types';
import { INVOICE_SEARCH_COMMENTARY_MODAL } from 'const/translations';
import clsx from 'clsx';
import { Icon } from 'antd';
import Styles from './InvoiceSearchCommentaryModal.module.css';

const { TITLE, NO_COMMENTS } = INVOICE_SEARCH_COMMENTARY_MODAL;

const propTypes = {
  commentaryArray: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      user: PropTypes.shape({
        isApprovingUser: PropTypes.bool,
        isPayingUser: PropTypes.bool,
        name: PropTypes.string,
      }),
    })
  ),
};

export const CommentsList = ({ commentaryArray }) => (
  <div className={clsx(Styles.commentaryModalRow, Styles.textDefault)}>
    <h2 className={Styles.textDefault}>{TITLE}</h2>
    <div className={Styles.commentsList}>
      {commentaryArray.length !== 0 ? (
        commentaryArray
          .map(({ comment, date, user }) => {
            const isApprovingUser = user?.isApprovingUser || false;
            const isPayingUser = user?.isPayingUser || false;
            const name = user?.name || '';

            const viewStyle = {
              [Styles.commentViewValidating]: isApprovingUser,
              [Styles.commentViewPayingAndValidating]:
                isPayingUser && isApprovingUser,
            };

            const additionalTextStyle = {
              [Styles.textAdditionalValidating]: isApprovingUser,
              [Styles.textAdditionalPayingAndValidating]:
                isPayingUser && isApprovingUser,
            };

            return (
              <div className={clsx(Styles.commentView, viewStyle)}>
                {user && (
                  <span
                    className={clsx(
                      Styles.textTruncated,
                      Styles.textAdditional,
                      additionalTextStyle
                    )}
                  >
                    {name}
                  </span>
                )}
                <span className={clsx(Styles.textDefault, Styles.textComment)}>
                  {comment}
                </span>
                <span
                  className={clsx(
                    Styles.commentDate,
                    Styles.textAdditional,
                    additionalTextStyle
                  )}
                >
                  {date}
                </span>
              </div>
            );
          })
          .reverse()
      ) : (
        <div
          className={clsx(Styles.commentaryModalRow, Styles.noCommentsWrapper)}
        >
          <Icon
            type="message"
            theme="filled"
            className={Styles.noCommentsIcon}
          />
          <h2 className={Styles.textDefault}>{NO_COMMENTS}</h2>
        </div>
      )}
    </div>
  </div>
);

CommentsList.propTypes = propTypes;
