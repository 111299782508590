import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { ViewSwitch } from 'components/ui/ViewSwitch';
import { useChartFilters } from 'hooks/useChartFilters';
import { Legends } from 'components/Charts/Legends';
import { LineChart } from 'components/Charts/LineChart';
import { BarChart } from 'components/Charts/BarChart';
import { CommentsModal } from 'components/CommentsModal';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { PillarLoader } from 'components/ui/PillarLoader';
import { CURRENT_YEAR } from 'const';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { useCounterpartiesChart } from 'helpers/counterparties';
import Styles from './CounterpartiesChart.module.css';

const { LINE } = CHARTS_VIEW_SWITCH_TYPES;

const propTypes = {
  currentExercice: PropTypes.string,
};

export const CounterpartiesChart = ({ currentExercice }) => {
  const [hiddenLinesYears, setHiddenLinesYears] = useState([]);

  const {
    chartData,
    comments,
    dashboardName,
    firstMonthFiscal,
    isLoading,
    isError,
  } = useCounterpartiesChart();

  const {
    activeYear,
    setActiveYear,
    activeMonthName,
    chartViewActiveMode,
    setChartViewActiveMode,
    activeDateViewOption,
  } = useChartFilters();

  const Chart = useMemo(() => {
    if (chartViewActiveMode === LINE) return LineChart;
    return BarChart;
  }, [chartViewActiveMode]);

  const currentYearFromExercice = useMemo(
    () => (currentExercice && currentExercice.slice(0, 4)) || CURRENT_YEAR,
    [currentExercice]
  );

  useEffect(() => {
    setActiveYear(Number(currentYearFromExercice));
  }, [currentYearFromExercice, setActiveYear]);

  const {
    isModalOpen,
    onCloseCommentsModal,
    onOpenCommentsModal,
  } = useCommentsModalCallbacks();

  return (
    <Row className={Styles.contentRow}>
      <CommentsModal isOpen={isModalOpen} closeModal={onCloseCommentsModal} />
      <Col span={24} className={Styles.filtersWrapper}>
        <div className={Styles.currentYear}>
          Exercice {currentExercice.slice(0, -2)}
        </div>
        <div className={Styles.chartFilters}>
          <ViewSwitch
            activeOption={chartViewActiveMode}
            setActiveOption={setChartViewActiveMode}
          />
          <Legends
            chartData={chartData}
            hiddenLinesYears={hiddenLinesYears}
            setHiddenLineYears={setHiddenLinesYears}
            className={Styles.chartLegends}
          />
        </div>
      </Col>
      {isLoading && (
        <Col span={24} className={Styles.loaderWrapper}>
          <PillarLoader colored />
        </Col>
      )}
      {!isLoading &&
        (isError ? (
          <Col span={24} className={Styles.loaderWrapper}>
            <PillarLoader />
            <div className={Styles.errorText}>
              {HOMEPAGE_DASHBOARDS_TRANSLATIONS.DASHBOARD_ERROR_TEXT}
            </div>
          </Col>
        ) : (
          <Col span={24} className={Styles.contentCol}>
            <div className={Styles.chartWrapper}>
              <Chart
                firstMonth={firstMonthFiscal}
                activeYear={activeYear}
                data={chartData}
                activeDateViewOption={activeDateViewOption}
                activeMonthName={activeMonthName}
                hiddenLinesYears={hiddenLinesYears}
                gradientHeight={600}
                enableGrid
                comments={comments}
                dashboardName={dashboardName}
                openCommentsModal={onOpenCommentsModal}
              />
            </div>
          </Col>
        ))}
    </Row>
  );
};

CounterpartiesChart.propTypes = propTypes;
