import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Select, Table, message } from 'antd';
import bin from 'assets/icons/Subscription/bucket.svg';
import { Formik } from 'formik';
import MainStyles from 'assets/styles/main.module.css';
import { Button } from 'components/ui/Button';
import PropTypes from 'prop-types';
import { ADDITIONAL_USERS_TABLE_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import { validateEmail } from 'modules/additionalUsers/helpers';
import { ADDITIONAL_USER_ROLES_LABELS } from 'const/packages';
import {
  validateUserModules,
  verifyPayModulesDisabled,
} from 'helpers/additionalUsersSelector';
import { removeFromStorage, setToStorage } from 'helpers/storage';
import Styles from './AdditionalUsersTable.module.css';

const propTypes = {
  unsynchronizedUserDataset: PropTypes.object.isRequired,
  removeUserFromDataset: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func.isRequired,
  isSaveChangesButtonEnabled: PropTypes.bool,
  setIsChangesButtonEnabled: PropTypes.func,
  initialDataset: PropTypes.object.isRequired,
  resetStates: PropTypes.func.isRequired,
  datasetLocalStorageKey: PropTypes.string.isRequired,
  isPositionEditable: PropTypes.bool,
  className: PropTypes.string,
  userPossibleModules: PropTypes.array.isRequired,
  confirmButtonText: PropTypes.string,
  isResetButtonVisible: PropTypes.bool,
  setResetButtonVisibility: PropTypes.func,
  submitButtonVariant: PropTypes.string,
  currentUserEmail: PropTypes.string,
};

const { Option } = Select;

const {
  EMPTY_TABLE_TEXT,
  NAME_HEADER,
  SURNAME_HEADER,
  MAIL_HEADER,
  MODULES_HEADER,
  POSITION_HEADER,
  RESET_CHANGES_BUTTON,
  SAVE_TABLE_BUTTON,
  SELECT_INPUT_TEXT,
  SELECT_PLACEHOLDER,
  EMAIL_EQUAL_MAIN_USER_EMAIL,
} = ADDITIONAL_USERS_TABLE_TRANSLATIONS;

/**
 * @param unsynchronizedUserDataset {object} -> contains stored locally changes, which persist through
 *                                    reload and can be either sent to backend and saved, or reset to initial values.
 *              structure -> {
 *                  userId: {
 *                      email: string,
 *                      position: string,
 *                      isUserNew: boolean,
 *                      tableModules: array
 *                  }
 *              }
 * @param removeUserFromDataset {function} -> function to remove user from dataset
 *            (remember that if you just removing user from additionalUserDataset current changed values going to reset)
 *            (in this function you should take current form values and remove user from it, then set it to additionalUserDataset)
 * @param onSaveChanges {function} -> onSubmit function
 * @param isSaveChangesButtonEnabled {boolean} -> flag that save button is enabled
 * @param setIsChangesButtonEnabled {function} -> setState to change save button enabled state
 * @param isResetButtonVisible {boolean} -> flag that reset button is visible
 * @param setResetButtonVisibility {function} -> setState to set visibility of reset button
 * @param initialDataset {object} -> is used to reset all changes after clear localStorage, same structure that additionalUserDataset
 * @param resetStates{function} -> function that clears localStorage and resets additionalUserDataset to initialDataset state
 * @param datasetLocalStorageKey{string} -> key for save additionalUserDataset in localstorage and load it in it after page reloading
 * @param confirmButtonText {string} -> text for submit button
 * @param userPossibleModules {string} -> array of modules which are enabled for modules select cell in table
 * @param isPositionEditable {boolean} -> flag which decides whether position cell in table editable or not
 * */
export const AdditionalUsersTable = React.memo(
  ({
    unsynchronizedUserDataset,
    removeUserFromDataset,
    onSaveChanges,
    setIsChangesButtonEnabled = () => {},
    isSaveChangesButtonEnabled = true,
    initialDataset,
    resetStates,
    datasetLocalStorageKey,
    isPositionEditable = true,
    className = '',
    userPossibleModules = [],
    confirmButtonText = SAVE_TABLE_BUTTON,
    isResetButtonVisible = true,
    setResetButtonVisibility = () => {},
    submitButtonVariant = 'success',
    currentUserEmail,
  }) => {
    const isTableEmpty = useMemo(
      () => Object.keys(unsynchronizedUserDataset).length === 0,
      [unsynchronizedUserDataset]
    );

    const getColumns = useCallback(
      (handleChange, handleSelectChange, errors, resetError) => [
        {
          title: NAME_HEADER,
          dataIndex: 'name',
          width: '170px',
          render: (name, { id: userId, isUserNew }) => {
            const fieldName = `${userId}.name`;
            return isUserNew ? (
              <div className={Styles.inputWrapper}>
                <input
                  type="text"
                  onChange={handleChange}
                  name={fieldName}
                  data-user-id={userId}
                  className={clsx(Styles.input, {
                    [Styles.inputError]: errors[userId]?.name,
                  })}
                  onFocus={resetError(userId, 'name')}
                  value={name}
                />
              </div>
            ) : (
              <div className={Styles.overflowHidden}>{name}</div>
            );
          },
        },
        {
          title: SURNAME_HEADER,
          dataIndex: 'surname',
          width: '170px',
          render: (surname, { id: userId, isUserNew }) => {
            const fieldName = `${userId}.surname`;
            return isUserNew ? (
              <div className={Styles.inputWrapper}>
                <input
                  type="text"
                  onChange={handleChange}
                  name={fieldName}
                  data-user-id={userId}
                  className={clsx(Styles.input, {
                    [Styles.inputError]: errors[userId]?.surname,
                  })}
                  onFocus={resetError(userId, 'surname')}
                  value={surname}
                />
              </div>
            ) : (
              <div className={Styles.overflowHidden}>{surname}</div>
            );
          },
        },
        {
          title: MAIL_HEADER,
          dataIndex: 'email',
          width: '300px',
          render: (email, { id: userId, isUserNew }) => {
            const fieldName = `${userId}.email`;
            return isUserNew ? (
              <div className={Styles.inputWrapper}>
                <input
                  type="text"
                  onChange={handleChange}
                  name={fieldName}
                  data-user-id={userId}
                  className={clsx(Styles.input, {
                    [Styles.inputError]: errors[userId]?.email,
                  })}
                  onFocus={resetError(userId, 'email')}
                  value={email}
                />
              </div>
            ) : (
              <div className={Styles.overflowHidden}>{email}</div>
            );
          },
        },
        {
          title: POSITION_HEADER,
          dataIndex: 'position',
          width: '170px',
          render: (position, { id: userId, isUserNew }) => {
            const fieldName = `${userId}.position`;
            const shouldCellBeInput = isUserNew || isPositionEditable;
            return shouldCellBeInput ? (
              <div className={Styles.inputWrapper}>
                <input
                  type="text"
                  onChange={handleChange}
                  name={fieldName}
                  data-user-id={userId}
                  className={clsx(Styles.input, {
                    [Styles.inputError]: errors[userId]?.position,
                  })}
                  onFocus={resetError(userId, 'position')}
                  value={position}
                />
              </div>
            ) : (
              <div>{position}</div>
            );
          },
        },
        {
          title: MODULES_HEADER,
          dataIndex: 'tableModules',
          render: (tableModules, { id: userId }) => {
            const selectableModules = tableModules.filter((module) =>
              userPossibleModules.includes(module)
            );

            return (
              <div className={Styles.selectCellWrapper}>
                <Select
                  mode="multiple"
                  className={Styles.select}
                  showArrow
                  value={selectableModules}
                  dropdownClassName={Styles.selectDropdown}
                  maxTagPlaceholder={(omittedValues) => (
                    <div>
                      {SELECT_INPUT_TEXT}
                      {omittedValues.length}
                    </div>
                  )}
                  onChange={handleSelectChange(userId)}
                  placeholder={SELECT_PLACEHOLDER}
                  maxTagCount={0}
                >
                  {userPossibleModules.map((module) => (
                    <Option
                      value={module}
                      key={module}
                      disabled={verifyPayModulesDisabled(
                        selectableModules,
                        module
                      )}
                    >
                      {ADDITIONAL_USER_ROLES_LABELS[module] || ''}
                    </Option>
                  ))}
                </Select>
              </div>
            );
          },
        },
        {
          width: '30px',
          render: (_, { id: userId, email }) => (
            <div className={Styles.actionsWrapper}>
              <div
                className={Styles.iconWrapper}
                onClick={removeUserFromDataset}
                data-user-id={userId}
                data-email={email}
              >
                <img src={bin} alt="delete bucket icon" />
              </div>
            </div>
          ),
        },
      ],
      [removeUserFromDataset, isPositionEditable, userPossibleModules]
    );

    return (
      <Formik
        initialValues={unsynchronizedUserDataset}
        enableReinitialize
        onSubmit={onSaveChanges}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validate={(values) => {
          const errors = {};
          const valuesArray = Object.values(values);
          valuesArray.forEach(
            ({ id, position, isUserNew, email, name, surname }) => {
              if (!name) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].name = 'name required';
              }
              if (!surname) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].surname = 'surname required';
              }
              if (!position) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].position = 'position required';
              }
              if (!isUserNew) {
                return;
              }
              if (!email) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].email = 'email required';
              }
              if (email && !validateEmail(email)) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].email = 'wrong email structure';
              }
              if (
                email &&
                valuesArray.find(
                  ({ id: userId, email: userEmail }) =>
                    id !== userId &&
                    email.toLocaleLowerCase() === userEmail.toLocaleLowerCase()
                )
              ) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].email = 'email is taken';
              }
              if (
                email.toLocaleLowerCase() ===
                currentUserEmail.toLocaleLowerCase()
              ) {
                if (!errors[id]) {
                  errors[id] = {};
                }
                errors[id].email = 'email is taken';
                message.error(EMAIL_EQUAL_MAIN_USER_EMAIL);
              }
            }
          );
          return errors;
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          setFieldError,
          resetForm,
        }) => {
          if (Object.values(values).length === 0) {
            removeFromStorage({ key: datasetLocalStorageKey });
          } else {
            setToStorage({ key: datasetLocalStorageKey, value: values });
          }

          const handleChangeForInputs = (e) => {
            const { value } = e.target;
            const { userId } = e.target.dataset;
            setFieldValue(e.target.name, value);
            setFieldValue(`${userId}.touched`, true);
            setIsChangesButtonEnabled(true);
            setResetButtonVisibility(true);
          };

          const handleSelectChange = (userId) => (value) => {
            setFieldValue(
              `${userId}.tableModules`,
              validateUserModules(value, values, userId)
            );
            setFieldValue(`${userId}.touched`, true);
            setIsChangesButtonEnabled(true);
            setResetButtonVisibility(true);
          };
          const resetError = (userId, field) => () => {
            setFieldError(`${userId}.${field}`, null);
          };

          const resetChanges = () => {
            resetStates();
            resetForm(initialDataset);
          };

          return (
            <form onSubmit={handleSubmit}>
              <Table
                className={clsx(MainStyles.table, Styles.table, className)}
                dataSource={Object.values(values)}
                columns={getColumns(
                  handleChangeForInputs,
                  handleSelectChange,
                  errors,
                  resetError
                )}
                rowKey="id"
                pagination={false}
              />
              {isTableEmpty && (
                <div className={Styles.tablePlaceholder}>
                  <div className={Styles.tablePlaceholderText}>
                    {EMPTY_TABLE_TEXT}
                  </div>
                </div>
              )}
              <div className={Styles.buttonWrapper}>
                {isSaveChangesButtonEnabled && (
                  <Button
                    type="submit"
                    className={Styles.submitButton}
                    variant={submitButtonVariant}
                    disabled={!isSaveChangesButtonEnabled}
                  >
                    {confirmButtonText}
                  </Button>
                )}
                {isResetButtonVisible && (
                  <Button
                    type="button"
                    variant="secondary"
                    wide
                    onClick={resetChanges}
                    className={Styles.resetChangesButton}
                  >
                    {RESET_CHANGES_BUTTON}
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
);

AdditionalUsersTable.propTypes = propTypes;
