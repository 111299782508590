import React from 'react';

/**
 * Sorts failed uploads from successful
 */
export const sortUploadsByStatus = (historyLogs) =>
  historyLogs.reduce(
    (acc, log) => {
      if (log.uploadError) {
        acc.failed.push(log);
      } else {
        acc.successful.push(log);
      }
      return acc;
    },
    { successful: [], failed: [] }
  );

export const formatUploadErrorsToMessage = (historyLogs) => {
  const errorMessage = historyLogs.reduce((acc, { name, uploadError }) => {
    if (!uploadError) {
      return acc;
    }
    return (
      <>
        {acc}
        <div>
          {name.length < 20 ? name : `${name.substr(0, 20)}...`} : {uploadError}
        </div>
      </>
    );
  }, '');
  return <>{errorMessage}</>;
};
