import { getType, getActionCreator } from 'utils';
const prefix = '@@app/debtors';

/**
 * Debtors
 */

export const DEBTORS_SEARCH_REQUEST = getType(prefix, 'DEBTORS_SEARCH_REQUEST');
export const debtorsSearchRequest = getActionCreator(DEBTORS_SEARCH_REQUEST);

export const DEBTORS_SEARCH_SUCCESS = getType(prefix, 'DEBTORS_SEARCH_SUCCESS');
export const debtorsSearchSuccess = getActionCreator(DEBTORS_SEARCH_SUCCESS);

export const DEBTORS_SEARCH_ERROR = getType(prefix, 'DEBTORS_SEARCH_ERROR');
export const debtorsSearchError = getActionCreator(DEBTORS_SEARCH_ERROR);

/**
 * Debtor
 */

export const DEBTOR_DATA_REQUEST = getType(prefix, 'DEBTOR_DATA_REQUEST');
export const debtorDataRequest = getActionCreator(DEBTOR_DATA_REQUEST);

export const DEBTOR_DATA_SUCCESS = getType(prefix, 'DEBTOR_DATA_SUCCESS');
export const debtorDataSuccess = getActionCreator(DEBTOR_DATA_SUCCESS);

export const DEBTOR_DATA_ERROR = getType(prefix, 'DEBTOR_DATA_ERROR');
export const debtorDataError = getActionCreator(DEBTOR_DATA_ERROR);
