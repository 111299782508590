import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, notification } from 'antd';
import clsx from 'clsx';
import { Button } from 'components/ui';
import { COMMON_LABELS, INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { STATUS_NOT_REQUESTED, STATUS_LOADING, EMPTY_FUNCTION } from 'const';
import {
  selectInvoiceBeingApprovedId,
  selectInvoicePaymentURL,
  selectInvoiceSearchResults,
  selectInvoiceSearchStatus,
  selectStoredMeta,
  selectDownloadDocumentsIsLoading,
} from 'modules/invoiceSearch/selectors';
import MainStyles from 'assets/styles/main.module.css';
import { useSortableTableCells } from 'hooks/useSortableTableCells';
import {
  resetPayInvoiceUrl,
  downloadDocumentsRequest,
} from 'modules/invoiceSearch/actions';
import { openModal } from 'modules/ModalContainer/actions';
import { MODAL_TYPES } from 'const/ModalContainer';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceSearchTableFooter } from 'hooks/useInvoiceSearchTableFooter';
import { getResultsTableColumns } from 'helpers/invoiceSearchTable';
import { useSearchResultsTablePagination } from 'hooks/useSearchResultsTablePagination';
import { Spinner } from 'components/Spinner';

const {
  TABLE: { RESULTS_COUNTER, NO_RESULTS_MESSAGE, MASS_DOWNLOAD_BUTTON },
} = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
  setSelectedRowKeys: PropTypes.func,
  setSelectedInvoicesKeys: PropTypes.func,
};

export const SearchResultsTable = React.memo(
  ({ selectedRowKeys, setSelectedRowKeys, setSelectedInvoicesKeys }) => {
    const dispatch = useDispatch();

    const searchStatus = useSelector(selectInvoiceSearchStatus);
    const searchData = useSelector(selectInvoiceSearchResults);
    const { total } = useSelector(selectStoredMeta);
    const invoiceBeingApprovedId = useSelector(selectInvoiceBeingApprovedId);
    const paymentURL = useSelector(selectInvoicePaymentURL);
    const downloadDocumentsIsLoading = useSelector(
      selectDownloadDocumentsIsLoading
    );

    useEffect(() => {
      if (paymentURL) {
        dispatch(
          openModal({
            type: MODAL_TYPES.iframeModal,
            modalProps: {
              src: paymentURL,
              title: 'Libeo payment page',
              onCancel: dispatch(resetPayInvoiceUrl()),
            },
          })
        );
      }
    }, [dispatch, paymentURL]);

    const isLoading = searchStatus === STATUS_LOADING;

    const title = isLoading
      ? COMMON_LABELS.LOADING
      : RESULTS_COUNTER.replace('{{TOTAL}}', Number(total));
    const columns = useMemo(getResultsTableColumns, []);

    const rowSelection = {
      selectedRowKeys: selectedRowKeys?.invoicesId,
      onChange: (invoicesId, invoices) => {
        const dynamicTotals = invoices?.reduce(
          (acc, invoice) => ({
            amount: acc.amount + invoice.amount,
            vat: acc.vat + invoice.vat,
            amountWithVat: acc.amountWithVat + invoice.amountWithVat,
          }),
          {
            amount: 0,
            vat: 0,
            amountWithVat: 0,
          }
        );

        setSelectedRowKeys({
          invoicesId,
          dynamicTotals,
        });
        setSelectedInvoicesKeys(
          invoices
            .filter(
              (invoice) =>
                !(
                  (invoiceBeingApprovedId &&
                    invoiceBeingApprovedId.includes(invoice.documentId)) ||
                  invoice.paymentApprovedBy !== undefined
                )
            )
            .map((invoice) => invoice.id)
        );
      },
    };

    const getTableFooter = useInvoiceSearchTableFooter(selectedRowKeys);
    const pagination = useSearchResultsTablePagination(setSelectedRowKeys);

    /**
     * Table sorting.
     *
     * TODO: optimize current solution by fetching additional
     * invoices only if they were not fetched already and
     * implementing frontend-based sorting for this kind of data
     */
    const cellData = useSortableTableCells();

    useEffect(() => {
      if (cellData.length === 0) {
        return EMPTY_FUNCTION;
      }

      cellData.forEach(({ cell, handleSort }) => {
        cell.addEventListener('click', handleSort);
      });

      return () => {
        cellData.forEach(({ cell, handleSort }) => {
          cell.removeEventListener('click', handleSort);
        });
      };
    }, [cellData]);

    const onMassDownload = useCallback(() => {
      if (!selectedRowKeys?.invoicesId?.length) {
        notification.warn({
          message:
            'Merci de sélectionner une ou plusieurs factures avant de procéder à leur téléchargement',
        });
        return;
      }

      dispatch(
        downloadDocumentsRequest(
          selectedRowKeys.invoicesId.map(
            (key) =>
              searchData.find((invoice) => invoice.id === key)?.documentId
          )
        )
      );
    }, [dispatch, searchData, selectedRowKeys]);

    useEffect(() => {
      setSelectedRowKeys([]);
    }, [searchData, setSelectedRowKeys]);

    if (searchStatus === STATUS_NOT_REQUESTED) {
      return null;
    }

    return (
      <div className={Styles.resultsTable}>
        <div className={Styles.resultsTableTitle}>
          <h3 className={Styles.title}>{title}</h3>
          <Button
            outlined
            big
            onClick={onMassDownload}
            className={Styles.resultsTableButton}
          >
            {downloadDocumentsIsLoading && (
              <Spinner circle className={Styles.resultsTableSpinner} />
            )}
            {MASS_DOWNLOAD_BUTTON}
          </Button>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          footer={getTableFooter}
          dataSource={searchData}
          locale={{ emptyText: NO_RESULTS_MESSAGE }}
          className={clsx(MainStyles.table, Styles.table)}
          rowClassName={({ isCancellation, isDeleted }) =>
            clsx({
              [Styles.red]: isCancellation,
              [Styles.strikethrough]: isDeleted,
            })
          }
          rowSelection={rowSelection}
        />
      </div>
    );
  }
);

SearchResultsTable.propTypes = propTypes;
