import { put, takeLatest, call, select } from 'redux-saga/effects';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { downloadFile } from 'helpers/common';
import {
  DOWNLOAD_SHAREPOINT_FILE_REQUEST,
  SHAREPOINT_FOLDER_REQUEST,
  SHAREPOINT_SEARCH_REQUEST,
  downloadSharepointFileError,
  downloadSharepointFileSuccess,
  sharepointFolderError,
  sharepointFolderSuccess,
  sharepointSearchError,
  sharepointSearchSuccess,
} from '../actions';
import { getSharepointContent, getSharepointFile } from '../services';

function* getSharepointFolderWorker({ payload: { path } }) {
  if (!path?.length) {
    return;
  }
  try {
    const companyId = yield select(selectUserCompanyId);
    const payloadInfo = path.join('/');
    const {
      data: { files, folders },
    } = yield call(getSharepointContent, { companyId, payloadInfo });
    yield put(sharepointFolderSuccess({ files, folders }));
  } catch (error) {
    yield put(sharepointFolderError({ error }));
  }
}

function* getSharepointFileWorker({ payload: { fileId, fileName, onError } }) {
  try {
    const companyId = yield select(selectUserCompanyId);

    const fileData = yield call(getSharepointFile, companyId, fileId);

    yield call(downloadFile, fileData, fileName);
    yield put(downloadSharepointFileSuccess({ fileId }));
  } catch (error) {
    console.warn(error);
    onError();
    yield put(downloadSharepointFileError({ fileId }));
  }
}

function* searchInSharepointWorker({ payload: { search } }) {
  try {
    const companyId = yield select(selectUserCompanyId);
    const {
      data: { files, folders },
    } = yield call(getSharepointContent, {
      companyId,
      payloadInfo: search,
      isSearch: true,
    });
    yield put(sharepointSearchSuccess({ files, folders }));
  } catch (error) {
    yield put(sharepointSearchError({ error }));
  }
}

export function* browseSharepointMainSaga() {
  yield takeLatest(SHAREPOINT_FOLDER_REQUEST, getSharepointFolderWorker);
  yield takeLatest(SHAREPOINT_SEARCH_REQUEST, searchInSharepointWorker);
  yield takeLatest(DOWNLOAD_SHAREPOINT_FILE_REQUEST, getSharepointFileWorker);
}
