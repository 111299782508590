import { SCHEDULE_TYPES } from '../../modules/SalaryModule/CompanySettings/const';

export const COMPANY_SETTINGS_TABS = {
  GENERAL_INFORMATION: 'Informations générales',
  WORKING_HOURS: "Libellé de l'horaire",
  VARIABLE_ITEM_TITLES: 'Titres des éléments variables',
};

export const COMPANY_SETTINGS = {
  SCREEN_WIDTH_BREAKPOINT_VALUE: 640,
  NOOP_FUNCTION: () => {},
  PAGE_TITLE: 'Paramètres entreprise',
  SCHEDULE_TABLE_HEADS: {
    TITLE: "Libellé de l'horaire",
    DAYS: 'Jours de la semaine',
    TOTAL: 'Total',
    STAFF_COUNT: 'Salariés concernés',
  },
  SCHEDULE_STAFF_MOBILE: 'salariés',
  ESTABLISHMENTS: 'Etablissement',
  ADD_SCHEDULE: 'Ajouter horaire',
  REMOVE_BTN_TEXT: 'Supprimer',
  REMOVE_MODAL_TITLE: "Suppression de l'horaire",
  REMOVE_MODAL_TEXT_1:
    "Êtes-vous sûr de vouloir supprimer l'horaire ? Cette modification affectera {{COUNT}} salarié(s).",
  REMOVE_MODAL_TEXT_2:
    'L’horaire par défaut sera appliqué (35 h / sem sur 5 jours (lundi au vendredi - 7 h / jour)',
  SCHEDULE_DEFAULT: '{{SCHEDULE_NAME}} - défaut',
  SCHEDULE_FORM: {
    PAGE_TITLE: 'Horaire',
    MAX_SCHEDULE_TITLE_LENGTH: 150,
    EVERY_DAY: 'Chaque jour',
    PAUSE_LONG_TEXT: 'Pause / Coupure',
    PAUSE_SHORT_TEXT: 'Repas',
    MESSAGE_SUCCESS: 'Les données sont mises en jour!',
    MESSAGE_ERROR:
      'Cet horaire existe déjà - A modifier. Afficher l`horaire existant',
    MESSAGE_DEFAULT:
      "L'horaire par défaut ne peut pas être modifié ou supprimé",
    EMPTY_STAFF_TEXT: `Pour appliquer cet horaire au(x) salarié(s), veuillez ouvrir le menu "Mes salariés" et cliquer sur les pictogrammes présents dans la colonne "Horaire"`,
    DEFAULT_TITLE_TEXT:
      '35 h / semaine sur 5 jours (lundi au vendredi = 7 h / jour)',
    TITLE_ERROR_TEXT: 'champs requis',
    SAVE_BTN_TEXT: 'Sauvegarder',
    CANCEL_BTN_TEXT: 'Supprimer',
    TAB_DIFFERENT: 'different',
    TAB_SAME: 'same',
    TABS_OPTIONS: [
      {
        label: 'Horaire différent',
        value: SCHEDULE_TYPES.DIFFERENT,
      },
      {
        label: 'Même horaire chaque jour',
        value: SCHEDULE_TYPES.SAME,
      },
    ],
    STAFF_LIST_TITLE: 'Les salariés avec cet horaire',
    STAFF_LIST_EMPTY_LINK: 'Ouvrir Mes ressources humaines',
    SEARCH_PLACEHOLDER: 'Rechercher par nom',
  },
  BRANCH_FORM: {
    COMPANY_NAME: 'Raison sociale',
    CHOOSE_BRANCH: "Choisissez l'établissement",
    NEW_BRANCH: 'Nouvel établissement',
    TOOLTIP_TEXT1: `Jours ouvrables : tous les jours de la semaine à l'exception des jours fériés non travaillés et du jour de repos hebdomadaire (généralement le dimanche).`,
    TOOLTIP_TEXT2: `Jours ouvrés  : jours d’ouverture de l’entreprise (le plus souvent 5 jours par semaine du lundi au vendredi ou du mardi au samedi) ou le nombre de jours normalement travaillés par les salariés.`,
    CHANNEL_NUMBER: 'Numéro de voie',
    ROAD_NAME: 'Nom de la voie',
    ADDITIONAL_ADDRESS: 'Complément adresse',
    POSTAL_CODE: 'Code postal',
    CITY: 'Ville',
    COUNTRY_CODE: 'Code pays',
    DISTRIBUTION_CODE: 'Code distribution',
    FOREIGN_ADDRESS: "Adresse à l'étranger",
    NUMBER: 'N° Siret',
    SIREN_NUMBER: 'N° siren',
    PAY_METHOD: 'Mode de décompte des congés payés',
    PAY_METHOD_OPTIONS: [
      {
        label: 'Ouvrables',
        value: 'Ouvrables',
      },
      {
        label: 'Ouvrés',
        value: 'Ouvres',
      },
    ],

    CANCEL_BUTTON: 'Annuler',
    CONFIRM_BUTTON: 'Ajouter',
  },
};

export const COMPANY_SETTINGS_REWARDS = {
  ADD_FAMILY_BTN: '+ Ajouter une famille de variables',
  REMOVE_APPROVE_TITLE: "Suppression d'une famille de variables",
  REMOVE_APPROVE_TEXT_1:
    'Êtes-vous sûr de vouloir supprimer la famille de variables ? Cette modification affectera ',
  REMOVE_APPROVE_TEXT_2: 'variables de paie.',
  REMOVE_CANCEL_TITLE: 'Impossible de supprimer cette famille de variables',
  REMOVE_CANCEL_TEXT:
    'Impossible de supprimer cette famille de variables. Des données ont été saisies sur le mois en cours. Supprimez-les sur la page',
  REMOVE_CANCEL_TEXT2: '',
  REMOVE_CANCEL_LINK: ' Eléments variables de paie.',
  REWARD_TYPE_OPTIONS: [
    {
      value: 'amount',
      label: 'Montant',
    },
    {
      value: 'hour',
      label: 'Heure ',
    },
    {
      value: 'number',
      label: 'Nombre',
    },
  ],
  CONFIRM_TEXT: 'Supprimer',
  LIST_TITLE: 'Eléments ajoutés',
  SELECT_PLACEHOLDER: 'Choisir les variables',
  AUTOCOMPLETE_SELECT_PLACEHOLDER: 'Veuillez entrer au moins 3 caractères',
  SELECT_TITLE: 'Ajouter une variable :',
  FAMILY_TITLE: 'Titre de famille :',
  FAMILY_PAGE_TITLE: 'Famille de variables',
  SAVE_BTN_TEXT: 'Sauvegarder',
  TITLE_COLUMN: 'Famille de variables',
  ACTIONS_COLUMN: 'Actions',
  TOOL_OPTIONS: [
    {
      value: 'other',
      label: 'Autre',
    },
  ],
};

export const COMPANY_SETTINGS_REWARDS_DETAIL = {
  REMOVE_APPROVE_TITLE: "Suppression d'une variable de paie",
  REMOVE_APPROVE_TEXT:
    'Êtes-vous sûr de vouloir supprimer la variable de paie ?',
  REMOVE_CANCEL_TITLE: 'Impossible de supprimer cette variable',
  REMOVE_CANCEL_TEXT:
    'Impossible de supprimer cette variable. Des données ont été saisies sur le mois en cours. Supprimez-les sur la page',
  REMOVE_CANCEL_TEXT2: '',
  REMOVE_CANCEL_LINK: ' Eléments variables de paie.',
  SUCCESS_MESSAGE: 'Les données sont mises en jour!',
  ERROR_MESSAGE: "Vous devez créer une famille avant d'ajouter une variable",
  TABLE: {
    CODE: 'Code',
    TITLE: 'Titre',
    TYPE: 'Type',
    ACTIONS: 'Actions',
    TOOL: 'Outil',
    TOOL_EMPTY: 'Autre',
  },
};
