import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES } from 'const/ModalContainer';
import { openModal } from 'modules/ModalContainer/actions';
import { useInvoiceActionsData } from '../useInvoiceActionsData';

export const usePayActionModal = () => {
  const dispatch = useDispatch();

  const modalProps = useInvoiceActionsData();

  return useCallback(() => {
    const {
      isDeleted,
      isPayableInvoice,
      canValidateAndPay,
      isValidated,
    } = modalProps;

    if (isDeleted) {
      return null;
    }

    if (!isValidated && isPayableInvoice && canValidateAndPay) {
      dispatch(
        openModal({
          type: MODAL_TYPES.validateAndPayModal,
          modalProps,
        })
      );
    } else {
      dispatch(
        openModal({
          type: MODAL_TYPES.invoiceSearchInfoModal,
          modalProps: {
            ...modalProps,
            hideStatus: true,
          },
        })
      );
    }

    return null;
  }, [dispatch, modalProps]);
};
