/**
 * values in French because they are used as
 * sharepoint api browse starting points.
 */
export const DOCUMENTS_CABINET_TRANSLATIONS = {
  TITLE: 'Dossier partagé avec Exco',
  SEARCH_SUMMARY: '{{TOTAL}} résultats pour votre recherche',
  MENU_TITLE: 'Dossiers',
  MENU_ITEMS: {
    COMPATIBILITY: {
      label: 'Comptabilité',
      value: 'Comptabilité',
    },
    ADVICE: {
      label: 'Conseil',
      value: 'Conseil',
    },
    DEPOSIT: {
      label: 'Dépôt',
      value: 'Dépôt',
    },
    TAX: {
      label: 'Fiscal',
      value: 'Fiscal',
    },
    LEGAL: {
      label: 'Juridique',
      value: 'Juridique',
    },
    SOCIAL: {
      label: 'Social',
      value: 'Social',
    },
  },
  DOCUMENTS_LIST: {
    RESULTS_COUNTER: '{{TOTAL}} Résultat(s)',
    PLACEHOLDER: 'Rechercher',
    TABLE_HEADER_CAPTIONS: {
      FILE_NAME: 'Nom',
      SIZE: 'Taille',
      DATE: 'Date',
      DOWNLOAD: 'Télécharger',
    },
  },
  INFO_TEXT: {
    INFO_TITLE: 'Accédez à vos documents dans le Dossier Partagé',
    TEXT1:
      'Le dossier partagé est votre bibliothèque de Documents (GED). Il vous permet de retrouver tous les fichiers mis à disposition par vos interlocuteurs Exco, classés par thème (Comptabilité/Conseil/Social...).',
    TEXT2:
      'N.B : Vous ne pouvez pas y déposer de fichiers directement. Pour cela, vous pouvez utiliser la rubrique',
    LINK: 'Envoi de documents',
  },
  ERRORS: {
    DOWNLOAD_FAILED: 'Erreur lors du téléchargement',
    FETCH_FOLDER_FAILED: 'Une erreur est survenue',
    TRY_AGAIN_LATER:
      "Indisponible pour l'instant, merci de réessayer plus tard",
  },
};
