import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InputNumberAdapted } from 'components/adaptors/inputNumber';
import {
  COMMON_LABELS,
  INVOICE_SEARCH_TRANSLATIONS,
  LOGIC_CONDITIONS,
} from 'const/translations';
import { DATE_FORMAT } from 'const';
import { DatePickerAdapted } from 'components/adaptors/DatePicker';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';

const { placeholders } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  onChange: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object,
  name: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['first', 'second']),
};

export const PairedInput = memo(
  ({ onChange, formikValues, formikErrors, name, order = 'first' }) => {
    const combinedName = order === 'first' ? `${name}Input1` : `${name}Input2`;

    const sharedInputProps = {
      onChange,
      value: !formikValues[name] ? null : formikValues[combinedName],
      name: combinedName,
      disabled:
        order === 'first'
          ? !formikValues[name]
          : formikValues[name] !== LOGIC_CONDITIONS.BETWEEN.value,
      className: formikErrors[combinedName]
        ? 'inputNumberWrapperError'
        : undefined,
    };

    return name === 'invoiceDate' ? (
      <DatePickerAdapted
        placeholder={COMMON_LABELS.DATE_FORMAT}
        className={Styles.rangeUncontrolledDatePicker}
        storageFormat={DATE_FORMAT}
        {...sharedInputProps}
      />
    ) : (
      <InputNumberAdapted
        placeholder={placeholders.numbers}
        step={0.01}
        precision={2}
        {...sharedInputProps}
      />
    );
  }
);

PairedInput.propTypes = propTypes;
