import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Col, Typography } from 'antd';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import MainStyles from 'assets/styles/main.module.css';
import { SelectAdapted } from 'components/adaptors/Select';
import { Range } from 'components/ui/Range';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';
import { PairedInput } from './PairedInput';

const { labels, lists, placeholders } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  onChange: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
  formikErrors: PropTypes.object,
};

export const RangedInputs = memo(({ onChange, formikValues, formikErrors }) =>
  Object.entries(labels)
    .slice(4, 7)
    .map(([name, label]) => (
      <Col span={24} className={MainStyles.col} key={name}>
        <Typography.Paragraph
          className={clsx(MainStyles.formLabel, Styles.formLabel)}
        >
          {label}
        </Typography.Paragraph>
        <span className={Styles.rowInputs}>
          <Form.Item className={Styles.formItem}>
            <SelectAdapted
              placeholder={placeholders[name]}
              options={lists.searchRangeOptions}
              onChange={onChange}
              value={formikValues[name]}
              name={name}
            />
          </Form.Item>
          <div className={Styles.rangeWrapper}>
            <Range
              componentFrom={
                <PairedInput
                  order="first"
                  name={name}
                  onChange={onChange}
                  formikValues={formikValues}
                  formikErrors={formikErrors}
                />
              }
              componentTo={
                <PairedInput
                  order="second"
                  name={name}
                  onChange={onChange}
                  formikValues={formikValues}
                  formikErrors={formikErrors}
                />
              }
            />
          </div>
        </span>
      </Col>
    ))
);

RangedInputs.propTypes = propTypes;
