import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, Table } from 'antd';
import {
  UPLOAD_DOCUMENT_TRANSLATIONS,
  COMMON_LABELS,
} from 'const/translations';
import moment from 'moment';
import { DATE_FORMAT, SCREEN_WIDTH_BREAKPOINT_VALUE } from 'const';
import { Button } from 'components/ui/Button';
import { convertBytesToKilobytes } from 'helpers/common';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch } from 'react-redux';
import { moreUploadHistoryRequest } from 'modules/uploadDocuments/actions';
import Styles from './DocumentsHistory.module.css';
import { FileName } from '../FileName/FileName';

const {
  DOCUMENTS_HISTORY: {
    TITLE,
    TABLE_HEADER_CAPTIONS: { FILE_NAME, DATE, SIZE, FILE_SEND_BY },
  },
  BUTTON_DOWNLOAD_MORE,
} = UPLOAD_DOCUMENT_TRANSLATIONS;

const columns = [
  {
    title: FILE_SEND_BY,
    dataIndex: 'clientEmail',
    key: 'clientEmail',
    width: 250,
    render: (clientEmail) => <span>{clientEmail}</span>,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: FILE_NAME,
    dataIndex: 'fileName',
    key: 'fileName',
    render: (fileName, { key, isDownloadable }) => (
      <FileName
        fileName={fileName}
        fileId={key}
        isDownloadable={isDownloadable}
      />
    ),
    width: 500,
    sorter: (a, b) => a.fileName.localeCompare(b.fileName),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: DATE,
    dataIndex: 'date',
    key: 'date',
    width: 130,
    render: (rawDate) => (
      <span className={Styles.dateColumn}>
        {moment(rawDate).format(DATE_FORMAT)}
      </span>
    ),
    sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: SIZE,
    dataIndex: 'size',
    key: 'size',
    width: 100,
    render: (rawSize) =>
      `${convertBytesToKilobytes(rawSize)} ${COMMON_LABELS.KB_SHORT}`,
  },
];

export const DocumentsHistory = ({
  documents,
  isHistoryDownloaded,
  isMobileVersion,
}) => {
  const dispatch = useDispatch();

  const getMoreUploadHistory = useCallback(
    () => dispatch(moreUploadHistoryRequest()),
    [dispatch]
  );

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.history}>
        <Typography.Text className={Styles.title}>{TITLE}</Typography.Text>
      </div>
      <div className={Styles.historyTable}>
        <Table
          columns={columns}
          dataSource={documents}
          className={MainStyles.table}
          scroll={isMobileVersion ? { x: SCREEN_WIDTH_BREAKPOINT_VALUE } : {}}
          pagination={false}
        />
        {!isHistoryDownloaded && (
          <Button onClick={getMoreUploadHistory}>{BUTTON_DOWNLOAD_MORE}</Button>
        )}
      </div>
    </div>
  );
};

DocumentsHistory.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  isHistoryDownloaded: PropTypes.bool,
  isMobileVersion: PropTypes.bool,
};
