import mesVentesLogo from 'assets/images/mes-ventes-card-logo.png';
import mesIndicatorsLogo from 'assets/images/LandingPages/mes-indicators-lp-logo.svg';
import mesDespensesLogo from 'assets/images/mes-despenses-card-logo.png';
import uploadDocumentsLogo from 'assets/images/upload-documents-card.png';
import mesSalaries from 'assets/images/CardsLogos/mes-salaries-card.svg';
import indicatorsRH from 'assets/images/CardsLogos/indicators-RH.png';
import invoiceSearch from 'assets/images/CardsLogos/invoices-search-100.png';
import indicatorsFinances from 'assets/images/CardsLogos/indicators-finances.png';
import documentsAndSharepoint from 'assets/images/CardsLogos/documents-and-sharepoint-card.svg';
import PropTypes from 'prop-types';
import { PACKAGES_NAMES } from 'const/packages';
import { waibiAccessRequest } from 'modules/dashboardIndicators/action';
import { selectIsWaibiLoading } from 'modules/dashboardIndicators/selectors';
import { APP_MODULES, ROUTES } from '../index';
import { HOMEPAGE_FORM_TRANSLATIONS } from '../translations';
import { fetchIsanetRedirectUrl } from '../../modules/loggedUserInfo/actions';

export const EXCO_SERVICES_FUNCTIONAL_ITEM = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  TEXT: PropTypes.string.isRequired,
  MODULES_FOR_ACCESS: PropTypes.arrayOf(
    PropTypes.oneOf([...Object.values(APP_MODULES)])
  ),
  LINK: PropTypes.shape({
    NAME: PropTypes.string,
    HREF: PropTypes.string,
  }),
});

const CARD_TYPES = {
  MULTI_LINK: 'MULTI_LINK',
  DEFAULT: 'DEFAULT',
};

export const EXCO_SERVICES_PROPTYPE = PropTypes.shape({
  TYPE: PropTypes.oneOf([...Object.values(CARD_TYPES)]),
  ID: PropTypes.number,
  TITLE: PropTypes.string,
  LOGO: PropTypes.string,
  SERVICE_LP_LINK: PropTypes.string,
  MODULES_FOR_ACCESS: PropTypes.arrayOf(
    PropTypes.oneOf([...Object.values(APP_MODULES)])
  ),
  LINK_WITH_ACCESS: PropTypes.string,
  FUNCTIONAL: PropTypes.arrayOf(EXCO_SERVICES_FUNCTIONAL_ITEM),
});

const { CARDS_TITLES, CARDS_FUNCTIONAL } = HOMEPAGE_FORM_TRANSLATIONS;

export const EXCO_SERVICES = [
  {
    ID: 0,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.MY_SALES,
    PACKAGE_NAME: PACKAGES_NAMES.FACT,
    LOGO: mesVentesLogo,
    SERVICE_LP_LINK: ROUTES.SALES_LANDING,
    ACTION: fetchIsanetRedirectUrl,
    MODULES_FOR_ACCESS: [APP_MODULES.ISANETFACT],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.MY_SALES_1,
      },
      { ID: 1, TEXT: CARDS_FUNCTIONAL.MY_SALES_2 },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.MY_SALES_3,
      },
    ],
  },
  {
    ID: 1,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.WAIBI,
    PACKAGE_NAME: PACKAGES_NAMES.WAIBI,
    LOGO: mesIndicatorsLogo,
    SERVICE_LP_LINK: ROUTES.WAIBI_LANDING,
    ACTION: waibiAccessRequest,
    SELECT_IS_LOADING: selectIsWaibiLoading,
    MODULES_FOR_ACCESS: [APP_MODULES.WAIBI],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.WAIBI_1,
      },
      { ID: 1, TEXT: CARDS_FUNCTIONAL.WAIBI_2 },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.WAIBI_3,
      },
    ],
  },
  {
    ID: 2,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.MY_EXPENSES,
    PACKAGE_NAME: PACKAGES_NAMES.PAY,
    LOGO: mesDespensesLogo,
    SERVICE_LP_LINK: ROUTES.EXPENSES_LANDING,
    LINK_WITH_ACCESS: ROUTES.EXPENSES,
    MODULES_FOR_ACCESS: [
      APP_MODULES.APPROVE_INVOICE,
      APP_MODULES.PAY_INVOICE,
      APP_MODULES.READONLY_INVOICE,
      APP_MODULES.APPROVE_AND_PAY_INVOICE,
      APP_MODULES.PRE_VALIDATE_INVOICE,
    ],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.MY_EXPENSES_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.MY_EXPENSES_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.MY_EXPENSES_3,
      },
    ],
  },
  {
    ID: 3,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.UPLOAD_DOCUMENTS,
    SUBTITLE: 'Inclus',
    LOGO: uploadDocumentsLogo,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.UPLOAD_DOCUMENTS,
    MODULES_FOR_ACCESS: [APP_MODULES.UPLOAD_DOCUMENTS],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.UPLOAD_DOCUMENTS_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.UPLOAD_DOCUMENTS_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.UPLOAD_DOCUMENTS_3,
      },
    ],
  },
  {
    ID: 4,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.DOCUMENTS_CABINET,
    SUBTITLE: 'Inclus',
    LOGO: documentsAndSharepoint,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.DOCUMENTS_CABINET,
    MODULES_FOR_ACCESS: [APP_MODULES.DOCUMENTS_CABINET],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.DOCUMENTS_CABINET_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.DOCUMENTS_CABINET_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.DOCUMENTS_CABINET_3,
      },
    ],
  },
  {
    ID: 5,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.MY_SALARIES,
    SUBTITLE: 'Inclus',
    LOGO: mesSalaries,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.SALARIES_MAIN_PAGE,
    MODULES_FOR_ACCESS: [APP_MODULES.EVP],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.MY_SALARIES_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.MY_SALARIES_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.MY_SALARIES_3,
      },
    ],
  },
];

const BOUQUET_MY_EXCO_CARDS = [
  {
    ID: 2,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.INDICATORS,
    LOGO: indicatorsRH,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.ROOT,
    MODULES_FOR_ACCESS: [APP_MODULES.DASHBOARD_HR, APP_MODULES.DASHBOARD_FIN],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.INDICATORS_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.INDICATORS_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.INDICATORS_3,
      },
      {
        ID: 3,
        TEXT: CARDS_FUNCTIONAL.INDICATORS_4,
      },
    ],
  },
  {
    ID: 3,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.TREASURY,
    LOGO: indicatorsFinances,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.ROOT,
    MODULES_FOR_ACCESS: [APP_MODULES.DASHBOARD_FIN],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.TREASURY_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.TREASURY_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.TREASURY_3,
      },
    ],
  },
  {
    ID: 4,
    TYPE: CARD_TYPES.DEFAULT,
    TITLE: CARDS_TITLES.SEARCH_INVOICES,
    LOGO: invoiceSearch,
    SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
    LINK_WITH_ACCESS: ROUTES.INVOICE_SEARCH,
    MODULES_FOR_ACCESS: [APP_MODULES.SEARCH_INVOICES],
    FUNCTIONAL: [
      {
        ID: 0,
        TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_1,
      },
      {
        ID: 1,
        TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_2,
      },
      {
        ID: 2,
        TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_3,
      },
    ],
  },
];

export const INVOICE_SEARCH_CARD = {
  ID: 4,
  TYPE: CARD_TYPES.DEFAULT,
  TITLE: CARDS_TITLES.SEARCH_INVOICES,
  LOGO: invoiceSearch,
  SERVICE_LP_LINK: ROUTES.MY_EXCO_LANDING,
  LINK_WITH_ACCESS: ROUTES.INVOICE_SEARCH,
  MODULES_FOR_ACCESS: [APP_MODULES.SEARCH_INVOICES],
  FUNCTIONAL: [
    {
      ID: 0,
      TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_1,
    },
    {
      ID: 1,
      TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_2,
    },
    {
      ID: 2,
      TEXT: CARDS_FUNCTIONAL.SEARCH_INVOICES_3,
    },
  ],
};

export const BOUQUET_MY_EXCO = {
  services: BOUQUET_MY_EXCO_CARDS,
  TITLE: CARDS_TITLES.MY_EXCO,
  PACKAGE_NAME: PACKAGES_NAMES.MY_EXCO,
  LANDING_PAGE: ROUTES.MY_EXCO_LANDING,
};
