export const INVOICE_SEARCH_COMMENTARY_MODAL = {
  TITLE: 'Commentaires',
  NEW_COMMENTARY: 'Nouveau commentaire',
  NO_COMMENTS: "Il n'y a pas encore de commentaires",
  CANCEL_BUTTON: 'Annuler',
  OK_BUTTON: 'Envoyer le commentaire',
  CHECKBOX_LABEL:
    'Partager le commentaire avec ma.mon responsable dossier Exco',
  ERROR_REQUIRED_COMMENT: 'Un commentaire est requis',
};
