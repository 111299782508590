import { MONTHS } from 'const/ui';

export const getCurrentMonthNameFromConst = () => {
  const currentMonthIndex = new Date(Date.now()).getMonth();
  return MONTHS[currentMonthIndex].name;
};

export const getMonthIndexFromName = (monthName) =>
  MONTHS.findIndex((month) => month.name === monthName);

export const getPreviousMonthName = (monthIndex) => {
  const prevMonthIndex = monthIndex === 0 ? MONTHS.length - 1 : monthIndex - 1;
  return MONTHS[prevMonthIndex].name;
};

export const getMonthNameFromIndex = (monthIndex) => MONTHS[monthIndex].name;

export const getNextMonthName = (monthIndex) => {
  const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1;
  return MONTHS[nextMonthIndex].name;
};

const isThisFirstMonthOfFiscalYear = (firstMonthName, monthName) => {
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  const monthIndex = getMonthIndexFromName(monthName);
  return Number(monthIndex) === Number(firstMonthIndex);
};

const isThisLastMonthOfFiscalYear = (firstMonthName, monthName) => {
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  const monthForCheckIndex = getMonthIndexFromName(monthName);
  if (firstMonthIndex === 0 && monthForCheckIndex === 11) {
    return true;
  }
  return firstMonthIndex - monthForCheckIndex === 1;
};

// returns Set of types of button that are disabled
// types of buttons can be only 'prev' and 'next'
// prev returns if current month in selector is first month of financial year
// next returns if current month in selector is last mont of financial year or its actual currently going month
export const getDisabledButtonTypes = (firstMonthName, activeMonthName) => {
  const activeMonthIndex = getMonthIndexFromName(activeMonthName);
  const todayMonthIndex = getMonthIndexFromName(getCurrentMonthNameFromConst());
  const isActiveMonthIsTodayMonth = activeMonthIndex === todayMonthIndex;

  const disabledTypesSet = new Set();
  if (isActiveMonthIsTodayMonth) disabledTypesSet.add('next');

  if (isThisFirstMonthOfFiscalYear(firstMonthName, activeMonthName))
    return disabledTypesSet.add('prev');

  if (isThisLastMonthOfFiscalYear(firstMonthName, activeMonthName))
    return disabledTypesSet.add('next');
  return disabledTypesSet;
};
