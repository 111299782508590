import React, { useCallback } from 'react';
import { TimePicker } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';

export const TimePickerFormik = ({ onChange, name, value, ...props }) => {
  const handleChange = useCallback(
    (newValue) => {
      onChange(name, newValue ? newValue.toISOString() : null);
    },
    [onChange, name]
  );
  return <TimePicker {...props} onChange={handleChange} value={value} />;
};

TimePickerFormik.propTypes = formikOnChangeAdaptorTypes;
