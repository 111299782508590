import { fromJS } from 'immutable';
import {
  GET_LOGIN_BANNERS_REQUEST,
  GET_LOGIN_BANNERS_SUCCESS,
  GET_LOGIN_BANNERS_ERROR,
} from '../actions';

const initialState = fromJS({
  banners: [],
  areBannersLoading: false,
});

export const LoginPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_BANNERS_REQUEST:
      return state.set('areBannersLoading', true);
    case GET_LOGIN_BANNERS_SUCCESS:
      return state
        .set('areBannersLoading', false)
        .set('banners', action.payload);
    case GET_LOGIN_BANNERS_ERROR:
      return state.set('areBannersLoading', false);
    default:
      return state;
  }
};
