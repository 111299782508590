export const HR_TABS_IDS = {
  TOTAL_COST: 'Coût entreprise mensuel',
  TOTAL_HOURS: 'Nombre d’heures travaillées',
  CA: 'CA généré pour 1 heure travaillée',
  CA_PERCENTAGE: '% Charges de personnel sur CA',
};

export const HR_TABS = [
  {
    id: HR_TABS_IDS.TOTAL_COST,
    name: HR_TABS_IDS.TOTAL_COST,
    suffix: '€',
  },
  {
    id: HR_TABS_IDS.TOTAL_HOURS,
    name: HR_TABS_IDS.TOTAL_HOURS,
    suffix: 'h',
  },
  {
    id: HR_TABS_IDS.CA,
    name: HR_TABS_IDS.CA,
    suffix: '€',
  },
  {
    id: HR_TABS_IDS.CA_PERCENTAGE,
    name: HR_TABS_IDS.CA_PERCENTAGE,
    suffix: '%',
  },
];
