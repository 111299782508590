import { request } from 'utils/request';
import { getStringDateWithDots } from 'helpers/commentsModal';

export const getAllComments = ({ exercice }) =>
  request.get(`/dashboards/comments?exercice=${exercice}`);

export const addDashboardComment = ({ exercice, comment }) => {
  const date = getStringDateWithDots(comment.date);
  return request.post(`/dashboards/comments`, {
    exercice,
    text: comment.text,
    date,
    type: comment.type,
  });
};

export const editDashboardComment = ({ commentId, commentText }) =>
  request.put(`/dashboards/comments/${commentId}`, { text: commentText });

export const deleteDashboardComment = (commentId) =>
  request.delete(`/dashboards/comments/${commentId}`);
