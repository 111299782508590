import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_BOOKMARKS,
  getBookmarks,
  getBookmarksSucces,
  SET_BOOKMARK,
  EDIT_BOOKMARK,
  DELETE_BOOKMARK,
} from 'modules/usefulLinks/actions';
import {
  getBookmarksRequest,
  setBookmarkRequest,
  editBookmarkRequest,
  deleteBookmarkRequest,
} from 'modules/usefulLinks/services';

function* getBookmarksWorker() {
  try {
    const links = yield call(getBookmarksRequest);

    yield put(getBookmarksSucces({ links }));
  } catch (error) {
    // yield put(getPackagesCostsError());
    console.warn(error);
  }
}

function* setBookmarksWorker({ payload: { name, url } }) {
  try {
    yield call(setBookmarkRequest, name, url);
    yield put(getBookmarks());
  } catch (error) {
    // yield put(getPackagesCostsError());
    console.warn(error);
  }
}

function* editBookmarksWorker({ payload: { id, name, url } }) {
  try {
    yield call(editBookmarkRequest, id, name, url);
    yield put(getBookmarks());
  } catch (error) {
    // yield put(getPackagesCostsError());
    console.warn(error);
  }
}

function* deleteBookmarksWorker({ payload: id }) {
  try {
    yield call(deleteBookmarkRequest, id);
    yield put(getBookmarks());
  } catch (error) {
    // yield put(getPackagesCostsError());
    console.warn(error);
  }
}

export function* bookmarksMainSaga() {
  yield takeLatest(GET_BOOKMARKS, getBookmarksWorker);
  yield takeLatest(SET_BOOKMARK, setBookmarksWorker);
  yield takeLatest(EDIT_BOOKMARK, editBookmarksWorker);
  yield takeLatest(DELETE_BOOKMARK, deleteBookmarksWorker);
}
