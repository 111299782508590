import { fromJS } from 'immutable';
import {
  STATUS_NOT_REQUESTED,
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'const';
import {
  DOWNLOAD_SHAREPOINT_FILE_ERROR,
  DOWNLOAD_SHAREPOINT_FILE_REQUEST,
  DOWNLOAD_SHAREPOINT_FILE_SUCCESS,
  SHAREPOINT_FOLDER_ERROR,
  SHAREPOINT_FOLDER_REQUEST,
  SHAREPOINT_FOLDER_SUCCESS,
  SHAREPOINT_INIT_PAGE,
  SHAREPOINT_RESET_STATUS,
  SHAREPOINT_SEARCH_ERROR,
  SHAREPOINT_SEARCH_REQUEST,
  SHAREPOINT_SEARCH_SUCCESS,
} from '../actions';

const getStoreStructure = () => ({
  status: STATUS_NOT_REQUESTED,
  currentPath: [],
  search: '',
  data: {
    folders: [],
    files: [],
  },
  downloadingDocumentsList: [],
  error: {},
});

const initialState = fromJS(getStoreStructure());

export const documentsCabinet = (state = initialState, action) => {
  switch (action.type) {
    case SHAREPOINT_FOLDER_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('error', initialState.getIn(['error']))
        .set('search', initialState.getIn(['search']))
        .set('currentPath', fromJS(action.payload.path));
    case SHAREPOINT_FOLDER_SUCCESS:
    case SHAREPOINT_SEARCH_SUCCESS: {
      const { folders, files } = action.payload;
      return state
        .set('status', STATUS_SUCCESS)
        .setIn(['data', 'folders'], folders)
        .setIn(['data', 'files'], files);
    }
    case DOWNLOAD_SHAREPOINT_FILE_REQUEST:
      return state.update('downloadingDocumentsList', (documentsIds) =>
        documentsIds.push(fromJS(action.payload.fileId))
      );
    case DOWNLOAD_SHAREPOINT_FILE_SUCCESS:
    case DOWNLOAD_SHAREPOINT_FILE_ERROR:
      return state.update('downloadingDocumentsList', (documentsIds) =>
        documentsIds.filter((id) => id !== action.payload.fileId)
      );
    case SHAREPOINT_FOLDER_ERROR:
    case SHAREPOINT_SEARCH_ERROR:
      return state
        .set('status', STATUS_ERROR)
        .set('data', initialState.getIn(['data']))
        .set('error', action.payload.error);
    case SHAREPOINT_SEARCH_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('error', initialState.getIn(['error']))
        .set('currentPath', initialState.getIn(['currentPath']))
        .set('search', fromJS(action.payload.search));
    case SHAREPOINT_INIT_PAGE:
      return initialState;
    case SHAREPOINT_RESET_STATUS:
      return state.set('status', STATUS_NOT_REQUESTED);
    default:
      return state;
  }
};
