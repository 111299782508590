import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spin, message } from 'antd';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import { downloadSharepointFileRequest } from 'modules/documentsCabinet/actions';
import { useDispatch, useSelector } from 'react-redux';
import Styles from 'components/DocumentsCabinet/DocumentsList/DocumentsList.module.css';
import { selectDownloadingDocumentsList } from 'modules/documentsCabinet/selectors';

const { ERRORS } = DOCUMENTS_CABINET_TRANSLATIONS;

const propTypes = {
  size: PropTypes.string,
  fileId: PropTypes.string,
  fileName: PropTypes.string,
};

export const DownloadButton = memo(({ size, fileId, fileName }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectDownloadingDocumentsList).includes(
    fileId
  );

  const handleFileDownload = () => {
    dispatch(
      downloadSharepointFileRequest({
        fileId,
        fileName,
        onError: () => message.error(ERRORS.DOWNLOAD_FAILED),
      })
    );
  };

  if (!size) {
    return '-';
  }

  if (isLoading) {
    return (
      <span className={Styles.spinSubstitute}>
        <Spin />
      </span>
    );
  }

  return (
    <button
      type="button"
      onClick={handleFileDownload}
      className={Styles.Button_download}
    >
      <Icon type="download" className={Styles.Icon} />
    </button>
  );
});

DownloadButton.propTypes = propTypes;
