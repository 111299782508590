import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocumentRequest } from 'modules/uploadDocuments/actions';
import { selectDownloadingDocumentsList } from 'modules/uploadDocuments/selectors';
import { Spin } from 'antd';
import Styles from './FileName.module.css';

const propTypes = {
  fileName: PropTypes.string,
  fileId: PropTypes.string,
  isDownloadable: PropTypes.bool,
};

export const FileName = ({ fileName, fileId, isDownloadable }) => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(downloadDocumentRequest({ fileId }));
  };

  const isLoading = useSelector(selectDownloadingDocumentsList).includes(
    fileId
  );

  if (!isDownloadable) {
    return <div className={Styles.fileNameWrapper}>{fileName}</div>;
  }

  if (isLoading) {
    return <Spin className={Styles.loader} />;
  }

  return (
    <div className={Styles.fileNameWrapper}>
      <span onClick={handleDownload} className={Styles.fileName}>
        {fileName}
      </span>
    </div>
  );
};

FileName.propTypes = propTypes;
