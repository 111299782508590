import clsx from 'clsx';
import moment from 'moment';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';

export const useValidateIconClassName = () => {
  const {
    paidOutside,
    paymentStatus,
    isPayableInvoice,
    date,
  } = useInvoiceActionsData();

  const isActual = moment(date)
    .add(3, 'y')
    .isAfter(moment());

  const validatedStyle = !paymentStatus ? 'validationIcon-validated' : '';
  const paidOutsideStyle = paidOutside
    ? 'validationIcon-paidOutside'
    : validatedStyle;

  const paymentIconStyle =
    paymentStatus && !paidOutside
      ? `validationIcon-${paymentStatus}`
      : paidOutsideStyle;

  return clsx(
    Styles.Icon,
    isPayableInvoice && isActual
      ? Styles[paymentIconStyle]
      : Styles.IconDisabled
  );
};
