import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from '../../../../const';
import {
  CLEAN_UP_SCHEDULE_DETAIL,
  FETCH_COMPANY_DETAIL,
  FETCH_COMPANY_DETAIL_ERROR,
  FETCH_COMPANY_DETAIL_SUCCESS,
  FETCH_SCHEDULE_DETAIL,
  FETCH_SCHEDULE_DETAIL_ERROR,
  FETCH_SCHEDULE_DETAIL_SUCCESS,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  SCHEDULE_DETAIL_MESSAGE,
  SET_ACTIVE_BRANCH,
} from '../actions';

const initialState = fromJS({
  companyDetailData: null,
  activeBranch: null,
  activeScheduleData: null,
  status: STATUS_NOT_REQUESTED,
  fetchScheduleStatus: STATUS_NOT_REQUESTED,
  scheduleDetailMessage: null,
  schedules: null,
});

export const companySettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_DETAIL:
      return state.set('status', STATUS_LOADING);
    case FETCH_COMPANY_DETAIL_SUCCESS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('companyDetailData', action.payload.data);
    case FETCH_COMPANY_DETAIL_ERROR:
      return state.set('status', STATUS_ERROR);
    case SET_ACTIVE_BRANCH:
      return state.set('activeBranch', action.payload);
    case FETCH_SCHEDULE_DETAIL:
      return state.set('fetchScheduleStatus', STATUS_LOADING);
    case FETCH_SCHEDULE_DETAIL_SUCCESS:
      return state
        .set('fetchScheduleStatus', STATUS_SUCCESS)
        .set('activeScheduleData', action.payload);
    case CLEAN_UP_SCHEDULE_DETAIL:
      return state
        .set('fetchScheduleStatus', STATUS_NOT_REQUESTED)
        .set('activeScheduleData', initialState.activeScheduleData);
    case FETCH_SCHEDULE_DETAIL_ERROR:
      return state.set('fetchScheduleStatus', STATUS_ERROR);
    case SCHEDULE_DETAIL_MESSAGE:
      return state.set('scheduleDetailMessage', action.payload);
    case FETCH_SCHEDULES_REQUEST:
      return state.set('status', STATUS_LOADING);
    case FETCH_SCHEDULES_SUCCESS:
      return state
        .set('status', STATUS_SUCCESS)
        .set('schedules', action.payload);
    default:
      return state;
  }
};
