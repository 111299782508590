export const EXECUTABLE_FILES_EXTENSIONS = [
  'action',
  'apk',
  'app',
  'bin',
  'bat',
  'cmd',
  'com',
  'command',
  'cpl',
  'csh',
  'exe',
  'gadget',
  'inf',
  'ins',
  'inx',
  'ipa',
  'isu',
  'job',
  'jse',
  'ksh',
  'lnk',
  'msc',
  'msi',
  'msp',
  'mst',
  'osx',
  'out',
  'paf',
  'pif',
  'prg',
  'ps1',
  'reg',
  'rgs',
  'run',
  'scr',
  'sct',
  'shb',
  'shs',
  'u3p',
  'vb',
  'vbe',
  'vbs',
  'vbscript',
  'workflow',
  'ws',
  'wsf',
  'wsh',
  'eml',
  'webarchive',
];
