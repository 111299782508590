import React, { memo, useMemo, useCallback } from 'react';
import { Table, Icon, Spin } from 'antd';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { calculateHeaderCellWidth } from 'helpers/salaryHelpers/rewards';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from 'const';
import {
  REWARD_SUFFIXES_BY_TYPES,
  REWARDS_GENERAL_TAB,
} from 'const/SalaryRewardsPage';
import { REWARDS_PAGE } from 'const/translations/SalaryRewardsPage';
import {
  selectEmployeeTransformRewards,
  selectFamilyRewardsTableColumns,
  selectEmployeeRewardsMeta,
  selectRewardsTableStatus,
} from 'modules/SalaryModule/RewardsTable/selectors';
import { getEmployeeRewardsNextPage } from 'modules/SalaryModule/RewardsTable/actions';
import {
  selectChosenFamilyTabId,
  selectCompanyRewardsFamilies,
} from 'modules/SalaryModule/Rewards/selectors';
import { NoRewardsDataFound } from '../NoRewardsDataFound';
import { ColumnTitle } from './ColumnTitle';
import { EditableCell } from './EditableCell';
import Styles from './RewardsTable.module.css';

const propTypes = {
  setEmployeeRewardData: PropTypes.func,
  setEmployeeIdXlsx: PropTypes.func,
  companyId: PropTypes.string,
  activeMonth: PropTypes.object,

  activeFamily: PropTypes.any,
  selectedBranch: PropTypes.any,
};

export const REWARDS_TABLE_ITEM_PER_PAGE = 10;

/**
 * @param setEmployeeIdCsv {function} - sets employee`s id whose csv file is going to be downloaded
 * */
export const RewardsTable = memo(
  ({
    setEmployeeRewardData,
    setEmployeeIdXlsx,
    activeMonth,
    companyId,
    activeFamily,
    selectedBranch,
  }) => {
    const dispatch = useDispatch();
    const employeesRewards = useSelector(selectEmployeeTransformRewards);
    const employeesRewardsMeta = useSelector(selectEmployeeRewardsMeta);
    const familyColumns = useSelector(selectFamilyRewardsTableColumns);
    const families = useSelector(selectCompanyRewardsFamilies);

    const activeTabId = useSelector(selectChosenFamilyTabId);

    const loadingStatus = useSelector(selectRewardsTableStatus);
    const isLoading = [STATUS_LOADING, STATUS_NOT_REQUESTED].includes(
      loadingStatus
    );

    const setEmployeeIdXlsxHandler = useCallback(
      (e) => {
        const { id } = e.currentTarget.dataset;
        setEmployeeIdXlsx(id);
      },
      [setEmployeeIdXlsx]
    );

    const isTableEmpty = useMemo(
      () => employeesRewards.length === 0 || familyColumns.length === 0,
      [familyColumns, employeesRewards]
    );

    const pagination = {
      current: Number(employeesRewardsMeta?.page) || 1,
      total: Number(employeesRewardsMeta?.total) || 1,
      pageSize: REWARDS_TABLE_ITEM_PER_PAGE,
      hideOnSinglePage: true,
      onChange: (page, itemsPerPage) => {
        if (activeMonth && activeFamily && companyId && families) {
          dispatch(
            getEmployeeRewardsNextPage({
              companyId,
              siret: selectedBranch || '',
              rewardFamilyId: activeFamily,
              year: activeMonth.year(),
              month: activeMonth.month() + 1,
              page,
              limit: itemsPerPage,
            })
          );
        }
      },
    };

    const columns = useMemo(
      () => [
        {
          title: REWARDS_PAGE.TABLE_NAME_COLUMN,
          width: 180,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          sorter: (a, b) => a.name.localeCompare(b.name),
          defaultSortOrder: 'ascend',
          sortDirections: ['descend', 'ascend'],
        },
        ...familyColumns
          .filter(
            ({ id }) =>
              activeTabId !== REWARDS_GENERAL_TAB.ID ||
              employeesRewards.some((employee) => Boolean(employee[id]))
          )
          .map(({ name, id, type }) => ({
            title: <ColumnTitle title={name} />,
            width: calculateHeaderCellWidth(name),
            className: Styles.editableCell,
            dataIndex: id,
            key: id,
            render: (value, record) => (
              <EditableCell
                value={value}
                rewardId={id}
                employeeId={record.id}
                handleSave={setEmployeeRewardData}
                suffix={REWARD_SUFFIXES_BY_TYPES[type]}
              />
            ),
          })),
        {
          title: '',
          dataIndex: 'id',
          key: 'actions',
          fixed: 'right',
          render: (id) => (
            <Icon
              onClick={setEmployeeIdXlsxHandler}
              data-id={id}
              type="download"
              className={Styles.Icon}
            />
          ),
        },
      ],
      [
        activeTabId,
        employeesRewards,
        familyColumns,
        setEmployeeIdXlsxHandler,
        setEmployeeRewardData,
      ]
    );

    if (isLoading) {
      return (
        <div className={Styles.loaderWrap}>
          <Spin />
        </div>
      );
    }

    if (!employeesRewards) {
      return <NoRewardsDataFound />;
    }

    return (
      <Table
        bordered
        className={clsx(
          MainStyles.table,
          MainStyles.tableCustomRow,
          Styles.table,
          {
            [Styles.overflowHidden]: isTableEmpty,
          }
        )}
        dataSource={employeesRewards}
        columns={columns}
        pagination={pagination}
        scroll={{ x: 1130 }}
        rowKey="id"
      />
    );
  }
);

RewardsTable.propTypes = propTypes;
