import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { EMPTY_FUNCTION, EURO_SIGN } from 'const';
import { ServiceCardButtons } from 'components/ServiceCard/ServiceCardButtons';
import clsx from 'clsx';
import { STORE_TRANSLATIONS } from 'const/Store';
import { EXCO_SERVICES_PROPTYPE } from 'const/excoServices';
import { PACKAGES_NAMES } from 'const/packages';
import { CART_PAGE_TRANSLATIONS } from 'const/translations/CartPage';
import { handleInternalRedirect } from 'helpers/common';
import { useSelector } from 'react-redux';
import Styles from '../ServiceCard.module.css';
import { ServiceCardDescription } from '../ServiceCardDescription/ServiceCardDescription';

const propTypes = {
  serviceCardObject: EXCO_SERVICES_PROPTYPE,
  isActive: PropTypes.bool,
  isAddedToCart: PropTypes.bool,
  isMinified: PropTypes.bool,
  redirectHandler: PropTypes.func,
  selectIsLoading: PropTypes.func,
};

export const ServiceCardDefault = React.memo(
  ({
    serviceCardObject: {
      FUNCTIONAL,
      PACKAGE_COST,
      SUBTITLE,
      PACKAGE_NAME,
      LINK_WITH_ACCESS,
      SERVICE_LP_LINK,
      ACTION,
      LOGO,
      TITLE,
    },
    isActive = false,
    isAddedToCart = false,
    isMinified = false,
    redirectHandler = handleInternalRedirect,
    selectIsLoading = EMPTY_FUNCTION,
  }) => {
    const isLoading = useSelector(selectIsLoading);

    const onCtaClick = useCallback(() => {
      const url = isActive ? LINK_WITH_ACCESS : SERVICE_LP_LINK;
      if (!url && !ACTION) {
        return;
      }
      redirectHandler(url || ACTION);
    }, [isActive, LINK_WITH_ACCESS, SERVICE_LP_LINK, ACTION, redirectHandler]);

    if (!isActive && !PACKAGE_NAME && !isMinified) {
      return null;
    }

    return (
      <div
        className={clsx(Styles.card, {
          [Styles.isMinified]: isMinified,
          [Styles.notPaid]: !isActive && !isMinified,
        })}
        onClick={onCtaClick}
      >
        <div className={Styles.titleWrapper}>
          <img src={LOGO} alt="service-logo" />
          <div className={Styles.titleBox}>
            <span className={Styles.title}>{TITLE}</span>
            {SUBTITLE && <span className={Styles.subtitle}>{SUBTITLE}</span>}
            {!isActive &&
              (PACKAGE_NAME === PACKAGES_NAMES.WAIBI ? (
                <span className={Styles.costSubtitle}>
                  {CART_PAGE_TRANSLATIONS.COST_TO_BE_DEFINED}
                </span>
              ) : (
                PACKAGE_COST && (
                  <span className={Styles.costSubtitle}>
                    <span className={Styles.titleCostEuro}>
                      {PACKAGE_COST}
                      {EURO_SIGN}
                    </span>
                    <span>{STORE_TRANSLATIONS.PER_MONTH}</span>
                  </span>
                )
              ))}
          </div>
        </div>
        <ServiceCardDescription
          functionalArray={FUNCTIONAL}
          isMultiLink={false}
        />
        <div className={Styles.buttonsWrapper}>
          <ServiceCardButtons
            isMinified={isMinified}
            isActive={isActive}
            isAddedToCart={isAddedToCart}
            isLoading={isLoading}
            packageName={PACKAGE_NAME}
            landingPage={SERVICE_LP_LINK}
          />
        </div>
      </div>
    );
  }
);

ServiceCardDefault.propTypes = propTypes;
