import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  dateStringIntoDate,
  getDayOfWeekNameByIndex,
} from 'modules/SalaryModule/Calendar/helpers';
import moment from 'moment';
import { useHolidays } from 'modules/SalaryModule/Calendar/utils';
import Styles from './CalendarColumnTitle.module.css';

const propTypes = {
  stringDate: PropTypes.string,
};

/**
 * returns calendar table column title depending on date
 * @param stringDate {string} - string date in format DD/MM/YYYY
 * */
export const CalendarColumnHeader = React.memo(({ stringDate }) => {
  const date = dateStringIntoDate(stringDate);

  const { isDateHoliday } = useHolidays(date.getFullYear());

  const isHoliday = isDateHoliday(date);
  const isWeekends = date.getDay() === 0 || date.getDay() === 6;
  const isDayPassedCurrent = moment(date).isBefore(moment(), 'day');

  const dayNumber = date.getDate();
  const weekDay = getDayOfWeekNameByIndex(date.getDay());

  return (
    <div
      className={clsx(Styles.dateTitle, {
        [Styles.sundayTitle]: isWeekends,
        [Styles.holidayTitle]: isHoliday,
        [Styles.passedCurrentDayTitle]: isDayPassedCurrent,
      })}
    >
      <div className={Styles.dayNumber}>{dayNumber}</div>
      <div className={Styles.weekDay}>{weekDay}</div>
    </div>
  );
});

CalendarColumnHeader.propTypes = propTypes;
