import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Icon, Typography, Col, Input, Spin } from 'antd';
import { Button } from 'components/ui';
import { withFormik } from 'formik';
import MainStyles from 'assets/styles/main.module.css';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { formikTypes, PROP_TYPE_STATUS } from 'const/propTypes';
import { Redirect } from 'react-router';
import { ROUTES, STATUS_NOT_REQUESTED, STATUS_SUCCESS } from 'const';
import { VoucherItem } from './VoucherItem';
import { validationSchema } from './Vouchers.yupValidationSchema';
import Styles from './Vouchers.module.css';
import { SharedNotification } from '../SharedNotification';

const {
  VOUCHERS_FORM_TAB: { SUBTITLE, DOCUMENTS, COMMENT_LABEL },
  GENERAL_LABELS,
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  submitStatus: PROP_TYPE_STATUS,
  values: PropTypes.shape({
    employeeEducation: PropTypes.string,
  }),
  salarySituationData: PropTypes.object.isRequired,
  specialSituationsData: PropTypes.object.isRequired,
  onPrev: PropTypes.func,
  ...formikTypes,
};

/**
 * Added abstraction that consists of withFormik HOC
 * and props passed to it. Wraps component on export,
 * passing all formik functionality and props to it.
 */
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({ documentsData = {} }) => ({
    idOrPassport: documentsData.idOrPassport || [],
    workContract: documentsData.workContract || [],
    CPAM: documentsData.CPAM || [],
    disabledCertificate: documentsData.disabledCertificate || [],
    DPAE: documentsData.DPAE || [],
    mutualExclusion: documentsData.mutualExclusion || [],
    comment: documentsData.comment || '',
  }),
  handleSubmit: (
    formValues,
    { validateForm, props: { initiateFormSubmit } }
  ) => {
    validateForm().then((errors) => {
      initiateFormSubmit({
        values: formValues,
        documentsErrors: errors,
        isValid: !Object.keys(errors).length,
      });
    });
  },
});

const VouchersTab = ({
  submitStatus,
  documentsUploadStatus,
  salarySituationData,
  specialSituationsData,
  isFirst4TabsValid,
  onPrev,
  values,
  errors,
  setFieldValue,
  handleSubmit,
  handleChange,
  resetData,
}) =>
  submitStatus === STATUS_SUCCESS ? (
    <Redirect to={ROUTES.EMPLOYEE_REGISTER_TITLE} />
  ) : (
    <div>
      <Row className={clsx(MainStyles.row, Styles.infoWrapper)}>
        <Icon type="exclamation-circle" className={Styles.infoIcon} />
        <Typography.Paragraph className={Styles.info} role="alert">
          {SUBTITLE}
        </Typography.Paragraph>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className={MainStyles.row}>
          <VoucherItem
            name="idOrPassport"
            value={values.idOrPassport}
            onChange={setFieldValue}
            label={DOCUMENTS.ID_CARD_OR_PASSPORT}
          />
          <VoucherItem
            name="workContract"
            value={values.workContract}
            onChange={setFieldValue}
            label={DOCUMENTS.WORK_CONTRACT_COPY}
          />
          <VoucherItem
            name="CPAM"
            value={values.CPAM}
            onChange={setFieldValue}
            label={DOCUMENTS.CPAM_CERTIFICATE}
          />
          <VoucherItem
            name="disabledCertificate"
            value={values.disabledCertificate}
            onChange={setFieldValue}
            label={DOCUMENTS.DISABLED_WORKER_CERTIFICATE}
            optional={!specialSituationsData.isHandicapped}
          />
          <VoucherItem
            name="DPAE"
            value={values.DPAE}
            onChange={setFieldValue}
            label={DOCUMENTS.DPAE_COPY}
            optional
          />
          <VoucherItem
            name="mutualExclusion"
            value={values.mutualExclusion}
            onChange={setFieldValue}
            label={DOCUMENTS.MUTUAL_EXCLUSION}
            optional={!!salarySituationData.hasCompanyInsurance}
          />
        </Row>
        <Row className={MainStyles.row}>
          <Col span={24} className={clsx(MainStyles.col)}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.commentLabel)}
            >
              {COMMENT_LABEL}
            </Typography.Paragraph>
            <Form.Item
              className={clsx(Styles.formItem)}
              validateStatus={errors.employmentParttimeDetails ? 'error' : ''}
            >
              {documentsUploadStatus !== STATUS_NOT_REQUESTED ? (
                <Spin />
              ) : (
                <Input.TextArea
                  className={clsx(Styles.formInput)}
                  onChange={handleChange}
                  value={values.comment}
                  maxLength={1000}
                  rows={5}
                  name="comment"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* TODO: Move out as separate component */}
        <SharedNotification />
        <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
          <Button role="button" big outlined onClick={onPrev}>
            {GENERAL_LABELS.PREV_TAB}
          </Button>
          <Button outlined role="button" big onClick={resetData}>
            {GENERAL_LABELS.RESET}
          </Button>
          <Button
            role="button"
            htmlType="submit"
            success
            big
            disabled={!isFirst4TabsValid || Boolean(Object.keys(errors).length)}
          >
            {GENERAL_LABELS.SUBMIT_FORM}
          </Button>
        </div>
      </Form>
    </div>
  );
VouchersTab.propTypes = propTypes;

export const VouchersFormTab = formikEnhancer(VouchersTab);
