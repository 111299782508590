import React, { memo } from 'react';
import { Row, Col, Typography, Icon } from 'antd';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../const';
import { COMPANY_SETTINGS } from '../../../../const/translations/SalaryCompanySettings';
import Styles from './ScheduleList.module.css';

const { Text } = Typography;

const propTypes = {
  schedules: PropTypes.array,
  openDeleteModal: PropTypes.func,
};

export const ScheduleList = memo(({ schedules, openDeleteModal }) => (
  <div className={Styles.list}>
    {schedules.map((schedule) => (
      <div key={schedule.id} className={Styles.item}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={Styles.ItemHeader}
        >
          <Col className={clsx('ant-typography-ellipsis-single-line')}>
            {schedule.title.link ? (
              <NavLink
                exact
                to={`${ROUTES.SALARIES_SCHEDULE_EMPTY}${schedule.id}${
                  schedule.siret ? `?siret=${schedule.siret}` : ''
                }`}
                className={clsx(MainStyles.link, Styles.scheduleLink)}
              >
                {schedule.title.text}
              </NavLink>
            ) : (
              <span className={MainStyles.link}>
                {COMPANY_SETTINGS.SCHEDULE_DEFAULT.replace(
                  '{{SCHEDULE_NAME}}',
                  schedule.title.text
                )}
              </span>
            )}
          </Col>
          <Col className={Styles.itemActions}>
            {schedule.siret && (
              <Icon
                type="delete"
                theme="filled"
                className={Styles.removeBtn}
                data-id={schedule.id}
                onClick={openDeleteModal}
              />
            )}
          </Col>
        </Row>
        <Row type="flex" align="middle" className={Styles.footerText}>
          <Text className={Styles.text}>{schedule.total}</Text>
          <Text className={Styles.text}>
            {schedule.concerned} {COMPANY_SETTINGS.SCHEDULE_STAFF_MOBILE}
          </Text>
        </Row>
      </div>
    ))}
  </div>
));

ScheduleList.propTypes = propTypes;
