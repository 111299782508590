import { fromJS } from 'immutable';
import {
  GET_EXCO_INFO_MODAL_DATA_REQUEST,
  GET_EXCO_INFO_MODAL_DATA_SUCCESS,
  RESET_EXCO_INFO_MODAL_DATA,
  SEND_EXCO_INFO_ERROR,
  SEND_EXCO_INFO_REQUEST,
  SEND_EXCO_INFO_SUCCESS,
  SET_MODAL_VISIBILITY,
} from 'modules/SalaryModule/SalaryModal/actions';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';

const initialState = fromJS({
  infoModal: {
    isVisible: false,
    info: {
      totalAbsences: '',
      totalVacations: '',
      totalRewards: '',
    },
    lastFinalization: '',
  },
  sendingStatus: STATUS_NOT_REQUESTED,
  status: STATUS_NOT_REQUESTED,
});

export const salaryModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_VISIBILITY:
      return state.setIn(['infoModal', 'isVisible'], action.payload);
    case SEND_EXCO_INFO_REQUEST:
      return state.setIn(['sendingStatus'], STATUS_LOADING);
    case SEND_EXCO_INFO_SUCCESS: {
      return state.setIn(['sendingStatus'], STATUS_SUCCESS);
    }
    case SEND_EXCO_INFO_ERROR: {
      return state.setIn(['sendingStatus'], STATUS_ERROR);
    }
    case GET_EXCO_INFO_MODAL_DATA_REQUEST: {
      return state.setIn(['status'], STATUS_LOADING);
    }
    case GET_EXCO_INFO_MODAL_DATA_SUCCESS: {
      const {
        data: { lastFinalization, ...data },
      } = action.payload;
      return state
        .setIn(['infoModal', 'info'], fromJS(data))
        .setIn(['infoModal', 'lastFinalization'], lastFinalization)
        .setIn(['status'], STATUS_SUCCESS);
    }
    case RESET_EXCO_INFO_MODAL_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
