import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import MainStyles from 'assets/styles/main.module.css';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import flowersImg from 'assets/images/loginNewBg.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  LANDING_ADVANTAGES,
  LANDING_COST_BLOCK,
  LANDING_TOP_SECTION,
} from 'const/ServiceLanding';
import { LANDING_PAGE_TRANSLATIONS } from 'const/translations/ServiceLanding';
import { TERMS_OF_USE_TRANSLATIONS } from 'const/translations/TermsOfUse';
import { ROUTES } from 'const';
import { selectPackagesInCart } from 'modules/cart/selectors';
import {
  selectIsMainUser,
  selectUserPackagesNames,
} from 'modules/loggedUserInfo/selectors';
import { addPackagesToCart, addWaibiToCart } from 'modules/cart/actions';
import { getCurrentButtonType } from 'helpers/landingPage';
import { LANDING_ACTIONS } from 'const/landingPage';
import { openSuccessNotification } from 'helpers/notifications';
import { PACKAGES_NAMES } from 'const/packages';
import Styles from './LandingPage.module.css';
import { LPPriceBlock } from './LPPriceBlock';
import { LandingAdvantagesSection } from '../LandingAdvantagesSection';
import { LPTopSection } from './LPTopSection';

const propTypes = {
  pageName: PropTypes.oneOf(Object.keys(LANDING_TOP_SECTION)),
  simplified: PropTypes.bool,
};

const {
  WAIBI: { CONSULTANT_CONTACT_SUCCESS },
  AVAILABLE_DAY_AND_NIGHT,
  DAY_AND_NIGHT_NUMBERS,
  BACK_TO_STORE,
  BUTTONS_TEXT,
} = LANDING_PAGE_TRANSLATIONS;
const { BACK_TO_HOMEPAGE } = TERMS_OF_USE_TRANSLATIONS;

/**
 * Landing page should have the same name as the package it was created for
 */
export const LandingPage = ({ pageName, simplified = false }) => {
  const dispatch = useDispatch();
  const isMainUser = useSelector(selectIsMainUser);
  const cartItems = useSelector(selectPackagesInCart);
  const currentPackages = useSelector(selectUserPackagesNames);
  const history = useHistory();
  const prevPage = new URLSearchParams(history.location.search).get('prev');

  const [showTooltip, setTooltipVisible] = useState(false);

  const buttonAction = useMemo(
    () => getCurrentButtonType({ pageName, cartItems }),
    [pageName, cartItems]
  );

  const tooltipTimerRef = useRef();
  useEffect(() => {
    if (showTooltip && !tooltipTimerRef.current) {
      tooltipTimerRef.current = setTimeout(() => {
        setTooltipVisible(false);
      }, 3000);
    }
    return () => clearTimeout(tooltipTimerRef);
  }, [dispatch, showTooltip, tooltipTimerRef]);

  const onButtonClickHandler = useCallback(() => {
    window.scrollTo(0, 0);

    switch (buttonAction) {
      case LANDING_ACTIONS.ADD_TO_CART: {
        dispatch(
          addPackagesToCart({
            packageName: pageName,
            currentPackages,
          })
        );
        setTooltipVisible(true);
        return null;
      }
      case LANDING_ACTIONS.VISIT_CART_PAGE: {
        return history.push(ROUTES.CART);
      }
      case LANDING_ACTIONS.CONTACT_CONSULTANT: {
        dispatch(
          addWaibiToCart({
            packageName: [PACKAGES_NAMES.WAIBI],
          })
        );
        openSuccessNotification({
          message: CONSULTANT_CONTACT_SUCCESS,
        });
        return history.push(ROUTES.ROOT);
      }
      default:
        return null;
    }
  }, [history, pageName, dispatch, buttonAction, currentPackages]);

  return (
    <Row>
      <Col span={24} className={Styles.returnHomeWrapper}>
        <div className={MainStyles.container}>
          <Link
            to={isMainUser && prevPage !== 'home' ? ROUTES.STORE : ROUTES.ROOT}
            className={Styles.returnHome}
          >
            <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
            <span>
              {isMainUser && prevPage !== 'home'
                ? BACK_TO_STORE
                : BACK_TO_HOMEPAGE}
            </span>
          </Link>
        </div>
      </Col>
      <Col span={24}>
        <LPTopSection
          {...LANDING_TOP_SECTION[pageName]}
          buttonText={BUTTONS_TEXT[buttonAction]}
          onButtonClick={onButtonClickHandler}
          showTooltip={showTooltip}
        />
      </Col>
      {LANDING_COST_BLOCK[pageName] && (
        <Col span={24} className={Styles.lowerBlockWrapper}>
          {!simplified && (
            <LandingAdvantagesSection
              advantages={LANDING_ADVANTAGES[pageName]}
              pageName={pageName}
            />
          )}
          <div
            className={clsx(MainStyles.container, Styles.textDivider, {
              [Styles.simplified]: simplified,
            })}
          >
            {AVAILABLE_DAY_AND_NIGHT} <span>{DAY_AND_NIGHT_NUMBERS}</span>
          </div>
          <LPPriceBlock
            {...LANDING_COST_BLOCK[pageName]}
            buttonText={BUTTONS_TEXT[buttonAction]}
            simplified={simplified}
            packageName={pageName}
            onButtonClick={onButtonClickHandler}
            showTooltip={showTooltip}
          />
          <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
        </Col>
      )}
    </Row>
  );
};

LandingPage.propTypes = propTypes;
