import { fromJS } from 'immutable';
import {
  STATUS_NOT_REQUESTED,
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'const';
import {
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
  GET_UPLOAD_HISTORY_REQUEST,
  GET_UPLOAD_HISTORY_SUCCESS,
  GET_UPLOAD_HISTORY_ERROR,
  MORE_UPLOAD_HISTORY_SUCCESS,
  MORE_UPLOAD_HISTORY_REQUEST,
  MORE_UPLOAD_HISTORY_ERROR,
  UPLOAD_FILES_RESET_STATUS,
  INIT_UPLOAD_FILES_PAGE,
  DOWNLOAD_DOCUMENT_REQUEST,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
} from '../actions';

const getTypicalStoreStructure = () => ({
  uploadStatus: STATUS_NOT_REQUESTED,
  uploadHistory: {
    data: [],
    meta: {},
    historyStatus: STATUS_NOT_REQUESTED,
    error: {},
  },
  downloadingDocumentsList: [],
  error: {},
});

const initialState = fromJS(getTypicalStoreStructure());

export const uploadDocuments = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPLOAD_HISTORY_REQUEST:
      return initialState.setIn(
        ['uploadHistory', 'historyStatus'],
        STATUS_LOADING
      );
    case MORE_UPLOAD_HISTORY_REQUEST:
      return state.setIn(['uploadHistory', 'historyStatus'], STATUS_LOADING);
    case GET_UPLOAD_HISTORY_SUCCESS:
    case MORE_UPLOAD_HISTORY_SUCCESS: {
      const { documents, meta } = action.payload;
      return state
        .updateIn(['uploadHistory', 'data'], (data) =>
          data.concat(fromJS(documents))
        )
        .setIn(['uploadHistory', 'meta'], fromJS(meta))
        .setIn(['uploadHistory', 'historyStatus'], STATUS_SUCCESS);
    }
    case GET_UPLOAD_HISTORY_ERROR:
    case MORE_UPLOAD_HISTORY_ERROR:
      return state
        .setIn(['uploadHistory', 'error'], fromJS(action.payload.error))
        .setIn(['uploadHistory', 'historyStatus'], STATUS_ERROR);
    case DOWNLOAD_DOCUMENT_REQUEST:
      return state.update('downloadingDocumentsList', (documentsIds) =>
        documentsIds.push(fromJS(action.payload.fileId))
      );
    case DOWNLOAD_DOCUMENT_SUCCESS:
    case DOWNLOAD_DOCUMENT_ERROR:
      return state.update('downloadingDocumentsList', (documentsIds) =>
        documentsIds.filter((id) => id !== action.payload.fileId)
      );
    case UPLOAD_FILES_REQUEST:
      return state.set('uploadStatus', STATUS_LOADING);
    case UPLOAD_FILES_SUCCESS: {
      const { documents } = action.payload;
      return state
        .set('uploadStatus', STATUS_SUCCESS)
        .updateIn(['uploadHistory', 'data'], (data) =>
          data.concat(fromJS(documents))
        );
    }
    case UPLOAD_FILES_ERROR:
      return state
        .set('error', fromJS(action.payload.error))
        .set('uploadStatus', STATUS_ERROR);
    case UPLOAD_FILES_RESET_STATUS:
      return state.set('uploadStatus', STATUS_NOT_REQUESTED);
    case INIT_UPLOAD_FILES_PAGE:
      return initialState;
    default:
      return state;
  }
};
