import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, Row, Col, Select } from 'antd';
import CustomSelectStyles from 'components/ui/CustomSelect/CustomSelect.module.css';
import { Modal } from 'components/ui/Modal';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../../components/Spinner';
import {
  ROUTES,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
} from '../../../../const';
import {
  COMPANY_SETTINGS_REWARDS,
  COMPANY_SETTINGS_REWARDS_DETAIL,
} from '../../../../const/translations/SalaryCompanySettings';
import { selectUserCompanyId } from '../../../../modules/loggedUserInfo/selectors';
import {
  addRewardToFamily,
  getCompanyRewards,
  getRewardsFamily,
  removeRewardFromFamily,
  setRewardFamilyMessage,
  setRewardsFamily,
} from '../../../../modules/SalaryModule/Rewards/actions';
import {
  selectAvailableRewardsOptions,
  selectCompanyRewardsStatus,
  selectCompanyUnaddedRewards,
  selectFamilyData,
  selectFamilyMessage,
  selectFamilyRewardsMapped,
} from '../../../../modules/SalaryModule/Rewards/selectors';
import { getRewardItemRemoveModalBody } from '../../../../modules/SalaryModule/Rewards/utils';
import { Message } from '../Message';
import Styles from './CompanySettingsManageRewardItemPage.module.css';
import { ManageRewardFamilyTitleForm } from './ManageRewardFamilyTitle';
import { EditableRewardsTable } from './EditableRewardsTable';

const { Paragraph } = Typography;

let timeout;

/*
 * Detail page for managing reward family(in company-settings)
 * */
export const CSManageRewardFamily = () => {
  const companyId = useSelector(selectUserCompanyId);
  const familyData = useSelector(selectFamilyData);
  const familyRewards = useSelector(selectFamilyRewardsMapped);
  const familyMessage = useSelector(selectFamilyMessage);
  const unaddedRewards = useSelector(selectCompanyUnaddedRewards);
  const unaddedRewardsOptions = useSelector(selectAvailableRewardsOptions);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    title: '',
    text: '',
    isError: false,
  });
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [selectOpen, setOpen] = useState(false);
  const history = useHistory();
  const loadingStatus = useSelector(selectCompanyRewardsStatus);
  const { id } = useParams();
  const rewardsSelectRef = useRef(null);

  const showRemoveRewardsItemModal = useCallback((e) => {
    const { id: rewardId, removable } = e.currentTarget.dataset;
    setModalData(getRewardItemRemoveModalBody(!!removable, rewardId));
    setIsVisibleModal(true);
  }, []);

  const closeRemoveModal = useCallback(() => {
    setIsVisibleModal(false);
  }, []);

  const submitRemoveModal = useCallback(() => {
    if (!modalData.isError && modalData.rewardId) {
      dispatch(
        removeRewardFromFamily({
          companyId,
          companyRewardId: modalData.rewardId,
          familyId: familyData.id,
        })
      );
    }
    setIsVisibleModal(false);
  }, [companyId, dispatch, familyData, modalData]);

  const onChangeSelect = useCallback(
    (rewardId) => {
      const reward = unaddedRewards.find(({ id: rewId }) => rewId === rewardId);

      if (reward && familyData) {
        dispatch(
          addRewardToFamily({
            companyId,
            data: {
              rewardId,
              rewardFamilyId: familyData.id,
              customName: reward.name,
              customType: reward.type,
            },
          })
        );
      } else {
        dispatch(
          setRewardFamilyMessage({
            type: 'error',
            text: COMPANY_SETTINGS_REWARDS_DETAIL.ERROR_MESSAGE,
          })
        );
      }
      setOpen(false);
      rewardsSelectRef.current.blur();
    },
    [dispatch, unaddedRewards, companyId, familyData, rewardsSelectRef]
  );

  const onSelectInputChange = useCallback(
    (value) => {
      const shouldOpen = value.length >= 3;
      if (shouldOpen && !selectOpen) {
        setOpen(true);
      }
      if (!shouldOpen && selectOpen) {
        setOpen(false);
      }
    },
    [selectOpen]
  );
  const onBlur = useCallback(() => setOpen(false), []);
  const handleFiltering = useCallback(
    (input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    []
  );

  useEffect(() => {
    if (familyMessage) {
      timeout = setTimeout(() => {
        dispatch(setRewardFamilyMessage(null));
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch, familyMessage]);

  useEffect(
    () => () => {
      dispatch(setRewardFamilyMessage(null));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentCompany) {
      setCurrentCompany(companyId);
      return;
    }
    if (currentCompany !== companyId) {
      history.push(`${ROUTES.SALARIES_COMPANY_SETTINGS}?tab=3`);
      return;
    }
    if (id && companyId) {
      dispatch(
        getRewardsFamily({
          companyId,
          familyId: id,
        })
      );
    }
    if (companyId) {
      dispatch(getCompanyRewards({ companyId }));
    }
  }, [currentCompany, history, companyId, dispatch, id]);

  useEffect(
    () => () => {
      dispatch(setRewardsFamily(null));
    },
    [dispatch]
  );

  if ([STATUS_NOT_REQUESTED, STATUS_LOADING].includes(loadingStatus)) {
    return <Spinner />;
  }

  return (
    <div className={Styles.container}>
      <ManageRewardFamilyTitleForm
        companyId={companyId}
        familyData={familyData}
      />
      {familyMessage && (
        <Row type="flex" align="middle" className={Styles.row}>
          <Col xs={24} sm={24}>
            <Message leftGutter={false} type={familyMessage.type}>
              {familyMessage.text}
            </Message>
          </Col>
        </Row>
      )}
      <Row type="flex" align="middle" className={Styles.row}>
        <Col xs={24} sm={24} lg={4}>
          <Typography.Text
            className={clsx(
              MainStyles.heading,
              MainStyles.formLabel,
              Styles.label
            )}
          >
            {COMPANY_SETTINGS_REWARDS.SELECT_TITLE}
          </Typography.Text>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Select
            size="large"
            showArrow
            className={clsx(
              CustomSelectStyles.select,
              CustomSelectStyles.showSearch,
              Styles.select
            )}
            dropdownClassName={CustomSelectStyles.dropdown}
            placeholder={
              COMPANY_SETTINGS_REWARDS.AUTOCOMPLETE_SELECT_PLACEHOLDER
            }
            value={undefined}
            mode="multiple"
            onSelect={onChangeSelect}
            filterOption={handleFiltering}
            onSearch={onSelectInputChange}
            onBlur={onBlur}
            open={selectOpen}
            ref={rewardsSelectRef}
          >
            {unaddedRewardsOptions.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Typography.Text
        className={clsx(MainStyles.heading, MainStyles.formLabel, Styles.label)}
      >
        {COMPANY_SETTINGS_REWARDS.LIST_TITLE} :
      </Typography.Text>

      <EditableRewardsTable
        companyId={companyId}
        rewardsList={familyRewards}
        onRemoveRewardsItem={showRemoveRewardsItemModal}
      />
      <Modal
        fullMobileScreen={false}
        isVisible={isVisibleModal}
        width="384px"
        title={modalData.title}
        okText={COMPANY_SETTINGS_REWARDS.CONFIRM_TEXT}
        onClose={closeRemoveModal}
        isError={modalData.isError}
        onSubmit={submitRemoveModal}
        closable={false}
        isSmallModal
      >
        <Paragraph>
          {modalData.text}
          {modalData.isError && (
            <>
              <a
                href={ROUTES.SALARIES_REWARDS}
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.link}
              >
                {COMPANY_SETTINGS_REWARDS_DETAIL.REMOVE_CANCEL_LINK}
              </a>
              {modalData.text2}
            </>
          )}
        </Paragraph>
      </Modal>
    </div>
  );
};
