import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table } from 'antd';
import clsx from 'clsx';
import { DATE_FORMAT } from 'const';
import MainStyles from 'assets/styles/main.module.css';
import {
  calculateDetailedInfoPageTotals,
  separateNumber,
} from 'helpers/common';
import { COUNTERPARTY_TABLE_TRANSLATIONS } from 'const/translations';
import Styles from './CounterpartyTable.module.css';

const propTypes = {
  dataSource: PropTypes.array,
};

const { TOTAL, COLUMNS_HEADERS } = COUNTERPARTY_TABLE_TRANSLATIONS;

const COLUMNS = [
  {
    title: COLUMNS_HEADERS.DATE,
    className: Styles.emboldened,
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    defaultSortOrder: 'ascend',
    sortDirections: ['descend', 'ascend'],
    render: (date) => moment(date).format(DATE_FORMAT),
    width: 120,
  },
  {
    title: COLUMNS_HEADERS.OP_NUMBER,
    dataIndex: 'operationNumber',
    key: 'operationNumber',
    sorter: (a, b) => a.operationNumber.localeCompare(b.operationNumber),
    sortDirections: ['descend', 'ascend'],
    width: 120,
  },
  {
    title: COLUMNS_HEADERS.DESCRIPTION,
    dataIndex: 'description',
    key: 'description',
    sorter: (a, b) => a.description.localeCompare(b.description),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: COLUMNS_HEADERS.DEBIT,
    dataIndex: 'debit',
    key: 'debit',
    width: 110,
    sorter: (a, b) => a.debit - b.debit,
    sortDirections: ['descend', 'ascend'],
    render: (debit) => (
      <div className={Styles.debit}>
        {debit ? separateNumber(debit.toFixed(2)) : debit}
      </div>
    ),
  },
  {
    title: COLUMNS_HEADERS.CREDIT,
    dataIndex: 'credit',
    key: 'credit',
    width: 110,
    sorter: (a, b) => a.credit - b.credit,
    sortDirections: ['descend', 'ascend'],
    render: (credit) => (
      <div className={Styles.credit}>
        {credit ? separateNumber(credit.toFixed(2)) : credit}
      </div>
    ),
  },
  {
    title: COLUMNS_HEADERS.AMOUNT,
    dataIndex: 'amount',
    key: 'amount',
    width: 110,
    sorter: (a, b) => a.amount - b.amount,
    sortDirections: ['descend', 'ascend'],
    render: (amount) => (
      <div className={Styles.saldo}>
        {amount ? separateNumber(amount.toFixed(2)) : amount}
      </div>
    ),
  },
];

/**
 * Antd Table drop provided dataSource to footer func as parameter
 * @param dataSource - same thing that is provided to dataSource prop in Table.
 * @returns {*}
 */
const getFooterRow = (dataSource) => {
  const { debit, credit, amount } = calculateDetailedInfoPageTotals(dataSource);
  return (
    <div className={Styles.tableFooter}>
      <div className={Styles.totalName}>{TOTAL}</div>
      <div className={Styles.debitSum}>
        {debit ? separateNumber(debit.toFixed(2)) : ''}
      </div>
      <div className={Styles.creditSum}>
        {credit ? separateNumber(credit.toFixed(2)) : ''}
      </div>
      <div className={Styles.saldoSum}>
        {amount ? separateNumber(amount.toFixed(2)) : ''}
      </div>
    </div>
  );
};

export const CounterpartyTable = ({ dataSource }) => (
  <div className={Styles.debtorsTable}>
    <Table
      columns={COLUMNS}
      dataSource={dataSource}
      className={clsx(MainStyles.table, Styles.table)}
      pagination={{
        hideOnSinglePage: true,
        pageSize: 20,
      }}
      footer={getFooterRow}
    />
  </div>
);

CounterpartyTable.propTypes = propTypes;
