import React, { useCallback } from 'react';
import { Button } from 'components/ui/Button';
import PropTypes from 'prop-types';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { APP_MODULES, ROUTES } from 'const';
import { useDispatch, useSelector } from 'react-redux';
import { PillarLoader } from 'components/ui/PillarLoader';
import { message } from 'antd';
import { history } from 'routing/history';
import { waibiAccessRequest } from 'modules/dashboardIndicators/action';
import { selectIsWaibiLoading } from 'modules/dashboardIndicators/selectors';
import { Spinner } from 'components/Spinner';
import Styles from './DashboardIndicators.module.css';

const { WAIBI } = APP_MODULES;
const { ADVANCED_INDICATORS } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const propTypes = {
  userModules: PropTypes.arrayOf(PropTypes.string),
};

export const DashboardIndicators = ({ userModules }) => {
  const dispatch = useDispatch();
  const isSubscribedToWaibi = userModules.includes(WAIBI);

  const isLoading = useSelector(selectIsWaibiLoading);

  const requestWaibiAccess = useCallback(() => {
    dispatch(
      waibiAccessRequest({
        onError: (errorMessage) => message.error(errorMessage),
      })
    );
  }, [dispatch]);

  const navigateToWaibiLanding = () => {
    history.push(`${ROUTES.WAIBI_LANDING}/?prev=home`);
  };

  const description = isSubscribedToWaibi ? (
    <p className={Styles.descriptionSubscribed}>
      {ADVANCED_INDICATORS.DESCRIPTION_SUBSCRIBED}
    </p>
  ) : (
    <p>{ADVANCED_INDICATORS.DESCRIPTION_UNSUBSCRIBED}</p>
  );

  return (
    <div className={Styles.indicatorsWrapper}>
      <h3 className={Styles.indicatorsTitle}>{ADVANCED_INDICATORS.TITLE}</h3>
      <div className={Styles.indicatorsDescription}>
        <PillarLoader colored className={Styles.indicatorsPillars} />
        {description}
      </div>
      {isSubscribedToWaibi ? (
        <Button
          variant="success"
          className={Styles.indicatorsButton}
          disabled={isLoading}
          onClick={requestWaibiAccess}
          sizeAuto
        >
          {isLoading ? (
            <Spinner className={Styles.indicatorsButtonSpinner} />
          ) : (
            ADVANCED_INDICATORS.BUTTON_SUBSCRIBED
          )}
        </Button>
      ) : (
        <Button
          variant="secondary"
          className={Styles.indicatorsButton}
          onClick={navigateToWaibiLanding}
          sizeAuto
        >
          {ADVANCED_INDICATORS.BUTTON_UNSUBSCRIBED}
        </Button>
      )}
    </div>
  );
};

DashboardIndicators.propTypes = propTypes;
