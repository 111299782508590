import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Col, Typography } from 'antd';
import { withFormik } from 'formik';

import { omitEmptyKeys } from 'helpers/common';
import { Button, Checkbox, Input } from 'components/ui';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { formikTypes } from 'const/propTypes';
import MainStyles from 'assets/styles/main.module.css';
import { DatePickerFormik, SelectFormik } from 'components/adaptors';
import { SharedNotification } from '../SharedNotification';
import { validationSchema } from './SalarySituation.yupValidationSchema';
import Styles from './SalarySituation.module.css';

const {
  SALARY_SITUATION_FORM_TAB: { LABELS, LISTS },
  GENERAL_LABELS,
  GENERAL_PLACEHOLDERS,
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  values: PropTypes.shape({
    degreeEmployee: PropTypes.string,
    beforeEmployment: PropTypes.string,
    hasCompanyInsurance: PropTypes.bool,
    hasAnotherJob: PropTypes.bool,
    since: PropTypes.date,
    beforeEmploymentDetails: PropTypes.string,
  }),
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  updateSalarySituation: PropTypes.func,
  ...formikTypes,
};

/**
 * Added abstraction that consists of withFormik HOC
 * and props passed to it. Wraps component on export,
 * passing all formik functionality and props to it.
 */
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({ salarySituationData = {} }) => ({
    degreeEmployee: salarySituationData.degreeEmployee || undefined,
    beforeEmployment: salarySituationData.beforeEmployment || undefined,
    hasCompanyInsurance:
      salarySituationData.hasCompanyInsurance !== undefined
        ? salarySituationData.hasCompanyInsurance
        : true,
    hasAnotherJob: salarySituationData.hasAnotherJob || false,
    since: salarySituationData.since || null,
    beforeEmploymentDetails: salarySituationData.beforeEmploymentDetails || '',
  }),

  mapPropsToErrors: ({ salarySituationErrors = {} }) => salarySituationErrors,
});

const SalarySituationTab = ({
  onNext,
  onPrev,
  updateSalarySituation,
  values,
  errors,
  handleChange,
  validateForm,
  setFieldValue,
  setErrors,
  isTabValid,
  salarySituationData,
  resetData,
}) => {
  /**
   * Next two useEffect hooks imitate
   * componentWillUnmount lifecycle method behavior.
   */
  const valuesRef = useRef();
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);
  useEffect(
    () => () => {
      validateForm().then((foundErrors) => {
        const isValid = !Object.keys(foundErrors).length;
        updateSalarySituation({
          values: {
            ...omitEmptyKeys(valuesRef.current),
            isValid,
          },
          salarySituationErrors: foundErrors,
          isValid,
        });
      });
    },
    [validateForm, updateSalarySituation]
  );
  /**
   * Additional validation in case tab data has come from backend,
   * where only values and status are saved,
   */
  useEffect(() => {
    if (Object.values(salarySituationData).length && !isTabValid) {
      validateForm().then((foundErrors) => {
        setErrors(foundErrors);
      });
    }
  }, [values, salarySituationData, isTabValid, setErrors, validateForm]);
  return (
    <Form>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.EMPLOYEE_EDUCATION}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.degreeEmployee ? 'error' : ''}
          >
            <SelectFormik
              placeholder={GENERAL_LABELS.CHOOSE}
              onChange={setFieldValue}
              options={LISTS.DEGREE_EMPLOYEE}
              name="degreeEmployee"
              value={values.degreeEmployee}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={clsx(MainStyles.col, Styles.colTop)}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.SITUATION_BEFORE_HIRING}
          </Typography.Paragraph>
          <div>
            <Form.Item className={Styles.formItem}>
              <SelectFormik
                allowClear
                className={Styles.beforeEmploymentSelect}
                placeholder={GENERAL_LABELS.CHOOSE}
                onChange={setFieldValue}
                options={LISTS.BEFORE_EMPLOYMENT}
                name="beforeEmployment"
                value={values.beforeEmployment}
              />
            </Form.Item>
            {values.beforeEmployment === GENERAL_LABELS.OTHER && (
              <Col span={24} className={MainStyles.col}>
                <Form.Item
                  className={Styles.formItem}
                  validateStatus={errors.beforeEmploymentDetails ? 'error' : ''}
                >
                  <Input
                    placeholder={GENERAL_PLACEHOLDERS.DESCRIBE_OTHER}
                    onChange={handleChange}
                    value={values.beforeEmploymentDetails}
                    name="beforeEmploymentDetails"
                  />
                </Form.Item>
              </Col>
            )}
            {Boolean(values.beforeEmployment) && (
              <Col span={24} className={MainStyles.col}>
                <Typography.Paragraph
                  className={clsx(
                    MainStyles.formLabel,
                    Styles.formLabel,
                    Styles.formLDateLabel
                  )}
                >
                  {GENERAL_LABELS.SINCE}
                </Typography.Paragraph>
                <Form.Item
                  className={Styles.formItem}
                  validateStatus={errors.since ? 'error' : ''}
                >
                  <DatePickerFormik
                    className={Styles.employmentDetailsInput}
                    placeholder={GENERAL_PLACEHOLDERS.DATE_FORMAT}
                    onChange={setFieldValue}
                    name="since"
                    value={values.since}
                  />
                </Form.Item>
              </Col>
            )}
          </div>
        </Col>
      </Row>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.COMPANY_BENEFICIARY}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              onChange={handleChange}
              name="hasCompanyInsurance"
              checked={values.hasCompanyInsurance}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.SECOND_JOB}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              role="checkbox"
              onChange={handleChange}
              name="hasAnotherJob"
              checked={values.hasAnotherJob}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {/* TODO: Move out as separate component */}
      <SharedNotification />
      <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
        <Button role="button" big outlined onClick={onPrev}>
          {GENERAL_LABELS.PREV_TAB}
        </Button>
        <Button outlined role="button" big onClick={resetData}>
          {GENERAL_LABELS.RESET}
        </Button>
        <Button role="button" big onClick={onNext}>
          {GENERAL_LABELS.NEXT_TAB}
        </Button>
      </div>
    </Form>
  );
};

SalarySituationTab.propTypes = propTypes;

export const SalarySituationFormTab = formikEnhancer(SalarySituationTab);
