import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import {
  APP_MODULES,
  ROUTES,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
} from 'const';
import { useChartFilters } from 'hooks/useChartFilters';
import MainStyles from 'assets/styles/main.module.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { Section } from 'components/Section';
import { Col, Row, Table } from 'antd';
import { ViewSwitch } from 'components/ui/ViewSwitch';
import { Legends } from 'components/Charts/Legends';
import { getProperLookingAmount } from 'helpers/shortPreviewCards';
import { ChartTabs } from 'components/ChartTabs';
import { DASHBOARD_NAMES } from 'const/charts';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import {
  selectUserAuthorizedModules,
  selectUserCurrentCompany,
  selectUserCurrentCompanyExerciceWithYear,
  selectUserCurrentCompanyMaxPeriod,
} from 'modules/loggedUserInfo/selectors';
import { LineChart } from 'components/Charts/LineChart';
import { BarChart } from 'components/Charts/BarChart';
import { ChartSelect } from 'components/ui/ChartSelect';
import clsx from 'clsx';
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { ChartFilterCheckbox } from 'components/ui/ChartFilterCheckbox';
import {
  getMaxPeriodMonthIndex,
  getMaxPeriodValuesForEachTabFromDashboardData,
  getMonthsArrayFromFirstFiscalMonthToCurrent,
  getTotalHRTableValues,
} from 'helpers/HRhelpers';
import {
  HR_PAGE_ERRORS,
  HR_PAGE_TRANSLATIONS,
} from 'const/translations/HRPage';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { getHRDataError, getHRDataRequest } from 'modules/HRPage/actions';
import {
  selectCAYearTotal,
  selectCumulHRDashboardYearData,
  selectHRDashboardYearData,
  selectHRLastUpdateDate,
  selectHRStatus,
} from 'modules/HRPage/selectors';
import { PillarLoader } from 'components/ui/PillarLoader';
import { Button } from 'components/ui/Button';
import {
  selectCommentsStatus,
  selectHRCommentsForYearChart,
} from 'modules/commentsModal/selectors';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { CommentsModal } from 'components/CommentsModal';
import {
  getAllCommentsError,
  getAllCommentsRequest,
} from 'modules/commentsModal/actions';
import { HR_TABS } from 'const/HRPage';
import Styles from './HRPage.module.css';
import { LastUpdate } from '../../components/LastUpdate';

const { TITLES } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const { LINE } = CHARTS_VIEW_SWITCH_TYPES;

const { MY_INDICATORS_SECTION_TITLE } = HOMEPAGE_FORM_TRANSLATIONS;

const { ROOT } = ROUTES;

const {
  CUMUL,
  EXERCICE,
  TABLE_TITLE,
  ERROR_BUTTON_TEXT,
  ERROR_TEXT,
} = HR_PAGE_TRANSLATIONS;

const { NO_EVP_MODULE, NO_EVP_MODULE_ON_HR } = HR_PAGE_ERRORS;

const { EVP } = APP_MODULES;

const HRPageComponent = () => {
  const {
    activeYear,
    activeMonthName,
    chartViewActiveMode,
    setChartViewActiveMode,
    activeDateViewOption,
  } = useChartFilters();

  useScrollToTop();

  const dispatch = useDispatch();

  const { id: companyId } = useSelector(selectUserCurrentCompany);

  const userModules = useSelector(selectUserAuthorizedModules);

  const { currentExercice, currentYearFromExercice } = useSelector(
    selectUserCurrentCompanyExerciceWithYear
  );

  const maxPeriod = useSelector(selectUserCurrentCompanyMaxPeriod);
  useEffect(() => {
    if (userModules.includes(EVP)) {
      dispatch(getHRDataRequest({ companyId, currentExercice, maxPeriod }));
      dispatch(getAllCommentsRequest({ currentExercice }));
      return;
    }
    dispatch(getHRDataError({ error: { message: NO_EVP_MODULE } }));
    dispatch(getAllCommentsError({ error: { message: NO_EVP_MODULE_ON_HR } }));
  }, [companyId, currentExercice, maxPeriod, dispatch, userModules]);

  const firstMonth = useSelector(selectCurrentCompanyFirstFiscalMonth);
  const updateDate = useSelector(selectHRLastUpdateDate);

  const [hiddenLinesYears, setHiddenLinesYears] = useState([]);

  const HRTabs = useMemo(() => HR_TABS, []);

  const [activeTabId, setActiveTabId] = useState('');
  useEffect(() => {
    setActiveTabId(HRTabs[0]?.id || '');
  }, [HRTabs, setActiveTabId]);
  const changeActiveTab = useCallback((value) => setActiveTabId(value), [
    setActiveTabId,
  ]);

  const Chart = chartViewActiveMode === LINE ? LineChart : BarChart;

  const HRDashboardData = useSelector(selectHRDashboardYearData);

  const maxPeriodValues = useMemo(
    () =>
      getMaxPeriodValuesForEachTabFromDashboardData(
        HRDashboardData,
        firstMonth,
        maxPeriod
      ),
    [HRDashboardData, firstMonth, maxPeriod]
  );

  const HRTabsWithValuesInMaxPeriod = useMemo(
    () =>
      HRTabs.map(({ id, ...rest }) => ({
        ...rest,
        value: maxPeriodValues[id],
        id,
      })),
    [maxPeriodValues, HRTabs]
  );

  const chartData = useMemo(
    () =>
      HRDashboardData.find(({ tabId }) => tabId === activeTabId)?.tabValues ||
      [],
    [activeTabId, HRDashboardData]
  );

  const HRCumulDashboardData = useSelector(selectCumulHRDashboardYearData);
  const HRCumulChartData = useMemo(
    () =>
      HRCumulDashboardData.find(({ tabId }) => tabId === activeTabId)
        ?.tabValues,
    [HRCumulDashboardData, activeTabId]
  );

  const totalCaValuesByYears = useSelector(selectCAYearTotal);
  const totalHRTableValues = useMemo(
    () =>
      getTotalHRTableValues({
        HRDashboardData,
        HRTabs,
        maxPeriod,
        firstMonthName: firstMonth,
        totalCaValuesByYears,
      }),
    [HRTabs, HRDashboardData, maxPeriod, firstMonth, totalCaValuesByYears]
  );

  const monthsArrayFromFirstFiscalMonthToCurrent = useMemo(
    () => getMonthsArrayFromFirstFiscalMonthToCurrent(firstMonth, maxPeriod),
    [firstMonth, maxPeriod]
  );

  const [isTableCumulative, setIsTableCumulative] = useState(false);
  const toggleIsTableCumulative = useCallback(() => {
    setIsTableCumulative((prev) => !prev);
  }, [setIsTableCumulative]);

  const [isChartCumulative, setIsChartCumulative] = useState(false);
  const toggleIsChartCumulative = useCallback(() => {
    setIsChartCumulative((prev) => !prev);
  }, [setIsChartCumulative]);

  const [hiddenTableYears, setHiddenTableYears] = useState([]);

  const commentsFetchStatus = useSelector(selectCommentsStatus);
  const HRRequestStatus = useSelector(selectHRStatus);
  const isLoading = [HRRequestStatus, commentsFetchStatus].some(
    (status) => status === STATUS_LOADING || status === STATUS_NOT_REQUESTED
  );
  const isError = [HRRequestStatus, commentsFetchStatus].some(
    (status) => status === STATUS_ERROR
  );

  const HRCommentsForYear = useSelector(selectHRCommentsForYearChart);

  const {
    onOpenCommentsModal,
    onCloseCommentsModal,
    isModalOpen,
  } = useCommentsModalCallbacks();

  const columns = useMemo(
    () => [
      {
        dataIndex: 'tabId',
        ellipsis: true,
        width: 200,
        fixed: 'left',
        render: (tabId) => {
          const tabName = HRTabs.find(({ id }) => id === tabId).name;
          return <div className={Styles.rowName}>{tabName}</div>;
        },
      },
      ...monthsArrayFromFirstFiscalMonthToCurrent.map(
        ({ shortLabel, name }, monthSequenceIndex) => {
          const monthIndex = getMonthIndexFromName(name);
          const maxPeriodMonthIndex = getMaxPeriodMonthIndex(maxPeriod);
          const isItCurrentMonth = maxPeriodMonthIndex === monthIndex;
          return {
            title: <div className={Styles.monthColumnHeader}>{shortLabel}</div>,
            dataIndex: `tabValues`,
            key: `${name}tabValues`,
            className: clsx(Styles.monthValuesTdClass, {
              [Styles.currentMonth]: isItCurrentMonth,
            }),
            render: (tabValues, record) => {
              const { tabId } = record || {};

              return (
                <div
                  className={Styles.monthValuesCell}
                  key={`${record?.tabId}${name}`}
                >
                  {tabValues.map(({ values, id: valueId, year }, index) => (
                    <div
                      className={clsx(Styles.annualValue, {
                        [Styles.currentCellYear]: index === 0,
                        [Styles.prevCellYear]: index === 1,
                        [Styles.prevPrevCellYear]: index === 2,
                        [Styles.displayNone]: hiddenTableYears.includes(year),
                      })}
                      key={`${valueId}${year}${name}`}
                    >
                      {!values[monthSequenceIndex] &&
                      !(values[monthSequenceIndex] === 0) ? (
                        <span className={Styles.monthValueNumber}>N/A</span>
                      ) : (
                        <>
                          <span className={Styles.monthValueNumber}>
                            {getProperLookingAmount(
                              Number(values[monthSequenceIndex])
                            )}
                          </span>
                          <span className={Styles.monthValueSuffix}>
                            {HRTabs.find(({ id }) => id === tabId).suffix}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              );
            },
          };
        }
      ),
      {
        title: 'Total',
        className: Styles.monthValuesTdClass,
        render: (_, record) => {
          const { tabId } = record;
          const totalValues = totalHRTableValues[tabId];
          const key = `total${tabId}`;
          return (
            <div key={key} className={Styles.monthValuesCell}>
              {totalValues.map(({ totalValue, id: totalId, year }, index) => (
                <div
                  className={clsx(Styles.annualValue, {
                    [Styles.currentCellYear]: index === 0,
                    [Styles.prevCellYear]: index === 1,
                    [Styles.prevPrevCellYear]: index === 2,
                    [Styles.displayNone]: hiddenTableYears.includes(year),
                  })}
                  key={totalId}
                >
                  {Number.isNaN(totalValue) ? (
                    <span className={Styles.monthValueNumber}>N/A</span>
                  ) : (
                    <>
                      <span className={Styles.monthValueNumber}>
                        {getProperLookingAmount(totalValue)}
                      </span>
                      <span className={Styles.monthValueSuffix}>
                        {HRTabs.find(({ id }) => id === tabId).suffix}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: '',
        className: Styles.emptyColumn,
      },
    ],
    [
      monthsArrayFromFirstFiscalMonthToCurrent,
      hiddenTableYears,
      totalHRTableValues,
      HRTabs,
      maxPeriod,
    ]
  );

  return (
    <section className={Styles.page}>
      <CommentsModal isOpen={isModalOpen} closeModal={onCloseCommentsModal} />
      <div className={MainStyles.container}>
        <Link to={ROOT} className={Styles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
          <span>{MY_INDICATORS_SECTION_TITLE}</span>
        </Link>
      </div>
      <Section title={TITLES.HR}>
        <ChartSelect
          options={HRTabsWithValuesInMaxPeriod}
          activeValue={activeTabId}
          onChange={changeActiveTab}
          className={Styles.chartSelect}
          itemsHasValue
        />
        <Row className={Styles.contentRow}>
          {!isError && (
            <ChartTabs
              dashboardName={DASHBOARD_NAMES.HR}
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
              maxItemsOutsideSelector={4}
              tabItems={HRTabsWithValuesInMaxPeriod}
              horizontal
              className={Styles.chartTabs}
              tabNamesInFullWidth
            />
          )}
          <Col span={24} className={Styles.filtersWrapper}>
            <div className={Styles.dateSwitchAndMonthSelector}>
              <div className={Styles.currentYear}>
                <div className={Styles.exercice}>{EXERCICE}</div>
                <div>{currentYearFromExercice}</div>
              </div>
            </div>
            <div className={Styles.chartFilters}>
              <ChartFilterCheckbox
                toggleChecked={toggleIsChartCumulative}
                id="HRPageEnCumulChartCheckbox"
                checked={isChartCumulative}
                label={CUMUL}
                className={Styles.tableCumulCheckbox}
              />
              <ViewSwitch
                activeOption={chartViewActiveMode}
                setActiveOption={setChartViewActiveMode}
                className={Styles.chartViewSwitch}
              />
              <Legends
                chartData={chartData}
                hiddenLinesYears={hiddenLinesYears}
                setHiddenLineYears={setHiddenLinesYears}
                className={Styles.chartLegends}
              />
            </div>
          </Col>
          {isLoading && (
            <Col span={24} className={Styles.loaderWrapper}>
              <PillarLoader colored />
            </Col>
          )}
          {!isLoading &&
            (isError ? (
              <Col span={24} className={Styles.loaderWrapper}>
                <PillarLoader />
                <div className={Styles.errorText}>{ERROR_TEXT}</div>
                <a
                  href={ROUTES.CONTACTS}
                  target="_blank"
                  className={Styles.errorButton}
                  rel="noopener noreferrer"
                >
                  <Button variant="secondary">{ERROR_BUTTON_TEXT}</Button>
                </a>
              </Col>
            ) : (
              <Col span={24} className={Styles.contentCol}>
                <div className={Styles.chartWrapper}>
                  <Chart
                    firstMonth={firstMonth}
                    activeYear={activeYear}
                    data={isChartCumulative ? HRCumulChartData : chartData}
                    activeDateViewOption={activeDateViewOption}
                    activeMonthName={activeMonthName}
                    hiddenLinesYears={hiddenLinesYears}
                    gradientHeight={600}
                    enableGrid
                    dashboardName={DASHBOARD_NAMES.HR}
                    activeTabId={activeTabId}
                    comments={HRCommentsForYear}
                    openCommentsModal={onOpenCommentsModal}
                  />
                </div>
              </Col>
            ))}
        </Row>
        {!isLoading && !isError && (
          <>
            <div className={Styles.tableFiltersWrapper}>
              <div className={Styles.tableTitle}>{TABLE_TITLE}</div>
              <div className={Styles.tableFilter}>
                <ChartFilterCheckbox
                  toggleChecked={toggleIsTableCumulative}
                  id="HRPageEnCumulCheckbox"
                  checked={isTableCumulative}
                  label={CUMUL}
                  className={Styles.tableCumulCheckbox}
                />
                <Legends
                  chartData={chartData}
                  hiddenLinesYears={hiddenTableYears}
                  setHiddenLineYears={setHiddenTableYears}
                />
              </div>
            </div>
            <Table
              className={Styles.table}
              dataSource={
                isTableCumulative ? HRCumulDashboardData : HRDashboardData
              }
              columns={columns}
              pagination={{
                pageSize: 20,
                hideOnSinglePage: true,
              }}
              scroll={{ x: 1120 }}
              rowKey="tabId"
            />
          </>
        )}
        <LastUpdate
          updateDate={updateDate}
          showCondition={Boolean(currentExercice)}
          className={Styles.updateDate}
        />
      </Section>
    </section>
  );
};

export const HRPage = React.memo(HRPageComponent);
