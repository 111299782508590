import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Input } from 'antd';
import Styles from './Input.module.css';

const propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

/**
    Styles.input - default class

    There are states:
    Styles.hint - hint
*/

const InputPassword = ({ className, error, ...props }) => (
  <Input.Password
    className={clsx(className, Styles.input, {
      [Styles.error]: error,
    })}
    {...props}
  />
);

/**
 * Keep the ref forwarding, it's necessary for
 * MaskedInputNumber functionality
 */

const InputMain = React.forwardRef(({ className, error, ...props }, ref) => (
  <Input
    ref={ref}
    className={clsx(className, Styles.input, {
      [Styles.error]: error,
    })}
    {...props}
  />
));

InputMain.Password = InputPassword;

InputPassword.propTypes = propTypes;
InputMain.propTypes = propTypes;

export { InputMain as Input };
