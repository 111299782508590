import { request } from 'utils/request';
import { REWARDS_GENERAL_TAB } from 'const/SalaryRewardsPage';

export const getEmployeeRewardsRequest = (payload) => {
  const {
    rewardFamilyId,
    siret,
    companyId,
    year,
    month,
    page,
    limit,
  } = payload;
  let query = `?year=${year}&month=${month}`;

  if (siret) {
    query += `&siret=${siret}`;
  }
  if (rewardFamilyId !== REWARDS_GENERAL_TAB.ID) {
    query += `&rewardFamilyId=${rewardFamilyId}`;
  }
  if (page) {
    query += `&page=${Number(page)}`;
  }
  if (limit) {
    query += `&limit=${Number(limit)}`;
  }

  return request.get(`/companies/${companyId}/employee-rewards${query}`);
};

export const createOrUpdateEmployeeRewardRequest = (payload) => {
  const { companyId, data } = payload;

  return request.post(`/companies/${companyId}/employee-rewards`, data);
};

export const downloadEmployeeRewardsXlsx = (payload) => {
  const { companyId, query } = payload;

  return request.get(`/companies/${companyId}/employee-rewards/xlsx?${query}`, {
    responseType: 'blob',
  });
};
