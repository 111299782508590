import { object as yupObject, string as yupString } from 'yup';

export const validationSchema = () =>
  yupObject().shape({
    lastNameAtBirthday: yupString().required('Field cannot be empty'),
    lastName: yupString(),
    firstName1: yupString().required('Field cannot be empty'),
    firstName2: yupString().nullable(),
    firstName3: yupString().nullable(),
    statusFamily: yupString().required('Field cannot be empty'),
    email: yupString()
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/)
      .required('Field cannot be empty'),
    numberSS: yupString().required('Field cannot be empty'),
    numberSSCle: yupString().required('Field cannot be empty'),
    dateBirthday: yupString().required('Field cannot be empty'),
    placeBirthCity: yupString().required('Field cannot be empty'),
    placeBirthCountry: yupString().required('Field cannot be empty'),
    nationality: yupString().required('Field cannot be empty'),
    addressActual: yupString().required('Field cannot be empty'),
  });
