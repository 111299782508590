import React, { useCallback } from 'react';
import { Icon } from 'antd';
import clsx from 'clsx';
import { openSuccessNotification } from 'helpers/notifications';
import { TableAction } from 'components/ui/TableAction';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { invoicesSendByEmailRequest } from 'modules/invoiceSearch/actions';
import { useInvoiceActionsContext } from 'contexts/InvoiceTableActionsContext';
import { selectInvoicesSendingList } from 'modules/invoiceSearch/selectors';

const {
  TOOLTIP: { SEND_BY_EMAIL },
  MESSAGES: { EMAIL_SENT },
} = INVOICE_SEARCH_TRANSLATIONS;

export const SendByEmailAction = () => {
  const dispatch = useDispatch();

  const { documentId, isHidden, hidingComment } = useInvoiceActionsContext();
  const isLoading = useSelector(selectInvoicesSendingList).includes(documentId);

  const handleClick = useCallback(() => {
    if (isHidden) {
      return;
    }

    dispatch(
      invoicesSendByEmailRequest({
        documentId,
        onSuccess: () => openSuccessNotification({ message: EMAIL_SENT }),
      })
    );
  }, [dispatch, documentId, isHidden]);

  return (
    <TableAction
      tooltipProps={{
        title: hidingComment || SEND_BY_EMAIL,
        overlayClassName: !isHidden ? Styles.tooltipSendByEmail : '',
        placement: 'topRight',
        arrowPointAtCenter: true,
      }}
      isLoading={isLoading}
      displayComponent={
        <Icon
          type="mail"
          className={clsx(Styles.Icon, {
            [Styles.disabled]: isHidden,
          })}
          onClick={handleClick}
        />
      }
    />
  );
};
