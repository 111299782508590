import { put, call, takeLatest, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { COMPANY_SETTINGS } from 'const/translations/SalaryCompanySettings';
import {
  fetchEmployeesError,
  fetchEmployeesRequest,
  setScheduleId,
} from 'modules/SalaryModule/Employees/actions';
import {
  CREATE_SCHEDULE,
  FETCH_COMPANY_DETAIL,
  FETCH_SCHEDULE_DETAIL,
  FETCH_SCHEDULES_REQUEST,
  UPDATE_SCHEDULE,
  REMOVE_SCHEDULE,
  CREATE_BRANCH,
  DELETE_BRANCH,
  UPDATE_BRANCH,
  fetchCompanyDetailError,
  fetchCompanyDetailSuccess,
  fetchScheduleDetailError,
  fetchScheduleDetailSuccess,
  fetchSchedulesRequest,
  fetchSchedulesSuccess,
  scheduleDetailMessage,
  setActiveBranch,
  fetchCompanyDetailRequest,
} from '../actions';
import { selectActiveBranchData } from '../selectors';
import {
  createSchedule,
  deleteSchedule,
  getCompanyDetail,
  getScheduleDetail,
  getSchedules,
  updateSchedule,
  createBranch,
  updateBranch,
  deleteBranch,
} from '../services';
import { isCurrentBranchInOptions } from '../utils';

function* fetchCompanyDetailData({ payload: { companyId, newBranchSiret } }) {
  try {
    const { data } = yield call(getCompanyDetail, companyId);
    const activeBranch = yield select(selectActiveBranchData);
    yield put(fetchCompanyDetailSuccess({ data }));

    if (newBranchSiret) {
      const newBranch = data.branches.find(
        ({ siret }) => siret === newBranchSiret
      );
      yield put(setActiveBranch(newBranch));
      return;
    }

    if (
      data?.branches[0] &&
      (!activeBranch ||
        !isCurrentBranchInOptions(activeBranch.siret, data.branches))
    ) {
      yield put(setActiveBranch(data.branches[0]));
    } else {
      const updatedBranch = data.branches.find(
        ({ siret }) => siret === activeBranch.siret
      );
      if (updatedBranch) {
        yield put(setActiveBranch(updatedBranch));
      } else {
        yield put(setActiveBranch(data.branches[0]));
      }
    }
  } catch (error) {
    yield put(fetchCompanyDetailError({ error }));
  }
}

function* fetchSchedulesWorker({ payload }) {
  try {
    const { data } = yield call(getSchedules, payload);
    const [defaultSchedule] = data.schedules;
    yield put(setScheduleId(defaultSchedule ? defaultSchedule.id : ''));
    yield put(fetchSchedulesSuccess(data.schedules));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* fetchScheduleDetailData({ payload }) {
  try {
    const { data } = yield call(getScheduleDetail, payload);
    yield put(fetchScheduleDetailSuccess(data.schedule));
  } catch (error) {
    yield put(fetchScheduleDetailError({ error }));
  }
}

function* createScheduleSaga({ payload: { body, onSuccess } }) {
  try {
    yield call(createSchedule, body);
    yield put(
      scheduleDetailMessage({
        type: 'success',
        message: COMPANY_SETTINGS.SCHEDULE_FORM.MESSAGE_SUCCESS,
      })
    );
    yield put(fetchSchedulesRequest(body.companyId));
    yield put(onSuccess());
  } catch (error) {
    yield put(
      scheduleDetailMessage({
        type: 'error',
        message: COMPANY_SETTINGS.SCHEDULE_FORM.MESSAGE_ERROR,
      })
    );
  }
}

function* updateScheduleDataSaga({ payload }) {
  try {
    yield call(updateSchedule, payload);
    yield put(
      scheduleDetailMessage({
        type: 'success',
        message: COMPANY_SETTINGS.SCHEDULE_FORM.MESSAGE_SUCCESS,
      })
    );
  } catch (error) {
    yield put(
      scheduleDetailMessage({
        type: 'error',
        message: COMPANY_SETTINGS.SCHEDULE_FORM.MESSAGE_ERROR,
      })
    );
  }
}

function* removeScheduleSaga({ payload }) {
  try {
    yield call(deleteSchedule, payload);

    yield put(fetchSchedulesRequest(payload.companyId));
    yield put(fetchEmployeesRequest(payload.companyId));
  } catch (error) {
    yield put(fetchCompanyDetailError({ error }));
  }
}

function* createBranchWorker({ payload: { companyId, data, setSubmitting } }) {
  try {
    yield call(createBranch, companyId, data);
    yield put(
      fetchCompanyDetailRequest({ companyId, newBranchSiret: data.siret })
    );
    setSubmitting(false);
  } catch (error) {
    notification.error({
      message: error.error,
    });
    setSubmitting(false);
  }
}

function* updateBranchWorker({
  payload: { companyId, siret, data, setSubmitting },
}) {
  try {
    yield call(updateBranch, companyId, siret, data);
    yield put(fetchCompanyDetailRequest({ companyId }));
    setSubmitting(false);
  } catch (error) {
    notification.error({
      message: error.error,
    });
    setSubmitting(false);
  }
}

function* deleteBranchWorker({ payload: { companyId, siret } }) {
  try {
    yield call(deleteBranch, companyId, siret);
    yield put(fetchCompanyDetailRequest({ companyId }));
  } catch (error) {
    notification.error({
      message: error.error,
    });
  }
}

export function* companySettingsMainSaga() {
  yield takeLatest(FETCH_COMPANY_DETAIL, fetchCompanyDetailData);
  yield takeLatest(FETCH_SCHEDULE_DETAIL, fetchScheduleDetailData);
  yield takeLatest(CREATE_SCHEDULE, createScheduleSaga);
  yield takeLatest(FETCH_SCHEDULES_REQUEST, fetchSchedulesWorker);
  yield takeLatest(UPDATE_SCHEDULE, updateScheduleDataSaga);
  yield takeLatest(REMOVE_SCHEDULE, removeScheduleSaga);
  yield takeLatest(CREATE_BRANCH, createBranchWorker);
  yield takeLatest(UPDATE_BRANCH, updateBranchWorker);
  yield takeLatest(DELETE_BRANCH, deleteBranchWorker);
}
