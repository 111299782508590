import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStoredPayload,
  selectTableSort,
} from 'modules/invoiceSearch/selectors';
import { invoicesSearchSetSort } from 'modules/invoiceSearch/actions';
import { getNextSortOrder } from 'helpers/invoiceSearchTable';

/**
 * Custom React hook for managing the local state of
 * sorting for a column in a table.
 *
 * @returns {Function} A callback function that
 * represents sort handler creator.
 */
const useTableSortCreator = () => {
  const dispatch = useDispatch();
  const storedPayload = useSelector(selectStoredPayload);
  const currentTableSorting = useSelector(selectTableSort);
  const [currentSort, setCurrentSort] = useState(currentTableSorting);

  /**
   * Callback function that represents sort handler creator.
   * @param {string} defaultSort - The default sort order for the column.
   * @returns {Function} A callback function that updates the sort state
   * and fetches data with a particular sort direction.
   */
  return useCallback(
    (defaultSort) => () => {
      const isOtherColumnSortedPreviously =
        currentTableSorting !== defaultSort &&
        currentTableSorting !== `-${defaultSort}` &&
        currentTableSorting !== defaultSort.slice(1) &&
        currentTableSorting !== null;

      const nextSort = isOtherColumnSortedPreviously
        ? defaultSort
        : getNextSortOrder(currentSort, defaultSort);

      dispatch(
        invoicesSearchSetSort({
          ...storedPayload,
          sort: nextSort,
        })
      );
      setCurrentSort(nextSort);
    },
    [currentTableSorting, dispatch, currentSort, storedPayload]
  );
};

/**
 * Custom React hook for managing sortable table cells.
 *
 * @returns {Array} An array of objects representing sortable table cells.
 */
export const useSortableTableCells = () => {
  const headerCells = document.querySelectorAll(
    'th.ant-table-column-has-sorters'
  );
  const cellSorts = useMemo(
    () => [
      'date',
      '-invoiceNumber',
      'dueDate',
      '-provider',
      'amount',
      'amountWithVat',
    ],
    []
  );
  const sortCreator = useTableSortCreator();

  return useMemo(
    () =>
      // Length comparison is added to ensure querySelectorAll selecting every sortable cell.
      headerCells.length === cellSorts.length
        ? cellSorts.map((sort, index) => ({
            cell: headerCells[index],
            handleSort: sortCreator(sort),
          }))
        : [],
    [cellSorts, headerCells, sortCreator]
  );
};
