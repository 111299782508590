import React from 'react';
import MainStyles from 'assets/styles/main.module.css';
import { useSelector } from 'react-redux';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { CONTACTS_PAGE_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import flowersImage from 'assets/images/loginNewBg.svg';
import { Section } from 'components/Section';
import { CompanyContact } from 'components/Contacts';
import Styles from './Contacts.module.css';

export const Contacts = () => {
  const {
    emailOfAccountant,
    emailOfManager,
    emailOfHRManager,
    emailOfAssistant,
  } = useSelector(selectUserCurrentCompany);

  return (
    <div className={Styles.hubPageBackground}>
      <div className={clsx(MainStyles.container)}>
        <img className={Styles.flowersImg} src={flowersImage} alt="flowers" />
        <Section
          title={CONTACTS_PAGE_TRANSLATIONS.TITLE}
          titleTextColor="white"
        >
          <div className={Styles.container}>
            <CompanyContact
              label={CONTACTS_PAGE_TRANSLATIONS.EMAIL_EXPERT_EXPERT}
              emails={emailOfManager.toLowerCase()}
            />
            <CompanyContact
              label={CONTACTS_PAGE_TRANSLATIONS.EMAIL_ACCOUNTANT_COLLAB}
              emails={emailOfAccountant.toLowerCase()}
            />
            {Boolean(emailOfHRManager) && (
              <CompanyContact
                label={CONTACTS_PAGE_TRANSLATIONS.EMAIL_HR_MANAGER_COLRH}
                emails={emailOfHRManager.toLowerCase()}
              />
            )}
            {Boolean(emailOfAssistant) && (
              <CompanyContact
                label={CONTACTS_PAGE_TRANSLATIONS.EMAIL_ASSISTANT}
                emails={emailOfAssistant.toLowerCase()}
              />
            )}
          </div>
        </Section>
      </div>
    </div>
  );
};
