import { useMemo, useState } from 'react';
import {
  get12MonthArrayFromFirstMonth,
  getArrayFromOneToNumber,
  getNumberOfDaysInMonth,
} from 'helpers/charts';
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

/**
 *  @param activeDateViewOption {string} - Annuels(YEAR) or Mensuels(MONTH) from const
 *  @param activeYear {number}
 *  @param activeMonthName {string} - current active month
 *  @param firstMonth {string} - name of first month of financial year
 *  */
// hook returns supporting values for ChartComponents such as
//    hoveredLineIndex, setHoveredLineIndex - state(array) of id`s of dataset which cursor is on at the moment
//    isViewModeAnnual {bool} - is date view is Annual or not
//    labels {array} - labels for charts : if isViewModeAnnual returns array of 12 month from firstMonth(argument)
//                     if not - returns 1 to 28/29/30/31 numbers depends of what month is active
export const useChartSupportValues = (
  activeDateViewOption,
  activeYear,
  activeMonthName,
  firstMonth
) => {
  const [hoveredLineIndex, setHoveredLineIndex] = useState(null);

  const isViewModeAnnual = useMemo(() => activeDateViewOption === YEAR, [
    activeDateViewOption,
  ]);
  const labels = useMemo(() => {
    if (isViewModeAnnual) {
      return get12MonthArrayFromFirstMonth(firstMonth);
    }
    const monthIndex = getMonthIndexFromName(activeMonthName);
    const daysNumber = getNumberOfDaysInMonth(activeYear, monthIndex + 1);

    return getArrayFromOneToNumber(daysNumber);
  }, [isViewModeAnnual, activeYear, activeMonthName, firstMonth]);
  return { hoveredLineIndex, setHoveredLineIndex, labels, isViewModeAnnual };
};
