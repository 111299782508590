/*
  Map files by propTypes to array of new files
  for upload (with originFileObj field)
  with type and number property
 */
export const mapTab5ValuesToFiles = (values) =>
  Object.entries(values).reduce(
    (acc, [type, files]) => [
      ...acc,
      ...files.map(({ originFileObj, name, uid }, index) => ({
        type,
        name: name || (originFileObj && originFileObj.name),
        file: originFileObj,
        number: index,
        id: originFileObj ? undefined : uid,
      })),
    ],
    []
  );

export const mapResponseStepToStore = (data, stepIdx) => {
  if (!data[`step${stepIdx}`]) {
    return {
      data: {},
      isValid: false,
    };
  }
  const { isValid, ...values } = data[`step${stepIdx}`];
  return {
    data: {
      values,
    },
    isValid: isValid || false,
  };
};

export const mapResponseTab5ToStore = (data = {}) => ({
  data: {
    values:
      data.step5 && data.step5.files
        ? mapResponseFilesToStore(data.step5.files)
        : {},
  },
  isValid: false,
});

export const mapFilesToRequestSaveTab5 = (files, companyId, comment) => ({
  files: files.map(({ id, type, name, number }) => ({
    id,
    type,
    name,
    index: number,
  })),
  companyId,
  comment,
});

/*
  Map files list to object
  with keys as file propTypes
  values as array of files with current type
  array compatible with ant-d upload fileList (item have uid and name fields)
 */
export const mapResponseFilesToStore = (files = []) =>
  files
    .sort((a, b) => a.number - b.number)
    .reduce(
      (acc, { id, type, name }) => ({
        ...acc,
        [type]: [
          ...(type in acc ? acc[type] : []),
          {
            uid: id,
            name,
          },
        ],
      }),
      {}
    );
