import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Styles from './SectionTitle.module.css';

const propTypes = {
  title: PropTypes.string,
  titleTextColor: PropTypes.oneOf(['default', 'white']),
  className: PropTypes.string,
};

export const SectionTitle = ({ title, titleTextColor, className }) => (
  <div className={clsx(Styles.title, Styles[titleTextColor], className)}>
    <span>{title}</span>
  </div>
);

SectionTitle.propTypes = propTypes;
