import React, { useCallback, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import pencilIcon from 'assets/icons/ChartComments/pencil.svg';
import bucketIcon from 'assets/icons/ChartComments/bucket.svg';
import { Button } from 'components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChartTypeForSort,
  selectCommentsForModal,
  selectSubmitCommentFormStatus,
} from 'modules/commentsModal/selectors';
import PropTypes from 'prop-types';
import { getStringDateWithDots } from 'helpers/commentsModal';
import {
  addCommentRequest,
  changeCommentRequest,
  deleteCommentRequest,
  resetCommentsFormStatus,
} from 'modules/commentsModal/actions';
import { ChangeCommentForm } from 'components/CommentsModal/ChangeCommentForm';
import { selectUserCurrentCompanyExercice } from 'modules/loggedUserInfo/selectors';
import clsx from 'clsx';
import { useSimpleMedia } from 'hooks/useMedia';
import { COMMENTS_MODAL_TRANSLATIONS } from 'const/translations/CommentsModal';
import Styles from './CommentsModal.module.css';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 950;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const {
  NO_COMMENTS,
  ADD_BUTTON,
  CANCEL,
  COMMENT,
} = COMMENTS_MODAL_TRANSLATIONS;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export const CommentsModal = ({ isOpen, closeModal }) => {
  const comments = useSelector(selectCommentsForModal);

  const currentChartType = useSelector(selectChartTypeForSort);

  const dispatch = useDispatch();

  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  const [commentInEditForm, setCommentInEditForm] = useState(null);

  const onEditCommentClickCreator = (comment) => () => {
    dispatch(resetCommentsFormStatus());
    setCommentInEditForm(comment);
    setIsEditFormVisible(true);
  };

  const closeForm = useCallback(() => {
    setIsEditFormVisible(false);
    setCommentInEditForm(null);
  }, [setIsEditFormVisible, setCommentInEditForm]);

  const exercice = useSelector(selectUserCurrentCompanyExercice);

  const addComment = useCallback(
    ({ text, type, date }) => {
      dispatch(addCommentRequest({ text, type, date, exercice }));
    },
    [dispatch, exercice]
  );

  const changeComment = useCallback(
    ({ text, commentId }) => {
      dispatch(changeCommentRequest({ text, commentId }));
    },
    [dispatch]
  );

  const deleteCommentCreator = ({ commentId }) => () => {
    dispatch(deleteCommentRequest({ commentId }));
  };

  const submitCommentFormStatus = useSelector(selectSubmitCommentFormStatus);

  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  useEffect(() => {
    if (isMobileVersion) {
      closeModal();
    }
  }, [isMobileVersion, closeModal]);

  const openEditModal = useCallback(() => {
    setIsEditFormVisible(true);
    dispatch(resetCommentsFormStatus());
  }, [dispatch, setIsEditFormVisible]);

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      width={384}
      className={clsx(Styles.modal, {
        [Styles.shrinkHeight]: isEditFormVisible,
      })}
      closable={false}
      footer={[]}
      destroyOnClose
    >
      <div className={Styles.modalWrapper}>
        <Row className={Styles.commentsWrapper}>
          {comments.map(({ id, text, date, type }) => (
            <Col key={id} span={24} className={Styles.comment}>
              <div className={Styles.commentHeader}>
                <span className={Styles.commentTitle}>{COMMENT}</span>
                <span className={Styles.commentDate}>
                  {getStringDateWithDots(date)}
                </span>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  className={Styles.pencilIcon}
                  src={pencilIcon}
                  alt="edit pencil"
                  onClick={onEditCommentClickCreator({ id, text, date, type })}
                />
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  onClick={deleteCommentCreator({ commentId: id })}
                  src={bucketIcon}
                  alt="bucket"
                />
              </div>
              <p className={Styles.commentText}>{text}</p>
            </Col>
          ))}
          {comments.length === 0 && (
            <div className={Styles.noCommentsYet}>{NO_COMMENTS}</div>
          )}
        </Row>
        <div className={Styles.buttonWrapper}>
          <Button
            variant="success"
            onClick={openEditModal}
            className={clsx(Styles.addButton, Styles.button)}
          >
            {ADD_BUTTON}
          </Button>
          <Button
            onClick={closeModal}
            className={Styles.button}
            variant="secondary"
          >
            {CANCEL}
          </Button>
        </div>
      </div>
      {isEditFormVisible && (
        <ChangeCommentForm
          closeForm={closeForm}
          commentInEdit={commentInEditForm}
          addComment={addComment}
          changeComment={changeComment}
          chartType={currentChartType}
          submitStatus={submitCommentFormStatus}
          exercice={exercice}
        />
      )}
    </Modal>
  );
};

CommentsModal.propTypes = propTypes;
