import React, { useMemo } from 'react';
import clsx from 'clsx';
import { CalendarColumnHeader } from 'components/SalaryComponents/Calendar/CalendarColumnTitle';
import { CalendarTableCell } from 'containers/Salary/CalendarPage/CalendarTableCell/CalendarTableCell';
import { formatNameUpperLastName } from 'modules/SalaryModule/Employees/utils';
import Styles from 'containers/Salary/CalendarPage/CalendarPage.module.css';
import useGetCalendarCellOverlay from './useGetCalendarCellOverlay';

export const useGetCalendarMonthViewColumn = (
  isMobileVersion,
  datesArrayForCurrentMonth,
  isDateHoliday,
  absencesMappedToDates,
  openLeaveModal,
  downloadAttachment,
  selectedRowKeys
) => {
  const monthViewColumn = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'firstname1',
        key: 'employeeid',
        width: isMobileVersion ? 140 : 160,
        height: 52,
        fixed: 'left',
        ellipsis: true,
        className: Styles.nameColumn,
        render: (name, { lastname }) => {
          const fullName = formatNameUpperLastName(name, lastname);
          const truncated = fullName.length > 35;
          return (
            <div
              className={clsx(Styles.userNameCell, {
                [Styles.userNameCellTruncated]: truncated,
              })}
            >
              {fullName}
            </div>
          );
        },
      },
      ...datesArrayForCurrentMonth.map((dateString) => ({
        title: <CalendarColumnHeader stringDate={dateString} />,
        dataIndex: ['absences', `${dateString}`],
        key: dateString,
        width: 36,
        render: (leaveObj, employee) => (
          <CalendarTableCell
            leaveObj={leaveObj}
            employee={employee}
            dateString={dateString}
            absencesMappedToDates={absencesMappedToDates}
            isDateHoliday={isDateHoliday}
            getCalendarCellOverlay={useGetCalendarCellOverlay}
            openLeaveModal={openLeaveModal}
            downloadAttachment={downloadAttachment}
            selectedRowKeys={selectedRowKeys}
          />
        ),
      })),
    ],
    [
      absencesMappedToDates,
      datesArrayForCurrentMonth,
      downloadAttachment,
      isDateHoliday,
      isMobileVersion,
      openLeaveModal,
      selectedRowKeys,
    ]
  );

  return monthViewColumn;
};
