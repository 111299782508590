import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  SCHEDULE_TIME_INTERVALS,
  SCHEDULE_DAY_FULLTEXT,
  SCHEDULE_DEFAULT_TIMES,
} from 'const/SalaryCompanySettings';
import { Typography, Switch, Row, Col } from 'antd';
import { CustomSelect, Checkbox } from 'components/ui';
import { useSimpleMedia } from 'hooks/useMedia';

import { COMPANY_SETTINGS } from 'const/translations/SalaryCompanySettings';
import { useScheduleRowHook } from '../../../../modules/SalaryModule/CompanySettings/hooks/useScheduleRow.hook';
import { getScheduleObjects } from '../../../../modules/SalaryModule/CompanySettings/utils';
import Styles from './ScheduleFormItem.module.css';
import { SCHEDULE_TYPES } from '../../../../modules/SalaryModule/CompanySettings/const';

const { Text } = Typography;

const propTypes = {
  daySchedule: PropTypes.array,
  sameRowData: PropTypes.object,
  activeTab: PropTypes.string,
  withMain: PropTypes.bool,
  dayKey: PropTypes.string,
  setTotalTime: PropTypes.func,
  setSameRowData: PropTypes.func,
  setScheduleDay: PropTypes.func,
};

const SCREEN_WIDTH_BREAKPOINT_VALUE = 1100;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const { SCHEDULE_FORM } = COMPANY_SETTINGS;
/**
 * Schedule form day row
 * @param { Array } daySchedule - schedule day intervals
 * @param { String } activeTab - all days have same time intervals or every day has own
 * @param { Boolean } withMain - is there the row for set same data for all days
 * @param { String } dayKey - abbreviated name of the day
 * @param { Object } sameRowData - same time intervals for all days
 * @param { Function } setTotalTime - add day total time to total time of schedule
 * @param { Function } setSameRowData - if it withMain row - set same data for all days
 * @param { Function } setScheduleDay - add day formatted intervals to common schedule data
 */
export const ScheduleFormItem = memo(
  ({
    daySchedule,
    activeTab,
    withMain = false,
    dayKey,
    sameRowData = null,
    setTotalTime = null,
    setSameRowData = null,
    setScheduleDay = null,
  }) => {
    const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

    const isControlledRow = sameRowData && !withMain;

    const {
      withLunchBreak,
      setWithLunchBreak,
      gapState,
      setGapData,
      isEditable,
      setEditable,
      rowTotalTime,
      availableOptions,
    } = useScheduleRowHook(daySchedule, sameRowData, withMain);

    useEffect(() => {
      if (setTotalTime) {
        setTotalTime((prev) => ({
          ...prev,
          [dayKey]: isEditable ? rowTotalTime : { hours: 0, minutes: 0 },
        }));
      }
    }, [rowTotalTime, isEditable, dayKey, setTotalTime]);

    useEffect(() => {
      if (setSameRowData && withMain && activeTab === SCHEDULE_TYPES.SAME) {
        const rowData = { gapState, withLunchBreak };
        if (JSON.stringify(sameRowData) !== JSON.stringify(rowData)) {
          setSameRowData(rowData);
        }
      }
    }, [
      gapState,
      withLunchBreak,
      activeTab,
      withMain,
      sameRowData,
      setSameRowData,
      setWithLunchBreak,
    ]);

    useEffect(() => {
      if (!withMain) {
        const formattedState = getScheduleObjects(gapState, withLunchBreak);
        setScheduleDay(formattedState, dayKey, !isEditable);
      }
    }, [
      gapState,
      withLunchBreak,
      isEditable,
      dayKey,
      withMain,
      setScheduleDay,
    ]);

    const toggleEditable = useCallback(() => {
      setEditable((prev) => !prev);
    }, [setEditable]);

    const changeWorkPeriod = useCallback(() => {
      setWithLunchBreak((prev) => !prev);
    }, [setWithLunchBreak]);

    return (
      <div className={Styles.item}>
        <div className={Styles.labelWrapper}>
          {withMain ? (
            <Text className={clsx(Styles.label, Styles.labelMd)}>
              {SCHEDULE_FORM.EVERY_DAY}
            </Text>
          ) : (
            <>
              <Switch
                className={Styles.switch}
                checked={isEditable}
                onChange={toggleEditable}
              />
              <Text className={Styles.label}>
                {SCHEDULE_DAY_FULLTEXT[dayKey]}
              </Text>
            </>
          )}
        </div>
        <div className={Styles.checkboxWrapper}>
          <Checkbox
            checked={withLunchBreak}
            disabled={!isEditable || isControlledRow}
            onChange={changeWorkPeriod}
            className={Styles.checkbox}
          >
            {isMobileVersion
              ? SCHEDULE_FORM.PAUSE_SHORT_TEXT
              : SCHEDULE_FORM.PAUSE_LONG_TEXT}
          </Checkbox>
        </div>
        <Row type="flex" className={Styles.timeWrapper}>
          <Col className={Styles.timePeriodLeft}>
            <Row type="flex" align="middle" className={Styles.flexNoWrap}>
              <CustomSelect
                size="large"
                disabled={!isEditable || isControlledRow}
                className={Styles.select}
                options={
                  availableOptions.availableMin || SCHEDULE_DEFAULT_TIMES
                }
                value={gapState.gap1}
                name={SCHEDULE_TIME_INTERVALS.gap1.name}
                onChange={setGapData}
                placeholder=""
                notFoundContent={null}
              />
              <Text className={Styles.separator}>-</Text>
              <CustomSelect
                size="large"
                disabled={!isEditable || isControlledRow}
                className={Styles.select}
                options={
                  withLunchBreak
                    ? availableOptions.available
                    : availableOptions.availableMax
                }
                value={gapState.gap2}
                name={SCHEDULE_TIME_INTERVALS.gap2.name}
                onChange={setGapData}
                placeholder=""
                notFoundContent={null}
              />
            </Row>
          </Col>
          {withLunchBreak && (
            <Col className={Styles.timePeriodRight}>
              <Row type="flex" align="middle" className={Styles.flexNoWrap}>
                <Text className={Styles.toNext}>et</Text>
                <CustomSelect
                  size="large"
                  disabled={!isEditable || isControlledRow}
                  className={Styles.select}
                  options={availableOptions.available || SCHEDULE_DEFAULT_TIMES}
                  value={gapState.gap3}
                  name={SCHEDULE_TIME_INTERVALS.gap3.name}
                  onChange={setGapData}
                  placeholder=""
                  notFoundContent={null}
                />
                <Text className={Styles.separator}>-</Text>
                <CustomSelect
                  size="large"
                  disabled={!isEditable || isControlledRow}
                  className={Styles.select}
                  options={
                    availableOptions.availableMax || SCHEDULE_DEFAULT_TIMES
                  }
                  value={gapState.gap4}
                  name={SCHEDULE_TIME_INTERVALS.gap4.name}
                  onChange={setGapData}
                  placeholder=""
                  notFoundContent={null}
                />
              </Row>
            </Col>
          )}
        </Row>
        <Text
          className={clsx(Styles.totalItem, {
            [Styles.totalItemHidden]: !isEditable,
          })}
        >
          {rowTotalTime.hours} h{' '}
          {rowTotalTime.minutes === 0 ? '00' : rowTotalTime.minutes} min
        </Text>
      </div>
    );
  }
);

ScheduleFormItem.propTypes = propTypes;
