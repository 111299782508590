export const FOOTER_TRANSLATIONS = {
  TITLES: {
    CONTACTS: 'Contacts',
    HELP: 'Aide',
    LEGAL_NOTICES: 'Mentions légales',
  },
  LINKS: {
    OUR_AGENCIES: 'Nos agences',
    CONTACT_US: 'Nous contacter',
    UNSUBSCRIBE_HERE: 'Se désinscrire',
    HOW_IT_WORKS: 'Comment ça marche ?',
    WHO_ARE_WE: 'Qui sommes-nous ?',
    CGU: 'CGU',
    QUESTIONS: 'Foire Aux Questions',
  },
};
