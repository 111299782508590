import { NetPromoterScoreModal } from 'components/NetPromoterScoreModal';
import { InformationModal } from 'components/InformationModal';
import { IframeModal } from 'components/IframeModal';
import { ValidateAndPayModal } from 'components/InvoiceSearch/modals/ValidateAndPayModal';
import { InvoiceSearchInfoModal } from 'components/InvoiceSearch/modals/InfoModal';
import { MassInvoiceActionsModal } from 'components/MassInvoiceActionsModal';
import { CommentaryModal } from 'components/InvoiceSearch/modals/CommentaryModal';
import { ApproveModal } from 'components/InvoiceSearch/modals/ApproveModal';
import { HelpModal } from 'containers/ModalRequestHelp';
import { ContactHRModal } from 'containers/ModalContactHR';

export const MODAL_TYPES = {
  requestHelp: 'requestHelp',
  contactHR: 'contactHR',
  netPromoterScore: 'netPromoterScore',
  informationModal: 'informationModal',
  validateAndPayModal: 'validateAndPayModal',
  invoiceSearchInfoModal: 'invoiceSearchInfoModal',
  massInvoiceActionsModal: 'massInvoiceActionsModal',
  invoiceSearchCommentaryModal: 'invoiceSearchCommentaryModal',
  iframeModal: 'iframeModal',
  approveModal: 'approveModal',
};

export const MODALS_BY_TYPES = {
  [MODAL_TYPES.requestHelp]: HelpModal,
  [MODAL_TYPES.contactHR]: ContactHRModal,
  [MODAL_TYPES.netPromoterScore]: NetPromoterScoreModal,
  [MODAL_TYPES.informationModal]: InformationModal,
  [MODAL_TYPES.validateAndPayModal]: ValidateAndPayModal,
  [MODAL_TYPES.invoiceSearchInfoModal]: InvoiceSearchInfoModal,
  [MODAL_TYPES.massInvoiceActionsModal]: MassInvoiceActionsModal,
  [MODAL_TYPES.invoiceSearchCommentaryModal]: CommentaryModal,
  [MODAL_TYPES.iframeModal]: IframeModal,
  [MODAL_TYPES.approveModal]: ApproveModal,
};
