import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Select } from 'antd';
import { Icon } from '@iconify/react';
import caretDownFill from '@iconify/icons-ant-design/caret-down-fill';
import Styles from './Select.module.css';

const { Option } = Select;

const SelectMain = ({ options, className, ...passedProps }) => (
  <Select
    suffixIcon={<Icon icon={caretDownFill} />}
    className={clsx(className, Styles.select)}
    {...passedProps}
  >
    {options.map(({ value, label }) => (
      <Option key={value} value={value}>
        {label || value}
      </Option>
    ))}
  </Select>
);

SelectMain.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export { SelectMain as Select };
