import { redirect } from 'helpers/common';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';
import {
  AUTH_TOKEN_KEY,
  TOKEN_ENDPOINTS,
  WAIBI_ACCESS_TOKEN,
  WAIBI_REFRESH_TOKEN,
} from '../config';

/**
 * Separates camelCased object keys with an underscore
 *
 * @param {AxiosRequestConfig} config - server's request
 * @return {AxiosRequestConfig}
 */
// export function requestMapper(config) {
//   if (config.data && !(config.data instanceof FormData)) {
//     const decamelizedData = humps.decamelizeKeys(config.data);
//     delete config.data;
//     config.data = decamelizedData;
//   }
//
//   return config;
// }

/**
 * Converts object keys to camelCase
 *
 * @param {AxiosResponse} response - server's response
 * @return {Promise.<Object>}
 */
export function responseMapper(response) {
  const contentHeader =
    response.headers['content-disposition'] ||
    response.headers['Content-Disposition'];

  if (!contentHeader) {
    return Promise.resolve(response.data);
  }

  const [, fileNameInQuotes] = contentHeader.split('=');
  const fileName = decodeURI(fileNameInQuotes?.replace(/"/g, ' ').trim());

  return Promise.resolve({ fileData: response.data, fileName });
}

/**
 * Set jwt token in the authorization header under the 'Authorization' key
 *
 * @param {AxiosRequestConfig} config
 * @return {AxiosRequestConfig}
 */
/* eslint-disable no-param-reassign */
export function setJWTHeader(tokenKey) {
  return (config) => {
    config.headers.common.Authorization = `Bearer ${getFromStorage({
      key: tokenKey,
      value: false,
    })}`;

    return config;
  };
}

/**
 * Save JWT to the LocalStorage
 *
 * @param {AxiosResponse} response
 * @return {AxiosResponse}
 */
export function setJWTLocalStorage(tokenKey) {
  return (response) => {
    if (
      Object.values(TOKEN_ENDPOINTS).some((endpoint) =>
        response.config.url.includes(endpoint)
      )
    ) {
      const token =
        (response && response.data && response.data.token) ||
        (response &&
          response.data &&
          response.data.data &&
          response.data.data.token);
      if (token) {
        setToStorage({ key: tokenKey, value: token, storage: localStorage });
      }
    }
    return response;
  };
}

/**
 * Check response for errors
 * This handler is specific for project
 *
 * @param {AxiosResponse} data - server's response
 * @return {Promise.<Object>}
 */
export function errorHandler(data = {}) {
  const { response } = data;
  const newError = {};

  if (
    [401, 403].includes(response.status) &&
    getFromStorage({ key: AUTH_TOKEN_KEY })
  ) {
    removeFromStorage({
      key: [AUTH_TOKEN_KEY, WAIBI_ACCESS_TOKEN, WAIBI_REFRESH_TOKEN],
    });
    redirect('/');
  }

  newError.statusCode = response.status;
  newError.statusMessage = response.statusText;
  /**
   * TODO: Change error handling to actual errors structure
   */
  newError.error = parseJSON(response.data && response.data.message);

  return Promise.reject(newError);
}

/**
 * Parse json if needed
 *
 * @param {*} data
 * @return {Object|*}
 */
function parseJSON(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}
