import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { useSelector } from 'react-redux';

import { MAX_FILES_SIZE_25MO, MAX_FILES_SIZE_10MO } from 'const';
import { useUpload } from 'hooks/useUpload';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import { FileList } from 'components/UploadDocuments/FileList/FileList';
import { Button } from 'components/ui/Button';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import Styles from './UploadFormik.module.css';

const {
  VOUCHERS_FORM_TAB: { DOCUMENTS, MESSAGES },
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  name: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFilesCount: PropTypes.number,
  accept: PropTypes.string,
  classNameFileList: PropTypes.string,
  removeIcon: PropTypes.node,
  hasDownload: PropTypes.bool,
  onFileDownload: PropTypes.func,
};

const defaultProps = {
  buttonText: DOCUMENTS.BTN_CHOOSE,
  disabled: false,
  multiple: true,
};

// hasDownload {boolean} - flag that file can be downloaded on name label click
export const UploadFormik = ({
  children,
  buttonText,
  disabled: forceDisabled,
  multiple,
  maxFilesCount,
  name,
  uploadedFiles,
  onChange,
  classNameFileList,
  removeIcon,
  hasDownload = false,
  onFileDownload,
  ...props
}) => {
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);

  const setUploadedFiles = useCallback((fileList) => onChange(name, fileList), [
    name,
    onChange,
  ]);
  const { handleChange, handleRemove } = useUpload(
    uploadedFiles,
    setUploadedFiles
  );
  const beforeUpload = useCallback(
    (file) => {
      const summarySize =
        uploadedFiles.reduce((acc, { size }) => acc + size, 0) + file.size;
      if (
        summarySize >
        ((isPreCompta && MAX_FILES_SIZE_25MO) || MAX_FILES_SIZE_10MO)
      ) {
        message.error(MESSAGES.ERROR_MAX_SIZE.replace('{{FILE}}', file.name));
        return Promise.reject();
      }
      return false;
    },
    [isPreCompta, uploadedFiles]
  );

  const disabled =
    forceDisabled || (maxFilesCount && uploadedFiles.length >= maxFilesCount);
  const noFilesAttached = uploadedFiles.length === 0;

  return (
    <div className={Styles.wrapper}>
      {(multiple || noFilesAttached) && (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          fileList={uploadedFiles}
          {...props}
        >
          <Button
            role="button"
            outlined
            disabled={disabled}
            className={Styles.button}
          >
            {buttonText}
          </Button>
        </Upload>
      )}
      {!noFilesAttached && (
        <FileList
          files={uploadedFiles}
          onRemove={handleRemove}
          className={classNameFileList}
          removeIcon={removeIcon}
          hasDownload={hasDownload}
          onFileDownload={onFileDownload}
        />
      )}
    </div>
  );
};

UploadFormik.propTypes = propTypes;
UploadFormik.defaultProps = defaultProps;
