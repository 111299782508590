import React from 'react';
import clsx from 'clsx';
import { Spin } from 'antd';
import Styles from 'components/FormCard/FormCard.module.css';

export const WrappedLoader = () => (
  <div className={clsx(Styles.bannerImageWrapper, Styles.bannerImage)}>
    <Spin />
  </div>
);
