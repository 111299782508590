import React from 'react';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectPackagesInCart } from 'modules/cart/selectors';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';
import shoppingCart from 'assets/icons/CartPage/shopping-cart.svg';
import { HEADER_CART_TRANSLATIONS } from 'const/translations/CartPage';
import { PACKAGES_NAMES_TRANSLATIONS } from 'const/packages';
import Styles from './CartDropdown.module.css';

const { MODULES, MY_CART } = HEADER_CART_TRANSLATIONS;

export const CartDropdown = React.memo(() => {
  const cartPackages = useSelector(selectPackagesInCart);

  const cartPackagesLength = cartPackages.length;
  return (
    <Dropdown
      overlay={
        <div className={Styles.dropDownMenu}>
          <span className={Styles.cartDropdownText}>
            {cartPackagesLength}{' '}
            {MODULES.replace('{{ENDING}}', cartPackagesLength === 1 ? '' : 's')}
          </span>
          <div className={Styles.packagesWrapper}>
            {cartPackages.map((packageName) => (
              <span key={packageName} className={Styles.packageItem}>
                {PACKAGES_NAMES_TRANSLATIONS[packageName] || 'Unknown'}
              </span>
            ))}
          </div>
        </div>
      }
      arrow
      trigger={['hover']}
      overlayClassName={clsx(Styles.dropDownWindow, {
        [Styles.hidden]: cartPackagesLength === 0,
      })}
      placement="bottomRight"
    >
      <NavLink to={ROUTES.CART}>
        <div className={clsx(Styles.dropDownTrigger)}>
          <div className={Styles.shoppingCartWrapper}>
            <img src={shoppingCart} alt="shopping cart" />
            {cartPackagesLength > 0 && <div className={Styles.redDot} />}
          </div>
          <span>
            {MY_CART.replace('{{PACKAGES_LENGTH}}', cartPackagesLength)}
          </span>
        </div>
      </NavLink>
    </Dropdown>
  );
});
