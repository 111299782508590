import { useMemo } from 'react';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { getActiveMonthCommentsForChart } from 'modules/commentsModal/helpers';

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

// RETURNS COMMENTS ARRAY DEPENDING ON ACTIVE CHART DATE MODE(YEAR OF MONTH)
// todo remake same hook for chartData or make new
export const useChartComments = (
  commentsForMonths,
  commentsForYear,
  activeMonthName,
  activeDateViewOption = YEAR
) =>
  useMemo(
    () =>
      activeDateViewOption === YEAR
        ? commentsForYear
        : getActiveMonthCommentsForChart(commentsForMonths, activeMonthName),
    [commentsForMonths, commentsForYear, activeMonthName, activeDateViewOption]
  );
