import { getUniqueSimpleArray } from 'modules/cart/helpers';
import { UNIQUE_MODULES_OF_EACH_PACKAGE } from 'const/packages';

// each package contains one or several user`s modules
// function returns array of user packages based on his modules
export const getUserPackagesFromModules = (userModules) =>
  getUniqueSimpleArray(
    Object.entries(UNIQUE_MODULES_OF_EACH_PACKAGE).reduce(
      (acc, [packageName, includedModules]) => {
        if (includedModules.some((module) => userModules.includes(module))) {
          acc.add(packageName);
          return acc;
        }
        return acc;
      },
      new Set()
    )
  );
