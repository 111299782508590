import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import { STATUS_LOADING, STATUS_SUCCESS } from 'const';
import MainStyles from 'assets/styles/main.module.css';
import { PROP_TYPE_STATUS } from 'const/propTypes';
import { Spinner } from 'components/Spinner';
import { useDocumentsListColumns } from 'hooks/useDocumentsListColumns';
import { useSelector } from 'react-redux';
import { selectCurrentPath } from 'modules/documentsCabinet/selectors';
import Styles from './DocumentsList.module.css';

const { SEARCH_SUMMARY } = DOCUMENTS_CABINET_TRANSLATIONS;

export const DocumentsList = ({ cabinetStatus, documents, goToParent }) => {
  const currentPath = useSelector(selectCurrentPath);
  const columns = useDocumentsListColumns();

  return (
    <>
      <div className={Styles.searchPanel}>
        {currentPath.length ? (
          <Breadcrumb currentPath={currentPath} goToParent={goToParent} />
        ) : (
          <h3>
            {cabinetStatus === STATUS_SUCCESS
              ? SEARCH_SUMMARY.replace('{{TOTAL}}', documents.length)
              : ''}
          </h3>
        )}
      </div>
      <div className={Styles.table}>
        {cabinetStatus === STATUS_LOADING ? (
          <Spinner />
        ) : (
          <Table
            columns={columns}
            dataSource={documents}
            className={MainStyles.table}
          />
        )}
      </div>
    </>
  );
};

DocumentsList.propTypes = {
  cabinetStatus: PROP_TYPE_STATUS,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.string,
      date: PropTypes.object,
      documentId: PropTypes.number,
    })
  ),
  goToParent: PropTypes.func,
};
