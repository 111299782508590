import { Col, Form, Row } from 'antd';
import { Button } from 'components/ui';
import React, { useState, memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PageTitle } from 'components/SalaryComponents/PageTitle';
import { Spinner } from 'components/Spinner';
import { ROUTES } from 'const';
import { COMPANY_SETTINGS } from 'const/translations/SalaryCompanySettings';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import {
  cleanUpScheduleDetail,
  fetchScheduleDetailRequest,
  fetchSchedulesRequest,
  removeSchedule,
  requestScheduleCreate,
  requestScheduleUpdate,
  scheduleDetailMessage,
} from 'modules/SalaryModule/CompanySettings/actions';
import { useQuery } from 'modules/SalaryModule/CompanySettings/hooks/useQuery.hook';
import {
  selectDefaultPageTitle,
  selectDefaultSchedule,
  selectInitialPageTitle,
  selectScheduleDetailData,
  selectScheduleDetailMessage,
} from 'modules/SalaryModule/CompanySettings/selectors';
import {
  buildCreateScheduleBody,
  buildUpdateScheduleBody,
} from 'modules/SalaryModule/CompanySettings/utils';
import Styles from '../CompanySettingsPage.module.css';
import { Message } from '../Message';
import { ScheduleForm } from '../ScheduleForm';
import { CompanySettingsStaff } from '../CompanySettingsStaff';
import { CSScheduleDetailTitle } from './CSScheduleDetailTitle';
import { ScheduleRemoveModal } from './ScheduleRemoveModal';
import { SCHEDULE_TYPES } from '../../../../modules/SalaryModule/CompanySettings/const';

/*
 * Schedule detail page with create and update logic.
 * Also with a list of company employees who work on this schedule
 * */
export const CompanySettingsScheduleDetail = memo(() => {
  const [activeTab, setActiveTab] = useState('different');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const companyId = useSelector(selectUserCompanyId);
  const scheduleData = useSelector(selectScheduleDetailData);
  const scheduleDataDefault = useSelector(selectDefaultSchedule);
  const scheduleMessage = useSelector(selectScheduleDetailMessage);
  const dispatch = useDispatch();
  const { id: scheduleId } = useParams();
  const query = useQuery();
  const siretNumber = query.get('siret');
  const [scheduleDays, setScheduleDays] = useState({});
  const history = useHistory();
  const scheduleTitleText = useSelector(
    scheduleId ? selectInitialPageTitle : selectDefaultPageTitle
  );
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const openDeleteModal = useCallback(() => {
    setOpenRemoveModal(true);
  }, []);

  const removeScheduleHandler = useCallback(() => {
    if (scheduleId) {
      dispatch(removeSchedule({ companyId, scheduleId }));
      setOpenRemoveModal(false);
      history.push(`${ROUTES.SALARIES_COMPANY_SETTINGS}?tab=2`);
    }
  }, [history, dispatch, scheduleId, companyId]);

  const handleFormSubmit = useCallback(() => {
    if (!title) {
      setTitleError(COMPANY_SETTINGS.SCHEDULE_FORM.TITLE_ERROR_TEXT);
      return false;
    }
    if (!scheduleId) {
      return dispatch(
        requestScheduleCreate({
          body: buildCreateScheduleBody({
            title,
            scheduleDays,
            siretNumber,
            companyId,
            daysSame: activeTab === SCHEDULE_TYPES.SAME,
          }),
          onSuccess: () =>
            history.push(`${ROUTES.SALARIES_COMPANY_SETTINGS}?tab=2`),
        })
      );
    }
    return dispatch(
      requestScheduleUpdate(
        buildUpdateScheduleBody({
          title,
          scheduleDays,
          companyId,
          scheduleId,
          daysSame: activeTab === SCHEDULE_TYPES.SAME,
        })
      )
    );
  }, [
    title,
    scheduleId,
    dispatch,
    scheduleDays,
    companyId,
    activeTab,
    siretNumber,
    history,
  ]);

  const setScheduleDayHandler = useCallback((data, key, isDeleteKey) => {
    if (isDeleteKey) {
      setScheduleDays((prev) => {
        const clone = { ...prev };
        delete clone[key];
        return clone;
      });
    } else {
      setScheduleDays((prev) => ({ ...prev, [key]: data }));
    }
  }, []);

  useEffect(() => {
    if (scheduleId) {
      dispatch(
        fetchScheduleDetailRequest({
          companyId,
          scheduleId,
          siret: siretNumber,
        })
      );
    } else {
      dispatch(fetchSchedulesRequest(companyId));
    }
  }, [companyId, dispatch, scheduleId, siretNumber]);

  useEffect(() => {
    dispatch(
      scheduleDetailMessage(
        scheduleData?.schedule?.isDefault
          ? {
              message: COMPANY_SETTINGS.SCHEDULE_FORM.MESSAGE_DEFAULT,
            }
          : null
      )
    );
    if (scheduleData) {
      setActiveTab(
        scheduleData.schedule.daysSame
          ? SCHEDULE_TYPES.SAME
          : SCHEDULE_TYPES.DIFFERENT
      );
    }
    if (
      scheduleData &&
      scheduleData.schedule.companyDosId !== companyId &&
      !scheduleData.schedule.isDefault
    ) {
      history.push(`${ROUTES.SALARIES_COMPANY_SETTINGS}?tab=2`);
    }
  }, [dispatch, companyId, scheduleData, history]);

  useEffect(
    () => () => {
      dispatch(cleanUpScheduleDetail());
    },
    [dispatch]
  );

  useEffect(() => {
    if (scheduleTitleText) {
      setTitle(scheduleTitleText);
    }
  }, [scheduleTitleText]);

  const handleTabChange = (e) => {
    setActiveTab(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(
      e.target.value.substr(
        0,
        COMPANY_SETTINGS.SCHEDULE_FORM.MAX_SCHEDULE_TITLE_LENGTH
      )
    );
    if (e.target.value.length > 0) {
      setTitleError('');
    }
  };

  if ((!scheduleData && scheduleId) || (!scheduleDataDefault && !scheduleId)) {
    return <Spinner />;
  }

  return (
    <div className={Styles.container}>
      <PageTitle className={Styles.pageTitle}>
        {COMPANY_SETTINGS.SCHEDULE_FORM.PAGE_TITLE}
      </PageTitle>
      <Form>
        <CSScheduleDetailTitle
          title={title}
          titleError={titleError}
          handleTitleChange={handleTitleChange}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
        <Row type="flex">
          <Col xs={{ span: 24 }} lg={{ span: 18 }}>
            <ScheduleForm
              activeTab={activeTab}
              setScheduleDay={setScheduleDayHandler}
            />
            <div className={Styles.buttonWrapper}>
              <Button
                className={Styles.btn}
                variant="primary"
                onClick={handleFormSubmit}
                disabled={scheduleData?.schedule?.isDefault || false}
              >
                {COMPANY_SETTINGS.SCHEDULE_FORM.SAVE_BTN_TEXT}
              </Button>
              {scheduleId && (
                <Button
                  variant="secondary"
                  onClick={openDeleteModal}
                  className={Styles.btn}
                  disabled={scheduleData?.schedule?.isDefault || false}
                >
                  {COMPANY_SETTINGS.SCHEDULE_FORM.CANCEL_BTN_TEXT}
                </Button>
              )}
              {scheduleMessage && (
                <Message type={scheduleMessage.type}>
                  {scheduleMessage.message}
                </Message>
              )}
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
            <CompanySettingsStaff />
          </Col>
        </Row>
      </Form>
      <ScheduleRemoveModal
        employeeCount={scheduleData?.employees?.length || 0}
        openRemoveModal={openRemoveModal}
        setOpenRemoveModal={setOpenRemoveModal}
        removeScheduleHandler={removeScheduleHandler}
      />
    </div>
  );
});
