import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox } from 'antd';
import Styles from './Checkbox.module.css';

const CheckboxMain = ({ className, children, onChange, ...passedProps }) => (
  <Checkbox
    onChange={onChange}
    className={clsx(className, Styles.checkbox)}
    {...passedProps}
  >
    {children}
  </Checkbox>
);

CheckboxMain.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export { CheckboxMain as Checkbox };
