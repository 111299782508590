import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { ROUTES } from 'const';
import {
  LOGIN_REQUEST,
  PASSWORD_CHANGE_REQUEST,
  TEMPORARY_PASSWORD_CHANGE_REQUEST,
  PASSWORD_RECOVERY_REQUEST,
  loginError,
  loginSuccess,
  passwordChangeSuccess,
  passwordChangeError,
  temporaryPasswordChangeSuccess,
  temporaryPasswordChangeError,
  passwordRecoverySuccess,
  passwordRecoveryError,
} from 'modules/Authentication/actions';
import { redirect } from 'helpers/common';
import {
  loginUser,
  changeUserPassword,
  changeTemporaryUserPassword,
  recoverUserPassword,
} from '../services';

/**
 * log in
 */
function* loginSagaWorker({ payload }) {
  try {
    const response = yield call(loginUser, payload);
    yield put(loginSuccess({ response }));
    redirect('/');
  } catch (error) {
    yield put(loginError({ error }));
  }
}

/**
 * Password change
 */
function* passwordChangeSagaWorker({ payload }) {
  try {
    const response = yield call(changeUserPassword, payload);
    yield put(passwordChangeSuccess({ response }));
    yield put(push(ROUTES.PASSWORD_CHANGED));
  } catch (error) {
    yield put(passwordChangeError({ error }));
  }
}

/**
 * Temporary password change
 */
function* temporaryPasswordChangeSagaWorker({ payload }) {
  try {
    const response = yield call(changeTemporaryUserPassword, payload);
    yield put(temporaryPasswordChangeSuccess({ response }));
    yield put(push(ROUTES.PASSWORD_CHANGED));
  } catch (error) {
    yield put(temporaryPasswordChangeError({ error }));
  }
}

/**
 * Password recovery
 */
function* passwordRecoverySagaWorker({ payload }) {
  try {
    yield call(recoverUserPassword, payload);
    yield put(passwordRecoverySuccess(payload));
    yield put(push(ROUTES.PASSWORD_SENT));
  } catch (error) {
    yield put(passwordRecoveryError({ error }));
  }
}

export function* authenticationMainSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSagaWorker);
  yield takeLatest(PASSWORD_CHANGE_REQUEST, passwordChangeSagaWorker);
  yield takeLatest(PASSWORD_RECOVERY_REQUEST, passwordRecoverySagaWorker);
  yield takeLatest(
    TEMPORARY_PASSWORD_CHANGE_REQUEST,
    temporaryPasswordChangeSagaWorker
  );
}
