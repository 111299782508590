import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Select } from 'antd';
import { MainHeaderWithRouter } from 'components/MainHeader';
import { MainFooter } from 'components/MainFooter';
import Styles from './AppLayout.module.css';
import { PROP_TYPE_STATUS } from '../../const/propTypes';

import HeaderStyles from '../MainHeader/MainHeader.module.css';

const { Option } = Select;

const propTypes = {
  registeredCompanies: PropTypes.array,
  userModules: PropTypes.array,
  currentCompany: PropTypes.object,
  handleCompanyChange: PropTypes.func,
  handleSetApiKey: PropTypes.func,
  children: PropTypes.node,
  handleIsanetRedirect: PropTypes.func,
  userInfoStatus: PROP_TYPE_STATUS,
  userInfoFullName: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  modalProps: PropTypes.object,
  isMainUser: PropTypes.bool,
};

export const AppLayout = ({
  registeredCompanies,
  userModules,
  currentCompany,
  handleCompanyChange,
  handleSetApiKey,
  children,
  handleIsanetRedirect,
  userInfoStatus,
  userInfoFullName,
  isSidebarOpen,
  toggleSidebar,
  modalProps,
  isMainUser,
}) => (
  <Layout className={Styles.wrapper}>
    <MainHeaderWithRouter
      registeredCompanies={registeredCompanies}
      currentCompany={currentCompany}
      handleCompanyChange={handleCompanyChange}
      handleSetApiKey={handleSetApiKey}
      handleIsanetRedirect={handleIsanetRedirect}
      isSideBarOpen={isSidebarOpen}
      isSideBarOpenToggle={toggleSidebar}
      userInfoStatus={userInfoStatus}
      userInfoFullName={userInfoFullName}
      modalProps={modalProps}
      isMainUser={isMainUser}
    />
    <div className={Styles.mobileSelectWrapper}>
      <Select
        value={currentCompany.id}
        className={HeaderStyles.coloredDropdown}
        onChange={handleCompanyChange}
        dropdownClassName={HeaderStyles.dropdownMenu}
      >
        {registeredCompanies
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, name }) => (
            <Option value={id} className={HeaderStyles.option} key={id}>
              {name}
            </Option>
          ))}
      </Select>
    </div>
    <Layout.Content className={Styles.content}>{children}</Layout.Content>
    <div id="dropdown-area" className={HeaderStyles.dropdownArea}></div>
    <MainFooter
      userModules={userModules}
      currentCompanyActivated={currentCompany.activated}
      isMainUser={isMainUser}
    />
  </Layout>
);

AppLayout.propTypes = propTypes;
