import { call, takeLatest, put, select } from 'redux-saga/effects';
import { COMPANY_SETTINGS_REWARDS_DETAIL } from '../../../../const/translations/SalaryCompanySettings';
import {
  ADD_REWARD_TO_FAMILY,
  addRewardToFamilySuccess,
  CREATE_REWARD_FAMILY,
  GET_COMPANY_REWARDS,
  GET_COMPANY_REWARDS_FAMILIES,
  GET_REWARDS_FAMILY,
  getRewardsFamily,
  REMOVE_REWARD_FAMILY,
  REMOVE_REWARD_FROM_FAMILY,
  setCompanyRewardsFamilies,
  setCompanyUnaddedRewards,
  setRewardFamilyMessage,
  setRewardsError,
  setRewardsFamily,
  UPDATE_REWARD_FAMILY,
  UPDATE_REWARD_TO_FAMILY,
  updateRewardToFamilySuccess,
} from '../actions';
import {
  selectCompanyRewardsFamilies,
  selectCompanyUnaddedRewards,
  selectFamilyData,
} from '../selectors';
import {
  addRewardToFamilyRequest,
  createRewardFamilyRequest,
  getCompanyUnaddedRewardsRequest,
  getRewardsFamiliesRequest,
  getRewardsFamilyItemsRequest,
  removeRewardFromFamilyRequest,
  removeRewardsFamilyRequest,
  updateRewardFamilyRequest,
  updateRewardToFamilyRequest,
} from '../services';

function* getCompanyRewardsWorker({ payload: { companyId } }) {
  try {
    const { data } = yield call(getCompanyUnaddedRewardsRequest, {
      companyId,
      query: { limit: 1000 },
    });
    yield put(setCompanyUnaddedRewards(data.unaddedRewards));
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* getCompanyRewardsFamiliesWorker({ payload }) {
  try {
    const { data } = yield call(getRewardsFamiliesRequest, payload);
    yield put(setCompanyRewardsFamilies(data.rewardsFamilies));
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* getRewardsFamilyWorker({ payload }) {
  try {
    const { data } = yield call(getRewardsFamilyItemsRequest, payload);
    yield put(setRewardsFamily(data.rewardFamily));
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* createRewardFamilyWorker({ payload }) {
  try {
    const { data } = yield call(createRewardFamilyRequest, payload);
    yield put(
      getRewardsFamily({
        companyId: payload.companyId,
        familyId: data.rewardFamily.id,
      })
    );
    yield put(
      setRewardFamilyMessage({
        type: 'success',
        text: COMPANY_SETTINGS_REWARDS_DETAIL.SUCCESS_MESSAGE,
      })
    );
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* updateRewardFamilyWorker({ payload }) {
  try {
    yield call(updateRewardFamilyRequest, payload);
    yield put(
      setRewardFamilyMessage({
        type: 'success',
        text: COMPANY_SETTINGS_REWARDS_DETAIL.SUCCESS_MESSAGE,
      })
    );
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* addRewardToFamilyWorker({ payload }) {
  try {
    const { data } = yield call(addRewardToFamilyRequest, payload);

    const addedReward = data.companyReward;
    const unaddedRewards = yield select(selectCompanyUnaddedRewards);

    const unaddedRewardsUpdated = unaddedRewards.filter(
      (reward) => reward.id !== addedReward.reward.id
    );
    yield put(setCompanyUnaddedRewards(unaddedRewardsUpdated));
    yield put(
      addRewardToFamilySuccess({
        ...addedReward,
        canBeRemoved: true,
      })
    );
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* updateRewardToFamilyWorker({ payload }) {
  try {
    const { data } = yield call(updateRewardToFamilyRequest, payload);
    const updatedReward = data.companyReward;

    yield put(updateRewardToFamilySuccess(updatedReward));
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* removeRewardFamilyWorker({ payload }) {
  try {
    yield call(removeRewardsFamilyRequest, payload);
    const families = yield select(selectCompanyRewardsFamilies);
    const familiesUpdated = families.filter(
      (family) => family.id !== payload.familyId
    );
    yield put(setCompanyRewardsFamilies(familiesUpdated));
  } catch (error) {
    yield put(setRewardsError());
  }
}

function* removeRewardFromFamilyWorker({ payload }) {
  try {
    yield call(removeRewardFromFamilyRequest, payload);

    const familyData = yield select(selectFamilyData);
    const unaddedRewards = yield select(selectCompanyUnaddedRewards);

    const rewardItem = familyData.companyRewards.find(
      (reward) => reward.id === payload.companyRewardId
    );

    yield put(
      setRewardsFamily({
        ...familyData,
        companyRewards: familyData.companyRewards.filter(
          (reward) => reward.id !== payload.companyRewardId
        ),
      })
    );
    yield put(setCompanyUnaddedRewards([...unaddedRewards, rewardItem.reward]));
  } catch (error) {
    yield put(setRewardsError());
  }
}

export function* rewardsMainSaga() {
  yield takeLatest(GET_COMPANY_REWARDS, getCompanyRewardsWorker);
  yield takeLatest(CREATE_REWARD_FAMILY, createRewardFamilyWorker);
  yield takeLatest(UPDATE_REWARD_FAMILY, updateRewardFamilyWorker);
  yield takeLatest(GET_REWARDS_FAMILY, getRewardsFamilyWorker);
  yield takeLatest(ADD_REWARD_TO_FAMILY, addRewardToFamilyWorker);
  yield takeLatest(UPDATE_REWARD_TO_FAMILY, updateRewardToFamilyWorker);
  yield takeLatest(REMOVE_REWARD_FAMILY, removeRewardFamilyWorker);
  yield takeLatest(REMOVE_REWARD_FROM_FAMILY, removeRewardFromFamilyWorker);
  yield takeLatest(
    GET_COMPANY_REWARDS_FAMILIES,
    getCompanyRewardsFamiliesWorker
  );
}
