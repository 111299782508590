import {
  creditorDataRequest,
  creditorsSearchRequest,
} from 'modules/Creditors/actions';
import {
  selectCreditorsCommentsForYearChart,
  selectDebtorsCommentsForYearChart,
} from 'modules/commentsModal/selectors';
import {
  selectCreditorPageData,
  selectCreditors,
  selectCreditorsLastUpdated,
  selectCreditorsStatus,
  selectCreditorsYearData,
} from 'modules/Creditors/selectors';
import {
  selectDebtorPageData,
  selectDebtors,
  selectDebtorsLastUpdated,
  selectDebtorsStatus,
  selectDebtorsYearData,
} from 'modules/Debtors/selectors';
import {
  debtorDataRequest,
  debtorsSearchRequest,
} from 'modules/Debtors/actions';
import { ROUTES } from 'const';
import { DASHBOARD_NAMES } from 'const/charts';
import {
  CREDITORS_TRANSLATIONS,
  CREDITOR_TRANSLATIONS,
  DEBTORS_TRANSLATIONS,
  DEBTOR_TRANSLATIONS,
} from 'const/translations';

export const COUNTERPARTIES_DATA = {
  creditors: {
    translations: CREDITORS_TRANSLATIONS,
    dashboardName: DASHBOARD_NAMES.CREDITORS,
    routes: {
      counterparties: ROUTES.CREDITORS,
    },
    actions: {
      searchRequest: creditorsSearchRequest,
    },
    selectors: {
      dataSource: selectCreditors,
      chartData: selectCreditorsYearData,
      comments: selectCreditorsCommentsForYearChart,
      updateDate: selectCreditorsLastUpdated,
      status: selectCreditorsStatus,
    },
  },
  debtors: {
    translations: DEBTORS_TRANSLATIONS,
    dashboardName: DASHBOARD_NAMES.DEBTORS,
    routes: {
      counterparties: ROUTES.DEBTORS,
    },
    actions: {
      searchRequest: debtorsSearchRequest,
    },
    selectors: {
      dataSource: selectDebtors,
      chartData: selectDebtorsYearData,
      comments: selectDebtorsCommentsForYearChart,
      updateDate: selectDebtorsLastUpdated,
      status: selectDebtorsStatus,
    },
  },
};

export const COUNTERPARTY_DATA = {
  creditors: {
    translations: CREDITOR_TRANSLATIONS,
    routes: {
      counterparties: COUNTERPARTIES_DATA.creditors.routes.counterparties,
    },
    actions: {
      dataRequest: creditorDataRequest,
    },
    selectors: {
      data: selectCreditorPageData,
    },
  },
  debtors: {
    translations: DEBTOR_TRANSLATIONS,
    routes: {
      counterparties: COUNTERPARTIES_DATA.debtors.routes.counterparties,
    },
    actions: {
      dataRequest: debtorDataRequest,
    },
    selectors: {
      data: selectDebtorPageData,
    },
  },
};
