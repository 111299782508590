import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { Dropdown } from 'antd';
import {
  isAbsenceInAfternoon,
  isAbsenceInMorning,
  dateStringIntoDate,
  isAbsencePartial,
  isAbsenceWithAttachment,
  isCurrentAbsenceLoading,
} from 'modules/SalaryModule/Calendar/helpers';
import { LEAVES_TYPES } from 'const/translations/SalaryCalendar';
import { Spinner } from 'components/Spinner';
import attachmentIcon from 'assets/icons/attachment.png';
import Styles from '../CalendarPage.module.css';

const { LEAVE_VACATION, LEAVE_ABSENCE } = LEAVES_TYPES;

const propTypes = {
  leaveObj: PropTypes.object,
  employee: PropTypes.object,
  dateString: PropTypes.string,
  absencesMappedToDates: PropTypes.array,
  isDateHoliday: PropTypes.func,
  getCalendarCellOverlay: PropTypes.func,
  openLeaveModal: PropTypes.func,
  downloadAttachment: PropTypes.func,
  selectedRowKeys: PropTypes.array,
  yearDate: PropTypes.object,
  isYearToday: PropTypes.bool,
  isYearHoliday: PropTypes.bool,
  isYearWeekends: PropTypes.bool,
  calendarEmployee: PropTypes.string,
};

export const CalendarTableCell = React.memo(
  ({
    leaveObj,
    employee,
    dateString,
    absencesMappedToDates,
    isDateHoliday,
    getCalendarCellOverlay,
    openLeaveModal,
    downloadAttachment,
    selectedRowKeys,
    yearDate,
    isYearToday,
    isYearHoliday,
    isYearWeekends,
    calendarEmployee,
  }) => {
    const date = yearDate || dateStringIntoDate(dateString);

    const isHoliday = isYearHoliday || isDateHoliday(date);
    const isWeekends =
      isYearWeekends || date.getDay() === 0 || date.getDay() === 6;
    const isToday = isYearToday || moment(date).isSame(moment(), 'day');

    const [firstAbsence, secondAbsence] =
      dateString in absencesMappedToDates
        ? absencesMappedToDates[dateString].filter(
            (el) => el.employeeId === employee.employeeid
          )
        : [];

    let absencesEmployeeAndDate;
    let absencesEmployeeAndDateFirst;
    let absencesEmployeeAndDateSecond;
    let isFullDay;
    let isMorning;
    let isAfternoon;
    let hasAttachment;
    let isCellLoading;
    let isMorningFirst;
    let hasFirstAttachment;
    let hasSecondAttachment;
    let morningLeave = leaveObj;
    let afternoonLeave = leaveObj;
    let hasMorningAttachment = false;
    let hasAfternoonAttachment = false;

    let morningCell = leaveObj && leaveObj?.reason?.type;
    let afternoonCell = leaveObj && leaveObj?.reason?.type;

    if (firstAbsence && secondAbsence) {
      isMorning = true;
      isAfternoon = true;
      isCellLoading = false;
      isFullDay = false;

      absencesEmployeeAndDateFirst = {
        absences: [firstAbsence],
        employeeId: employee.employeeid,
        date: dateString,
      };
      absencesEmployeeAndDateSecond = {
        absences: [secondAbsence],
        employeeId: employee.employeeid,
        date: dateString,
      };

      isMorningFirst = isAbsenceInMorning(absencesEmployeeAndDateFirst);

      hasFirstAttachment = isAbsenceWithAttachment(
        absencesEmployeeAndDateFirst
      );
      hasSecondAttachment = isAbsenceWithAttachment(
        absencesEmployeeAndDateSecond
      );

      if (isMorningFirst) {
        morningCell = leaveObj && firstAbsence?.reason?.type;
        afternoonCell = leaveObj && secondAbsence?.reason?.type;
        morningLeave = firstAbsence;
        afternoonLeave = secondAbsence;
        hasMorningAttachment = hasFirstAttachment;
        hasAfternoonAttachment = hasSecondAttachment;
      } else {
        morningCell = leaveObj && secondAbsence?.reason?.type;
        afternoonCell = leaveObj && firstAbsence?.reason?.type;
        morningLeave = secondAbsence;
        afternoonLeave = firstAbsence;
        hasMorningAttachment = hasSecondAttachment;
        hasAfternoonAttachment = hasFirstAttachment;
      }
    } else {
      absencesEmployeeAndDate = {
        absences: absencesMappedToDates[dateString],
        employeeId: employee.employeeid,
        date: dateString,
      };

      isFullDay = !isAbsencePartial(absencesEmployeeAndDate);
      isMorning = isAbsenceInMorning(absencesEmployeeAndDate);
      isAfternoon = isAbsenceInAfternoon(absencesEmployeeAndDate);

      hasAttachment = isAbsenceWithAttachment(absencesEmployeeAndDate);
      isCellLoading = isCurrentAbsenceLoading(absencesEmployeeAndDate);
    }

    if (isFullDay || !leaveObj) {
      return (
        <Dropdown
          overlay={getCalendarCellOverlay(
            isCellLoading,
            calendarEmployee ? { employeeid: calendarEmployee } : employee,
            dateString || moment(date).format('DD/MM/YYYY'),
            leaveObj,
            openLeaveModal,
            hasAttachment,
            downloadAttachment,
            selectedRowKeys,
            isMorning,
            isAfternoon
          )}
        >
          <div
            className={clsx(Styles.calendarCell, {
              [Styles.sundayCell]: isWeekends,
              [Styles.holidayCell]: isHoliday,
              ...(isYearToday
                ? {
                    [Styles.yearTodayCell]: isToday,
                  }
                : { [Styles.todayCell]: isToday }),
            })}
          >
            {isCellLoading ? (
              <div className={Styles.calendarAttachmentLoaderWrapper}>
                <Spinner
                  className={clsx(Styles.calendarCellWhole, {
                    [Styles.vacation]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                    [Styles.absence]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                  })}
                />
              </div>
            ) : (
              <>
                <div
                  className={clsx(Styles.calendarCellTop, {
                    [Styles.vacation]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                    [Styles.absence]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                  })}
                >
                  {hasAttachment && (
                    <img
                      src={attachmentIcon}
                      alt="Attachment"
                      className={Styles.calendarAttachment}
                    />
                  )}
                </div>
                <div
                  className={clsx(Styles.calendarCellBottom, {
                    [Styles.vacation]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                    [Styles.absence]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                  })}
                />
              </>
            )}
          </div>
        </Dropdown>
      );
    }

    if (!isMorning || !isAfternoon) {
      return (
        <Dropdown
          overlay={getCalendarCellOverlay(
            isCellLoading,
            calendarEmployee ? { employeeid: calendarEmployee } : employee,
            dateString || moment(date).format('DD/MM/YYYY'),
            leaveObj,
            openLeaveModal,
            hasAttachment,
            downloadAttachment,
            selectedRowKeys,
            isMorning,
            isAfternoon
          )}
        >
          <div
            className={clsx(Styles.calendarCell, {
              [Styles.sundayCell]: isWeekends,
              [Styles.holidayCell]: isHoliday,
              ...(isYearToday
                ? {
                    [Styles.yearTodayCell]: isToday,
                  }
                : { [Styles.todayCell]: isToday }),
            })}
          >
            {isCellLoading ? (
              <div className={Styles.calendarAttachmentLoaderWrapper}>
                <Spinner
                  className={clsx(Styles.calendarCellWhole, {
                    [Styles.vacation]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                    [Styles.absence]:
                      leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                  })}
                />
              </div>
            ) : (
              <>
                {isMorning && (
                  <div
                    className={clsx(Styles.calendarCellTop, {
                      [Styles.vacation]:
                        leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                      [Styles.absence]:
                        leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                    })}
                  >
                    {hasAttachment && (
                      <img
                        src={attachmentIcon}
                        alt="Attachment"
                        className={Styles.calendarAttachment}
                      />
                    )}
                  </div>
                )}

                {isAfternoon && (
                  <div
                    className={clsx(Styles.calendarCellBottom, {
                      [Styles.vacation]:
                        leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                      [Styles.absence]:
                        leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
                    })}
                  >
                    {hasAttachment && (
                      <img
                        src={attachmentIcon}
                        alt="Attachment"
                        className={Styles.calendarAttachmentBottom}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </Dropdown>
      );
    }

    return (
      <div
        className={clsx(Styles.calendarCell, {
          [Styles.sundayCell]: isWeekends,
          [Styles.holidayCell]: isHoliday,
          ...(isYearToday
            ? {
                [Styles.yearTodayCell]: isToday,
              }
            : { [Styles.todayCell]: isToday }),
        })}
      >
        {isCellLoading ? (
          <div className={Styles.calendarAttachmentLoaderWrapper}>
            <Spinner
              className={clsx(Styles.calendarCellWhole, {
                [Styles.vacation]:
                  leaveObj && leaveObj?.reason?.type === LEAVE_VACATION,
                [Styles.absence]:
                  leaveObj && leaveObj?.reason?.type === LEAVE_ABSENCE,
              })}
            />
          </div>
        ) : (
          <>
            <Dropdown
              overlay={getCalendarCellOverlay(
                isCellLoading,
                calendarEmployee ? { employeeid: calendarEmployee } : employee,
                dateString || moment(date).format('DD/MM/YYYY'),
                morningLeave,
                openLeaveModal,
                hasMorningAttachment,
                downloadAttachment,
                selectedRowKeys,
                isMorning,
                isAfternoon
              )}
            >
              <div
                className={clsx(
                  Styles.calendarCellTop,
                  isMorning
                    ? {
                        [Styles.vacation]: morningCell === LEAVE_VACATION,
                        [Styles.absence]: morningCell === LEAVE_ABSENCE,
                      }
                    : {}
                )}
              >
                {isMorning && hasFirstAttachment && (
                  <img
                    src={attachmentIcon}
                    alt="Attachment"
                    className={Styles.calendarAttachment}
                  />
                )}
              </div>
            </Dropdown>

            <Dropdown
              overlay={getCalendarCellOverlay(
                isCellLoading,
                employee,
                dateString || moment(date).format('DD/MM/YYYY'),
                afternoonLeave,
                openLeaveModal,
                hasAfternoonAttachment,
                downloadAttachment,
                selectedRowKeys,
                isMorning,
                isAfternoon
              )}
            >
              <div
                className={clsx(
                  Styles.calendarCellBottom,
                  isAfternoon
                    ? {
                        [Styles.vacation]: afternoonCell === LEAVE_VACATION,
                        [Styles.absence]: afternoonCell === LEAVE_ABSENCE,
                      }
                    : {}
                )}
              >
                {isAfternoon && hasSecondAttachment && (
                  <img
                    src={attachmentIcon}
                    alt="Attachment"
                    className={Styles.calendarAttachmentBottom}
                  />
                )}
              </div>
            </Dropdown>
          </>
        )}
      </div>
    );
  }
);

CalendarTableCell.propTypes = propTypes;
