import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  GET_TURNOVER_DATA_ERROR,
  GET_TURNOVER_DATA_REQUEST,
  GET_TURNOVER_DATA_SUCCESS,
} from 'modules/turnoverPage/actions';

const initialState = fromJS({
  turnoverData: { datasets: {} },
  error: '',
  status: STATUS_NOT_REQUESTED,
});

export const turnoverPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_TURNOVER_DATA_REQUEST: {
      return state.setIn(['status'], STATUS_LOADING);
    }
    case GET_TURNOVER_DATA_SUCCESS: {
      const { data } = action.payload;
      return state
        .setIn(['turnoverData'], fromJS(data))
        .setIn(['status'], STATUS_SUCCESS);
    }
    case GET_TURNOVER_DATA_ERROR: {
      const { error } = action.payload;
      return state
        .setIn(['status'], STATUS_ERROR)
        .setIn(['error'], error)
        .setIn(['turnoverData'], fromJS({ datasets: {} }));
    }
    default:
      return state;
  }
};
