import { takeLatest, call, put } from 'redux-saga/effects';

import { unsubscribeUserService } from '../services';
import { UNSUBSCRIBE_USER_REQUEST, unsubscribeUserError } from '../actions';

function* unsubscribeUserWorker({ payload: { onSuccess, values } }) {
  try {
    const body = {
      modules: values.modules,
      confirmation: values.confirmation,
      reasons: values.reasons,
    };
    yield call(unsubscribeUserService, body);
    yield put(onSuccess());
  } catch (error) {
    yield put(unsubscribeUserError({ error }));
  }
}

export function* unsubscribePageMainSaga() {
  yield takeLatest(UNSUBSCRIBE_USER_REQUEST, unsubscribeUserWorker);
}
