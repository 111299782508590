import { mapObjectsToItemsList } from 'helpers/common';

const COUNTRIES_LIST = [
  { COUTRY_ID: 'AFG', COUNTRY_NAME: 'Afghanistan' },
  { COUTRY_ID: 'ALB', COUNTRY_NAME: 'Albanie' },
  { COUTRY_ID: 'ATA', COUNTRY_NAME: 'Antarctique' },
  { COUTRY_ID: 'DZA', COUNTRY_NAME: 'Algerie' },
  { COUTRY_ID: 'ASM', COUNTRY_NAME: 'Samoa Americaines' },
  { COUTRY_ID: 'AND', COUNTRY_NAME: 'Andorre' },
  { COUTRY_ID: 'AGO', COUNTRY_NAME: 'Angola' },
  { COUTRY_ID: 'ATG', COUNTRY_NAME: 'Antigua-et-Barbuda' },
  { COUTRY_ID: 'AZE', COUNTRY_NAME: 'Azerbaidjan' },
  { COUTRY_ID: 'ARG', COUNTRY_NAME: 'Argentine' },
  { COUTRY_ID: 'AUS', COUNTRY_NAME: 'Australie' },
  { COUTRY_ID: 'AUT', COUNTRY_NAME: 'Autriche' },
  { COUTRY_ID: 'BHS', COUNTRY_NAME: 'Bahamas' },
  { COUTRY_ID: 'BHR', COUNTRY_NAME: 'Bahrein' },
  { COUTRY_ID: 'BGD', COUNTRY_NAME: 'Bangladesh' },
  { COUTRY_ID: 'ARM', COUNTRY_NAME: 'Armenie' },
  { COUTRY_ID: 'BRB', COUNTRY_NAME: 'Barbade' },
  { COUTRY_ID: 'BEL', COUNTRY_NAME: 'Belgique' },
  { COUTRY_ID: 'BMU', COUNTRY_NAME: 'Bermudes' },
  { COUTRY_ID: 'BTN', COUNTRY_NAME: 'Bhoutan' },
  { COUTRY_ID: 'BOL', COUNTRY_NAME: 'Bolivie' },
  { COUTRY_ID: 'BIH', COUNTRY_NAME: 'Bosnie-Herzegovine' },
  { COUTRY_ID: 'BWA', COUNTRY_NAME: 'Botswana' },
  { COUTRY_ID: 'BVT', COUNTRY_NAME: 'Ile Bouvet' },
  { COUTRY_ID: 'BRA', COUNTRY_NAME: 'Bresil' },
  { COUTRY_ID: 'BLZ', COUNTRY_NAME: 'Belize' },
  {
    COUTRY_ID: 'IOT',
    COUNTRY_NAME: "Territoire Britannique de l'Ocean Indien",
  },
  { COUTRY_ID: 'SLB', COUNTRY_NAME: 'Iles Salomon' },
  { COUTRY_ID: 'VGB', COUNTRY_NAME: 'Iles Vierges Britanniques' },
  { COUTRY_ID: 'BRN', COUNTRY_NAME: 'Brunei Darussalam' },
  { COUTRY_ID: 'BGR', COUNTRY_NAME: 'Bulgarie' },
  { COUTRY_ID: 'MMR', COUNTRY_NAME: 'Myanmar' },
  { COUTRY_ID: 'BDI', COUNTRY_NAME: 'Burundi' },
  { COUTRY_ID: 'BLR', COUNTRY_NAME: 'Belarus' },
  { COUTRY_ID: 'KHM', COUNTRY_NAME: 'Cambodge' },
  { COUTRY_ID: 'CMR', COUNTRY_NAME: 'Cameroun' },
  { COUTRY_ID: 'CAN', COUNTRY_NAME: 'Canada' },
  { COUTRY_ID: 'CPV', COUNTRY_NAME: 'Cap-vert' },
  { COUTRY_ID: 'CYM', COUNTRY_NAME: 'Iles Caimanes' },
  { COUTRY_ID: 'CAF', COUNTRY_NAME: 'Republique Centrafricaine' },
  { COUTRY_ID: 'LKA', COUNTRY_NAME: 'Sri Lanka' },
  { COUTRY_ID: 'TCD', COUNTRY_NAME: 'Tchad' },
  { COUTRY_ID: 'CHL', COUNTRY_NAME: 'Chili' },
  { COUTRY_ID: 'CHN', COUNTRY_NAME: 'Chine' },
  { COUTRY_ID: 'TWN', COUNTRY_NAME: 'Taiwan' },
  { COUTRY_ID: 'CXR', COUNTRY_NAME: 'Ile Christmas' },
  { COUTRY_ID: 'CCK', COUNTRY_NAME: 'Iles Cocos (Keeling)' },
  { COUTRY_ID: 'COL', COUNTRY_NAME: 'Colombie' },
  { COUTRY_ID: 'COM', COUNTRY_NAME: 'Comores' },
  { COUTRY_ID: 'MYT', COUNTRY_NAME: 'Mayotte' },
  { COUTRY_ID: 'COG', COUNTRY_NAME: 'Republique du Congo' },
  { COUTRY_ID: 'COD', COUNTRY_NAME: 'Republique Democratique du Congo' },
  { COUTRY_ID: 'COK', COUNTRY_NAME: 'Iles Cook' },
  { COUTRY_ID: 'CRI', COUNTRY_NAME: 'Costa Rica' },
  { COUTRY_ID: 'HRV', COUNTRY_NAME: 'Croatie' },
  { COUTRY_ID: 'CUB', COUNTRY_NAME: 'Cuba' },
  { COUTRY_ID: 'CYP', COUNTRY_NAME: 'Chypre' },
  { COUTRY_ID: 'CZE', COUNTRY_NAME: 'Republique Tcheque' },
  { COUTRY_ID: 'BEN', COUNTRY_NAME: 'Benin' },
  { COUTRY_ID: 'DNK', COUNTRY_NAME: 'Danemark' },
  { COUTRY_ID: 'DMA', COUNTRY_NAME: 'Dominique' },
  { COUTRY_ID: 'DOM', COUNTRY_NAME: 'Republique Dominicaine' },
  { COUTRY_ID: 'ECU', COUNTRY_NAME: 'Equateur' },
  { COUTRY_ID: 'SLV', COUNTRY_NAME: 'El Salvador' },
  { COUTRY_ID: 'GNQ', COUNTRY_NAME: 'Guinee Equatoriale' },
  { COUTRY_ID: 'ETH', COUNTRY_NAME: 'Ethiopie' },
  { COUTRY_ID: 'ERI', COUNTRY_NAME: 'Erythree' },
  { COUTRY_ID: 'EST', COUNTRY_NAME: 'Estonie' },
  { COUTRY_ID: 'FRO', COUNTRY_NAME: 'Iles Feroe' },
  { COUTRY_ID: 'FLK', COUNTRY_NAME: 'Iles (malvinas) Falkland' },
  {
    COUTRY_ID: 'SGS',
    COUNTRY_NAME: 'Georgie du Sud et les Iles Sandwich du Sud',
  },
  { COUTRY_ID: 'FJI', COUNTRY_NAME: 'Fidji' },
  { COUTRY_ID: 'FIN', COUNTRY_NAME: 'Finlande' },
  { COUTRY_ID: 'FRA', COUNTRY_NAME: 'France' },
  { COUTRY_ID: 'GUF', COUNTRY_NAME: 'Guyane Francaise' },
  { COUTRY_ID: 'PYF', COUNTRY_NAME: 'Polynesie Francaise' },
  { COUTRY_ID: 'ATF', COUNTRY_NAME: 'Terres Australes Francaises' },
  { COUTRY_ID: 'DJI', COUNTRY_NAME: 'Djibouti' },
  { COUTRY_ID: 'GAB', COUNTRY_NAME: 'Gabon' },
  { COUTRY_ID: 'GEO', COUNTRY_NAME: 'Georgie' },
  { COUTRY_ID: 'GMB', COUNTRY_NAME: 'Gambie' },
  { COUTRY_ID: 'PSE', COUNTRY_NAME: 'Territoire Palestinien Occupe' },
  { COUTRY_ID: 'DEU', COUNTRY_NAME: 'Allemagne' },
  { COUTRY_ID: 'GHA', COUNTRY_NAME: 'Ghana' },
  { COUTRY_ID: 'GIB', COUNTRY_NAME: 'Gibraltar' },
  { COUTRY_ID: 'KIR', COUNTRY_NAME: 'Kiribati' },
  { COUTRY_ID: 'GRC', COUNTRY_NAME: 'Grece' },
  { COUTRY_ID: 'GRL', COUNTRY_NAME: 'Groenland' },
  { COUTRY_ID: 'GRD', COUNTRY_NAME: 'Grenade' },
  { COUTRY_ID: 'GLP', COUNTRY_NAME: 'Guadeloupe' },
  { COUTRY_ID: 'GUM', COUNTRY_NAME: 'Guam' },
  { COUTRY_ID: 'GTM', COUNTRY_NAME: 'Guatemala' },
  { COUTRY_ID: 'GIN', COUNTRY_NAME: 'Guinee' },
  { COUTRY_ID: 'GUY', COUNTRY_NAME: 'Guyana' },
  { COUTRY_ID: 'HTI', COUNTRY_NAME: 'Haiti' },
  { COUTRY_ID: 'HMD', COUNTRY_NAME: 'Iles Heard et Mcdonald' },
  {
    COUTRY_ID: 'VAT',
    COUNTRY_NAME: 'Saint-Siege (etat de la Cite du Vatican)',
  },
  { COUTRY_ID: 'HND', COUNTRY_NAME: 'Honduras' },
  { COUTRY_ID: 'HKG', COUNTRY_NAME: 'Hong-Kong' },
  { COUTRY_ID: 'HUN', COUNTRY_NAME: 'Hongrie' },
  { COUTRY_ID: 'ISL', COUNTRY_NAME: 'Islande' },
  { COUTRY_ID: 'IND', COUNTRY_NAME: 'Inde' },
  { COUTRY_ID: 'IDN', COUNTRY_NAME: 'Indonesie' },
  { COUTRY_ID: 'IRN', COUNTRY_NAME: "Republique Islamique d'Iran" },
  { COUTRY_ID: 'IRQ', COUNTRY_NAME: 'Iraq' },
  { COUTRY_ID: 'IRL', COUNTRY_NAME: 'Irlande' },
  { COUTRY_ID: 'ISR', COUNTRY_NAME: 'Israel' },
  { COUTRY_ID: 'ITA', COUNTRY_NAME: 'Italie' },
  { COUTRY_ID: 'CIV', COUNTRY_NAME: "Cote d'Ivoire" },
  { COUTRY_ID: 'JAM', COUNTRY_NAME: 'Jamaique' },
  { COUTRY_ID: 'JPN', COUNTRY_NAME: 'Japon' },
  { COUTRY_ID: 'KAZ', COUNTRY_NAME: 'Kazakhstan' },
  { COUTRY_ID: 'JOR', COUNTRY_NAME: 'Jordanie' },
  { COUTRY_ID: 'KEN', COUNTRY_NAME: 'Kenya' },
  {
    COUTRY_ID: 'PRK',
    COUNTRY_NAME: 'Republique Populaire Democratique de Coree',
  },
  { COUTRY_ID: 'KOR', COUNTRY_NAME: 'Republique de Coree' },
  { COUTRY_ID: 'KWT', COUNTRY_NAME: 'Koweit' },
  { COUTRY_ID: 'KGZ', COUNTRY_NAME: 'Kirghizistan' },
  { COUTRY_ID: 'LAO', COUNTRY_NAME: 'Republique Democratique Populaire Lao' },
  { COUTRY_ID: 'LBN', COUNTRY_NAME: 'Liban' },
  { COUTRY_ID: 'LSO', COUNTRY_NAME: 'Lesotho' },
  { COUTRY_ID: 'LVA', COUNTRY_NAME: 'Lettonie' },
  { COUTRY_ID: 'LBR', COUNTRY_NAME: 'Liberia' },
  { COUTRY_ID: 'LBY', COUNTRY_NAME: 'Jamahiriya Arabe Libyenne' },
  { COUTRY_ID: 'LIE', COUNTRY_NAME: 'Liechtenstein' },
  { COUTRY_ID: 'LTU', COUNTRY_NAME: 'Lituanie' },
  { COUTRY_ID: 'LUX', COUNTRY_NAME: 'Luxembourg' },
  { COUTRY_ID: 'MAC', COUNTRY_NAME: 'Macao' },
  { COUTRY_ID: 'MDG', COUNTRY_NAME: 'Madagascar' },
  { COUTRY_ID: 'MWI', COUNTRY_NAME: 'Malawi' },
  { COUTRY_ID: 'MYS', COUNTRY_NAME: 'Malaisie' },
  { COUTRY_ID: 'MDV', COUNTRY_NAME: 'Maldives' },
  { COUTRY_ID: 'MLI', COUNTRY_NAME: 'Mali' },
  { COUTRY_ID: 'MLT', COUNTRY_NAME: 'Malte' },
  { COUTRY_ID: 'MTQ', COUNTRY_NAME: 'Martinique' },
  { COUTRY_ID: 'MRT', COUNTRY_NAME: 'Mauritanie' },
  { COUTRY_ID: 'MUS', COUNTRY_NAME: 'Maurice' },
  { COUTRY_ID: 'MEX', COUNTRY_NAME: 'Mexique' },
  { COUTRY_ID: 'MCO', COUNTRY_NAME: 'Monaco' },
  { COUTRY_ID: 'MNG', COUNTRY_NAME: 'Mongolie' },
  { COUTRY_ID: 'MDA', COUNTRY_NAME: 'Republique de Moldova' },
  { COUTRY_ID: 'MSR', COUNTRY_NAME: 'Montserrat' },
  { COUTRY_ID: 'MAR', COUNTRY_NAME: 'Maroc' },
  { COUTRY_ID: 'MOZ', COUNTRY_NAME: 'Mozambique' },
  { COUTRY_ID: 'OMN', COUNTRY_NAME: 'Oman' },
  { COUTRY_ID: 'NAM', COUNTRY_NAME: 'Namibie' },
  { COUTRY_ID: 'NRU', COUNTRY_NAME: 'Nauru' },
  { COUTRY_ID: 'NPL', COUNTRY_NAME: 'Nepal' },
  { COUTRY_ID: 'NLD', COUNTRY_NAME: 'Pays-Bas' },
  { COUTRY_ID: 'ANT', COUNTRY_NAME: 'Antilles Neerlandaises' },
  { COUTRY_ID: 'ABW', COUNTRY_NAME: 'Aruba' },
  { COUTRY_ID: 'NCL', COUNTRY_NAME: 'Nouvelle-Caledonie' },
  { COUTRY_ID: 'VUT', COUNTRY_NAME: 'Vanuatu' },
  { COUTRY_ID: 'NZL', COUNTRY_NAME: 'Nouvelle-Zelande' },
  { COUTRY_ID: 'NIC', COUNTRY_NAME: 'Nicaragua' },
  { COUTRY_ID: 'NER', COUNTRY_NAME: 'Niger' },
  { COUTRY_ID: 'NGA', COUNTRY_NAME: 'Nigeria' },
  { COUTRY_ID: 'NIU', COUNTRY_NAME: 'Niue' },
  { COUTRY_ID: 'NFK', COUNTRY_NAME: 'Ile Norfolk' },
  { COUTRY_ID: 'NOR', COUNTRY_NAME: 'Norvege' },
  { COUTRY_ID: 'MNP', COUNTRY_NAME: 'Iles Mariannes du Nord' },
  { COUTRY_ID: 'UMI', COUNTRY_NAME: 'Iles Mineures Eloignees des Etats-Unis' },
  { COUTRY_ID: 'FSM', COUNTRY_NAME: 'Etats Federes de Micronesie' },
  { COUTRY_ID: 'MHL', COUNTRY_NAME: 'Iles Marshall' },
  { COUTRY_ID: 'PLW', COUNTRY_NAME: 'Palaos' },
  { COUTRY_ID: 'PAK', COUNTRY_NAME: 'Pakistan' },
  { COUTRY_ID: 'PAN', COUNTRY_NAME: 'Panama' },
  { COUTRY_ID: 'PNG', COUNTRY_NAME: 'Papouasie-Nouvelle-Guinee' },
  { COUTRY_ID: 'PRY', COUNTRY_NAME: 'Paraguay' },
  { COUTRY_ID: 'PER', COUNTRY_NAME: 'Perou' },
  { COUTRY_ID: 'PHL', COUNTRY_NAME: 'Philippines' },
  { COUTRY_ID: 'PCN', COUNTRY_NAME: 'Pitcairn' },
  { COUTRY_ID: 'POL', COUNTRY_NAME: 'Pologne' },
  { COUTRY_ID: 'PRT', COUNTRY_NAME: 'Portugal' },
  { COUTRY_ID: 'GNB', COUNTRY_NAME: 'Guinee-Bissau' },
  { COUTRY_ID: 'TLS', COUNTRY_NAME: 'Timor-Leste' },
  { COUTRY_ID: 'PRI', COUNTRY_NAME: 'Porto Rico' },
  { COUTRY_ID: 'QAT', COUNTRY_NAME: 'Qatar' },
  { COUTRY_ID: 'REU', COUNTRY_NAME: 'Reunion' },
  { COUTRY_ID: 'ROU', COUNTRY_NAME: 'Roumanie' },
  { COUTRY_ID: 'RUS', COUNTRY_NAME: 'Federation de Russie' },
  { COUTRY_ID: 'RWA', COUNTRY_NAME: 'Rwanda' },
  { COUTRY_ID: 'SHN', COUNTRY_NAME: 'Sainte-Helene' },
  { COUTRY_ID: 'KNA', COUNTRY_NAME: 'Saint-Kitts-et-Nevis' },
  { COUTRY_ID: 'AIA', COUNTRY_NAME: 'Anguilla' },
  { COUTRY_ID: 'LCA', COUNTRY_NAME: 'Sainte-Lucie' },
  { COUTRY_ID: 'SPM', COUNTRY_NAME: 'Saint-Pierre-et-Miquelon' },
  { COUTRY_ID: 'VCT', COUNTRY_NAME: 'Saint-Vincent-et-les Grenadines' },
  { COUTRY_ID: 'SMR', COUNTRY_NAME: 'Saint-Marin' },
  { COUTRY_ID: 'STP', COUNTRY_NAME: 'Sao Tome-et-Principe' },
  { COUTRY_ID: 'SAU', COUNTRY_NAME: 'Arabie Saoudite' },
  { COUTRY_ID: 'SEN', COUNTRY_NAME: 'Senegal' },
  { COUTRY_ID: 'SYC', COUNTRY_NAME: 'Seychelles' },
  { COUTRY_ID: 'SLE', COUNTRY_NAME: 'Sierra Leone' },
  { COUTRY_ID: 'SGP', COUNTRY_NAME: 'Singapour' },
  { COUTRY_ID: 'SVK', COUNTRY_NAME: 'Slovaquie' },
  { COUTRY_ID: 'VNM', COUNTRY_NAME: 'Viet Nam' },
  { COUTRY_ID: 'SVN', COUNTRY_NAME: 'Slovenie' },
  { COUTRY_ID: 'SOM', COUNTRY_NAME: 'Somalie' },
  { COUTRY_ID: 'ZAF', COUNTRY_NAME: 'Afrique du Sud' },
  { COUTRY_ID: 'ZWE', COUNTRY_NAME: 'Zimbabwe' },
  { COUTRY_ID: 'ESP', COUNTRY_NAME: 'Espagne' },
  { COUTRY_ID: 'ESH', COUNTRY_NAME: 'Sahara Occidental' },
  { COUTRY_ID: 'SDN', COUNTRY_NAME: 'Soudan' },
  { COUTRY_ID: 'SUR', COUNTRY_NAME: 'Suriname' },
  { COUTRY_ID: 'SJM', COUNTRY_NAME: 'Svalbard etIle Jan Mayen' },
  { COUTRY_ID: 'SWZ', COUNTRY_NAME: 'Swaziland' },
  { COUTRY_ID: 'SWE', COUNTRY_NAME: 'Suede' },
  { COUTRY_ID: 'CHE', COUNTRY_NAME: 'Suisse' },
  { COUTRY_ID: 'SYR', COUNTRY_NAME: 'Republique Arabe Syrienne' },
  { COUTRY_ID: 'TJK', COUNTRY_NAME: 'Tadjikistan' },
  { COUTRY_ID: 'THA', COUNTRY_NAME: 'Thailande' },
  { COUTRY_ID: 'TGO', COUNTRY_NAME: 'Togo' },
  { COUTRY_ID: 'TKL', COUNTRY_NAME: 'Tokelau' },
  { COUTRY_ID: 'TON', COUNTRY_NAME: 'Tonga' },
  { COUTRY_ID: 'TTO', COUNTRY_NAME: 'Trinite-et-Tobago' },
  { COUTRY_ID: 'ARE', COUNTRY_NAME: 'Emirats Arabes Unis' },
  { COUTRY_ID: 'TUN', COUNTRY_NAME: 'Tunisie' },
  { COUTRY_ID: 'TUR', COUNTRY_NAME: 'Turquie' },
  { COUTRY_ID: 'TKM', COUNTRY_NAME: 'Turkmenistan' },
  { COUTRY_ID: 'TCA', COUNTRY_NAME: 'Iles Turks et Caiques' },
  { COUTRY_ID: 'TUV', COUNTRY_NAME: 'Tuvalu' },
  { COUTRY_ID: 'UGA', COUNTRY_NAME: 'Ouganda' },
  { COUTRY_ID: 'UKR', COUNTRY_NAME: 'Ukraine' },
  { COUTRY_ID: 'MKD', COUNTRY_NAME: "L'ex-Republique Yougoslave de Macedoine" },
  { COUTRY_ID: 'EGY', COUNTRY_NAME: 'Egypte' },
  { COUTRY_ID: 'GBR', COUNTRY_NAME: 'Royaume-Uni' },
  { COUTRY_ID: 'IMN', COUNTRY_NAME: 'Ile de Man' },
  { COUTRY_ID: 'TZA', COUNTRY_NAME: 'Republique-Unie de Tanzanie' },
  { COUTRY_ID: 'USA', COUNTRY_NAME: 'Etats-Unis' },
  { COUTRY_ID: 'VIR', COUNTRY_NAME: 'Iles Vierges des Etats-Unis' },
  { COUTRY_ID: 'BFA', COUNTRY_NAME: 'Burkina Faso' },
  { COUTRY_ID: 'URY', COUNTRY_NAME: 'Uruguay' },
  { COUTRY_ID: 'UZB', COUNTRY_NAME: 'Ouzbekistan' },
  { COUTRY_ID: 'VEN', COUNTRY_NAME: 'Venezuela' },
  { COUTRY_ID: 'WLF', COUNTRY_NAME: 'Wallis et Futuna' },
  { COUTRY_ID: 'WSM', COUNTRY_NAME: 'Samoa' },
  { COUTRY_ID: 'YEM', COUNTRY_NAME: 'Yemen' },
  { COUTRY_ID: 'SCG', COUNTRY_NAME: 'Serbie-et-Montenegro' },
  { COUTRY_ID: 'ZMB', COUNTRY_NAME: 'Zambie' },
];

export const COUNTRIES_LIST_OPTIONS = mapObjectsToItemsList(
  COUNTRIES_LIST,
  'COUNTRY_NAME',
  'COUNTRY_NAME',
  'COUNTRY_ID'
);
