import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Styles from './HamburgerButton.module.css';

const propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export const HamburgerButton = ({ isOpen, toggleIsOpen }) => (
  <div
    className={clsx(Styles.hamburgerBackground, {
      [Styles.active]: isOpen,
    })}
    onClick={toggleIsOpen}
  >
    <div className={Styles.linesWrapper}>
      <div className={Styles.line} />
    </div>
  </div>
);

HamburgerButton.propTypes = propTypes;
