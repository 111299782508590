import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from 'components/Section';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { Col, Row } from 'antd';
import { YearMonthSwitch } from 'components/ui/YearMonthSwitch';
import {
  COMMON_TEXT_TRANSLATIONS,
  HOMEPAGE_FORM_TRANSLATIONS,
} from 'const/translations';
import { MonthSelector } from 'components/ui/MonthSelector';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { ViewSwitch } from 'components/ui/ViewSwitch';
import { Legends } from 'components/Charts/Legends';
import { TurnoverSummary } from 'components/TurnoverSummary';
import MainStyles from 'assets/styles/main.module.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { useChartFilters } from 'hooks/useChartFilters';
import { ChartFilterCheckbox } from 'components/ui/ChartFilterCheckbox';
import { ROUTES, STATUS_ERROR, STATUS_LOADING } from 'const';
import { useDispatch, useSelector } from 'react-redux';
import { getTurnoverDataRequest } from 'modules/turnoverPage/actions';
import {
  selectCurrentCompanyFirstFiscalMonth,
  selectTurnoverDataForCharts,
  selectTurnoverDataForYear,
  selectTurnoverDataStatus,
  selectTurnoverLastUpdatedDate,
} from 'modules/turnoverPage/selectors';
import {
  getActiveMonthChartData,
  getCumulatedArray,
} from 'helpers/chartDataMappers';
import {
  selectUserCurrentCompanyDosId,
  selectUserCurrentCompanyExerciceWithYear,
} from 'modules/loggedUserInfo/selectors';
import { getAllCommentsRequest } from 'modules/commentsModal/actions';
import {
  selectCommentsStatus,
  selectTurnoverCommentsForChart,
  selectTurnoverCommentsForYearChart,
} from 'modules/commentsModal/selectors';
import { useChartComments } from 'hooks/useChartComments';
import { CommentsModal } from 'components/CommentsModal';
import { DASHBOARD_NAMES } from 'const/charts';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { LineChart } from 'components/Charts/LineChart';
import { BarChart } from 'components/Charts/BarChart';
import { ChartSelect } from 'components/ui/ChartSelect';
import { PillarLoader } from 'components/ui/PillarLoader';
import Styles from './TurnoverPage.module.css';
import { LastUpdate } from '../../components/LastUpdate';

const { TITLES } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { LINE } = CHARTS_VIEW_SWITCH_TYPES;

const { CUMULATIVE } = COMMON_TEXT_TRANSLATIONS;

const { MY_INDICATORS_SECTION_TITLE } = HOMEPAGE_FORM_TRANSLATIONS;

const { ROOT } = ROUTES;

export const TurnoverPage = React.memo(() => {
  const {
    activeYear,
    setActiveYear,
    setActiveMonthName,
    activeMonthName,
    chartViewActiveMode,
    setChartViewActiveMode,
    activeDateViewOption,
    setActiveDateViewOption,
  } = useChartFilters();

  const dispatch = useDispatch();

  const currentCompanyId = useSelector(selectUserCurrentCompanyDosId);
  const { currentExercice, currentYearFromExercice } = useSelector(
    selectUserCurrentCompanyExerciceWithYear
  );

  useEffect(() => {
    dispatch(getTurnoverDataRequest({ currentCompanyId, currentExercice }));
  }, [dispatch, currentCompanyId, currentExercice]);

  const [hiddenLinesYears, setHiddenLinesYears] = useState([]);

  useEffect(() => {
    setActiveYear(Number(currentYearFromExercice));
    setActiveDateViewOption(YEAR);
  }, [
    setActiveYear,
    setActiveDateViewOption,
    currentCompanyId,
    currentYearFromExercice,
  ]);

  const turnoverYearData = useSelector(selectTurnoverDataForYear);

  const turnoverData = useSelector(selectTurnoverDataForCharts);

  const chartData = useMemo(
    () =>
      activeDateViewOption === YEAR
        ? turnoverYearData
        : getActiveMonthChartData(turnoverData, activeMonthName),
    [activeDateViewOption, activeMonthName, turnoverYearData, turnoverData]
  );

  useEffect(() => {
    dispatch(getAllCommentsRequest({ currentExercice }));
  }, [dispatch, currentCompanyId, currentExercice]);

  const turnoverCommentsForMonths = useSelector(selectTurnoverCommentsForChart);
  const turnoverCommentsForYear = useSelector(
    selectTurnoverCommentsForYearChart
  );

  const turnoverComments = useChartComments(
    turnoverCommentsForMonths,
    turnoverCommentsForYear,
    activeMonthName,
    activeDateViewOption
  );

  const {
    isModalOpen,
    onCloseCommentsModal,
    onOpenCommentsModal,
  } = useCommentsModalCallbacks();

  const updateDate = useSelector(selectTurnoverLastUpdatedDate);

  const Chart = useMemo(() => {
    if (chartViewActiveMode === LINE) return LineChart;
    return BarChart;
  }, [chartViewActiveMode]);

  const [isChartCumulative, setIsChartCumulative] = useState(false);
  const toggleIsChartCumulative = useCallback(() => {
    setIsChartCumulative((prev) => !prev);
  }, [setIsChartCumulative]);

  // gets cumulative chart data object
  // e.g from [{id:1, year:2020, values:[2,3,4,5]}] to [{id:1, year:2020, values:[2,5,9,14]}]
  const cumulativeChartData = useMemo(
    () =>
      chartData.map(({ id, year, values }) => {
        const cumulValues = getCumulatedArray(values);
        return {
          id: `${id}${activeMonthName}${activeDateViewOption}`,
          year,
          values: cumulValues,
        };
      }),
    [chartData, activeDateViewOption, activeMonthName]
  );

  const firstMonth = useSelector(selectCurrentCompanyFirstFiscalMonth);

  const turnoverDataFetchStatus = useSelector(selectTurnoverDataStatus);
  const commentsFetchStatus = useSelector(selectCommentsStatus);
  const isLoading = [turnoverDataFetchStatus, commentsFetchStatus].some(
    (status) => status === STATUS_LOADING
  );
  const isError = [turnoverDataFetchStatus, commentsFetchStatus].some(
    (status) => status === STATUS_ERROR
  );

  const yearSelectCallback = useCallback(
    (value) => setActiveDateViewOption(value),
    [setActiveDateViewOption]
  );

  return (
    <section className={Styles.page}>
      <CommentsModal closeModal={onCloseCommentsModal} isOpen={isModalOpen} />
      <div className={MainStyles.container}>
        <Link to={ROOT} className={Styles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
          <span>{MY_INDICATORS_SECTION_TITLE}</span>
        </Link>
      </div>
      <Section title={TITLES.TURNOVER}>
        <Row className={Styles.contentRow}>
          <Col span={24} className={Styles.filtersWrapper}>
            <div className={Styles.dateSwitchAndMonthSelector}>
              <ChartSelect
                activeValue={activeDateViewOption}
                onChange={yearSelectCallback}
                className={Styles.yearMonthSelector}
              />
              <YearMonthSwitch
                setActiveOption={setActiveDateViewOption}
                activeOption={activeDateViewOption}
                className={Styles.yearMonthSwitcher}
              />
              {activeDateViewOption === YEAR ? (
                <div className={Styles.currentYear}>
                  {currentYearFromExercice}
                </div>
              ) : (
                <MonthSelector
                  activeMonthName={activeMonthName}
                  activeYear={activeYear}
                  firstMonth={firstMonth}
                  setActiveMonthName={setActiveMonthName}
                  setActiveYear={setActiveYear}
                  className={Styles.monthSelector}
                  bordered
                />
              )}
            </div>
            <div className={Styles.chartFilters}>
              <ChartFilterCheckbox
                toggleChecked={toggleIsChartCumulative}
                checked={isChartCumulative}
                className={Styles.cumulCheckbox}
                label={CUMULATIVE}
                id="treasuryCumulCheckbox"
              />
              <ViewSwitch
                activeOption={chartViewActiveMode}
                setActiveOption={setChartViewActiveMode}
                className={Styles.chartViewSwitch}
              />
              <Legends
                chartData={chartData}
                hiddenLinesYears={hiddenLinesYears}
                setHiddenLineYears={setHiddenLinesYears}
                className={Styles.chartLegends}
              />
            </div>
          </Col>
          {isLoading && (
            <Col span={24} className={Styles.loaderWrapper}>
              <PillarLoader colored />
            </Col>
          )}
          {!isLoading &&
            (isError ? (
              <Col span={24} className={Styles.loaderWrapper}>
                <PillarLoader />
                <div className={Styles.errorText}>
                  {HOMEPAGE_DASHBOARDS_TRANSLATIONS.DASHBOARD_ERROR_TEXT}
                </div>
              </Col>
            ) : (
              <Col span={24} className={Styles.contentCol}>
                <div className={Styles.chartWrapper}>
                  <Chart
                    firstMonth={firstMonth}
                    activeYear={activeYear}
                    data={isChartCumulative ? cumulativeChartData : chartData}
                    activeDateViewOption={activeDateViewOption}
                    activeMonthName={activeMonthName}
                    hiddenLinesYears={hiddenLinesYears}
                    gradientHeight={600}
                    enableGrid
                    comments={turnoverComments}
                    openCommentsModal={onOpenCommentsModal}
                    dashboardName={DASHBOARD_NAMES.TURNOVER}
                  />
                </div>
                <TurnoverSummary
                  activeMonthName={activeMonthName}
                  activeDateViewOption={activeDateViewOption}
                  firstMonth={firstMonth}
                  chartData={chartData}
                  className={Styles.turnoverSummary}
                  updatedDate={updateDate}
                />
              </Col>
            ))}
        </Row>
        <LastUpdate
          updateDate={updateDate}
          showCondition={Boolean(currentExercice)}
          className={Styles.updateDate}
        />
      </Section>
    </section>
  );
});
