import { createContext, useContext } from 'react';

export const InvoiceTableActionsContext = createContext();

/**
 * Context containing the data for individual invoices.
 *
 * @typedef {Object} InvoiceData
 * @property {number} amount
 * @property {number} amountWithVat
 * @property {boolean} approvedAsGroup
 * @property {string} approvedDueDate
 * @property {string} approvedId
 * @property {({comment: string, date: string})[]} comments
 * @property {boolean} createdInLibeo
 * @property {string} date
 * @property {string} documentId
 * @property {string} dueDate
 * @property {number} id
 * @property {string} invoiceNumber
 * @property {string} invoiceType
 * @property {number} isCancellation
 * @property {number} key
 * @property {boolean} linkedToSupplier
 * @property {string} path
 * @property {string} paymentApprovedBy
 * @property {string} paymentApprovedComment
 * @property {string} paymentApprovedDate
 * @property {string} paymentStatus
 * @property {boolean} preValidated
 * @property {string} provider
 * @property {number} vat
 *
 * @returns {InvoiceData}
 */
export const useInvoiceActionsContext = () => {
  const invoiceData = useContext(InvoiceTableActionsContext);

  if (!invoiceData) {
    throw new Error('No InvoiceTableActionsContext was provided.');
  }

  return invoiceData;
};
