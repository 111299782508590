import { createSelector } from 'reselect';
import {
  mapDashboardDataToChartView,
  mapDashboardDataToCumulLastDayAnnual,
} from 'helpers/chartDataMappers';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import { selectUserCurrentCompanyExercice } from '../../loggedUserInfo/selectors';

export const selectDebtorsStatus = (state) =>
  state.getIn(['debtors', 'status']);

export const selectDebtors = createSelector(
  (state) => state.getIn(['debtors', 'data']),
  (data) => (data ? data.toJS() : [])
);

export const selectDebtorPageData = createSelector(
  (state) => state.getIn(['debtors', 'current']),
  (pageInfo) => {
    const { data, ...rest } = pageInfo;
    return {
      ...rest,
      data: data ? data.toJS() : [],
    };
  }
);

const selectDebtorsDatasets = createSelector(
  (state) => state.getIn(['debtors', 'datasets']),
  (debtorsData) => (debtorsData ? debtorsData.toJS() : [])
);

export const selectDebtorsLastUpdated = (state) =>
  state.getIn(['debtors', 'lastUpdated']) || new Date(Date.now()).toISOString();

const selectDebtorsDataForCharts = createSelector(
  selectDebtorsDatasets,
  selectDebtorsLastUpdated,
  selectUserCurrentCompanyExercice,
  (debtorsDatasets, lastUpdated, currentExercice) =>
    mapDashboardDataToChartView(
      debtorsDatasets,
      'debts',
      lastUpdated,
      currentExercice
    )
);

export const selectDebtorsYearData = createSelector(
  selectCurrentCompanyFirstFiscalMonth,
  selectDebtorsDataForCharts,
  (firstMonthName, debtorsChartData) =>
    mapDashboardDataToCumulLastDayAnnual(debtorsChartData, firstMonthName)
);
