import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';

const {
  TOOLTIP: { PROCESS, NOT_VALIDATED, PAYMENT_STATUS_TOOLTIPS },
} = INVOICE_SEARCH_TRANSLATIONS;

export const getPaymentTooltipTitle = (paymentStatus, isValidated) => {
  const paymentTitleByStatus = paymentStatus
    ? PAYMENT_STATUS_TOOLTIPS[paymentStatus]
    : PROCESS;

  return isValidated ? paymentTitleByStatus : NOT_VALIDATED;
};
