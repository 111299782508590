import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PasswordSent } from 'components/PasswordSent';
import { PasswordChanged } from 'components/PasswordChanged';
import { LoginPage } from 'containers/LoginPage';
import { ChangePasswordPage } from 'containers/ChangePasswordPage';
import { ForgottenPasswordPage } from 'containers/ForgottenPasswordPage';
import { ROUTES } from 'const';
import { UNAUTHORIZED_REDIRECTS } from '../const/RoutesRedirects';

export const UnauthorisedRoutes = () => (
  <Switch>
    <Route exact path={ROUTES.LOGIN} component={LoginPage} />
    <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePasswordPage} />
    <Route
      exact
      path={ROUTES.TEMPORARY_CHANGE_PASSWORD}
      component={ChangePasswordPage}
    />
    <Route
      exact
      path={ROUTES.FORGOT_PASSWORD}
      component={ForgottenPasswordPage}
    />
    <Route exact path={ROUTES.PASSWORD_SENT} component={PasswordSent} />
    <Route exact path={ROUTES.PASSWORD_CHANGED} component={PasswordChanged} />
    <Redirect from="/modifier-mot-depasse" to={ROUTES.CHANGE_PASSWORD} />
    {UNAUTHORIZED_REDIRECTS.map(({ to, from }) => (
      <Redirect exact key={`${from}_${to}`} to={to} from={from} />
    ))}
    <Redirect to={ROUTES.LOGIN} />
  </Switch>
);
