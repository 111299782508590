export const CONTACT_HR_TRANSLATIONS = {
  TITLE: 'Contact & Envoi de documents RH',
  OK_BUTTON: 'Envoyer',
  CANCEL_BUTTON: 'Annuler',
  TOPIC_LABEL: 'Sujet',
  MESSAGE_PLACEHOLDER: 'Message',
  ERRORS: {
    NO_TITLE: 'Veuillez choisir le sujet',
    NO_MESSAGE: 'Veuillez écrire votre demande',
    DEFAULT_REQUEST_ERROR: 'Erreur de demande de Support',
  },
  SUBMIT_SUCCESS: 'Merci ! Votre message a été envoyé',
};
