import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Col, Typography } from 'antd';
import { Button, Input, Radio } from 'components/ui';
import { DatePickerFormik, SelectFormik } from 'components/adaptors';
import { withFormik } from 'formik';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { COUNTRIES_LIST_OPTIONS } from 'const/countriesList';
import { NATIONALITIES_LIST_OPTIONS } from 'const/nationalitiesList';
import { omitEmptyKeys } from 'helpers/common';
import { formikTypes } from 'const/propTypes';
import MainStyles from 'assets/styles/main.module.css';
import { DATE_FORMAT } from 'const';
import { MaskedInputNumber } from 'components/ui/MaskedInputNumber';
import { SharedNotification } from '../SharedNotification';
import { validationSchema } from './CivilData.yupValidationSchema';
import Styles from './CivilData.module.css';

const {
  CIVIL_DATA_FORM_TAB: { LABELS, PLACEHOLDERS, LISTS },
  GENERAL_LABELS,
  GENERAL_PLACEHOLDERS,
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  values: PropTypes.shape({
    lastNameAtBirthday: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    firstName1: PropTypes.string,
    firstName2: PropTypes.string,
    firstName3: PropTypes.string,
    gender: PropTypes.string,
    statusFamily: PropTypes.string,
    numberSS: PropTypes.string,
    numberSSCle: PropTypes.string,
    dateBirthday: PropTypes.date,
    placeBirthCity: PropTypes.string,
    placeBirthCountry: PropTypes.string,
    nationality: PropTypes.string,
    addressActual: PropTypes.string,
  }),
  ...formikTypes,
  onNext: PropTypes.func,
  updateCivilData: PropTypes.func,
};

/**
 * Added abstraction that consists of withFormik HOC
 * and props passed to it. Wraps component on export,
 * passing all formik functionality and props to it.
 */
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({ civilData = {} }) => ({
    lastName: civilData.lastName || '',
    lastNameAtBirthday: civilData.lastNameAtBirthday || '',
    email: civilData.email || '',
    firstName1: civilData.firstName1 || '',
    firstName2: civilData.firstName2,
    firstName3: civilData.firstName3,
    gender: civilData.gender || LABELS.MALE,
    /**
     * undefined is set to show placeholder, it doesn't work
     * with empty string or null
     */
    statusFamily: civilData.statusFamily || undefined,
    numberSS: civilData.numberSS || '',
    numberSSCle: civilData.numberSSCle || '',
    dateBirthday: civilData.dateBirthday || '',
    placeBirthCity: civilData.placeBirthCity || '',
    placeBirthCountry: civilData.placeBirthCountry || 'France',
    nationality: civilData.nationality || 'FRANÇAISE',
    addressActual: civilData.addressActual || '',
  }),
  mapPropsToErrors: ({ civilDataErrors = {} }) => civilDataErrors,
});

const CivilDataTab = ({
  onNext,
  updateCivilData,
  values,
  errors,
  handleChange,
  validateForm,
  setFieldValue,
  setErrors,
  isTabValid,
  civilData,
  resetData,
}) => {
  /**
   * Next two useEffect hooks imitate
   * componentWillUnmount lifecycle method behavior.
   */
  const valuesRef = useRef();
  const numberSSCleRef = useRef(null);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);
  useEffect(
    () => () => {
      validateForm().then((foundErrors) => {
        const isValid = !Object.keys(foundErrors).length;
        updateCivilData({
          values: {
            ...omitEmptyKeys(valuesRef.current),
            isValid,
          },
          civilDataErrors: foundErrors,
          isValid,
        });
      });
    },
    [validateForm, updateCivilData]
  );
  useEffect(() => {
    if (values.numberSS.length === 13 && numberSSCleRef.current) {
      numberSSCleRef.current.focus();
    }
  }, [values.numberSS, numberSSCleRef]);
  const handleGenderChange = useCallback(
    (event) => {
      setFieldValue('statusFamily', undefined);
      handleChange(event);
    },
    [handleChange, setFieldValue]
  );

  /**
   * Additional validation in case tab data has come from backend,
   * where only values and status are saved,
   */
  useEffect(() => {
    if (Object.values(civilData).length && !isTabValid) {
      validateForm().then((foundErrors) => {
        setErrors(foundErrors);
      });
    }
  }, [values, civilData, isTabValid, setErrors, validateForm]);

  /**
   * TODO: Move <p/><Form.Item><Input/></Form.Item> block to <Input /> to make it a single component with `label` prop.
   *
   * TODO: Analyze other markup and think about moving it to a separate component to reuse it later
   * (e.g. <Row className={MainStyles.row}> and <Col span={24} className={MainStyles.col}>)
   */
  return (
    <Form>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.LAST_NAME}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.lastNameAtBirthday ? 'error' : ''}
          >
            <Input
              placeholder={PLACEHOLDERS.BIRTH_LAST_NAME}
              onChange={handleChange}
              name="lastNameAtBirthday"
              value={values.lastNameAtBirthday}
            />
          </Form.Item>
          <Form.Item className={Styles.formItem}>
            <Input
              className={Styles.formInput}
              placeholder={PLACEHOLDERS.USE_LAST_NAME}
              onChange={handleChange}
              name="lastName"
              value={values.lastName}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.FIRST_NAME}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.firstName1 ? 'error' : ''}
          >
            <Input
              className={Styles.formInput}
              placeholder={PLACEHOLDERS.FIRST_NAME_1}
              onChange={handleChange}
              value={values.firstName1}
              name="firstName1"
            />
          </Form.Item>
          <Form.Item className={Styles.formItem}>
            <Input
              className={Styles.formInput}
              placeholder={PLACEHOLDERS.FIRST_NAME_2}
              onChange={handleChange}
              value={values.firstName2}
              name="firstName2"
            />
          </Form.Item>
          <Form.Item className={Styles.formItem}>
            <Input
              className={Styles.formInput}
              placeholder={PLACEHOLDERS.FIRST_NAME_3}
              onChange={handleChange}
              value={values.firstName3}
              name="firstName3"
            />
          </Form.Item>
        </Col>
      </Row>
      <div className={Styles.rowWrapper}>
        <Row className={MainStyles.row}>
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.GENDER}
            </Typography.Paragraph>
            <Form.Item className={Styles.formItem}>
              <Radio
                options={LISTS.GENDER}
                onChange={handleGenderChange}
                value={values.gender}
                name="gender"
              />
            </Form.Item>
          </Col>
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.FAMILY_SITUATION}
            </Typography.Paragraph>
            <Form.Item
              className={Styles.formItem}
              validateStatus={errors.statusFamily ? 'error' : ''}
            >
              <SelectFormik
                placeholder={GENERAL_LABELS.CHOOSE}
                onChange={setFieldValue}
                options={
                  values.gender === LABELS.MALE
                    ? LISTS.STATUS_FAMILY_MALE
                    : LISTS.STATUS_FAMILY_FEMALE
                }
                name="statusFamily"
                value={values.statusFamily}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={MainStyles.row}>
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.EMAIL}
            </Typography.Paragraph>
            <Form.Item
              className={Styles.formItem}
              validateStatus={errors.email ? 'error' : ''}
            >
              <Input
                className={Styles.formInput}
                type="email"
                placeholder={PLACEHOLDERS.EMAIL}
                onChange={handleChange}
                name="email"
                value={values.email}
              />
            </Form.Item>
          </Col>
          <Col span={24} className={MainStyles.col}>
            <Typography.Paragraph
              className={clsx(MainStyles.formLabel, Styles.formLabel)}
            >
              {LABELS.SS_NUMBER}
            </Typography.Paragraph>
            <Form.Item
              className={Styles.formItemNumber}
              validateStatus={errors.numberSS ? 'error' : ''}
            >
              <MaskedInputNumber
                mask="_   _ _   _ _   _ _   _ _ _   _ _ _"
                maxLength={13}
                className={Styles.formItemSsNumber1}
                placeholder={PLACEHOLDERS.SS_NUMBER_1}
                onChange={setFieldValue}
                value={values.numberSS}
                name="numberSS"
              />
            </Form.Item>
            <Form.Item
              className={Styles.formItemNumber}
              validateStatus={errors.numberSSCle ? 'error' : ''}
            >
              <MaskedInputNumber
                ref={numberSSCleRef}
                mask="_ _"
                maxLength={2}
                className={Styles.formItemSsNumber2}
                placeholder={PLACEHOLDERS.SS_NUMBER_2}
                onChange={setFieldValue}
                value={values.numberSSCle}
                name="numberSSCle"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.DATE_OF_BIRTH}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.dateBirthday ? 'error' : ''}
          >
            <DatePickerFormik
              storageFormat={DATE_FORMAT}
              placeholder={GENERAL_PLACEHOLDERS.DATE_FORMAT}
              onChange={setFieldValue}
              name="dateBirthday"
              value={values.dateBirthday}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.PLACE_OF_BIRTH}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.placeBirthCity ? 'error' : ''}
          >
            <Input
              className={Styles.formInput}
              placeholder={PLACEHOLDERS.CITY}
              onChange={handleChange}
              name="placeBirthCity"
              value={values.placeBirthCity}
            />
          </Form.Item>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.placeBirthCountry ? 'error' : ''}
          >
            <SelectFormik
              placeholder={GENERAL_LABELS.CHOOSE}
              defaultValue="France"
              onChange={setFieldValue}
              options={COUNTRIES_LIST_OPTIONS}
              name="placeBirthCountry"
              value={values.placeBirthCountry}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.NATIONALITY}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.nationality ? 'error' : ''}
          >
            <SelectFormik
              placeholder={GENERAL_LABELS.CHOOSE}
              defaultValue="FRANCAISE"
              onChange={setFieldValue}
              options={NATIONALITIES_LIST_OPTIONS}
              name="nationality"
              value={values.nationality}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.ACTUAL_ADDRESS}
          </Typography.Paragraph>
          <Form.Item
            className={Styles.formItem}
            validateStatus={errors.addressActual ? 'error' : ''}
          >
            <Input
              className={Styles.formInputFull}
              placeholder={PLACEHOLDERS.ADDRESS_EXAMPLE}
              onChange={handleChange}
              value={values.addressActual}
              name="addressActual"
            />
          </Form.Item>
        </Col>
      </Row>
      {/* TODO: Move out as separate component */}
      <SharedNotification />
      <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
        <Button role="button" big outlined disabled>
          {GENERAL_LABELS.PREV_TAB}
        </Button>
        <Button outlined role="button" big onClick={resetData}>
          {GENERAL_LABELS.RESET}
        </Button>
        <Button role="button" big onClick={onNext}>
          {GENERAL_LABELS.NEXT_TAB}
        </Button>
      </div>
    </Form>
  );
};

CivilDataTab.propTypes = propTypes;

export const CivilDataFormTab = formikEnhancer(CivilDataTab);
