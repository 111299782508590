import { Col, Input, Row, Typography, Form } from 'antd';
import { Radio } from 'components/ui';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import MainStyles from 'assets/styles/main.module.css';
import { COMPANY_SETTINGS } from '../../../../../const/translations/SalaryCompanySettings';
import Styles from '../../CompanySettingsPage.module.css';

const { Text } = Typography;

const propTypes = {
  title: PropTypes.string,
  titleError: PropTypes.string,
  activeTab: PropTypes.string,
  handleTitleChange: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export const CSScheduleDetailTitle = ({
  title,
  titleError,
  handleTitleChange,
  handleTabChange,
  activeTab,
}) => (
  <Row type="flex">
    <Col xs={{ span: 24 }} sm={{ span: 18 }}>
      <Form.Item className={Styles.formRow}>
        <Text
          className={clsx(
            MainStyles.heading,
            MainStyles.headingH3,
            Styles.textLabel
          )}
        >
          Titre
        </Text>
        <Input
          size="large"
          onChange={handleTitleChange}
          value={title}
          name="title"
          type="text"
          className={titleError ? Styles.titleInputError : ''}
        />
        <Text className={Styles.titleError}>{titleError}</Text>
      </Form.Item>
      <Form.Item className={Styles.formRow}>
        <Text
          className={clsx(
            MainStyles.heading,
            MainStyles.headingH3,
            Styles.textLabel
          )}
        >
          Type
        </Text>
        <Radio
          size="large"
          className={clsx(MainStyles.radioGroup, Styles.tabs)}
          options={COMPANY_SETTINGS.SCHEDULE_FORM.TABS_OPTIONS}
          onChange={handleTabChange}
          value={activeTab}
        />
      </Form.Item>
    </Col>
  </Row>
);

CSScheduleDetailTitle.propTypes = propTypes;
