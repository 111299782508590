import { request } from 'utils/request';

export const searchInvoices = (payload, queryObject = {}) =>
  request.post(`invoices/search`, payload, { params: queryObject });

export const downloadInvoiceAttachedDocument = (invoiceId) =>
  request.get(`invoices/${invoiceId}?download=1`, {
    responseType: 'blob',
  });

export const approveInvoice = ({ documentId, ...payload }) =>
  request.put(`/invoices/validate/${documentId}`, payload);

export const approveInvoices = (payload) =>
  request.put(`/invoices/validate/list`, payload);

export const devalidateInvoice = ({ documentId }) =>
  request.put(`/invoices/devalidate/${documentId}`);

export const prevalidateInvoice = ({ documentId, paidOutside }) =>
  request.patch(
    `/invoices/prevalidate/${documentId}${
      paidOutside ? '?paidoutside=true' : ''
    }`
  );

export const cancelPrevalidation = ({ documentId }) =>
  request.patch(`/invoices/deprevalidate/${documentId}`);

export const payInvoiceOutside = ({ documentId, commentary }) =>
  request.put(`/invoices/paidoutside/${documentId}`, {
    paymentApprovedComment: commentary,
  });

export const payInvoicesOutside = (payload) =>
  request.put(`/invoices/paidoutside/list`, payload);

export const deleteInvoice = ({ documentId }) =>
  request.delete(`/invoices/${documentId}`);

export const acceptInvoice = ({ documentId }) =>
  request.patch(`/invoices/accept/${documentId}`);

export const addInvoiceComment = ({ documentId, ...payload }) =>
  request.put(`/invoices/comment/${documentId}`, payload);

export const sendInvoiceByEmail = (invoiceId) =>
  request.get(`/invoices/send/${invoiceId}`);

export const createInvoiceInLibeo = (invoiceId) =>
  request.post('/invoices/libeo', { invoiceId });

/**
 * for user with validateAndPay access all payment links should have an embedded view
 * @param invoiceId string
 * @param isAbleToValidateAndPay boolean
 */
export const getInvoicePaymentURL = ({ invoiceId, isAbleToValidateAndPay }) =>
  request.get(`/invoices/libeo/${invoiceId}?embed=${isAbleToValidateAndPay}`);

export const downloadDocumentList = (fileIds) =>
  request.get(`/invoices/download/selected`, {
    responseType: 'blob',
    params: {
      ids: fileIds.join(','),
    },
  });
