import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { openFile } from 'helpers/common';
import {
  GET_UPLOAD_HISTORY_REQUEST,
  getUploadHistorySuccess,
  getUploadHistoryError,
  UPLOAD_FILES_REQUEST,
  uploadFilesSuccess,
  uploadFilesError,
  moreUploadHistorySuccess,
  moreUploadHistoryError,
  MORE_UPLOAD_HISTORY_REQUEST,
  DOWNLOAD_DOCUMENT_REQUEST,
  downloadDocumentError,
  downloadDocumentSuccess,
} from '../actions';
import {
  selectUploadedHistoryLength,
  selectUploadHistoryIds,
  selectUploadHistoryMeta,
} from '../selectors';
import {
  getUploadHistory,
  uploadDocuments,
  downloadDocument,
} from '../services';
import { formatUploadErrorsToMessage, sortUploadsByStatus } from '../helpers';

/**
 * fetch history of uploaded files
 */
function* uploadHistoryWorker({ payload: { companyId } }) {
  try {
    const {
      data: { documents },
      meta,
    } = yield call(getUploadHistory, { companyId });
    yield put(getUploadHistorySuccess({ documents, meta }));
  } catch (error) {
    yield put(getUploadHistoryError({ error }));
  }
}

/**
 * Fetch next chunk of the same upload history
 */
function* uploadMoreHistoryWorker() {
  try {
    const { limit } = yield select(selectUploadHistoryMeta);
    const downloadedAmount = yield select(selectUploadedHistoryLength);
    const companyId = yield select(selectUserCompanyId);
    const {
      data: { documents },
      meta,
    } = yield call(getUploadHistory, {
      limit,
      offset: downloadedAmount,
      companyId,
    });
    const docsIds = yield select(selectUploadHistoryIds);
    yield put(
      moreUploadHistorySuccess({
        documents: documents.filter(({ id }) => !docsIds.includes(id)),
        meta,
      })
    );
  } catch (error) {
    yield put(moreUploadHistoryError({ error }));
  }
}

/**
 * upload files to backend
 */
function* uploadFilesWorker({ payload: { uploadedFiles, onError } }) {
  try {
    const companyId = yield select(selectUserCompanyId);
    const formData = new FormData();
    uploadedFiles.forEach(({ originFileObj }) =>
      formData.append('files', originFileObj)
    );
    formData.append('companyId', companyId);
    const {
      data: { documents },
    } = yield call(uploadDocuments, formData);
    const { successful, failed } = sortUploadsByStatus(documents);
    if (failed.length) {
      yield onError(formatUploadErrorsToMessage(failed));
    }
    if (successful.length) {
      yield put(uploadFilesSuccess({ documents: successful }));
    }
    if (failed.length && !successful.length) {
      yield put(uploadFilesError({}));
    }
  } catch (error) {
    yield put(uploadFilesError({ error }));
    onError(
      error.error || UPLOAD_DOCUMENT_TRANSLATIONS.MESSAGES.ERROR_DURING_UPLOAD
    );
  }
}

function* downloadFileWorker({ payload: { fileId } }) {
  try {
    const { fileData } = yield call(downloadDocument, fileId);

    yield call(openFile, fileData);
    yield put(downloadDocumentSuccess({ fileId }));
  } catch (error) {
    yield put(downloadDocumentError({ fileId }));
  }
}

export function* uploadDocumentsMainSaga() {
  yield takeLatest(GET_UPLOAD_HISTORY_REQUEST, uploadHistoryWorker);
  yield takeLatest(UPLOAD_FILES_REQUEST, uploadFilesWorker);
  yield takeLatest(MORE_UPLOAD_HISTORY_REQUEST, uploadMoreHistoryWorker);
  yield takeLatest(DOWNLOAD_DOCUMENT_REQUEST, downloadFileWorker);
}
