import { getType, getActionCreator } from 'utils';
const prefix = '@@app/hrModal';

export const CONTACT_HR_REQUEST = getType(prefix, 'CONTACT_HR_REQUEST');
export const contactHRRequest = getActionCreator(CONTACT_HR_REQUEST);

const CONTACT_HR_SUCCESS = getType(prefix, 'CONTACT_HR_SUCCESS');
export const contactHRSuccess = getActionCreator(CONTACT_HR_SUCCESS);

const CONTACT_HR_ERROR = getType(prefix, 'CONTACT_HR_ERROR');
export const contactHRError = getActionCreator(CONTACT_HR_ERROR);
