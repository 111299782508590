import { INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
import { useSelector } from 'react-redux';
import { selectStoredPayload } from 'modules/invoiceSearch/selectors';
import { useInvoiceActionsContext } from 'contexts/InvoiceTableActionsContext';
import { useInvoiceActionsFlags } from './useInvoiceActionsFlags';
const { IN_PROGRESS } = INVOICE_SEARCH_PAYMENT_STATUSES;

export const useInvoiceActionsData = () => {
  const invoiceData = useInvoiceActionsContext();
  const invoiceFlags = useInvoiceActionsFlags();

  const { paymentStatus } = invoiceData;
  const { canValidate } = invoiceFlags;

  const { paymentStatus: paymentStatusOnFilter } = useSelector(
    selectStoredPayload
  );
  const paymentStatusForValidatingUser =
    canValidate && paymentStatusOnFilter === IN_PROGRESS
      ? paymentStatusOnFilter
      : paymentStatus;

  return {
    ...invoiceData,
    ...invoiceFlags,
    paymentStatus: paymentStatusForValidatingUser,
  };
};
