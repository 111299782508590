import { getType, getActionCreator } from 'utils';
const prefix = '@@app/LoginPage';

export const GET_LOGIN_BANNERS_REQUEST = getType(
  prefix,
  'GET_LOGIN_BANNERS_REQUEST'
);
export const getLoginBannersRequest = getActionCreator(
  GET_LOGIN_BANNERS_REQUEST
);

export const GET_LOGIN_BANNERS_SUCCESS = getType(
  prefix,
  'GET_LOGIN_BANNERS_SUCCESS'
);
export const getLoginBannersSuccess = getActionCreator(
  GET_LOGIN_BANNERS_SUCCESS
);

export const GET_LOGIN_BANNERS_ERROR = getType(
  prefix,
  'GET_LOGIN_BANNERS_ERROR'
);
export const getLoginBannersError = getActionCreator(GET_LOGIN_BANNERS_ERROR);
