import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, message } from 'antd';

import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { COMMON_LABELS, INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { useDispatch } from 'react-redux';
import { validateAndPayInvoice } from 'modules/invoiceSearch/actions';

const propTypes = {
  handleClose: PropTypes.func,
  documentId: PropTypes.string,
};

const {
  MODAL: { VALIDATE_AND_PAY_CONFIRM },
} = INVOICE_SEARCH_TRANSLATIONS;

export const ValidateAndPayModal = ({ handleClose, documentId }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    dispatch(
      validateAndPayInvoice({
        invoiceId: documentId,
        onError: (error) => message.error(error),
      })
    );
    handleClose();
  }, [dispatch, documentId, handleClose]);

  return (
    <>
      <div className={Styles.infoModalRow}>
        <Typography>{VALIDATE_AND_PAY_CONFIRM}</Typography>
      </div>
      <div className="ant-modal-confirm-btns">
        <Button className="ant-btn ant-btn-primary" onClick={handleSubmit}>
          {COMMON_LABELS.YES}
        </Button>
        <Button className="ant-btn ant-btn-primary" onClick={handleClose}>
          {COMMON_LABELS.NO}
        </Button>
      </div>
    </>
  );
};

ValidateAndPayModal.propTypes = propTypes;
