import { put, call, select, take, race, delay } from 'redux-saga/effects';
import { invoicesSearchSuccess } from 'modules/invoiceSearch/actions';
import {
  selectStoredMeta,
  selectStoredPayload,
  selectTableSort,
} from 'modules/invoiceSearch/selectors';
import { INVOICE_POLLING_DURATION } from 'const';
import { searchInvoices } from 'modules/invoiceSearch/services';
import {
  getPendingInvoicesIds,
  mapInvoicesResponseToReduxStore,
} from 'modules/invoiceSearch/helpers';
import { separateStatusFromFlags } from 'helpers/invoiceRequests';
import { START_LONG_POLLING, STOP_LONG_POLLING } from '../actions';

// Individual polling
// const { ACCEPTED, REJECTED } = INVOICE_SEARCH_PAYMENT_STATUSES;

// /**
//  * Individual polling worker. Intended to terminate only if payment cycle
//  * ends(invoice gets either 'accepted' or 'rejected' status)
//  * Any mid-process terminations are handled by pollAllWorker
//  * @param invoiceId
//  */
// function* pollForStatusUpdatesWorker(invoiceId) {
//   try {
//     const { data: invoiceData } = yield call(pollForStatusUpdates, {
//       invoiceId,
//     });
//     yield put(updateInvoice({ invoiceData }));
//     console.warn('invoiceData', invoiceData);
//     if (
//       invoiceData &&
//       ![REJECTED, ACCEPTED].includes(invoiceData.paymentStatus)
//     ) {
//       yield call(pollForStatusUpdatesWorker, invoiceId);
//     }
//   } catch (e) {
//     console.warn('error', e);
//     if (e && [502].includes(e.statusCode)) {
//       yield call(pollForStatusUpdatesWorker, invoiceId);
//     }
//   }
// }

/**
 * Polling 'controller' worker, indicates that polling is in progress
 */
function* pollAllWorker() {
  try {
    yield delay(INVOICE_POLLING_DURATION);

    const storedPayload = yield select(selectStoredPayload);
    const { limit, offset } = yield select(selectStoredMeta);
    const sort = yield select(selectTableSort);
    const flagsAndStatus = separateStatusFromFlags(storedPayload.paymentStatus);
    const searchPayload = { ...storedPayload, ...flagsAndStatus };
    const { data, meta } = yield call(searchInvoices, searchPayload, {
      limit: offset ? offset + limit : limit,
      sort,
    });

    const mappedToReduxData = mapInvoicesResponseToReduxStore(data.documents);
    yield put(
      invoicesSearchSuccess({
        data: mappedToReduxData,
        meta,
        payload: storedPayload,
      })
    );

    const remainingPendingInvoices = getPendingInvoicesIds(data.documents);
    if (remainingPendingInvoices.length) {
      yield call(pollAllWorker);
    }
  } catch (error) {
    console.warn(error);
  }
}

// /**
//  * Imperatively adds polling for invoice when polling cycle is
//  * already started, then cancels it when cycle ends(or polling is stopped).
//  * On the new cycle cancelled invoice polling will be invoked by pollAllWorker
//  * @param documentId
//  */
// function* startAdditionalPolling({ payload: { documentId } }) {
//   try {
//     yield race([
//       call(pollForStatusUpdatesWorker, documentId),
//       take(END_POLLING_CYCLE),
//     ]);
//   } catch (error) {
//     console.warn(error);
//   }
// }

export function* pollingWatcher() {
  // yield takeEvery(START_ADDITIONAL_POLLING, startAdditionalPolling);
  while (true) {
    yield take(START_LONG_POLLING);
    yield race([call(pollAllWorker), take(STOP_LONG_POLLING)]);
  }
}
