import { request } from 'utils/request';

/**
 * Has content-disposition header, but cannot have a name in it,
 * due to the fact that our backend don't have a direct access to
 * the files themselves;
 */
export const getSharepointFile = (companyId, fileId) =>
  request.get(`companies/${companyId}/buckets/`, {
    responseType: 'blob',
    params: { fileId },
  });

/**
 * Same service is used for both getting the contents of specific folder,
 * or to get results of search. payloadInfo includes path to folder in first case and
 * search query in second.
 */
export const getSharepointContent = ({
  companyId,
  payloadInfo,
  isSearch = false,
}) => {
  const params = isSearch ? { search: payloadInfo } : { path: payloadInfo };
  return request.get(`companies/${companyId}/buckets/`, {
    params,
  });
};
