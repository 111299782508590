import { useCallback, useEffect, useMemo, useState } from 'react';

export const useSimpleMedia = (query) => {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const getValue = useCallback(() => mediaQuery.matches, [mediaQuery]);
  const [matches, setValue] = useState(getValue);

  useEffect(() => {
    const handleResize = () => {
      const newValue = getValue();

      if (newValue === matches) {
        return;
      }

      setValue(newValue);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [matches, getValue]);

  return matches;
};
