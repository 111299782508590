import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'const';

const propTypes = {
  isAuthorized: PropTypes.bool,
  fallbackRoute: PropTypes.oneOf(Object.values(ROUTES)),
};
/**
 * Conditional route, uses isAuthorized bool to determine
 * whether to grant user access to route component, or to redirect
 * him to fallback route or access denied page(if no fallback specified)
 */
export const ProtectedRoute = ({
  isAuthorized,
  fallbackRoute,
  ...passingProps
}) =>
  isAuthorized ? (
    <Route {...passingProps} />
  ) : (
    <Redirect
      to={
        Object.values(ROUTES).includes(fallbackRoute)
          ? fallbackRoute
          : ROUTES.NOT_AUTHORIZED
      }
    />
  );

ProtectedRoute.propTypes = propTypes;
