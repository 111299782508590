import { getType, getActionCreator } from 'utils';
const prefix = '@@app/turnoverpage';

export const GET_TURNOVER_DATA_REQUEST = getType(
  prefix,
  'GET_TURNOVER_DATA_REQUEST'
);
export const getTurnoverDataRequest = getActionCreator(
  GET_TURNOVER_DATA_REQUEST
);

export const GET_TURNOVER_DATA_SUCCESS = getType(
  prefix,
  'GET_TURNOVER_DATA_SUCCESS'
);
export const getTurnoverDataSuccess = getActionCreator(
  GET_TURNOVER_DATA_SUCCESS
);

export const GET_TURNOVER_DATA_ERROR = getType(
  prefix,
  'GET_TURNOVER_DATA_ERROR'
);
export const getTurnoverDataError = getActionCreator(GET_TURNOVER_DATA_ERROR);
