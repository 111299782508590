import { call, put, takeLatest, all, take, select } from 'redux-saga/effects';

import { uploadChannel } from 'utils/uploadChannel';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { redirect } from 'helpers/common';
import { ROUTES } from 'const';
import {
  getNewEmployeeTab1Infos,
  getNewEmployeeTab2Infos,
  getNewEmployeeTab3Infos,
  getNewEmployeeTab4Infos,
  getNewEmployeeTab5Infos,
  uploadNewEmployeeTab1,
  uploadNewEmployeeTab2,
  uploadNewEmployeeTab3,
  uploadNewEmployeeTab4,
  uploadNewEmployeeTab5,
  resetFormService,
} from '../services';
import {
  formInfosSuccess,
  formInfosError,
  FORM_INFOS_REQUEST,
  civilDataUpdateLocal,
  civilDataUploadSuccess,
  civilDataUploadError,
  CIVIL_DATA_UPLOAD_REQUEST,
  salarySituationUpdateLocal,
  salarySituationUploadSuccess,
  salarySituationUploadError,
  workContractUpdateLocal,
  workContractUploadSuccess,
  workContractUploadError,
  specialSituationsUpdateLocal,
  specialSituationsUploadSuccess,
  specialSituationsUploadError,
  submitFormSuccess,
  documentsUploadProgress,
  documentsUploadError,
  SALARY_SITUATION_UPLOAD_REQUEST,
  WORK_CONTRACT_UPLOAD_REQUEST,
  SPECIAL_SITUATIONS_UPLOAD_REQUEST,
  DOCUMENTS_UPLOAD_REQUEST,
  documentsUpdateLocal,
  RESET_FORM_REQUEST,
  resetFormSuccess,
  resetFormError,
} from '../actions';
import {
  mapFilesToRequestSaveTab5,
  mapTab5ValuesToFiles,
  mapResponseFilesToStore,
} from '../mappers';

/**
 * request all employeeRegister form tabs data
 */
function* requestTabInfos() {
  try {
    const [
      civilData,
      salarySituation,
      workContract,
      specialSituations,
      documents,
    ] = yield all([
      call(getNewEmployeeTab1Infos),
      call(getNewEmployeeTab2Infos),
      call(getNewEmployeeTab3Infos),
      call(getNewEmployeeTab4Infos),
      call(getNewEmployeeTab5Infos),
    ]);
    yield put(
      formInfosSuccess({
        civilData,
        salarySituation,
        workContract,
        specialSituations,
        documents,
      })
    );
  } catch (error) {
    yield put(formInfosError({ error }));
  }
}

/**
 * upload civil data form tab values
 * @param values - form values
 */
function* uploadCivilDataInfos({ payload }) {
  try {
    yield call(uploadNewEmployeeTab1, payload.values);
    yield put(civilDataUpdateLocal(payload));
    yield put(civilDataUploadSuccess());
  } catch (error) {
    yield put(civilDataUploadError({ error }));
  }
}

/**
 * upload civil data form tab values
 * @param values - form values
 */
function* uploadSalarySituationInfos({ payload }) {
  try {
    yield call(uploadNewEmployeeTab2, payload.values);
    yield put(salarySituationUpdateLocal(payload));
    yield put(salarySituationUploadSuccess());
  } catch (error) {
    yield put(salarySituationUploadError({ error }));
  }
}

/**
 * upload civil data form tab values
 * @param values - form values
 */
function* uploadWorkContactInfos({ payload }) {
  try {
    yield call(uploadNewEmployeeTab3, payload.values);
    yield put(workContractUpdateLocal(payload));
    yield put(workContractUploadSuccess());
  } catch (error) {
    yield put(workContractUploadError({ error }));
  }
}

/**
 * upload civil data form tab values
 * @param values - form values
 */
function* uploadSpecialSituationsInfos({ payload }) {
  try {
    yield call(uploadNewEmployeeTab4, payload.values);
    yield put(specialSituationsUpdateLocal(payload));
    yield put(specialSituationsUploadSuccess());
  } catch (error) {
    yield put(specialSituationsUploadError({ error }));
  }
}

function* uploadDocument(file, index, count) {
  const channel = yield call(
    uploadChannel,
    '/forms/employees/step-5/send-document',
    file
  );
  while (channel) {
    try {
      const { progress, data, error, complete } = yield take(channel);
      if (complete) {
        // TODO remove mock id when API will be fixed
        return { id: data ? data.data.id : '' };
      }
      if (progress) {
        yield put(documentsUploadProgress((index * 100 + progress) / count));
      } else if (error) {
        yield put(documentsUploadError(error));
        return { error };
      }
    } catch (error) {
      yield put(documentsUploadError(error));
      return { error };
    }
  }
  return {};
}

/**
 * upload documents(vouchers) form tab files
 * and initiate form submit on backend.
 * @param {Object} payload
 */
function* uploadDocuments({ payload }) {
  try {
    yield put(documentsUpdateLocal(payload));
    if (!payload.isValid) {
      return;
    }
    const { comment, ...restValues } = payload.values;
    const files = mapTab5ValuesToFiles(restValues);
    /**
       Series files upload one by one using uploadChannel from utils
       with support progress, event channel emit events with progress
     */
    const forUploadCount = files.filter(({ file }) => file).length;
    let index = 0;
    let progressIndex = 0;
    while (index < files.length) {
      if (files[index].id) {
        index += 1;
        // eslint-disable-next-line no-continue
        continue;
      }
      const { id, error } = yield call(
        uploadDocument,
        files[index],
        progressIndex,
        forUploadCount
      );
      if (error) {
        yield put(documentsUploadError({ error }));
        return;
      }

      files[index].id = id;

      progressIndex += 1;
      index += 1;
    }

    // map names to already uploaded files
    const filesWithIds = files.map(({ name, type, id, file, number }) => ({
      id: id || Math.random(),
      type,
      name: name || file.name,
      number,
    }));

    // update store
    yield call(documentsUpdateLocal, {
      ...payload,
      values: mapResponseFilesToStore(filesWithIds),
    });

    const currentCompanyId = yield select(selectUserCompanyId);
    // attach uploaded files to tab 5 by id
    // success also triggers form submission
    yield call(
      uploadNewEmployeeTab5,
      mapFilesToRequestSaveTab5(filesWithIds, currentCompanyId, comment)
    );

    yield put(submitFormSuccess());
  } catch (error) {
    yield put(documentsUploadError({ error }));
  }
}

/**
 * Form reset
 */
function* resetFormWorker() {
  try {
    const response = yield call(resetFormService);
    yield put(resetFormSuccess({ response }));
    redirect(ROUTES.EMPLOYEE_REGISTER_FORM);
  } catch (error) {
    yield put(resetFormError({ error }));
  }
}

export function* employeeRegisterMainSaga() {
  yield takeLatest(FORM_INFOS_REQUEST, requestTabInfos);
  yield takeLatest(CIVIL_DATA_UPLOAD_REQUEST, uploadCivilDataInfos);
  yield takeLatest(SALARY_SITUATION_UPLOAD_REQUEST, uploadSalarySituationInfos);
  yield takeLatest(WORK_CONTRACT_UPLOAD_REQUEST, uploadWorkContactInfos);
  yield takeLatest(
    SPECIAL_SITUATIONS_UPLOAD_REQUEST,
    uploadSpecialSituationsInfos
  );
  yield takeLatest(DOCUMENTS_UPLOAD_REQUEST, uploadDocuments);
  yield takeLatest(RESET_FORM_REQUEST, resetFormWorker);
}
