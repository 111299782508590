import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as HelpIcon } from 'assets/icons/Sidebar/help.svg';
import { ReactComponent as HomePageIcon } from 'assets/icons/Sidebar/homePage.svg';
import { ReactComponent as MesDocumentsIcon } from 'assets/icons/Sidebar/mesDocuments.svg';
import { ReactComponent as WaibiIcon } from 'assets/icons/Sidebar/waibi.svg';
import { ReactComponent as MesVentesIcon } from 'assets/icons/Sidebar/mesVentes.svg';
import { ReactComponent as MesDepensesIcon } from 'assets/icons/Sidebar/mesDepenses.svg';
import { ReactComponent as MesSalariesIcon } from 'assets/icons/Sidebar/mesSalaries.svg';
import { ReactComponent as CartIcon } from 'assets/icons/Sidebar/cart.svg';
import { selectIsWaibiLoading } from 'modules/dashboardIndicators/selectors';
import Styles from './SidebarNavLinks.module.css';
import { SidebarIconWithLoader } from './SidebarIconWithLoader';

const propTypes = {
  closeSidebar: PropTypes.func,
  handleCloseAndRedirect: PropTypes.func,
  links: PropTypes.array,
  authorisedModules: PropTypes.array,
  currentRoute: PropTypes.string,
  currentCompany: PropTypes.object,
  handleCustomActions: PropTypes.func,
  isSidebarFullWidth: PropTypes.bool,
  isMainUser: PropTypes.bool,
};

const SIDEBAR_ICONS = {
  help: <HelpIcon className={Styles.sidebarIcon} />,
  homePage: <HomePageIcon className={Styles.sidebarIcon} />,
  mesDocuments: <MesDocumentsIcon className={Styles.sidebarIcon} />,
  waibi: (
    <SidebarIconWithLoader
      icon={WaibiIcon}
      selector={selectIsWaibiLoading}
      className={Styles.sidebarIcon}
    />
  ),
  mesVentes: <MesVentesIcon className={Styles.sidebarIcon} />,
  mesDepenses: <MesDepensesIcon className={Styles.sidebarIcon} />,
  mesSalaries: <MesSalariesIcon className={Styles.sidebarIcon} />,
  cart: <CartIcon className={Styles.sidebarIcon} />,
};

export const SidebarNavLinks = ({
  closeSidebar,
  handleCloseAndRedirect,
  links = [],
  authorisedModules,
  currentRoute,
  currentCompany = null,
  handleCustomActions,
  isSidebarFullWidth = true,
  isMainUser,
}) => (
  <ul className={clsx(Styles.wrapper, Styles.list)}>
    {links.map(
      ({
        id,
        customActionType,
        conditional,
        verifyAccess,
        verifyHidden,
        verifyCurrent,
        childrenLinks,
        label,
        route,
        routesForMultiLink,
        isPayRelated = false,
        iconId,
      }) => {
        const linkIcon = iconId && SIDEBAR_ICONS[iconId];

        const isCurrent = verifyCurrent
          ? verifyCurrent(currentRoute)
          : currentRoute === route;
        const shouldShow =
          !verifyHidden ||
          !verifyHidden(authorisedModules, currentCompany, isMainUser);

        if (!shouldShow) {
          return null;
        }

        const isPayEnabledForCompany = currentCompany?.payEnabled || false;
        if (isPayRelated && !isPayEnabledForCompany) {
          return null;
        }

        if (customActionType) {
          return (
            <li key={id} className={Styles.listItem}>
              <button
                className={Styles.clickWrapper}
                type="button"
                data-action-type={customActionType}
                onClick={handleCustomActions}
              >
                {linkIcon}
                {isSidebarFullWidth && <span>{label}</span>}
              </button>
            </li>
          );
        }

        if (conditional && verifyAccess(authorisedModules)) {
          return (
            <li
              key={id}
              className={clsx(Styles.listItem, {
                [Styles.selected]: isCurrent,
              })}
            >
              <button
                type="button"
                className={Styles.clickWrapper}
                onClick={handleCloseAndRedirect}
              >
                {linkIcon}
                {isSidebarFullWidth && <span>{label}</span>}
              </button>
            </li>
          );
        }

        if (childrenLinks && childrenLinks.length) {
          const isChildrenLinkActive = routesForMultiLink.includes(
            `/${currentRoute.split('/')[1]}`
          );

          return (
            !verifyHidden(authorisedModules, currentCompany) && (
              <li
                key={id}
                className={clsx({
                  [Styles.multiLinkActive]: isChildrenLinkActive,
                })}
              >
                <div className={Styles.listItem}>
                  {linkIcon}
                  {isSidebarFullWidth && (
                    <span className={Styles.parent}>{label}</span>
                  )}
                </div>
                {isSidebarFullWidth && (
                  <SidebarNavLinks
                    links={childrenLinks}
                    closeSidebar={closeSidebar}
                    authorisedModules={authorisedModules}
                    handleCloseAndRedirect={handleCloseAndRedirect}
                    currentRoute={currentRoute}
                    isSidebarFullWidth={isSidebarFullWidth}
                  />
                )}
              </li>
            )
          );
        }

        return (
          <li
            key={id}
            className={clsx(Styles.listItem, {
              [Styles.selected]: isCurrent,
            })}
          >
            <Link to={route} onClick={closeSidebar}>
              <div className={Styles.clickWrapper}>
                {linkIcon}
                {isSidebarFullWidth && <span>{label}</span>}
              </div>
            </Link>
          </li>
        );
      }
    )}
  </ul>
);

SidebarNavLinks.propTypes = propTypes;
