import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import {
  selectPackagesInCart,
  selectPackagesInCartWithCost,
  selectShouldCartBeReseted,
  selectSubscriptionStatus,
} from 'modules/cart/selectors';
import bin from 'assets/icons/Subscription/bucket.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCart,
  removePackageFromCart,
  resetCartAndAdditionalUsers,
  setSubscriptionStatus,
  subscribeToPackagesRequest,
} from 'modules/cart/actions';
import { GreenCheckbox } from 'components/ui/GreenCheckbox/GreenCheckbox';
import { AdditionalUsersTable } from 'components/AdditionalUsersTable/AdditionalUsersTable';
import {
  selectUserAdditionalUsers,
  selectUserPackagesNames,
  selectUserAuthorizedModules,
  selectUserEmail,
} from 'modules/loggedUserInfo/selectors';
import { Button } from 'components/ui/Button';
import { AdditionalUserModal } from 'components/AdditionalUserModal/AdditionalUserModal';
import { useAdditionalUsersTable } from 'hooks/useAdditionalUsersTable';
import {
  CART_PAGE_DATASET_KEY,
  CART_PAGE_DELETED_USERS_KEY,
  INITIAL_DATASET_LOCAL_STORAGE_KEY,
  USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
} from 'const/cartPage';
import {
  ALL_MODULES_OF_EACH_PACKAGE,
  getModulesForPurchase,
  PACKAGES_DEPENDENCIES,
  PACKAGES_NAMES,
  PACKAGES_NAMES_TRANSLATIONS,
} from 'const/packages';
import { Tooltip } from 'antd';
import { CART_PAGE_TRANSLATIONS } from 'const/translations/CartPage';
import { history } from 'routing/history';
import {
  ROUTES,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  APP_MODULES,
} from 'const';
import searchIcon from 'assets/icons/CartPage/search-icon.svg';
import { NavLink } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import {
  getUniqueSimpleArray,
  isSameArray,
  validateAdditionalUsers,
} from 'modules/cart/helpers';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';
import { openModal } from 'modules/ModalContainer/actions';
import { MODAL_TYPES } from 'const/ModalContainer';
import { FACT_MEETING_FORM_URL } from 'config';
import Styles from './CartPage.module.css';

const initialUserModalValues = {
  name: '',
  surname: '',
  email: '',
  position: '',
  tableModules: [],
  isUserNew: true,
};

const {
  ADD_USER_BUTTON,
  CART_BLOCK_TITLE,
  CHECKBOX_LABEL,
  EURO_PER_MONTH,
  PAGE_TITLE,
  SUBSCRIBE_BUTTON,
  SUBSCRIPTION_INFO,
  TABLE_TITLE,
  TOOLTIP,
  TOTAL,
  EMPTY_BLOCK_BUTTON,
  EMPTY_BLOCK_TEXT,
  FACT_TRAINING_PROMO_1,
  FACT_TRAINING_PROMO_2,
  SUCCESS_BLOCK_BUTTON,
  SUCCESS_BLOCK_NO_PAY,
  SUCCESS_BLOCK_PAY_SINGLE,
  SUCCESS_BLOCK_PAY_PART_OF_SET,
  SUCCESS_BLOCK_TITLE,
  COST_TO_BE_DEFINED,
} = CART_PAGE_TRANSLATIONS;

export const CartPage = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(selectUserEmail);

  const subscriptionStatus = useSelector(selectSubscriptionStatus);

  const addedPackagesToCart = useSelector(selectPackagesInCart);
  const addedPackagesToCartWithCost = useSelector(selectPackagesInCartWithCost);
  const activePackages = useSelector(selectUserAuthorizedModules);
  const isEvp = Boolean(activePackages.find((p) => p === APP_MODULES.EVP));

  const totalCartAmount = useMemo(
    () =>
      addedPackagesToCartWithCost.reduce((acc, { name, cost }) => {
        if (cost === 'N/A' || acc === 'N/A') {
          return 'N/A';
        }

        if (name === PACKAGES_NAMES.WAIBI) {
          return acc;
        }

        return acc + Number(cost);
      }, 0),
    [addedPackagesToCartWithCost]
  );

  const userCurrentPackages = useSelector(selectUserPackagesNames);
  const currentUserPackagesWithCartPackages = useMemo(
    () => [...new Set([...addedPackagesToCart, ...userCurrentPackages])],
    [addedPackagesToCart, userCurrentPackages]
  );

  const additionalUsersPossibleModules = useMemo(
    () =>
      currentUserPackagesWithCartPackages.reduce(
        (acc, pack) => {
          const packageModules = getModulesForPurchase()[pack] || [];
          return getUniqueSimpleArray([...acc, ...packageModules]);
        },
        isEvp
          ? [APP_MODULES.EVP, ...ALL_MODULES_OF_EACH_PACKAGE.FREE_MODULES]
          : ALL_MODULES_OF_EACH_PACKAGE.FREE_MODULES
      ),
    [currentUserPackagesWithCartPackages, isEvp]
  );

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const toggleCheckboxChecked = useCallback(() => {
    setIsCheckboxChecked((prev) => !prev);
  }, [setIsCheckboxChecked]);

  const additionalUsers = useSelector((state) =>
    selectUserAdditionalUsers(state, additionalUsersPossibleModules)
  );

  const {
    initialAdditionalUsersDataset,
    setUnsynchronizedUserDataset,
    unsynchronizedUserDataset,
    deletedUsers,
    resetChanges,
    removeUserFromDataset,
    addNewUserToTable,
  } = useAdditionalUsersTable(
    additionalUsers,
    CART_PAGE_DATASET_KEY,
    CART_PAGE_DELETED_USERS_KEY
  );

  const [
    isResetChangesButtonVisible,
    setResetChangesButtonVisibility,
  ] = useState(false);
  const [subscribedPackages, setSubscribedPackages] = useState([]);

  useEffect(() => {
    if (isCheckboxChecked) {
      dispatch(
        openModal({
          type: MODAL_TYPES.iframeModal,
          modalProps: {
            src: FACT_MEETING_FORM_URL,
            title: 'Schedule meeting',
          },
        })
      );
    }
  }, [dispatch, isCheckboxChecked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (subscriptionStatus === STATUS_SUCCESS) {
      window.scrollTo(0, 0);
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    setResetChangesButtonVisibility(
      Object.values(unsynchronizedUserDataset).some(
        ({ touched, isUserNew }) => !!touched || isUserNew
      ) || deletedUsers.length > 0
    );
  }, [
    unsynchronizedUserDataset,
    setResetChangesButtonVisibility,
    deletedUsers,
  ]);

  const removeDeletedPackageFromTableForm = useCallback(
    (deletedPackageName) => {
      const localStorageInfo = getFromStorage({ key: CART_PAGE_DATASET_KEY });
      if (!localStorageInfo) {
        return;
      }

      const modulesToRemove =
        ALL_MODULES_OF_EACH_PACKAGE[deletedPackageName] || [];

      const currentFormState = JSON.parse(localStorageInfo);
      const newFormState = Object.entries(currentFormState).reduce(
        (acc, [userId, values]) => {
          const filteredModules =
            values?.tableModules.filter(
              (pack) => !modulesToRemove.includes(pack)
            ) || [];
          acc[userId] = {
            ...values,
            tableModules: filteredModules,
          };
          return acc;
        },
        {}
      );
      setUnsynchronizedUserDataset(newFormState);
    },
    [setUnsynchronizedUserDataset]
  );

  const removePackage = useCallback(
    (e) => {
      const { packageName } = e.currentTarget.dataset;
      dispatch(removePackageFromCart({ packageName }));
      removeDeletedPackageFromTableForm(packageName);
    },
    [dispatch, removeDeletedPackageFromTableForm]
  );

  /*
   * useEffect checks whether incoming from backend userPackages or additional users has changed
   *                since last request and if it has, then cart and additional users localstorage and current data resets
   * */
  useEffect(() => {
    let shouldCartBeReseted = false;
    const savedInitialDataset = getFromStorage({
      key: INITIAL_DATASET_LOCAL_STORAGE_KEY,
    });
    if (
      savedInitialDataset &&
      !validateAdditionalUsers(JSON.parse(savedInitialDataset), additionalUsers)
    ) {
      shouldCartBeReseted = true;
    }
    const savedUserPackages = getFromStorage({
      key: USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
    });
    if (
      savedUserPackages &&
      !isSameArray(userCurrentPackages, JSON.parse(savedUserPackages))
    ) {
      shouldCartBeReseted = true;
    }
    setToStorage({
      key: INITIAL_DATASET_LOCAL_STORAGE_KEY,
      value: additionalUsers,
    });
    setToStorage({
      key: USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
      value: userCurrentPackages,
    });
    if (shouldCartBeReseted) {
      dispatch(resetCartAndAdditionalUsers({ value: true }));
    }
  }, [additionalUsers, userCurrentPackages, dispatch]);

  const [initialModalFormValues, setInitialModalFormValues] = useState(
    initialUserModalValues
  );
  const resetInitialModalFormValues = useCallback(() => {
    setInitialModalFormValues(initialUserModalValues);
  }, [setInitialModalFormValues]);

  const [isModalVisible, setModalVisibility] = useState(false);

  const toggleModalVisibility = useCallback(() => {
    setModalVisibility((prev) => !prev);
  }, [setModalVisibility]);

  const initiateCreateAdditionalUserModal = useCallback(() => {
    toggleModalVisibility();
  }, [toggleModalVisibility]);

  const onCreateUserSubmit = useCallback(
    (values) => {
      addNewUserToTable(values);
      toggleModalVisibility();
    },
    [toggleModalVisibility, addNewUserToTable]
  );

  const onSubscribeSubmit = useCallback(
    (values) => {
      setSubscribedPackages(addedPackagesToCart);
      dispatch(
        subscribeToPackagesRequest({
          currentUsers: values,
          deletedUsers,
          cartPackages: addedPackagesToCart,
        })
      );
    },
    [deletedUsers, dispatch, addedPackagesToCart]
  );

  const shouldCartBeReseted = useSelector(selectShouldCartBeReseted);

  useEffect(() => {
    if (shouldCartBeReseted) {
      dispatch(clearCart());
      resetChanges();
      dispatch(resetCartAndAdditionalUsers({ value: false }));
      removeFromStorage({
        key: [
          ADDITIONAL_USERS_LOCAL_STORAGE_KEYS.DATASET_KEY,
          ADDITIONAL_USERS_LOCAL_STORAGE_KEYS.DELETED_USERS_KEY,
        ],
      });
    }
  }, [shouldCartBeReseted, dispatch, resetChanges]);

  const hasSubscriptionEventSucceeded = useMemo(
    () => subscriptionStatus === STATUS_SUCCESS,
    [subscriptionStatus]
  );

  const isCartEmpty = useMemo(() => addedPackagesToCart.length === 0, [
    addedPackagesToCart,
  ]);

  const subscribeSuccessMessage = useMemo(() => {
    let successBlock;
    if (!subscribedPackages.includes(PACKAGES_NAMES.PAY)) {
      successBlock = SUCCESS_BLOCK_NO_PAY;
    } else {
      successBlock =
        subscribedPackages.length === 1
          ? SUCCESS_BLOCK_PAY_SINGLE
          : SUCCESS_BLOCK_PAY_PART_OF_SET;
    }
    return successBlock.map((line) => (
      <span className={Styles.successBlockLine} key={line}>
        {line}
      </span>
    ));
  }, [subscribedPackages]);

  useEffect(() => {
    if (hasSubscriptionEventSucceeded || isCartEmpty) {
      resetChanges();
      dispatch(clearCart());
    }
  }, [hasSubscriptionEventSucceeded, resetChanges, dispatch, isCartEmpty]);

  const onSuccessButtonClick = useCallback(() => {
    dispatch(setSubscriptionStatus({ value: STATUS_NOT_REQUESTED }));
    history.push(ROUTES.ROOT);
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(setSubscriptionStatus({ value: STATUS_NOT_REQUESTED }));
    },
    [dispatch]
  );

  const maxUsersCountReached = useMemo(
    () => Object.keys(unsynchronizedUserDataset)?.length > 4,
    [unsynchronizedUserDataset]
  );

  if (shouldCartBeReseted) {
    return <Spinner />;
  }

  return (
    <div className={Styles.background}>
      <div className={clsx(MainStyles.container)}>
        <SectionTitle title={PAGE_TITLE} />
        <div className={Styles.contentWrapper}>
          {hasSubscriptionEventSucceeded && (
            <div className={Styles.successBlock}>
              <span className={Styles.successBlockTitle}>
                {SUCCESS_BLOCK_TITLE}
              </span>
              <div className={Styles.successBlockInfo}>
                {subscribeSuccessMessage}
              </div>
              <Button variant="success" wide onClick={onSuccessButtonClick}>
                {SUCCESS_BLOCK_BUTTON}
              </Button>
            </div>
          )}
          {isCartEmpty && !hasSubscriptionEventSucceeded && (
            <div className={Styles.emptyCartWrapper}>
              <img
                src={searchIcon}
                alt="search icon"
                className={Styles.searchIconImg}
              />
              <span className={Styles.emptyCartText}>{EMPTY_BLOCK_TEXT}</span>
              <NavLink to={ROUTES.STORE}>
                <Button variant="success" wide>
                  {EMPTY_BLOCK_BUTTON}
                </Button>
              </NavLink>
            </div>
          )}
          {!isCartEmpty && !hasSubscriptionEventSucceeded && (
            <>
              <div className={Styles.cartItemsWrapper}>
                <span className={Styles.cartItemsTitle}>
                  {CART_BLOCK_TITLE}
                </span>
                <div className={Styles.itemsWrapper}>
                  {addedPackagesToCartWithCost.map(({ name, cost, id }) => {
                    const packageDependencies =
                      PACKAGES_DEPENDENCIES[name] || [];
                    const cantBeDeleted =
                      !!PACKAGES_DEPENDENCIES[name] &&
                      addedPackagesToCartWithCost.some(({ name: packName }) =>
                        packageDependencies.includes(packName)
                      );

                    return (
                      <div key={id} className={Styles.cartItem}>
                        <span className={Styles.cartItemName}>
                          {PACKAGES_NAMES_TRANSLATIONS[name] || 'Unknown'}
                        </span>
                        {name === PACKAGES_NAMES.WAIBI ? (
                          <span className={Styles.costSubtitle}>
                            {COST_TO_BE_DEFINED}
                          </span>
                        ) : (
                          <span className={Styles.cartItemCost}>
                            {cost} {EURO_PER_MONTH}
                          </span>
                        )}
                        <div className={Styles.cartItemDeleteIcon}>
                          {cantBeDeleted ? (
                            <Tooltip
                              placement="topLeft"
                              arrowPointAtCenter
                              title={TOOLTIP}
                              overlayClassName={Styles.toolTip}
                            >
                              <div
                                className={Styles.bucketImgWrapper}
                                data-package-name={name}
                              >
                                <img
                                  src={bin}
                                  alt="bucket delete icon"
                                  className={clsx(
                                    Styles.bucketImg,
                                    Styles.bucketDisabled
                                  )}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={Styles.bucketImgWrapper}
                              data-package-name={name}
                              onClick={removePackage}
                            >
                              <img
                                src={bin}
                                alt="bucket delete icon"
                                className={Styles.bucketImg}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className={Styles.totalRow}>
                    <span className={Styles.totalTitle}>{TOTAL}</span>
                    {addedPackagesToCartWithCost.length === 1 &&
                    addedPackagesToCartWithCost[0].name ===
                      PACKAGES_NAMES.WAIBI ? (
                      <span className={Styles.totalCost}>
                        {COST_TO_BE_DEFINED}
                      </span>
                    ) : (
                      <span className={Styles.totalCost}>
                        {totalCartAmount} {EURO_PER_MONTH}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.subscriptionInfo}>{SUBSCRIPTION_INFO}</div>
              {addedPackagesToCart.includes(PACKAGES_NAMES.FACT) && (
                <>
                  <div className={Styles.checkboxWrapper}>
                    <GreenCheckbox
                      toggleChecked={toggleCheckboxChecked}
                      checked={isCheckboxChecked}
                      inputId="CART_FACT_CHECKBOX"
                      label={CHECKBOX_LABEL}
                    />
                  </div>
                  <div className={Styles.subscriptionInfo}>
                    {FACT_TRAINING_PROMO_1}
                  </div>
                  <div className={Styles.subscriptionInfo}>
                    {FACT_TRAINING_PROMO_2}
                  </div>
                </>
              )}
              <div className={Styles.tableWrapper}>
                <div className={Styles.tableHeader}>
                  <span className={Styles.tableTitle}>{TABLE_TITLE}</span>
                  <Button
                    variant="success"
                    onClick={initiateCreateAdditionalUserModal}
                    disabled={maxUsersCountReached}
                  >
                    {ADD_USER_BUTTON}
                  </Button>
                </div>
                <AdditionalUsersTable
                  unsynchronizedUserDataset={unsynchronizedUserDataset}
                  removeUserFromDataset={removeUserFromDataset}
                  onSaveChanges={onSubscribeSubmit}
                  initialDataset={initialAdditionalUsersDataset}
                  resetStates={resetChanges}
                  datasetLocalStorageKey={CART_PAGE_DATASET_KEY}
                  isResetButtonVisible={isResetChangesButtonVisible}
                  setResetButtonVisibility={setResetChangesButtonVisibility}
                  isPositionEditable={false}
                  userPossibleModules={additionalUsersPossibleModules}
                  confirmButtonText={SUBSCRIBE_BUTTON}
                  className={Styles.cartTableWrapper}
                  submitButtonVariant="primary"
                  currentUserEmail={currentUserEmail}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <AdditionalUserModal
        initialFormValues={initialModalFormValues}
        resetInitialFormValues={resetInitialModalFormValues}
        isVisible={isModalVisible}
        closeModal={toggleModalVisibility}
        onCreateUserSubmit={onCreateUserSubmit}
        userPossibleModules={additionalUsersPossibleModules}
      />
    </div>
  );
};
