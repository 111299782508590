import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import Styles from './ViewSwitch.module.css';

const { LINE, BAR } = CHARTS_VIEW_SWITCH_TYPES;

const propTypes = {
  activeOption: PropTypes.oneOf([LINE, BAR]),
  setActiveOption: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const { VIEW } = HOMEPAGE_FORM_TRANSLATIONS.CHARTS_VIEW_SWITCH;

export const ViewSwitch = ({ activeOption, setActiveOption, className }) => {
  const toggleChartsView = useCallback(
    (e) => {
      const target = e.currentTarget;
      setActiveOption((prev) => {
        if (target.dataset.option !== LINE && prev === LINE) {
          return BAR;
        }
        if (target.dataset.option !== BAR && prev === BAR) {
          return LINE;
        }
        return prev;
      });
    },
    [setActiveOption]
  );

  return (
    <div className={clsx(Styles.wrapper, className)}>
      <div className={Styles.viewText}>{VIEW} :</div>
      <div
        className={clsx(Styles.chartIcon, Styles.lineChartsIcon, {
          [Styles.active]: activeOption === LINE,
        })}
        data-option={LINE}
        onClick={toggleChartsView}
      >
        <svg
          width="23"
          height="10"
          viewBox="0 0 23 10"
          fill="none"
          className={Styles.lineChartsBrokenLine}
        >
          <path d="M1.5 9L9.5 3.5L13.5 6.5L21.5 1" strokeWidth="2" />
        </svg>
        <div className={Styles.lineChartsBottomLine} />
      </div>
      <div
        className={clsx(Styles.chartIcon, Styles.barChartsIcon, {
          [Styles.active]: activeOption === BAR,
        })}
        data-option={BAR}
        onClick={toggleChartsView}
      >
        <div className={Styles.barChartsMiddleLine} />
      </div>
    </div>
  );
};

ViewSwitch.propTypes = propTypes;
