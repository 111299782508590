import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AUTH_TOKEN_KEY,
  WAIBI_ACCESS_TOKEN,
  WAIBI_REFRESH_TOKEN,
} from 'config';
import { redirect } from 'helpers/common';
import { ROUTES } from 'const';
import { TermsOfUse } from 'containers/TermsOfUse';
import { removeFromStorage } from 'helpers/storage';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';
const { DATASET_KEY, DELETED_USERS_KEY } = ADDITIONAL_USERS_LOCAL_STORAGE_KEYS;

export const TermsOfUseRoutes = () => (
  <Switch>
    <Route exact path={ROUTES.ROOT} render={() => <TermsOfUse />} />
    <Route
      exact
      path={ROUTES.LOGOUT}
      render={() => {
        removeFromStorage({
          key: [
            AUTH_TOKEN_KEY,
            WAIBI_ACCESS_TOKEN,
            WAIBI_REFRESH_TOKEN,
            DATASET_KEY,
            DELETED_USERS_KEY,
          ],
        });
        return redirect(ROUTES.ROOT);
      }}
    />
    <Redirect to={ROUTES.ROOT} />
  </Switch>
);
