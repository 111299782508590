import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import Styles from './InputNumber.module.css';

const propTypes = {
  className: PropTypes.string,
};

// TODO check requirements min max
const InputNumberMain = ({ className, ...props }) => (
  <div className={Styles.inputNumberWrapper}>
    <InputNumber type="number" min={0} {...props} className={className} />
    <span className={Styles.iconWrapper}>&#8364;</span>
  </div>
);

InputNumberMain.propTypes = {
  onChange: PropTypes.func.isRequired,
};

InputNumberMain.propTypes = propTypes;

export { InputNumberMain as InputNumber };
