import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { APP_MODULES } from 'const/index';
import {
  selectIsMainUser,
  selectUserAuthorizedModules,
} from 'modules/loggedUserInfo/selectors';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import { FoldersMenuItem } from 'components/DocumentsCabinet/FoldersMenu/FoldersMenuItem';
import Styles from './FoldersMenu.module.css';

const { Sider } = Layout;
const { MENU_TITLE, MENU_ITEMS } = DOCUMENTS_CABINET_TRANSLATIONS;

const propTypes = {
  selected: PropTypes.string,
};

export const FoldersMenu = ({ selected = null }) => {
  const isMainUser = useSelector(selectIsMainUser);
  const activePackages = useSelector(selectUserAuthorizedModules);
  const isAccessSicialDir = Boolean(
    activePackages.find((p) => p === APP_MODULES.ACCESS_SOCIAL_DIR)
  );

  return (
    <Sider width={256} className={Styles.aside}>
      <div className={Styles.asideHeader}>{MENU_TITLE}</div>
      <Menu mode="inline" className={Styles.asideMenu} selectedKeys={selected}>
        <FoldersMenuItem
          isSelected={selected === MENU_ITEMS.COMPATIBILITY.value}
          key={MENU_ITEMS.COMPATIBILITY.value}
          name={MENU_ITEMS.COMPATIBILITY.value}
          className={Styles.menuItem}
        >
          <span>{MENU_ITEMS.COMPATIBILITY.label}</span>
        </FoldersMenuItem>
        <FoldersMenuItem
          isSelected={selected === MENU_ITEMS.ADVICE.value}
          key={MENU_ITEMS.ADVICE.value}
          name={MENU_ITEMS.ADVICE.value}
          className={Styles.menuItem}
        >
          <span>{MENU_ITEMS.ADVICE.label}</span>
        </FoldersMenuItem>
        <FoldersMenuItem
          isSelected={selected === MENU_ITEMS.DEPOSIT.value}
          key={MENU_ITEMS.DEPOSIT.value}
          name={MENU_ITEMS.DEPOSIT.value}
          className={Styles.menuItem}
        >
          <span>{MENU_ITEMS.DEPOSIT.label}</span>
        </FoldersMenuItem>
        <FoldersMenuItem
          isSelected={selected === MENU_ITEMS.TAX.value}
          key={MENU_ITEMS.TAX.value}
          name={MENU_ITEMS.TAX.value}
          className={Styles.menuItem}
        >
          <span>{MENU_ITEMS.TAX.label}</span>
        </FoldersMenuItem>
        <FoldersMenuItem
          isSelected={selected === MENU_ITEMS.LEGAL.value}
          key={MENU_ITEMS.LEGAL.value}
          name={MENU_ITEMS.LEGAL.value}
          className={Styles.menuItem}
        >
          <span>{MENU_ITEMS.LEGAL.label}</span>
        </FoldersMenuItem>
        {(isMainUser || isAccessSicialDir) && (
          <FoldersMenuItem
            isSelected={selected === MENU_ITEMS.SOCIAL.value}
            key={MENU_ITEMS.SOCIAL.value}
            name={MENU_ITEMS.SOCIAL.value}
            className={Styles.menuItem}
          >
            <span>{MENU_ITEMS.SOCIAL.label}</span>
          </FoldersMenuItem>
        )}
      </Menu>
    </Sider>
  );
};

FoldersMenu.propTypes = propTypes;
