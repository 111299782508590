import React from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Styles from './Section.module.css';
import { SectionTitle } from './SectionTitle';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  titleTextColor: PropTypes.oneOf(['default', 'white']),
};

export const Section = ({ children, titleTextColor = 'default', title }) => (
  <div className={clsx(MainStyles.container, Styles.sectionWrapper)}>
    <SectionTitle titleTextColor={titleTextColor} title={title} />
    {children}
  </div>
);

Section.propTypes = propTypes;
