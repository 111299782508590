export const TERMS_OF_USE_TEXT =
  '\titleDate de dernière mise à jour : Décembre 2023\title\n' +
  '\n' +
  '\titleArticle 1 – Champ d’application\title\n' +
  '\n' +
  '\bold1.1.\bold Les présentes Conditions générales définissent les règles et conditions d’utilisation de l’Espace My Exco et sont conclues entre l’\boldUtilisateur \boldet la société \boldEXCO FIDUCIAIRE DU SUD OUEST, \boldSociété par Actions Simplifiée, au capital de 1 934 760,00 €, immatriculée sous le numéro 540 800 406 au RCS de Toulouse qui a pour activité la fourniture de services d’activités comptables, sociales, juridiques, audit et de conseil (ci-après « EXCO »).\n' +
  '\n' +
  '\boldEXCO\bold et l’\boldUtilisateur\bold sont ci-après individuellement dénommés une \bold« Partie »\bold et ensemble les \bold« Parties »\bold.\n' +
  '\n' +
  '\bold1.2.\bold L’Espace My Exco a été développé par \boldEXCO\bold au profit de ses clients ainsi que des clients des autres sociétés du \boldGroupe Exco\bold pour lesquels ces dernières effectuent les activités susmentionnées.\n' +
  '\n' +
  'L’accès à l’Espace My Exco est autorisé au Client dans le cadre de la Mission réalisée par EXCO ou la société du Groupe Exco concernée.\n' +
  '\n' +
  '\bold1.3. \boldToute utilisation de l’Espace My Exco implique de plein droit l’acceptation sans réserve de l’intégralité des présentes Conditions générales. L’acceptation par l’\boldUtilisateur\bold des présentes Conditions générales est matérialisée par le fait pour l’\boldUtilisateur\bold de cocher la case attenante à la mention « J’accepte les conditions générales d’abonnement et les conditions générales d’utilisation » ou « J’accepte » les conditions générales d’utilisation) lors de sa première connexion et chaque fois que les Conditions générales seront mises à jour conformément à l’article 7.1 ci-après. Cette démarche équivaut pour l’\boldUtilisateur\bold à reconnaître qu’il a pris pleinement connaissance et qu’il approuve, sans exception ni réserve, l’ensemble des Conditions générales indiquées ci-après. A défaut d’acceptation des présentes Conditions générales, l’\boldUtilisateur\bold ne pourra accéder à l’Espace My Exco et ainsi bénéficier des services afférents.\n' +
  '\n' +
  '\titleArticle 2 – Définitions\title\n' +
  '\n' +
  '\bold« Client »\bold\bold \bolddésigne toute personne physique ou morale, publique ou privée, capable juridiquement de contracter, signataire d’un Contrat donnant accès à l’Espace My Exco et agissant à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, y compris lorsqu’elle agit au nom et pour le compte d’un autre professionnel.\n' +
  '\n' +
  '\bold« Compte »\bold désigne le compte de l’Utilisateur permettant l’accès à l’Espace My Exco.\n' +
  '\n' +
  '\bold« Compte dirigeant »\bold désigne le compte administrateur disposant de la capacité d’accéder à l’Espace My Exco, de, potentiellement, souscrire des services complémentaires et de gérer les niveaux d’accès des Utilisateurs aux services souscrits et ce, sous l’entière responsabilité du Client, seul habilité à organiser les accès administrateurs, les délégations éventuelles y afférents et les niveaux d’information et de confidentialité requis.\n' +
  '\n' +
  '\bold« Conditions générales »\bold désigne les présentes conditions générales d’utilisation de l’Espace My Exco.\n' +
  '\n' +
  '\bold« Contrat »\bold désigne spécifiquement la souscription par le Client, au travers de la Mission ou d’une souscription en ligne, des Services permettant de bénéficier de l’accès à l’Espace My Exco. \n' +
  '\n' +
  '\bold« Données à caractère personnel »\bold désigne toute information se rapportant à une personne physique identifiée ou identifiable.\n' +
  '\n' +
  '\bold« Espace My Exco »\bold désigne l’outil développé par \boldEXCO \boldaux fins d’échange d’informations dans le cadre des missions de ce dernier portant sur la gestion comptable et/ou sociale conférée par le \boldClient\bold au \boldGroupe Exco\bold et de centralisation de services mis à disposition du Client en ce compris, sans que cela soit limitatif la consultation de ses indicateurs et le paiement de factures. \n' +
  '\n' +
  '\bold« Groupe Exco »\bold désigne toutes les sociétés contrôlées par EXCO, contrôlant EXCO ou se trouvant sous contrôle commun à ce jour et à venir, au sens de l’article L. 233-3 du code de commerce.\n' +
  '\n' +
  '\bold« Mission »\bold désigne les prestations d’activités comptables, sociales, juridiques, audit et/ou de conseils effectuées par EXCO ou une société du Groupe EXCO pour le Client dont les conditions et modalités sont décrites par une lettre de mission.\n' +
  '\n' +
  '\bold« Partenaires »\bold désigne les structures proposant des Services en marque blanche ou expressément identifiées au sein de l’Espace My Exco pouvant être souscrits par le Client et dont ce dernier sera le cas échéant amené à accepter les conditions contractuelles lesquelles s’appliqueront à la relation contractuelle entre le Partenaire et le Client. \n' +
  '\n' +
  '\bold« Services »\bold désigne le Bouquet My Exco et les Services complémentaires, à savoir l’ensemble des fonctionnalités proposées directement par EXCO et/ou ses Partenaires auxquelles le Client a souscrit par Contrat.\n' +
  '\n' +
  '\bold« Utilisateur »\bold désigne toute personne physique qui se connecte au Compte du \boldClient\bold pour accéder à l’Espace My Exco, en tant que préposé du \boldClient\bold dûment habilité à accomplir cette mission. L’\boldUtilisateur\bold reconnaît expressément agir dans le cadre de son exercice professionnel lorsqu’il accède à l’Espace My Exco.\n' +
  '\n' +
  '\titleArticle 3 – Accès à l’Espace My Exco\title\n' +
  '\n' +
  '\bold3\bold\bold.1.\bold Lors de la souscription des Services par le Client, celui-ci indiquera et ouvrira depuis le Compte dirigeant, les droits des Utilisateurs pour l’accès à l’Espace My Exco et le niveau de droits d’accès individuellement conférés par le Client pour chaque Utilisateur. EXCO génère automatiquement et transmet à l’Utilisateur un mot de passe temporaire, dont il n’a pas connaissance, rattaché à l’adresse courriel de l’\boldUtilisateur\bold habilité par le Client à se connecter sur l’Espace My Exco.  \n' +
  '\n' +
  '\bold3\bold\bold.2.\bold L’\boldUtilisateur\bold dispose de la faculté de modifier son mot de passe. Lors de la modification du mot de passe, l’\boldUtilisateur\bold sera contraint, pour procéder à une telle modification, de respecter les règles minimales de complexité en matière de cybersécurité, en ce compris sans que cela ne soit limitatif les recommandations de l’Agence nationale de la sécurité des systèmes d’information. \n' +
  '\n' +
  '\bold3\bold\bold.3.\bold Le mot de passe est personnel et confidentiel. Chaque \boldUtilisateur\bold est entièrement responsable de l’utilisation et de la préservation de son mot de passe. L’\boldUtilisateur\bold s’engage à mettre tout en œuvre pour conserver secret son mot de passe et à ne le divulguer à quiconque. Afin de préserver le caractère confidentiel du mot de passe, il est recommandé à l’\boldUtilisateur\bold de se déconnecter de l’Espace My Exco à la fin de chaque session de connexion.\n' +
  '\n' +
  '\bold3\bold\bold.\bold\bold4\bold\bold. \boldEn cas de perte ou divulgation involontaire d’élément susceptible de permettre à un tiers de prendre connaissance du mot de passe d’un \boldUtilisateur\bold, ce dernier s’engage à le signaler immédiatement par écrit à \boldEXCO\bold à l’adresse \blinksupport@fsodigital.fr\blink afin que soient changés les identifiant et mot de passe de l’\boldUtilisateur \boldconcerné.\n' +
  '\n' +
  '\bold3\bold\bold.\bold\bold5\bold\bold.\bold En cas d’oubli du mot de passe, l’\boldUtilisateur\bold devra l’indiquer via la rubrique « Mot de passe oublié ». Il recevra un email de réinitialisation de son mot de passe à l’adresse email utilisée à titre d’identifiant.\n' +
  '\n' +
  '\bold3\bold\bold.\bold\bold6\bold\bold.\bold A l’issue de cinq tentatives de connexion infructueuses par \boldl’Utilisateur\bold, son accès à l’Espace My Exco sera bloqué par \boldEXCO\bold. Il appartiendra ainsi à \boldl’Utilisateur\bold de solliciter la réinitialisation de son accès et la fourniture, par EXCO, d’un nouveau mot de passe via la rubrique « Mot de passe oublié ».\n' +
  '\n' +
  '\bold3\bold\bold.\bold\bold7\bold\bold. \boldL’accès à l’Espace My Exco permet à l’Utilisateur de naviguer sur différentes sections de l’Espace My Exco, selon les Services souscrits par le Client auprès d’\boldEXCO\bold depuis son Compte dirigeant, en ce compris sans que cela ne soit limitatif : \n' +
  '\n' +
  '\bold(i)\bold Enregistrer un salarié du Client et ainsi remplir les informations nécessaires pour la mission sociale, en ce compris le cas échéant la rédaction de contrats de travail, et de gestion de la paie souscrite par le Client, portant notamment sur les données d’identification du salarié, sa situation, son contrat de travail, et le cas échéant des situations particulières. Au sein de cette section, l’\boldUtilisateur\bold devra par ailleurs fournir les pièces justificatives afférentes aux informations renseignées ;\n' +
  '\n' +
  '\bold(ii)\bold Rechercher une facture d’achat ou de vente passée en comptabilité. Cette option pourra être effectuée par l’\boldUtilisateur\bold par une recherche plein texte sur l’ensemble du corps de la facture ou bien par critères ;\n' +
  '\n' +
  '\bold(i\bold\boldii\bold\bold) \boldConsulter les indicateurs, tableaux de bord, documents mis à disposition par \boldEXCO\bold et rangés par catégorie selon les prestations souscrites par le \boldClient\bold auprès d’\boldEXCO\bold, à savoir notamment Comptabilité, Conseil, Dépôt, Fiscal, Juridique et Social. L’\boldUtilisateur\bold dispose également d’une fonction de recherche lui permettant d’accéder à ses documents ; \n' +
  '\n' +
  '\bold(\bold\boldiv)\bold Déposer des documents destinés à \boldEXCO\bold pour la réalisation de ses missions ; \n' +
  '\n' +
  '\bold(v)\bold Gérer et procéder, le cas échéant, au paiement de ses factures par renvoi vers la plateforme d’un Partenaire.\n' +
  '\n' +
  '\bold(vi)\bold Consulter sa comptabilité en ligne par renvoi vers la plateforme d’un Partenaire.\n' +
  '\n' +
  'Lorsque les Services incluent le renvoi vers une plateforme Partenaire, il appartiendra à l’utilisateur d’accepter le cas échéant la documentation contractuelle mise en place par ce dernier.\n' +
  '\n' +
  '\titleArticle 4 – Déclarations, obligations et responsabilités de l’Utilisateur\title\n' +
  '\n' +
  '\bold4\bold\bold.1. \boldL’\boldUtilisateur\bold déclare disposer de la capacité juridique lui permettant de donner son accord aux présentes Conditions générales.\n' +
  '\n' +
  '\bold4\bold\bold.2. \boldL’\boldUtilisateur \boldreconnaît que l’Espace My Exco nécessite une connexion Internet pour fonctionner dans son ensemble. A ce titre, l’\boldUtilisateur\bold déclare bien connaître l’Internet, ses caractéristiques et ses limites et reconnaît notamment que les transmissions de données sur l’Internet ne bénéficient que d’une fiabilité technique relative, celles-ci circulant sur des réseaux hétérogènes aux caractéristiques et capacités techniques diverses qui sont parfois saturés à certaines périodes de la journée. \n' +
  '\n' +
  '\bold4\bold\bold.3\bold. \boldL’Utilisateur\bold reconnaît que l’accès à l’Espace My Exco lui est accordé en vertu du Contrat et/ou de la Mission. La fin du Contrat et/ou de la Mission pour quelque cause que ce soit (notamment par arrivée du terme ou résiliation) pourra entrainer la suppression des droits d’accès de l\bold’Utilisateur\bold sans que cela ne puisse engager la responsabilité de\bold EXCO\bold.\n' +
  '\n' +
  "\bold4\bold\bold.4. \boldPar ailleurs, l’\boldUtilisateur \bolds’engage à ne pas utiliser l’Espace My Exco d’une manière non prévue par les présentes Conditions générales ni d'une manière qui puisse la rendre inaccessible, l’endommager ou l’empêcher de fonctionner. De même, l’\boldUtilisateur \bolds’engage à ne pas utiliser des failles, bugs informatiques ou toute autre forme d'erreur pour obtenir des avantages dans l’utilisation de l’Espace My Exco. \n" +
  '\n' +
  '\bold4\bold\bold.5. \boldL’\boldUtilisateur\bold est responsable de l’exactitude, la sincérité, la pertinence, la conformité aux bonnes mœurs et la véracité des informations qu’il fournit à \boldEXCO\bold\bold \boldvia l’Espace My Exco. Il lui appartient de s’assurer et de justifier à première demande de ce qu’il dispose de la capacité juridique et des habilitations nécessaires au dépôt des documents et/ou à la saisie des informations dans l’Espace My Exco.  \boldEXCO\bold ne saurait être tenue responsable en raison d’une erreur dans la saisie de ces informations ou d’une utilisation inadaptée.\n' +
  '\n' +
  '\bold4\bold\bold.6.\bold Il appartient à \boldl’Utilisateur\bold d’assurer, par ses propres moyens, la conservation et la sauvegarde régulière des données transmises par l’Espace My Exco.\n' +
  '\n' +
  '\bold4\bold\bold.7\bold. L’\boldUtilisateur \bolds’engage à notifier sans délai \boldEXCO \bolddès qu’il aura connaissance d’une quelconque faille de sécurité, compromission de fichier, panne, erreur, dysfonctionnement ou violation de l’Espace My Exco, en utilisant l’adresse \blinkvosdonnees-fso@exco.fr.\blink \n' +
  '\n' +
  '\bold4\bold\bold.\bold\bold8\bold\bold. \boldL\bold’Utilisateur \bolds’engage à collaborer de manière loyale avec \boldEXCO\bold afin d’assurer le bon fonctionnement de l’Espace My Exco.\n' +
  '\n' +
  '\titleArticle 5 – Déclarations, obligations et responsabilités de EXCO\title\n' +
  '\n' +
  '\bold5\bold\bold.1. \boldCompte tenu des aléas techniques liés au fonctionnement décentralisé du réseau Internet, \boldEXCO\bold ne fournit aucune garantie de continuité de service ou d’absence d’erreurs de l’Espace My Exco.\n' +
  '\n' +
  '\bold5\bold\bold.2.\bold\bold \bold\boldEXCO\bold ne sera pas responsable si un quelconque dysfonctionnement (défaillance, panne, difficulté, interruption de fonctionnement, etc.) de l’Espace My Exco, indépendant de sa volonté, empêche notamment l’accès à l’Espace My Exco.\n' +
  '\n' +
  '\bold5\bold\bold.3. \bold\boldEXCO\bold se réserve le droit de suspendre l’accès à l’Espace My Exco en tout ou partie sans préavis notamment pour procéder à toute opération de correction, de mise à jour ou de maintenance. \boldEXCO\bold\bold \boldne peut en aucun cas être tenu responsable de tout préjudice et/ou perte qui en résulterait pour l’\boldUtilisateur\bold. \n' +
  '\n' +
  "\bold5\bold\bold.4. \bold\boldEXCO\bold ne sera pas responsable des dommages subis ou occasionnés par l’utilisation de l’Espace My Exco qu’il s’agisse de dommages matériels ou immatériels, consécutifs ou non- consécutifs. A ce titre, il appartient à l’\boldUtilisateur\bold de prendre toutes les mesures appropriées de nature à protéger ses propres données et logiciels de la contamination par d'éventuels virus informatiques.\n" +
  '\n' +
  '\bold5\bold\bold.5.\bold\bold \bold\boldEXCO\bold ne sera pas responsable des dommages causés aux \boldUtilisateurs\bold, à des tiers et/ou à leurs équipements du fait de la connexion ou de l’utilisation de l’Espace My Exco.\n' +
  '\n' +
  '\bold5\bold\bold.6. \bold\boldEXCO\bold ne sera pas responsable en cas de dommages liés à la perte ou à la divulgation du mot de passe d’un \boldUtilisateur\bold. \n' +
  '\n' +
  '\bold5\bold\bold.7.\bold \boldEXCO\bold est uniquement responsable du contenu produit par elle et intégré à l’Espace My Exco, et des fonctionnalités y relatives.\n' +
  '\n' +
  '\bold5\bold\bold.8\bold. EXCO se réserve le droit de procéder à la modification de l’organisation et du contenu des modules proposés sur l’Espace My Exco sans que cela puisse lui être reproché par l’\boldUtilisateur\bold.\n' +
  '\n' +
  '\bold5\bold\bold.9. EXCO\bold se réserve le droit de suspendre l’accès de l’Utilisateur à l’Espace My Exco dans l’hypothèse où ce dernier ne respecterait pas les obligations lui incombant en vertu de l’article 4 ci-dessus.\n' +
  '\n' +
  '\titleArticle 6 – Propriété intellectuelle sur l’Espace My Exco\title\n' +
  '\n' +
  '\bold6\bold\bold.1.\bold Tous les éventuels droits de propriété intellectuelle et autres droits liés à l’Espace My Exco, y compris les droits d’auteur, les marques, les dessins et modèles, les droits sur les bases de données, ainsi que tout autre droit de propriété intellectuelle ou autres, sont et restent la propriété exclusive de \boldEXCO\bold et, pour les titres et technologies sous licence, de leurs auteurs et/ou propriétaires.\n' +
  '\n' +
  '\bold6\bold\bold.2.\bold La société EXCO & AUTRES, 42 Avenue de la Grande Armée 75017 PARIS est titulaire des marques portant sur les termes « EXCO » et « MY EXCO » laquelle a accordé à EXCO, en sa qualité du membre du Réseau EXCO, des droits de propriété intellectuelle permettant l’exploitation de ladite marque.\n' +
  '\n' +
  'L’accès à l’Espace My Exco confère, à l’Utilisateur ou au Client, un droit d’utilisation personnel, non exclusif, non cessible et non transmissible de l’Espace My Exco. Il est dès lors formellement interdit de copier, utiliser, modifier ou exercer une quelconque autre action à l’égard de ces marques et du logo y associé.\n' +
  '\n' +
  '\bold6\bold\bold.3. \boldL’accès à l’Espace My Exco ne confère aucun droit de quelque sorte que ce soit à l’égard de tout contenu protégé par le droit d’auteur, en ce compris, notamment le formulaire d’enregistrement d’un salarié, plus globalement les documents transmis par \boldEXCO \boldainsi que toute mention légale relative à \boldEXCO\bold. Ces contenus sont la propriété exclusive de la société \boldEXCO\bold. L’\boldUtilisateur\bold n’est pas autorisé à diffuser ces contenus, ni à procéder à des copies, sur quelque support que ce soit. \n' +
  '\n' +
  'L’architecture de l’Espace My Exco, l’interface, la charte graphique, la base de données et son arborescence appartiennent à \boldEXC\bold\boldO \boldou aux autres sociétés du Groupe ou du Réseau Exco ayant accordé à EXCO des droits de propriété intellectuelle lui permettant d’exploiter les éléments concernés.\n' +
  '\n' +
  '\bold6\bold\bold.4.\bold L’\boldUtilisateur \boldn’est pas autorisé à intervenir de quelque sorte que ce soit sur l’Espace My Exco ou l’un quelconque de ses éléments en dehors des options spécifiquement prévues à cet effet sur l’interface.\n' +
  '\n' +
  '\bold6\bold\bold.5.\bold Il est rigoureusement interdit de copier, décompiler, modifier, exporter, capter, extraire ou reprendre par quelque moyen que ce soit tout ou partie de l’Espace My Exco. \n' +
  '\n' +
  '\bold6\bold\bold.6. \boldEn toute hypothèse et de la même façon, l’accès de l’Utilisateur à des plateformes déployées par des Partenaires ne confère, ni à l’Utilisateur, ni au Client, aucun droit de quelque sorte que ce soit à cet égard, sans qu’il puisse être considéré que EXCO se porte-fort du respect par l’Utilisateur et/ou le Client du respect des droits de propriété intellectuelle de ces plateformes partenaires. \n' +
  '\n' +
  '\titleArticle 7 – Données à caractère personnel\title\n' +
  '\n' +
  '\bold7\bold\bold.1. \bold\boldEXCO \boldaccorde une attention\bold \boldparticulière à la protection des Données à caractère personnel et à la sauvegarde des droits des personnes concernées. \boldEXCO\bold est ainsi tout particulièrement sensible à la réglementation applicable, et notamment au Règlement 2016/679 du 27 avril 2016 sur la protection des données personnelles (Le RGPD) et à la loi française n°78-17 du 6 janvier 1978 (dite « Loi Informatique et Libertés ») modifiée.\n' +
  '\n' +
  '\bold7\bold\bold.\bold\bold2\bold\bold.\bold L’\boldUtilisateur\bold s’engage à respecter la confidentialité et la sécurité des Données à caractère personnel de toute personne, auxquelles il pourra avoir accès dans le cadre de l’utilisation de l’Espace My Exco et des documents qu’il serait susceptible de communiquer.\n' +
  '\n' +
  'Il est formellement interdit à l’Utilisateur d’utiliser toute Donnée à caractère personnel rendue accessible depuis l’Espace My Exco à des fins autres que celles qui sont définies dans les présentes Conditions générales.\n' +
  '\n' +
  '\bold7\bold\bold.\bold\bold3\bold\bold.\bold Il est précisé que les dispositions de l’article 7.4 ci-après relatives aux Données à caractère personnel ont uniquement vocation à couvrir les traitements de Données à caractère personnel des \boldUtilisateurs\bold effectués par \boldEXCO \boldpour son compte.\n' +
  '\n' +
  'Les dispositions relatives à la protection des données à caractère personnel dans le cadre de la relation contractuelle entre le \boldClient \boldet \boldEXCO\bold sont régies par les conditions générales attachées à la mission conclue entre les \boldParties\bold et au respect de la Réglementation applicable.\n' +
  '\n' +
  '\bold7\bold\bold.\bold\bold4\bold\bold. \boldLes Données à caractère personnel de l’\boldUtilisateur\bold font l’objet d’un traitement informatisé par \boldEXCO\bold aux fins d’utilisation des services via le Portail My Exco par l’\boldUtilisateur\bold. Les Données à caractère personnel sont relatives à ses nom et prénom ainsi que les fichiers logs et l’adresse I.P de \boldl’Utilisateur\bold.  \n' +
  '\n' +
  'Ces traitements sont : \n' +
  '\bold(i)\bold D’une part, nécessaires aux fins d’exécution des présentes Conditions générales. Par conséquent, cette nécessité contractuelle forme la base légale de ce traitement, aux fins du RGPD. Ces données à caractère personnel sont nécessaires à l’accès et à l’utilisation des services de l’Espace My Exco, leur collecte et leur traitement sont ainsi une condition obligatoire pour permettre l’utilisation de l’Espace My Exco par l’\boldUtilisateur\bold ; \n' +
  '\bold(ii)\bold D’autre part, nécessaires aux fins des intérêts légitimes poursuivis par \boldEXCO\bold afin d’assurer la sécurité informatique de l’Espace My Exco pour le traçage des accès et la gestion des incidents relatifs aux connexions des \boldUtilisateurs\bold. \n' +
  '\n' +
  'Les données d’identification de \boldl’Utilisateur\bold sont conservées par \boldEXCO\bold jusqu’à la fin du contrat pour quelque cause que ce soit (notamment par arrivée du terme ou résiliation). Les données pourront ensuite être archivées pendant les durées de prescriptions civile et pénale restant à courir. Elles seront ensuite détruites de manière sécurisée. Les fichiers logs seront quant à eux conservés pendant une durée de six mois conformément aux préconisations de la Commission Nationale de l’Informatique et des Libertés (la « CNIL »). \n' +
  '\n' +
  '\boldEXCO\bold pourra être amenée à partager les données à caractère personnel de l’\boldUtilisateur\bold avec notamment ses sous-traitants, prestataires et fournisseurs techniques susceptibles de traiter les Données à caractère personnel de l’\boldUtilisateur\bold pour son compte, notamment en matière d’hébergement informatique.   \n' +
  '\n' +
  'Dans le cadre de la collecte et du traitement des données personnelles, l’Utilisateur dispose : \n' +
  '- du droit d’accéder à l’ensemble de ses données personnelles faisant l’objet d’un traitement ; \n' +
  '- du droit d’en demander la rectification, l’effacement et/ou la limitation ; \n' +
  '- du droit de demander la portabilité de ses données ;\n' +
  '- du droit de s’opposer à tout moment au traitement de ses données personnelles à des fins de prospection et / ou à des fins statistiques ;\n' +
  '- du droit de définir des directives relatives à la conservation, \n' +
  'à l’effacement et à la communication de ses données à caractère personnel après son décès ;\n' +
  'du droit d’introduire une réclamation auprès de la CNIL. \n' +
  '\n' +
  'L’Utilisateur peut adresser toute demande au Référent à la Protection des Données d’\boldEXCO \boldpar courrier postal, à l’adresse : 2 rue des Feuillants, 31076 Toulouse, ou par email à l’adresse  \blinkvosdonnees-fso@exco.fr\blink. \n' +
  '\n' +
  'Pour plus d’informations sur l’utilisation des Données à caractère personnel par \boldEXCO\bold, l’\boldUtilisateur\bold est invité à consulter la politique de confidentialité sur son site internet. \n' +
  '\n' +
  '\titleArticle 8 – Dispositions diverses\title\n' +
  '\n' +
  '\bold8\bold\bold.1.\bold \boldEXCO\bold peut modifier à tout moment les présentes Conditions générales, notamment pour se conformer à une disposition légale. La version des Conditions générales en vigueur sera disponible sur l’Espace My Exco dans la rubrique concernée. L’\boldUtilisateur \boldsera informé des modifications intervenues lors de sa première connexion consécutive à ladite modification et sera invité à accepter la nouvelle version des Conditions générales avant de pouvoir accéder à l’Espace My Exco.\n' +
  '\n' +
  '\bold8\bold\bold.2. \boldSi l’une quelconque des stipulations des présentes Conditions générales, ou une partie d’entre elles, s’avérait nulle au regard d’un règlement, d’une loi en vigueur ou à la suite d’une décision judiciaire devenue définitive, elle sera réputée non écrite, mais n’entraînera pas la nullité des Conditions générales dans leur ensemble, ni celle de la clause seulement partiellement concernée.\n' +
  '\n' +
  '\bold8\bold\bold.3. \boldLe fait que l’une ou l’autre des Parties n’ait pas exigé, temporairement ou définitivement, l’application d’une stipulation des présentes Conditions générales ne pourra être considérée comme une renonciation aux droits détenus par cette Partie.\n' +
  '\n' +
  '\bold8\bold\bold.4. \boldLes Conditions générales sont rédigées en français. En cas de traduction des présentes et de différences entre les versions, la version française prévaudra.\n' +
  '\n' +
  '\titleArticle 9 – Loi applicable et règlement des litiges\title\n' +
  '\n' +
  '\bold9\bold\bold.1. \boldLes présentes Conditions générales, ainsi que l’ensemble des rapports contractuels qui pourraient en découler sont soumis au droit français.\n' +
  '\n' +
  "\bold9\bold\bold.2. \boldDans l'hypothèse où un litige surviendrait entre les Parties, ces dernières s’engagent à rechercher une solution amiable, prenant en compte les intérêts de chacune d’elles avant d’engager toute action judiciaire.\n";
