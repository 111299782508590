import React from 'react';
import { Typography } from 'antd';
import { FormCard } from 'components/FormCard/FormCard';
import iconCheck from 'assets/icons/icon-check.svg';
import { CHANGE_PASSWORD_TRANSLATIONS } from 'const/translations';
import { LeafyBackground } from 'components/ui/LeafyBackground';
import Styles from './PasswordChanged.module.css';

const { ALERTS } = CHANGE_PASSWORD_TRANSLATIONS;

export const PasswordChanged = () => (
  <LeafyBackground>
    <FormCard links={['LOGIN']}>
      <div className={Styles.titleWrapper}>
        <img src={iconCheck} alt="Icon Success" />
        <Typography.Title className={Styles.textTitle}>
          {ALERTS.PASSWORD_HAS_BEEN_CHANGED}
        </Typography.Title>
      </div>
    </FormCard>
  </LeafyBackground>
);
