export const selectLoginErrorCode = (state) =>
  state.getIn(['authError', 'errorStatusCode']);

export const selectLoginErrorMessage = (state) =>
  state.getIn(['authError', 'message']);

export const selectPasswordRecoveryErrorCode = (state) =>
  state.getIn(['passwordRecovery', 'errorStatusCode']);

export const selectPasswordRecoveryEnteredEmail = (state) =>
  state.getIn(['passwordRecovery', 'enteredEmail']);

export const selectPasswordChangeErrorCode = (state) =>
  state.getIn(['passwordChangeError', 'errorStatusCode']);
