import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button } from 'components/ui/Button';
import PropTypes from 'prop-types';
import {
  STATUS_SUCCESS,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  DATE_FORMAT_DOTTED,
  CURRENT_YEAR,
} from 'const';
import { DatePicker } from 'antd';
import { getMonthIndexFromName } from 'helpers/monthSelector';
import { getNumberOfDaysInMonth } from 'helpers/charts';
import moment from 'moment';
import { getStringDateWithDots } from 'helpers/commentsModal';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import errorIcon from 'assets/icons/error.svg';
import { useSelector } from 'react-redux';
import {
  selectDateModeForSort,
  selectMonthForSort,
  selectsDayForSort,
} from 'modules/commentsModal/selectors';
import clsx from 'clsx';
import { COMMENTS_MODAL_TRANSLATIONS } from 'const/translations/CommentsModal';
import { MONTHS } from 'const/ui';
import Styles from './ChangeCommentForm.module.css';

moment.updateLocale('fr', {
  weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  monthsShort: MONTHS.map((month) => month.label),
});

const {
  NO_SYMBOLS_ERROR,
  TO_MANY_SYMBOLS_ERROR,
  SEND,
  CANCEL,
  TYPE_COMMENTARY,
  ADD_COMMENTARY,
  MODIFY_COMMENT,
} = COMMENTS_MODAL_TRANSLATIONS;

const propTypes = {
  closeForm: PropTypes.func,
  commentInEdit: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  addComment: PropTypes.func,
  chartType: PropTypes.string,
  submitStatus: PropTypes.oneOf([
    STATUS_LOADING,
    STATUS_ERROR,
    STATUS_SUCCESS,
    STATUS_NOT_REQUESTED,
  ]),
  changeComment: PropTypes.func,
  exercice: PropTypes.string,
};

export const ChangeCommentForm = ({
  closeForm,
  commentInEdit,
  addComment,
  chartType,
  submitStatus,
  changeComment,
  exercice,
}) => {
  useEffect(
    () => () => {
      closeForm();
    },
    [closeForm]
  );
  useEffect(() => {
    if (submitStatus === STATUS_SUCCESS) {
      closeForm();
    }
  }, [closeForm, submitStatus]);

  const dateModeForSort = useSelector(selectDateModeForSort);
  const monthIndexForSort = useSelector(selectMonthForSort);
  const dayIndexForSort = useSelector(selectsDayForSort);
  const firstMonthName = useSelector(selectCurrentCompanyFirstFiscalMonth);
  const numberToTakeFromYear =
    monthIndexForSort >= getMonthIndexFromName(firstMonthName) &&
    monthIndexForSort < 12 &&
    monthIndexForSort !== 0
      ? 1
      : 0;

  const [dateInPicker, setDateInPicker] = useState(
    dateModeForSort === 'month'
      ? moment([CURRENT_YEAR - numberToTakeFromYear, monthIndexForSort])
      : moment([
          CURRENT_YEAR - numberToTakeFromYear,
          monthIndexForSort,
          dayIndexForSort,
        ])
  );

  const disabledDate = useCallback(
    (current) => {
      const firstMonthIndex = getMonthIndexFromName(firstMonthName);
      const stringFirstMonthIndex = String(firstMonthIndex + 1).padStart(
        2,
        '0'
      );
      const maxYear = Number(exercice.slice(0, 4));
      const minYear = firstMonthIndex !== 0 ? maxYear - 3 : maxYear - 2;
      const lastMonthIndex = parseInt(exercice.slice(4), 10);
      const stringLastMonthIndex = String(lastMonthIndex).padStart(2, '0');
      const daysNumberOfLastMonth = getNumberOfDaysInMonth(
        maxYear,
        lastMonthIndex
      );
      const minDate = new Date(
        Date.parse(`${minYear}-${stringFirstMonthIndex}-01T00:00:00`)
      );
      const maxDate = new Date(
        Date.parse(
          `${maxYear}-${stringLastMonthIndex}-${daysNumberOfLastMonth}T23:59:59`
        )
      );
      const date = current.toDate();
      return date < minDate || date > maxDate;
    },
    [exercice, firstMonthName]
  );

  const onChange = useCallback(
    (date) => {
      setDateInPicker(date);
    },
    [setDateInPicker]
  );

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <span className={Styles.headerTitle}>
          {commentInEdit ? MODIFY_COMMENT : ADD_COMMENTARY}
        </span>
        {commentInEdit ? (
          <div className={Styles.editCommentaryDate}>
            {getStringDateWithDots(commentInEdit.date || '')}
          </div>
        ) : (
          <DatePicker
            className={Styles.datePicker}
            dropdownClassName={Styles.dateDrop}
            monthCellContentRender={(value) => value.format('MMMM')}
            placeholder="dd.mm.yyyy"
            format={DATE_FORMAT_DOTTED}
            disabledDate={disabledDate}
            onChange={onChange}
            value={dateInPicker}
            locale={moment.locale('fr')}
          />
        )}
      </div>
      <Formik
        initialValues={{
          comment: commentInEdit ? commentInEdit.text : '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.comment) {
            errors.comment = NO_SYMBOLS_ERROR;
          }
          if (values.comment && values.comment.length > 1000) {
            errors.comment = TO_MANY_SYMBOLS_ERROR;
          }
          return errors;
        }}
        onSubmit={(values) => {
          if (commentInEdit) {
            changeComment({
              text: values.comment,
              commentId: commentInEdit.id,
            });
          } else {
            addComment({
              text: values.comment,
              type: chartType,
              date: dateInPicker.toDate().toISOString(),
            });
          }
        }}
      >
        {({ values, handleSubmit, handleChange, errors, setErrors }) => (
          <form onSubmit={handleSubmit} className={Styles.form}>
            <div className={Styles.textAreaWrapper}>
              <textarea
                name="comment"
                onChange={handleChange}
                onFocus={() => setErrors({})}
                value={values.comment}
                className={clsx(Styles.commentTextarea, {
                  [Styles.textareaError]: errors.comment,
                })}
                placeholder={TYPE_COMMENTARY}
              />
              {errors.comment && (
                <div className={Styles.error}>
                  <img src={errorIcon} alt="error" className={Styles.icon} />
                  <span className={Styles.errorText}>{errors.comment}</span>
                </div>
              )}
            </div>
            <div className={Styles.buttonWrapper}>
              <Button variant="success" type="submit" className={Styles.button}>
                {SEND}
              </Button>
              <Button
                variant="secondary"
                className={clsx(Styles.backButton, Styles.button)}
                onClick={closeForm}
              >
                {CANCEL}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

ChangeCommentForm.propTypes = propTypes;
