import { getType, getActionCreator } from 'utils';
const prefix = '@@app/loggedUserInfo';

export const LOGGED_USER_INFO_REQUEST = getType(
  prefix,
  'LOGGED_USER_INFO_REQUEST'
);
export const loggedUserInfoRequest = getActionCreator(LOGGED_USER_INFO_REQUEST);

export const LOGGED_USER_INFO_SUCCESS = getType(
  prefix,
  'LOGGED_USER_INFO_SUCCESS'
);
export const loggedUserInfoSuccess = getActionCreator(LOGGED_USER_INFO_SUCCESS);

export const LOGGED_USER_INFO_ERROR = getType(prefix, 'LOGGED_USER_INFO_ERROR');
export const loggedUserInfoError = getActionCreator(LOGGED_USER_INFO_ERROR);

export const CHANGE_CURRENT_COMPANY = getType(prefix, 'CHANGE_CURRENT_COMPANY');
export const changeCurrentCompany = getActionCreator(CHANGE_CURRENT_COMPANY);

export const SET_CURRENT_COMPANY_API_KEY_REQUEST = getType(
  prefix,
  'SET_CURRENT_COMPANY_API_KEY_REQUEST'
);
export const setCurrentCompanyApiKeyRequest = getActionCreator(
  SET_CURRENT_COMPANY_API_KEY_REQUEST
);

export const SET_CURRENT_COMPANY_API_KEY_SUCCESS = getType(
  prefix,
  'SET_CURRENT_COMPANY_API_KEY_SUCCESS'
);
export const setCurrentCompanyApiKeySuccess = getActionCreator(
  SET_CURRENT_COMPANY_API_KEY_SUCCESS
);

export const FETCH_ISANET_REDIRECT_URL = getType(
  prefix,
  'FETCH_ISANET_REDIRECT_URL'
);
export const fetchIsanetRedirectUrl = getActionCreator(
  FETCH_ISANET_REDIRECT_URL
);

const USER_COMPANIES_DASHBOARD_DATA_REQUEST = getType(
  prefix,
  'USER_COMPANIES_DASHBOARD_DATA_REQUEST'
);
export const userCompaniesDashboardDataRequest = getActionCreator(
  USER_COMPANIES_DASHBOARD_DATA_REQUEST
);

export const USER_COMPANIES_DASHBOARD_DATA_SUCCESS = getType(
  prefix,
  'USER_COMPANIES_DASHBOARD_DATA_SUCCESS'
);
export const userCompaniesDashboardDataSuccess = getActionCreator(
  USER_COMPANIES_DASHBOARD_DATA_SUCCESS
);

export const USER_FINANCIALS_DATA_REQUEST = getType(
  prefix,
  'USER_FINANCIALS_DATA_REQUEST'
);
export const userFinancialsDataRequest = getActionCreator(
  USER_FINANCIALS_DATA_REQUEST
);

export const USER_FINANCIALS_DATA_SUCCESS = getType(
  prefix,
  'USER_FINANCIALS_DATA_SUCCESS'
);
export const userFinancialsDataSuccess = getActionCreator(
  USER_FINANCIALS_DATA_SUCCESS
);

export const USER_FINANCIALS_DATA_ERROR = getType(
  prefix,
  'USER_FINANCIALS_DATA_ERROR'
);
export const userFinancialsDataError = getActionCreator(
  USER_FINANCIALS_DATA_ERROR
);

export const ACCEPT_TERMS_OF_USE_REQUEST = getType(
  prefix,
  'ACCEPT_TERMS_OF_USE_REQUEST'
);
export const acceptTermsOfUseRequest = getActionCreator(
  ACCEPT_TERMS_OF_USE_REQUEST
);

export const ACCEPT_TERMS_OF_USE_SUCCESS = getType(
  prefix,
  'ACCEPT_TERMS_OF_USE_SUCCESS'
);
export const acceptTermsOfUseSuccess = getActionCreator(
  ACCEPT_TERMS_OF_USE_SUCCESS
);

export const GO_TO_NEXT_NOTIFICATION = getType(
  prefix,
  'GO_TO_NEXT_NOTIFICATION'
);
export const goToNextNotification = getActionCreator(GO_TO_NEXT_NOTIFICATION);
