import React from 'react';
import iconDone from 'assets/icons/icon-done.svg';
import { NavLink } from 'react-router-dom';
import { LANDING_PAGE_TRANSLATIONS } from 'const/translations/ServiceLanding';
import { ROUTES } from 'const';
import Styles from './SubscribeSuccess.module.css';

const {
  SUBSCRIBE_SUCCESS_TEXT,
  TOOLTIP_RETURN_TO_STORE,
} = LANDING_PAGE_TRANSLATIONS;

export const SubscribeSuccess = () => (
  <div className={Styles.wrapper}>
    <img src={iconDone} alt="Done" />
    <div className={Styles.text}>
      {SUBSCRIBE_SUCCESS_TEXT}
      <NavLink to={ROUTES.STORE} className={Styles.returnLink}>
        {TOOLTIP_RETURN_TO_STORE}
      </NavLink>
    </div>
  </div>
);
