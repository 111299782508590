import React, { useEffect } from 'react';
import { FileUploadManager } from 'components/UploadDocuments/FileUploadManager/FileUploadManager';
import { DocumentsHistory } from 'components/UploadDocuments/DocumentsHistory';
import { getUploadHistoryRequest } from 'modules/uploadDocuments/actions';
import {
  selectUploadHistoryDownloadedBool,
  selectUploadHistoryTableData,
  selectUploadStatus,
} from 'modules/uploadDocuments/selectors';
import { selectUserCompanyId } from 'modules/loggedUserInfo/selectors';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { useSimpleMedia } from 'hooks/useMedia';
import { SCREEN_WIDTH_BREAKPOINT_MEDIA, STATUS_LOADING } from 'const';
import flowersImg from 'assets/images/loginNewBg.svg';
import { Modal, Spin } from 'antd';
import { SectionTitle } from 'components/Section/SectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './UploadDocumentsPage.module.css';

const { TITLE, MESSAGES } = UPLOAD_DOCUMENT_TRANSLATIONS;

export const UploadDocumentsPage = () => {
  const dispatch = useDispatch();

  const uploadHistory = useSelector(selectUploadHistoryTableData);
  const companyId = useSelector(selectUserCompanyId);
  const uploadStatus = useSelector(selectUploadStatus);
  const isHistoryDownloaded = useSelector(selectUploadHistoryDownloadedBool);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    dispatch(getUploadHistoryRequest({ companyId }));
  }, [companyId, dispatch]);

  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  return (
    <div className={Styles.background}>
      <Modal
        visible={uploadStatus === STATUS_LOADING}
        closable={false}
        footer={null}
        width={340}
      >
        <div className={Styles.modalText}>{MESSAGES.UPLOAD_PLEASE_WAIT}</div>
        <div className={Styles.modalSpinWrapper}>
          <Spin />
        </div>
      </Modal>
      <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
      <div className={Styles.uploadDocumentsPage}>
        <SectionTitle
          title={TITLE}
          titleTextColor="white"
          className={Styles.title}
        />
        <FileUploadManager
          uploadStatus={uploadStatus}
          isMobileVersion={isMobileVersion}
        />
        {Boolean(uploadHistory.length) && (
          <DocumentsHistory
            documents={uploadHistory}
            isHistoryDownloaded={isHistoryDownloaded}
            isMobileVersion={isMobileVersion}
          />
        )}
      </div>
    </div>
  );
};
