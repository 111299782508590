import React from 'react';
import PropTypes from 'prop-types';
import Styles from './WaibiLandingAdvantage.module.css';
import waibiImage1 from '../../../assets/images/bandeau-tbd-1.png';
import waibiImage2 from '../../../assets/images/bandeau-tbd-2.png';

const propTypes = {
  title: PropTypes.string,
  descriptionList: PropTypes.array,
};

export const WaibiLandingAdvantage = ({ title, descriptionList }) => (
  <div className={Styles.textWrapper}>
    <h4 className={Styles.title}>{title}</h4>
    <ul className={Styles.list}>
      {descriptionList.map((point) => (
        <li className={Styles.description} key={point}>
          {point}
        </li>
      ))}
      <div className={Styles.imageWrapper}>
        <img src={waibiImage1} alt="waibi" className={Styles.image} />
        <img src={waibiImage2} alt="waibi" className={Styles.image} />
      </div>
    </ul>
  </div>
);

WaibiLandingAdvantage.propTypes = propTypes;
