import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Col, Typography } from 'antd';
import { Input } from 'components/ui';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import MainStyles from 'assets/styles/main.module.css';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';

const { labels, placeholders } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  onChange: PropTypes.func.isRequired,
  formikValues: PropTypes.object.isRequired,
};

export const TextInputs = memo(({ onChange, formikValues }) =>
  Object.entries(labels)
    .slice()
    .splice(0, 3)
    .map(([key, value]) => (
      <Col span={24} className={MainStyles.col} key={key}>
        <Typography.Paragraph
          className={clsx(MainStyles.formLabel, Styles.formLabel)}
        >
          {value}
        </Typography.Paragraph>
        <Form.Item className={clsx(Styles.formInputFull, Styles.formItem)}>
          <Input
            placeholder={placeholders[key]}
            onChange={onChange}
            name={key}
            value={formikValues[key]}
          />
        </Form.Item>
      </Col>
    ))
);

TextInputs.propTypes = propTypes;
