import { Col, Form, Icon, Typography } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React, { useState, useCallback } from 'react';

import { UploadFormik } from 'components/ui';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import MainStyles from 'assets/styles/main.module.css';

import Styles from './Vouchers.module.css';

const { GENERAL_PLACEHOLDERS } = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const ACCEPT_FORMATS = '.jpg,.png,.pdf,.doc,.docx';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  maxFilesCount: PropTypes.number,
};

const defaultProps = {
  maxFilesCount: 3,
};

export const VoucherItem = ({
  label,
  name,
  value,
  onChange,
  maxFilesCount,
}) => {
  const [completed, setCompleted] = useState(!!value.length);

  const handleChange = useCallback(
    (fieldName, fileList) => {
      onChange(fieldName, fileList);
      if (fileList.length) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    },
    [onChange]
  );

  return (
    <Col span={24} className={MainStyles.col2}>
      <Icon
        type="check-circle"
        className={clsx(Styles.labelIcon, completed && Styles.completed)}
      />
      <Typography.Paragraph
        className={clsx(MainStyles.formLabel, Styles.formLabel)}
      >
        {label}
      </Typography.Paragraph>
      <Form.Item className={clsx(Styles.formItem, Styles.uploadFile)}>
        <UploadFormik
          placeholder={GENERAL_PLACEHOLDERS.NOT_UPLOADED}
          onChange={handleChange}
          name={name}
          uploadedFiles={value}
          maxFilesCount={maxFilesCount}
          accept={ACCEPT_FORMATS}
        />
      </Form.Item>
    </Col>
  );
};

VoucherItem.propTypes = propTypes;
VoucherItem.defaultProps = defaultProps;
