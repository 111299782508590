import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { UploadDocumentsPage } from 'containers/UploadDocumentsPage';
import { EmployeeRegisterForm } from 'containers/EmployeeRegister';
import { EmployeeRegisterTitlePage } from 'containers/EmployeeRegisterTitlePage';
import { InvoiceSearchPage } from 'containers/InvoiceSearchPage';
import { DocumentsCabinetPage } from 'containers/DocumentsCabinet';
import { HomePage } from 'containers/HomePage';
import {
  AUTH_TOKEN_KEY,
  WAIBI_ACCESS_TOKEN,
  WAIBI_REFRESH_TOKEN,
} from 'config';
import { redirect } from 'helpers/common';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { AccessDeniedPage } from 'components/AccessDeniedPage';
import { Contacts } from 'containers/Contacts';
import { MyInformation } from 'containers/MyInformation';
import { TurnoverPage } from 'containers/TurnoverPage';
import { APP_MODULES, ROUTES } from 'const';
import { TreasuryPage } from 'containers/TreasuryPage';
import { SalaryContainer } from 'containers/Salary/SalaryContainer';
import { HRPage } from 'containers/HRPage';
import { AdditionalUsers } from 'containers/AdditionalUsers/AdditionalUsers';
import { TermsOfUseLanding } from 'containers/TermsOfUseLanding';
import {
  REGISTERED_TERMS_TRANSLATIONS,
  TERMS_OF_USE_TRANSLATIONS,
} from 'const/translations/TermsOfUse';
import { Store } from 'containers/Store/Store';
import { CartPage } from 'containers/CartPage/CartPage';
import { PACKAGES_NAMES } from 'const/packages';
import { removeFromStorage } from 'helpers/storage';
import { LandingPage } from 'components/LandingPage';
import {
  CART_PACKAGES_LOCAL_STORAGE_KEY,
  INITIAL_DATASET_LOCAL_STORAGE_KEY,
  USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
} from 'const/cartPage';
import { UnsubscribePage } from 'containers/UnsubscribePage';
import { AUTHORIZED_REDIRECTS } from 'const/RoutesRedirects';
import { Counterparties } from 'components/Counterparties/Counterparties';
import { Counterparty } from 'components/Counterparties/Counterparty';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';

const propTypes = {
  authorizedModules: PropTypes.array,
  isPayEnable: PropTypes.bool,
  isCurrentCompanyActivated: PropTypes.bool,
  isMainUser: PropTypes.bool,
};

const { TERMS_TEXT, TITLE, BACK_TO_HOMEPAGE } = TERMS_OF_USE_TRANSLATIONS;
const { DATASET_KEY, DELETED_USERS_KEY } = ADDITIONAL_USERS_LOCAL_STORAGE_KEYS;

const {
  EVP,
  PAY_INVOICE,
  SEARCH_INVOICES,
  READONLY_INVOICE,
  APPROVE_INVOICE,
  DOCUMENTS_CABINET,
  UPLOAD_DOCUMENTS,
  PRE_VALIDATE_INVOICE,
} = APP_MODULES;

const {
  REGISTERED_USAGE_TERMS,
  REGISTERED_USAGE_TERMS_TITLE,
} = REGISTERED_TERMS_TRANSLATIONS;

/**
 * Switch children must always be Route or Redirect components
 */
export const AuthorisedRoutes = ({
  authorizedModules,
  isPayEnable = false,
  isCurrentCompanyActivated,
  isMainUser,
}) => (
  <Switch>
    <Route exact path={ROUTES.ROOT} component={HomePage} />
    <Route exact path={ROUTES.CONTACTS} component={Contacts} />
    <Route exact path={ROUTES.MY_INFORMATION} component={MyInformation} />
    <Route
      exact
      path={ROUTES.TERMS_OF_USE_LANDING}
      render={() => (
        <TermsOfUseLanding
          termsText={TERMS_TEXT}
          hasBreadcrumb
          title={TITLE}
          breadcrumbLink={ROUTES.ROOT}
          breadcrumbText={BACK_TO_HOMEPAGE}
        />
      )}
    />
    <Route
      exact
      path={ROUTES.LOGOUT}
      render={() => {
        removeFromStorage({
          key: [
            AUTH_TOKEN_KEY,
            WAIBI_ACCESS_TOKEN,
            WAIBI_REFRESH_TOKEN,
            INITIAL_DATASET_LOCAL_STORAGE_KEY,
            USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
            CART_PACKAGES_LOCAL_STORAGE_KEY,
            DATASET_KEY,
            DELETED_USERS_KEY,
          ],
        });
        return redirect(ROUTES.LOGIN);
      }}
    />
    {!isCurrentCompanyActivated && <Redirect to={ROUTES.ROOT} />}
    <ProtectedRoute
      exact
      path={ROUTES.WAIBI_LANDING}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized
      render={() => <LandingPage pageName={PACKAGES_NAMES.WAIBI} />}
    />
    <ProtectedRoute
      exact
      path={ROUTES.SALES_LANDING}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      render={() => <LandingPage pageName={PACKAGES_NAMES.FACT} />}
    />
    <ProtectedRoute
      exact
      path={ROUTES.EXPENSES_LANDING}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      render={() => <LandingPage pageName={PACKAGES_NAMES.PAY} />}
    />
    <Route
      exact
      path={ROUTES.HELP}
      render={() => <LandingPage pageName="HELP" simplified />}
    />
    <ProtectedRoute
      exact
      path={ROUTES.MY_EXCO_LANDING}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      render={() => <LandingPage pageName={PACKAGES_NAMES.MY_EXCO} />}
    />
    <Route exact path={ROUTES.TURNOVER} render={() => <TurnoverPage />} />
    <Route exact path={ROUTES.TREASURY} render={() => <TreasuryPage />} />
    <Route exact path={ROUTES.HR} render={() => <HRPage />} />
    <Route
      exact
      path={[ROUTES.DEBTORS, ROUTES.CREDITORS]}
      component={Counterparties}
    />
    <Route
      exact
      path={[ROUTES.DEBTOR, ROUTES.CREDITOR]}
      component={Counterparty}
    />
    <ProtectedRoute
      isAuthorized={authorizedModules.includes(EVP) && isPayEnable}
      exact
      path={ROUTES.EMPLOYEE_REGISTER_TITLE}
      component={EmployeeRegisterTitlePage}
    />
    <ProtectedRoute
      isAuthorized={authorizedModules.includes(EVP) && isPayEnable}
      exact
      path={ROUTES.EMPLOYEE_REGISTER_FORM}
      component={EmployeeRegisterForm}
    />
    <ProtectedRoute
      isAuthorized={authorizedModules.includes(UPLOAD_DOCUMENTS)}
      exact
      path={ROUTES.UPLOAD_DOCUMENTS}
      component={UploadDocumentsPage}
    />
    <ProtectedRoute
      isAuthorized={authorizedModules.includes(DOCUMENTS_CABINET)}
      path={ROUTES.DOCUMENTS_CABINET}
      component={DocumentsCabinetPage}
    />
    <ProtectedRoute
      isAuthorized={isMainUser}
      exact
      path={ROUTES.ADDITIONAL_USERS}
      render={() => <AdditionalUsers />}
      fallbackRoute={ROUTES.ROOT}
    />
    <ProtectedRoute
      isAuthorized={authorizedModules.includes(SEARCH_INVOICES)}
      exact
      path={ROUTES.INVOICE_SEARCH}
      component={InvoiceSearchPage}
    />
    <ProtectedRoute
      isAuthorized={[
        APPROVE_INVOICE,
        PAY_INVOICE,
        READONLY_INVOICE,
        PRE_VALIDATE_INVOICE,
      ].some((module) => authorizedModules.includes(module))}
      exact
      path={ROUTES.EXPENSES}
      component={InvoiceSearchPage}
      fallbackRoute={ROUTES.EXPENSES_LANDING}
    />
    <ProtectedRoute
      exact
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      path={ROUTES.TERMS_OF_SUBSCRIPTION}
      render={() => (
        <TermsOfUseLanding
          hasBreadcrumb={false}
          termsText={REGISTERED_USAGE_TERMS}
          title={REGISTERED_USAGE_TERMS_TITLE}
          hasLogoOnPage
        />
      )}
    />
    <ProtectedRoute
      exact
      path={ROUTES.STORE}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      render={() => <Store />}
    />
    <ProtectedRoute
      exact
      path={ROUTES.CART}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      render={() => <CartPage />}
    />
    <ProtectedRoute
      exact
      path={ROUTES.UNSUBSCRIBE}
      fallbackRoute={ROUTES.ROOT}
      isAuthorized={isMainUser}
      component={UnsubscribePage}
    />
    <Route exact path={ROUTES.NOT_AUTHORIZED} component={AccessDeniedPage} />
    {AUTHORIZED_REDIRECTS.map(({ to, from }) => (
      <Redirect exact key={`${from}_${to}`} to={to} from={from} />
    ))}
    {authorizedModules.includes(EVP) && isPayEnable && <SalaryContainer />}
    <Redirect to={ROUTES.ROOT} />
  </Switch>
);

AuthorisedRoutes.propTypes = propTypes;
