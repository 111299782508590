import { COMMON_LABELS } from './index';
import { PACKAGES_NAMES } from '../packages';

export const UNSUBSCRIBE_PAGE_TRANSLATIONS = {
  TITLE: 'Désinscription My Exco',
  TEXT: {
    BLOCK_1: {
      LINE_1: 'Cette demande sera envoyée :',
      LINE_2:
        "- à l'équipe technique My Exco pour la fermeture des services payants de votre espace.",
      LINE_3:
        "- à l'équipe de facturation de My Exco pour la mise à jour de votre abonnement. La résiliation prendra effet à la fin du mois en cours.",
    },
    BLOCK_2: {
      LINE_1:
        "En cas de désinscription complète, dès le mois prochain, vos services My Exco seront réduits au strict minimum, c'est à dire aux outils d’échange digitaux avec Exco :",
      LINE_2: '- Dépôt de documents',
      LINE_3: '- Saisie des variables de paie de vos salariés',
      LINE_4: '- Accès au dossier partagé avec Exco',
    },
    BLOCK_3: {
      LINE_1:
        "En revanche, vous n'aurez plus accès aux outils et services de pilotage de votre entreprise :",
      LINE_2: "- Indicateurs d'activité et de productivité RH",
      LINE_3: '- Suivi et gestion de la trésorerie',
      LINE_4: '- Recherche multicritères',
    },
  },
  LABELS: {
    MODULES: '1. Sur quel(s) outils(s) ou service(s) porte votre demande ?',
    CONFIRMATION: '2. Etes-vous sûr de vouloir vous désinscrire ?',
    REASONS:
      '3. Dans un but d’amélioration continue de nos services, pour quelle(s) raison(s) vous désinscrivez-vous ?',
  },
  REASON_OTHER_PLACEHOLDER: 'Veuillez préciser',
  OPTIONS: {
    MODULES: [
      { label: 'Mes ventes - FACT', value: PACKAGES_NAMES.FACT },
      { label: 'Mes dépenses - PAY', value: PACKAGES_NAMES.PAY },
      {
        label:
          "Bouquet My Exco (je garde seulement les outils d'échange avec Exco par défaut)",
        value: PACKAGES_NAMES.MY_EXCO,
        subtitle:
          "(attention, vous devez conserver le bouquet My Exco pour garder l'accès aux modules FACT et/ou PAY)",
      },
    ],
    CONFIRM: [
      { value: COMMON_LABELS.YES, label: COMMON_LABELS.YES },
      { value: COMMON_LABELS.NO, label: COMMON_LABELS.NO },
    ],
    REASONS: [
      {
        label: 'Je n’en ai pas trouvé l’utilité',
        value: 'Je n’en ai pas trouvé l’utilité',
      },
      {
        label: 'Je n’arrive pas à m’en servir',
        value: 'Je n’arrive pas à m’en servir',
      },
      {
        label: 'Cela ne correspond pas à mes attentes',
        value: 'Cela ne correspond pas à mes attentes',
      },
      {
        label: 'Autre',
        value: COMMON_LABELS.OTHER,
      },
    ],
  },
  ERRORS: {
    NO_MODULES: 'Veuillez spécifier les modules à désinscrire',
    NO_CONFIRM: 'Veuillez confirmer la désinscription',
    NO_REASONS: 'Merci de préciser le motif de votre désinscription',
    NO_SUBSCRIBED_MODULES: "Vous n'avez pas de modules abonnés pour le moment.",
  },
  REQUEST_SUCCESS: 'Les modules demandés seront désactivés dans les 48 heures',
};
