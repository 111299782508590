import React, { useCallback, useMemo, useState } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import { Button } from 'components/ui/Button';
import { Modal as antdModal } from 'antd';
import { ROUTES } from 'const';
import { redirect } from 'helpers/common';
import flowersImg from 'assets/images/loginNewBg.svg';
import logo from 'assets/images/LOGO_MyExco.svg';
import { useDispatch } from 'react-redux';
import { acceptTermsOfUseRequest } from 'modules/loggedUserInfo/actions';
import { TERMS_OF_USE_TRANSLATIONS } from 'const/translations/TermsOfUse';
import { mapTermsOfUseText } from 'helpers/termsOfUse';
import Styles from './TermsOfUse.module.css';

const {
  AGREE_BUTTON,
  DECLINE_BUTTON,
  TERMS_TEXT,
  TITLE,
  CONFIRM_MODAL_TITLE,
  CANCEL_BUTTON,
  OK_BUTTON,
} = TERMS_OF_USE_TRANSLATIONS;

let paragraphIndex = 0;
let spansIndex = 0;

/**
 * Agreement for additional user, with just Terms of Use.
 * For registered user option check RegisteredTerms container.
 */
export const TermsOfUse = () => {
  const formattedText = useMemo(() => mapTermsOfUseText(TERMS_TEXT), []);

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  const handleScroll = useCallback(
    (e) => {
      const bottom =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 200 <=
        e.currentTarget.clientHeight;
      if (bottom) {
        setAreButtonsDisabled(false);
      }
    },
    [setAreButtonsDisabled]
  );

  const onDeclineClick = useCallback(() => {
    antdModal.confirm({
      title: CONFIRM_MODAL_TITLE,
      icon: false,
      className: MainStyles.modal,
      okText: OK_BUTTON,
      okButtonProps: {
        className: MainStyles.modalOkButton,
      },
      autoFocusButton: null,
      cancelText: CANCEL_BUTTON,
      cancelButtonProps: {
        className: MainStyles.modalCancelButton,
      },
      onOk() {
        redirect(ROUTES.LOGOUT);
      },
      onCancel() {},
    });
  }, []);

  const dispatch = useDispatch();

  const onAcceptClick = useCallback(() => {
    dispatch(acceptTermsOfUseRequest());
  }, [dispatch]);

  return (
    <div className={Styles.termsOfUseWrapper}>
      <div className={clsx(MainStyles.container, Styles.wrapper)}>
        <img src={logo} alt="Exco logo" className={Styles.mainLogo} />
        <div className={Styles.modalWrapper}>
          <SectionTitle title={TITLE} className={Styles.title} />
          <div
            className={Styles.termsSection}
            onScroll={areButtonsDisabled ? handleScroll : null}
          >
            {formattedText.map((paragraph) => {
              paragraphIndex += 1;
              return (
                <div className={Styles.paragraph} key={paragraphIndex}>
                  {paragraph.map((spanElement) => {
                    spansIndex += 1;
                    return (
                      <React.Fragment key={spansIndex}>
                        {spanElement}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={Styles.buttonsWrapper}>
            <Button
              variant="success"
              onClick={onAcceptClick}
              disabled={areButtonsDisabled}
              className={Styles.agreeButton}
            >
              {AGREE_BUTTON}
            </Button>
            <Button
              variant="fullTransparent"
              className={Styles.declineButton}
              onClick={onDeclineClick}
            >
              {DECLINE_BUTTON}
            </Button>
          </div>
        </div>
      </div>
      <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
    </div>
  );
};
