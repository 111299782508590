import React, { memo } from 'react';
import { Row, Col, Typography, Icon } from 'antd';
import { NO_DATA_FOUND } from 'const/translations/SalaryEmployeePage';
import { Button } from 'components/ui/Button';
import PropTypes from 'prop-types';
import Styles from './NoDataFound.module.css';

const { Paragraph } = Typography;

const propTypes = {
  onResetFilters: PropTypes.func.isRequired,
};

export const NoDataFound = memo(({ onResetFilters }) => (
  <Row type="flex" justify="center">
    <Col className={Styles.content}>
      <Icon className={Styles.icon} type="search"></Icon>
      <Paragraph className={Styles.text}>
        {NO_DATA_FOUND.TEXT_FIRST_LINE}
        <br />
        {NO_DATA_FOUND.TEXT_SECOND_LINE}
      </Paragraph>
      <Button className={Styles.button} onClick={onResetFilters}>
        {NO_DATA_FOUND.BUTTON_RESET_FILTERS}
      </Button>
    </Col>
  </Row>
));

NoDataFound.propTypes = propTypes;
