import { getType, getActionCreator } from 'utils';
const prefix = '@@app/commentsModal';

export const GET_ALL_COMMENTS_REQUEST = getType(
  prefix,
  'GET_ALL_COMMENTS_REQUEST'
);
export const getAllCommentsRequest = getActionCreator(GET_ALL_COMMENTS_REQUEST);

export const GET_ALL_COMMENTS_SUCCESS = getType(
  prefix,
  'GET_ALL_COMMENTS_SUCCESS'
);
export const getAllCommentsSuccess = getActionCreator(GET_ALL_COMMENTS_SUCCESS);

export const GET_ALL_COMMENTS_ERROR = getType(prefix, 'GET_ALL_COMMENTS_ERROR');
export const getAllCommentsError = getActionCreator(GET_ALL_COMMENTS_ERROR);

export const OPEN_COMMENTS_MODAL = getType(prefix, 'OPEN_COMMENTS_MODAL');
export const openCommentsModal = getActionCreator(OPEN_COMMENTS_MODAL);

export const CLOSE_COMMENTS_MODAL = getType(prefix, 'CLOSE_COMMENTS_MODAL');
export const closeCommentsModal = getActionCreator(CLOSE_COMMENTS_MODAL);

export const ADD_COMMENT_REQUEST = getType(prefix, 'ADD_COMMENT_REQUEST');
export const addCommentRequest = getActionCreator(ADD_COMMENT_REQUEST);

export const ADD_COMMENT_SUCCESS = getType(prefix, 'ADD_COMMENT_SUCCESS');
export const addCommentSuccess = getActionCreator(ADD_COMMENT_SUCCESS);

export const ADD_COMMENT_ERROR = getType(prefix, 'ADD_COMMENT_ERROR');
export const addCommentError = getActionCreator(ADD_COMMENT_ERROR);

export const CHANGE_COMMENT_REQUEST = getType(prefix, 'CHANGE_COMMENT_REQUEST');
export const changeCommentRequest = getActionCreator(CHANGE_COMMENT_REQUEST);

export const CHANGE_COMMENT_SUCCESS = getType(prefix, 'CHANGE_COMMENT_SUCCESS');
export const changeCommentSuccess = getActionCreator(CHANGE_COMMENT_SUCCESS);

export const DELETE_COMMENT_REQUEST = getType(prefix, 'DELETE_COMMENT_REQUEST');
export const deleteCommentRequest = getActionCreator(DELETE_COMMENT_REQUEST);

export const DELETE_COMMENT_SUCCESS = getType(prefix, 'DELETE_COMMENT_SUCCESS');
export const deleteCommentSuccess = getActionCreator(DELETE_COMMENT_SUCCESS);

export const RESET_COMMENTS_FORM_STATUS = getType(
  prefix,
  'RESET_COMMENTS_FORM_STATUS'
);
export const resetCommentsFormStatus = getActionCreator(
  RESET_COMMENTS_FORM_STATUS
);
