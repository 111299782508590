import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { UsefulLinksModal } from 'components/UsefulLinks/UsefulLinksModal';
import { DeleteUsefulLinksModal } from 'components/UsefulLinks/DeleteUsefulLinksModal';

import link from 'assets/icons/link.svg';
import bin from 'assets/icons/Subscription/bucket.svg';
import pen from 'assets/icons/Subscription/pen.svg';

import { getBookmarks } from 'modules/usefulLinks/actions';
import { selectBookmarks } from 'modules/usefulLinks/selectors';

import Styles from './UsefulLinks.module.css';

const initialStateValues = {
  id: null,
  name: null,
  url: null,
};

const UsefulLinks = ({ isVisible, closeModal, openModal }) => {
  const dispatch = useDispatch();
  const bookmarks = useSelector(selectBookmarks);
  const [isDeleteModalVisisble, setDeleteModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState(initialStateValues);

  const closeEditModal = () => {
    closeModal();
    setInitialValues(initialStateValues);
  };
  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setInitialValues(initialStateValues);
  };

  const openDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const onEdit = (id, title, url) => (e) => {
    e.preventDefault();
    setInitialValues({ id, name: title, url });
    openModal();
  };

  const onDelete = (id) => (e) => {
    e.preventDefault();
    setInitialValues({ id });
    openDeleteModal();
  };

  useEffect(() => {
    dispatch(getBookmarks());
  }, [dispatch]);

  return (
    <div className={Styles.linksWrap}>
      <UsefulLinksModal
        isVisible={isVisible}
        closeModal={closeEditModal}
        initialValues={initialValues}
      />
      <DeleteUsefulLinksModal
        isVisible={isDeleteModalVisisble}
        closeModal={closeDeleteModal}
        id={initialValues.id}
      />
      {bookmarks?.data?.map(({ id, title, url, isGeneral }) => {
        const fixedUrl = url.search('http') > -1 ? url : `https://${url}`;

        return (
          <a
            href={fixedUrl}
            target="_blank"
            className={Styles.link}
            rel="noopener noreferrer"
          >
            <img src={link} alt="link icon" />
            <span className={Styles.linkTitle}>{title}</span>
            <span className={Styles.linkDesc}>{url}</span>

            {!isGeneral && (
              <div className={Styles.linkActions}>
                <div className={Styles.icon} onClick={onEdit(id, title, url)}>
                  <img src={pen} alt="edit icon" />
                </div>

                <div className={Styles.icon} onClick={onDelete(id)}>
                  <img src={bin} alt="delete icon" className={Styles.icon} />
                </div>
              </div>
            )}
          </a>
        );
      })}
    </div>
  );
};

UsefulLinks.propTypes = {
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

export default memo(UsefulLinks);
