const NATURE_EMPLOYMENT = 'Nature de l’emploi';
const CLASSIFICATION = 'Classification';
const CONTRACT = 'Contrat';

export const EMPLOYEE_TABLE = {
  HEADER_TITLES: {
    NAME: 'Nom Prénom',
    ESTABLISHMENTS: 'Etablissement',
    NATURE_EMPLOYMENT,
    CLASSIFICATION,
    CONTRACT,
    ENTRY_DATE: 'Date d’entrée',
    RELEASE_DATE: 'Date de sortie',
    SCHEDULE: 'Horaire',
  },
  ACTIONS: {
    ABSENCES: 'Absences',
    LEAVE: 'Congés',
  },
};

export const EMPLOYEE_HEADER = {
  FILTER_PLACEHOLDERS: {
    ESTABLISHMENT: 'Etablissement',
    NATURE_EMPLOYMENT,
    CLASSIFICATION,
    CONTRACT,
    SEARCH_BY_NAME: 'Rechercher par nom',
  },
  FILTER_TITLE: 'Les filtres',
  BUTTONS_LABELS: {
    DOWNLOAD: 'Télécharger',
    NEW_EMPLOYEE: 'Ajouter nouveau salarié',
    SAVE: 'Sauvegarder',
    CANCEL: 'Annuler',
  },
};

export const NO_DATA_FOUND = {
  TEXT_FIRST_LINE: 'Aucun résultat',
  TEXT_SECOND_LINE: 'Veuillez modifier votre demande',
  BUTTON_RESET_FILTERS: 'Effacer les filtres',
};

export const TIME_TABLE_MODAL = {
  TITLE: 'Horaire applicable au salarié',
  TOTAL: 'Total',
  SEPARATOR: 'et',
};
