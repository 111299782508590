import React from 'react';
import { Spinner } from 'components/Spinner';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Styles from './SidebarNavLinks.module.css';

const propTypes = {
  icon: PropTypes.object,
  selector: PropTypes.func,
  className: PropTypes.string,
};

export const SidebarIconWithLoader = ({ icon: Icon, selector, className }) => {
  const isLoading = useSelector(selector);

  if (isLoading) {
    return <Spinner className={clsx(Styles.sidebarLoader, ...className)} />;
  }

  return <Icon className={className} />;
};

SidebarIconWithLoader.propTypes = propTypes;
