import { getType, getActionCreator } from 'utils';
const prefix = '@@app/DocumentsCabinet';

/**
 * request folder data stored at particular path
 */
export const SHAREPOINT_FOLDER_REQUEST = getType(
  prefix,
  'SHAREPOINT_FOLDER_REQUEST'
);
export const sharepointFolderRequest = getActionCreator(
  SHAREPOINT_FOLDER_REQUEST
);

export const SHAREPOINT_FOLDER_SUCCESS = getType(
  prefix,
  'SHAREPOINT_FOLDER_SUCCESS'
);
export const sharepointFolderSuccess = getActionCreator(
  SHAREPOINT_FOLDER_SUCCESS
);

export const SHAREPOINT_FOLDER_ERROR = getType(
  prefix,
  'SHAREPOINT_FOLDER_ERROR'
);
export const sharepointFolderError = getActionCreator(SHAREPOINT_FOLDER_ERROR);

/**
 * download file
 */
export const DOWNLOAD_SHAREPOINT_FILE_REQUEST = getType(
  prefix,
  'DOWNLOAD_SHAREPOINT_FILE_REQUEST'
);
export const downloadSharepointFileRequest = getActionCreator(
  DOWNLOAD_SHAREPOINT_FILE_REQUEST
);
export const DOWNLOAD_SHAREPOINT_FILE_SUCCESS = getType(
  prefix,
  'DOWNLOAD_SHAREPOINT_FILE_SUCCESS'
);
export const downloadSharepointFileSuccess = getActionCreator(
  DOWNLOAD_SHAREPOINT_FILE_SUCCESS
);
export const DOWNLOAD_SHAREPOINT_FILE_ERROR = getType(
  prefix,
  'DOWNLOAD_SHAREPOINT_FILE_ERROR'
);
export const downloadSharepointFileError = getActionCreator(
  DOWNLOAD_SHAREPOINT_FILE_ERROR
);

/**
 * return page to initial state
 */
export const SHAREPOINT_INIT_PAGE = getType(prefix, 'SHAREPOINT_INIT_PAGE');
export const sharepointInitPage = getActionCreator(SHAREPOINT_INIT_PAGE);

/**
 * reset page status
 */
export const SHAREPOINT_RESET_STATUS = getType(
  prefix,
  'SHAREPOINT_RESET_STATUS'
);
export const sharepointResetStatus = getActionCreator(SHAREPOINT_RESET_STATUS);

/**
 * search for information in all available data
 */
export const SHAREPOINT_SEARCH_REQUEST = getType(
  prefix,
  'SHAREPOINT_SEARCH_REQUEST'
);
export const sharepointSearchRequest = getActionCreator(
  SHAREPOINT_SEARCH_REQUEST
);

export const SHAREPOINT_SEARCH_SUCCESS = getType(
  prefix,
  'SHAREPOINT_SEARCH_SUCCESS'
);
export const sharepointSearchSuccess = getActionCreator(
  SHAREPOINT_SEARCH_SUCCESS
);

export const SHAREPOINT_SEARCH_ERROR = getType(
  prefix,
  'SHAREPOINT_SEARCH_ERROR'
);
export const sharepointSearchError = getActionCreator(SHAREPOINT_SEARCH_ERROR);
