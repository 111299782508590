import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { closeModal } from 'modules/ModalContainer/actions';
import { MODALS_BY_TYPES } from 'const/ModalContainer';
import Styles from './ModalContainer.module.css';

const propTypes = {
  type: PropTypes.string,
  modalProps: PropTypes.object,
};

/**
 * Shared container for modals, modal type and props are set through redux
 * type defines exact modal content
 */
export const ModalContainer = memo(({ type, modalProps = {} }) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const ModalContent = MODALS_BY_TYPES[type];

  const { closable, onClose } = modalProps;

  const modalHandleClose = (onClose && onClose(handleClose)) || handleClose;

  if (!ModalContent) {
    return null;
  }

  return (
    <Modal
      visible={!!type}
      className={Styles[type]}
      onCancel={modalHandleClose}
      footer={false}
      closable={closable || false}
    >
      <ModalContent {...modalProps} handleClose={modalHandleClose} />
    </Modal>
  );
});

ModalContainer.propTypes = propTypes;
