/**
 * Formats an array of objects into a structure suitable for use as tab items.
 *
 * @param {Array} accounts - The array of accounts to format.
 * @param {string} nameKey - The name of the key in each object to use as the name of the tab item.
 * @param {string} valueKey - The name of the key in each object to use as the value of the tab item.
 * @returns {Array} An array of objects with `id`, `name`, `value`, and `suffix` properties, sorted by `value` in descending order.
 */
export const formatToTabItemsStructure = (accounts, nameKey, valueKey) => {
  const formattedAccounts = accounts.map((account) => ({
    id: account[nameKey],
    name: account[nameKey],
    value: account[valueKey],
    suffix: '€',
  }));

  return formattedAccounts.sort((a, b) => Number(b.value) - Number(a.value));
};
