import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectUserCompanyId,
  selectUserCurrentCompanyExercice,
  selectUserCurrentCompanyTransition,
} from 'modules/loggedUserInfo/selectors';
import { getCreditorData, getCreditorsRequest } from '../services';
import { mapCreditorsResponseToReduxStore } from '../helpers';
import {
  CREDITOR_DATA_REQUEST,
  creditorDataError,
  creditorDataSuccess,
  CREDITORS_SEARCH_REQUEST,
  creditorsSearchError,
  creditorsSearchSuccess,
} from '../actions';
import { trimDetailedInfoTableData } from '../../../helpers/common';

function* getCreditors({ payload }) {
  try {
    const id = yield select(selectUserCompanyId);
    const currentExercice = yield select(selectUserCurrentCompanyExercice);
    const transition = yield select(selectUserCurrentCompanyTransition);
    const { details, datasets, lastUpdated, meta } = yield call(
      getCreditorsRequest,
      {
        id,
        currentExercice,
        transition,
      }
    );
    const mappedToReduxData = mapCreditorsResponseToReduxStore(details);
    yield put(
      creditorsSearchSuccess({
        data: mappedToReduxData,
        datasets,
        lastUpdated,
        meta,
        payload,
      })
    );
  } catch (error) {
    yield put(creditorsSearchError({ error }));
  }
}

function* getCreditorDataWorker({ payload: { currentDosId: dosId, code } }) {
  try {
    const exercice = yield select(selectUserCurrentCompanyExercice);
    const transition = yield select(selectUserCurrentCompanyTransition);
    const { data, accountName } = yield call(getCreditorData, {
      dosId,
      code,
      exercice,
      transition,
    });
    yield put(
      creditorDataSuccess({
        data: trimDetailedInfoTableData(data),
        accountName,
      })
    );
  } catch (error) {
    yield put(creditorDataError({ error }));
  }
}

export function* creditorsMainSaga() {
  yield takeLatest(CREDITORS_SEARCH_REQUEST, getCreditors);
  yield takeLatest(CREDITOR_DATA_REQUEST, getCreditorDataWorker);
}
