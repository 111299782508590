import { getType, getActionCreator } from 'utils';
const prefix = '@@app/additionalUsers';

export const SAVE_ADDITIONAL_USERS_REQUEST = getType(
  prefix,
  'SAVE_ADDITIONAL_USERS_REQUEST'
);
export const saveAdditionalUsersRequest = getActionCreator(
  SAVE_ADDITIONAL_USERS_REQUEST
);
