import React from 'react';
import homepageAdvantagesLogo from 'assets/images/homepage-advantages-logo.png';
import simpleAdvantage from 'assets/images/HomePageAdvantages/simple-advantage-logo.png';
import effectiveAdvantage from 'assets/images/HomePageAdvantages/effective-advantage-logo.png';
import accessibleAdvantage from 'assets/images/HomePageAdvantages/accessible-advantage-logo.png';
import reliableAdvantage from 'assets/images/HomePageAdvantages/reliable-advantage-logo.png';
import { HOMEPAGE_FORM_TRANSLATIONS } from '../../const/translations';
import Styles from './HomePageAdvantages.module.css';

const { ADVANTAGES_TITLES, ADVANTAGES_TEXT } = HOMEPAGE_FORM_TRANSLATIONS;

export const HomePageAdvantages = () => (
  <div className={Styles.section}>
    <img src={homepageAdvantagesLogo} alt="advantage-section-logo" />
    <div className={Styles.advantagesWrapper}>
      <div className={Styles.advantagesItem}>
        <img src={simpleAdvantage} alt="simple-advantage" />
        <div className={Styles.advantageText}>
          <span className={Styles.advantageTitle}>
            {ADVANTAGES_TITLES.SIMPLE}
          </span>
          <span className={Styles.advantageDescription}>
            {ADVANTAGES_TEXT.SIMPLE_ADVANTAGE}
          </span>
        </div>
      </div>
      <div className={Styles.advantagesItem}>
        <img src={effectiveAdvantage} alt="effective-advantage" />
        <div className={Styles.advantageText}>
          <span className={Styles.advantageTitle}>
            {ADVANTAGES_TITLES.ACCESSIBLE}
          </span>
          <span className={Styles.advantageDescription}>
            {ADVANTAGES_TEXT.ACCESSIBLE_ADVANTAGE}
          </span>
        </div>
      </div>
      <div className={Styles.advantagesItem}>
        <img src={accessibleAdvantage} alt="accessible-advantage" />
        <div className={Styles.advantageText}>
          <span className={Styles.advantageTitle}>
            {ADVANTAGES_TITLES.EFFECTIVE}
          </span>
          <span className={Styles.advantageDescription}>
            {ADVANTAGES_TEXT.EFFECTIVE_ADVANTAGE}
          </span>
        </div>
      </div>
      <div className={Styles.advantagesItem}>
        <img src={reliableAdvantage} alt="reliable-advantage" />
        <div className={Styles.advantageText}>
          <span className={Styles.advantageTitle}>
            {ADVANTAGES_TITLES.RELIABLE}
          </span>
          <span className={Styles.advantageDescription}>
            {ADVANTAGES_TEXT.RELIABLE_ADVANTAGE}
          </span>
        </div>
      </div>
    </div>
  </div>
);
