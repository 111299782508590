import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  GET_HR_DATA_ERROR,
  GET_HR_DATA_REQUEST,
  GET_HR_DATA_SUCCESS,
  INIT_HR_DATA,
} from 'modules/HRPage/actions';

const initialState = fromJS({
  totalCostData: [],
  totalHoursData: [],
  turnover: { datasets: {} },
  lastUpdated: '',
  error: '',
  status: STATUS_NOT_REQUESTED,
});

export const HRPage = (state = initialState, action) => {
  switch (action.type) {
    case INIT_HR_DATA:
      return initialState;
    case GET_HR_DATA_REQUEST: {
      return initialState.setIn(['status'], STATUS_LOADING);
    }
    case GET_HR_DATA_SUCCESS: {
      const { totalcost, totalheures, turnover, lastUpdated } = action.payload;
      return state
        .setIn(['totalCostData'], fromJS(totalcost))
        .setIn(['totalHoursData'], fromJS(totalheures))
        .setIn(['turnover'], fromJS(turnover))
        .setIn(['lastUpdated'], lastUpdated)
        .setIn(['status'], STATUS_SUCCESS);
    }
    case GET_HR_DATA_ERROR: {
      const { error } = action.payload;
      return state
        .setIn(['status'], STATUS_ERROR)
        .setIn(['error'], error)
        .setIn(['totalCostData'], fromJS([]))
        .setIn(['totalHoursData'], fromJS([]))
        .setIn(['turnover'], fromJS({ datasets: {} }));
    }
    default:
      return state;
  }
};
