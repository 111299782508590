import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import { Switch } from 'antd';
import Styles from './YearMonthSwitch.module.css';

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const propTypes = {
  activeOption: PropTypes.oneOf([YEAR, MONTH]).isRequired,
  setActiveOption: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const YearMonthSwitch = ({
  activeOption,
  setActiveOption,
  className,
}) => {
  const toggleYearMonth = useCallback(
    (checked) => {
      setActiveOption(checked ? MONTH : YEAR);
    },
    [setActiveOption]
  );

  return (
    <div className={clsx(Styles.wrapper, className)}>
      <div
        className={clsx(Styles.itemText, {
          [Styles.active]: activeOption === YEAR,
        })}
      >
        {YEAR}
      </div>
      <Switch
        className={Styles.switch}
        checked={activeOption === MONTH}
        onChange={toggleYearMonth}
      />
      <div
        className={clsx(Styles.itemText, {
          [Styles.active]: activeOption === MONTH,
        })}
      >
        {MONTH}
      </div>
    </div>
  );
};

YearMonthSwitch.propTypes = propTypes;
