import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from 'assets/images/LOGO.svg';
import clsx from 'clsx';
import { ROUTES } from 'const';
import Styles from './Logo.module.css';

const propTypes = { className: PropTypes.string };

export const Logo = ({ className }) => (
  <Link to={ROUTES.ROOT} className={clsx(Styles.logoLink, className)}>
    <img src={logo} alt="Exco logo" />
  </Link>
);

Logo.propTypes = propTypes;
