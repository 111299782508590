import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Table, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { getToolTypeText } from 'modules/SalaryModule/Rewards/utils';
import { COMPANY_SETTINGS_REWARDS_DETAIL } from '../../../../../const/translations/SalaryCompanySettings';
import { updateRewardToFamily } from '../../../../../modules/SalaryModule/Rewards/actions';
import { ControlsCell } from './ControlsCell';
import Styles from './RewardsTable.module.css';
import { EditableCell } from './EditableCell';
import { EditableContext } from './context';

const propTypes = {
  rewardsList: PropTypes.array,
  onRemoveRewardsItem: PropTypes.func,
  form: PropTypes.object,
  companyId: PropTypes.string,
};

const INPUT_MAX_LENGTH = 40;
const ITEM_PER_PAGE = 20;
const { TABLE } = COMPANY_SETTINGS_REWARDS_DETAIL;

const getRewardTableColumns = (
  isEditing,
  cancelEditHandler,
  editingKey,
  editHandler,
  onRemoveRewardsItem,
  saveEditHandler
) => [
  {
    title: TABLE.TOOL,
    dataIndex: ['reward', 'tool'],
    key: 'tool',
    width: '100px',
    className: Styles.customColumn,
    render: (value) => getToolTypeText(value) || TABLE.TOOL_EMPTY,
  },
  {
    title: TABLE.CODE,
    dataIndex: ['reward', 'code'],
    key: 'code',
    width: '100px',
    className: Styles.customColumn,
  },
  {
    title: TABLE.TITLE,
    dataIndex: ['reward', 'name'],
    key: 'title',
    ellipsis: true,
    editable: true,
  },
  {
    title: TABLE.TYPE,
    dataIndex: ['reward', 'type'],
    key: 'type',
    width: '130px',
    editable: true,
  },
  {
    title: TABLE.ACTIONS,
    key: 'actions',
    width: '80px',
    fixed: 'right',
    render: (_, record) => {
      const editable = isEditing(record);

      return (
        <ControlsCell
          cancelEditHandler={cancelEditHandler}
          editable={editable}
          canBeEdited={editingKey === ''}
          editHandler={editHandler}
          onRemoveRewardsItem={onRemoveRewardsItem}
          saveEditHandler={saveEditHandler}
          record={record}
        />
      );
    },
  },
];

const RewardsTable = ({
  companyId,
  rewardsList,
  onRemoveRewardsItem,
  form,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const dispatch = useDispatch();

  const isEditing = useCallback(({ id }) => id === editingKey, [editingKey]);

  const cancelEditHandler = useCallback(() => {
    setEditingKey('');
  }, []);

  const saveEditHandler = useCallback(
    (e) => {
      const { id, family } = e.currentTarget.dataset;
      const { type, name } = form.getFieldsValue();
      if (type && name?.length <= INPUT_MAX_LENGTH) {
        dispatch(
          updateRewardToFamily({
            companyId,
            rewardId: id,
            familyId: family,
            data: {
              customType: type,
              customName: name,
            },
          })
        );
        setEditingKey('');
      }
    },
    [form, companyId, dispatch]
  );

  const initiateEdit = useCallback((e) => {
    const { id } = e.currentTarget.dataset;
    setEditingKey(id);
  }, []);

  const columns = useMemo(
    () =>
      getRewardTableColumns(
        isEditing,
        cancelEditHandler,
        editingKey,
        initiateEdit,
        onRemoveRewardsItem,
        saveEditHandler
      ),
    [
      cancelEditHandler,
      initiateEdit,
      editingKey,
      isEditing,
      onRemoveRewardsItem,
      saveEditHandler,
    ]
  );

  const renderColumns = useMemo(
    () =>
      columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            form,
            inputType: col.dataIndex.includes('type') ? 'select' : 'text',
            dataIndex: col.dataIndex,
            editing: isEditing(record),
            inputMaxLength: INPUT_MAX_LENGTH,
          }),
        };
      }),
    [columns, form, isEditing]
  );

  return (
    <EditableContext.Provider value={form}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        className={clsx(
          MainStyles.table,
          MainStyles.tableCustomRow,
          Styles.table
        )}
        dataSource={rewardsList}
        columns={renderColumns}
        rowKey="id"
        rowClassName="editable-row"
        pagination={{
          pageSize: ITEM_PER_PAGE,
          hideOnSinglePage: true,
          onChange: cancelEditHandler,
        }}
        scroll={{ x: 480 }}
      />
    </EditableContext.Provider>
  );
};

RewardsTable.propTypes = propTypes;

export const EditableRewardsTable = Form.create()(RewardsTable);
