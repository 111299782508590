import React from 'react';
import { Select } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';

/**
 * Adaptor imitates emitting of event on select change
 * instead of simple value
 */
const SelectAdapted = ({ onChange, name, ...props }) => {
  const handleChange = React.useCallback(
    (value) => onChange({ target: { name, value } }),
    [name, onChange]
  );
  return <Select {...props} onChange={handleChange} />;
};

SelectAdapted.propTypes = formikOnChangeAdaptorTypes;

export { SelectAdapted };
