import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import leftOutlined from '@iconify/icons-ant-design/left-outlined';
import rightOutlined from '@iconify/icons-ant-design/right-outlined';
import { MONTHS } from 'const/ui';
import {
  getDisabledButtonTypes,
  getMonthIndexFromName,
  getNextMonthName,
  getPreviousMonthName,
} from 'helpers/monthSelector';
import { Button } from 'components/ui/Button';
import { MONTH_SELECTOR_TRANSLATIONS } from 'const/translations/MonthSelector';
import clsx from 'clsx';
import { useSimpleMedia } from 'hooks/useMedia';
import Styles from './MonthSelector.module.css';

const { MONTH: MONTH_TEXT } = MONTH_SELECTOR_TRANSLATIONS;

const SCREEN_WIDTH_BREAKPOINT_VALUE = 500;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const propTypes = {
  firstMonth: PropTypes.oneOf(MONTHS.map((month) => month.name)),
  setActiveMonthName: PropTypes.func.isRequired,
  activeMonthName: PropTypes.oneOf(MONTHS.map((month) => month.name))
    .isRequired,
  activeYear: PropTypes.number.isRequired,
  setActiveYear: PropTypes.func.isRequired,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  noLabel: PropTypes.bool,
};

export const MonthSelector = ({
  //  MONTH[0].code === January
  firstMonth = MONTHS[0].name,
  setActiveMonthName,
  activeMonthName,
  activeYear,
  setActiveYear,
  className,
  bordered = false,
  noLabel = false,
}) => {
  const [disabledButtonTypesSet, setDisabledButtonTypesSet] = useState(
    new Set()
  );

  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  useEffect(() => {
    setDisabledButtonTypesSet(
      getDisabledButtonTypes(firstMonth, activeMonthName)
    );
  }, [setDisabledButtonTypesSet, firstMonth, activeMonthName]);

  const switchToPrevMonth = useCallback(() => {
    setActiveMonthName((currentMonthName) => {
      const currentMonthIndex = getMonthIndexFromName(currentMonthName);
      if (currentMonthIndex === 0) {
        setActiveYear((prevYear) => Number(prevYear) - 1);
      }
      return getPreviousMonthName(currentMonthIndex);
    });
  }, [setActiveMonthName, setActiveYear]);

  const switchToNextMonth = useCallback(() => {
    setActiveMonthName((currentMonthName) => {
      const currentMonthIndex = getMonthIndexFromName(currentMonthName);
      if (currentMonthIndex === 11) {
        setActiveYear((prevYear) => Number(prevYear) + 1);
      }
      return getNextMonthName(currentMonthIndex);
    });
  }, [setActiveMonthName, setActiveYear]);

  const activeMonthLabel = useMemo(() => {
    const monthObject = MONTHS.find((month) => month.name === activeMonthName);
    if (isMobileVersion) {
      return monthObject.shortLabel;
    }
    return monthObject.label;
  }, [isMobileVersion, activeMonthName]);

  const buttonVariant = bordered ? 'fullTransparent' : 'transparent';

  return (
    <div
      className={clsx(Styles.wrapper, className, {
        [Styles.bordered]: bordered || noLabel,
      })}
    >
      {!bordered && !noLabel && (
        <div className={Styles.labelText}>{MONTH_TEXT} :</div>
      )}
      <div className={clsx(Styles.switchWrapper)}>
        <Button
          variant={buttonVariant}
          paginateSize
          onClick={switchToPrevMonth}
          disabled={disabledButtonTypesSet.has('prev')}
          iconOnly
        >
          <div className={Styles.iconWrapper}>
            <Icon className={Styles.icon} icon={leftOutlined} />
          </div>
        </Button>
        <div className={Styles.dateWrapper}>
          <span className={Styles.monthName}>{activeMonthLabel}</span>
          <span>{activeYear}</span>
        </div>
        <Button
          variant={buttonVariant}
          paginateSize
          onClick={switchToNextMonth}
          disabled={disabledButtonTypesSet.has('next')}
        >
          <div className={Styles.iconWrapper}>
            <Icon className={Styles.icon} icon={rightOutlined} />
          </div>
        </Button>
      </div>
    </div>
  );
};

MonthSelector.propTypes = propTypes;
