import { takeLatest, call, put } from 'redux-saga/effects';
import {
  CONTACT_HR_REQUEST,
  contactHRSuccess,
  contactHRError,
} from 'modules/HRModal/actions';
import { contactHR } from '../services';

function* requestHelpWorker({
  payload: { topic, message, uploadedFiles, onSuccess, onError },
}) {
  try {
    const formData = new FormData();
    uploadedFiles.forEach(({ originFileObj }) =>
      formData.append('files', originFileObj)
    );
    formData.append('topic', topic);
    formData.append('message', message);
    yield call(contactHR, formData);
    yield put(contactHRSuccess());
    yield onSuccess();
  } catch (error) {
    yield put(contactHRError({ error }));
    yield onError(error);
  }
}

export function* contactHRMainSaga() {
  yield takeLatest(CONTACT_HR_REQUEST, requestHelpWorker);
}
