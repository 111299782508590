import MainStyles from 'assets/styles/main.module.css';
import { List, Icon, Input, Pagination, Typography } from 'antd';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../const';
import { COMPANY_SETTINGS } from '../../../../const/translations/SalaryCompanySettings';
import { useSortHook } from '../../../../modules/SalaryModule/CompanySettings/hooks/useSort.hook';
import { selectScheduleDetailData } from '../../../../modules/SalaryModule/CompanySettings/selectors';
import Styles from './CompanySettingsStaff.module.css';
import { formatNameUpperLastName } from '../../../../modules/SalaryModule/Employees/utils';
const { Text } = Typography;

const ITEM_PER_PAGE = 10;

export const CompanySettingsStaff = memo(() => {
  const scheduleData = useSelector(selectScheduleDetailData);
  const [staffList, setStaffList] = useState([]);
  const {
    sortedData,
    page,
    setPage,
    search,
    setSearch,
    currentDataLength,
  } = useSortHook(staffList, ITEM_PER_PAGE);

  useEffect(() => {
    if (scheduleData && scheduleData.employees) {
      setStaffList(scheduleData.employees);
    }
  }, [scheduleData]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setPage(pageNumber);
    },
    [setPage]
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Text className={Styles.titleSearch}>
        {COMPANY_SETTINGS.SCHEDULE_FORM.STAFF_LIST_TITLE} ({staffList.length})
      </Text>
      {staffList.length ? (
        <>
          <Input
            className={Styles.search}
            prefix={
              <Icon type="search" style={{ color: 'var(--linesList)' }} />
            }
            size="large"
            placeholder={COMPANY_SETTINGS.SCHEDULE_FORM.SEARCH_PLACEHOLDER}
            onChange={handleSearchChange}
            value={search}
            allowClear
          />
          <List
            className={clsx(MainStyles.listItems, Styles.listItems)}
            itemLayout="horizontal"
            dataSource={sortedData}
            renderItem={(item) => (
              <List.Item className={MainStyles.link}>
                {formatNameUpperLastName(item.firstName, item.lastName)}
              </List.Item>
            )}
          />
          <div className={Styles.paginationWrapper}>
            {currentDataLength > ITEM_PER_PAGE && (
              <Pagination
                className={MainStyles.pagination}
                defaultCurrent={page}
                total={currentDataLength}
                onChange={handlePageChange}
                pageSize={ITEM_PER_PAGE}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <Text className={Styles.staffEmptyText}>
            {COMPANY_SETTINGS.SCHEDULE_FORM.EMPTY_STAFF_TEXT}
          </Text>
          <NavLink
            exact
            to={ROUTES.SALARIES_EMPLOYEES}
            className={Styles.staffEmptyLink}
          >
            {COMPANY_SETTINGS.SCHEDULE_FORM.STAFF_LIST_EMPTY_LINK}
          </NavLink>
        </>
      )}
    </>
  );
});
