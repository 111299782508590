import { CART_PAGE_DATASET_KEY } from 'const/cartPage';
import {
  ALL_MODULES_OF_EACH_PACKAGE,
  PACKAGES_DEPENDENCIES,
} from 'const/packages';
import { groupObjectsByProperty } from 'modules/additionalUsers/helpers';
import { APP_MODULES } from 'const';
import { getFromStorage, setToStorage } from 'helpers/storage';

export const getUniqueSimpleArray = (array) => [...new Set(array)];

const filterModulesFromPayModules = (modules) =>
  modules.filter(
    (module) =>
      ![APP_MODULES.APPROVE_INVOICE, APP_MODULES.READONLY_INVOICE].includes(
        module
      )
  );

// attaches recently added packages to each additional user in stored in localstorage dataset except of current packages in cart
export const addNewPackagesToCachedAdditionalUsersInCart = (
  addedPackages,
  currentCartPackages
) => {
  const filteredAddedPackages = addedPackages.filter(
    (pack) => !currentCartPackages.includes(pack)
  );
  const modulesFromPackagesToAdd = filteredAddedPackages.reduce((acc, pack) => {
    if (ALL_MODULES_OF_EACH_PACKAGE[pack]) {
      return getUniqueSimpleArray([
        ...acc,
        ...ALL_MODULES_OF_EACH_PACKAGE[pack],
      ]);
    }
    return acc;
  }, []);

  const filteredModulesToAdd = filterModulesFromPayModules(
    modulesFromPackagesToAdd
  );

  const localStorageInfo = getFromStorage({ key: CART_PAGE_DATASET_KEY });
  if (!localStorageInfo) {
    return;
  }
  const cachedAdditionalUsers = JSON.parse(localStorageInfo);
  const additionalUsersWithAddedPackages = Object.entries(
    cachedAdditionalUsers
  ).reduce((acc, [userId, values]) => {
    if (!values?.tableModules) return acc;
    const newUserPackages = getUniqueSimpleArray([
      ...values.tableModules,
      ...filteredModulesToAdd,
    ]);
    acc[userId] = {
      ...values,
      tableModules: newUserPackages,
    };
    return acc;
  }, {});

  setToStorage({
    key: CART_PAGE_DATASET_KEY,
    value: additionalUsersWithAddedPackages,
  });
};

// some packages have dependencies they cant be subscribed without
// function returns package with it`s dependency to add it to cart, if dependency was not subscribed yet
export const getAddedPackagesFromDependencies = (
  packageName,
  currentUserPackages
) => {
  const dependenciesPackagesArray = Object.entries(PACKAGES_DEPENDENCIES);
  return dependenciesPackagesArray.reduce(
    (acc, [mainPackage, dependencies]) => {
      if (
        dependencies.includes(packageName) &&
        !currentUserPackages.includes(mainPackage)
      ) {
        return [mainPackage, ...acc];
      }
      return acc;
    },
    [packageName]
  );
};

export const isSameArray = (array1, array2) =>
  array1.length === array2.length &&
  array1.every((item) => array2.includes(item));

// validates whether additional users has changed from last request
// function compares previous store additional users object with current one
export const validateAdditionalUsers = (
  prevAdditionalUsers,
  currentAdditionalUsers
) => {
  if (prevAdditionalUsers.length !== currentAdditionalUsers.length) {
    return false;
  }
  const prevUsersObj = groupObjectsByProperty(prevAdditionalUsers);
  const isTheSame = currentAdditionalUsers.reduce((acc, { modules, id }) => {
    if (prevUsersObj[id] && isSameArray(modules, prevUsersObj[id].modules)) {
      return true;
    }
    return false;
  }, true);
  if (!isTheSame) {
    return false;
  }
  return true;
};
