/**
 * localStorage\sessionStorage wrapper to get saved items.
 * @param {object} options - The options object.
 * @param {string} options.key - The item key used to retrieve the value from storage.
 * @param {boolean} [options.shouldBeParsed=false] - The flag showing should the value be returned as object.
 * @param {Storage} [options.storage=localStorage] - The storage to work with.
 * @returns {string | string[] | Record<string, string> | null}
 */
export const getFromStorage = ({
  key,
  shouldBeParsed = false,
  storage = localStorage,
}) => {
  const value = storage.getItem(key) || null;

  if (value !== null && shouldBeParsed) {
    return JSON.parse(value);
  }

  return value;
};

/**
 * localStorage\sessionStorage wrapper to set items.
 * @param {object} options - The options object.
 * @param {string} options.key - The item key used to retrieve the value from storage.
 * @param {string} options.value - The item value retrieved from storage.
 * @param {Storage} [options.storage=localStorage] - The storage to work with.
 * @returns {void}
 */
export const setToStorage = ({ key, value, storage = localStorage }) => {
  if (typeof value !== 'object') {
    return storage.setItem(key, value);
  }

  return storage.setItem(key, JSON.stringify(value));
};

/**
 * localStorage\sessionStorage wrapper to remove items.
 * @param {object} options - The options object.
 * @param {string | string[]} options.key - The item key used to retrieve the value from storage.
 * @param {Storage} [options.storage=localStorage] - The storage to work with.
 *
 * @returns {void}
 */
export const removeFromStorage = ({ key, storage = localStorage }) => {
  if (!Array.isArray(key)) {
    return storage.removeItem(key);
  }

  return key.forEach((item) => storage.removeItem(item));
};
