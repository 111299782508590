import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { Icon } from 'antd';
import { TableAction } from 'components/ui/TableAction';
import { MODAL_TYPES } from 'const/ModalContainer';
import { openModal } from 'modules/ModalContainer/actions';
import { useInvoiceComments } from 'helpers/InvoiceSearchCommentaryModal';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';

const {
  TOOLTIP: { LEAVE_A_COMMENT },
} = INVOICE_SEARCH_TRANSLATIONS;

export const AddCommentAction = () => {
  const dispatch = useDispatch();

  const { documentId, canValidate, canPay } = useInvoiceActionsData();
  const currentInvoiceComments = useInvoiceComments(documentId);

  const handleOpenCommentModal = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPES.invoiceSearchCommentaryModal,
        modalProps: { documentId },
      })
    );
  }, [dispatch, documentId]);

  if (!canValidate && !canPay) {
    return null;
  }

  return (
    <TableAction
      tooltipProps={{
        title: LEAVE_A_COMMENT,
        arrowPointAtCenter: true,
        placement: 'topRight',
      }}
      displayComponent={
        <Icon
          type="message"
          theme={currentInvoiceComments.length ? 'filled' : 'outlined'}
          className={Styles.Icon}
          onClick={handleOpenCommentModal}
        />
      }
    />
  );
};
