import { Typography, Menu, Icon } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { REWARDS_PAGE } from 'const/translations/SalaryRewardsPage';
import { REWARDS_GENERAL_TAB } from 'const/SalaryRewardsPage';
import { CustomSelect } from 'components/ui/CustomSelect';
import Styles from '../RewardsToolbar.module.css';

const propTypes = {
  isMobileVersion: PropTypes.bool,
  activeFamily: PropTypes.string,
  setActiveFamily: PropTypes.func,
  families: PropTypes.array.isRequired,
};

export const RewardsFamiliesTabs = memo(
  ({ isMobileVersion, activeFamily, setActiveFamily, families = [] }) => {
    const handleFamilyChange = useCallback(
      ({ key }) => {
        setActiveFamily(key);
      },
      [setActiveFamily]
    );
    const handleFamilySelectChange = useCallback(
      (_, data) => {
        setActiveFamily(data);
      },
      [setActiveFamily]
    );

    const tabsMore = (
      <div className={Styles.moreBtn}>
        <span>{REWARDS_PAGE.MORE_BTN_TEXT}</span>
        <Icon type="down" className={Styles.moreBtnArrow} />
      </div>
    );

    const filteredFamilies = useMemo(
      () =>
        families.filter(({ rewardsCount }) => rewardsCount && rewardsCount > 0),
      [families]
    );

    return (
      <>
        {isMobileVersion ? (
          <div className={Styles.chooseWrapper}>
            <Typography.Text className={Styles.chooseTitle}>
              {REWARDS_PAGE.FAMILIES_TITLE_MOBILE} ({filteredFamilies.length}):
            </Typography.Text>
            <CustomSelect
              size="large"
              name="primes"
              className={Styles.select}
              options={[
                {
                  value: REWARDS_GENERAL_TAB.ID,
                  label: REWARDS_GENERAL_TAB.TITLE,
                },
                ...filteredFamilies.map(({ id, title }) => ({
                  value: id,
                  label: title,
                })),
              ]}
              onChange={handleFamilySelectChange}
              value={activeFamily}
              placeholder="primes"
              allowClear
            />
          </div>
        ) : (
          <Menu
            overflowedIndicator={tabsMore}
            onClick={handleFamilyChange}
            selectedKeys={activeFamily}
            mode="horizontal"
            className={Styles.menuTabs}
            popupClassName={Styles.menuTabsPopup}
          >
            <Menu.Item key={REWARDS_GENERAL_TAB.ID}>
              {REWARDS_GENERAL_TAB.TITLE}
            </Menu.Item>
            {filteredFamilies.map(({ id, title }) => (
              <Menu.Item key={id}>{title}</Menu.Item>
            ))}
          </Menu>
        )}
      </>
    );
  }
);

RewardsFamiliesTabs.propTypes = propTypes;
