import React from 'react';
import PropTypes from 'prop-types';
import Styles from './LandingAdvantage.module.css';

const propTypes = {
  image: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
};

export const LandingAdvantage = ({ image, title, description }) => (
  <div className={Styles.wrapper}>
    <img className={Styles.image} src={image} alt="test" />
    <div className={Styles.textWrapper}>
      <h4 className={Styles.title}>{title}</h4>
      <div className={Styles.description}>{description}</div>
    </div>
  </div>
);

LandingAdvantage.propTypes = propTypes;
