import { getType, getActionCreator } from 'utils';
const prefix = '@@app/Salary';

export const SET_MODAL_VISIBILITY = getType(prefix, 'SET_MODAL_VISIBILITY');
export const setModalVisibility = getActionCreator(SET_MODAL_VISIBILITY);

export const SEND_EXCO_INFO_REQUEST = getType(prefix, 'SEND_EXCO_INFO_REQUEST');
export const sendExcoInfoRequest = getActionCreator(SEND_EXCO_INFO_REQUEST);

export const SEND_EXCO_INFO_SUCCESS = getType(prefix, 'SEND_EXCO_INFO_SUCCESS');
export const sendExcoInfoSuccess = getActionCreator(SEND_EXCO_INFO_SUCCESS);

export const SEND_EXCO_INFO_ERROR = getType(prefix, 'SEND_EXCO_INFO_ERROR');
export const sendExcoInfoError = getActionCreator(SEND_EXCO_INFO_ERROR);

export const GET_EXCO_INFO_MODAL_DATA_REQUEST = getType(
  prefix,
  'GET_EXCO_INFO_MODAL_DATA_REQUEST'
);
export const getExcoInfoModalDataRequest = getActionCreator(
  GET_EXCO_INFO_MODAL_DATA_REQUEST
);

export const GET_EXCO_INFO_MODAL_DATA_SUCCESS = getType(
  prefix,
  'GET_EXCO_INFO_MODAL_DATA_SUCCESS'
);
export const getExcoInfoModalDataSuccess = getActionCreator(
  GET_EXCO_INFO_MODAL_DATA_SUCCESS
);

export const RESET_EXCO_INFO_MODAL_DATA = getType(
  prefix,
  'RESET_EXCO_INFO_MODAL_DATA'
);
export const resetExcoInfoModalData = getActionCreator(
  RESET_EXCO_INFO_MODAL_DATA
);
