/**
 * Email name structure: 'EMAIL_{REDUX NAME}_{DB NAME}
 */
export const CONTACTS_PAGE_TRANSLATIONS = {
  TITLE: 'Mes contacts',
  EMAIL_HR_MANAGER_COLRH: 'Collaborateur GRH',
  EMAIL_ACCOUNTANT_COLLAB: 'Collaborateur comptable',
  EMAIL_EXPERT_EXPERT: 'Expert comptable',
  EMAIL_ASSISTANT: 'Assistant',
};
