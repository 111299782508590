import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Styles from './Legends.module.css';

const propTypes = {
  chartData: PropTypes.array.isRequired,
  hiddenLinesYears: PropTypes.array.isRequired,
  setHiddenLineYears: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const Legends = ({
  chartData,
  hiddenLinesYears,
  setHiddenLineYears,
  className,
}) => {
  const changeableChartLegends = useMemo(() => chartData.slice(1), [chartData]);

  const toggleHiddenYearCreator = (year) => () => {
    setHiddenLineYears((prev) => {
      if (prev.includes(year)) {
        return prev.filter((hiddenYear) => hiddenYear !== year);
      }
      return [...prev, year];
    });
  };
  return (
    <div className={clsx(Styles.wrapper, className)}>
      {chartData.length > 0 && (
        <div className={Styles.chartLegends}>
          <div className={clsx(Styles.legend, Styles.legend0)}>
            {chartData[0].year}
          </div>
          {changeableChartLegends.map(({ year }, index) => (
            <div
              key={year}
              className={clsx(Styles.legend, Styles[`legend${index + 1}`], {
                [Styles.hidden]: hiddenLinesYears.includes(year),
              })}
              onClick={toggleHiddenYearCreator(year)}
            >
              {year}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Legends.propTypes = propTypes;
