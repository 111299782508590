import React from 'react';
import { TERMS_OF_USE_TEXT } from 'const/TermsOfUseTexts/TermsOfUse';
import { TERMS_OF_SUBSCRIPTION_TEXT } from 'const/TermsOfUseTexts/TermsOfSubscription';

export const TERMS_OF_USE_TRANSLATIONS = {
  BACK_TO_HOMEPAGE: 'Accueil',
  TERMS_TEXT: TERMS_OF_USE_TEXT,
  TITLE: 'Conditions Générales d’Utilisation My Exco',
  AGREE_BUTTON: 'J’accepte',
  DECLINE_BUTTON: 'Je refuse',
  CONFIRM_MODAL_TITLE:
    'Le refus des Conditions Générales d’Utilisation entraînera votre déconnexion immédiate, êtes-vous sûr ?',
  OK_BUTTON: 'Oui',
  CANCEL_BUTTON: 'Non',
};

export const REGISTERED_TERMS_TRANSLATIONS = {
  MODAL_TITLE: 'Bienvenue dans votre  Espace My Exco',
  OPTIONS_TITLE: 'Je profite de 3 mois d’essai offert :',
  SUBTITLE_DESCRIPTION: (
    <span>
      Dès aujourd&apos;hui, simplifiez-vous le quotidien en retrouvant sur une
      plateforme unique l&apos;ensemble de ces fonctionnalités&nbsp;:
    </span>
  ),
  SUBTITLE_MY_EXCO:
    'Pendant les 3 premiers mois, profitez également du bouquet My Exco :',
  REGISTERED_USAGE_TERMS: TERMS_OF_USE_TEXT,
  REGISTERED_USAGE_TERMS_TITLE: 'Conditions Générales d’Utilisation My Exco',
  REGISTERED_SUBSCRIPTION_TERMS: TERMS_OF_SUBSCRIPTION_TEXT,
  REGISTERED_SUBSCRIPTION_TERMS_TITLE:
    'Conditions Générales d’Abonnement My Exco',
  AGREE_BUTTON: 'Je démarre',
  COST_NOTIFICATION:
    '3 premiers mois à partir de la date de souscription. Au-delà des 3 mois, les services du bouquet My Exco seront facturés 9 € HT / mois.',
  FAQ_TITLE: 'Questions fréquentes :',
};

export const DESCRIPTION_POINTS = [
  {
    id: '1',
    optionText: 'Transmission dématérialisée de vos documents',
  },
  {
    id: '2',
    optionText:
      'Consultation de vos documents numérisés dans votre espace partagé et sécurisé (comptes annuels, statuts, etc.)',
  },
  {
    id: '3',
    optionText:
      'Accès à toutes vos factures en un clic grâce à un moteur de recherche puissant',
  },
  {
    id: '4',
    optionText:
      'Suivi de vos indicateurs de gestion et de votre trésorerie (sur demande)',
  },
  {
    id: '5',
    optionText:
      'Accès à des modules complémentaires en fonction de vos besoins dans le STORE My Exco',
    options: [
      {
        id: '1',
        optionText: (
          <span>
            <b>FACT</b> : Solution pour réaliser vos devis, factures, relances
            et suivi de vos indicateurs commerciaux
          </span>
        ),
      },
      {
        id: '2',
        optionText: (
          <span>
            <b>PAY</b> : Solution sécurisée de délégation de vos paiements
            fournisseurs sans vous connecter à votre banque
          </span>
        ),
      },
    ],
  },
];

export const MY_EXCO_POINTS = [
  {
    id: '1',
    optionText: 'Moteur de recherche multicritères',
  },
  {
    id: '2',
    optionText: 'Indicateurs de gestion et RH en temps réel',
  },
  {
    id: '3',
    optionText: 'Suivi de trésorerie',
  },
];

export const FAQ_ITEMS = [
  {
    id: '1',
    question:
      'L’entreprise sera-t-elle débité(e) pendant ma période d’essai gratuit ?',
    answer:
      'Non, vous ne serez pas débité(e) pendant cette période qui démarre aujourd’hui.',
  },
  {
    id: '2',
    question: 'Comment puis-je me désinscrire pendant ma période d’essai ?',
    answer:
      'Une fois identifié, rendez-vous dans le menu de désinscription pour vous désinscrire. A noter, vous n’aurez alors accès qu’aux fonctions de partage, mais plus à celles de pilotage.',
  },
  {
    id: '3',
    question: 'Que se passe-t-il une fois l`essai terminé ?',
    answer:
      'Si vous ne vous désinscrivez pas, vous serez automatiquement facturé pour votre abonnement.',
  },
];

export const REGISTERED_TERMS_CHECKBOX = {
  CHECKBOX_1_TEXT: 'J’accepte les',
  CHECKBOX_2_LINK: 'conditions générales d’abonnement',
  CHECKBOX_3_TEXT: 'et les',
  CHECKBOX_4_LINK: 'conditions générales d’utilisation',
};
