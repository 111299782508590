import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import { SectionTitle } from 'components/Section/SectionTitle';
import { Button } from 'components/ui/Button';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { APP_MODULES } from 'const/index';
import {
  selectUserAdditionalUsers,
  selectUserPackagesNames,
  selectUserAuthorizedModules,
  selectUserEmail,
} from 'modules/loggedUserInfo/selectors';
import { AdditionalUserModal } from 'components/AdditionalUserModal/AdditionalUserModal';
import { saveAdditionalUsersRequest } from 'modules/additionalUsers/actions';
import { AdditionalUsersTable } from 'components/AdditionalUsersTable/AdditionalUsersTable';
import { ADDITIONAL_USERS_PAGE_TRANSLATIONS } from 'const/translations/AdditionalUsers';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';
import { useAdditionalUsersTable } from 'hooks/useAdditionalUsersTable';
import {
  CART_PAGE_DATASET_KEY,
  CART_PAGE_DELETED_USERS_KEY,
  INITIAL_DATASET_LOCAL_STORAGE_KEY,
  USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
} from 'const/cartPage';
import {
  getUniqueSimpleArray,
  isSameArray,
  validateAdditionalUsers,
} from 'modules/cart/helpers';
import { clearCart, resetCartAndAdditionalUsers } from 'modules/cart/actions';
import { selectShouldCartBeReseted } from 'modules/cart/selectors';
import { Spinner } from 'components/Spinner';
import {
  ALL_MODULES_OF_EACH_PACKAGE,
  getModulesForPurchase,
} from 'const/packages';
import { openSuccessNotification } from 'helpers/notifications';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';
import Styles from './AdditionalUsers.module.css';

const { DATASET_KEY, DELETED_USERS_KEY } = ADDITIONAL_USERS_LOCAL_STORAGE_KEYS;

const {
  ADD_USER_BUTTON,
  NOTIFICATION_MESSAGE,
  PAGE_TITLE,
} = ADDITIONAL_USERS_PAGE_TRANSLATIONS;

const initialUserModalValues = {
  name: '',
  surname: '',
  email: '',
  position: '',
  tableModules: [],
  isUserNew: true,
};

export const AdditionalUsers = () => {
  const additionalUsers = useSelector(selectUserAdditionalUsers);
  const currentUserEmail = useSelector(selectUserEmail);

  const packages = useSelector(selectUserPackagesNames);
  const activePackages = useSelector(selectUserAuthorizedModules);
  const isEvp = Boolean(activePackages.find((p) => p === APP_MODULES.EVP));
  const isDashboardFin = Boolean(
    activePackages.find((p) => p === APP_MODULES.DASHBOARD_FIN)
  );

  const additionalUsersPossibleModules = useMemo(
    () =>
      packages.reduce(
        (acc, pack) => {
          const packageModules =
            getModulesForPurchase(false, isDashboardFin)[pack] || [];
          return getUniqueSimpleArray([...acc, ...packageModules]);
        },
        isEvp
          ? [APP_MODULES.EVP, ...ALL_MODULES_OF_EACH_PACKAGE.FREE_MODULES]
          : ALL_MODULES_OF_EACH_PACKAGE.FREE_MODULES
      ),
    [isEvp, packages, isDashboardFin]
  );

  const {
    resetChanges,
    initialAdditionalUsersDataset,
    addNewUserToTable,
    removeUserFromDataset,
    deletedUsers,
    unsynchronizedUserDataset,
  } = useAdditionalUsersTable(additionalUsers, DATASET_KEY, DELETED_USERS_KEY);

  const [isSaveChangesButtonEnabled, setIsChangesButtonEnabled] = useState(
    false
  );
  useEffect(() => {
    setIsChangesButtonEnabled(
      Object.values(unsynchronizedUserDataset).some(
        ({ touched, isUserNew }) => !!touched || isUserNew
      ) || deletedUsers.length > 0
    );
  }, [unsynchronizedUserDataset, setIsChangesButtonEnabled, deletedUsers]);

  const [initialModalFormValues, setInitialModalFormValues] = useState(
    initialUserModalValues
  );
  const resetInitialModalFormValues = useCallback(() => {
    setInitialModalFormValues(initialUserModalValues);
  }, [setInitialModalFormValues]);

  const [isModalVisible, setModalVisibility] = useState(false);

  const toggleModalVisibility = useCallback(() => {
    setModalVisibility((prev) => !prev);
  }, [setModalVisibility]);

  const initiateCreateAdditionalUserModal = useCallback(() => {
    toggleModalVisibility();
  }, [toggleModalVisibility]);

  const onSubmitSuccess = useCallback(() => {
    openSuccessNotification({
      message: NOTIFICATION_MESSAGE,
    });
  }, []);

  const onCreateUserSubmit = useCallback(
    (values) => {
      addNewUserToTable(values);
      toggleModalVisibility();
    },
    [toggleModalVisibility, addNewUserToTable]
  );

  const dispatch = useDispatch();

  const onSaveChanges = useCallback(
    (values, { resetForm }) => {
      resetChanges();
      resetForm(initialAdditionalUsersDataset);
      dispatch(
        saveAdditionalUsersRequest({
          onSuccess: onSubmitSuccess,
          currentUsers: values,
          deletedUsers,
        })
      );
    },
    [
      dispatch,
      onSubmitSuccess,
      deletedUsers,
      initialAdditionalUsersDataset,
      resetChanges,
    ]
  );

  const maxUserCountReached = useMemo(
    () => Object.keys(unsynchronizedUserDataset)?.length > 4,
    [unsynchronizedUserDataset]
  );

  const userCurrentPackages = useSelector(selectUserPackagesNames);

  /*
   * useEffect checks whether incoming from backend userPackages or additional users has changed
   *                since last request and if it has, then cart and additional users localstorage and current data resets
   * */
  useEffect(() => {
    let shouldAddUsersBeReseted = false;
    const savedInitialDataset = getFromStorage({
      key: INITIAL_DATASET_LOCAL_STORAGE_KEY,
    });
    if (
      savedInitialDataset &&
      !validateAdditionalUsers(JSON.parse(savedInitialDataset), additionalUsers)
    ) {
      shouldAddUsersBeReseted = true;
    }
    const savedUserPackages = getFromStorage({
      key: USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
    });
    if (
      savedUserPackages &&
      !isSameArray(userCurrentPackages, JSON.parse(savedUserPackages))
    ) {
      shouldAddUsersBeReseted = true;
    }
    setToStorage({
      key: INITIAL_DATASET_LOCAL_STORAGE_KEY,
      value: additionalUsers,
    });
    setToStorage({
      key: USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
      value: userCurrentPackages,
    });
    if (shouldAddUsersBeReseted) {
      dispatch(resetCartAndAdditionalUsers({ value: true }));
    }
  }, [additionalUsers, userCurrentPackages, dispatch]);

  const shouldAddUsersBeReseted = useSelector(selectShouldCartBeReseted);

  useEffect(() => {
    if (shouldAddUsersBeReseted) {
      dispatch(clearCart());
      resetChanges();
      removeFromStorage({
        key: [CART_PAGE_DATASET_KEY, CART_PAGE_DELETED_USERS_KEY],
      });
      dispatch(resetCartAndAdditionalUsers({ value: false }));
    }
  }, [shouldAddUsersBeReseted, dispatch, resetChanges]);

  if (shouldAddUsersBeReseted) {
    return <Spinner />;
  }

  return (
    <div className={Styles.background}>
      <div className={clsx(MainStyles.container, Styles.pageWrapper)}>
        <div className={Styles.pageHeader}>
          <SectionTitle title={PAGE_TITLE} />
          <Button
            variant="success"
            disabled={maxUserCountReached}
            onClick={initiateCreateAdditionalUserModal}
          >
            {ADD_USER_BUTTON}
          </Button>
        </div>
      </div>
      <div className={clsx(MainStyles.container, Styles.contentWrapper)}>
        <AdditionalUsersTable
          unsynchronizedUserDataset={unsynchronizedUserDataset}
          removeUserFromDataset={removeUserFromDataset}
          onSaveChanges={onSaveChanges}
          isSaveChangesButtonEnabled={isSaveChangesButtonEnabled}
          setIsChangesButtonEnabled={setIsChangesButtonEnabled}
          isResetButtonVisible={isSaveChangesButtonEnabled}
          setResetButtonVisibility={setIsChangesButtonEnabled}
          initialDataset={initialAdditionalUsersDataset}
          resetStates={resetChanges}
          datasetLocalStorageKey={DATASET_KEY}
          userPossibleModules={additionalUsersPossibleModules}
          currentUserEmail={currentUserEmail}
        />
      </div>
      <AdditionalUserModal
        initialFormValues={initialModalFormValues}
        resetInitialFormValues={resetInitialModalFormValues}
        isVisible={isModalVisible}
        closeModal={toggleModalVisibility}
        onCreateUserSubmit={onCreateUserSubmit}
        userPossibleModules={additionalUsersPossibleModules}
      />
    </div>
  );
};
