import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Select, Tooltip, Button } from 'antd';
import { useFormikOnChange } from 'hooks';
import Styles from './CustomSelect.module.css';

const propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  hasPaleLabel: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  onDelete: PropTypes.func,
  deletable: PropTypes.bool,
};

/**
 * @param options {array} - array of options objects
 * @param className {string}
 * @param onChange {function}
 * @param name {string} - name of select input
 * @param hasPaleLabel {boolean} - flag that option object has property paleLabel
 *                    (means that label of every option will have 2 parts with different styles :
 *                          label property will be black color and paleLabel will be also black but paler)
 * @param isContentOffset {boolean} - flag if content needs to be wider then select box
 * */
export const CustomSelect = memo(
  ({
    options,
    className,
    onChange,
    name,
    hasPaleLabel = false,
    hasTooltip = false,
    onDelete = null,
    deletable,
    ...passedProps
  }) => {
    const { Option } = Select;
    const { isContentOffset, value } = passedProps;

    const handleChange = useFormikOnChange(name, onChange);
    const formattedValue = useMemo(() => (value !== '' ? value : undefined), [
      value,
    ]);

    const handleDetele = (v) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      onDelete(v);
    };

    return (
      <Select
        className={clsx(Styles.select, className)}
        dropdownClassName={clsx(
          Styles.dropdown,
          `${isContentOffset ? Styles.offsetContent : ''}`
        )}
        {...passedProps}
        value={formattedValue}
        onChange={handleChange}
        optionLabelProp="label"
      >
        {/* if default select without tooltip needed */}
        {!hasTooltip &&
          options?.map((item, optionIndex) => (
            <Option
              key={`${item.value}-${optionIndex}`}
              value={item.value}
              label={item.label}
            >
              {hasPaleLabel ? (
                <div className={Styles.paleLabelWrapper}>
                  <span>{item.label}</span>
                  <span className={Styles.paleLabel}>{item.paleLabel}</span>
                  {!!onDelete && !item.isNew && deletable && (
                    <Button
                      type="link"
                      icon="close"
                      className={Styles.deleteButton}
                      onClick={handleDetele(item.value)}
                    />
                  )}
                </div>
              ) : (
                <div className={Styles.paleLabelWrapper}>
                  <span>{item.label || item.value}</span>
                  {!!onDelete && !item.isNew && deletable && (
                    <Button
                      type="link"
                      icon="close"
                      className={Styles.deleteButton}
                      onClick={handleDetele(item.value)}
                    />
                  )}
                </div>
              )}
            </Option>
          ))}
        {/* if tooltip on each option needed */}
        {hasTooltip &&
          options.length &&
          options?.map((item, optionIndex) => (
            <Option
              key={`${item.value}-${optionIndex}`}
              value={item.value}
              label={item.label}
            >
              <Tooltip
                title={
                  <div className={Styles.selectTooltipWrapper}>
                    {item.tooltipLabelsArray
                      ? item.tooltipLabelsArray.map((label, index, array) => (
                          <span
                            key={label}
                            className={Styles.selectTooltipItem}
                          >
                            {label}
                            {index < array.length - 1 && ' - '}
                          </span>
                        ))
                      : item.label}
                  </div>
                }
                overlayClassName={Styles.tooltip}
                placement="top"
                arrowPointAtCenter
                mouseEnterDelay={0.5}
              >
                {hasPaleLabel ? (
                  <div className={Styles.paleLabelWrapper}>
                    <span>{item.label}</span>
                    <span className={Styles.paleLabel}>{item.paleLabel}</span>
                    {!!onDelete && !item.isNew && deletable && (
                      <Button
                        type="link"
                        icon="close"
                        className={Styles.deleteButton}
                        onClick={handleDetele(item.value)}
                      />
                    )}
                  </div>
                ) : (
                  <div className={Styles.paleLabelWrapper}>
                    <span>{item.label || item.value}</span>
                    {!!onDelete && !item.isNew && deletable && (
                      <Button
                        type="link"
                        icon="close"
                        className={Styles.deleteButton}
                        onClick={handleDetele(item.value)}
                      />
                    )}
                  </div>
                )}
              </Tooltip>
            </Option>
          ))}
      </Select>
    );
  }
);

CustomSelect.propTypes = propTypes;
