export const YEAR_TABLE_DATA_TEMPLATE = [
  {
    key: 1,
    month: 'Janvier',
  },
  {
    key: 2,
    month: 'Février',
  },
  {
    key: 3,
    month: 'Mars',
  },
  {
    key: 4,
    month: 'Avril',
  },
  {
    key: 5,
    month: 'Mai',
  },
  {
    key: 6,
    month: 'Juin',
  },
  {
    key: 7,
    month: 'Juillet',
  },
  {
    key: 8,
    month: 'Aout',
  },
  {
    key: 9,
    month: 'Septembre',
  },
  {
    key: 10,
    month: 'Octobre',
  },
  {
    key: 11,
    month: 'Novembre',
  },
  {
    key: 12,
    month: 'Décembre',
  },
];
