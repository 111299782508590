import { MONTH_SELECTOR_TRANSLATIONS } from 'const/translations/MonthSelector';
import { WEEK_DAYS } from 'const/translations';

const {
  APRIL,
  AUGUST,
  DECEMBER,
  FEBRUARY,
  JANUARY,
  JULY,
  JUNE,
  MARCH,
  MAY,
  NOVEMBER,
  OCTOBER,
  SEPTEMBER,
} = MONTH_SELECTOR_TRANSLATIONS;

const {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} = WEEK_DAYS;

export const MONTHS = [
  { name: 'January', label: JANUARY, shortLabel: 'Janv' },
  { name: 'February', label: FEBRUARY, shortLabel: 'Fevr' },
  { name: 'March', label: MARCH, shortLabel: 'Mar' },
  { name: 'April', label: APRIL, shortLabel: 'Avr' },
  { name: 'May', label: MAY, shortLabel: 'Mai' },
  { name: 'June', label: JUNE, shortLabel: 'Juin' },
  { name: 'July', label: JULY, shortLabel: 'Juil' },
  { name: 'August', label: AUGUST, shortLabel: 'Aout' },
  { name: 'September', label: SEPTEMBER, shortLabel: 'Sept' },
  { name: 'October', label: OCTOBER, shortLabel: 'Oct' },
  { name: 'November', label: NOVEMBER, shortLabel: 'Nov' },
  { name: 'December', label: DECEMBER, shortLabel: 'Dec' },
];

export const DAYS_OF_WEEK = [
  { name: 'Monday', label: MONDAY },
  { name: 'Tuesday', label: TUESDAY },
  { name: 'Wednesday', label: WEDNESDAY },
  { name: 'Thursday', label: THURSDAY },
  { name: 'Friday', label: FRIDAY },
  { name: 'Saturday', label: SATURDAY },
  { name: 'Sunday', label: SUNDAY },
];

export const CHARTS_VIEW_SWITCH_TYPES = {
  BAR: 'bar',
  LINE: 'line',
};
