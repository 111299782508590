import { REWARDS_GENERAL_TAB } from 'const/SalaryRewardsPage';

/**
 * Add separator between digits to number string - `1234567.77`
 * @param { String } value - number string
 * @param { String } separator - separator between value digits
 * @returns {String} formatted value - `1 234 567.77`
 */
export const formatByOrdersOfMagnitude = (value, separator = ' ') =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

/**
 * Parser for antd number input value
 * @param { String } value - current input value
 * @returns {String} parsed value without letters and other symbols
 *e.g  'test23j33' => '2333'
 *      '23.ff3' => '23.3'
 * */
export const inputNumberParser = (value) =>
  value.replace(/[^\d.-]/g, '').replace(/\$\s?|(\s*)/g, '');

/**
 * Formatter for antd number input value
 * @param { String } value - current input value
 * @returns {String} formatted value with chosen separator
 */
export const inputNumberFormatter = (value) => formatByOrdersOfMagnitude(value);

/**
 * Get filter object from employee rewards table filters
 * @param { String } selectedBranch - active branch
 * @param { String } activeFamily - active reward family
 * @param { Object } activeMonth - filter date object
 * @param { String } employeeSearch - filter search by name
 * @param { String } employeeId - row employee id
 * @returns {Object} - formatted object
 */
export const getEmployeeRewardsCsvCommonFilter = (
  selectedBranch = '',
  activeFamily,
  activeMonth,
  employeeSearch = '',
  employeeId = ''
) => ({
  siret: selectedBranch,
  year: activeMonth.year(),
  month: activeMonth.month() + 1,
  employeeId,
  rewardFamilyId:
    activeFamily === REWARDS_GENERAL_TAB.ID ? undefined : activeFamily,
  name: employeeSearch,
});
