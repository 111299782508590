import { LANDING_ACTIONS } from 'const/landingPage';
import { PACKAGES_NAMES } from 'const/packages';

/**
 * Each landing button can have 1 of 2 states, with different text and logic
 * Helper returns button state depending on type of landing page.
 * @param pageName - type of LP
 * @param cartItems - already in store cart
 * @returns {string|null}
 */
export const getCurrentButtonType = ({ pageName, cartItems }) => {
  if (cartItems.includes(pageName)) {
    return LANDING_ACTIONS.VISIT_CART_PAGE;
  }

  if (pageName === PACKAGES_NAMES.WAIBI) {
    return LANDING_ACTIONS.CONTACT_CONSULTANT;
  }

  return LANDING_ACTIONS.ADD_TO_CART;
};
