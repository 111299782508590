import moment from 'moment';
import { COMMON_LABELS } from 'const/translations';
import { convertBytesToMegabytes } from 'helpers/common';

export const mapDocumentsDataToTable = ({ folders = [], files = [] }) => {
  const mappedFolders = folders.map(({ id, name, updatedAt }) => ({
    key: id,
    name,
    date: moment(updatedAt),
    size: null,
  }));
  const mappedFiles = files.map(({ id, name, updatedAt, size }) => ({
    key: id,
    name,
    extension: name.split('.').pop(),
    date: moment(updatedAt),
    size: `${convertBytesToMegabytes(size)} ${COMMON_LABELS.MB_SHORT}`,
  }));
  return mappedFolders.concat(mappedFiles);
};
