import React from 'react';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import checkMark from '@iconify/icons-ant-design/check-outlined';
import PropTypes from 'prop-types';
import Styles from './ChartFilterCheckbox.module.css';

const propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  isMinified: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const ChartFilterCheckbox = ({
  name,
  checked = false,
  toggleChecked,
  className,
  label,
  id,
  isMinified = false,
  disabled = false,
}) => (
  <div
    className={clsx(Styles.checkBox, className, {
      [Styles.isMinified]: isMinified,
      [Styles.disabled]: disabled,
    })}
  >
    <input
      disabled={disabled}
      value={checked}
      name={name}
      onChange={toggleChecked}
      type="checkbox"
      id={id}
    />
    <label htmlFor={id}>
      <div className={Styles.box}>
        <Icon
          className={clsx(Styles.checkMark, {
            [Styles.checked]: checked,
          })}
          icon={checkMark}
        />
      </div>
      <span>{label}</span>
    </label>
  </div>
);

ChartFilterCheckbox.propTypes = propTypes;
