import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { formatByOrdersOfMagnitude } from '../../../../modules/SalaryModule/RewardsTable/utils';
import Styles from './RewardsTable.module.css';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
  onClick: PropTypes.func,
};

export const TextCell = ({ value, suffix, onClick }) => (
  <div className={Styles.customCell} onClick={onClick}>
    <Text>{formatByOrdersOfMagnitude(value.toString())}</Text>
    <Text className={Styles.customCellSuffix}>{suffix}</Text>
  </div>
);

TextCell.propTypes = propTypes;
