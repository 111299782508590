import { request } from 'utils/request';

export const subscribeToPackagesService = ({ modules, additionalUsers }) =>
  request.post('/me/subscribe', {
    modules,
    additionalUsers,
  });

export const subscribeToWaibiService = () =>
  request.post('/me/subscribe/waibi');

export const getPackagesCostsService = () =>
  request.get('/subscriptions/prices');
