import { useDispatch, useSelector } from 'react-redux';
import { INVOICE_SEARCH_TABLE_SIZE_OPTIONS } from 'const';
import {
  selectStoredMeta,
  selectTableCurrentPage,
} from 'modules/invoiceSearch/selectors';
import { invoicesNextPageRequest } from 'modules/invoiceSearch/actions';

export const useSearchResultsTablePagination = (setSelectedRowKeys) => {
  const dispatch = useDispatch();

  const { total } = useSelector(selectStoredMeta);

  const currentTablePage = useSelector(selectTableCurrentPage);

  /**
   * handler is necessary to prevent the entire click event
   * ending up in redux
   * Limit calculation is taken to helper to ensure storing actual page & itemsPerPage in store.
   * Page is stored and used in table to prevent bugs introduced by using solely limit and offset
   * in backend interaction(page in meta could be wrong).
   */
  const handleLoadMore = (page, itemsPerPage) => {
    setSelectedRowKeys([]);
    dispatch(
      invoicesNextPageRequest({
        page,
        itemsPerPage,
      })
    );
  };

  return {
    current: currentTablePage,
    pageSizeOptions: INVOICE_SEARCH_TABLE_SIZE_OPTIONS,
    showSizeChanger: true,
    onShowSizeChange: handleLoadMore,
    total,
    onChange: handleLoadMore,
  };
};
