import React, { useMemo } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import { BOUQUET_MY_EXCO } from 'const/excoServices';
import { useSelector } from 'react-redux';
import {
  selectUserAuthorizedModules,
  selectUserPackagesNames,
} from 'modules/loggedUserInfo/selectors';
import { STORE_TRANSLATIONS } from 'const/Store';
import { useScrollToTop } from 'hooks/useScrollToTop';
import {
  selectMyExcoPackageWithCost,
  selectPackagesInCart,
} from 'modules/cart/selectors';
import { ServiceMultiCard } from 'components/ServiceCard/ServiceMultiCard/ServiceMultiCard';
import { useServiceCards } from 'hooks/useServiceCards';
import Styles from './Store.module.css';

const { TITLE } = STORE_TRANSLATIONS;

export const Store = () => {
  const userModules = useSelector(selectUserAuthorizedModules);

  useScrollToTop();

  const addedModulesToCart = useSelector(selectPackagesInCart);

  const userCurrentPackages = useSelector(selectUserPackagesNames);

  const isMyExcoPackageActive = useMemo(
    () => userCurrentPackages.includes(BOUQUET_MY_EXCO.PACKAGE_NAME),
    [userCurrentPackages]
  );

  const isMyExcoPackageAddedToCart = useMemo(
    () => addedModulesToCart.includes(BOUQUET_MY_EXCO.PACKAGE_NAME),
    [addedModulesToCart]
  );

  const bouquetMyExcoWithCost = useSelector(selectMyExcoPackageWithCost);

  const serviceCards = useServiceCards();

  return (
    <div className={Styles.backgroundWrapper}>
      <div className={clsx(MainStyles.container)}>
        <SectionTitle title={TITLE} />
        <div className={clsx(Styles.serviceCardsWrapper)}>
          <ServiceMultiCard
            isActive={isMyExcoPackageActive}
            userModules={userModules}
            mainService={bouquetMyExcoWithCost}
            isAddedToCart={isMyExcoPackageAddedToCart}
          />
          <div className={Styles.defaultCardsWrapper}>{serviceCards}</div>
        </div>
      </div>
    </div>
  );
};
