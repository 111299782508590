import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { EXTERNAL_ROUTES } from 'const';
import { openURLInNewTab, parseJwt } from 'helpers/common';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';
import { WAIBI_ACCESS_TOKEN, WAIBI_REFRESH_TOKEN } from 'config';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { fetchWaibiAccessToken } from '../services';
import {
  waibiAccessError,
  waibiAccessSuccess,
  WAIBI_ACCESS_REQUEST,
} from '../action';

function* waibiAccessWorker({ payload: { onError, isLoop } }) {
  try {
    const accessToken = getFromStorage({ key: WAIBI_ACCESS_TOKEN });
    const refreshToken = getFromStorage({ key: WAIBI_REFRESH_TOKEN });

    let token = accessToken;
    const isTokenExpired = parseJwt(accessToken)?.exp * 1000 < Date.now();

    if (!accessToken || isTokenExpired) {
      const {
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
        expires_in: expiresIn,
      } = yield call(fetchWaibiAccessToken, refreshToken);

      setToStorage({
        key: WAIBI_ACCESS_TOKEN,
        value: newAccessToken,
      });
      setToStorage({
        key: WAIBI_REFRESH_TOKEN,
        value: newRefreshToken,
      });

      token = newAccessToken;

      if (isLoop) {
        yield delay(expiresIn * 1000);
        yield waibiAccessWorker({ payload: { isLoop: true } });
      }
    }

    if (!isLoop) {
      yield call(openURLInNewTab, `${EXTERNAL_ROUTES.WAIBI_LOGIN}${token}`);
    }

    yield put(waibiAccessSuccess());
    yield delay(10 * 1000);
    yield waibiAccessWorker({ payload: { isLoop: true } });
  } catch (e) {
    console.warn(e);
    onError(HOMEPAGE_DASHBOARDS_TRANSLATIONS.ADVANCED_INDICATORS.AUTH_ERROR);
    removeFromStorage(WAIBI_ACCESS_TOKEN, localStorage);
    removeFromStorage(WAIBI_REFRESH_TOKEN, localStorage);
    yield put(waibiAccessError());
  }
}

export function* waibiMainSaga() {
  yield takeLatest(WAIBI_ACCESS_REQUEST, waibiAccessWorker);
}
