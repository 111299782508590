import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal as AntModal, Typography } from 'antd';
import clsx from 'clsx';
import { Button } from 'components/ui';
import { CloseIcon } from 'components/ui/CloseIcon';
import Styles from './Modal.module.css';

const { Title } = Typography;

export const Modal = memo(
  ({ isVisible = true, onClose, onSubmit, ...passedProps }) => {
    const {
      title,
      isError = false,
      extraInfoMessage,
      width,
      children,
      okText = 'Déclarer',
      cancelText = isError ? 'Ok' : 'Annuler',
      deleteText = 'Supprimer',
      hasDeleteButton = false,
      onDelete,
      fullMobileScreen = true,
      isSmallModal = false,
    } = passedProps;
    return (
      <AntModal
        width={'740px' || width}
        closeIcon={<CloseIcon />}
        footer={null}
        visible={isVisible}
        wrapClassName={clsx(
          Styles.content,
          {
            [Styles.fullMobileScreen]: fullMobileScreen,
          },
          {
            [Styles.isSmallModal]: isSmallModal,
          }
        )}
        closable
        centered
        onCancel={onClose}
        {...passedProps}
        title={null}
      >
        {title && (
          <div className={Styles.header}>
            {title && (
              <Title
                className={clsx(Styles.textTitle, {
                  [Styles.isError]: isError,
                })}
                level={3}
              >
                {title}
              </Title>
            )}
          </div>
        )}
        <div className={Styles.bodyContent}>{children}</div>
        {extraInfoMessage && (
          <div className={Styles.infoWrapper}>{extraInfoMessage}</div>
        )}
        <div className={Styles.footer}>
          <div className={Styles.buttonsWrapper}>
            {isError ? (
              <Button
                className={clsx(Styles.btn, { [Styles.errorBtn]: isError })}
                variant="primary"
                onClick={onClose}
              >
                {cancelText}
              </Button>
            ) : (
              <>
                {hasDeleteButton && (
                  <Button
                    className={Styles.btn}
                    variant="danger"
                    onClick={onDelete}
                  >
                    {deleteText}
                  </Button>
                )}
                <div className={Styles.okAndCancelButtons}>
                  <Button
                    className={Styles.btn}
                    variant="secondary"
                    onClick={onClose}
                  >
                    {cancelText}
                  </Button>
                  <Button
                    className={Styles.btn}
                    variant="primary"
                    onClick={onSubmit}
                  >
                    {okText}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </AntModal>
    );
  }
);

Modal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fullMobileScreen: PropTypes.bool,
  deleteText: PropTypes.string,
  hasDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
};
