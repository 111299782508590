import { createSelector } from 'reselect';

const stepsKeys = [
  'civilData',
  'salarySituation',
  'workContract',
  'specialSituations',
  'documents',
];

export const selectSubmitStatus = (state) =>
  state.getIn(['employeeRegister', 'submitStatus']);

export const selectCivilData = createSelector(
  (state) => state.getIn(['employeeRegister', 'civilData', 'data', 'values']),
  (values) => (values ? values.toJS() : {})
);

export const selectCivilDataErrors = createSelector(
  (state) => state.getIn(['employeeRegister', 'civilData', 'civilDataErrors']),
  (errors) => (errors ? errors.toJS() : {})
);

const selectCivilDataIsValidStatus = (state) =>
  state.getIn(['employeeRegister', 'civilData', 'isValid']);

export const selectSalarySituationData = createSelector(
  (state) =>
    state.getIn(['employeeRegister', 'salarySituation', 'data', 'values']),
  (values) => (values ? values.toJS() : {})
);

export const selectSalarySituationErrors = createSelector(
  (state) =>
    state.getIn([
      'employeeRegister',
      'salarySituation',
      'salarySituationErrors',
    ]),
  (errors) => (errors ? errors.toJS() : {})
);

const selectSalarySituationIsValidStatus = (state) =>
  state.getIn(['employeeRegister', 'salarySituation', 'isValid']);

export const selectWorkContractData = createSelector(
  (state) =>
    state.getIn(['employeeRegister', 'workContract', 'data', 'values']),
  (values) => (values ? values.toJS() : {})
);

export const selectWorkContractErrors = createSelector(
  (state) =>
    state.getIn(['employeeRegister', 'workContract', 'workContractErrors']),
  (errors) => (errors ? errors.toJS() : {})
);

const selectWorkContractIsValidStatus = (state) =>
  state.getIn(['employeeRegister', 'workContract', 'isValid']);

export const selectSpecialSituationsData = createSelector(
  (state) =>
    state.getIn(['employeeRegister', 'specialSituations', 'data', 'values']),
  (values) => (values ? values.toJS() : {})
);

export const selectSpecialSituationsErrors = createSelector(
  (state) =>
    state.getIn([
      'employeeRegister',
      'specialSituations',
      'specialSituationsErrors',
    ]),
  (errors) => (errors ? errors.toJS() : {})
);

const selectSpecialSituationsIsValidStatus = (state) =>
  state.getIn(['employeeRegister', 'specialSituations', 'isValid']);

export const selectDocumentsData = createSelector(
  (state) => state.getIn(['employeeRegister', 'documents', 'data', 'values']),
  (values) => (values ? values.toJS() : {})
);

export const selectDocumentsErrors = createSelector(
  (state) => state.getIn(['employeeRegister', 'documents', 'documentsErrors']),
  (errors) => (errors ? errors.toJS() : {})
);

export const selectDocumentsUploadStatus = (state) =>
  state.getIn(['employeeRegister', 'documents', 'uploadStatus']);

/*
  Calculate progress by all steps
  support local progress in percents in every step
  local progress can be saved as field progress in field data of every step
 */
export const selectProgress = (state) => {
  const employeeRegister = state.get('employeeRegister').toJS();
  const stepsCount = stepsKeys.length;
  return Math.ceil(
    (stepsKeys.reduce((acc, key) => {
      const step = employeeRegister[key];
      const oneStepProgress = step.data.progress;
      return step.isValid && !step.error
        ? acc + (oneStepProgress !== undefined ? oneStepProgress / 100 : 1)
        : acc;
    }, 0) /
      stepsCount) *
      100
  );
};

export const selectFirst4TabsIsValidArray = createSelector(
  [
    selectCivilDataIsValidStatus,
    selectSalarySituationIsValidStatus,
    selectWorkContractIsValidStatus,
    selectSpecialSituationsIsValidStatus,
  ],
  (isTab1Valid, isTab2Valid, isTab3Valid, isTab4Valid) => [
    isTab1Valid,
    isTab2Valid,
    isTab3Valid,
    isTab4Valid,
  ]
);

export const selectFirst4TabsOverallStatus = createSelector(
  [selectFirst4TabsIsValidArray],
  (isValidArray) => !isValidArray.includes(false)
);
