import { createSelector } from 'reselect';
import { getMonthNameFromIndex } from 'helpers/monthSelector';
import {
  groupDatasetsByMonthsAndDays,
  mapDashboardDataToYearView,
} from 'helpers/chartDataMappers';

export const selectTurnoverDataStatus = (state) =>
  state.getIn(['turnoverPage', 'status']);

const selectTurnoverData = createSelector(
  (state) => state.getIn(['turnoverPage', 'turnoverData']),
  (turnoverData) => (turnoverData ? turnoverData.toJS() : [])
);

export const selectCurrentCompanyFirstFiscalMonth = createSelector(
  (state) =>
    state.getIn([
      'loggedUserInfo',
      'info',
      'data',
      'current',
      'lastMonthOfFiscalYear',
    ]),
  (lastFiscalMonthIndex) => {
    const firstMonthIndex =
      Number(lastFiscalMonthIndex) - 1 < 11 ? Number(lastFiscalMonthIndex) : 0;
    return getMonthNameFromIndex(firstMonthIndex);
  }
);

export const selectTurnoverDataForCharts = createSelector(
  selectTurnoverData,
  (turnoverData) =>
    groupDatasetsByMonthsAndDays(turnoverData.datasets, 'turnover')
);

export const selectTurnoverDataForYear = createSelector(
  selectCurrentCompanyFirstFiscalMonth,
  selectTurnoverDataForCharts,
  (firstMonthName, turnoverMonthsData) =>
    mapDashboardDataToYearView(turnoverMonthsData, firstMonthName)
);

export const selectTurnoverLastUpdatedDate = (state) =>
  state.getIn(['turnoverPage', 'turnoverData', 'lastUpdated']) ||
  new Date(Date.now()).toISOString();
