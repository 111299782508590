import React from 'react';
import { Typography } from 'antd';
import { redirect } from 'helpers/common';
import { ROUTES } from 'const';
import { FORGOTTEN_PASSWORD_TRANSLATIONS } from 'const/translations';
import { FormCard } from 'components/FormCard/FormCard';
import { LeafyBackground } from 'components/ui/LeafyBackground';
import { selectPasswordRecoveryEnteredEmail } from 'modules/Authentication/selectors';
import { useSelector } from 'react-redux';
import FormCardStyles from 'components/FormCard/FormCard.module.css';
import { Banners } from 'containers/LoginPage/Banners';
import clsx from 'clsx';
import Styles from './PasswordSent.module.css';

const { ALERTS } = FORGOTTEN_PASSWORD_TRANSLATIONS;

export const PasswordSent = () => {
  const email = useSelector(selectPasswordRecoveryEnteredEmail);

  if (!email) {
    redirect(ROUTES.ROOT);
  }

  return (
    <LeafyBackground>
      <FormCard links={['LOGIN']}>
        <Typography.Title
          className={clsx(FormCardStyles.textTitle, Styles.textTitle)}
        >
          {ALERTS.PASSWORD_HAS_BEEN_SENT}
        </Typography.Title>
        <Typography.Text className={Styles.textParagraph}>
          {ALERTS.CHECK_YOUR_EMAIL}
        </Typography.Text>
        <Typography.Text className={Styles.textMail}>{email}</Typography.Text>
      </FormCard>
      <Banners />
    </LeafyBackground>
  );
};
