import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import moment from 'moment';
import { TIME_FORMAT } from 'const';
import Styles from './TimePicker.module.css';

const TimePickerMain = ({ value, ...props }) => (
  <TimePicker
    defaultOpenValue={moment('00:00:00', TIME_FORMAT)}
    className={Styles.timePicker}
    value={value ? moment(value, moment.ISO_8601) : null}
    {...props}
  />
);

TimePickerMain.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export { TimePickerMain as TimePicker };
