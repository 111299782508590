import React, { useCallback, useState, useEffect } from 'react';
import { Form, Typography } from 'antd';
import { LeafyBackground } from 'components/ui/LeafyBackground';
import { selectPasswordRecoveryErrorCode } from 'modules/Authentication/selectors';
import { passwordRecoveryRequest } from 'modules/Authentication/actions';
import { FormCard } from 'components/FormCard/FormCard';
import { Input } from 'components/ui';
import {
  FORGOTTEN_PASSWORD_TRANSLATIONS,
  GENERAL_ERRORS,
} from 'const/translations';
import { Banners } from 'containers/LoginPage/Banners';
import Styles from 'components/FormCard/FormCard.module.css';
import { FIELD_NAMES } from 'const/forms';
import { useDispatch, useSelector } from 'react-redux';

const { AUTH } = FIELD_NAMES;
const { TITLE, LABELS, BTNS, ERRORS } = FORGOTTEN_PASSWORD_TRANSLATIONS;

export const ForgottenPasswordPage = () => {
  const dispatch = useDispatch();

  const errorCode = useSelector(selectPasswordRecoveryErrorCode);

  // TODO: Use Formik instead of `useState`
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 404:
          setEmailError(ERRORS.USER_DOES_NOT_EXIST);
          break;
        case 423:
          setEmailError(GENERAL_ERRORS.ACCOUNT_LOCKED);
          break;
        default:
          setEmailError(ERRORS.SERVER_ERROR);
      }
    }
  }, [errorCode]);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setEmail(value);
    setEmailError('');
  }, []);

  const validateField = () => {
    if (!email.length) {
      setEmailError(ERRORS.FIELD_REQUIRED);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email) {
      dispatch(passwordRecoveryRequest({ email }));
    } else {
      setEmailError(ERRORS.FIELD_REQUIRED);
    }
  };

  return (
    <LeafyBackground>
      <FormCard
        submitText={BTNS.SEND}
        onSubmit={handleSubmit}
        links={['LOGIN']}
      >
        <Typography.Title className={Styles.textTitle}>
          {TITLE}
        </Typography.Title>
        <Form.Item
          label={
            <Typography.Paragraph className={Styles.textLabel}>
              {LABELS.EMAIL}
            </Typography.Paragraph>
          }
          validateStatus={emailError.length ? 'error' : ''}
          help={emailError}
        >
          <Input
            onChange={handleEmailChange}
            name={AUTH.EMAIL}
            type="email"
            onBlur={validateField}
          />
        </Form.Item>
      </FormCard>
      <Banners />
    </LeafyBackground>
  );
};
