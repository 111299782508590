import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  GET_HR_DATA_REQUEST,
  getHRDataError,
  getHRDataSuccess,
} from 'modules/HRPage/actions';
import { getHRDataService } from 'modules/HRPage/services';
import { selectUserCurrentCompanyTransition } from 'modules/loggedUserInfo/selectors';

function* getHRDataWorker({
  payload: { currentExercice, maxPeriod, companyId },
}) {
  try {
    if (currentExercice) {
      const transition = yield select(selectUserCurrentCompanyTransition);
      const { totalcost, totalheures, turnover, lastUpdated } = yield call(
        getHRDataService,
        {
          currentExercice,
          maxPeriod,
          companyId,
          transition,
        }
      );
      yield put(
        getHRDataSuccess({ totalcost, totalheures, turnover, lastUpdated })
      );
    } else {
      throw Error('currentExercice not found');
    }
  } catch (e) {
    yield put(getHRDataError({ error: e }));
  }
}

export function* HRMainSaga() {
  yield takeLatest(GET_HR_DATA_REQUEST, getHRDataWorker);
}
