import React, { useCallback, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';
import { ReactComponent as AntdArrow } from 'assets/images/ant-arrow.svg';
import { ROUTES } from '../../../const';
import {
  NAVIGATION_LABELS,
  MY_INFORMATION_PAGE_TRANSLATIONS,
} from '../../../const/translations';
import Styles from './LogoutDropdown.module.css';

const propTypes = {
  userInfoFullName: PropTypes.string,
  isCurrentCompanyActivated: PropTypes.bool,
  isMainUser: PropTypes.bool,
};

export const LogoutDropdown = ({
  userInfoFullName,
  isCurrentCompanyActivated,
  isMainUser,
}) => {
  const [arrowRotateDeg, setArrowRotateDeg] = useState(0);

  const arrowRotateStyle = {
    transform: `rotate(${arrowRotateDeg}deg)`,
  };

  const onVisibleChange = useCallback(
    (visible) => {
      if (visible) {
        setArrowRotateDeg(180);
        return;
      }
      setArrowRotateDeg(0);
    },
    [setArrowRotateDeg]
  );

  const onMenuItemClick = useCallback(() => {
    setArrowRotateDeg(0);
  }, [setArrowRotateDeg]);

  return (
    <Dropdown
      overlay={
        <Menu className={Styles.dropDownMenu} onClick={onMenuItemClick}>
          <Menu.Item key={ROUTES.CONTACTS}>
            <Link to={ROUTES.CONTACTS}>{NAVIGATION_LABELS.CONTACTS}</Link>
          </Menu.Item>
          <Menu.Item key={ROUTES.MY_INFORMATION}>
            <Link to={ROUTES.MY_INFORMATION}>
              {MY_INFORMATION_PAGE_TRANSLATIONS.TITLE}
            </Link>
          </Menu.Item>
          {isCurrentCompanyActivated && isMainUser && (
            <Menu.Item key="Additional users">
              <Link to={ROUTES.ADDITIONAL_USERS}>
                {NAVIGATION_LABELS.ADDITIONAL_USERS}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="Disconnect">
            <Link to="/logout">{NAVIGATION_LABELS.DISCONNECT}</Link>
          </Menu.Item>
        </Menu>
      }
      arrow
      trigger={['click']}
      overlayClassName={Styles.dropDownWindow}
      placement="bottomRight"
      onVisibleChange={onVisibleChange}
    >
      <div
        className={clsx(Styles.dropDownTrigger, {
          [Styles.transparentTrigger]: arrowRotateDeg === 180,
        })}
      >
        <UserIcon className={Styles.userIcon} />
        <span>{userInfoFullName}</span>
        <i
          aria-label="icon: down"
          style={arrowRotateStyle}
          className="ant-select-arrow-icon"
        >
          <AntdArrow />
        </i>
      </div>
    </Dropdown>
  );
};

LogoutDropdown.propTypes = propTypes;
