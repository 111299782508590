import { getType, getActionCreator } from 'utils';
const prefix = '@@app/salaryVacations';

export const SET_LEAVE_MODAL_MAIN_DATA = getType(
  prefix,
  'SET_LEAVE_MODAL_MAIN_DATA'
);
export const setLeaveModalMainData = getActionCreator(
  SET_LEAVE_MODAL_MAIN_DATA
);

export const CREATE_VACATION_REQUEST = getType(
  prefix,
  'CREATE_VACATION_REQUEST'
);
export const createVacation = getActionCreator(CREATE_VACATION_REQUEST);

export const SET_EDIT_LEAVE_MODAL_DATA = getType(
  prefix,
  'SET_EDIT_LEAVE_MODAL_DATA'
);
export const setEditLeaveModalData = getActionCreator(
  SET_EDIT_LEAVE_MODAL_DATA
);

export const RESET_VACATION_MODAL_DATA = getType(
  prefix,
  'RESET_VACATION_MODAL_DATA'
);
export const resetVacationModalData = getActionCreator(
  RESET_VACATION_MODAL_DATA
);

export const EDIT_LEAVE_REQUEST = getType(prefix, 'EDIT_LEAVE_REQUEST');
export const editLeaveRequest = getActionCreator(EDIT_LEAVE_REQUEST);

export const DELETE_LEAVE_REQUEST = getType(prefix, 'DELETE_LEAVE_REQUEST');
export const deleteLeaveRequest = getActionCreator(DELETE_LEAVE_REQUEST);

export const DOWNLOAD_INITIAL_ABSENCE_SUPPORTING_DOC = getType(
  prefix,
  'DOWNLOAD_INITIAL_ABSENCE_SUPPORTING_DOC'
);
export const downloadInitialAbsenceSupportingDoc = getActionCreator(
  DOWNLOAD_INITIAL_ABSENCE_SUPPORTING_DOC
);

export const DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL = getType(
  prefix,
  'DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL'
);
export const downloadAbsenceAttachmentWithoutModal = getActionCreator(
  DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL
);

export const TOGGLE_VACATION_MODAL_VISIBILITY = getType(
  prefix,
  'TOGGLE_VACATION_MODAL_VISIBILITY'
);
export const toggleVacationModalVisibility = getActionCreator(
  TOGGLE_VACATION_MODAL_VISIBILITY
);

export const EMPLOYEE_ABSENCES_REQUEST = getType(
  prefix,
  'EMPLOYEE_ABSENCES_REQUEST'
);
export const employeeAbsencesRequest = getActionCreator(
  EMPLOYEE_ABSENCES_REQUEST
);
export const EMPLOYEE_ABSENCES_SUCCESS = getType(
  prefix,
  'EMPLOYEE_ABSENCES_SUCCESS'
);
export const employeeAbsencesSuccess = getActionCreator(
  EMPLOYEE_ABSENCES_SUCCESS
);
export const EMPLOYEE_ABSENCES_ERROR = getType(
  prefix,
  'EMPLOYEE_ABSENCES_ERROR'
);
export const employeeAbsencesError = getActionCreator(EMPLOYEE_ABSENCES_ERROR);

export const SET_TABLE_DEFAULT_PAGE = getType(prefix, 'SET_TABLE_DEFAULT_PAGE');
export const setTableDefaultPage = getActionCreator(SET_TABLE_DEFAULT_PAGE);
