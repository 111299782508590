import React from 'react';
import PropTypes from 'prop-types';
import rightOutlined from 'assets/icons/PreviewCards/outligned-arrow-right.svg';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  getPreviewCardDescription,
  getProperLookingAmount,
  getStringUpdateDate,
} from 'helpers/shortPreviewCards';
import { SHORT_PREVIEW_CARD_TYPES } from 'const/dashboardCards';
import Styles from './ShortPreviewCard.module.css';

const propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  updateDate: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SHORT_PREVIEW_CARD_TYPES)).isRequired,
  iconSrc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const ShortPreviewCard = ({
  amount,
  updateDate,
  type,
  iconSrc,
  url,
  className,
}) => {
  const stringUpdateDate = updateDate && getStringUpdateDate(updateDate);
  const description = getPreviewCardDescription(type, stringUpdateDate);
  const stringAmount =
    Number(amount) || Number(amount) === 0
      ? getProperLookingAmount(Math.round(Number(amount)))
      : 'N/A';

  return (
    <Link to={url} className={clsx(Styles.wrapper, Styles[type], className)}>
      <div className={Styles.info}>
        <div className={clsx(Styles.icon, Styles[type])}>
          <img src={iconSrc} alt={type} />
        </div>
        <div className={Styles.amount}>
          {stringAmount} {stringAmount !== 'N/A' && '€'}
        </div>
      </div>
      <div className={Styles.descriptionWrapper}>
        <div className={Styles.descriptionText}>{description}</div>
        <img className={Styles.arrow} src={rightOutlined} alt="arrow-right" />
      </div>
    </Link>
  );
};

ShortPreviewCard.propTypes = propTypes;
