export const CART_PAGE_TRANSLATIONS = {
  PAGE_TITLE: 'Mon panier / souscription',
  CART_BLOCK_TITLE: 'Mon panier :',
  EURO_PER_MONTH: '€ HT / mois',
  TOOLTIP:
    "Bouquet My Exco est obligatoire pour l’utilisation d'autres modules",
  TOTAL: 'Total',
  SUBSCRIPTION_INFO:
    'Votre abonnement sera mis à jour sur votre prochaine facture. TVA applicable : 20%.',
  FACT_TRAINING_PROMO_1:
    "Pourquoi réaliser la formation à l'outil FACT My Exco ?",
  FACT_TRAINING_PROMO_2:
    "Cette formation comprend d'une part la présentation détaillée du module FACT (Tableau de bord, réalisation d'un " +
    "devis, d'une facture avec ou sans acompte, d'un avoir, etc.) et inclut d'autre part le paramétrage de votre compte " +
    "(gestion des taux de TVA, personnalisation de votre logo et de votre modèle de facture, envoi d'e-mails possibles " +
    'pour les devis, factures et relances, etc.).',
  CHECKBOX_LABEL: 'Je souhaite être formé(e) à l’outil FACT (150 € HT).',
  CHECKBOX_LINK: 'Réserver un créneau',
  TABLE_TITLE: 'Gestion des droits utilisateurs :',
  ADD_USER_BUTTON: '+ Ajouter',
  SUBSCRIBE_BUTTON: 'Souscrire',
  SUCCESS_BLOCK_TITLE: 'Merci !',
  SUCCESS_BLOCK_NO_PAY: [
    'Les modules demandés seront actifs sous 48h ',
    '(temps d’activation estimé)',
  ],
  SUCCESS_BLOCK_PAY_SINGLE: [
    'Votre demande a bien été prise en compte.',
    'Nous vous contacterons dans les prochains jours pour procéder aux étapes de vérification nécessaires à la mise en place du module PAY',
  ],
  SUCCESS_BLOCK_PAY_PART_OF_SET: [
    'Votre demande a bien été prise en compte.',
    "L'activation des modules du store nécessite des délais de paramétrage pouvant varier :",
    '- Le module FACT est généralement opérationnel sous 48h.',
    "- Le module PAY impose des étapes de vérification préalables à son fonctionnement. Pour ce dernier, nos équipes vous guident pas à pas pour gérer l'avancement de votre dossier",
  ],
  SUCCESS_BLOCK_BUTTON: "Retourner à l'accueil",
  EMPTY_BLOCK_TEXT: 'Votre panier est vide',
  EMPTY_BLOCK_BUTTON: 'Aller au Store',
  COST_TO_BE_DEFINED: 'Prix à définir selon vos besoins',
};

export const HEADER_CART_TRANSLATIONS = {
  MODULES: 'module{{ENDING}} :',
  MY_CART: 'Mon panier ({{PACKAGES_LENGTH}})',
};
