import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import Styles from 'components/DocumentsCabinet/DocumentsList/DocumentsList.module.css';
import { getFileIconByExtension } from 'helpers/documentsCabinet';

const propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  extension: PropTypes.string,
};

const DataNodeCompomnent = withRouter(
  ({ name, size, extension, location, history }) => {
    const handleGetSubfolder = (folderName) => () => {
      history.push(`${location.pathname}/${folderName}`);
    };

    if (size) {
      // File
      return (
        <div className={Styles.tableRow}>
          {getFileIconByExtension(extension.toLowerCase())}
          <span className={Styles.fileName}>{name}</span>
        </div>
      );
    }

    return (
      // Folder
      <div className={Styles.tableRow}>
        <Icon type="folder" className={Styles.Icon} />
        <span onClick={handleGetSubfolder(name)} className={Styles.folderName}>
          {name}
        </span>
      </div>
    );
  }
);

DataNodeCompomnent.propTypes = propTypes;

export const DataNode = memo(DataNodeCompomnent);
