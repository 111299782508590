import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown, Button, Menu } from 'antd';
import { useCounterparties } from 'helpers/counterparties';
import { COUNTERPARTIES_TABLE_DEFAULT_FILTER } from 'const/translations/Counterparties';
import Styles from './TableFilterSelect.module.css';

const {
  id: defaultId,
  title: defaultTitle,
} = COUNTERPARTIES_TABLE_DEFAULT_FILTER;

const propTypes = {
  setFilteredDataSource: PropTypes.func,
};

/**
 * Filter Select for counterparties table
 * @param {Function} setFilteredDataSource - The handler for setting table data source
 * @returns {JSX}
 */
export const TableFilterSelect = ({ setFilteredDataSource }) => {
  const { dataSource } = useCounterparties();

  const [clientFilterValue, setClientFilterValue] = useState(null);

  const options = dataSource
    .map((item) => ({ title: item.clientName, id: item.clientId }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const handleChange = (event) => {
    setClientFilterValue(event.item.props['data-title']);
    setFilteredDataSource(
      event.key === defaultId
        ? dataSource
        : dataSource.filter((item) => item.clientId === event.key)
    );
  };

  return (
    <Dropdown
      overlay={
        <Menu onClick={handleChange} className={Styles.dropdownMenu}>
          <Menu.Item key={defaultId}>{defaultTitle}</Menu.Item>
          {options.map(({ id, title }) => (
            <Menu.Item key={id} data-title={title}>
              {title}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button>
        {clientFilterValue || defaultTitle} <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

TableFilterSelect.propTypes = propTypes;
