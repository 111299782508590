import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  selectUserAuthorizedModules,
  selectUserCurrentCompany,
} from 'modules/loggedUserInfo/selectors';
import { downloadFile, openFile, openURLInNewTab } from 'helpers/common';
import { openSuccessNotification } from 'helpers/notifications';
import { APP_MODULES } from 'const/index';
import { separateStatusFromFlags } from 'helpers/invoiceRequests';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { message } from 'antd';
import {
  INVOICES_SEARCH_REQUEST,
  invoicesSearchSuccess,
  invoicesSearchError,
  invoicesNextPageError,
  INVOICES_NEXT_PAGE_REQUEST,
  invoicesNextPageSuccess,
  INVOICES_APPROVE_REQUEST,
  invoicesApproveSuccess,
  invoicesApproveError,
  INVOICES_SEND_BY_EMAIL_REQUEST,
  invoicesSendByEmailSuccess,
  invoicesSendByEmailError,
  GET_PAY_INVOICE_URL_REQUEST,
  createInvoiceInLibeoSuccess,
  invoicesDevalidateSuccess,
  invoicesDevalidateError,
  INVOICES_DEVALIDATE_REQUEST,
  INVOICES_ACCEPT_REQUEST,
  invoicesAcceptSuccess,
  invoicesAcceptError,
  VALIDATE_AND_PAY_INVOICE,
  getPayInvoiceUrlSuccess,
  MASS_INVOICES_APPROVE_REQUEST,
  massInvoicesApproveSuccess,
  massInvoicesApproveError,
  INVOICES_SEARCH_SET_SORT,
  INVOICES_DELETE_REQUEST,
  invoicesDeleteSuccess,
  invoicesDeleteError,
  INVOICES_ADD_COMMENT_REQUEST,
  invoicesAddCommentSuccess,
  invoicesAddCommentError,
  INVOICES_PREVALIDATE_REQUEST,
  invoicesPrevalidateSuccess,
  invoicesPrevalidateError,
  CANCEL_INVOICE_PREVALIDATION_REQUEST,
  cancelInvoicePrevalidationSuccess,
  INVOICES_PAID_OUTSIDE_REQUEST,
  MASS_INVOICES_PAID_OUTSIDE_REQUEST,
  massInvoicesPaidOutsideSuccess,
  invoicesPaidOutsideSuccess,
  invoicesDevalidateAndApproveError,
  invoicesDevalidateAndApproveSuccess,
  INVOICES_DEVALIDATE_AND_APPROVE_REQUEST,
  DOWNLOAD_INVOICE_REQUEST,
  OPEN_INVOICE_REQUEST,
  openInvoiceSuccess,
  openInvoiceError,
  downloadInvoiceSuccess,
  downloadInvoiceError,
  storeFile,
  DOWNLOAD_DOCUMENTS_REQUEST,
  downloadDocumentsSuccess,
  downloadDocumentsError,
} from '../actions';
import {
  searchInvoices,
  approveInvoice,
  approveInvoices,
  acceptInvoice,
  deleteInvoice,
  addInvoiceComment,
  sendInvoiceByEmail,
  createInvoiceInLibeo,
  getInvoicePaymentURL,
  devalidateInvoice,
  prevalidateInvoice,
  payInvoicesOutside,
  payInvoiceOutside,
  cancelPrevalidation,
  downloadInvoiceAttachedDocument,
  downloadDocumentList,
} from '../services';
import {
  calculateOffset,
  getDefaultApproveInvoicePayload,
  mapInvoicesResponseToReduxStore,
  transformInvoiceForStore,
} from '../helpers';
import {
  selectInvoiceSearchResults,
  selectItemsPerPage,
  selectTableSort,
  selectStoredPayload,
  selectStoredMeta,
} from '../selectors';

const {
  ERRORS: {
    API_KEY_MISSING,
    INVOICE_NO_PROVIDER_NAME,
    CREATE_IN_LIBEO_ERROR,
    CANNOT_GET_DOCUMENT,
  },
  MESSAGES: { INVOICE_ACCEPTED, INVOICE_DELETED },
} = INVOICE_SEARCH_TRANSLATIONS;

function* getInvoices({ payload }) {
  try {
    const limit = yield select(selectItemsPerPage);
    const sort = yield select(selectTableSort);
    const flagsAndStatus = separateStatusFromFlags(payload.paymentStatus);
    const searchPayload = {
      ...payload,
      ...flagsAndStatus,
      attachTotalsObject: true,
    };
    const { data, meta } = yield call(searchInvoices, searchPayload, {
      limit,
      sort,
    });

    const mappedToReduxData = mapInvoicesResponseToReduxStore(data.documents);
    yield put(
      invoicesSearchSuccess({
        data: mappedToReduxData,
        meta,
        payload,
        totalsObject: data?.totalsObject,
      })
    );
  } catch (error) {
    yield put(invoicesSearchError({ error }));
  }
}

function* getNewInvoicesPage({ payload: { page, itemsPerPage } }) {
  try {
    const offset = calculateOffset({ page, itemsPerPage });

    const storedPayload = yield select(selectStoredPayload);
    const sort = yield select(selectTableSort);
    const flagsAndStatus = separateStatusFromFlags(storedPayload.paymentStatus);
    const searchPayload = { ...storedPayload, ...flagsAndStatus };
    const { data, meta } = yield call(searchInvoices, searchPayload, {
      limit: itemsPerPage,
      offset,
      sort,
    });

    const mappedToReduxData = mapInvoicesResponseToReduxStore(data.documents);
    yield put(invoicesNextPageSuccess({ data: mappedToReduxData, meta }));
  } catch (error) {
    yield put(invoicesNextPageError({ error }));
  }
}

function* approveInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(approveInvoice, payload);
    yield put(invoicesApproveSuccess({ invoiceData: data.document }));
  } catch (error) {
    yield put(invoicesApproveError({ error }));
  }
}

function* approveInvoicesWorker({ payload }) {
  try {
    const { data } = yield call(approveInvoices, payload);
    yield put(massInvoicesApproveSuccess(data));
  } catch (error) {
    yield put(massInvoicesApproveError({ error }));
  }
}

function* acceptInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(acceptInvoice, payload);
    yield put(invoicesAcceptSuccess({ invoiceData: data.document }));
    yield call(openSuccessNotification({ message: INVOICE_ACCEPTED }));
  } catch (error) {
    yield put(invoicesAcceptError({ error }));
  }
}

function* deleteInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(deleteInvoice, payload);
    yield put(invoicesDeleteSuccess({ invoiceData: data.document }));
    yield call(openSuccessNotification({ message: INVOICE_DELETED }));
  } catch (error) {
    yield put(invoicesDeleteError({ error }));
  }
}

function* addInvoiceCommentWorker({ payload }) {
  try {
    const { data } = yield call(addInvoiceComment, payload);
    yield put(invoicesAddCommentSuccess({ invoiceData: data.document }));
  } catch (error) {
    yield put(invoicesAddCommentError({ error }));
  }
}

function* devalidateInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(devalidateInvoice, payload);
    yield put(
      invoicesDevalidateSuccess({
        invoiceData: transformInvoiceForStore(data.document),
      })
    );
  } catch (error) {
    yield put(invoicesDevalidateError({ error }));
  }
}

function* devalidateAndApproveInvoiceWorker({ payload }) {
  try {
    yield call(devalidateInvoice, payload);
    const { data } = yield call(approveInvoice, payload);

    yield put(
      invoicesDevalidateAndApproveSuccess({
        invoiceData: transformInvoiceForStore(data.document),
      })
    );
  } catch (error) {
    yield put(invoicesDevalidateAndApproveError());
  }
}

function* prevalidateInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(prevalidateInvoice, payload);
    yield put(
      invoicesPrevalidateSuccess({
        invoiceData: transformInvoiceForStore(data.document),
      })
    );
  } catch (error) {
    yield put(invoicesPrevalidateError({ error }));
  }
}

function* cancelInvoicePrevalidationWorker({ payload }) {
  try {
    const { data } = yield call(cancelPrevalidation, payload);
    yield put(
      cancelInvoicePrevalidationSuccess({ invoiceData: data.document })
    );
  } catch (error) {
    console.warn(error);
  }
}

function* paidOutsideInvoiceWorker({ payload }) {
  try {
    const { data } = yield call(payInvoiceOutside, payload);
    yield put(invoicesPaidOutsideSuccess({ invoiceData: data.document }));
  } catch (error) {
    console.warn(error);
  }
}

function* paidOutsideInvoicesWorker({ payload }) {
  try {
    const { data } = yield call(payInvoicesOutside, payload);
    yield put(massInvoicesPaidOutsideSuccess(data));
  } catch (error) {
    console.warn(error);
  }
}

function* sendByEmailWorker({ payload: { documentId, onSuccess } }) {
  try {
    yield call(sendInvoiceByEmail, documentId);
    yield put(invoicesSendByEmailSuccess({ documentId }));
    yield put(onSuccess());
  } catch (error) {
    console.warn(error);
    yield put(invoicesSendByEmailError({ documentId }));
    yield call(message.error, CANNOT_GET_DOCUMENT);
  }
}

function* createInvoiceInLibeoWorker({ invoice }) {
  try {
    const { data } = yield call(createInvoiceInLibeo, invoice.documentId);
    yield put(createInvoiceInLibeoSuccess({ invoiceData: data.document }));
    return data.document;
  } catch (error) {
    console.warn(error);
    return error;
  }
}

function* getPayInvoiceURLWorker({ payload: { invoiceId, onError } }) {
  try {
    const currentCompany = yield select(selectUserCurrentCompany);
    if (!currentCompany.paymentId) {
      yield put(
        onError(API_KEY_MISSING.replace('{{company}}', currentCompany.name))
      );
    }
    const invoices = yield select(selectInvoiceSearchResults);
    const invoice = invoices.find(({ documentId }) => documentId === invoiceId);
    if (!invoice.provider) {
      onError(INVOICE_NO_PROVIDER_NAME);
      return;
    }
    if (!invoice.createdInLibeo) {
      const { id } = yield call(createInvoiceInLibeoWorker, {
        invoice,
        onError,
      });
      if (!id) {
        onError(CREATE_IN_LIBEO_ERROR);
        return;
      }
    }
    const userModules = yield select(selectUserAuthorizedModules);
    const isAbleToValidateAndPay = userModules.includes(
      APP_MODULES.APPROVE_AND_PAY_INVOICE
    );

    const { url } = yield call(getInvoicePaymentURL, {
      invoiceId,
      isAbleToValidateAndPay,
    });

    if (!url) {
      return;
    }

    if (userModules.includes(APP_MODULES.PAY_INVOICE)) {
      openURLInNewTab(url);
    }
    if (isAbleToValidateAndPay) {
      yield put(getPayInvoiceUrlSuccess({ url }));
    }
  } catch (error) {
    console.warn(error);
  }
}

function* validateAndPayInvoiceWorker({ payload }) {
  try {
    const approvePayload = getDefaultApproveInvoicePayload(payload.invoiceId);
    yield call(approveInvoiceWorker, { payload: approvePayload });
    yield call(getPayInvoiceURLWorker, { payload });
  } catch (error) {
    console.warn(error);
  }
}

function* getSortedInvoices() {
  try {
    const storedPayload = yield select(selectStoredPayload);
    const flagsAndStatus = separateStatusFromFlags(storedPayload.paymentStatus);

    const { limit, offset } = yield select(selectStoredMeta);
    const sort = yield select(selectTableSort);
    const { data, meta } = yield call(
      searchInvoices,
      // Order of objects to spread is important.
      { ...storedPayload, ...flagsAndStatus },
      {
        limit: offset ? offset + limit : limit,
        sort,
      }
    );

    const mappedToReduxData = mapInvoicesResponseToReduxStore(data.documents);
    yield put(
      invoicesSearchSuccess({
        data: mappedToReduxData,
        meta,
        // Order of objects to spread is important.
        payload: { ...flagsAndStatus, ...storedPayload },
        totalsObject: data?.totalsObject,
      })
    );
  } catch (error) {
    yield put(invoicesSearchError({ error }));
  }
}

function* openInvoiceWorker({ payload: { documentId } }) {
  try {
    const { fileData } = yield call(
      downloadInvoiceAttachedDocument,
      documentId
    );
    const window = yield call(openFile, fileData);

    if (!window) {
      yield put(storeFile({ documentId, fileData }));
    }
    yield put(openInvoiceSuccess({ documentId }));
  } catch (error) {
    console.warn(error);
    yield put(openInvoiceError({ documentId }));
    yield call(message.error, CANNOT_GET_DOCUMENT);
  }
}

function* downloadInvoiceWorker({ payload: { documentId } }) {
  try {
    const { fileData, fileName } = yield call(
      downloadInvoiceAttachedDocument,
      documentId
    );
    yield call(downloadFile, fileData, fileName);
    yield put(downloadInvoiceSuccess({ documentId }));
  } catch (error) {
    console.warn(error);
    yield put(downloadInvoiceError({ documentId }));
    yield call(message.error, CANNOT_GET_DOCUMENT);
  }
}

function* downloadInvoicesWorker({ payload: documentIds }) {
  try {
    const { fileData, fileName } = yield call(
      downloadDocumentList,
      documentIds
    );
    yield call(downloadFile, fileData, fileName);
    yield put(downloadDocumentsSuccess({ documentIds }));
  } catch (error) {
    console.warn(error);
    yield put(downloadDocumentsError({ documentIds }));
    yield call(message.error, CANNOT_GET_DOCUMENT);
  }
}

export function* invoiceSearchMainSaga() {
  yield takeLatest(INVOICES_SEARCH_REQUEST, getInvoices);
  yield takeLatest(INVOICES_SEARCH_SET_SORT, getSortedInvoices);
  yield takeLatest(INVOICES_NEXT_PAGE_REQUEST, getNewInvoicesPage);
  yield takeLatest(MASS_INVOICES_APPROVE_REQUEST, approveInvoicesWorker);
  yield takeLatest(INVOICES_APPROVE_REQUEST, approveInvoiceWorker);
  yield takeLatest(INVOICES_DEVALIDATE_REQUEST, devalidateInvoiceWorker);
  yield takeLatest(
    INVOICES_DEVALIDATE_AND_APPROVE_REQUEST,
    devalidateAndApproveInvoiceWorker
  );
  yield takeLatest(INVOICES_PREVALIDATE_REQUEST, prevalidateInvoiceWorker);
  yield takeLatest(
    CANCEL_INVOICE_PREVALIDATION_REQUEST,
    cancelInvoicePrevalidationWorker
  );
  yield takeLatest(INVOICES_PAID_OUTSIDE_REQUEST, paidOutsideInvoiceWorker);
  yield takeLatest(
    MASS_INVOICES_PAID_OUTSIDE_REQUEST,
    paidOutsideInvoicesWorker
  );
  yield takeLatest(INVOICES_ACCEPT_REQUEST, acceptInvoiceWorker);
  yield takeLatest(INVOICES_DELETE_REQUEST, deleteInvoiceWorker);
  yield takeLatest(INVOICES_ADD_COMMENT_REQUEST, addInvoiceCommentWorker);
  yield takeLatest(INVOICES_SEND_BY_EMAIL_REQUEST, sendByEmailWorker);
  yield takeLatest(GET_PAY_INVOICE_URL_REQUEST, getPayInvoiceURLWorker);
  yield takeLatest(VALIDATE_AND_PAY_INVOICE, validateAndPayInvoiceWorker);
  yield takeLatest(DOWNLOAD_INVOICE_REQUEST, downloadInvoiceWorker);
  yield takeLatest(OPEN_INVOICE_REQUEST, openInvoiceWorker);
  yield takeLatest(DOWNLOAD_DOCUMENTS_REQUEST, downloadInvoicesWorker);
}
