import { createSelector } from 'reselect';
import {
  mapDashboardDataToChartView,
  mapDashboardDataToCumulLastDayAnnual,
} from 'helpers/chartDataMappers';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import { selectUserCurrentCompanyExercice } from '../../loggedUserInfo/selectors';

export const selectCreditorsStatus = (state) =>
  state.getIn(['creditors', 'status']);

export const selectCreditors = createSelector(
  (state) => state.getIn(['creditors', 'data']),
  (data) => (data ? data.toJS() : [])
);

const selectCreditorsDatasets = createSelector(
  (state) => state.getIn(['creditors', 'datasets']),
  (creditorsData) => (creditorsData ? creditorsData.toJS() : [])
);

export const selectCreditorsLastUpdated = (state) =>
  state.getIn(['creditors', 'lastUpdated']) ||
  new Date(Date.now()).toISOString();

const selectCreditorsDataForCharts = createSelector(
  selectCreditorsDatasets,
  selectCreditorsLastUpdated,
  selectUserCurrentCompanyExercice,
  (creditorsDatasets, lastUpdated, currentExercice) =>
    mapDashboardDataToChartView(
      creditorsDatasets,
      'credits',
      lastUpdated,
      currentExercice
    )
);

export const selectCreditorsYearData = createSelector(
  selectCurrentCompanyFirstFiscalMonth,
  selectCreditorsDataForCharts,
  (firstMonthName, creditorsChartData) =>
    mapDashboardDataToCumulLastDayAnnual(creditorsChartData, firstMonthName)
);

export const selectCreditorPageData = createSelector(
  (state) => state.getIn(['creditors', 'current']),
  (pageInfo) => {
    const { data, ...rest } = pageInfo;
    return {
      ...rest,
      data: data ? data.toJS() : [],
    };
  }
);
