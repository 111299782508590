import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'antd';
import Styles from './Button.module.css';

const ButtonMain = ({
  variant = 'primary',
  children,
  big = false,
  sizeAuto = false,
  outlined = false,
  iconOnly = false,
  success = false,
  wide = false,
  paginateSize = false,
  type = 'button',
  className,
  ...props
}) => (
  <Button
    className={clsx(Styles.button, className, Styles[variant], {
      [Styles.buttonBigger]: big,
      [Styles.buttonSizeAuto]: sizeAuto,
      [Styles.outlined]: outlined,
      [Styles.iconOnly]: iconOnly,
      [Styles.success]: success,
      [Styles.wide]: wide,
      [Styles.paginateSize]: paginateSize,
    })}
    htmlType={type}
    {...props}
  >
    {children}
  </Button>
);

ButtonMain.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'outlined',
    'negative',
    'secondary',
    'success',
    'transparent',
    'secondaryOutlined',
    'fullTransparent',
    'danger',
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  big: PropTypes.bool,
  outlined: PropTypes.bool,
  sizeAuto: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node,
  success: PropTypes.bool,
  wide: PropTypes.bool,
  paginateSize: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
};

export { ButtonMain as Button };
