import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'const';
import moment from 'moment';
import Styles from './CustomRangePicker.module.css';

export const CustomRangePicker = memo(
  ({
    value,
    endOpen = false,
    onChange,
    fromText = 'du',
    toText = 'au',
    dateDifferenceValue = 'year',
    ...props
  }) => {
    const [startValue, endValue] = value;
    const [startVal, setStartVal] = useState(startValue);
    const [endVal, setEndVal] = useState(endValue);
    const [isEndOpen, setEndOpen] = useState(endOpen);

    const disabledStartDate = useCallback(
      (startDate) => {
        if (!startDate || !endVal) {
          return false;
        }
        const endValCopy = { ...endVal };
        if (
          startDate.isBefore(
            moment(endValCopy).subtract(1, dateDifferenceValue)
          )
        ) {
          return true;
        }
        return endVal.isBefore(startDate);
      },
      [endVal, dateDifferenceValue]
    );

    const disabledEndDate = useCallback(
      (endDate) => {
        if (!endDate || !startVal) {
          return false;
        }
        const startValCopy = { ...startVal };
        if (endDate.isAfter(moment(startValCopy).add(1, dateDifferenceValue))) {
          return true;
        }
        return startVal.isAfter(endDate);
      },
      [startVal, dateDifferenceValue]
    );

    const onStartChange = (val) => {
      setStartVal(val);
    };

    const onEndChange = (val) => {
      setEndVal(val);
    };

    const handleStartOpenChange = (open) => {
      if (!open) {
        setEndOpen(true);
      }
    };

    const handleEndOpenChange = (open) => {
      setEndOpen(open);
    };

    useEffect(() => {
      onChange([startVal, endVal]);
    }, [startVal, endVal, onChange]);

    return (
      <div className={Styles.rangePickerWrapper}>
        <span className={Styles.separator}>{fromText}</span>
        <DatePicker
          disabledDate={disabledStartDate}
          format={DATE_FORMAT}
          value={startVal}
          placeholder="Start"
          onChange={onStartChange}
          onOpenChange={handleStartOpenChange}
          {...props}
        />
        <span className={Styles.separator}>{toText}</span>
        <DatePicker
          disabledDate={disabledEndDate}
          format={DATE_FORMAT}
          value={endVal}
          placeholder="End"
          onChange={onEndChange}
          open={isEndOpen}
          onOpenChange={handleEndOpenChange}
          {...props}
        />
      </div>
    );
  }
);

CustomRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  endOpen: PropTypes.bool,
  fromText: PropTypes.string,
  toText: PropTypes.string,
  dateDifferenceValue: PropTypes.oneOf(['year', 'month', 'day']),
};
