import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { UPLOAD_DOCUMENTS_FILE_ERRORS } from 'const';
import clsx from 'clsx';
import { File } from '../File/File';
import Styles from './FileList.module.css';

const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

// hasDownload {boolean} - flag that file can be downloaded on name label click
const FileList = ({
  files,
  onRemove,
  className,
  removeIcon,
  hasDownload = false,
  onFileDownload,
}) => (
  <div className={clsx(Styles.fileList)}>
    {files.map((file) => (
      <File
        key={file.uid}
        uid={file.uid}
        errors={file.errors}
        name={file.name}
        onRemove={onRemove}
        className={className}
        removeIcon={removeIcon}
        hasDownload={hasDownload}
        onFileDownload={onFileDownload}
        file={file}
      />
    ))}
  </div>
);

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      errors: PropTypes.shape({
        [COUNT_LIMIT_ERROR]: PropTypes.bool,
        [SIZE_LIMIT_ERROR]: PropTypes.bool,
        [TYPE_FORBIDDEN_ERROR]: PropTypes.bool,
      }),
      onRemove: PropTypes.func,
    })
  ),
  onRemove: PropTypes.func,
  className: PropTypes.string,
  removeIcon: PropTypes.node,
  hasDownload: PropTypes.bool,
  onFileDownload: PropTypes.func,
};

const MemoFileList = memo(FileList);

export { MemoFileList as FileList };
