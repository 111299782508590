import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import { DATE_FORMAT } from 'const';
import Styles from './DatePicker.module.css';

const DatePickerMain = ({ value, ...props }) => (
  <DatePicker
    format={DATE_FORMAT}
    className={Styles.datePicker}
    value={value ? moment(value) : null}
    {...props}
  />
);

DatePickerMain.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { DatePickerMain as DatePicker };
