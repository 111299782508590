import React, { useMemo, useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { CHARTS_VIEW_SWITCH_TYPES, MONTHS } from 'const/ui';
import { DASHBOARD_NAMES } from 'const/charts';
import { ChartTabs } from 'components/ChartTabs';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import rightOutlined from '@iconify/icons-ant-design/right-outlined';
import { Legends } from 'components/Charts/Legends';
import { TurnoverSummary } from 'components/TurnoverSummary';
import { HOMEPAGE_DASHBOARDS_TRANSLATIONS } from 'const/translations/HomePageDashboards';
import { useCommentsModalCallbacks } from 'hooks/useCommentsModalCallbacks';
import { LineChart } from 'components/Charts/LineChart';
import { BarChart } from 'components/Charts/BarChart';
import { Col } from 'antd';
import { Button } from 'components/ui/Button';
import { getScreenWidthBreakpointMedia } from 'utils';
import { useSimpleMedia } from 'hooks/useMedia';
import { ROUTES } from 'const';
import Styles from './HomePageDashboard.module.css';
import { PillarLoader } from '../ui/PillarLoader';

const { LINE, BAR } = CHARTS_VIEW_SWITCH_TYPES;

const { YEAR, MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { TITLES, SUBTITLES } = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

const propTypes = {
  firstMonth: PropTypes.oneOf(MONTHS.map((month) => month.name)).isRequired,
  activeMonthName: PropTypes.oneOf(MONTHS.map((month) => month.name))
    .isRequired,
  activeDateViewOption: PropTypes.oneOf([YEAR, MONTH]),
  activeYear: PropTypes.number.isRequired,
  chartData: PropTypes.array.isRequired,
  chartViewActiveMode: PropTypes.oneOf([LINE, BAR]),
  tabItems: PropTypes.array,
  activeTabId: oneOfType([PropTypes.string, PropTypes.number]),
  setActiveTabId: PropTypes.func,
  dashboardName: PropTypes.oneOf(Object.values(DASHBOARD_NAMES)),
  addTurnoverSummary: PropTypes.bool,
  linkUrl: PropTypes.string,
  disableMainLinkCondition: PropTypes.bool,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  chartBlockClassName: PropTypes.string,
  updatedDate: PropTypes.any,
  comments: PropTypes.array,
  isLoading: PropTypes.bool,
  isSingleAccountLoading: PropTypes.bool,
  isError: PropTypes.bool,
  hasAccederButton: PropTypes.bool,
  accederButtonLink: PropTypes.string,
  accederButtonLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  errorButtonText: PropTypes.string,
  hasErrorButton: PropTypes.bool,
  errorLink: PropTypes.string,
};

const SCREEN_WIDTH_BREAKPOINT_MEDIA = getScreenWidthBreakpointMedia(850);

const {
  DASHBOARD_ERROR_TEXT,
  DASHBOARD_ERROR_BUTTON_TEXT,
} = HOMEPAGE_DASHBOARDS_TRANSLATIONS;

export const HomePageDashboard = React.memo(
  ({
    firstMonth,
    activeMonthName,
    activeDateViewOption = YEAR,
    activeYear,
    chartData,
    chartViewActiveMode = LINE,
    tabItems = [],
    activeTabId,
    setActiveTabId,
    addTurnoverSummary = false,
    dashboardName,
    linkUrl = '#',
    disableMainLinkCondition = false,
    horizontal = false,
    className,
    chartBlockClassName,
    updatedDate,
    isLoading = false,
    isSingleAccountLoading = false,
    isError = false,
    comments = [],
    hasAccederButton = false,
    accederButtonLink = '#',
    accederButtonLabel = '',
    errorMessage = DASHBOARD_ERROR_TEXT,
    errorButtonText = DASHBOARD_ERROR_BUTTON_TEXT,
    hasErrorButton = false,
    errorLink = ROUTES.CONTACTS,
  }) => {
    const [hiddenLinesYears, setHiddenLineYears] = useState([]);

    const { onOpenCommentsModal } = useCommentsModalCallbacks();
    const isTabVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

    const Chart = useMemo(() => {
      if (chartViewActiveMode === LINE) return LineChart;
      return BarChart;
    }, [chartViewActiveMode]);

    const LoaderWrappedChart = isError ? (
      <Col span={24} className={Styles.loaderWrapper}>
        <PillarLoader />
        <div className={Styles.errorText}>{errorMessage}</div>
        {hasErrorButton && (
          <NavLink to={errorLink} className={Styles.errorButton}>
            <Button variant="secondary">{errorButtonText}</Button>
          </NavLink>
        )}
      </Col>
    ) : (
      <Chart
        activeYear={activeYear}
        activeDateViewOption={activeDateViewOption}
        data={chartData}
        activeMonthName={activeMonthName}
        firstMonth={firstMonth}
        gradientHeight={300}
        hiddenLinesYears={hiddenLinesYears}
        dashboardName={dashboardName}
        activeTabId={activeTabId}
        comments={comments}
        openCommentsModal={onOpenCommentsModal}
      />
    );

    const isSingleAccountTreasuryLoading =
      isSingleAccountLoading && dashboardName === DASHBOARD_NAMES.TREASURY;

    return (
      <div className={clsx(Styles.wrapper, className)}>
        <div
          className={clsx(Styles.chartBlock, chartBlockClassName, {
            [Styles.squared]: tabItems.length > 0,
            [Styles.horizontal]: horizontal,
          })}
        >
          <div className={Styles.chartHeaderAndBodyWrapper}>
            <div className={Styles.chartHeader}>
              <NavLink
                to={disableMainLinkCondition ? '#' : linkUrl}
                className={Styles.titleWrapper}
              >
                <div
                  className={clsx(Styles.title, {
                    [Styles.withoutSubtitle]: activeDateViewOption !== YEAR,
                  })}
                >
                  <h2>{TITLES[dashboardName]}</h2>
                  <div className={Styles.arrowWrapper}>
                    <Icon className={Styles.rightArrow} icon={rightOutlined} />
                  </div>
                </div>
                {activeDateViewOption === YEAR && (
                  <span className={Styles.subtitle}>
                    {SUBTITLES[dashboardName]}
                  </span>
                )}
              </NavLink>
              <Legends
                chartData={chartData}
                hiddenLinesYears={hiddenLinesYears}
                setHiddenLineYears={setHiddenLineYears}
              />
            </div>
            <div className={Styles.chartBody}>
              {isLoading || isSingleAccountTreasuryLoading ? (
                <Col span={24} className={Styles.loaderWrapper}>
                  <PillarLoader colored />
                </Col>
              ) : (
                LoaderWrappedChart
              )}
            </div>
            {hasAccederButton && !isTabVersion && !isError && (
              <NavLink
                to={accederButtonLink}
                className={Styles.underChartButton}
              >
                <Button variant="secondary" sizeAuto>
                  {accederButtonLabel}
                </Button>
              </NavLink>
            )}
          </div>
          {addTurnoverSummary && !isLoading && !isError && (
            <TurnoverSummary
              chartData={chartData}
              activeMonthName={activeMonthName}
              activeDateViewOption={activeDateViewOption}
              firstMonth={firstMonth}
              className={Styles.turnoverSummary}
              vertical={horizontal}
              updatedDate={updatedDate}
            />
          )}
        </div>
        {(!isLoading ||
          (isSingleAccountLoading &&
            dashboardName === DASHBOARD_NAMES.TREASURY)) &&
          tabItems.length > 0 &&
          !isError && (
            <ChartTabs
              tabItems={tabItems}
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
              maxItemsOutsideSelector={
                dashboardName === DASHBOARD_NAMES.HR ? 4 : 3
              }
              dashboardName={dashboardName}
              updateDate={updatedDate}
              className={clsx(
                dashboardName === DASHBOARD_NAMES.HR && Styles.HRTabs
              )}
            />
          )}
        {hasAccederButton && isTabVersion && !isError && (
          <div className={Styles.accederButtonWrapper}>
            <NavLink to={accederButtonLink}>
              <Button variant="secondary" sizeAuto>
                {accederButtonLabel}
              </Button>
            </NavLink>
          </div>
        )}
      </div>
    );
  }
);

HomePageDashboard.propTypes = propTypes;
