import { takeLatest, call } from 'redux-saga/effects';
import { SAVE_ADDITIONAL_USERS_REQUEST } from 'modules/additionalUsers/actions';
import { saveChangesForAdditionalUsersService } from 'modules/additionalUsers/services';

function* saveAdditionalUsers({
  payload: { onSuccess, currentUsers, deletedUsers },
}) {
  try {
    const mappedDeletedUsers = deletedUsers.map(({ email }) => ({
      email,
      modules: [],
      position: 'supprimé',
      name: 'N/A',
      surname: 'N/A',
    }));

    const mappedCurrentUsers = Object.values(currentUsers).map(
      ({ tableModules, email, position, name, surname }) => ({
        name,
        surname,
        email,
        position,
        modules: tableModules,
      })
    );
    const additionalUsers = [...mappedCurrentUsers, ...mappedDeletedUsers];
    yield call(saveChangesForAdditionalUsersService, { additionalUsers });
    onSuccess();
  } catch (error) {
    console.warn(error);
  }
}

export function* additionalUsersMainSaga() {
  yield takeLatest(SAVE_ADDITIONAL_USERS_REQUEST, saveAdditionalUsers);
}
