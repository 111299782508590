import { getType, getActionCreator } from 'utils';
const prefix = '@@app/HRPage';

export const INIT_HR_DATA = getType(prefix, 'INIT_HR_DATA');
export const initHRData = getActionCreator(INIT_HR_DATA);

export const GET_HR_DATA_REQUEST = getType(prefix, 'GET_HR_DATA_REQUEST');
export const getHRDataRequest = getActionCreator(GET_HR_DATA_REQUEST);

export const GET_HR_DATA_SUCCESS = getType(prefix, 'GET_HR_DATA_SUCCESS');
export const getHRDataSuccess = getActionCreator(GET_HR_DATA_SUCCESS);

export const GET_HR_DATA_ERROR = getType(prefix, 'GET_HR_DATA_ERROR');
export const getHRDataError = getActionCreator(GET_HR_DATA_ERROR);
