import { MONTHS } from 'const/ui';
import {
  getMonthIndexFromName,
  getMonthNameFromIndex,
} from 'helpers/monthSelector';
import {
  getNumberOfDaysInMonth,
  getRealYearValueFromFiscal,
} from 'helpers/charts';
import moment from 'moment';

export const sortArrayFromJanuaryToArrayFromFirstMonth = (
  array,
  firstMonthName
) => {
  const firstMonthIndex = getMonthIndexFromName(firstMonthName);
  return [...array.slice(firstMonthIndex), ...array.slice(0, firstMonthIndex)];
};

/*
 * returns array of every day of every month of the year with null value
 * e.g [{year:2020, name:'January', values:[null x 31]},
 *      {year:2020, name:'February', values:[null x 29]} ... ]
 * */
export const getInitialMonthArray = (year) =>
  MONTHS.map(({ name }) => {
    const monthIndex = getMonthIndexFromName(name);
    const daysCountInMonth = getNumberOfDaysInMonth(
      Number(year),
      monthIndex + 1
    );
    return {
      year,
      name,
      values: [...new Array(daysCountInMonth)].map(() => null),
    };
  });

const getOrderedInitialArray = (year, firstMonthName) =>
  sortArrayFromJanuaryToArrayFromFirstMonth(
    getInitialMonthArray(year),
    firstMonthName
  );

export const sortChartDataByYear = (a, b) => {
  if (Number(a.year) > Number(b.year)) return -1;
  if (Number(a.year) < Number(b.year)) return 1;
  return 0;
};

/*
 * returns cumulated array where every value is sum of all previous values and current value itself
 * */
export const getCumulatedArray = (array) => {
  let lastNotNullValue = 0;
  return array.map((value) => {
    if (Number.isNaN(value)) return null;
    const cumulValue = lastNotNullValue + Number(value);
    lastNotNullValue = cumulValue;
    return cumulValue ? cumulValue.toFixed(2) : cumulValue;
  });
};

/**
 * returns mapped dashboard data in chartView for annual chart
 * e.g. dashboard data for chart -> [{year:2020, monthsData:[{year:2020, name:'January', values:[3000, null, 2000, null, ... null x 27]}, ...]]
 *      returns [{year:2020, id:'2020YearViewMode' values:[5000, null, null, ... null x 9]}]
 *                                                        jan    feb   march           dec
 * values array depends of firstMonthName, if firstMonth = march -> array will be sorted in order from march to febr
 * if onlyLastDayOfMonth = true -> value of each month will be last known month value instead of summary values of each month
 * if isCumulated = true -> values of year data will be cumulated(every next value will be sum of prev values and current value itself)
 *
 * @param firstMonthName {string} -> month name from MONTHS array in const ('January','February',...)
 * @param dashboardDataInChartView {array} -> array of dashboard data in ChartView (function below)
 * @param onlyLastDayOfMonth{boolean} -> if true value of month will be last known value instead of sum
 * @param isCumulated{boolean} -> should result values be cumulated
 * */
export const mapDashboardDataToYearView = (
  dashboardDataInChartView,
  firstMonthName,
  onlyLastDayOfMonth = false,
  isCumulated = false
) =>
  dashboardDataInChartView
    .map(({ year, monthsData }) => {
      const monthsValues = monthsData.map(({ values }) => {
        // if only last day of each month needed
        if (!onlyLastDayOfMonth) {
          return values.reduce((sum, value) => {
            if (sum === null && value === null) return null;
            if (sum === null && value !== null)
              return Number(parseFloat(value).toFixed(2));
            return Number(parseFloat(Number(sum) + Number(value)).toFixed(2));
          }, null);
        }
        // if sum of every day of month needed
        const valuesCopy = [...values];
        return valuesCopy.reverse().find((value) => value !== null);
      });

      const yearValuesInOrderFromFirstMonth = sortArrayFromJanuaryToArrayFromFirstMonth(
        monthsValues,
        firstMonthName
      );

      return {
        year,
        id: `${year}YearViewMode`,
        values: isCumulated
          ? getCumulatedArray(yearValuesInOrderFromFirstMonth)
          : yearValuesInOrderFromFirstMonth,
      };
    })
    .sort(sortChartDataByYear);

/**
 * Takes datasets [{date, valueKey: value},...] and summarizes values for same months and days
 * @note Input data should not contain entries for same months and days, but for different YEARS
 */
const groupDatasetByMonthsAndDays = (year, yearData, valueKey) =>
  yearData.reduce((monthValues, dayValue) => {
    const { date } = dayValue;
    const value = dayValue[valueKey];
    const monthIndex = Number(new Date(Date.parse(date)).getMonth());
    const day = Number(new Date(Date.parse(date)).getDate() - 1);
    // eslint-disable-next-line no-param-reassign
    monthValues[monthIndex].values[day] =
      monthValues[monthIndex].values[day] === null
        ? Number(parseFloat(value).toFixed(2))
        : Number(
            parseFloat(
              Number(value) + Number(monthValues[monthIndex].values[day])
            ).toFixed(2)
          );
    return monthValues;
  }, getInitialMonthArray(year));

export const groupDatasetsByMonthsAndDays = (datasets, valueKey) =>
  Object.entries(datasets).map(([year, yearData]) => ({
    year,
    monthsData: groupDatasetByMonthsAndDays(year, yearData, valueKey),
  }));

/**
 * Takes datasets and cumulates (consecutively summarize with each other) them
 * Current fiscal year dataset CAN include data of previous years, and it should be cumulated to the values
 * of current fiscal year (or exercice, same things)
 * @param year - dataset year
 * @param yearData - [{date, valueKey: value},...]
 * @param valueKey - represent meaning of dataset data ('balance', 'debt' etc.)
 * @param firstMonthName - fiscal year lasts for 12 month, but can start at any month
 */
const cumulateDatasetValuesForGivenFiscalYear = (
  year,
  yearData,
  valueKey,
  firstMonthName
) => {
  if (!firstMonthName) {
    return [];
  }
  const orderedInitialArray = getOrderedInitialArray(year, firstMonthName);
  const indexOffset = getMonthIndexFromName(orderedInitialArray[0].name);
  const startingPointYear = indexOffset === 0 ? year : year - 1;
  const fiscalYearStartDate = moment({
    year: startingPointYear,
    month: indexOffset,
  });
  const exerciceStartDatasetIndex = yearData.findIndex(
    ({ date }) => !moment(date).isBefore(fiscalYearStartDate)
  );
  let currentFiscalYearValues = yearData;
  let accumulatedValue = 0;
  if (exerciceStartDatasetIndex !== 0) {
    accumulatedValue = yearData
      .slice(0, exerciceStartDatasetIndex)
      .reduce((sum, entry) => {
        const value = Number(parseFloat(entry[valueKey]).toFixed(2));
        return Number(sum + value);
      }, 0);
    currentFiscalYearValues = yearData.slice(exerciceStartDatasetIndex);
  }
  return currentFiscalYearValues.reduce((monthValues, dayValue) => {
    const { date } = dayValue;
    const value = Number(parseFloat(dayValue[valueKey]).toFixed(2));
    accumulatedValue = Number(
      parseFloat(Number(accumulatedValue) + value).toFixed(2)
    );
    const monthChronoIndex = Number(new Date(Date.parse(date)).getMonth());
    const monthArrayIndex =
      monthChronoIndex - indexOffset < 0
        ? monthChronoIndex - indexOffset + 12
        : monthChronoIndex - indexOffset;
    const day = Number(new Date(Date.parse(date)).getDate() - 1);
    // eslint-disable-next-line no-param-reassign
    monthValues[monthArrayIndex].values[day] = [null, undefined].includes(
      monthValues[monthArrayIndex].values[day]
    )
      ? accumulatedValue
      : Number(
          parseFloat(
            Number(value) + Number(monthValues[monthArrayIndex].values[day])
          ).toFixed(2)
        );
    return monthValues;
  }, orderedInitialArray);
};

/**
 * creates initialMonthArray for each year dataset and fullfill it by dates
 * e.g. from [{2020:[{treasury: 3000, date: 2020-01-01T00:00:000Z},
 *                  {treasury: 2000, date: 2020-03-01T00:00:000Z}]}]
 *      to [{year:2020, monthsData:[{year:2020, name:'January', values:[3000, null, 2000, null, ... null x 27]}, ...]]
 *  @param datasets {array} -> array of datasets e.g above
 *  @param valueKey {string} -> key of value in dataset ('treasury' is value key in above example)
 *  @param lastUpdated {string} -> date of last update, used to limit data for latest exercice
 *  @param currentExercice {string} -> format YYYYMM, MM - last month in fiscal year
 * */
export const mapDashboardDataToChartView = (
  datasets,
  valueKey,
  lastUpdated,
  currentExercice
) =>
  Object.entries(datasets).map(([year, yearData]) => {
    const exerciceLastMonthIndex = currentExercice
      ? Number(currentExercice.slice(4)) - 1
      : null;
    const firstMonthName = exerciceLastMonthIndex
      ? getMonthNameFromIndex(
          exerciceLastMonthIndex === 11 ? 0 : exerciceLastMonthIndex + 1
        )
      : null;
    const cumulMonthsData = cumulateDatasetValuesForGivenFiscalYear(
      year,
      yearData,
      valueKey,
      firstMonthName
    );
    return {
      year,
      monthsData: cumulMonthsData,
    };
  });

export const fillEmptyDates = (cumulatedData, firstMonthName, lastUpdate) =>
  cumulatedData.map(({ year, monthsData }) => {
    let prevCumulValue = 0;
    return {
      year,
      monthsData: monthsData.map(({ year: monthDataYear, name, values }) => ({
        year: monthDataYear,
        name,
        values: values.map((value, dayIndex) => {
          const valueMonthIndex = getMonthIndexFromName(name);
          const firstMonthIndex = getMonthIndexFromName(firstMonthName);
          const currentMonthChartIndex =
            valueMonthIndex - firstMonthIndex >= 0
              ? valueMonthIndex - firstMonthIndex
              : valueMonthIndex - firstMonthIndex + 12;
          if (
            !isDateFurtherThanLimitDate({
              dayIndex: dayIndex + 1,
              monthIndex: valueMonthIndex,
              year: getRealYearValueFromFiscal(
                monthDataYear,
                currentMonthChartIndex,
                firstMonthName
              ),
              lastUpdate,
            }) &&
            value === null
          ) {
            return prevCumulValue;
          }
          prevCumulValue = Number(parseFloat(value).toFixed(2));
          return value;
        }),
      })),
    };
  });

/*
 * dayIndex {number} - day number
 * monthIndex {number} - month index of year (needs to be decreased by 1) => like  new Date().getMonth()
 * year {number} - year
 * returns boolean flag whether date based on inputs is further then limit date(today by default)
 * */
const isDateFurtherThanLimitDate = ({
  dayIndex,
  monthIndex,
  year,
  lastUpdate = new Date().toISOString(),
}) => {
  const limitDate = new Date(lastUpdate);
  const currentMonthIndex = limitDate.getMonth();
  return (
    (dayIndex > limitDate.getDate() &&
      monthIndex === currentMonthIndex &&
      Number(year) === limitDate.getFullYear()) ||
    (monthIndex > currentMonthIndex &&
      Number(year) === limitDate.getFullYear()) ||
    Number(year) > limitDate.getFullYear()
  );
};

export const mapDashboardDataToCumulLastDayAnnual = (
  cumulatedChartData,
  firstMonthName,
  fillDates = false,
  lastUpdate
) => {
  const absolutelyCumulDashboardDataInChartView = fillDates
    ? fillEmptyDates(cumulatedChartData, firstMonthName, lastUpdate)
    : cumulatedChartData;

  return absolutelyCumulDashboardDataInChartView
    .map(({ year, monthsData }) => {
      const monthsValues = monthsData.map(({ values }) => {
        const valuesCopy = [...values];
        return valuesCopy.reverse().find((value) => value !== null);
      });

      return {
        year,
        id: `${year}YearViewMode`,
        values: monthsValues,
      };
    })
    .sort(sortChartDataByYear);
};

/*
 * picks values of givenMonth from dashboard chartView data and returns in structure for chart
 * */
export const getActiveMonthChartData = (
  dashboardDataInChartView,
  activeMonthName
) =>
  dashboardDataInChartView
    .map(({ year, monthsData }) => {
      const activeMonths = monthsData.find(
        (monthData) => monthData.name === activeMonthName
      );

      return {
        year,
        id: `${year}${activeMonthName}MonthViewData`,
        values: activeMonths ? activeMonths.values : [],
      };
    })
    .sort(sortChartDataByYear);
