import React from 'react';
import { Modal, Typography } from 'antd';
import { COMMON_LABELS } from 'const/translations';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';

export const showActionConfirmationModal = ({ handleOk, confirmationText }) =>
  Modal.confirm({
    icon: false,
    onOk: handleOk,
    okText: COMMON_LABELS.YES,
    cancelText: COMMON_LABELS.NO,
    content: (
      <div className={Styles.infoModalRow}>
        <Typography>{confirmationText}</Typography>
      </div>
    ),
    className: Styles.infoModal,
  });
