import { REWARDS_PAGE } from '../translations/SalaryRewardsPage';

export const SALARY_REWARDS_PAGE = {
  SCREEN_WIDTH_BREAKPOINT_VALUE: 767,
};

export const REWARD_SUFFIXES_BY_TYPES = {
  amount: '€',
  hour: 'h',
  number: '',
};

export const REWARDS_GENERAL_TAB = {
  ID: 'all',
  TITLE: REWARDS_PAGE.TABLE_GENERAL_TAB_TITLE,
};
