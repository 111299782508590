import { getType, getActionCreator } from 'utils';
const prefix = '@@app/salaryRewardsTable';

export const GET_EMPLOYEE_REWARDS = getType(prefix, 'GET_EMPLOYEE_REWARDS');
export const getEmployeeRewards = getActionCreator(GET_EMPLOYEE_REWARDS);

export const GET_EMPLOYEE_REWARDS_NEXT_PAGE = getType(
  prefix,
  'GET_EMPLOYEE_REWARDS_NEXT_PAGE'
);
export const getEmployeeRewardsNextPage = getActionCreator(
  GET_EMPLOYEE_REWARDS_NEXT_PAGE
);

export const SET_EMPLOYEE_REWARDS = getType(prefix, 'SET_EMPLOYEE_REWARDS');
export const setEmployeeRewards = getActionCreator(SET_EMPLOYEE_REWARDS);

export const UPSERT_EMPLOYEE_REWARD = getType(prefix, 'UPSERT_EMPLOYEE_REWARD');
export const upsertEmployeeReward = getActionCreator(UPSERT_EMPLOYEE_REWARD);

export const SET_EMPLOYEE_REWARDS_SEARCH = getType(
  prefix,
  'SET_EMPLOYEE_REWARDS_SEARCH'
);
export const setEmployeeRewardsSearch = getActionCreator(
  SET_EMPLOYEE_REWARDS_SEARCH
);

export const SET_EMPLOYEE_REWARDS_STATUS = getType(
  prefix,
  'SET_EMPLOYEE_REWARDS_STATUS'
);
export const setEmployeeRewardsStatus = getActionCreator(
  SET_EMPLOYEE_REWARDS_STATUS
);

export const SET_EMPLOYEE_REWARD_ITEM = getType(
  prefix,
  'SET_EMPLOYEE_REWARD_ITEM'
);
export const setEmployeeRewardItem = getActionCreator(SET_EMPLOYEE_REWARD_ITEM);

export const DOWNLOAD_EMPLOYEES_REWARDS_XLSX = getType(
  prefix,
  'DOWNLOAD_EMPLOYEES_REWARDS_XLSX'
);
export const downloadEmployeesRewardsXlsx = getActionCreator(
  DOWNLOAD_EMPLOYEES_REWARDS_XLSX
);

export const INIT_REWARDS_TABLE_DATA = getType(
  prefix,
  'INIT_REWARDS_TABLE_DATA'
);
export const initRewardsTableData = getActionCreator(INIT_REWARDS_TABLE_DATA);
