import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectUserCompanyId,
  selectUserCurrentCompanyExercice,
  selectUserCurrentCompanyTransition,
} from 'modules/loggedUserInfo/selectors';
import { trimDetailedInfoTableData } from 'helpers/common';

import {
  DEBTOR_DATA_REQUEST,
  debtorDataError,
  debtorDataSuccess,
  DEBTORS_SEARCH_REQUEST,
  debtorsSearchError,
  debtorsSearchSuccess,
} from '../actions';
import { getDebtorData, getDebtorsRequest } from '../services';
import { mapDebtorsResponseToReduxStore } from '../helpers';

function* getDebtors({ payload }) {
  try {
    const id = yield select(selectUserCompanyId);
    const currentExercice = yield select(selectUserCurrentCompanyExercice);
    const transition = yield select(selectUserCurrentCompanyTransition);
    const { details, datasets, lastUpdated, meta } = yield call(
      getDebtorsRequest,
      {
        id,
        currentExercice,
        transition,
      }
    );
    const mappedToReduxData = mapDebtorsResponseToReduxStore(details);
    yield put(
      debtorsSearchSuccess({
        data: mappedToReduxData,
        datasets,
        lastUpdated,
        meta,
        payload,
      })
    );
  } catch (error) {
    yield put(debtorsSearchError({ error }));
  }
}

function* getDebtorDataWorker({ payload: { currentDosId: dosId, code } }) {
  try {
    const exercice = yield select(selectUserCurrentCompanyExercice);
    const transition = yield select(selectUserCurrentCompanyTransition);
    const { data, accountName } = yield call(getDebtorData, {
      dosId,
      code,
      exercice,
      transition,
    });
    yield put(
      debtorDataSuccess({ data: trimDetailedInfoTableData(data), accountName })
    );
  } catch (error) {
    yield put(debtorDataError({ error }));
  }
}

export function* debtorsMainSaga() {
  yield takeLatest(DEBTORS_SEARCH_REQUEST, getDebtors);
  yield takeLatest(DEBTOR_DATA_REQUEST, getDebtorDataWorker);
}
