import PropTypes from 'prop-types';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const/index';

export { formikTypes } from 'const/propTypes/formikTypes';
export { formikOnChangeAdaptorTypes } from 'const/propTypes/formikOnChangeAdaptorTypes';
export const PROP_TYPE_STATUS = PropTypes.oneOf([
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  STATUS_LOADING,
  STATUS_ERROR,
]);
