import { getType, getActionCreator } from 'utils';
const prefix = '@@app/NPS';

export const VOTE_NPS_REQUEST = getType(prefix, 'VOTE_NPS_REQUEST');
export const voteNpsRequest = getActionCreator(VOTE_NPS_REQUEST);

const VOTE_NPS_SUCCESS = getType(prefix, 'VOTE_NPS_SUCCESS');
export const voteNpsSuccess = getActionCreator(VOTE_NPS_SUCCESS);

const VOTE_NPS_ERROR = getType(prefix, 'VOTE_NPS_ERROR');
export const voteNpsError = getActionCreator(VOTE_NPS_ERROR);
