import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Row, Col, Typography } from 'antd';
import { Button, Radio, Checkbox, Select } from 'components/ui';
import { withFormik } from 'formik';
import { omitEmptyKeys, updateField } from 'helpers/common';
import MainStyles from 'assets/styles/main.module.css';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';
import { formikTypes } from 'const/propTypes';
import { validationSchema } from './SpecialSituations.yupValidationSchema';
import Styles from './SpecialSituations.module.css';
import { SharedNotification } from '../SharedNotification';

const {
  SPECIAL_SITUATIONS_FORM_TAB: { LABELS, LISTS },
  GENERAL_LABELS,
} = ADD_EMPLOYEE_FORM_TRANSLATIONS;

const propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  values: PropTypes.shape({
    statusEmployee: PropTypes.string.isRequired,
    isEmployerRelative: PropTypes.bool.isRequired,
    isHandicapped: PropTypes.bool.isRequired,
  }),
  updateSpecialSituations: PropTypes.func.isRequired,
  ...formikTypes,
};

/**
 * Added abstraction that consists of withFormik HOC
 * and props passed to it. Wraps component on export,
 * passing all formik functionality and props to it.
 */
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({ specialSituationsData = {} }) => ({
    statusEmployee: specialSituationsData.statusEmployee || LABELS.NOT_SELECTED,
    isEmployerRelative: specialSituationsData.isEmployerRelative || false,
    isHandicapped: specialSituationsData.isHandicapped || false,
    relationshipParentWithEmployerDenote:
      specialSituationsData.relationshipParentWithEmployerDenote || undefined,
  }),
  mapPropsToErrors: ({ specialSituationsErrors = {} }) =>
    specialSituationsErrors,
});

const SpecialSituationsTab = ({
  onNext,
  onPrev,
  updateSpecialSituations,
  values,
  errors,
  handleChange,
  validateForm,
  setFieldValue,
  setErrors,
  isTabValid,
  specialSituationsData,
  resetData,
}) => {
  /**
   * Next two useEffect hooks imitate
   * componentWillUnmount lifecycle method behavior.
   */
  const valuesRef = useRef();
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);
  useEffect(
    () => () => {
      validateForm().then((foundErrors) => {
        const isValid = !Object.keys(foundErrors).length;
        updateSpecialSituations({
          values: {
            ...omitEmptyKeys(valuesRef.current),
            isValid,
          },
          specialSituationsErrors: foundErrors,
          isValid,
        });
      });
    },
    [validateForm, updateSpecialSituations]
  );
  /**
   * Additional validation in case tab data has come from backend,
   * where only values and status are saved,
   */
  useEffect(() => {
    if (Object.values(specialSituationsData).length && !isTabValid) {
      validateForm().then((foundErrors) => {
        setErrors(foundErrors);
      });
    }
  }, [values, isTabValid, setErrors, specialSituationsData, validateForm]);

  const updateRelationship = React.useCallback(
    updateField('relationshipParentWithEmployerDenote', setFieldValue),
    [setFieldValue]
  );

  return (
    <Form>
      <Row className={MainStyles.row}>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.EMPLOYEE_STATUS}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Radio
              role="radiogroup"
              name="statusEmployee"
              onChange={handleChange}
              defaultValue={values.statusEmployee}
              options={LISTS.EMPLOYEE_STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.IS_EMPLOYER_RELATIVE}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              role="checkbox"
              name="isEmployerRelative"
              checked={values.isEmployerRelative}
              onChange={handleChange}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
            {values.isEmployerRelative && (
              <Form.Item
                className={Styles.formItem}
                validateStatus={
                  errors.relationshipParentWithEmployerDenote ? 'error' : ''
                }
              >
                <Select
                  placeholder={GENERAL_LABELS.CHOOSE}
                  onChange={updateRelationship}
                  name="relationshipParentWithEmployerDenote"
                  value={values.relationshipParentWithEmployerDenote}
                  options={LISTS.RELATIONS}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={24} className={MainStyles.col}>
          <Typography.Paragraph
            className={clsx(MainStyles.formLabel, Styles.formLabel)}
          >
            {LABELS.IS_HANDICAPPED}
          </Typography.Paragraph>
          <Form.Item className={Styles.formItem}>
            <Checkbox
              role="checkbox"
              name="isHandicapped"
              checked={values.isHandicapped}
              onChange={handleChange}
            >
              {GENERAL_LABELS.YES}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {/* TODO: Move out as separate component */}
      <SharedNotification />
      <div className={clsx(MainStyles.buttonWrapper, Styles.buttonWrapper)}>
        <Button role="button" big outlined onClick={onPrev}>
          {GENERAL_LABELS.PREV_TAB}
        </Button>
        <Button outlined role="button" big onClick={resetData}>
          {GENERAL_LABELS.RESET}
        </Button>
        <Button role="button" big onClick={onNext}>
          {GENERAL_LABELS.NEXT_TAB}
        </Button>
      </div>
    </Form>
  );
};

SpecialSituationsTab.propTypes = propTypes;

export const SpecialSituationsFormTab = formikEnhancer(SpecialSituationsTab);
