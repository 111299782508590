import { ADDITIONAL_USERS_MODAL_TRANSLATIONS } from '../translations/AdditionalUsers';

export const ADDITIONAL_USERS_LOCAL_STORAGE_KEYS = {
  DATASET_KEY: 'additionalUsersPageForm',
  DELETED_USERS_KEY: 'deletedAdditionalUsers',
};

export const MODAL_ERRORS = {
  REQUIRED: 'required',
  WRONG_FORMAT: 'format error',
  EMPTY_MOUDLES: 'empty modules',
};

export const MODAL_ERRORS_BY_STATUS = {
  [MODAL_ERRORS.REQUIRED]: ADDITIONAL_USERS_MODAL_TRANSLATIONS.REQUIRED_ERROR,
  [MODAL_ERRORS.WRONG_FORMAT]: ADDITIONAL_USERS_MODAL_TRANSLATIONS.EMAIL_ERROR,
  [MODAL_ERRORS.EMPTY_MOUDLES]:
    ADDITIONAL_USERS_MODAL_TRANSLATIONS.MODULES_ERROR,
};
