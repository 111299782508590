import { takeLatest, call, select, put } from 'redux-saga/effects';
import {
  GET_TREASURY_DATA_REQUEST,
  GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST,
  getTreasuryDataError,
  getTreasuryDataSuccess,
  getTreasurySingleAccountDataError,
  getTreasurySingleAccountDataSuccess,
  setTreausrySingleAccountStatusLoading,
} from 'modules/treasuryPage/actions';
import {
  getTreasuryData,
  getTreasurySingleAccountData,
} from 'modules/treasuryPage/services';
import {
  selectUserCurrentCompanyDosId,
  selectUserCurrentCompanyExercice,
  selectUserCurrentCompanyTransition,
} from 'modules/loggedUserInfo/selectors';

function* getTreasuryDataWorker({ payload: { singleAccount = false } }) {
  try {
    if (singleAccount) {
      yield put(setTreausrySingleAccountStatusLoading({ singleAccount }));
    }

    const currentCompanyId = yield select(selectUserCurrentCompanyDosId);
    const currentExercice = yield select(selectUserCurrentCompanyExercice);

    if (currentExercice && currentCompanyId) {
      const transition = yield select(selectUserCurrentCompanyTransition);

      const treasuryData = yield call(getTreasuryData, {
        currentExercice,
        transition,
        currentCompanyId,
      });

      yield put(getTreasuryDataSuccess({ data: treasuryData, singleAccount }));
    } else {
      throw Error('currentExercice && currentCompanyId not found');
    }
  } catch (e) {
    yield put(getTreasuryDataError({ error: e, singleAccount }));
  }
}

function* getTreasurySingleAccountDataWorker({ payload: { accountName } }) {
  try {
    const currentCompanyId = yield select(selectUserCurrentCompanyDosId);
    const currentExercice = yield select(selectUserCurrentCompanyExercice);
    const transition = yield select(selectUserCurrentCompanyTransition);
    const treasuryAccountData = yield call(getTreasurySingleAccountData, {
      currentExercice,
      transition,
      currentCompanyId,
      accountName,
    });
    yield put(
      getTreasurySingleAccountDataSuccess({ data: treasuryAccountData })
    );
  } catch (e) {
    yield put(getTreasuryDataError({ error: e }));
    yield put(getTreasurySingleAccountDataError({ error: e }));
  }
}

export function* treasuryMainSaga() {
  yield takeLatest(GET_TREASURY_DATA_REQUEST, getTreasuryDataWorker);
  yield takeLatest(
    GET_TREASURY_SINGLE_ACCOUNT_DATA_REQUEST,
    getTreasurySingleAccountDataWorker
  );
}
