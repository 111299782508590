import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { Checkbox } from 'components/ui';
import { INVOICE_SEARCH_COMMENTARY_MODAL } from 'const/translations';
import { useFormik } from 'formik';
import { Button } from 'components/ui/Button';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch } from 'react-redux';
import { invoicesAddCommentRequest } from 'modules/invoiceSearch/actions';
import { useInvoiceComments } from 'helpers/InvoiceSearchCommentaryModal';
import Styles from './InvoiceSearchCommentaryModal.module.css';
import { CommentsList } from './CommentsList';

const { TextArea } = Input;

const propTypes = {
  handleClose: PropTypes.func,
  documentId: PropTypes.string,
};

const {
  NEW_COMMENTARY,
  CANCEL_BUTTON,
  OK_BUTTON,
  CHECKBOX_LABEL,
  ERROR_REQUIRED_COMMENT,
} = INVOICE_SEARCH_COMMENTARY_MODAL;

export const CommentaryModal = ({ handleClose, documentId }) => {
  const dispatch = useDispatch();

  const currentInvoiceComments = useInvoiceComments(documentId);

  const onSubmit = useCallback(
    (values) => {
      dispatch(invoicesAddCommentRequest({ ...values, documentId }));
      handleClose();
    },
    [dispatch, documentId, handleClose]
  );

  const initialValues = {
    comment: '',
    notifyCollaborater: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: (valuesToValidate) => {
      const errorsNew = {};
      if (valuesToValidate.comment === '') {
        errorsNew.comment = ERROR_REQUIRED_COMMENT;
      }
      return errorsNew;
    },
    validateOnBlur: true,
    validateOnMount: true,
  });

  const handleChange = ({ target: { name, value } }) => {
    formik.touched[name] = true;
    formik.setFieldValue(name, value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <CommentsList commentaryArray={currentInvoiceComments} />
      <div className={clsx(Styles.commentaryModalRow, Styles.textDefault)}>
        <h2 className={clsx(Styles.textDefault)}>{NEW_COMMENTARY}</h2>
        <TextArea
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
          name="comment"
        />
      </div>
      <Checkbox
        className={clsx(Styles.textDefault, Styles.checkbox)}
        onChange={handleChange}
        value={!formik.values.notifyCollaborater}
        checked={formik.values.notifyCollaborater}
        name="notifyCollaborater"
      >
        {CHECKBOX_LABEL}
      </Checkbox>
      <div
        className={clsx(MainStyles.buttonWrapper, Styles.modalButtonWrapper)}
      >
        <Button
          className={Styles.button}
          variant="secondary"
          onClick={handleClose}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          outlined
          disabled={Boolean(formik.errors.comment)}
          className={Styles.button}
          variant="secondary"
          type="submit"
        >
          {OK_BUTTON}
        </Button>
      </div>
    </form>
  );
};

CommentaryModal.propTypes = propTypes;
