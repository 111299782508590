import { fromJS } from 'immutable';
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_ERROR,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_ERROR,
  TEMPORARY_PASSWORD_CHANGE_ERROR,
  TEMPORARY_PASSWORD_CHANGE_REQUEST,
} from 'modules/Authentication/actions';

const getTypicalStoreStructure = () => ({
  errorStatusCode: null,
  message: '',
  enteredEmail: '',
});

const initialState = fromJS(getTypicalStoreStructure());

/**
 * Token storage and usage is handled by request
 * @see utils/request
 */

export const authError = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return initialState;
    case LOGIN_ERROR:
      return state
        .set('errorStatusCode', action.payload.error.statusCode)
        .set('message', action.payload.error.error);
    default:
      return state;
  }
};

export const passwordRecovery = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RECOVERY_REQUEST:
      return initialState;
    case PASSWORD_RECOVERY_SUCCESS:
      return state.set('enteredEmail', action.payload.email);
    case PASSWORD_RECOVERY_ERROR:
      return state.set('errorStatusCode', action.payload.error.statusCode);
    default:
      return state;
  }
};

export const passwordChangeError = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_CHANGE_REQUEST:
    case TEMPORARY_PASSWORD_CHANGE_REQUEST:
      return initialState;
    case PASSWORD_CHANGE_ERROR:
    case TEMPORARY_PASSWORD_CHANGE_ERROR:
      return state.set('errorStatusCode', action.payload.error.statusCode);
    default:
      return state;
  }
};
