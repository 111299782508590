import {
  INVOICE_SEARCH_TABLE_SIZE_OPTIONS,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import { fromJS } from 'immutable';
import {
  INVOICES_SEARCH_REQUEST,
  INVOICES_SEARCH_SUCCESS,
  INVOICES_SEARCH_ERROR,
  INVOICES_SEARCH_INIT,
  INVOICES_SEARCH_SET_SORT,
  INVOICES_NEXT_PAGE_REQUEST,
  INVOICES_NEXT_PAGE_SUCCESS,
  INVOICES_NEXT_PAGE_ERROR,
  INVOICES_APPROVE_REQUEST,
  INVOICES_APPROVE_SUCCESS,
  INVOICES_APPROVE_ERROR,
  INVOICES_SEND_BY_EMAIL_REQUEST,
  INVOICES_SEND_BY_EMAIL_SUCCESS,
  INVOICES_SEND_BY_EMAIL_ERROR,
  CREATE_INVOICE_IN_LIBEO_SUCCESS,
  INVOICES_DEVALIDATE_REQUEST,
  INVOICES_DEVALIDATE_SUCCESS,
  INVOICES_DEVALIDATE_ERROR,
  INVOICES_ACCEPT_REQUEST,
  INVOICES_ACCEPT_SUCCESS,
  INVOICES_ACCEPT_ERROR,
  INVOICES_DELETE_REQUEST,
  INVOICES_DELETE_SUCCESS,
  INVOICES_DELETE_ERROR,
  GET_PAY_INVOICE_URL_SUCCESS,
  RESET_PAY_INVOICE_URL,
  MASS_INVOICES_APPROVE_REQUEST,
  MASS_INVOICES_APPROVE_SUCCESS,
  INVOICES_ADD_COMMENT_SUCCESS,
  INVOICES_ADD_COMMENT_ERROR,
  INVOICES_PREVALIDATE_ERROR,
  INVOICES_PREVALIDATE_SUCCESS,
  CANCEL_INVOICE_PREVALIDATION_SUCCESS,
  INVOICES_PAID_OUTSIDE_SUCCESS,
  MASS_INVOICES_PAID_OUTSIDE_SUCCESS,
  MASS_INVOICES_PAID_OUTSIDE_REQUEST,
  INVOICES_PAID_OUTSIDE_REQUEST,
  INVOICES_DEVALIDATE_AND_APPROVE_REQUEST,
  INVOICES_DEVALIDATE_AND_APPROVE_SUCCESS,
  INVOICES_DEVALIDATE_AND_APPROVE_ERROR,
  INVOICES_NEXT_PAGE_NOT_NEEDED,
  OPEN_INVOICE_REQUEST,
  OPEN_INVOICE_SUCCESS,
  OPEN_INVOICE_ERROR,
  DOWNLOAD_INVOICE_REQUEST,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_ERROR,
  STORE_FILE,
  DOWNLOAD_DOCUMENTS_REQUEST,
  DOWNLOAD_DOCUMENTS_SUCCESS,
  DOWNLOAD_DOCUMENTS_ERROR,
} from '../actions';
import { mapInvoicesResponseToReduxStore } from '../helpers';

const initialState = fromJS({
  data: [],
  totals: {
    totalTVA: 'N/A',
    totalHT: 'N/A',
    totalTTC: 'N/A',
  },
  meta: {},
  storedPayload: {},
  paymentLink: '',
  tableItemsPerPage: INVOICE_SEARCH_TABLE_SIZE_OPTIONS[0],
  tableCurrentPage: 1,
  tableSort: null,
  status: STATUS_NOT_REQUESTED,
  approveStatus: STATUS_NOT_REQUESTED,
  invoicesOpeningList: [],
  invoicesDownloadingList: [],
  invoicesSendingList: [],
  approveInProgressId: '',
  error: '',
  file: {},
  downloadDocumentsIsLoading: false,
});

export const invoiceSearch = (state = initialState, action) => {
  switch (action.type) {
    case INVOICES_SEARCH_INIT:
      return initialState;
    case INVOICES_SEARCH_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('storedPayload', initialState.storedPayload)
        .set('tableCurrentPage', initialState.tableCurrentPage);
    case INVOICES_SEARCH_SUCCESS: {
      const { totalsObject } = action.payload;

      const currentTotalsObjectImmutable = state.getIn(['totals']);
      const currentTotalsJsObject = currentTotalsObjectImmutable
        ? currentTotalsObjectImmutable.toJS()
        : initialState.totals.toJS();

      return state
        .set('data', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set(
          'totals',
          totalsObject ? fromJS(totalsObject) : fromJS(currentTotalsJsObject)
        )
        .set('storedPayload', fromJS(action.payload.payload))
        .set('status', STATUS_SUCCESS);
    }
    case INVOICES_SEARCH_ERROR:
      return state
        .set('error', action.payload.error.message || 'error')
        .set('totals', initialState.totals)
        .set('status', STATUS_ERROR);
    case INVOICES_SEARCH_SET_SORT:
      return state.set('tableSort', action.payload.sort);
    case INVOICES_NEXT_PAGE_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('tableItemsPerPage', action.payload.itemsPerPage)
        .set('tableCurrentPage', action.payload.page);
    case INVOICES_NEXT_PAGE_SUCCESS:
      return state
        .set('data', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('status', STATUS_SUCCESS);
    case INVOICES_NEXT_PAGE_NOT_NEEDED:
      return state.set('status', STATUS_SUCCESS);
    case INVOICES_NEXT_PAGE_ERROR:
      return state
        .set('error', action.payload.error.message || 'error')
        .set('status', STATUS_ERROR);
    case INVOICES_DELETE_REQUEST:
    case INVOICES_ACCEPT_REQUEST:
    case INVOICES_APPROVE_REQUEST:
    case INVOICES_PAID_OUTSIDE_REQUEST:
    case INVOICES_DEVALIDATE_AND_APPROVE_REQUEST:
    case INVOICES_DEVALIDATE_REQUEST:
      return state
        .set('approveStatus', STATUS_LOADING)
        .set('approveInProgressId', action.payload.documentId)
        .set('error', '');
    case MASS_INVOICES_PAID_OUTSIDE_REQUEST:
    case MASS_INVOICES_APPROVE_REQUEST:
      return state
        .set('approveStatus', STATUS_LOADING)
        .set('approveInProgressId', action.payload.invoicesId)
        .set('error', '');
    case MASS_INVOICES_PAID_OUTSIDE_SUCCESS:
    case MASS_INVOICES_APPROVE_SUCCESS:
      return state
        .update('data', (invoices) => {
          const fetchedInvoicesMappedToStore = mapInvoicesResponseToReduxStore(
            action.payload.documents
          );

          return invoices.filter(
            (invoice) =>
              fetchedInvoicesMappedToStore.find(
                ({ id }) => id === invoice.get('id')
              ) === undefined
          );
        })
        .update('meta', (meta) => {
          const jsMeta = meta.toJS();

          return fromJS({
            ...jsMeta,
            total: jsMeta.total - state.getIn(['approveInProgressId']).length,
          });
        })
        .set('approveStatus', STATUS_SUCCESS)
        .set('approveInProgressId', initialState.approveInProgressId);
    case INVOICES_DEVALIDATE_SUCCESS:
    case INVOICES_PAID_OUTSIDE_SUCCESS:
    case CANCEL_INVOICE_PREVALIDATION_SUCCESS:
    case INVOICES_DELETE_SUCCESS:
    case INVOICES_PREVALIDATE_SUCCESS:
    case INVOICES_APPROVE_SUCCESS:
    case INVOICES_ACCEPT_SUCCESS:
      return state
        .update('data', (invoices) => {
          const isAllFilter =
            state.get('storedPayload').get('paymentStatus') === 'all';

          const index = invoices.findIndex(
            (invoice) => invoice.get('id') === action.payload.invoiceData.id
          );

          const isPreValidated = Boolean(
            action.payload.invoiceData.preValidated
          );

          if (isAllFilter) {
            return invoices.mergeIn(
              [index],
              fromJS({
                ...action.payload.invoiceData,
                paymentStatus: 'accepted',
                preValidated: isPreValidated,
              })
            );
          }

          return invoices.filter(
            (invoice) => invoice.get('id') !== action.payload.invoiceData.id
          );
        })
        .update('meta', (meta) => {
          const isAllFilter =
            state.get('storedPayload').get('paymentStatus') === 'all';
          const jsMeta = meta.toJS();
          const total = isAllFilter ? jsMeta.total : jsMeta.total - 1;

          return fromJS({
            ...jsMeta,
            total,
          });
        })
        .set('approveStatus', STATUS_SUCCESS)
        .set('approveInProgressId', initialState.approveInProgressId);
    case INVOICES_DEVALIDATE_AND_APPROVE_SUCCESS:
      return state
        .update('data', (invoices) => {
          const isAllFilter =
            state.get('storedPayload').get('paymentStatus') === 'all';

          const index = invoices.findIndex(
            (invoice) => invoice.get('id') === action.payload.invoiceData.id
          );

          if (isAllFilter) {
            return invoices.setIn(
              [index],
              fromJS({
                ...action.payload.invoiceData,
                paymentStatus: 'notValidated',
              })
            );
          }

          return invoices.filter(
            (invoice) => invoice.get('id') !== action.payload.invoiceData.id
          );
        })
        .update('meta', (meta) => {
          const isAllFilter =
            state.get('storedPayload').get('paymentStatus') === 'all';
          const jsMeta = meta.toJS();
          const total = isAllFilter ? jsMeta.total : jsMeta.total - 1;

          return fromJS({
            ...jsMeta,
            total,
          });
        })
        .set('approveStatus', STATUS_SUCCESS)
        .set('approveInProgressId', initialState.approveInProgressId);
    case INVOICES_ADD_COMMENT_SUCCESS:
      return state.update('data', (invoices) => {
        const index = invoices.findIndex(
          (invoice) => invoice.get('id') === action.payload.invoiceData.id
        );

        return invoices.mergeIn([index], fromJS(action.payload.invoiceData));
      });
    case INVOICES_ADD_COMMENT_ERROR:
    case INVOICES_DELETE_ERROR:
    case INVOICES_ACCEPT_ERROR:
    case INVOICES_PREVALIDATE_ERROR:
      return state.set('error', action.payload.error.message || 'error');
    case INVOICES_APPROVE_ERROR:
    case INVOICES_DEVALIDATE_AND_APPROVE_ERROR:
    case INVOICES_DEVALIDATE_ERROR:
      return state
        .set('error', action.payload.error.message || 'error')
        .set('approveStatus', STATUS_ERROR)
        .set('approveInProgressId', initialState.get('approveInProgressId'));
    case CREATE_INVOICE_IN_LIBEO_SUCCESS:
      return state.update('data', (invoices) => {
        const index = invoices.findIndex(
          (invoice) => invoice.get('id') === action.payload.invoiceData.id
        );
        return invoices.mergeIn([index], fromJS(action.payload.invoiceData));
      });
    case GET_PAY_INVOICE_URL_SUCCESS:
      return state.set('paymentLink', action.payload.url);
    case RESET_PAY_INVOICE_URL:
      return state.set('paymentLink', initialState.get('paymentLink'));
    case OPEN_INVOICE_REQUEST:
      return state.update('invoicesOpeningList', (invoiceIds) =>
        invoiceIds.push(fromJS(action.payload.documentId))
      );
    case OPEN_INVOICE_SUCCESS:
    case OPEN_INVOICE_ERROR:
      return state.update('invoicesOpeningList', (invoiceIds) =>
        invoiceIds.filter((id) => id !== action.payload.documentId)
      );
    case DOWNLOAD_INVOICE_REQUEST:
      return state.update('invoicesDownloadingList', (invoiceIds) =>
        invoiceIds.push(fromJS(action.payload.documentId))
      );
    case DOWNLOAD_INVOICE_SUCCESS:
    case DOWNLOAD_INVOICE_ERROR:
      return state.update('invoicesDownloadingList', (invoiceIds) =>
        invoiceIds.filter((id) => id !== action.payload.documentId)
      );
    case INVOICES_SEND_BY_EMAIL_REQUEST:
      return state.update('invoicesSendingList', (invoiceIds) =>
        invoiceIds.push(fromJS(action.payload.documentId))
      );
    case INVOICES_SEND_BY_EMAIL_SUCCESS:
    case INVOICES_SEND_BY_EMAIL_ERROR:
      return state.update('invoicesSendingList', (invoiceIds) =>
        invoiceIds.filter((id) => id !== action.payload.documentId)
      );
    case STORE_FILE: {
      const { documentId, fileData } = action.payload;
      return state.set('file', { documentId, fileData });
    }

    case DOWNLOAD_DOCUMENTS_REQUEST:
      return state.set('downloadDocumentsIsLoading', true);
    case DOWNLOAD_DOCUMENTS_SUCCESS:
    case DOWNLOAD_DOCUMENTS_ERROR:
      return state.set('downloadDocumentsIsLoading', false);

    default:
      return state;
  }
};
