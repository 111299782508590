import React from 'react';
import PropTypes from 'prop-types';
import { LandingAdvantage } from 'components/LandingAdvantagesSection/LandingAdvantage';
import { MyExcoLandingAdvantage } from 'components/LandingAdvantagesSection/MyExcoLandingAdvantage';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { PACKAGES_NAMES } from 'const/packages';
import { WaibiLandingAdvantage } from 'components/LandingAdvantagesSection/WaibiLandingAdvantage';
import Styles from './LandingAdvantagesSection.module.css';

const landings = {
  [PACKAGES_NAMES.MY_EXCO]: MyExcoLandingAdvantage,
  [PACKAGES_NAMES.WAIBI]: WaibiLandingAdvantage,
};

const propTypes = {
  advantages: PropTypes.array,
  pageName: PropTypes.string,
};

export const LandingAdvantagesSection = ({ advantages, pageName }) => {
  const AdvantageComponent = landings[pageName] || LandingAdvantage;

  return (
    <div className={clsx(Styles.wrapper, MainStyles.container)}>
      {advantages.map((advantage) => (
        <AdvantageComponent key={advantage.id} {...advantage} />
      ))}
    </div>
  );
};

LandingAdvantagesSection.propTypes = propTypes;
