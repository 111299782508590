import {
  CART_PAGE_DATASET_KEY,
  CART_PAGE_DELETED_USERS_KEY,
} from 'const/cartPage';
import { ADDITIONAL_USERS_LOCAL_STORAGE_KEYS } from 'const/additionalUsers';
import { removeFromStorage } from 'helpers/storage';

export const clearCartAndAdditionalUsersLocalStorage = () => {
  removeFromStorage({
    key: [
      CART_PAGE_DATASET_KEY,
      CART_PAGE_DELETED_USERS_KEY,
      ADDITIONAL_USERS_LOCAL_STORAGE_KEYS.DATASET_KEY,
      ADDITIONAL_USERS_LOCAL_STORAGE_KEYS.DELETED_USERS_KEY,
    ],
  });
};
