import { Col, Icon, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { UploadFormik } from 'components/ui';
import { downloadFile } from 'helpers/common';
import { downloadInitialAbsenceSupportingDoc } from 'modules/SalaryModule/Vacations/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { VACATION_MODAL } from 'const/translations/SalaryVacations';
import Styles from '../AddVacationModal.module.css';

const { Text } = Typography;
const {
  ACCEPT_FORMATS,
  LABEL_TEXTS: { FILE, OPTIONAL, FILE_BTN },
} = VACATION_MODAL;

const propTypes = {
  file: PropTypes.array,
  setFile: PropTypes.func,
  hasDownload: PropTypes.bool,
};

export const VacationModalFile = memo(
  ({ file, setFile, hasDownload = false }) => {
    const handleFileChange = (_, fileData) => {
      setFile(fileData);
    };

    const dispatch = useDispatch();

    const { id: companyId } = useSelector(selectUserCurrentCompany);

    const onFileDownload = useCallback(
      (fileToDownload, fileName) => {
        if (!fileToDownload || !hasDownload) {
          return;
        }

        if (fileToDownload.originFileObj) {
          const fileData = new Blob([fileToDownload.originFileObj], {
            type: fileToDownload.originFileObj.type,
          });

          downloadFile(fileData, fileName);
          return;
        }

        dispatch(
          downloadInitialAbsenceSupportingDoc({
            fileId: fileToDownload.uid,
            companyId,
          })
        );
      },
      [companyId, dispatch, hasDownload]
    );

    return (
      <Row type="flex" className={Styles.row}>
        <Col xs={{ span: 24 }} sm={{ span: 6 }} className={Styles.col}>
          <div className={Styles.labelWrapper}>
            <Text className={Styles.formLabel}>{FILE}</Text>
            <Text className={Styles.formHelp}>{OPTIONAL}</Text>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} className={Styles.col}>
          <UploadFormik
            buttonText={FILE_BTN}
            onChange={handleFileChange}
            name="document"
            multiple={false}
            uploadedFiles={file}
            maxFilesCount={1}
            accept={ACCEPT_FORMATS}
            classNameFileList={Styles.fileItem}
            hasDownload={hasDownload}
            onFileDownload={onFileDownload}
            removeIcon={
              <Icon
                type="delete"
                theme="filled"
                className={Styles.removeIcon}
              />
            }
          />
        </Col>
      </Row>
    );
  }
);

VacationModalFile.propTypes = propTypes;
