import React, { memo, useCallback } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { PageTitle } from 'components/SalaryComponents/PageTitle';
import { EMPLOYEE_HEADER } from 'const/translations/SalaryEmployeePage';
import Styles from './MobileFilters.module.css';
import { Button, CustomSelect } from '../../../../components/ui';
import {
  selectClassificationOptions,
  selectCompanyBranchesOptions,
  selectContractOptions,
  selectNatureEmploymentOptions,
} from '../../../../modules/SalaryModule/Employees/selectors';

const { FILTER_PLACEHOLDERS, BUTTONS_LABELS, FILTER_TITLE } = EMPLOYEE_HEADER;

const propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export const MobileFilters = memo(
  ({ isVisible = true, onClose, onSubmit, initialValues }) => {
    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const establishments = useSelector(selectCompanyBranchesOptions);
    const natureEmployments = useSelector(selectNatureEmploymentOptions);
    const classifications = useSelector(selectClassificationOptions);
    const contracts = useSelector(selectContractOptions);

    return (
      <Modal
        footer={null}
        visible={isVisible}
        wrapClassName={Styles.content}
        width="100%"
        closable={false}
        onCancel={onClose}
      >
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values, setFieldValue }) => {
            const onChange = (name, value = '') => {
              setFieldValue(name, value);
            };
            return (
              <>
                <PageTitle className={Styles.textTitle}>
                  {FILTER_TITLE}
                </PageTitle>
                <CustomSelect
                  name="establishments"
                  size="large"
                  className={Styles.select}
                  options={establishments}
                  onChange={onChange}
                  value={values.establishments}
                  placeholder={FILTER_PLACEHOLDERS.ESTABLISHMENT}
                  allowClear
                />
                <CustomSelect
                  name="natureEmployments"
                  size="large"
                  className={Styles.select}
                  options={natureEmployments}
                  onChange={onChange}
                  value={values.natureEmployments}
                  placeholder={FILTER_PLACEHOLDERS.NATURE_EMPLOYMENT}
                  allowClear
                  hasTooltip
                />
                <CustomSelect
                  name="classifications"
                  size="large"
                  className={Styles.select}
                  options={classifications}
                  onChange={onChange}
                  value={values.classifications}
                  placeholder={FILTER_PLACEHOLDERS.CLASSIFICATION}
                  allowClear
                />
                <CustomSelect
                  name="contracts"
                  size="large"
                  className={Styles.select}
                  options={contracts}
                  onChange={onChange}
                  value={values.contracts}
                  placeholder={FILTER_PLACEHOLDERS.CONTRACT}
                  allowClear
                />
                <div className={Styles.buttonsWrapper}>
                  <Button
                    className={Styles.btn}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    {BUTTONS_LABELS.SAVE}
                  </Button>
                  <Button
                    className={Styles.btn}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    {BUTTONS_LABELS.CANCEL}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  }
);

MobileFilters.propTypes = propTypes;
