import { MODIFY_LEAVE_TRANLATIONS } from 'const/translations/SalaryCalendar';

export const getCalendarOverlayEventText = (leaveType) =>
  MODIFY_LEAVE_TRANLATIONS[leaveType];

export const getTablePaginationRange = (range0, range1, total) =>
  `Affichage de l'élément ${range0} à ${range1} sur ${total}`;

export const getIsoFormatFromDateWithSlashes = (dateWithSlashes) => {
  const [day, month, year] = dateWithSlashes.split('/');
  return `${year}-${month}-${day}`;
};
