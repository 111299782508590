import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import {
  LEAVES_TYPES,
  SALARY_CALENDAR_TRANSLATIONS,
} from 'const/translations/SalaryCalendar';
import { getCalendarOverlayEventText } from 'helpers/salaryHelpers/calendar';
import Styles from 'containers/Salary/CalendarPage/CalendarPage.module.css';

const {
  MOTIF,
  FROM,
  TO,
  ADD_ABSENCE,
  ADD_MASS_ABSENCE,
  ADD_VACATION,
  ADD_MASS_VACATION,
  DOWNLOAD_ATTACHMENT,
} = SALARY_CALENDAR_TRANSLATIONS;

const { LEAVE_VACATION, LEAVE_ABSENCE } = LEAVES_TYPES;

export default (
  isCellLoading,
  employee,
  dateWithSlashes,
  leaveObj,
  openLeaveModal,
  hasAttachment,
  downloadAttachment,
  selectedRowKeys,
  isMorning,
  isAfternoon
) => {
  const eventText = leaveObj
    ? getCalendarOverlayEventText(leaveObj?.reason?.type)
    : '';
  const reasonText = leaveObj?.reason?.title;
  const startDate = leaveObj ? moment(leaveObj.start).format('L') : '';
  const endDate = leaveObj ? moment(leaveObj.end).format('L') : '';
  const employeeId = employee.employeeid;

  if (isCellLoading || !employeeId) {
    return <div />;
  }

  return leaveObj ? (
    <div className={Styles.overlay}>
      <Typography.Text className={Styles.reasonTextWrapper}>
        <span className={Styles.motif}>{`${MOTIF}: ${reasonText}`}</span>
        <br />
        <span className={Styles.motif}>{`${FROM}: ${startDate}`}</span>
        <br />
        <span className={Styles.motif}>{`${TO}: ${endDate}`}</span>
      </Typography.Text>
      {(isMorning || isAfternoon) && !(isMorning && isAfternoon) && (
        <>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employee.employeeid}
            data-leave-obj-id=""
            data-leave-type={LEAVE_ABSENCE}
            data-start-date={dateWithSlashes}
            data-is-morning={isMorning}
            data-is-afternoon={isAfternoon}
            onClick={openLeaveModal}
          >
            {ADD_ABSENCE}
          </Typography.Text>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employee.employeeid}
            data-leave-obj-id=""
            data-leave-type={LEAVE_VACATION}
            data-start-date={dateWithSlashes}
            data-is-morning={isMorning}
            data-is-afternoon={isAfternoon}
            onClick={openLeaveModal}
          >
            {ADD_VACATION}
          </Typography.Text>
        </>
      )}
      <Typography.Text
        className={Styles.overlayEvent}
        data-employee-id={employeeId}
        data-leave-obj-id={leaveObj.id}
        data-leave-type={
          leaveObj.type === LEAVE_VACATION ? LEAVE_VACATION : LEAVE_ABSENCE
        }
        onClick={openLeaveModal}
      >
        {eventText}
      </Typography.Text>
      {hasAttachment && (
        <Typography.Text
          className={Styles.overlayEvent}
          data-employee-id={employeeId}
          data-leave-obj-id={leaveObj.id}
          onClick={downloadAttachment}
        >
          {DOWNLOAD_ATTACHMENT}
        </Typography.Text>
      )}
    </div>
  ) : (
    <div className={Styles.overlay}>
      {selectedRowKeys.length > 1 ? (
        <>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employeeId}
            data-leave-obj-id=""
            data-leave-type={LEAVE_ABSENCE}
            data-start-date={dateWithSlashes}
            onClick={openLeaveModal}
          >
            {ADD_MASS_ABSENCE}
          </Typography.Text>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employeeId}
            data-leave-obj-id=""
            data-leave-type={LEAVE_VACATION}
            data-start-date={dateWithSlashes}
            onClick={openLeaveModal}
          >
            {ADD_MASS_VACATION}
          </Typography.Text>
        </>
      ) : (
        <>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employeeId}
            data-leave-obj-id=""
            data-leave-type={LEAVE_ABSENCE}
            data-start-date={dateWithSlashes}
            onClick={openLeaveModal}
          >
            {ADD_ABSENCE}
          </Typography.Text>
          <Typography.Text
            className={Styles.overlayEvent}
            data-employee-id={employeeId}
            data-leave-obj-id=""
            data-leave-type={LEAVE_VACATION}
            data-start-date={dateWithSlashes}
            onClick={openLeaveModal}
          >
            {ADD_VACATION}
          </Typography.Text>
        </>
      )}
    </div>
  );
};
