import { useLocation } from 'react-router';
import { COUNTERPARTIES_DATA, COUNTERPARTY_DATA } from 'const/Counterparties';
import { useSelector, useDispatch } from 'react-redux';
import {
  REGEX_ENCODED_PERCENT_ALL_INSTANCES,
  STATUS_ERROR,
  STATUS_LOADING,
} from 'const';
import { selectCommentsStatus } from 'modules/commentsModal/selectors';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import { useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { selectUserCurrentCompanyDosId } from 'modules/loggedUserInfo/selectors';

const useCounterpartyData = (isSingle = false) => {
  const { pathname } = useLocation();

  const party = pathname.split('/')[1];

  return isSingle ? COUNTERPARTY_DATA[party] : COUNTERPARTIES_DATA[party];
};

export const useCounterpartiesChart = () => {
  const { selectors, dashboardName } = useCounterpartyData();

  const chartData = useSelector(selectors.chartData);
  const comments = useSelector(selectors.comments);
  const status = useSelector(selectors.status);

  const firstMonthFiscal = useSelector(selectCurrentCompanyFirstFiscalMonth);
  const commentsStatus = useSelector(selectCommentsStatus);

  const isLoading = [status, commentsStatus].some(
    (partyStatus) => partyStatus === STATUS_LOADING
  );
  const isError = [status, commentsStatus].some(
    (partyStatus) => partyStatus === STATUS_ERROR
  );

  return {
    dashboardName,
    chartData,
    comments,
    firstMonthFiscal,
    isLoading,
    isError,
  };
};

export const useCounterparties = () => {
  const { selectors, ...partyConstData } = useCounterpartyData();

  const dataSource = useSelector(selectors.dataSource);
  const updateDate = useSelector(selectors.updateDate);

  return {
    ...partyConstData,
    dataSource,
    updateDate,
  };
};

export const useCounterparty = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useParams();

  const { selectors, actions, routes, ...partyConstData } = useCounterpartyData(
    true
  );
  const { creditor, debtor, data, status } = useSelector(selectors.data);

  const currentDosId = useSelector(selectUserCurrentCompanyDosId);

  // Redirect to counterparties page if current company was changed.
  const componentDidMountRef = useRef(false);
  useEffect(() => {
    if (componentDidMountRef.current) {
      history.push(routes.counterparties);
    }
    componentDidMountRef.current = true;
  }, [history, currentDosId, routes.counterparties]);

  /**
   * code value might contain '%' character
   * useParams handles encoded param differently in cases when page was reloaded
   * and when page was opened via Link component => in half of the cases decodeURI
   * will either throw an error or won't decode param properly => manual replacement.
   * @see https://stackoverflow.com/questions/62311396/react-decoding-with-in-url-causes-error
   */
  useEffect(() => {
    dispatch(
      actions.dataRequest({
        currentDosId,
        code: code.replace(REGEX_ENCODED_PERCENT_ALL_INSTANCES, '%'),
      })
    );
  }, [dispatch, code, currentDosId, actions]);

  return {
    ...partyConstData,
    routes,
    counterparty: { name: creditor || debtor, data, status },
  };
};
