import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/ui';
import { useFormikOnChange, useSimpleMask } from 'hooks';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';
import clsx from 'clsx';
import Styles from './MaskedInputNumber.module.css';

const propTypes = {
  ...formikOnChangeAdaptorTypes,
  value: PropTypes.string,
  mask: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

/**
 * Custom masked input number component, based on ant-design Input
 * Delete is handled onKeyDown because of a bug likely relative to
 * ant-d inner component mechanics - if handled onChange, it deletes 2
 * symbols when string is full.
 * Ref forwarding is added to enable elements focusing
 * @important component should NOT be based on InputNumber, otherwise
 * first inputted zero is ignored
 */

export const MaskedInputNumber = React.forwardRef(
  ({ onChange, className, name, value, mask, maxLength, ...props }, ref) => {
    const handleChange = useFormikOnChange(name, onChange);
    const { handleInput, maskedValue, handleDelete } = useSimpleMask(
      value,
      maxLength,
      handleChange,
      mask
    );

    return (
      <Input
        {...props}
        className={clsx(className, Styles.maskedInput)}
        ref={ref}
        onChange={handleInput}
        value={maskedValue}
        onKeyDown={handleDelete}
      />
    );
  }
);

MaskedInputNumber.propTypes = propTypes;
