import React, { useCallback } from 'react';
import { DatePicker } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';
import moment from 'moment';

export const DatePickerFormik = ({
  onChange,
  name,
  value,
  storageFormat = null,
  ...props
}) => {
  const handleChange = useCallback(
    (newValue) =>
      newValue
        ? onChange(
            name,
            storageFormat
              ? newValue.format(storageFormat)
              : newValue.toISOString()
          )
        : onChange(name, null),
    [onChange, name, storageFormat]
  );
  return (
    <DatePicker
      {...props}
      onChange={handleChange}
      value={storageFormat && value ? moment(value, storageFormat) : value}
    />
  );
};

DatePickerFormik.propTypes = formikOnChangeAdaptorTypes;
