import { request } from 'utils/request';

export const uploadVacationSupportDocRequest = (payload) =>
  request.post(
    `/companies/${payload.companyId}/absence/send-document`,
    payload.formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const deleteVacationSupportDocRequest = ({ companyId, fileId }) =>
  request.delete(`/companies/${companyId}/absence/file/${fileId}`);

export const createVacationRequest = (payload) =>
  request.post(
    `/companies/${payload.companyId}/employees/${payload.employeeId}/absence`,
    payload.data
  );

export const createVacationListRequest = (payload) =>
  request.post(`/companies/${payload.companyId}/absences`, payload.data);

export const editLeaveService = ({ leaveId, leaveData }) =>
  request.patch(`/companies/absence/${leaveId}`, leaveData);

export const deleteLeaveService = ({ absenceId }) =>
  request.delete(`/companies/absence/${absenceId}`);

export const fetchLeaveSupportingDoc = ({ fileId, companyId }) =>
  request.get(`/companies/${companyId}/absence/file/${fileId}`, {
    responseType: 'blob',
  });

export const getEmployeeAbsences = ({ companyId, employeeId }) =>
  request.get(`/companies/${companyId}/absences/${employeeId}`);
