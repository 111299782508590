import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import clsx from 'clsx';
import { separateNumber } from 'helpers/common';
import { MobileBlocker } from 'components/MobileBlocker';
import { TableFilterSelect } from 'components/TableFilterSelect';
import MainStyles from 'assets/styles/main.module.css';
import { useCounterparties } from 'helpers/counterparties';
import { v4 as uuidv4 } from 'uuid';
import Styles from './CounterpartiesTable.module.css';

const getFooter = (finalDataSource) => {
  const total = separateNumber(
    finalDataSource
      .reduce(
        (sum, { remainingAmount }) => sum + parseFloat(remainingAmount),
        0
      )
      .toFixed(2)
  );

  return () => (
    <div className={Styles.tableFooter}>
      <div className={Styles.totalName}>Solde</div>
      <div className={Styles.totalCell}>{total}</div>
    </div>
  );
};

/**
 *  Table of all counterparties
 *  @note URL to single counterparty page must have double encoding:
 *  clientId can contain special characters like '%'.
 *  If code has such character and single encoding - page will crash on counterparty page reload
 *  If no encoding - when Link with URL is rendered on the page.
 */
export const CounterpartiesTable = () => {
  const { translations, routes, dataSource } = useCounterparties();

  const [filteredDataSource, setFilteredDataSource] = useState(null);

  const {
    TABLE: { COLUMNS_HEADERS, NO_RESULTS_MESSAGE },
    LABELS,
  } = translations;

  const columns = useMemo(
    () => [
      {
        title: COLUMNS_HEADERS.CLIENT_NAME,
        dataIndex: 'clientName',
        key: 'clientName',
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        render: (name, { clientId }) => (
          <div className={Styles.clientCell}>
            <MobileBlocker
              desktop={
                <Link
                  to={`${routes.counterparties}/${encodeURI(
                    encodeURI(clientId)
                  )}`}
                >
                  {name}
                </Link>
              }
              mobile={name}
            />
          </div>
        ),
      },
      {
        title: COLUMNS_HEADERS.REMAINING_AMOUNT,
        dataIndex: 'remainingAmount',
        key: 'remainingAmount',
        sorter: (a, b) => a.remainingAmount - b.remainingAmount,
        sortDirections: ['descend', 'ascend'],
        align: 'right',
        render: (amount) => separateNumber(parseFloat(amount).toFixed(2)),
      },
    ],
    [
      COLUMNS_HEADERS.CLIENT_NAME,
      COLUMNS_HEADERS.REMAINING_AMOUNT,
      routes.counterparties,
    ]
  );

  const finalDataSource = (filteredDataSource || dataSource).map((item) => ({
    ...item,
    key: item.clientId + uuidv4(), // TODO\1420: The data here supposed to be unique, however it is not on test server
  }));

  return (
    <div className={Styles.clientsTable}>
      <h3 className={Styles.title}>{LABELS.DETAIL}</h3>
      <div className={Styles.tableFilters}>
        <span className={Styles.filterName}>{COLUMNS_HEADERS.CLIENT_NAME}</span>
        <TableFilterSelect setFilteredDataSource={setFilteredDataSource} />
      </div>
      <Table
        columns={columns}
        dataSource={finalDataSource}
        locale={{ emptyText: NO_RESULTS_MESSAGE }}
        className={clsx(MainStyles.table, Styles.table)}
        pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
        footer={getFooter(finalDataSource)}
      />
    </div>
  );
};
