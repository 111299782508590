import React from 'react';
import { Icon } from 'antd';
import {
  PDF_FILES_EXTENSION,
  EXCEL_FILES_EXTENSIONS,
  WORD_FILES_EXTENSIONS,
  POWERPOINT_FILES_EXTENSIONS,
} from 'const';
import pdfIcon from 'assets/images/pdf-icon.png';
import wordIcon from 'assets/images/word_icon.png';
import powerpointIcon from 'assets/images/powerpoint_icon.png';
import excelIcon from 'assets/images/excel_icon.png';
import Styles from 'components/DocumentsCabinet/DocumentsList/DocumentsList.module.css';

export const getFileIconByExtension = (extension) => {
  if (PDF_FILES_EXTENSION === extension) {
    return <img src={pdfIcon} alt="pdf icon" className={Styles.iconImage} />;
  }

  if (EXCEL_FILES_EXTENSIONS.includes(extension)) {
    return (
      <img src={excelIcon} alt="excel icon" className={Styles.iconImage} />
    );
  }

  if (WORD_FILES_EXTENSIONS.includes(extension)) {
    return <img src={wordIcon} alt="word icon" className={Styles.iconImage} />;
  }

  if (POWERPOINT_FILES_EXTENSIONS.includes(extension)) {
    return (
      <img
        src={powerpointIcon}
        alt="powerpoint icon"
        className={Styles.iconImage}
      />
    );
  }

  return <Icon type="file" className={Styles.Icon} />;
};
