import { fromJS } from 'immutable';
import { OPEN_MODAL, CLOSE_MODAL } from '../actions';

const initialState = fromJS({
  type: '',
  modalProps: {},
});

export const modalContainer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return state
        .set('type', action.payload.type)
        .set('modalProps', fromJS(action.payload.modalProps || {}));
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
