import React from 'react';
import clsx from 'clsx';
import EyeOpen from 'assets/icons/eye-open.svg';
import EyeClose from 'assets/icons/eye-close.svg';
import PropTypes from 'prop-types';
import Styles from './EyeCheckbox.module.css';

const propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  isMinified: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const EyeCheckbox = ({
  name,
  checked = false,
  onChange,
  className,
  label,
  id,
  isMinified = false,
  disabled = false,
}) => (
  <div
    className={clsx(Styles.checkBox, className, {
      [Styles.isMinified]: isMinified,
      [Styles.disabled]: disabled,
    })}
  >
    <input
      disabled={disabled}
      value={checked}
      name={name}
      onChange={onChange}
      type="checkbox"
      id={id}
    />
    <label htmlFor={id}>
      <div className={Styles.box}>
        {checked ? (
          <img src={EyeClose} alt="" className={Styles.eye} />
        ) : (
          <img src={EyeOpen} alt="" className={Styles.eye} />
        )}
      </div>
      <span>{label}</span>
    </label>
  </div>
);

EyeCheckbox.propTypes = propTypes;
