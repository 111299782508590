import { getExtensionFromName } from 'helpers/common';
import moment from 'moment';

export const formatFileToVacationModal = ({ fileName, fileId }) => ({
  name: fileName,
  type: getExtensionFromName(fileName),
  uid: fileId,
  errors: {},
});

export const validateAbsenceFormValues = (state) => {
  const { absenceType, dateStart, dateEnd, addedUsers, withAddedUsers } = state;

  const errors = [];

  if (!absenceType) errors.push('absenceType');
  if (!dateStart) errors.push('dateStart');
  if (!dateEnd) errors.push('dateEnd');
  if (withAddedUsers && addedUsers.length === 0) errors.push('withAddedUsers');

  return errors;
};

export const determineIfDatesOverlap = ({
  dateStart,
  dateEnd,
  filteredEmployeeAbsences,
  dateStartWorkingTime,
  dateEndWorkingTime,
}) => {
  let isOverlap = false;

  filteredEmployeeAbsences.forEach(
    ({ start, end, startTimeOfTheDay, endTimeOfTheDay }) => {
      if (
        dateStart &&
        dateEnd &&
        !dateStart.isSame(dateEnd, 'day') &&
        dateStart.isSame(start, 'day')
      ) {
        isOverlap = true;
      }

      if (
        dateStart &&
        dateStart.isSame(end, 'day') &&
        endTimeOfTheDay === 'morning' &&
        dateStartWorkingTime !== 'afternoon'
      ) {
        isOverlap = true;
      }

      if (
        dateStart &&
        dateEnd &&
        dateEnd.isSame(start, 'day') &&
        startTimeOfTheDay === 'afternoon' &&
        dateEndWorkingTime !== 'morning' &&
        !dateStart.isSame(dateEnd, 'day')
      ) {
        isOverlap = true;
      }

      if (
        dateStart &&
        dateEnd &&
        dateStart.isSame(dateEnd, 'day') &&
        dateStart.isSame(start, 'day') &&
        startTimeOfTheDay === 'afternoon' &&
        dateStartWorkingTime !== 'morning'
      ) {
        isOverlap = true;
      }

      if (
        dateStart &&
        dateEnd &&
        dateStart.isSame(dateEnd, 'day') &&
        dateStart.isSame(start, 'day') &&
        startTimeOfTheDay === 'morning' &&
        dateStartWorkingTime !== 'afternoon'
      ) {
        isOverlap = true;
      }

      if (dateStart && dateEnd && !dateStart.isSame(dateEnd, 'day')) {
        const momentStart = moment(start);
        const momentEnd = moment(end);
        if (
          [momentEnd, momentStart].some((existingAbsenceDate) =>
            existingAbsenceDate.isBetween(dateStart, dateEnd, 'days', '()')
          )
        ) {
          isOverlap = true;
        }
      }
    }
  );
  return isOverlap;
};

/* CALCULATE ABSENCE DAYS LOGIC */

const EAbsenceTime = {
  WHOLE_DAY: 'whole_day',
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
};

const EAbsenceType = {
  ABSENCE: 'absence',
  VACATION: 'vacation',
};

const Holidays2021 = {
  'Jour de l’an': new Date(2021, 0, 1),
  'Lundi de Pâques': new Date(2021, 3, 5),
  'Fête du Travail': new Date(2021, 4, 1),
  'Victoire de 1945': new Date(2021, 4, 8),
  Ascension: new Date(2021, 4, 13),
  'Lundi de Pentecôte': new Date(2021, 4, 24),
  'Fête Nationale': new Date(2021, 6, 14),
  Assomption: new Date(2021, 7, 15),
  'La Toussaint': new Date(2021, 10, 1),
  'Armistice 1918': new Date(2021, 10, 11),
  Noël: new Date(2021, 11, 25),
};

const Holidays2022 = {
  'Jour de l’an': new Date(2022, 0, 1),
  'Lundi de Pâques': new Date(2022, 3, 18),
  'Fête du Travail': new Date(2022, 4, 1),
  'Victoire de 1945': new Date(2022, 4, 8),
  'Jeudi de l’Ascension': new Date(2022, 4, 26),
  'Lundi de Pentecôte': new Date(2022, 5, 6),
  'Fête Nationale': new Date(2022, 6, 14),
  Assomption: new Date(2022, 7, 15),
  'La Toussaint': new Date(2022, 10, 1),
  'Armistice 1918': new Date(2022, 10, 11),
  Noël: new Date(2022, 11, 25),
};

const Holidays2023 = {
  'Jour de l’an': new Date(2023, 0, 1),
  'Lundi de Pâques': new Date(2023, 3, 10),
  'Fête du Travail': new Date(2023, 4, 1),
  'Victoire de 1945': new Date(2023, 4, 8),
  'Jeudi de l’Ascension': new Date(2023, 4, 18),
  'Lundi de Pentecôte': new Date(2023, 4, 29),
  'Fête Nationale': new Date(2023, 6, 14),
  Assomption: new Date(2023, 7, 15),
  'La Toussaint': new Date(2023, 10, 1),
  'Armistice 1918': new Date(2023, 10, 11),
  Noël: new Date(2023, 11, 25),
};

const Holidays2024 = {
  'Jour de l’an': new Date(2024, 0, 1),
  'Lundi de Pâques': new Date(2024, 3, 1),
  'Fête du Travail': new Date(2024, 4, 1),
  'Victoire de 1945': new Date(2024, 4, 8),
  'Jeudi de l’Ascension': new Date(2024, 4, 9),
  'Lundi de Pentecôte': new Date(2024, 4, 20),
  'Fête Nationale': new Date(2024, 6, 14),
  Assomption: new Date(2024, 7, 15),
  'La Toussaint': new Date(2024, 10, 1),
  'Armistice 1918': new Date(2024, 10, 11),
  Noël: new Date(2024, 11, 25),
};

const Holidays2025 = {
  'Jour de l’an': new Date(2025, 0, 1),
  'Lundi de Pâques': new Date(2025, 3, 1),
  'Fête du Travail': new Date(2025, 4, 1),
  'Victoire de 1945': new Date(2025, 4, 8),
  'Jeudi de l’Ascension': new Date(2025, 4, 29),
  'Lundi de Pentecôte': new Date(2025, 5, 9),
  'Fête Nationale': new Date(2025, 6, 14),
  Assomption: new Date(2025, 7, 15),
  'La Toussaint': new Date(2025, 10, 1),
  'Armistice 1918': new Date(2025, 10, 11),
  Noël: new Date(2025, 11, 25),
};

const Holidays = {
  2021: Holidays2021,
  2022: Holidays2022,
  2023: Holidays2023,
  2024: Holidays2024,
  2025: Holidays2025,
};

const getDayOfWeekNameByIndex = (index) => {
  switch (index) {
    case 0:
      return 'sun';
    case 1:
      return 'mon';
    case 2:
      return 'tue';
    case 3:
      return 'wed';
    case 4:
      return 'thu';
    case 5:
      return 'fri';
    case 6:
      return 'sat';

    default:
      return null;
  }
};

const allDatesBetween = (start, end) => {
  const result = [];
  const currentDate = new Date(start);

  while (currentDate <= new Date(end)) {
    result.push(new Date(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return result;
};

function getDiffsInMs(start, end, partOfTheDay) {
  let diffInMs = end.getTime() - start.getTime();
  const noon = new Date(0, 0, 0, 12);

  switch (partOfTheDay) {
    case EAbsenceTime.WHOLE_DAY: {
      break;
    }

    case EAbsenceTime.MORNING: {
      diffInMs = Math.min(noon.getTime(), end.getTime()) - start.getTime();
      break;
    }

    case EAbsenceTime.AFTERNOON: {
      diffInMs = end.getTime() - Math.max(noon.getTime(), start.getTime());
      break;
    }

    default:
      break;
  }

  return diffInMs;
}

function calcTimeBetweenPeriods({
  periods,
  partOfTheDay = EAbsenceTime.WHOLE_DAY,
}) {
  return periods.reduce(
    (acc, period) => {
      const start = new Date(0, 0, 0, period.start.hours, period.start.minutes);
      const end = new Date(0, 0, 0, period.end.hours, period.end.minutes);

      const diffInMs = getDiffsInMs(start, end, partOfTheDay);
      const diffInSeconds = diffInMs / 1000;
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);

      const totalMinutes = acc.totalMinutes + minutes;
      const totalHours = acc.totalHours + hours + Math.floor(totalMinutes / 60);

      return {
        totalMinutes: totalMinutes % 60,
        totalHours,
      };
    },
    { totalHours: 0, totalMinutes: 0 }
  );
}

function calcTimeForOneDay({ acc, timeOfTheDay, periods }) {
  let daysToCount;
  let periodsToCount;
  let partOfTheDay;

  switch (timeOfTheDay) {
    case EAbsenceTime.WHOLE_DAY: {
      periodsToCount = periods;
      partOfTheDay = EAbsenceTime.WHOLE_DAY;
      daysToCount = 1;
      break;
    }

    case EAbsenceTime.AFTERNOON: {
      daysToCount = 0.5;
      if (periods.length === 2) {
        periodsToCount = [periods[1]];
      } else {
        periodsToCount = periods;
        partOfTheDay = EAbsenceTime.AFTERNOON;
      }
      break;
    }

    case EAbsenceTime.MORNING: {
      daysToCount = 0.5;
      if (periods.length === 2) {
        periodsToCount = [periods[0]];
      } else {
        periodsToCount = periods;
        partOfTheDay = EAbsenceTime.MORNING;
      }
      break;
    }

    default:
      return acc;
  }

  const time = calcTimeBetweenPeriods({
    periods: periodsToCount,
    partOfTheDay,
  });
  const minutes = acc.minutes + time.totalMinutes;

  return {
    days: acc.days + daysToCount,
    hours: acc.hours + time.totalHours + Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
}

export function calcDaysAndHours({
  type,
  schedule,
  start,
  end,
  startTimeOfTheDay,
  endTimeOfTheDay,
}) {
  const dates = allDatesBetween(start, end);

  return dates.reduce(
    (acc, date, i, thisArr) => {
      const holidays = Object.values(Holidays[date.getFullYear()] || {});
      const isHoliday = holidays.some(
        (holiday) => holiday.toDateString() === date.toDateString()
      );
      const weekday = date.getDay();

      const name = getDayOfWeekNameByIndex(weekday);
      const periods = schedule.days[name];

      let newAcc;

      // Holidays and Sundays counts only for absences
      if ((isHoliday || weekday === 0) && type === EAbsenceType.VACATION) {
        return acc;
      }

      // If employee doesn't work in this day count only day
      if (!periods?.length) {
        if (i === 0) {
          newAcc = {
            ...acc,
            days:
              startTimeOfTheDay === EAbsenceTime.WHOLE_DAY
                ? acc.days + 1
                : acc.days + 0.5,
          };
        } else if (i === thisArr.length - 1) {
          newAcc = {
            ...acc,
            days:
              endTimeOfTheDay === EAbsenceTime.WHOLE_DAY
                ? acc.days + 1
                : acc.days + 0.5,
          };
        } else {
          newAcc = { ...acc, days: acc.days + 1 };
        }

        return newAcc;
      }

      if (i === 0) {
        newAcc = calcTimeForOneDay({
          acc,
          periods,
          timeOfTheDay: startTimeOfTheDay,
        });
      } else if (i === thisArr.length - 1) {
        newAcc = calcTimeForOneDay({
          acc,
          periods,
          timeOfTheDay: endTimeOfTheDay,
        });
      } else {
        newAcc = calcTimeForOneDay({
          acc,
          periods,
          timeOfTheDay: EAbsenceTime.WHOLE_DAY,
        });
      }

      return newAcc;
    },
    { days: 0, hours: 0, minutes: 0 }
  );
}

/* END - CALCULATE ABSENCE DAYS LOGIC */
