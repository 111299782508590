import { fork } from 'redux-saga/effects';
import { authenticationMainSaga } from 'modules/Authentication/sagas';
import { turnoverMainSaga } from 'modules/turnoverPage/sagas';
import { treasuryMainSaga } from 'modules/treasuryPage/sagas';
import { commentsModalMainSaga } from 'modules/commentsModal/sagas';
import { calendarMainSaga } from 'modules/SalaryModule/Calendar/sagas';
import { salaryModalMainSaga } from 'modules/SalaryModule/SalaryModal/sagas';
import { HRMainSaga } from 'modules/HRPage/sagas';
import { additionalUsersMainSaga } from 'modules/additionalUsers/sagas';
import { cartMainSaga } from 'modules/cart/sagas';
import { waibiMainSaga } from 'modules/dashboardIndicators/sagas';
import { employeeRegisterMainSaga } from '../employeeRegister/sagas';
import { invoiceSearchMainSaga } from '../invoiceSearch/sagas';
import { loggedUserMainSaga } from '../loggedUserInfo/sagas';
import { companySettingsMainSaga } from '../SalaryModule/CompanySettings/sagas';
import { rewardsMainSaga } from '../SalaryModule/Rewards/sagas';
import { rewardsTableMainSaga } from '../SalaryModule/RewardsTable/sagas';
import { vacationMainSaga } from '../SalaryModule/Vacations/sagas';
import { uploadDocumentsMainSaga } from '../uploadDocuments/sagas';
import { browseSharepointMainSaga } from '../documentsCabinet/sagas';
import { pollingWatcher } from '../Polling/sagas';
import { employeesMainSaga } from '../SalaryModule/Employees/sagas';
import { debtorsMainSaga } from '../Debtors/sagas';
import { creditorsMainSaga } from '../Creditors/sagas';
import { helpModalMainSaga } from '../helpModal/sagas';
import { contactHRMainSaga } from '../HRModal/sagas';
import { unsubscribePageMainSaga } from '../UnsubscribePage/sagas';
import { voteNPSMainSaga } from '../NPSModal/sagas';
import { loginPageMainSaga } from '../LoginPage/sagas';
import { bookmarksMainSaga } from '../usefulLinks/sagas';

/**
 * Root saga watcher. Launches all saga watchers
 */
export function* rootSaga() {
  yield fork(employeeRegisterMainSaga);
  yield fork(authenticationMainSaga);
  yield fork(loggedUserMainSaga);
  yield fork(invoiceSearchMainSaga);
  yield fork(uploadDocumentsMainSaga);
  yield fork(browseSharepointMainSaga);
  yield fork(pollingWatcher);
  yield fork(turnoverMainSaga);
  yield fork(debtorsMainSaga);
  yield fork(employeesMainSaga);
  yield fork(creditorsMainSaga);
  yield fork(companySettingsMainSaga);
  yield fork(treasuryMainSaga);
  yield fork(commentsModalMainSaga);
  yield fork(calendarMainSaga);
  yield fork(vacationMainSaga);
  yield fork(rewardsMainSaga);
  yield fork(rewardsTableMainSaga);
  yield fork(salaryModalMainSaga);
  yield fork(HRMainSaga);
  yield fork(helpModalMainSaga);
  yield fork(contactHRMainSaga);
  yield fork(additionalUsersMainSaga);
  yield fork(cartMainSaga);
  yield fork(unsubscribePageMainSaga);
  yield fork(voteNPSMainSaga);
  yield fork(loginPageMainSaga);
  yield fork(waibiMainSaga);
  yield fork(bookmarksMainSaga);
}
