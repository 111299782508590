import {
  object as yupObject,
  string as yupString,
  bool as yupBool,
  date as yupDate,
} from 'yup';
import { ADD_EMPLOYEE_FORM_TRANSLATIONS } from 'const/translations';

export const validationSchema = () =>
  yupObject().shape({
    degreeEmployee: yupString().required('Field cannot be empty'),
    beforeEmployment: yupString(),
    hasCompanyInsurance: yupBool(),
    hasAnotherJob: yupBool(),
    since: yupDate()
      .nullable()
      .when('beforeEmployment', {
        /**
         * 'is: true' won't be enough, because null with .nullable() is considered truthy value
         */
        is: (value) => Boolean(value),
        then: yupDate().required('Field cannot be empty'),
      }),
    beforeEmploymentDetails: yupString().when('beforeEmployment', {
      is: ADD_EMPLOYEE_FORM_TRANSLATIONS.GENERAL_LABELS.OTHER,
      then: yupString().required('Field cannot be empty'),
    }),
  });
