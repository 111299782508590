import React, { memo } from 'react';
import { Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { EyeCheckbox } from 'components/ui/EyeCheckbox';
import {
  CALENDAR_MOBILE_FILTER_TRANSLATIONS,
  SALARY_CALENDAR_TRANSLATIONS,
} from 'const/translations/SalaryCalendar';
import { formatNameUpperLastName } from 'modules/SalaryModule/Employees/utils';
import { Button, CustomSelect } from 'components/ui';
import Styles from './CalendarMobileFilters.module.css';

const propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  branches: PropTypes.array,
  filteredEmployees: PropTypes.array,
  isMonthCalendar: PropTypes.bool,
};

const {
  HIDE_USERS_WITHOUT_ABSENCES,
  ESTABLISHMENT,
  SELECT_EMPLOYEE,
} = SALARY_CALENDAR_TRANSLATIONS;

const { CANCEL, FILTERS, SAVE } = CALENDAR_MOBILE_FILTER_TRANSLATIONS;

export const CalendarMobileFiltersModal = memo(
  ({
    isVisible = true,
    onClose,
    onSubmit,
    initialValues,
    branches = [],
    filteredEmployees,
    isMonthCalendar,
  }) => (
    <Modal
      footer={null}
      visible={isVisible}
      wrapClassName={Styles.content}
      width="100%"
      closable={false}
      onCancel={onClose}
      destroyOnClose
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => {
          const onChange = (name, value = '') => {
            setFieldValue(name, value);
          };
          const onCheckboxCheckCreator = (name) => () => {
            setFieldValue(name, !values[name]);
          };

          return (
            <form onSubmit={handleSubmit} className={Styles.form}>
              <Typography.Title className={Styles.textTitle}>
                {FILTERS}
              </Typography.Title>
              <CustomSelect
                name="activeBranch"
                size="large"
                className={Styles.select}
                options={branches}
                onChange={onChange}
                value={values.activeBranch}
                placeholder={ESTABLISHMENT}
                allowClear
                hasPaleLabel
              />
              {!isMonthCalendar && (
                <CustomSelect
                  size="large"
                  name="employee"
                  placeholder={SELECT_EMPLOYEE}
                  onChange={onChange}
                  value={values.employee}
                  options={filteredEmployees.map((employee) => ({
                    label: formatNameUpperLastName(
                      employee.firstname1,
                      employee.lastname
                    ),
                    value: employee.employeeid,
                  }))}
                  className={Styles.select}
                />
              )}

              {isMonthCalendar && (
                <EyeCheckbox
                  isMinified
                  onChange={onCheckboxCheckCreator('withAbsencesOnly')}
                  id="CalendarCheckbox"
                  checked={values.withAbsencesOnly}
                  label={HIDE_USERS_WITHOUT_ABSENCES}
                  className={Styles.checkBox}
                />
              )}

              <div className={Styles.buttonsWrapper}>
                <Button className={Styles.btn} variant="primary" type="submit">
                  {SAVE}
                </Button>
                <Button
                  className={Styles.btn}
                  variant="secondary"
                  onClick={onClose}
                >
                  {CANCEL}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  )
);

CalendarMobileFiltersModal.propTypes = propTypes;
