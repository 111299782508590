import { mapValuesToItemsList } from 'helpers/common';

export const ADD_EMPLOYEE_FORM_TRANSLATIONS = {
  TITLE: "Enregistrement d'un salarié",
  TABS: {
    CIVIL_DATA: 'Données civiles',
    SALARY_SITUATION: 'Situation du salarié',
    WORK_CONTRACT: 'Le contrat de travail',
    SPECIAL_SITUATIONS: 'Situations particulières',
    VOUCHERS: 'Pièces justificatives',
  },
  NOTIFICATION:
    "Les informations saisies seront conservées jusqu'à validation complète du formulaire",
  GENERAL_LABELS: {
    COMPLETE: 'complété',
    CURRENT_TAB: 'vous êtes ici',
    TO_FILL: 'à remplir',
    NEXT_TAB: 'Suivant',
    PREV_TAB: 'Précédent',
    RESET: 'Reinitialiser',
    SUBMIT_FORM: 'Sauvegarder',
    YES: 'Oui',
    CHOOSE: 'Choisir',
    OTHER: 'Autre',
    MONTHLY: 'Mensuel',
    HOURLY: 'Horaire',
    SINCE: 'depuis le',
    FULLTIME: 'Temps complet',
    PARTTIME: 'Temps partiel',
  },
  GENERAL_PLACEHOLDERS: {
    DATE_FORMAT: 'jj/mm/aaaa',
    TIME_FORMAT: 'hh/mm',
    DESCRIBE_OTHER: 'Préciser « Autre »',
    ENTER_INFO: "Entrer l'info",
    NOT_UPLOADED: 'not uploaded',
  },
  CIVIL_DATA_FORM_TAB: {
    LABELS: {
      LAST_NAME: 'Nom du salarié',
      FIRST_NAME: 'Prénom',
      GENDER: 'Sexe',
      MALE: 'Masculin',
      FEMALE: 'Féminin',
      EMAIL: 'Email',
      FAMILY_SITUATION: 'Situation famiale',
      SS_NUMBER: 'Numéro SS',
      DATE_OF_BIRTH: 'Date de naissance',
      PLACE_OF_BIRTH: 'Lieu de naissance',
      NATIONALITY: 'Nationalité',
      ACTUAL_ADDRESS: 'Adresse actuelle',
    },
    PLACEHOLDERS: {
      BIRTH_LAST_NAME: 'Nom de naissance',
      USE_LAST_NAME: "Nom d'usage (optionnel)",
      FIRST_NAME_1: 'Prénom 1',
      FIRST_NAME_2: 'Prénom 2 (optionnel)',
      FIRST_NAME_3: 'Prénom 3 (optionnel)',
      EMAIL: 'email',
      SS_NUMBER_1: '_  __  __  __  ___  ___',
      SS_NUMBER_2: '__',
      CITY: 'Ville',
      ADDRESS_EXAMPLE: 'p.e. 211, Rue De lUniversité, Paris',
    },
    LISTS: {
      GENDER: mapValuesToItemsList(['Masculin', 'Féminin']),
      STATUS_FAMILY_MALE: mapValuesToItemsList([
        'Marié',
        'Pacsé',
        'Divorcé',
        'Séparé',
        'Célibataire',
        'Veuf',
      ]),
      STATUS_FAMILY_FEMALE: mapValuesToItemsList([
        'Mariée',
        'Pacsée',
        'Divorcée',
        'Séparée',
        'Célibataire',
        'Veuve',
      ]),
    },
  },
  SALARY_SITUATION_FORM_TAB: {
    LABELS: {
      EMPLOYEE_EDUCATION: 'Niveau de formation du salarié',
      SITUATION_BEFORE_HIRING: "Situation avant l'embauche (optionnel)",
      COMPANY_BENEFICIARY: "Bénéficiaire de la mutuelle d'entreprise",
      SECOND_JOB: "Cumul d'un autre emploi",
    },
    LISTS: {
      DEGREE_EMPLOYEE: mapValuesToItemsList([
        'CAP, BEP',
        'BAC',
        'BAC+2',
        'Licence',
        'Master 1',
        'Master 2',
        'Doctorat',
        'Dispence de titre',
      ]),
      BEFORE_EMPLOYMENT: mapValuesToItemsList([
        'Employé',
        "Demandeur d'emploi",
        'En interim',
        'En stage',
        'Autre',
      ]),
    },
  },
  WORK_CONTRACT_FORM_TAB: {
    LABELS: {
      WORK_CONTRACT_BY_EXCO:
        'Le contrat de travail va-t-il être établi par Exco ?',
      DPAE_FILLED_BY_EXCO:
        "Souhaitez-vous qu'Exco procède à la DPAE (ex DUE) ?",
      EMPLOYEE_POST: 'Intitulé du poste',
      DATE_AND_TIME_OF_HIRING: "Date et heure d'embauche",
      CLASSIFICATION: 'Classification',
      LEVEL_AND_COEFFICIENT: 'Niveau/Coefficient (optionnel)',
      REMUNERATION_BRUTE: 'Rémunération brute',
      CONTRACT_TYPE: 'Type de contrat',
      CONTRACT_TYPE_CDI: 'CDI',
      CONTRACT_TYPE_CONSTRUCTION_CONTRACT: 'CDI de chantier',
      CONTRACT_TYPE_CDD: 'CDD',
      TYPE_CDD: 'Motif du CDD',
      TERM_CDD: 'Date prèvisionnelle de fin de CDD',
      WORKER_REPLACE: "Remplacement d'un salarié absent",
      REPLACED_WORKER_NAME: 'Salarié remplacé',
      EMPLOYMENT_TYPE: 'Horaire',
      EMPLOYMENT_PARTTIME_DETAILS: 'Détail du temps partiel',
    },
    PLACEHOLDERS: {
      POST_DESCRIPTION: 'Intitulé descriptif',
      SALARY_BRUTE: 'Salaire brut',
      REPLACED_WORKER_NAME: 'Nom',
      EMPLOYMENT_PARTTIME_DETAILS: 'Préciser',
    },
    LISTS: {
      CLASSIFICATION: mapValuesToItemsList([
        'Employé',
        'Ouvriers qualifiés et non qualifiés',
        'Profession intermédiaire (technicien, contremaître, agent de maîtrise, clergé)',
        'Cadres',
        'Agriculteur salarié de son exploitation',
        'Artisan ou commerçant salarié de son entreprise',
        "Agent de la fonction publique d'Etat",
        'Agent de la fonction publique hospitalière',
        'Agent de la fonction publique territoriale',
      ]),
      REMUNERATION_GROSS: mapValuesToItemsList(['Autre', 'Mensuel', 'Horaire']),
      CONTRACT_TYPE: mapValuesToItemsList([
        'Autre',
        'CDI',
        'CDI de chantier',
        'CDD',
      ]),
      EMPLOYMENT_TYPE: mapValuesToItemsList(['Temps complet', 'Temps partiel']),
      TYPE_CDD: mapValuesToItemsList([
        'Surcroît de travail',
        "Remplacement d'un salarié absent",
        'Usage (Extra, spectacle...)',
        'Saisonnier',
        "Contrat d'apprentisage de professionnalisation",
        "Contrat Unique d'Insertion(CUI)",
      ]),
    },
  },
  SPECIAL_SITUATIONS_FORM_TAB: {
    LABELS: {
      EMPLOYEE_STATUS: 'Le nouvel employé est un',
      IS_EMPLOYER_RELATIVE: "Lien de parenté avec l'employeur",
      SECOND_JOB: "Cumul d'un autre emploi",
      IS_HANDICAPPED: 'Statut de travailleur handicapé',
      NOT_SELECTED: 'Non sélectionné',
      PUPIL_OR_STUDENT: 'Élevé/étudiant',
      RETIRED: 'Retraité',
    },
    LISTS: {
      RELATIONS: mapValuesToItemsList([
        'Conjoint',
        'Concubin',
        'Parent',
        'Fils/fille',
        'Frére/sœur',
      ]),
      EMPLOYEE_STATUS: mapValuesToItemsList([
        'Non sélectionné',
        'Élève/étudiant',
        'Retraité',
      ]),
    },
  },
  VOUCHERS_FORM_TAB: {
    SUBTITLE: `Vous pouvez joindre au maximum 3 documents
     par catégorie, le total de ces documents ne doit pas dépasser 10Mo.`,
    COMMENT_LABEL: 'Commentaire',
    DOCUMENTS: {
      ID_CARD_OR_PASSPORT: "Scan de carte d'identité ou passeport",
      WORK_CONTRACT_COPY: 'Copie du contrat de travail',
      CPAM_CERTIFICATE: "Scan de l'attestation CPAM des droits",
      DISABLED_WORKER_CERTIFICATE: 'Attestation travailleur handicapé',
      DPAE_COPY: 'Copie DPAE',
      MUTUAL_EXCLUSION: 'Justificatif exclusion mutuelle',
      BTN_CHOOSE: 'Choisir',
    },
    MESSAGES: {
      ERROR_MAX_SIZE:
        'Le total des documents est supérieur à la ' +
        'limite autorisée de 10Mo, le document ne peut pas être ajouté',
    },
  },
};

export const ADD_EMPLOYEE_TITLE_TRANSLATIONS = {
  TITLE: "Enregistrement d'un salarié",
  EMPLOYEE_IMAGE: "Image de l'employé",
  INFO_LIST_UNITS: {
    INFO_TITLE:
      'Bonjour, bienvenue sur le formulaire Exco d’enregistrement d’un nouveau salarié',
    UNITS: {
      REGISTRATION_UNIT_1:
        'Ce formulaire a été conçu pour vous permettre de renseigner facilement l’ensemble des informations requises par la législation en vigueur.',
      REGISTRATION_UNIT_2:
        'Celle-ci impose notamment de déposer un scan de pièce d’identité et une attestation CPAM des droits à l’assurance maladie.',
      REGISTRATION_UNIT_3:
        'Mais soyez rassuré, vous pourrez valider votre saisie et transmettre ces documents dans un second temps si vous ne les possédez pas à l’instant',
    },
  },
  TEXT_REGISTRATION: {
    MODIFY:
      "Pour le moment, vous ne pouvez pas modifier les informations relatives à l'employé sur le site après avoir soumis le formulaire. Mais nous y travaillons !",
    SEND_EMAIL:
      'Si vous avez fait une erreur dans les données ou en cas de changement, veuillez nous envoyer un message en indiquant des modifications à apporter par email',

    EMAIL: ' email_of_the_RH_collaborateur@exco.com',
  },
  BUTTON_ADD: ' Ajouter nouvel employé',
  FEEDBACK_MESSAGE_SUCCESS:
    "L'information sur le nouvel employé a été bien enregistrée !",
};
