import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const usePathOverlapWith = (paths) => {
  const { pathname } = useLocation();

  return useMemo(() => paths.some((route) => pathname.includes(route)), [
    pathname,
    paths,
  ]);
};
