import { createSelector } from 'reselect';

export const selectInvoiceSearchStatus = (state) =>
  state.getIn(['invoiceSearch', 'status']);

export const selectInvoiceSearchResults = createSelector(
  (state) => state.getIn(['invoiceSearch', 'data']),
  (data) => (data ? data.toJS() : [])
);

export const selectStoredMeta = createSelector(
  (state) => state.getIn(['invoiceSearch', 'meta']),
  (meta) => (meta ? meta.toJS() : {})
);

export const selectInvoicesSearchTotals = createSelector(
  (state) => state.getIn(['invoiceSearch', 'totals']),
  (totals) => (totals ? totals.toJS() : {})
);

export const selectItemsPerPage = (state) =>
  state.getIn(['invoiceSearch', 'tableItemsPerPage']);

export const selectTableSort = (state) =>
  state.getIn(['invoiceSearch', 'tableSort']);

export const selectTableCurrentPage = (state) =>
  state.getIn(['invoiceSearch', 'tableCurrentPage']);

export const selectStoredPayload = createSelector(
  (state) => state.getIn(['invoiceSearch', 'storedPayload']),
  (storedPayload) => (storedPayload ? storedPayload.toJS() : {})
);

export const selectInvoiceBeingApprovedId = (state) =>
  state.getIn(['invoiceSearch', 'approveInProgressId']);

export const selectInvoicePaymentURL = (state) =>
  state.getIn(['invoiceSearch', 'paymentLink']);

export const selectInvoicesOpeningList = (state) =>
  state.getIn(['invoiceSearch', 'invoicesOpeningList']);

export const selectInvoicesDownloadingList = (state) =>
  state.getIn(['invoiceSearch', 'invoicesDownloadingList']);

export const selectInvoicesSendingList = (state) =>
  state.getIn(['invoiceSearch', 'invoicesSendingList']);

export const selectFile = (state) => state.getIn(['invoiceSearch', 'file']);

export const selectDownloadDocumentsIsLoading = (state) =>
  state.getIn(['invoiceSearch', 'downloadDocumentsIsLoading']);
