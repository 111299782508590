import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  ADD_COMMENT_ERROR,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  CHANGE_COMMENT_REQUEST,
  CHANGE_COMMENT_SUCCESS,
  CLOSE_COMMENTS_MODAL,
  DELETE_COMMENT_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  GET_ALL_COMMENTS_REQUEST,
  GET_ALL_COMMENTS_SUCCESS,
  OPEN_COMMENTS_MODAL,
  RESET_COMMENTS_FORM_STATUS,
} from 'modules/commentsModal/actions';

const initialState = fromJS({
  comments: [],
  isModalOpen: false,
  monthIndexForSort: null,
  dayIndexForSort: null,
  dateModeForSort: 'month',
  chartType: null,
  error: '',
  status: STATUS_NOT_REQUESTED,
  submitFormStatus: STATUS_NOT_REQUESTED,
});

export const commentsModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COMMENTS_MODAL: {
      const { month, day, chartType, dateMode } = action.payload;
      return state
        .setIn(['dayIndexForSort'], Number(day))
        .setIn(['monthIndexForSort'], Number(month))
        .setIn(['chartType'], chartType)
        .setIn(['dateModeForSort'], dateMode)
        .setIn(['isModalOpen'], true);
    }
    case CLOSE_COMMENTS_MODAL: {
      return state
        .setIn(['dayIndexForSort'], null)
        .setIn(['monthIndexForSort'], null)
        .setIn(['chartType'], null)
        .setIn(['dateModeForSort'], null)
        .setIn(['isModalOpen'], false);
    }
    case GET_ALL_COMMENTS_REQUEST: {
      return state.setIn(['status'], STATUS_LOADING);
    }
    case GET_ALL_COMMENTS_SUCCESS: {
      const { comments } = action.payload;
      return state
        .setIn(['comments'], fromJS(comments))
        .setIn(['status'], STATUS_SUCCESS);
    }
    case GET_ALL_COMMENTS_ERROR: {
      const { error } = action.payload;
      return state
        .setIn(['error'], error)
        .setIn(['status'], STATUS_ERROR)
        .setIn(['comments'], fromJS([]));
    }
    case ADD_COMMENT_REQUEST: {
      return state.setIn(['submitFormStatus'], STATUS_LOADING);
    }
    case ADD_COMMENT_SUCCESS: {
      const { text, type, date, id } = action.payload;
      return state
        .updateIn(['comments'], (comments) =>
          fromJS([
            ...comments,
            {
              id,
              text,
              lastUpdated: new Date(Date.now()).toISOString(),
              date,
              type,
            },
          ])
        )
        .setIn(['submitFormStatus'], STATUS_SUCCESS);
    }
    case ADD_COMMENT_ERROR: {
      return state.setIn(['submitFormStatus'], STATUS_ERROR);
    }
    case RESET_COMMENTS_FORM_STATUS: {
      return state.setIn(['submitFormStatus'], STATUS_NOT_REQUESTED);
    }
    case CHANGE_COMMENT_REQUEST: {
      return state.setIn(['submitFormStatus'], STATUS_LOADING);
    }
    case CHANGE_COMMENT_SUCCESS: {
      const { text, commentId } = action.payload;
      return state
        .updateIn(['comments'], (comments) =>
          fromJS(
            comments.toJS().map((comment) => {
              if (comment.id === commentId) {
                return { ...comment, text };
              }
              return comment;
            })
          )
        )
        .setIn(['submitFormStatus'], STATUS_SUCCESS);
    }
    case DELETE_COMMENT_SUCCESS: {
      const { commentId } = action.payload;
      return state.updateIn(['comments'], (comments) =>
        fromJS(comments.toJS().filter((comment) => comment.id !== commentId))
      );
    }
    default:
      return state;
  }
};
