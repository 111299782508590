import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { COMMON_LABELS } from 'const/translations';
import { EMPTY_FUNCTION } from 'const';
import { Button } from 'components/ui';
import { Card } from 'components/ui/Card';
import Styles from './MainPageCard.module.css';

const propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  image: PropTypes.any,
  action: PropTypes.func,
  clickHandler: PropTypes.func,
};

export const MainPageCard = memo(
  ({ title, image, paragraph, action, clickHandler }) => {
    const handleClick = useCallback(() => {
      clickHandler(action);
    }, [clickHandler, action]);
    return (
      <Card
        className={Styles.card}
        onClick={action && clickHandler ? handleClick : EMPTY_FUNCTION}
      >
        <div className={Styles.row}>
          <img src={image} alt="img" className={Styles.img} />
          <h3 className={Styles.title}>{title}</h3>
        </div>
        <Typography.Paragraph className={Styles.paragraph}>
          {paragraph}
        </Typography.Paragraph>
        <div className={Styles.buttonWrapper}>
          <Button variant="success">{COMMON_LABELS.ACCESS}</Button>
        </div>
      </Card>
    );
  }
);

MainPageCard.propTypes = propTypes;
