import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Icon, Typography } from 'antd';
import { Button } from 'components/ui';
import employeeImage from 'assets/images/sararie.png';
import { ADD_EMPLOYEE_TITLE_TRANSLATIONS } from 'const/translations';
import { useHistory } from 'react-router-dom';
import { ROUTES, STATUS_SUCCESS } from 'const';
import { selectSubmitStatus } from 'modules/employeeRegister/selectors';
import { selectEmailOfHrManager } from 'modules/loggedUserInfo/selectors';
import Styles from 'containers/EmployeeRegisterTitlePage/EmployeeRegisterTitlePage.module.css';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { openSuccessNotification } from 'helpers/notifications';
import { useSelector } from 'react-redux';

const {
  TITLE,
  EMPLOYEE_IMAGE,
  INFO_LIST_UNITS: { INFO_TITLE, UNITS },
  TEXT_REGISTRATION: { MODIFY, SEND_EMAIL },
  BUTTON_ADD,
  FEEDBACK_MESSAGE_SUCCESS,
} = ADD_EMPLOYEE_TITLE_TRANSLATIONS;

export const EmployeeRegisterTitlePage = () => {
  const submitStatus = useSelector(selectSubmitStatus);
  const emailForSendingForms = useSelector(selectEmailOfHrManager);

  const history = useHistory();
  const handleClick = useCallback(
    () => history.push(ROUTES.EMPLOYEE_REGISTER_FORM),
    [history]
  );

  useScrollToTop();

  useEffect(() => {
    if (submitStatus === STATUS_SUCCESS) {
      openSuccessNotification({ message: FEEDBACK_MESSAGE_SUCCESS });
    }
  }, [submitStatus]);
  return (
    <div className={Styles.registrationPage}>
      <div className={Styles.containerWrapper}>
        <Typography.Title className={Styles.title}>{TITLE}</Typography.Title>
        <div className={Styles.welcomeWrapper}>
          <div className={Styles.imageWrapper}>
            <img
              src={employeeImage}
              alt={EMPLOYEE_IMAGE}
              className={Styles.image}
            />
          </div>
          <div className={Styles.info}>
            <Typography.Paragraph className={clsx(Styles.text, Styles.hello)}>
              <Typography.Text strong>{INFO_TITLE}</Typography.Text>
            </Typography.Paragraph>
            <ul className={Styles.list}>
              <li className={clsx(Styles.unit, Styles.listUnit)}>
                <Typography.Text className={Styles.checkIconWrapper}>
                  <Icon type="check" />
                </Typography.Text>
                <Typography.Text>{UNITS.REGISTRATION_UNIT_1}</Typography.Text>
              </li>
              <li className={clsx(Styles.unit, Styles.listUnit)}>
                <Typography.Text className={Styles.checkIconWrapper}>
                  <Icon type="check" />
                </Typography.Text>
                <Typography.Text>{UNITS.REGISTRATION_UNIT_2}</Typography.Text>
              </li>
              <li className={clsx(Styles.unit, Styles.listUnit)}>
                <Typography.Text className={Styles.checkIconWrapper}>
                  <Icon type="check" />
                </Typography.Text>
                <Typography.Text>{UNITS.REGISTRATION_UNIT_3}</Typography.Text>
              </li>
            </ul>
          </div>
        </div>
        <Typography className={Styles.warning}>
          <Typography.Paragraph className={Styles.unit}>
            {MODIFY}
          </Typography.Paragraph>
          <Typography.Paragraph className={Styles.unit}>
            {SEND_EMAIL}
            {'\n'}
            {emailForSendingForms.split('; ').map((email, index) => (
              <a key={email} href={`mailto:${email}`} className={Styles.email}>
                {index === 0 ? email : `, ${email}`}
              </a>
            ))}
          </Typography.Paragraph>
        </Typography>
        <Button big sizeAuto onClick={handleClick}>
          {BUTTON_ADD}
        </Button>
      </div>
    </div>
  );
};
