import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Input, message } from 'antd';

import { openSuccessNotification } from 'helpers/notifications';

import Styles from './RequestHelpModal.module.css';
import { helpRequest } from '../../modules/helpModal/actions';
import { REQUEST_HELP_TRANSLATIONS } from '../../const/translations/RequestHelpModal';
import { CustomSelect } from '../../components/ui/CustomSelect';
import { Button } from '../../components/ui/Button';

import { REQUEST_MODAL_TOPICS } from '../../const/RequestHelpModal';

const propTypes = {
  handleClose: PropTypes.func,
};

const {
  TITLE,
  TOPIC_LABEL,
  TOPIC_PLACEHOLDER,
  ERRORS,
  OK_BUTTON,
  CANCEL_BUTTON,
  MESSAGE_PLACEHOLDER,
  SUBMIT_SUCCESS,
} = REQUEST_HELP_TRANSLATIONS;
const { error: errorMessage } = message;

export const HelpModal = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [topicValue, setTopicValue] = useState(0);
  const [text, setText] = useState('');

  const handleSelectChange = useCallback(
    (_, value) => {
      setTopicValue(value);
    },
    [setTopicValue]
  );

  const handleMessageChange = useCallback(
    (event) => {
      const { value } = event.target;
      setText(value);
    },
    [setText]
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      helpRequest({
        topic: REQUEST_MODAL_TOPICS.find(({ value }) => value === topicValue)
          .label,
        paragraphs: text.split('\n'),
        onSuccess: () => openSuccessNotification({ message: SUBMIT_SUCCESS }),
        onError: (error) =>
          errorMessage(error?.message || ERRORS.DEFAULT_REQUEST_ERROR),
      })
    );
    setText('');
    setTopicValue(0);
    handleClose();
  }, [handleClose, dispatch, topicValue, text]);

  return (
    <>
      <div className={Styles.Title}>{TITLE}</div>
      <div className={Styles.TopicLabel}>{TOPIC_LABEL}</div>
      <div>
        <CustomSelect
          value={topicValue}
          placeholder={TOPIC_PLACEHOLDER}
          className={Styles.TopicSelect}
          onChange={handleSelectChange}
          name="topic"
          options={REQUEST_MODAL_TOPICS}
        />
      </div>
      <div>
        <Input.TextArea
          onChange={handleMessageChange}
          value={text}
          name="text"
          placeholder={MESSAGE_PLACEHOLDER}
          maxLength={1000}
          className={Styles.Message}
          rows={6}
        />
      </div>
      <div className={Styles.buttonWrapper}>
        <Button
          onClick={handleClose}
          className={clsx(Styles.CancelButton, Styles.button)}
          variant="secondary"
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          variant="success"
          onClick={handleSubmit}
          className={Styles.button}
          disabled={Number.isNaN(topicValue) || !text.trim()}
        >
          {OK_BUTTON}
        </Button>
      </div>
    </>
  );
};

HelpModal.propTypes = propTypes;
