import React, { memo, useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  inputNumberFormatter,
  inputNumberParser,
} from 'modules/SalaryModule/RewardsTable/utils';
import { REWARD_SUFFIXES_BY_TYPES } from 'const/SalaryRewardsPage';
import { formatDecimals, roundToDecimals } from 'helpers/salaryHelpers/rewards';
import { TextCell } from './TextCell';
import Styles from './RewardsTable.module.css';

const propTypes = {
  value: PropTypes.number,
  handleSave: PropTypes.func,
  suffix: PropTypes.string,
  rewardId: PropTypes.string,
  employeeId: PropTypes.string,
};

/**
 * Employee reward table editable cell
 * @param { Number } value - employee reward value
 * @param { String } suffix - depends on reward type '€' or 'h'
 * @param { Function } handleSave - fn for call after input new value and press Enter or blur
 * @param { String } rewardId - reward id
 * @param { Object } record - full table row item
 */
export const EditableCell = memo(
  ({ value, suffix, handleSave, rewardId, employeeId }) => {
    const [input, setInput] = useState(value > 0 ? Math.round(value) : '');
    const [isEditing, setEditing] = useState(false);
    const inputRef = useRef();

    const changeHandler = useCallback((val) => {
      if (Number(val)) {
        setInput(roundToDecimals(val));
      } else if (!val || !val.length) {
        setInput(0);
      }
    }, []);

    const saveHandler = useCallback(() => {
      setEditing(false);
      let updatedValue = input || 0;
      if (suffix === REWARD_SUFFIXES_BY_TYPES.number && updatedValue) {
        updatedValue = input <= 100 ? Math.max(input, 1) : 100;
      }
      if (input !== value && input !== '') {
        handleSave({
          value: Math.max(updatedValue, 0),
          employeeId,
          companyRewardId: rewardId,
        });
      }
    }, [suffix, value, handleSave, employeeId, rewardId, input]);

    const toggleEdit = useCallback(() => {
      setEditing(true);
    }, []);

    useEffect(() => {
      if (isEditing) {
        setInput(value > 0 ? value : '');
        inputRef.current.focus();
      }
    }, [value, isEditing]);

    const displayValue = value ? formatDecimals(value) : 0;

    return (
      <>
        {isEditing ? (
          <div className={Styles.inputWrap}>
            <InputNumber
              className={Styles.input}
              defaultValue={value}
              value={input}
              onPressEnter={saveHandler}
              onBlur={saveHandler}
              onChange={changeHandler}
              parser={inputNumberParser}
              formatter={inputNumberFormatter}
              ref={inputRef}
            />
            <Text className={Styles.inputSuffix}>{suffix}</Text>
          </div>
        ) : (
          <TextCell suffix={suffix} value={displayValue} onClick={toggleEdit} />
        )}
      </>
    );
  }
);

EditableCell.propTypes = propTypes;
