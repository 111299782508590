import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Button } from 'antd';
import { MONTH_YEAR_FORMAT, SHORT_MONTH_YEAR_FORMAT, YEAR_FORMAT } from 'const';
import { CalendarMiniIcon } from 'components/ui/CalendarMiniIcon';
import moment from 'moment';
import 'moment/locale/fr';
import clsx from 'clsx';
import Styles from './MonthPicker.module.css';

const { MonthPicker } = DatePicker;

const MonthPickerMain = ({
  value,
  setValue,
  className,
  isMobileVersion,
  isYearCalendar,
  ...props
}) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const deltaTimeStep = isYearCalendar ? 'year' : 'months';

  const getPreviousTimeStep = useCallback(() => {
    setValue(moment(value).subtract(1, deltaTimeStep));
  }, [value, setValue, deltaTimeStep]);
  const getNextTimeStep = useCallback(() => {
    setValue(moment(value).add(1, deltaTimeStep));
  }, [value, setValue, deltaTimeStep]);
  const onChange = useCallback(
    (val) => {
      setValue(val);
    },
    [setValue]
  );

  const DatePickerComponent = isYearCalendar ? DatePicker : MonthPicker;

  const monthCalendarFormat = isMobileVersion
    ? SHORT_MONTH_YEAR_FORMAT
    : MONTH_YEAR_FORMAT;

  const dateFormat = isYearCalendar ? YEAR_FORMAT : monthCalendarFormat;

  return (
    <div className={clsx(Styles.wrapper, className)}>
      <DatePickerComponent
        open={isDatePickerOpen}
        dropdownClassName={Styles.dropdown}
        allowClear={false}
        suffixIcon={<CalendarMiniIcon />}
        size="large"
        format={dateFormat}
        className={Styles.datePicker}
        value={value ? moment(value) : null}
        onChange={onChange}
        mode={isYearCalendar ? 'year' : 'month'}
        onOpenChange={(status) => {
          if (status) {
            setDatePickerOpen(true);
          } else {
            setDatePickerOpen(false);
          }
        }}
        onPanelChange={(v) => {
          onChange(v);
          setDatePickerOpen(false);
        }}
        {...props}
      />
      {!isMobileVersion && (
        <>
          <Button
            className={Styles.button}
            size="large"
            icon="left"
            onClick={getPreviousTimeStep}
          />
          <Button
            className={Styles.button}
            size="large"
            icon="right"
            onClick={getNextTimeStep}
          />
        </>
      )}
    </div>
  );
};

MonthPickerMain.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.func,
  className: PropTypes.string,
  isMobileVersion: PropTypes.bool,
  isYearCalendar: PropTypes.bool,
};

export { MonthPickerMain as MonthPicker };
