import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon, Typography } from 'antd';
// import MainStyles from 'assets/styles/main.module.css';
import Styles from './Message.module.css';

const { Text } = Typography;

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  leftGutter: PropTypes.bool,
  className: PropTypes.string,
};

export const Message = memo(
  ({ children, type, leftGutter = true, className }) => {
    const renderIcon = useCallback(() => {
      if (type === 'success') {
        return (
          <Icon type="check-circle" theme="outlined" className={Styles.icon} />
        );
      }
      if (type === 'error') {
        return (
          <Icon type="close-circle" theme="filled" className={Styles.icon} />
        );
      }
      return <Icon type="info-circle" theme="filled" className={Styles.icon} />;
    }, [type]);
    return (
      <div
        className={clsx(
          Styles.messageWrapper,
          className,
          { [Styles.leftGutter]: leftGutter },
          Styles[`${type}`]
        )}
      >
        {renderIcon()}
        <Text className={Styles.text}>{children}</Text>
      </div>
    );
  }
);

Message.propTypes = propTypes;
