import React, { useEffect } from 'react';
import MainStyles from 'assets/styles/main.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectUserAuthorizedModules,
  selectUserCurrentCompanyDosId,
  selectUserCurrentCompanyExercice,
} from 'modules/loggedUserInfo/selectors';
import { Col } from 'antd';
import { NAVIGATION_LABELS } from 'const/translations';
import { APP_MODULES, ROUTES } from 'const';
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { Section } from 'components/Section';
import { getAllCommentsRequest } from 'modules/commentsModal/actions';
import { LastUpdate } from 'components/LastUpdate';
import {
  CounterpartiesChart,
  CounterpartiesTable,
} from 'components/Counterparties';

import { useCounterparties } from 'helpers/counterparties';
import Styles from './Counterparties.module.css';

export const Counterparties = () => {
  const dispatch = useDispatch();
  const { actions, translations, updateDate } = useCounterparties();

  const currentExercice = useSelector(selectUserCurrentCompanyExercice);
  const currentCompanyId = useSelector(selectUserCurrentCompanyDosId);
  const userModules = useSelector(selectUserAuthorizedModules);

  useEffect(() => {
    dispatch(actions.searchRequest());
    if (currentExercice) {
      dispatch(getAllCommentsRequest({ currentExercice }));
    }
  }, [dispatch, currentCompanyId, currentExercice, actions]);

  return (
    <div className={Styles.hubPageBackground}>
      <div className={clsx(MainStyles.container)}>
        <Link to={ROUTES.ROOT} className={Styles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
          <span>{NAVIGATION_LABELS.INDICATORS}</span>
        </Link>
        <Section title={translations.TITLE}>
          <div className={Styles.container}>
            <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
              <CounterpartiesChart currentExercice={currentExercice} />
            </Col>
            {userModules.includes(APP_MODULES.DASHBOARD_DETAILS) && (
              <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
                <CounterpartiesTable />
              </Col>
            )}
            <LastUpdate
              updateDate={updateDate}
              showCondition={Boolean(currentExercice)}
              className={Styles.updateDate}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};
