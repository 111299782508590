import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';

import {
  USER_COMPANIES_DASHBOARD_DATA_SUCCESS,
  LOGGED_USER_INFO_SUCCESS,
  LOGGED_USER_INFO_ERROR,
  CHANGE_CURRENT_COMPANY,
  LOGGED_USER_INFO_REQUEST,
  SET_CURRENT_COMPANY_API_KEY_SUCCESS,
  USER_FINANCIALS_DATA_SUCCESS,
  ACCEPT_TERMS_OF_USE_SUCCESS,
  USER_FINANCIALS_DATA_ERROR,
  GO_TO_NEXT_NOTIFICATION,
} from 'modules/loggedUserInfo/actions';

const initialState = fromJS({
  info: {
    data: {
      companies: [],
      current: { name: '' },
      companiesDashboardData: [],
      financials: { debtors: 'N/A', creditors: 'N/A' },
      user: {
        termsAccepted: true,
        notifications: [],
      },
    },
    error: '',
    status: STATUS_NOT_REQUESTED,
  },
  notificationIndex: 0,
});

export const loggedUserInfo = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_USER_INFO_REQUEST:
      return state.setIn(['info', 'status'], STATUS_LOADING);
    case LOGGED_USER_INFO_SUCCESS: {
      const {
        companies,
        modules,
        primaryCompanyData,
        userData,
      } = action.payload;
      return state
        .setIn(['info', 'status'], STATUS_SUCCESS)
        .setIn(['info', 'data', 'companies'], fromJS(companies))
        .setIn(['info', 'data', 'authorizedModules'], fromJS(modules))
        .setIn(['info', 'data', 'user'], fromJS(userData))
        .setIn(['info', 'data', 'current'], fromJS(primaryCompanyData));
    }
    case LOGGED_USER_INFO_ERROR: {
      const error = action.payload;
      return state
        .setIn(['info', 'status'], STATUS_ERROR)
        .setIn(['info', 'error'], error);
    }
    case CHANGE_CURRENT_COMPANY: {
      const { companyId } = action.payload;
      const companyData = state
        .getIn(['info', 'data', 'companies'])
        .find((company) => company.get('id') === companyId);
      return state.setIn(['info', 'data', 'current'], companyData);
    }
    case SET_CURRENT_COMPANY_API_KEY_SUCCESS: {
      const { data } = action.payload;
      return state
        .updateIn(['info', 'data', 'companies'], (companies) => {
          const index = companies.findIndex(
            (company) => company.get('id') === data.id
          );
          return companies.set(index, fromJS(data));
        })
        .setIn(['info', 'data', 'current'], fromJS(data));
    }
    case USER_COMPANIES_DASHBOARD_DATA_SUCCESS: {
      const { data } = action.payload;
      return state.setIn(
        ['info', 'data', 'companiesDashboardData'],
        fromJS(data)
      );
    }
    case USER_FINANCIALS_DATA_SUCCESS: {
      const { data } = action.payload;
      return state.setIn(['info', 'data', 'financials'], fromJS(data));
    }
    case USER_FINANCIALS_DATA_ERROR: {
      return state.setIn(
        ['info', 'data', 'financials'],
        fromJS({ debtors: 'N/A', creditors: 'N/A' })
      );
    }
    case ACCEPT_TERMS_OF_USE_SUCCESS: {
      return state.setIn(['info', 'data', 'user', 'termsAccepted'], true);
    }
    case GO_TO_NEXT_NOTIFICATION: {
      const notificationIndex = state.getIn(['notificationIndex']);
      return state.setIn(['notificationIndex'], notificationIndex + 1);
    }

    default:
      return state;
  }
};
