import React, { memo } from 'react';
import { Select } from 'components/ui';
import { useFormikOnChange } from 'hooks';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';

export const SelectFormik = memo(({ onChange, name, ...props }) => {
  const handleChange = useFormikOnChange(name, onChange);
  return <Select {...props} onChange={handleChange} />;
});

SelectFormik.propTypes = formikOnChangeAdaptorTypes;
