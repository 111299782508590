import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import {
  ADD_PACKAGES_TO_CART,
  CLEAR_CART,
  GET_PACKAGES_COSTS_ERROR,
  GET_PACKAGES_COSTS_REQUEST,
  GET_PACKAGES_COSTS_SUCCESS,
  INITIALIZE_CART,
  PUT_USER_SUBSCRIBED_PACKAGES,
  REMOVE_PACKAGE_FROM_CART,
  RESET_CART_AND_ADDITIONAL_USERS,
  SET_SUBSCRIPTION_STATUS,
  SUBSCRIBE_TO_PACKAGES_REQUEST,
  SUBSCRIBE_TO_PACKAGES_SUCCESS,
} from 'modules/cart/actions';
import {
  addNewPackagesToCachedAdditionalUsersInCart,
  getAddedPackagesFromDependencies,
  getUniqueSimpleArray,
} from 'modules/cart/helpers';
import { CART_PACKAGES_LOCAL_STORAGE_KEY } from 'const/cartPage';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from 'helpers/storage';

const initialState = fromJS({
  currentItems: [],
  status: STATUS_NOT_REQUESTED,
  shouldBeReseted: false,
  packagesCosts: {},
  packagesCostsStatus: STATUS_NOT_REQUESTED,
  userSubscribedPackages: [],
});

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_USER_SUBSCRIBED_PACKAGES: {
      const { packages } = action.payload;
      return state.setIn(['userSubscribedPackages'], fromJS(packages));
    }
    case ADD_PACKAGES_TO_CART: {
      const { packageName } = action.payload;

      const currentUserPackages = state
        .getIn(['userSubscribedPackages'])
        .toJS();

      return state.updateIn(['currentItems'], (prevItems) => {
        const currentStoredPackages =
          getFromStorage({
            key: CART_PACKAGES_LOCAL_STORAGE_KEY,
            shouldBeParsed: true,
          }) || [];
        const currentCartPackagesInState = prevItems.toJS();

        if (currentUserPackages.includes(packageName)) {
          return fromJS(currentCartPackagesInState);
        }

        const packageWithMainDependency = getAddedPackagesFromDependencies(
          packageName,
          currentUserPackages
        );
        addNewPackagesToCachedAdditionalUsersInCart(
          packageWithMainDependency,
          currentCartPackagesInState
        );

        const updatedPackages = getUniqueSimpleArray([
          ...currentCartPackagesInState,
          ...currentStoredPackages,
          ...packageWithMainDependency,
        ]);
        setToStorage({
          key: CART_PACKAGES_LOCAL_STORAGE_KEY,
          value: updatedPackages,
        });
        return fromJS(updatedPackages);
      });
    }
    case REMOVE_PACKAGE_FROM_CART: {
      const { packageName } = action.payload;
      return state.updateIn(['currentItems'], (prevItems) => {
        const prevItemsArray = prevItems.toJS();
        const updatedPackages = prevItemsArray.filter(
          (pack) => pack !== packageName
        );
        setToStorage({
          key: CART_PACKAGES_LOCAL_STORAGE_KEY,
          value: updatedPackages,
        });
        return fromJS(updatedPackages);
      });
    }
    case INITIALIZE_CART: {
      const cachedCart = getFromStorage({
        key: CART_PACKAGES_LOCAL_STORAGE_KEY,
      });
      return state.setIn(
        ['currentItems'],
        fromJS(cachedCart ? JSON.parse(cachedCart) : [])
      );
    }
    case CLEAR_CART: {
      removeFromStorage({ key: CART_PACKAGES_LOCAL_STORAGE_KEY });
      return state.setIn(['currentItems'], fromJS([]));
    }
    case SUBSCRIBE_TO_PACKAGES_REQUEST: {
      return state.setIn(['status'], STATUS_LOADING);
    }
    case SUBSCRIBE_TO_PACKAGES_SUCCESS: {
      return state.setIn(['status'], STATUS_SUCCESS);
    }
    case SET_SUBSCRIPTION_STATUS: {
      const { value } = action.payload;
      return state.setIn(['status'], value);
    }
    case RESET_CART_AND_ADDITIONAL_USERS: {
      const { value } = action.payload;
      return state.setIn(['shouldBeReseted'], value);
    }
    case GET_PACKAGES_COSTS_REQUEST: {
      return state.setIn(['packagesCostsStatus'], STATUS_LOADING);
    }
    case GET_PACKAGES_COSTS_SUCCESS: {
      const { costs } = action.payload;
      return state
        .setIn(['packagesCosts'], fromJS(costs))
        .setIn(['packagesCostsStatus'], STATUS_SUCCESS);
    }
    case GET_PACKAGES_COSTS_ERROR: {
      return state
        .setIn(['packagesCostsStatus'], STATUS_ERROR)
        .setIn(['packagesCosts'], fromJS({}));
    }
    default: {
      return state;
    }
  }
};
