export const ADDITIONAL_USERS_PAGE_TRANSLATIONS = {
  NOTIFICATION_MESSAGE:
    'Les utilisateurs seront ajoutés sous 48h (temps d’activation estimé)',
  PAGE_TITLE: 'Gestion des utilisateurs',
  ADD_USER_BUTTON: '+ Ajouter',
  CONFIRM_MODAL_TITLE:
    'Êtes-vous sûr de vouloir supprimer l’utilisateur {{EMAIL}} ?',
  CONFIRM_MODAL_CANCEL: 'Annuler',
  CONFIRM_MODAL_OK_TEXT: 'Supprimer',
};

export const ADDITIONAL_USERS_TABLE_TRANSLATIONS = {
  NAME_HEADER: 'Prénom',
  SURNAME_HEADER: 'Nom',
  MAIL_HEADER: 'Mail',
  POSITION_HEADER: 'Fonction',
  MODULES_HEADER: 'Services',
  SELECT_INPUT_TEXT: 'Sélectionné : ',
  SELECT_PLACEHOLDER: 'Sélectionné : 0',
  EMPTY_TABLE_TEXT: 'Aucun utilisateur ajouté',
  SAVE_TABLE_BUTTON: 'Enregistrer',
  RESET_CHANGES_BUTTON: 'Annuler les modifications',
  EMAIL_EQUAL_MAIN_USER_EMAIL:
    'Merci d’utiliser une adresse e-mail différente de la vôtre',
};

export const ADDITIONAL_USERS_MODAL_TRANSLATIONS = {
  TITLE: 'Ajouter un utilisateur',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'Ex : prénom.nom@societe.fr',
  EMAIL_ERROR: 'Erreur de saisie de l`adresse mail',
  REQUIRED_ERROR: 'Ce champ est requis',
  MODULES_ERROR:
    'Merci de choisir au moins un service à activer pour cet utilisateur',
  POSITION_LABEL: 'Fonction',
  POSITION_PLACEHOLDER: 'Ex : Assistant, commercial...',
  NAME_LABEL: 'Prénom',
  SURNAME_LABEL: 'Nom',
  SELECT_INPUT_TEXT: 'Sélectionné',
  SELECT_PLACEHOLDER: 'Sélectionné : 0',
  MODULES_LABEL: 'Services à activer',
  CANCEL_BUTTON: 'Annuler',
  CONFIRM_BUTTON: 'Ajouter',
  EDIT_BUTTON: 'Sauvegarder',
};
