import { Col, Row, Typography } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { VACATION_MODAL } from '../../../../../const/translations/SalaryVacations';
import Styles from '../AddVacationModal.module.css';

const { Text } = Typography;
const { LABEL_TEXTS } = VACATION_MODAL;

const propTypes = {
  calculatedTime: PropTypes.object,
};

export const VacationModalTotalTime = memo(({ calculatedTime }) => {
  const formattedMinutes = Number(calculatedTime.minutes)
    ? String(calculatedTime.minutes).padStart(2, '0')
    : '00';
  return (
    <>
      <Row type="flex" className={Styles.row}>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          className={clsx(Styles.col, Styles.noMargin)}
        >
          <Text className={Styles.formLabel}>{LABEL_TEXTS.DAYS}</Text>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 18 }} className={Styles.col}>
          <Text>
            {calculatedTime.days
              .toString()
              .split('.')
              .join(',')}{' '}
            {calculatedTime.days <= 1 ? 'jour' : 'jours'}
          </Text>
        </Col>
      </Row>
      <Row type="flex" className={Styles.row}>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          className={clsx(Styles.col, Styles.noMargin)}
        >
          <Text className={Styles.formLabel}>{LABEL_TEXTS.HOURS}</Text>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 18 }} className={Styles.col}>
          <Text>
            {calculatedTime.hours} h {formattedMinutes} min
          </Text>
        </Col>
      </Row>
    </>
  );
});

VacationModalTotalTime.propTypes = propTypes;
