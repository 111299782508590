import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import { Col } from 'antd';
import clsx from 'clsx';
import Styles from 'components/Counterparties/CounterpartyTable/CounterpartyTable.module.css';
import MainStyles from 'assets/styles/main.module.css';
import { Section } from 'components/Section';
import { STATUS_LOADING, STATUS_NOT_REQUESTED } from 'const';
import { Spinner } from 'components/Spinner';
import { CounterpartyTable } from 'components/Counterparties';
import { useCounterparty } from 'helpers/counterparties';

export const Counterparty = () => {
  const { counterparty, routes, translations } = useCounterparty();

  const isLoading = [STATUS_NOT_REQUESTED, STATUS_LOADING].includes(
    counterparty.status
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={Styles.hubPageBackground}>
      <div className={MainStyles.container}>
        <Link to={routes.counterparties} className={Styles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={Styles.arrowIcon} />
          <span>{translations.BUTTON_BACK}</span>
        </Link>
        <Section
          title={translations.TITLE.replace(
            '{{NAME}}',
            counterparty.name ? `"${counterparty.name}"` : ''
          )}
        >
          <div className={Styles.container}>
            <Col span={24} className={clsx(MainStyles.col, Styles.col)}>
              <CounterpartyTable dataSource={counterparty.data} />
            </Col>
          </div>
        </Section>
      </div>
    </div>
  );
};
