import React from 'react';
import { Icon } from 'antd';
import { TableAction } from 'components/ui/TableAction';
import {
  usePayTooltipProps,
  usePayIconClassName,
  usePayActionModal,
} from 'hooks/invoiceSearchActions/pay';
import { useInvoiceActionsFlags } from 'hooks/invoiceSearchActions/useInvoiceActionsFlags';

export const PayInvoiceAction = () => {
  const { canPay } = useInvoiceActionsFlags();

  const tooltipProps = usePayTooltipProps();
  const iconStyle = usePayIconClassName();
  const handleShowModal = usePayActionModal();

  if (!canPay) {
    return null;
  }

  return (
    <TableAction
      tooltipProps={tooltipProps}
      displayComponent={
        <Icon type="euro" className={iconStyle} onClick={handleShowModal} />
      }
    />
  );
};
