import React, { useCallback } from 'react';
import { DatePicker } from 'components/ui';
import { formikOnChangeAdaptorTypes } from 'const/propTypes';
import moment from 'moment';

export const DatePickerAdapted = ({
  onChange,
  name,
  value,
  storageFormat = null,
  ...props
}) => {
  const handleChange = useCallback(
    (newValue) =>
      newValue
        ? onChange({
            target: {
              name,
              value: storageFormat
                ? newValue.format(storageFormat)
                : newValue.toISOString(),
            },
          })
        : onChange({ target: { name, value: null } }),
    [onChange, name, storageFormat]
  );
  return (
    <DatePicker
      {...props}
      onChange={handleChange}
      value={storageFormat && value ? moment(value, storageFormat) : value}
    />
  );
};

DatePickerAdapted.propTypes = formikOnChangeAdaptorTypes;
