import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Typography } from 'antd';
import { loginRequest } from 'modules/Authentication/actions';
import {
  selectLoginErrorCode,
  selectLoginErrorMessage,
} from 'modules/Authentication/selectors';
import { LeafyBackground } from 'components/ui/LeafyBackground';
import { FormCard } from 'components/FormCard/FormCard';
import { Input } from 'components/ui';
import { GENERAL_ERRORS, LOGIN_PAGE_TRANSLATIONS } from 'const/translations';
import Styles from 'components/FormCard/FormCard.module.css';
import { FIELD_NAMES } from 'const/forms';
import { Banners } from './Banners';

const { LABELS, ERRORS, HEADER_TEXT } = LOGIN_PAGE_TRANSLATIONS;
const { AUTH } = FIELD_NAMES;

export const LoginPage = () => {
  const dispatch = useDispatch();

  const errorCode = useSelector(selectLoginErrorCode);
  const errorMessage = useSelector(selectLoginErrorMessage);

  // TODO: Rewrite login using Formik instead of `useState`
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 403:
          if (errorMessage === 'Attempts: 3') {
            setEmailError(ERRORS.THREE_ATTEMPTS_LEFT);
            setPasswordError(ERRORS.THREE_ATTEMPTS_LEFT);
            break;
          }
          setEmailError(ERRORS.CREDENTIALS_INCORRECT);
          setPasswordError(ERRORS.CREDENTIALS_INCORRECT);
          break;
        case 423:
          setEmailError(GENERAL_ERRORS.ACCOUNT_LOCKED);
          setPasswordError(GENERAL_ERRORS.ACCOUNT_LOCKED);
          break;
        default:
          setEmailError(ERRORS.SERVER_ERROR);
          setPasswordError(ERRORS.SERVER_ERROR);
      }
    }
  }, [errorCode, errorMessage]);

  const handlePasswordChange = useCallback(({ target: { value } }) => {
    setPassword(value);
    setPasswordError('');
    setEmailError('');
  }, []);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setEmail(value);
    setEmailError('');
  }, []);

  const validateField = (input) => {
    const name = typeof input === 'string' ? input : input.target.name;
    if (name === AUTH.EMAIL && !email.length) {
      setEmailError(ERRORS.FIELD_REQUIRED);
      return false;
    }
    if (name === AUTH.OLD_PASSWORD && !password.length) {
      setPasswordError(ERRORS.FIELD_REQUIRED);
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const valid = [
      validateField(AUTH.EMAIL),
      validateField(AUTH.OLD_PASSWORD),
    ].every(Boolean);
    if (valid) {
      dispatch(loginRequest({ password, email }));
    }
  };

  return (
    <LeafyBackground>
      <FormCard
        submitText={LABELS.LOG_IN}
        onSubmit={handleSubmit}
        links={['CHANGE_PASSWORD', 'FORGOT_PASSWORD']}
      >
        <Typography.Title className={Styles.textTitle}>
          {HEADER_TEXT}
        </Typography.Title>
        <Form.Item
          label={
            <Typography.Paragraph className={Styles.textLabel}>
              {LABELS.EMAIL}
            </Typography.Paragraph>
          }
          validateStatus={emailError.length ? 'error' : ''}
          help={emailError === ERRORS.FIELD_REQUIRED ? emailError : ''}
        >
          <Input
            onChange={handleEmailChange}
            name={AUTH.EMAIL}
            type="email"
            onBlur={validateField}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Paragraph className={Styles.textLabel}>
              {LABELS.PASSWORD}
            </Typography.Paragraph>
          }
          validateStatus={passwordError.length ? 'error' : ''}
          help={passwordError}
        >
          <Input.Password
            onChange={handlePasswordChange}
            name={AUTH.OLD_PASSWORD}
            onBlur={validateField}
          />
        </Form.Item>
      </FormCard>
      <Banners />
    </LeafyBackground>
  );
};
