import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { getFromStorage, setToStorage } from 'helpers/storage';
import Styles from './Notification.module.css';

export const Notification = ({ message, id, headerFix }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (getFromStorage({ key: `closedNotification-${id}` })) {
      setShow(false);
    }
  }, [id]);

  const close = useCallback(() => {
    setShow(false);
    setToStorage({ key: `closedNotification-${id}`, value: true });
  }, [id]);

  return show ? (
    <div
      className={Styles.customNotification}
      style={headerFix && { transform: 'translateY(20px)' }}
    >
      <div>{message}</div>
      <Button
        type="link"
        icon="close"
        className={Styles.closeButton}
        onClick={close}
      />
    </div>
  ) : null;
};

const propTypes = {
  message: PropTypes.string,
  id: PropTypes.string,
  headerFix: PropTypes.bool,
};

Notification.propTypes = propTypes;
