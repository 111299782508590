import { request } from 'utils/request';

export const getCompanyRewardsRequest = ({ companyId, query }) =>
  request.get(`/companies/${companyId}/rewards`, { params: query });

export const getCompanyUnaddedRewardsRequest = ({ companyId, query }) =>
  request.get(`/companies/${companyId}/rewards-unadded`, { params: query });

export const getRewardsFamiliesRequest = (payload) => {
  const { companyId, year, month } = payload;
  let query = '';
  if (month && year) {
    query += `?year=${year}&month=${month}`;
  }
  return request.get(`/companies/${companyId}/rewards-families${query}`);
};

export const getRewardsFamilyItemsRequest = (payload) => {
  const { companyId, familyId, year, month } = payload;
  let query = '';
  if (month && year) {
    query += `?year=${year}&month=${month}`;
  }
  return request.get(
    `/companies/${companyId}/rewards-families/${familyId}${query}`
  );
};

export const createRewardFamilyRequest = (payload) =>
  request.post(`/companies/${payload.companyId}/rewards-families`, {
    title: payload.title,
  });

export const addRewardToFamilyRequest = (payload) =>
  request.post(`/companies/${payload.companyId}/rewards`, payload.data);

export const updateRewardToFamilyRequest = (payload) =>
  request.put(
    `/companies/${payload.companyId}/rewards/${payload.rewardId}`,
    payload.data
  );

export const updateRewardFamilyRequest = (payload) =>
  request.put(
    `/companies/${payload.companyId}/rewards-families/${payload.familyId}`,
    {
      title: payload.title,
    }
  );

export const removeRewardFromFamilyRequest = (payload) =>
  request.delete(
    `/companies/${payload.companyId}/rewards/${payload.companyRewardId}`
  );

export const removeRewardsFamilyRequest = (payload) =>
  request.delete(
    `/companies/${payload.companyId}/rewards-families/${payload.familyId}`
  );
