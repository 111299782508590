import { fromJS } from 'immutable';
import {
  STATUS_NOT_REQUESTED,
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from '../../../const';
import {
  HELP_REQUEST,
  HELP_REQUEST_ERROR,
  HELP_REQUEST_SUCCESS,
} from '../actions';

const initialState = fromJS({
  status: STATUS_NOT_REQUESTED,
  error: {},
});

export const requestHelp = (state = initialState, action) => {
  switch (action.type) {
    case HELP_REQUEST:
      return state
        .set('status', STATUS_LOADING)
        .set('error', initialState.get('error'));
    case HELP_REQUEST_SUCCESS:
      return state.set('status', STATUS_SUCCESS);
    case HELP_REQUEST_ERROR:
      return state
        .set('status', STATUS_ERROR)
        .set('error', fromJS(action.payload.error));
    default:
      return state;
  }
};
