import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import clsx from 'clsx';
import { DATE_TIME_FORMAT, INVOICE_SEARCH_PAYMENT_STATUSES } from 'const';
import {
  INVOICE_SEARCH_TRANSLATIONS,
  PAID_INVOICE_STATUSES,
} from 'const/translations/InvoiceSearch';
import { ExpandableParagraph } from 'components/ui/ExpandableParagraph';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceModalButtons } from 'hooks/invoiceSearchActions/useInvoiceModalButtons';
import { ModalContentButtons } from './ModalContentButtons';

const { REJECTED } = PAID_INVOICE_STATUSES;

const {
  MODAL: {
    APPROVED_BY,
    APPROVED_DUE_DATE,
    APPROVED_DATE,
    APPROVE_COMMENTARY,
    APPROVED_AS_GROUP_COMMENTARY,
    PAID_BY,
    PAID_DATE,
    PAYMENT_STATUS,
    PAYMENT_STATUS_LAST_UPDATED,
  },
} = INVOICE_SEARCH_TRANSLATIONS;
const { IN_PROGRESS: IN_PROGRESS_STATUS } = INVOICE_SEARCH_PAYMENT_STATUSES;

/**
 * Function shows modal with information about already approved invoice
 * and validation / payment actions
 */
export const InvoiceSearchInfoModal = (modalProps) => {
  const {
    approvedAsGroup,
    paymentApprovedBy,
    paymentApprovedDate,
    approvedDueDate,
    paymentApprovedComment,
    paymentDoneDate,
    paymentDoneBy,
    paymentStatus,
    paymentStatusLastUpdated,
    hideStatus,
    isValidateAction,
  } = modalProps;

  const paidStatusLabel =
    paymentStatus &&
    (Object.values(PAID_INVOICE_STATUSES).find(
      ({ value }) => value === paymentStatus
    )?.label ||
      null);

  const buttons = useInvoiceModalButtons(modalProps);

  const paidStatusLabelForValidate =
    paymentStatus === IN_PROGRESS_STATUS
      ? PAID_INVOICE_STATUSES.IN_PROGRESS.label
      : null;

  return (
    <>
      <div className={Styles.infoModalRow}>
        <span className={Styles.infoModalLabel}>{APPROVED_BY}</span>
        <Typography>{paymentApprovedBy}</Typography>
      </div>
      <div className={Styles.infoModalRow}>
        <span className={Styles.infoModalLabel}>{APPROVED_DATE}</span>
        <Typography>
          {moment(paymentApprovedDate).format(DATE_TIME_FORMAT)}
        </Typography>
      </div>
      <div className={Styles.infoModalRow}>
        <span className={Styles.infoModalLabel}>{APPROVED_DUE_DATE}</span>
        <Typography>{approvedDueDate}</Typography>
      </div>
      <div className={Styles.infoModalRow}>
        <span className={Styles.infoModalLabel}>
          {approvedAsGroup ? APPROVED_AS_GROUP_COMMENTARY : APPROVE_COMMENTARY}
        </span>
        <div className={Styles.infoModalComment}>
          <ExpandableParagraph text={paymentApprovedComment} />
        </div>
      </div>
      {paymentDoneBy && (
        <>
          <br />
          <div className={Styles.infoModalRow}>
            <span className={Styles.infoModalLabel}>{PAID_BY}</span>
            <Typography>{paymentDoneBy}</Typography>
          </div>
          <div className={Styles.infoModalRow}>
            <span className={Styles.infoModalLabel}>{PAID_DATE}</span>
            <Typography>
              {moment(paymentDoneDate).format(DATE_TIME_FORMAT)}
            </Typography>
          </div>
          {!hideStatus && (
            <>
              <div
                className={clsx(Styles.infoModalRow, {
                  [Styles.infoModalValueError]:
                    paymentStatus === REJECTED.value,
                })}
              >
                <span className={Styles.infoModalLabel}>{PAYMENT_STATUS}</span>
                <Typography>
                  {isValidateAction
                    ? paidStatusLabelForValidate
                    : paidStatusLabel}
                </Typography>
              </div>
              <div className={Styles.infoModalRow}>
                <span className={Styles.infoModalLabel}>
                  {PAYMENT_STATUS_LAST_UPDATED}
                </span>
                <Typography>
                  {moment(paymentStatusLastUpdated).format(DATE_TIME_FORMAT)}
                </Typography>
              </div>
            </>
          )}
        </>
      )}
      <ModalContentButtons buttons={buttons} />
    </>
  );
};
