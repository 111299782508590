export const REQUEST_HELP_TRANSLATIONS = {
  TITLE: 'Demande à l’équipe de support My Exco',
  OK_BUTTON: 'Envoyer',
  CANCEL_BUTTON: 'Annuler',
  TOPIC_LABEL: 'Sujet',
  TOPIC_PLACEHOLDER: "Changement d'utilisateur",
  MESSAGE_PLACEHOLDER: 'Votre demande',
  ERRORS: {
    NO_TITLE: 'Veuillez choisir le sujet',
    NO_MESSAGE: 'Veuillez écrire votre demande',
    DEFAULT_REQUEST_ERROR: 'Erreur de demande de Support',
  },
  SUBMIT_SUCCESS: 'Merci ! Votre demande a été envoyée',
  TOPICS: {
    GENERAL: 'Général',
    FACT: 'FACT (factures clients)',
    PAY: 'PAY (paiement fournisseurs)',
    EMPLOYEES: 'Mes Ressources Humaines',
    SEND_DOCUMENTS: 'Envoi de documents',
    SHAREPOINT: 'Dossier partagé avec Exco',
    SEARCH_INVOICES: 'Recherche facture',
    OTHER: 'Autre',
  },
};
