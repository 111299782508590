import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { Button } from 'components/ui/Button';
import Styles from 'components/ServiceCard/ServiceCard.module.css';
import clsx from 'clsx';
import { ROUTES } from 'const';
import { useDispatch } from 'react-redux';
import { addPackagesToCart, addWaibiToCart } from 'modules/cart/actions';
import { history } from 'routing/history';
import { PACKAGES_NAMES } from 'const/packages';
import { Spinner } from 'components/Spinner';
import { handleInternalRedirect } from 'helpers/common';

const propTypes = {
  isActive: PropTypes.bool.isRequired,
  isAddedToCart: PropTypes.bool.isRequired,
  packageName: PropTypes.string,
  isMinified: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAccessButtonHidden: PropTypes.bool,
  landingPage: PropTypes.string,
};

const {
  BUTTONS_TEXT: {
    GO_TO_CART_PAGE,
    ADD_TO_CART,
    LEARN_MORE,
    GET_ACCESS,
    CONTACT_WAIBI_CONSULTANT,
  },
} = HOMEPAGE_FORM_TRANSLATIONS;

export const ServiceCardButtons = memo(
  ({
    isMinified,
    isActive,
    isAddedToCart,
    isLoading = false,
    isAccessButtonHidden = false,
    packageName,
    landingPage = ROUTES.ROOT,
  }) => {
    const dispatch = useDispatch();

    const addPackageToCart = useCallback(
      (e) => {
        e.stopPropagation();
        dispatch(addPackagesToCart({ packageName }));
      },
      [dispatch, packageName]
    );

    const redirectToCartPage = useCallback((e) => {
      e.stopPropagation();
      history.push(ROUTES.CART);
    }, []);

    const requestWaibiSubscription = useCallback(
      (e) => {
        e.stopPropagation();
        dispatch(addWaibiToCart({ packageName }));
      },
      [dispatch, packageName]
    );

    const redirectToLandingPage = useCallback(
      (e) => {
        e.stopPropagation();
        handleInternalRedirect(landingPage);
      },
      [landingPage]
    );

    if (isActive) {
      return (
        !isAccessButtonHidden && (
          <Button variant="success" disabled={isLoading}>
            {isLoading ? (
              <Spinner className={Styles.accessButtonSpinner} />
            ) : (
              <span>{GET_ACCESS}</span>
            )}
          </Button>
        )
      );
    }

    const goToCartButton = (
      <Button
        className={clsx(Styles.mainButton, Styles.lightBlueButton)}
        variant="primary"
        onClick={redirectToCartPage}
      >
        <span>{GO_TO_CART_PAGE}</span>
      </Button>
    );

    const purchaseButton =
      packageName === PACKAGES_NAMES.WAIBI ? (
        <Button
          className={Styles.mainButton}
          variant="primary"
          onClick={requestWaibiSubscription}
        >
          <span>{CONTACT_WAIBI_CONSULTANT}</span>
        </Button>
      ) : (
        <Button
          className={Styles.mainButton}
          variant="primary"
          onClick={addPackageToCart}
        >
          <span>{ADD_TO_CART}</span>
        </Button>
      );

    const learnMoreButton = (
      <Button
        className={Styles.lightBlueTransparentButton}
        variant="fullTransparent"
        onClick={redirectToLandingPage}
      >
        <span>{LEARN_MORE}</span>
      </Button>
    );

    return (
      !isMinified && (
        <>
          {isAddedToCart ? goToCartButton : purchaseButton}
          {learnMoreButton}
        </>
      )
    );
  }
);

ServiceCardButtons.propTypes = propTypes;
