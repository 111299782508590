import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Styles from './Card.module.css';

const propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export const Card = memo(({ children, onClick, className }) => (
  <div className={clsx(Styles.card, className)} onClick={onClick}>
    {children}
  </div>
));

Card.propTypes = propTypes;
