import { useDispatch, useSelector } from 'react-redux';
import { getHolidaysRequest } from 'modules/SalaryModule/Calendar/actions';
import { getFromStorage } from 'helpers/storage';
import { ISO_DATE_FORMAT } from 'const';
import moment from 'moment';
import {
  selectHolidaysUpdating,
  selectYearsWithoutHolidays,
} from 'modules/SalaryModule/Calendar/selectors';

/**
 * Custom hook used to get holiday dates and check if date is a holiday;
 * Fetches an array from backend if it was not found in session storage before;
 * Returns an array and helper function;
 *
 * @param {number} year Choosen year to get holidays for.
 * * @typedef {{
 *   holidays: string[],
 *   isDateHoliday: (date: Date) => boolean,
 * }} HolidayData
 * Object containing array of ISO-formatted holidays for choosen
 * year and a function that checks if passed date was found in array.
 * @returns {HolidayData}
 */
export const useHolidays = (year) => {
  const dispatch = useDispatch();

  const storageHolidays =
    getFromStorage({
      key: 'holidays',
      shouldBeParsed: true,
      storage: sessionStorage,
    }) || [];
  const areHolidaysUpdating = useSelector(selectHolidaysUpdating);
  const yearsWithoutHolidays = useSelector(selectYearsWithoutHolidays);

  const isFoundInStorage = storageHolidays?.length !== 0;
  const haveYearChanged =
    isFoundInStorage &&
    !storageHolidays.find((date) => new Date(date).getFullYear() === year);
  const hasNoHolidays = yearsWithoutHolidays.includes(year);
  const shouldHolidaysUpdate =
    (!isFoundInStorage || haveYearChanged) && !hasNoHolidays;

  if (shouldHolidaysUpdate && !areHolidaysUpdating) {
    dispatch(getHolidaysRequest({ year }));
  }

  const holidays = storageHolidays.map((date) =>
    moment(date).format(ISO_DATE_FORMAT)
  );

  return {
    holidays,
    isDateHoliday: (date) =>
      holidays.includes(moment(date).format(ISO_DATE_FORMAT)),
  };
};
