import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'const';
import { Button } from 'components/ui/Button';
import { ACCESS_DENIED_PAGE_TRANSLATIONS } from 'const/translations';
import Styles from './AccessDeniedPage.module.css';

export const AccessDeniedPage = () => (
  <div className={Styles.container}>
    <h3>{ACCESS_DENIED_PAGE_TRANSLATIONS.NO_ACCESS_MESSAGE}</h3>
    <Link to={ROUTES.ROOT}>
      <Button>{ACCESS_DENIED_PAGE_TRANSLATIONS.BUTTON_RETURN}</Button>
    </Link>
  </div>
);
