import React from 'react';
import { Icon } from 'antd';

const CloseSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.18134"
      y="4.81885"
      width="17.8743"
      height="2.4374"
      transform="rotate(45 6.18134 4.81885)"
      fill="currentColor"
    />
    <rect
      x="18.8207"
      y="6.54248"
      width="17.8743"
      height="2.4374"
      transform="rotate(135 18.8207 6.54248)"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;
