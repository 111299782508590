import { WEEK_DAYS } from 'const/translations';

const {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} = WEEK_DAYS;

export const SCHEDULE_DEFAULT_TIMES = [
  { label: '0:00', value: '0:00' },
  { label: '0:15', value: '0:15' },
  { label: '0:30', value: '0:30' },
  { label: '0:45', value: '0:45' },
  { label: '1:00', value: '1:00' },
  { label: '1:15', value: '1:15' },
  { label: '1:30', value: '1:30' },
  { label: '1:45', value: '1:45' },
  { label: '2:00', value: '2:00' },
  { label: '2:15', value: '2:15' },
  { label: '2:30', value: '2:30' },
  { label: '2:45', value: '2:45' },
  { label: '3:00', value: '3:00' },
  { label: '3:15', value: '3:15' },
  { label: '3:30', value: '3:30' },
  { label: '3:45', value: '3:45' },
  { label: '4:00', value: '4:00' },
  { label: '4:15', value: '4:15' },
  { label: '4:30', value: '4:30' },
  { label: '4:45', value: '4:45' },
  { label: '5:00', value: '5:00' },
  { label: '5:15', value: '5:15' },
  { label: '5:30', value: '5:30' },
  { label: '5:45', value: '5:45' },
  { label: '6:00', value: '6:00' },
  { label: '6:15', value: '6:15' },
  { label: '6:30', value: '6:30' },
  { label: '6:45', value: '6:45' },
  { label: '7:00', value: '7:00' },
  { label: '7:15', value: '7:15' },
  { label: '7:30', value: '7:30' },
  { label: '7:45', value: '7:45' },
  { label: '8:00', value: '8:00' },
  { label: '8:15', value: '8:15' },
  { label: '8:30', value: '8:30' },
  { label: '8:45', value: '8:45' },
  { label: '9:00', value: '9:00' },
  { label: '9:15', value: '9:15' },
  { label: '9:30', value: '9:30' },
  { label: '9:45', value: '9:45' },
  { label: '10:00', value: '10:00' },
  { label: '10:15', value: '10:15' },
  { label: '10:30', value: '10:30' },
  { label: '10:45', value: '10:45' },
  { label: '11:00', value: '11:00' },
  { label: '11:15', value: '11:15' },
  { label: '11:30', value: '11:30' },
  { label: '11:45', value: '11:45' },
  { label: '12:00', value: '12:00' },
  { label: '12:15', value: '12:15' },
  { label: '12:30', value: '12:30' },
  { label: '12:45', value: '12:45' },
  { label: '13:00', value: '13:00' },
  { label: '13:15', value: '13:15' },
  { label: '13:30', value: '13:30' },
  { label: '13:45', value: '13:45' },
  { label: '14:00', value: '14:00' },
  { label: '14:15', value: '14:15' },
  { label: '14:30', value: '14:30' },
  { label: '14:45', value: '14:45' },
  { label: '15:00', value: '15:00' },
  { label: '15:15', value: '15:15' },
  { label: '15:30', value: '15:30' },
  { label: '15:45', value: '15:45' },
  { label: '16:00', value: '16:00' },
  { label: '16:15', value: '16:15' },
  { label: '16:30', value: '16:30' },
  { label: '16:45', value: '16:45' },
  { label: '17:00', value: '17:00' },
  { label: '17:15', value: '17:15' },
  { label: '17:30', value: '17:30' },
  { label: '17:45', value: '17:45' },
  { label: '18:00', value: '18:00' },
  { label: '18:15', value: '18:15' },
  { label: '18:30', value: '18:30' },
  { label: '18:45', value: '18:45' },
  { label: '19:00', value: '19:00' },
  { label: '19:15', value: '19:15' },
  { label: '19:30', value: '19:30' },
  { label: '19:45', value: '19:45' },
  { label: '20:00', value: '20:00' },
  { label: '20:15', value: '20:15' },
  { label: '20:30', value: '20:30' },
  { label: '20:45', value: '20:45' },
  { label: '21:00', value: '21:00' },
  { label: '21:15', value: '21:15' },
  { label: '21:30', value: '21:30' },
  { label: '21:45', value: '21:45' },
  { label: '22:00', value: '22:00' },
  { label: '22:15', value: '22:15' },
  { label: '22:30', value: '22:30' },
  { label: '22:45', value: '22:45' },
  { label: '23:00', value: '23:00' },
  { label: '23:15', value: '23:15' },
  { label: '23:30', value: '23:30' },
  { label: '23:45', value: '23:45' },
];

export const SCHEDULE_TIME_INTERVALS = {
  gap1: {
    name: 'gap1',
    defaultValue: '8:00',
    intervals: SCHEDULE_DEFAULT_TIMES,
  },
  gap2: {
    name: 'gap2',
    defaultValue: '12:00',
    intervals: SCHEDULE_DEFAULT_TIMES,
  },
  gap3: {
    name: 'gap3',
    defaultValue: '14:00',
    intervals: SCHEDULE_DEFAULT_TIMES,
  },
  gap4: {
    name: 'gap4',
    defaultValue: '17:00',
    intervals: SCHEDULE_DEFAULT_TIMES,
  },
};

export const DEFAULT_SCHEDULE_INTERVALS_EMPTY = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

export const SCHEDULE_DAY_FULLTEXT = {
  mon: MONDAY,
  tue: TUESDAY,
  wed: WEDNESDAY,
  thu: THURSDAY,
  fri: FRIDAY,
  sat: SATURDAY,
  sun: SUNDAY,
};

export const TIME_FORMAT = 'H:mm';
