import React, { useCallback } from 'react';
import { Icon } from 'antd';
import clsx from 'clsx';
import { TableAction } from 'components/ui/TableAction';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { useInvoiceActionsContext } from 'contexts/InvoiceTableActionsContext';
import { useDispatch, useSelector } from 'react-redux';
import { downloadInvoiceRequest } from 'modules/invoiceSearch/actions';
import { selectInvoicesDownloadingList } from 'modules/invoiceSearch/selectors';

const {
  TOOLTIP: { DOWNLOAD },
} = INVOICE_SEARCH_TRANSLATIONS;

export const DownloadAction = () => {
  const dispatch = useDispatch();

  const { documentId, isHidden, hidingComment } = useInvoiceActionsContext();
  const isLoading = useSelector(selectInvoicesDownloadingList).includes(
    documentId
  );

  const handleDownload = useCallback(() => {
    if (isHidden) {
      return;
    }

    dispatch(downloadInvoiceRequest({ documentId }));
  }, [dispatch, documentId, isHidden]);

  return (
    <TableAction
      tooltipProps={{
        title: isHidden ? hidingComment : DOWNLOAD,
        overlayClassName: !isHidden ? Styles.tooltipDownload : '',
      }}
      isLoading={isLoading}
      displayComponent={
        <Icon
          type="download"
          className={clsx(Styles.Icon, {
            [Styles.disabled]: isHidden,
          })}
          onClick={handleDownload}
        />
      }
    />
  );
};
