import { createSelector } from 'reselect';
import { mapToArray } from '../mappers';

export const selectUploadStatus = (state) =>
  state.getIn(['uploadDocuments', 'uploadStatus']);

const selectUploadHistory = createSelector(
  (state) => state.getIn(['uploadDocuments', 'uploadHistory', 'data']),
  (values) => (values ? mapToArray(values.toJS()) : [])
);

export const selectUploadHistoryIds = createSelector(
  selectUploadHistory,
  (history) => history.map(({ id }) => id)
);

export const selectUploadedHistoryLength = createSelector(
  selectUploadHistory,
  (history) => history.length || null
);

export const selectUploadHistoryTableData = createSelector(
  selectUploadHistory,
  (uploadHistoryArray) =>
    uploadHistoryArray.map(
      ({ createdAt, name, size, id, key, clientEmail }) => ({
        date: createdAt,
        fileName: name,
        size,
        key: id,
        isDownloadable: !!key,
        clientEmail,
      })
    )
);

export const selectUploadHistoryMeta = createSelector(
  (state) => state.getIn(['uploadDocuments', 'uploadHistory', 'meta']),
  (meta) => (meta ? meta.toJS() : {})
);

export const selectUploadHistoryDownloadedBool = createSelector(
  [selectUploadHistoryMeta, selectUploadedHistoryLength],
  ({ total, limit, page }, downloadedAmount) =>
    total <= downloadedAmount || total - limit * page < 0
);

export const selectDownloadingDocumentsList = (state) =>
  state.getIn(['uploadDocuments', 'downloadingDocumentsList']);
