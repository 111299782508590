import { fromJS } from 'immutable';
import { GET_BOOKMARKS_SUCCES } from 'modules/usefulLinks/actions';

const initialState = fromJS({
  usefulLinks: [],
});

export const bookmarksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKMARKS_SUCCES: {
      const { links } = action.payload;
      return state.setIn(['usefulLinks'], fromJS(links));
    }
    default: {
      return state;
    }
  }
};
