import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import clsx from 'clsx';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import { useDispatch, useSelector } from 'react-redux';
import { useInvoiceActionsContext } from 'contexts/InvoiceTableActionsContext';
import { openInvoiceRequest } from 'modules/invoiceSearch/actions';
import { checkIfTiff } from 'modules/invoiceSearch/helpers';
import {
  selectInvoicesOpeningList,
  selectFile,
} from 'modules/invoiceSearch/selectors';
import Styles from 'components/InvoiceSearch/SearchResultsTable/SearchResultsTable.module.css';
import { TableAction } from 'components/ui/TableAction';

const propTypes = {
  filePath: PropTypes.string,
};

const {
  TOOLTIP: { PREVIEW, NO_PREVIEW },
} = INVOICE_SEARCH_TRANSLATIONS;

export const OpenInNewTabAction = ({ filePath }) => {
  const dispatch = useDispatch();

  const { documentId: currentFileId, fileData } = useSelector(selectFile);

  const { documentId, isHidden, hidingComment } = useInvoiceActionsContext();

  const isLoading = useSelector(selectInvoicesOpeningList).includes(documentId);

  const isTiff = checkIfTiff(filePath);

  const handleDownload = useCallback(() => {
    if (isTiff || isHidden) {
      return;
    }

    dispatch(openInvoiceRequest({ documentId }));
  }, [dispatch, documentId, isTiff, isHidden]);

  const tooltipProps = isTiff
    ? {
        title: hidingComment || NO_PREVIEW,
        overlayClassName: !isHidden ? Styles.tooltipExport : '',
        arrowPointAtCenter: true,
      }
    : {
        title: hidingComment || PREVIEW,
        overlayClassName: !isHidden ? Styles.tooltipExport : '',
      };

  return (
    <TableAction
      tooltipProps={tooltipProps}
      isLoading={isLoading}
      displayComponent={
        fileData && currentFileId === documentId ? (
          <a
            href={window.URL.createObjectURL(fileData)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              type="export"
              className={clsx(Styles.Icon, Styles.animation, {
                [Styles.disabled]: isTiff || isHidden,
              })}
            />
          </a>
        ) : (
          <Icon
            type="export"
            className={clsx(Styles.Icon, {
              [Styles.disabled]: isTiff || isHidden,
            })}
            onClick={handleDownload}
          />
        )
      }
    />
  );
};

OpenInNewTabAction.propTypes = propTypes;
