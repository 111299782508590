import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  ADD_COMMENT_REQUEST,
  addCommentError,
  addCommentSuccess,
  CHANGE_COMMENT_REQUEST,
  changeCommentSuccess,
  DELETE_COMMENT_REQUEST,
  deleteCommentSuccess,
  GET_ALL_COMMENTS_REQUEST,
  getAllCommentsError,
  getAllCommentsSuccess,
} from 'modules/commentsModal/actions';
import {
  addDashboardComment,
  deleteDashboardComment,
  editDashboardComment,
  getAllComments,
} from 'modules/commentsModal/services';
import {
  formatStringDateWithDotsToDate,
  mapCommentsToRedux,
} from 'modules/commentsModal/helpers';

function* addCommentWorker({ payload }) {
  try {
    const { exercice, ...comment } = payload;
    const { data: newComment } = yield call(addDashboardComment, {
      exercice,
      comment,
    });
    yield put(
      addCommentSuccess({
        ...newComment,
        date: formatStringDateWithDotsToDate(newComment.date),
      })
    );
  } catch (error) {
    yield put(addCommentError());
    console.warn(error);
  }
}

function* changeCommentWorker({ payload: { text, commentId } }) {
  try {
    const { data: editedComment } = yield call(editDashboardComment, {
      commentId,
      commentText: String(text),
    });
    yield put(
      changeCommentSuccess({
        text: editedComment.text,
        commentId: editedComment.id,
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

function* deleteCommentWorker({ payload: { commentId } }) {
  try {
    yield call(deleteDashboardComment, commentId);
    yield put(deleteCommentSuccess({ commentId }));
  } catch (error) {
    console.warn(error);
  }
}
function* getAllCommentsWorker({ payload: { currentExercice } }) {
  try {
    if (currentExercice) {
      yield delay(500);
      const { data } = yield call(getAllComments, {
        exercice: currentExercice,
      });
      const comments = mapCommentsToRedux(data);
      yield put(getAllCommentsSuccess({ comments }));
    } else {
      throw Error('currentExercice not found');
    }
  } catch (e) {
    yield put(getAllCommentsError({ error: e }));
  }
}

export function* commentsModalMainSaga() {
  yield takeLatest(ADD_COMMENT_REQUEST, addCommentWorker);
  yield takeLatest(CHANGE_COMMENT_REQUEST, changeCommentWorker);
  yield takeLatest(DELETE_COMMENT_REQUEST, deleteCommentWorker);
  yield takeLatest(GET_ALL_COMMENTS_REQUEST, getAllCommentsWorker);
}
