import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Input, Table } from 'antd';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import { createColumns } from 'helpers/NetPromoterScoreModal';
import { voteNpsRequest } from 'modules/NPSModal/actions';
import { NPS_MODAL_TRANSLATIONS } from 'const/translations';
import Styles from './NetPromoterScoreModal.module.css';
import { Button } from '../ui/Button';

const {
  COMMENT,
  MESSAGE_PLACEHOLDER,
  CANCEL_BUTTON,
  OK_BUTTON,
  ERROR_REQUIRED_SCORE,
} = NPS_MODAL_TRANSLATIONS;

const propTypes = {
  handleClose: PropTypes.func,
  id: PropTypes.string,
};

export const NetPromoterScoreForm = ({ handleClose, id }) => {
  const dispatch = useDispatch();
  const [currentCell, setCurrentCellId] = useState(-1);

  const dataSource = [
    {
      key: '1',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
    },
  ];

  const columns = createColumns(currentCell);

  const initialValues = {
    score: -1,
    comment: '',
    id,
  };

  const handleOnSubmit = useCallback(
    (valuesToSubmit) => {
      dispatch(voteNpsRequest(valuesToSubmit));
      handleClose();
    },
    [dispatch, handleClose]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleOnSubmit,
    validate: (valuesToValidate) => {
      const errorsNew = {};
      if (valuesToValidate.score === -1) {
        errorsNew.score = ERROR_REQUIRED_SCORE;
      }
      return errorsNew;
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleOnRow = useCallback(
    () => ({
      onClick: (event) => {
        formik.setFieldValue('score', event.target.innerText);
        setCurrentCellId(event.target.innerText);
      },
    }),
    [formik]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Table
        className={Styles.table}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ['none'],
        }}
        onRow={handleOnRow}
      />
      <div className={clsx(Styles.textDefault, Styles.textQuestion)}>
        {COMMENT}
      </div>
      <Input.TextArea
        onChange={formik.handleChange}
        value={formik.values.comment}
        name="comment"
        placeholder={MESSAGE_PLACEHOLDER}
        maxLength={1000}
        className={MainStyles.Message}
        rows={6}
      />
      {formik.errors.score && (
        <span className={MainStyles.textError}>{formik.errors.score}</span>
      )}
      <div
        className={clsx(MainStyles.buttonWrapper, Styles.modalButtonWrapper)}
      >
        <Button
          onClick={handleClose}
          className={clsx(Styles.cancelButton, MainStyles.button)}
          variant="secondary"
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          variant="success"
          type="submit"
          className={MainStyles.button}
          disabled={!!formik.errors.score}
        >
          {OK_BUTTON}
        </Button>
      </div>
    </form>
  );
};

NetPromoterScoreForm.propTypes = propTypes;
