import { createSelector } from 'reselect';
import { selectCurrentCompanyFirstFiscalMonth } from 'modules/turnoverPage/selectors';
import {
  formatCommentsToMonths,
  formatMonthsCommentsToYear,
} from 'modules/commentsModal/helpers';

export const selectCommentsStatus = (state) =>
  state.getIn(['commentsModal', 'status']);

const selectAllComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) => (comments ? comments.toJS() : [])
);

export const selectSubmitCommentFormStatus = (state) =>
  state.getIn(['commentsModal', 'submitFormStatus']);

export const selectIsCommentsModalOpen = (state) =>
  state.getIn(['commentsModal', 'isModalOpen']);

export const selectsDayForSort = (state) =>
  state.getIn(['commentsModal', 'dayIndexForSort']);

export const selectMonthForSort = (state) =>
  state.getIn(['commentsModal', 'monthIndexForSort']);

export const selectDateModeForSort = (state) =>
  state.getIn(['commentsModal', 'dateModeForSort']);

export const selectChartTypeForSort = (state) =>
  state.getIn(['commentsModal', 'chartType']);

export const selectCommentsForModal = createSelector(
  selectAllComments,
  selectsDayForSort,
  selectMonthForSort,
  selectDateModeForSort,
  selectChartTypeForSort,
  (comments, sortDay, sortMonth, sortMode, chartType) =>
    comments.filter(({ date, type }) => {
      const parsedDate = new Date(Date.parse(date));
      const day = parsedDate.getDate();
      const month = parsedDate.getMonth();
      if (sortMode === 'month') {
        return month === sortMonth && type === chartType;
      }
      return day === sortDay && month === sortMonth && type === chartType;
    })
);

// ---- selectors for chart comments
// treasury
const selectTreasuryComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) =>
    comments
      ? comments.toJS().filter((comment) => comment.type === 'treasury')
      : []
);

export const selectTreasuryCommentsForChart = createSelector(
  selectTreasuryComments,
  (treasuryComments) => formatCommentsToMonths(treasuryComments)
);

export const selectTreasuryCommentsForYearChart = createSelector(
  selectTreasuryCommentsForChart,
  selectCurrentCompanyFirstFiscalMonth,
  (monthsComments, firstMonth) =>
    formatMonthsCommentsToYear(monthsComments, firstMonth)
);

// turnover
const selectTurnoverComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) =>
    comments
      ? comments.toJS().filter((comment) => comment.type === 'turnover')
      : []
);

export const selectTurnoverCommentsForChart = createSelector(
  selectTurnoverComments,
  (turnoverComments) => formatCommentsToMonths(turnoverComments)
);

export const selectTurnoverCommentsForYearChart = createSelector(
  selectTurnoverCommentsForChart,
  selectCurrentCompanyFirstFiscalMonth,
  (monthsComments, firstMonth) =>
    formatMonthsCommentsToYear(monthsComments, firstMonth)
);

// debtors
const selectDebtorsComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) =>
    comments
      ? comments.toJS().filter((comment) => comment.type === 'debtors')
      : []
);

const selectDebtorsCommentsForChart = createSelector(
  selectDebtorsComments,
  (turnoverComments) => formatCommentsToMonths(turnoverComments)
);

export const selectDebtorsCommentsForYearChart = createSelector(
  selectDebtorsCommentsForChart,
  selectCurrentCompanyFirstFiscalMonth,
  (monthsComments, firstMonth) =>
    formatMonthsCommentsToYear(monthsComments, firstMonth)
);

// creditors
const selectCreditorsComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) =>
    comments
      ? comments.toJS().filter((comment) => comment.type === 'creditors')
      : []
);

const selectCreditorsCommentsForChart = createSelector(
  selectCreditorsComments,
  (turnoverComments) => formatCommentsToMonths(turnoverComments)
);

export const selectCreditorsCommentsForYearChart = createSelector(
  selectCreditorsCommentsForChart,
  selectCurrentCompanyFirstFiscalMonth,
  (monthsComments, firstMonth) =>
    formatMonthsCommentsToYear(monthsComments, firstMonth)
);

// hr
const selectHRComments = createSelector(
  (state) => state.getIn(['commentsModal', 'comments']),
  (comments) =>
    comments ? comments.toJS().filter((comment) => comment.type === 'hr') : []
);

const selectHRCommentsForChart = createSelector(
  selectHRComments,
  (HRComments) => formatCommentsToMonths(HRComments)
);

export const selectHRCommentsForYearChart = createSelector(
  selectHRCommentsForChart,
  selectCurrentCompanyFirstFiscalMonth,
  (monthsComments, firstMonth) =>
    formatMonthsCommentsToYear(monthsComments, firstMonth)
);
