/* eslint
  import/no-unused-modules: ['off']
*/

import { getType, getActionCreator } from 'utils';
const prefix = '@@app/authentication';

/**
 * Log in
 */
export const LOGIN_REQUEST = getType(prefix, 'LOGIN_REQUEST');
export const loginRequest = getActionCreator(LOGIN_REQUEST);

export const LOGIN_SUCCESS = getType(prefix, 'LOGIN_SUCCESS');
export const loginSuccess = getActionCreator(LOGIN_SUCCESS);

export const LOGIN_ERROR = getType(prefix, 'LOGIN_ERROR');
export const loginError = getActionCreator(LOGIN_ERROR);

/**
 * Password change
 */
export const PASSWORD_CHANGE_REQUEST = getType(
  prefix,
  'PASSWORD_CHANGE_REQUEST'
);
export const passwordChangeRequest = getActionCreator(PASSWORD_CHANGE_REQUEST);

export const PASSWORD_CHANGE_SUCCESS = getType(
  prefix,
  'PASSWORD_CHANGE_SUCCESS'
);
export const passwordChangeSuccess = getActionCreator(PASSWORD_CHANGE_SUCCESS);

export const PASSWORD_CHANGE_ERROR = getType(prefix, 'PASSWORD_CHANGE_ERROR');
export const passwordChangeError = getActionCreator(PASSWORD_CHANGE_ERROR);

/**
 * Temporary password change
 */
export const TEMPORARY_PASSWORD_CHANGE_REQUEST = getType(
  prefix,
  'TEMPORARY_PASSWORD_CHANGE_REQUEST'
);
export const temporaryPasswordChangeRequest = getActionCreator(
  TEMPORARY_PASSWORD_CHANGE_REQUEST
);

export const TEMPORARY_PASSWORD_CHANGE_SUCCESS = getType(
  prefix,
  'TEMPORARY_PASSWORD_CHANGE_SUCCESS'
);
export const temporaryPasswordChangeSuccess = getActionCreator(
  TEMPORARY_PASSWORD_CHANGE_SUCCESS
);

export const TEMPORARY_PASSWORD_CHANGE_ERROR = getType(
  prefix,
  'TEMPORARY_PASSWORD_CHANGE_ERROR'
);
export const temporaryPasswordChangeError = getActionCreator(
  TEMPORARY_PASSWORD_CHANGE_ERROR
);

/**
 * Password recovery
 */
export const PASSWORD_RECOVERY_REQUEST = getType(
  prefix,
  'PASSWORD_RECOVERY_REQUEST'
);
export const passwordRecoveryRequest = getActionCreator(
  PASSWORD_RECOVERY_REQUEST
);

export const PASSWORD_RECOVERY_SUCCESS = getType(
  prefix,
  'PASSWORD_RECOVERY_SUCCESS'
);
export const passwordRecoverySuccess = getActionCreator(
  PASSWORD_RECOVERY_SUCCESS
);

export const PASSWORD_RECOVERY_ERROR = getType(
  prefix,
  'PASSWORD_RECOVERY_ERROR'
);
export const passwordRecoveryError = getActionCreator(PASSWORD_RECOVERY_ERROR);
