import { request } from 'utils/request';

export const fetchExcoInfoModalData = ({ companyId }) =>
  request.get(`/companies/${companyId}/pending-changes`);

export const finalizeExcoData = ({ month, year, comment, companyId }) =>
  request.post(`/companies/${companyId}/pending-changes`, {
    month,
    year,
    comment,
  });
