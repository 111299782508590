import { LANDING_ACTIONS } from 'const/landingPage';

export const LANDING_PAGE_TRANSLATIONS = {
  ADVANTAGES: {
    TITLES: {
      SIMPLE: 'de simplicité',
      SECURE: 'de sécurité',
      FAST: 'de rapidité',
      CASH: 'de trésorerie',
    },
    DESCRIPTIONS: {
      SALES_SIMPLE:
        'Outil facile d’utilisation Tout en 1. Gain de temps sur la partie administrative',
      SALES_CASH:
        'Favoriser la transformation des devis, facturer + vite, relancer les clients à temps',
      SALES_SECURE:
        'Conformité fiscale. Données clients centralisées et structurées',
      EXPENSES_FAST: `Validation « bon à payer » et Paiement des factures en 1 clic\n
        Paiement immédiat ou à échéance\n
        'Choix du compte en banque payeur`,
      EXPENSES_SIMPLE:
        'Traçabilité des bons à payer. Réconciliation automatique entre factures et paiements. Visualisation de vos dépenses en temps réel.',
      EXPENSES_SECURE:
        'IBAN vérifiés pour réduire la fraude. Transactions sécurisées sans vous connecter à votre compte bancaire',
    },
    MY_EXCO_TITLES: {
      INVOICE_SEARCH: 'Moteur de recherche de factures',
      HR_INDICATORS: 'Indicateurs de gestion et RH en temps réel',
      TREASURY: 'Suivi de trésorerie',
    },
    MY_EXCO_POINTS: {
      INVOICE_ANALYZE: "Analysez vos factures d'achats et de ventes",
      INVOICE_COMPARE: 'Comparez et pointez les prix d’achat',
      INVOICE_TRACK: 'Suivez les volumes d’achat',
      HR_TURNOVER: 'Chiffre d’affaires réalisé et potentiel',
      HR_SALARY: 'Coût salarial et heures travaillées',
      HR_HOURLY: 'Chiffre généré pour 1h travaillée',
      HR_PERCENT: '% Charges de personnel /chiffre d’affaires',
      TREASURY_CASH: 'Trésorerie disponible',
      TREASURY_OWED: 'Ce que vous devez à vos fournisseurs et prestataires',
      TREASURY_CREDITED: 'Ce que les clients vous doivent',
    },
    WAIBI_POINTS_TITLE:
      'Grâce à notre offre "Mon tableau de bord", vous pouvez :',
    WAIBI_POINTS: {
      ONLINE_CONSULTATION: `Consulter votre tableau de bord en ligne afin de suivre l’avancement de vos objectifs`,
      COMMENTS: `Accéder à vos commentaires, comparer le réalisé au budget, à N-1`,
      RESULTS: `Avoir une approche du résultat de fin d’année de manière anticipée`,
      PERSONALIZED_SUPPORT: `Bénéficier d'un accompagnement personnalisé`,
      REACTIVE_MANAGEMENT: `Piloter votre entreprise de manière plus sereine et réactive`,
    },
  },
  SALES: {
    SUBTITLE: 'Pilotez votre chiffre d’affaires !',
    DESCRIPTION:
      'Après avoir élaboré vos devis, facturez-les en 1 clic. Pilotez votre chiffre d’affaires et gérez votre trésorerie par le suivi des encaissements clients et la relance de vos impayés.',
    DISCLAIMER: 'Ce service est accessible pour les abonnés au Bouquet My Exco',
  },
  WAIBI: {
    DESCRIPTION: `Vous souhaitez aller plus loin dans la gestion de votre entreprise ?`,
    DISCLAIMER: null,
    CONSULTANT_CONTACT_SUCCESS:
      'Votre demande a bien été prise en compte, nous vous recontacterons dans les meilleurs délais',
  },
  EXPENSES: {
    SUBTITLE: 'Payez simplement vos factures fournisseurs !',
    DESCRIPTION:
      'Vous perdez des heures à saisir vos virements à la main ? Découvrez le paiement sécurisé de vos factures en un clic.',
    DISCLAIMER: 'Ce service est accessible pour les abonnés au Bouquet My Exco',
  },
  MY_EXCO: {
    SUBTITLE: 'Ne naviguez plus à vue, pilotez !',
    DESCRIPTION:
      'La plateforme digitale qui centralise les données et solutions de gestion pour rendre simple et efficace le pilotage de votre entreprise au quotidien.',
  },
  HELP: {
    SUBTITLE: 'Besoin d’un coup de pouce ?',
    DESCRIPTION:
      'Retrouvez ci-dessous nos foires aux questions, nos vidéos tuto. Et au besoin prenez rendez-vous avec un de nos coach digital !\n',
  },
  SUBSCRIBE: 'Souscrire',
  MAKE_APPOINTMENT: 'Prendre RDV',
  TAX: 'HT / Mois',
  SALES_COST_BLOCK_TEXT: 'Gardez le contrôle de vos ventes !',
  EXPENSES_COST_BLOCK_TEXT: 'Payez simplement vos factures fournisseurs !',
  MY_EXCO_COST_BLOCK_TEXT: 'Ne naviguez plus à vue, pilotez ',
  WAIBI_COST_BLOCK_TEXT: 'Plus d’informations',
  WAIBI_COST: 'Prix à définir\nselon vos besoins',
  BUTTONS_TEXT: {
    [LANDING_ACTIONS.ADD_TO_CART]: 'Ajouter au panier',
    [LANDING_ACTIONS.VISIT_CART_PAGE]: 'Voir mon panier',
    [LANDING_ACTIONS.CONTACT_CONSULTANT]: 'Etre contacté par mon conseiller',
  },
  AVAILABLE_DAY_AND_NIGHT:
    'Tout le pilotage et l’information au même endroit ! Disponible',
  DAY_AND_NIGHT_NUMBERS: '24/7 !',
  BACK_TO_STORE: 'Store',
  SUBSCRIBE_SUCCESS_TEXT: 'Merci ! Le module est ajouté à votre panier ',
  TOOLTIP_RETURN_TO_STORE: 'Retourner au Store',
};
