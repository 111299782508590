import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserCurrentCompany,
  selectUserCurrentCompanyMaxPeriod,
  selectUserCurrentCompanyReliability,
  selectUserPreviewFinancials,
} from 'modules/loggedUserInfo/selectors';
import clsx from 'clsx';
import { MonthSelector } from 'components/ui/MonthSelector';
import { YearMonthSwitch } from 'components/ui/YearMonthSwitch';
import { ViewSwitch } from 'components/ui/ViewSwitch';
import { SectionTitle } from 'components/Section/SectionTitle';
import MainStyles from 'assets/styles/main.module.css';
import wallet from 'assets/icons/PreviewCards/wallet.svg';
import arrowUp from 'assets/icons/PreviewCards/arrow-up.svg';
import { CurrentFiscalYear } from 'components/ui/MonthSelector/CurrentFiscalYear';
import { ShortPreviewCard } from 'components/ShortPreviewCard';
import { ReliabilityIndexCard } from 'components/ReliabilityIndexCard';
import { SHORT_PREVIEW_CARD_TYPES } from 'const/dashboardCards';
import { DashboardsSection } from 'components/DashboardsSection';
import {
  APP_MODULES,
  EMPTY_FUNCTION,
  ROUTES,
  SCREEN_WIDTH_BREAKPOINT_MEDIA,
} from 'const';
import { useChartFilters } from 'hooks/useChartFilters';
import {
  selectCurrentCompanyFirstFiscalMonth,
  selectTurnoverLastUpdatedDate,
} from 'modules/turnoverPage/selectors';
import {
  selectTreasuryLastUpdatedDate,
  selectTreasuryUserAccounts,
} from 'modules/treasuryPage/selectors';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { LastUpdate } from 'components/LastUpdate';
import { ChartSelect } from 'components/ui/ChartSelect';
import { useSimpleMedia } from 'hooks/useMedia';
import { selectAreIndicatorsLoading } from 'modules/Indicators/selectors';
import { Spinner } from 'components/Spinner';
import { getTurnoverDataRequest } from 'modules/turnoverPage/actions';
import {
  getTreasuryDataRequest,
  getTreasurySingleAccountDataRequest,
} from 'modules/treasuryPage/actions';
import { getAllCommentsRequest } from 'modules/commentsModal/actions';
import { getHRDataError, getHRDataRequest } from 'modules/HRPage/actions';
import { HR_PAGE_ERRORS } from 'const/translations/HRPage';
import Styles from './HomePage.module.css';

const { MY_INDICATORS_SECTION_TITLE } = HOMEPAGE_FORM_TRANSLATIONS;
const { NO_EVP_MODULE } = HR_PAGE_ERRORS;
const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { BAR } = CHARTS_VIEW_SWITCH_TYPES;
const { BALANCE, CREDITOR, DEBTOR } = SHORT_PREVIEW_CARD_TYPES;

const { DASHBOARD_FIN, EVP } = APP_MODULES;

const propTypes = {
  userModules: PropTypes.array,
  currentExercice: PropTypes.string,
  currentYearFromExercice: PropTypes.string,
  currentCompanyId: PropTypes.string,
};

export const IndicatorsSection = ({
  userModules,
  currentExercice,
  currentYearFromExercice,
  currentCompanyId,
}) => {
  const dispatch = useDispatch();

  const firstMonth = useSelector(selectCurrentCompanyFirstFiscalMonth);
  const isMobileView = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

  const {
    setActiveDateViewOption,
    activeDateViewOption,
    setChartViewActiveMode,
    chartViewActiveMode,
    activeMonthName,
    setActiveMonthName,
    setActiveYear,
    activeYear,
  } = useChartFilters(YEAR, BAR, currentYearFromExercice);

  const { debtors: debtorsAmount, creditors: creditorsAmount } = useSelector(
    selectUserPreviewFinancials
  );

  const yearSelectCallback = useCallback(
    (value) => setActiveDateViewOption(value),
    [setActiveDateViewOption]
  );

  const reliabilityIndex = useSelector(selectUserCurrentCompanyReliability);

  const userTreasuryAccounts = useSelector(selectTreasuryUserAccounts);
  const userTreasuryBalance = useMemo(
    () =>
      userTreasuryAccounts.reduce((sum, { value }) => {
        if (value || value === 0) {
          return Number(sum) ? sum + Number(value) : Number(value);
        }
        return sum;
      }, 'N/A'),
    [userTreasuryAccounts]
  );
  const treasuryUpdateDate = useSelector(selectTreasuryLastUpdatedDate);
  const turnoverUpdateDate = useSelector(selectTurnoverLastUpdatedDate);

  useEffect(() => {
    dispatch(getTurnoverDataRequest({ currentExercice, currentCompanyId }));
    dispatch(getAllCommentsRequest({ currentExercice }));
  }, [dispatch, currentCompanyId, currentExercice]);

  const treasuryAccounts = useSelector(selectTreasuryUserAccounts);
  const [treasuryActiveTabId, setTreasuryActiveTabId] = useState('total');
  useEffect(() => {
    setTreasuryActiveTabId('total');
  }, [treasuryAccounts, setTreasuryActiveTabId]);
  useEffect(
    () => {
      if (treasuryActiveTabId === 'total') {
        dispatch(getTreasuryDataRequest({ singleAccount: true }));
      } else {
        dispatch(
          getTreasurySingleAccountDataRequest({
            accountName: treasuryActiveTabId,
          })
        );
      }
    },
    // `currentCompanyId` dependency is essential for treasury data to update properly;
    [dispatch, treasuryActiveTabId, currentExercice, currentCompanyId]
  );
  const maxPeriod = useSelector(selectUserCurrentCompanyMaxPeriod);
  const { id: companyId } = useSelector(selectUserCurrentCompany);
  useEffect(() => {
    if (userModules.includes(EVP)) {
      dispatch(getHRDataRequest({ companyId, currentExercice, maxPeriod }));
      return;
    }
    dispatch(getHRDataError({ error: { message: NO_EVP_MODULE } }));
  }, [companyId, currentExercice, maxPeriod, dispatch, userModules]);

  const areIndicatorsLoading = useSelector(selectAreIndicatorsLoading);

  const [indicatorsLoadedOnce, setIndicatorsLoadedOnce] = useState(false);

  useEffect(() => {
    if (!areIndicatorsLoading) {
      setIndicatorsLoadedOnce(true);
    }
  }, [areIndicatorsLoading]);

  const activeOptionViewSwitch = indicatorsLoadedOnce
    ? chartViewActiveMode
    : null;
  const setActiveOptionViewSwitch = indicatorsLoadedOnce
    ? setChartViewActiveMode
    : EMPTY_FUNCTION;

  return (
    <>
      <div className={clsx(Styles.dashboardHeader, MainStyles.container)}>
        <div className={Styles.titleAndMonthYearSwitch}>
          <SectionTitle
            title={MY_INDICATORS_SECTION_TITLE}
            className={Styles.dashboardTitle}
          />
          {indicatorsLoadedOnce && (
            <YearMonthSwitch
              activeOption={activeDateViewOption}
              setActiveOption={setActiveDateViewOption}
              className={Styles.monthYearSwitch}
            />
          )}
          <ChartSelect
            activeValue={activeDateViewOption}
            onChange={yearSelectCallback}
            className={Styles.yearMonthSelector}
          />
        </div>
        <div className={Styles.chartFilters}>
          {activeDateViewOption === YEAR ? (
            <CurrentFiscalYear
              currentYear={String(currentYearFromExercice)}
              className={clsx(Styles.monthSelector)}
            />
          ) : (
            <MonthSelector
              firstMonth={firstMonth}
              activeMonthName={activeMonthName}
              setActiveMonthName={setActiveMonthName}
              activeYear={activeYear}
              setActiveYear={setActiveYear}
              className={Styles.monthSelector}
              noLabel={isMobileView}
            />
          )}
          <ViewSwitch
            activeOption={activeOptionViewSwitch}
            setActiveOption={setActiveOptionViewSwitch}
          />
        </div>
      </div>
      {!indicatorsLoadedOnce ? (
        <Spinner size="large" />
      ) : (
        <>
          {userModules.includes(DASHBOARD_FIN) && (
            <div className={clsx(Styles.cardsLine, MainStyles.container)}>
              <ShortPreviewCard
                url={ROUTES.TREASURY}
                type={BALANCE}
                amount={userTreasuryBalance}
                iconSrc={wallet}
                updateDate={treasuryUpdateDate}
                className={Styles.dashboardCard}
              />
              <ShortPreviewCard
                url={ROUTES.DEBTORS}
                type={DEBTOR}
                amount={debtorsAmount}
                iconSrc={arrowUp}
                className={Styles.dashboardCard}
              />
              <ShortPreviewCard
                url={ROUTES.CREDITORS}
                type={CREDITOR}
                amount={creditorsAmount}
                iconSrc={arrowUp}
                className={Styles.dashboardCard}
              />
              <ReliabilityIndexCard
                index={reliabilityIndex || 'N/A'}
                className={Styles.dashboardCard}
              />
            </div>
          )}
          <DashboardsSection
            firstMonth={firstMonth}
            activeDateViewOption={activeDateViewOption}
            activeYear={activeYear}
            activeMonthName={activeMonthName}
            chartViewActiveMode={chartViewActiveMode}
            userModules={userModules}
            currentCompanyId={currentCompanyId}
            currentExercice={currentExercice}
            treasuryAccounts={treasuryAccounts}
            treasuryActiveTabId={treasuryActiveTabId}
            setTreasuryActiveTabId={setTreasuryActiveTabId}
          />
          <LastUpdate
            updateDate={turnoverUpdateDate}
            showCondition={Boolean(currentExercice)}
            className={Styles.updateDate}
          />
        </>
      )}
    </>
  );
};

IndicatorsSection.propTypes = propTypes;
