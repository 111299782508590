import { MONTHS } from 'const/ui';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { SHORT_PREVIEW_CARD_TYPES } from 'const/dashboardCards';

export const getStringUpdateDate = (date, addYear = false) => {
  const parsedDate = new Date(Date.parse(date));
  const month = MONTHS[parsedDate.getMonth()];
  const monthName =
    month.label.length < 7
      ? month.label.toLowerCase()
      : month.shortLabel.toLowerCase();
  const day = String(parsedDate.getDate()).padStart(2, '0');
  const year = parsedDate.getFullYear();
  return `${day} ${monthName}${addYear ? ` ${year}` : ''}`;
};

const { SHORT_PREVIEW_CARDS_DESCRIPTIONS } = HOMEPAGE_FORM_TRANSLATIONS;

export const getPreviewCardDescription = (type, updateDate) => {
  if (type === SHORT_PREVIEW_CARD_TYPES.BALANCE) {
    return SHORT_PREVIEW_CARDS_DESCRIPTIONS[type].replace(
      '{{DATE}}',
      updateDate
    );
  }
  return SHORT_PREVIEW_CARDS_DESCRIPTIONS[type];
};

/**
 * Splits number into triple-digit parts.
 * @example getProperLookingAmount(12345678.4231) === 12,345,678.42;
 * @example getProperLookingAmount(78.00) === 78;
 * @example getProperLookingAmount(3248.1) === 3,248.1;
 * @example getProperLookingAmount(12) === 12;
 *
 * @param {number} amount - The number to be processed.
 * @returns {string} - The properly formatted `amount` as a string or
 *                     a null if `amount` wasn't a number in the first place.
 */
export const getProperLookingAmount = (amount) => {
  if (Number.isNaN(amount)) return null;

  return Number(Number(amount).toFixed(2)).toLocaleString();
};
