import { request } from 'utils/request';

export const getEmployees = (companyId) =>
  request.get(`/companies/${companyId}/employees/`);

export const setEmployeeSchedule = (payload) =>
  request.post(
    `/companies/${payload.companyId}/schedules/${payload.scheduleId}`,
    {
      employeeId: payload.employeeId,
    }
  );

export const downloadEmployeeTableXLSX = (payload) => {
  const { companyId, query } = payload;

  return request.get(`/companies/${companyId}/employees/xlsx?${query}`, {
    responseType: 'blob',
  });
};
