export const TERMS_OF_SUBSCRIPTION_TEXT =
  '\titleDate de dernière mise à jour : Décembre 2023\title\n' +
  '\titlePREAMBULE\title\n' +
  'Les présentes Conditions générales constituent, conformément à l’article L 441-1 du code de commerce, le socle unique de la relation commerciale entre d’une part EXCO Fiduciaire du Sud-Ouest ou l’une de ses filiales (ci-après « EXCO » ou « le cabinet d’expertise comptable ») et d’autre part le professionnel Client, chacune individuellement une « Partie » et ensemble les « Parties », applicable à la souscription et à l’utilisation de la plateforme digitale « My Exco » par les Utilisateurs. \n' +
  '\n' +
  "Les présentes Conditions Générales sont expressément agréées et acceptées par le Client et prévalent sur toutes les autres conditions, notamment les Conditions générales d’utilisation de l’Espace My Exco accessibles en permanence depuis ce dernier ainsi que les conditions générales d'achat du Client, lesquelles seront inopposables à EXCO, même si elle en a eu connaissance. L’acceptation par le Client des présentes Conditions Générales est matérialisée par le cochage de la case attenante à la mention « J’accepte les conditions générales d’abonnement et les conditions générales d’utilisation ») lors de souscription des services en ligne.\n" +
  '\n' +
  'Dans le cadre de la réalisation de sa Mission, EXCO apporte à ses Clients des services additionnels et accessoires à la réalisation de sa Mission telle que définie ci-après. Les prestations proposées par EXCO sont des prestations classiques fournies en l’état et conçues pour satisfaire les besoins du plus grand nombre de ses Clients. Dès lors, le Client a eu connaissance des services proposés et du périmètre fonctionnel de l’Espace My Exco ; il en a vérifié son adéquation par rapport à ses propres besoins. L’Espace My Exco comprend le ou les fonctionnalités ci-après décrites, que le Client peut souscrire en tout ou partie, ainsi que l’hébergement y afférent des données, la maintenance de l’application et l’assistance technique :\n' +
  '- Déposer des documents et consulter les documents déposés ou produits par EXCO ;\n' +
  '- Rechercher et consulter une facture d’achat ou de vente ;\n' +
  '- Consulter des indicateurs de gestion ;\n' +
  '- Communiquer les informations utiles à l’embauche d’un nouveau salarié et/ou la réalisation de bulletins de paie et charges sociales associées ;\n' +
  '- Réaliser des devis et factures de vente ;\n' +
  '- Payer des factures d’achat ;\n' +
  '- Consulter sa comptabilité en ligne.\n' +
  '\n' +
  '\bold1° DEFINITIONS\bold\n' +
  '\bold« Abonnement »\bold désigne l’ensemble des services payants souscrits par un Client auprès d’EXCO au travers de la Mission ou d’une souscription en ligne.\n' +
  '\n' +
  '\bold« Bouquet My Exco »\bold désigne les fonctionnalités payantes de base liées à la Mission. \n' +
  '\n' +
  '\bold« Client »\bold\bold \bolddésigne toute personne physique ou morale, publique ou privée, capable juridiquement de contracter et habilitée à engager sa structure, agissant à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, y compris lorsqu’elle agit au nom et pour le compte d’un autre professionnel.\n' +
  '\n' +
  '\bold« Compte »\bold désigne le compte de l’Utilisateur permettant l’accès à l’Espace My Exco.\n' +
  '\n' +
  '\bold« Compte dirigeant »\bold désigne le compte administrateur disposant de la capacité d’accéder à l’Espace My Exco, et de, potentiellement, souscrire des services complémentaires et de gérer les niveaux d’accès des Utilisateurs aux services souscrits et ce, sous l’entière responsabilité du Client, seul habilité à organiser les accès administrateurs, les délégations éventuelles y afférents et les niveaux d’information et de confidentialité requis.\n' +
  '\n' +
  '\bold« Conditions générales »\bold\bold \bolddésigne les présentes conditions générales d’abonnement à l’Espace My Exco.\n' +
  '\n' +
  '\bold« Conditions générales d’utilisation »\bold désigne les conditions générales acceptées par l’Utilisateur lors de l’accès à son Compte. \n' +
  '\n' +
  '\bold« \bold\boldEspace\bold\bold MyExco »\bold\bold \bolddésigne l’outil développé par EXCO aux fins d’échange d’informations dans le cadre des missions de ce dernier portant sur la gestion comptable et/ou sociale conférée par le Client au Groupe Exco et de centralisation des Services. \n' +
  '\n' +
  '\bold« \bold\boldGroupe Exco\bold\bold » \bolddésigne toutes les sociétés contrôlées par EXCO, contrôlant EXCO ou se trouvant sous contrôle commun à ce jour et à venir, au sens de l’article L233-3 du code de commerce\bold.\bold\n' +
  '\n' +
  '\bold« \bold\boldMission »\bold désigne les prestations d’activités comptables, sociales, juridiques, audit et/ou de conseils effectuées par EXCO ou une société du Groupe EXCO pour le Client dont les conditions et modalités sont décrites par une lettre de mission.\n' +
  '\n' +
  '\bold« Partenaires »\bold désigne les structures proposant des Services en marque blanche ou expressément identifiées au sein de l’Espace My Exco pouvant être souscrits par le Client et dont ce dernier sera le cas échéant amené à accepter les conditions contractuelles, lesquelles s’appliqueront à la relation contractuelle entre le Partenaire et le Client.\n' +
  '\n' +
  '\bold«\bold\bold Services \bold\bold»\bold désigne le Bouquet My Exco et les Services complémentaires, à savoir les différentes fonctionnalités proposées directement par EXCO et/ou ses Partenaires auxquelles le Client a souscrit via l’Abonnement tels que notamment décrits en Préambule des présentes Conditions générales.\n' +
  ' \n' +
  '\bold« Services complémentaires »\bold désigne les fonctionnalités supplémentaires proposées par EXCO et/ou ses Partenaires susceptibles d’être souscrites par le Client en sus du Bouquet My Exco à tout moment sur le Store de son Compte dirigeant. \n' +
  '\n' +
  '\n' +
  '\bold« Store »\bold définit l’espace sur le Compte dirigeant au sein duquel le Client est susceptible de souscrire à des Services complémentaires et/ou gérer les droits et accès des Utilisateurs.\n' +
  '\n' +
  '\bold« Utilisateur »\bold désigne la ou les personnes accédant au Compte dûment habilitée(s) par le Client. \n' +
  '\n' +
  '\bold2\bold\bold° \bold\boldSOUSCRIPTION DES SERVICES\bold\n' +
  'La souscription des Services complémentaires est effectuée en ligne exclusivement, sous réserve de l’activation préalable du Compte dirigeant, que ce soit à l’initiative d’EXCO dans le cadre de l’exercice de sa Mission ou sur demande du Client informé du déploiement des Services.\n' +
  'Un courriel d’authentification, afin d’accéder à la souscription des Services sera adressé au Client. La mise en activation du bouton « Je démarre » implique que le Client ait pris connaissance et accepté préalablement les Conditions générales et les Conditions générales d’utilisation et s’entend comme une souscription ferme et définitive au Bouquet My Exco.\n' +
  'Le Client peut à tout moment, sur le Store du Compte dirigeant, gérer ses Services et souscrire des Services complémentaires, lesquels ne peuvent être souscrits qu’en sus du Bouquet My Exco, à l’exclusion de toute adhésion unique aux Services complémentaires. EXCO n’est liée que par les engagements émanent d’une personne dûment habilitée. \n' +
  '\n' +
  '\bold3° \bold\boldTARIFS\bold\n' +
  'Les prix indiqués sur les catalogues ou toute documentation commerciale d’EXCO le sont à titre indicatif et peuvent être modifiés à tout moment et sans préavis. Le service est fourni au tarif en vigueur au jour de la souscription des Services spécifiquement concernés. \n' +
  'Le prix des Services souscrits peut être modifié par EXCO sous réserve de notification par écrit au Client au moins deux (2) mois avant l’entrée en vigueur des nouveaux tarifs. \n' +
  'Les prix sont exprimés en euros et stipulés hors taxes, les taxes étant supportées par le Client. Les taxes appliquées sont celles qui sont prévues par la réglementation en vigueur, et, au cas où celle-ci serait modifiée, les variations de prix qui en résulterait prendraient effet dès le jour de leur mis en application. Le prix toutes taxes comprises est indiqué lors de la souscription des Services, à savoir lors de l’activation du Bouquet My Exco et sur le Store, et sur la facture correspondante. Des rabais, réduction, ristourne ou remises sur le prix hors taxes peuvent être concédées au Client, en une seule fois et un seul lieu ou en fonction de la régularité des Services, dans les conditions déterminées par EXCO dans le cadre de sa relation avec le Client et indiquées sur la facture correspondante. \n' +
  '\n' +
  '\bold4\bold\bold° MODALITES DE PAIEMENT\bold\n' +
  'L’Abonnement sera facturé au Client conformément aux modalités ci-après décrites. \n' +
  'Aussi, par défaut et sauf stipulation contraire expresse convenue entre les Parties, les factures sont émises et payables selon les modalités et par le moyen de paiement choisi par le Client aux fins de règlement des prestations inhérentes à la Mission. A cet effet, l’Abonnement est facturé au Client dans le cadre de la facturation afférente à la réalisation de la Mission par EXCO ou le Groupe Exco selon l’interlocuteur habituel du Client dans le cadre de la réalisation de la Mission.\n' +
  'En cas de rejet de prélèvement par la banque du Client, EXCO facturera des frais de trente (30) euros H.T. par prélèvement rejeté qui seront immédiatement exigibles.\n' +
  'Les factures sont envoyées à l’adresse électronique identifiée par EXCO dans le cadre de sa Mission. A ce titre, EXCO ne saurait se voir reprocher quelque manquement que ce soit en regard de la transmission d’une facture si le Client fournit une adresse erronée.\n' +
  "Tout retard de paiement entraine l’exigibilité immédiate de la totalité des sommes dues, sans mise en demeure, ni autre formalité et expose le Client, en sus des frais exposés pour le recouvrement, à des pénalités de retard dont le taux est égal à trois fois le taux de l'intérêt légal ainsi qu'à une indemnité forfaitaire de quarante (40) euros par facture dans les conditions de l'article L 441-10 du code de commerce, toute dépense supplémentaire nécessitée pour le recouvrement étant également à la charge du Client sur présentation des justificatifs. Les intérêts de retard seront capitalisés annuellement.\n" +
  'Tout paiement par compensation est exclu. En cas de paiement anticipé, aucun escompte n’est accordé. Le Client ne pourra s’accorder d’escompte de sa propre initiative.\n' +
  'Toute contestation d’une facture devra être faite dans un délai de quinze (15) jours dès réception et motivée par lettre recommandée avec accusé de réception ; ladite contestation ne pourra justifier le non-paiement des autres prestations non contestées y compris celles incluses dans la même facture.\n' +
  'Le défaut de paiement d’une facture pourra, après rappel par lettre recommandée avec accusé de réception, entraîner la suspension des services ou, la résiliation des Services conformément aux dispositions de l’article 11 des présentes.\n' +
  '\n' +
  '\bold5\bold\bold° MODALITES DE FOURNITURE DU SERVICE\bold\n' +
  'Le Bouquet My Exco sera immédiatement disponible après l’acceptation des Conditions générales et des Conditions générale d’utilisation en ligne par le Client. Les Services complémentaires le cas échéant souscrits par le Client seront fournis dans les meilleurs délais par EXCO. Si la fourniture des Services n’a pas été effectuée dans un délai de trente (30) jours à compter de la souscription des Services complémentaires, le Client pourra solliciter la restitution des sommes éventuellement engagées pour la souscription des Services complémentaires. \n' +
  '\n' +
  'Le Client indiquera le nombre d’Utilisateurs pour lesquels un Compte sera créé ainsi que les droits d’accès qui leur seront conférés depuis l’onglet Store prévu à cet effet sur le Compte dirigeant. Le Client peut, à tout moment, modifier le nombre d’Utilisateurs ainsi que les droits d’accès de ces derniers sur son Compte dirigeant. Au surplus, le Client s’engage à informer EXCO, par écrit à l’adresse \blinksupport@fsodigital.fr\blink de tout départ d’un Utilisateur de sa structure aux fins de clôture par EXCO du Compte associé. \n' +
  '\n' +
  'Tous les identifiants et mots de passe sont personnels et confidentiels. Le Client est entièrement responsable de l’utilisation et de la préservation des identifiants et mots de passe et se porte fort du respect de ces impératifs par les Utilisateurs eux-mêmes. Afin de préserver le caractère confidentiel des identifiants et mots de passe, le Client et les Utilisateurs sont invités à se déconnecter de l’Espace My Exco à la fin de chaque session de connexion.\n' +
  '\n' +
  '\bold6\bold\bold° CONFIGURATION ET ACCES \bold\boldÀ L’ESPACE\bold\bold MY EXCO\bold\n' +
  'L’Espace My Exco est accessible depuis tout ordinateur fonctionnant sous tout type de système d’exploitation connu et via tout logiciel explorateur d’Internet. Il est également accessible depuis tout appareil connecté à Internet de type téléphone ou tablette dans les mêmes conditions. \n' +
  'S’agissant d’un logiciel SaaS, aucune configuration spécifique n’est indispensable au fonctionnement de l’Espace My Exco.\n' +
  'Toute information relative à l’accès et à l’utilisation des Services est exposée, dans les Conditions générales d’utilisation acceptées par ces derniers lors de leur première connexion sur le compte. \n' +
  '\n' +
  '\bold7\bold\bold° FORMATION\bold\n' +
  'Par le biais de son Compte dirigeant, le Client dispose de la possibilité de souscrire à des formations portant sur les Services complémentaires. Dans les meilleurs délais, une date d’intervention concernant la formation du ou des Utilisateurs quant à la prise en main et au fonctionnement des Services complémentaires souscrits sera déterminée en accord avec le Client.\n' +
  '\n' +
  'La formation sera assurée par téléphone ou via vidéoconférence par un des techniciens de EXCO.\n' +
  '\n' +
  '\bold8\bold\bold° MAINTENANCE - ASSISTANCE TECHNIQUE\bold\n' +
  'Le Client bénéficie des mises à jour et évolutions fonctionnelles de l’Espace My Exco pour les services qu’il a souscrits. Le Client reconnaît que les interventions relatives à ce service peuvent le rendre momentanément indisponible. Dans la mesure du possible, EXCO effectuera, par préférence, les maintenances, le cas échéant et sauf urgence absolue, les soirs et week-ends.\n' +
  '\n' +
  'EXCO se réserve le droit exclusif d’intervenir sur l’Espace My Exco, notamment pour en corriger les erreurs, et à tout moment dans les conditions prévues par l’article « GARANTIE ». \n' +
  '\n' +
  "Le Client bénéficie d’un service d'assistance technique gratuit portant sur les Services, à l’exception de certains Services complémentaires pour lesquels il bénéficiera de l’assistance directe du Partenaire. Le service d’assistance est disponible depuis le Compte de l’Utilisateur par le biais de l’onglet « Aide » prévu à cet effet. Les demandes sont traitées dans l'ordre de leur arrivée et donc sans engagement de délai.\n" +
  'Le Client bénéfice d’une assistance technique directe du Partenaire désigné pour certains Services complémentaires spécifiques et identifiés sur l’Espace My Exco. Le Client devra exclusivement se diriger vers l’assistance technique directe du Partenaire s’agissant de ces Services complémentaires. EXCO ne traitera pas les demandes des Clients portant sur des Services complémentaires exclus de son service d’assistance technique et fera ses meilleurs efforts pour inviter les Clients à se diriger vers l’assistance du service complémentaire compétent. En toute hypothèse, EXCO ne pourra être tenu responsable de tout défaut d’assistance technique du Partenaire, mauvaise ou insatisfaisante exécution de ce dernier, ainsi que toutes les conséquences directement ou indirectement liées à ce défaut d’assistance. \n' +
  '\n' +
  '\bold9\bold\bold° GARANTIE\bold\n' +
  'Le Client déclare connaître les caractéristiques d’Internet et reconnaît ses limites et notamment que :\n' +
  '\bold(i)\bold les transmissions de données sur l’Internet ne bénéficient que d’une fiabilité technique relative, celles-ci circulant sur des réseaux hétérogènes aux caractéristiques et capacités techniques diverses qui sont parfois saturés à certaines périodes de la journée ;\n' +
  '\bold(ii)\bold les données circulant sur l’Internet ne sont pas protégées contre des détournements éventuels et qu’ainsi la communication des identifiants, mots de passe et plus généralement de toutes informations à caractère sensible est effectuée par les Utilisateurs aux risques et périls du Client.\n' +
  '\n' +
  'De même, le Client reconnaît que l’Espace My Exco est susceptible de comporter des bugs et qu’il n’est pas possible de garantir un fonctionnement continu sans erreur. \n' +
  "Les signalements d'anomalie doivent être réalisés auprès de l’assistance technique sur l’Espace My Exco. EXCO procèdera, dans les meilleurs délais après avoir été saisie, au diagnostic de l'anomalie. En cas d'anomalie bloquante, EXCO s'efforce de corriger l'anomalie bloquante dans les meilleurs délais selon le type de panne, et peut, le cas échéant, proposer une solution de contournement. En cas d'anomalie mineure, EXCO propose la correction de l'anomalie mineure dans une nouvelle version de l’Espace My Exco qui sera livrée dans le cadre de la maintenance évolutive comme précédemment décrite.\n" +
  '\n' +
  'EXCO n’est pas tenue par son obligation de garantie dans le cas où le dysfonctionnement de l’Espace My Exco, ou les difficultés rencontrées dans la correction de l’anomalie n’incomberaient pas ou seraient extérieures à EXCO, et notamment dans les situations suivantes :\n' +
  '- refus du Client de collaborer dans la résolution des anomalies et notamment de répondre aux questions et demandes de renseignement ;\n' +
  '- utilisation de l’Espace My Exco d’une manière non conforme à sa destination, à sa documentation ou d’une manière qui contrevienne aux lois et aux règlements ;\n' +
  '- modification non autorisée de l’Espace My Exco par le Client ou un Utilisateur ;\n' +
  '- anomalie imputable à un Prestataire identifié ou identifiable par le Client ou par EXCO dans le cadre de la mise en œuvre et l’exécution des missions réalisées par le Prestataire ; \n' +
  '- manquement du Client ou des Utilisateurs à ses/leurs obligations au titre des Conditions Générales de Vente et/ou des Conditions Générales d’Utilisation ;\n' +
  "- implantation de tous progiciels, logiciels ou système d'exploitation non compatibles avec l’Espace My Exco ;\n" +
  '- défaillance des réseaux de communication électronique ;\n' +
  '- acte volontaire de dégradation, malveillance, sabotage ;\n' +
  '- détérioration due à un cas de force majeure.\n' +
  '\n' +
  '\bold10\bold\bold° RESPONSABILITE – FORCE MAJEURE - GARANTIE\bold\n' +
  'Le Client est responsable du paiement de tout frais ou dépense lié(e) à l’utilisation de l’Espace My Exco, y compris les frais facturés par le fournisseur d’accès pour l’utilisation du réseau et le partage de données. \n' +
  '\n' +
  "Tout événement susceptible d’avoir des conséquences, notamment en matière de responsabilité, doit être porté sans délai par le Client à la connaissance d’EXCO. Toute demande de dommages intérêts ne pourra être introduite que pendant un délai de trois (3) mois commençant à courir le jour de la survenance du sinistre correspondant à la demande. En tout état de cause, EXCO est expressément soumise à une obligation de moyens. En cas de faute prouvée par le Client dans l'exécution de ses prestations par EXCO, celle-ci ne sera tenue que de la réparation des conséquences pécuniaires des dommages directs et prévisibles du fait de l'utilisation de l’Espace My Exco et ses Services. En conséquence, EXCO ne pourra en aucune circonstance encourir de responsabilité au titre des pertes ou dommages indirects ou imprévisibles du Client ou des tiers, ce qui inclut notamment tout gain manqué, perte, inexactitude ou corruption de fichiers ou de données, préjudice commercial, perte de chiffre d'affaires ou de bénéfice, perte de clientèle, perte d'une chance, coût de l'obtention d'un produit, d'un service ou d’une technologie de substitution, en relation ou provenant de l'inexécution ou de l'exécution fautive des prestations. En tout état de cause, le montant de la responsabilité d’EXCO est strictement limité à la somme de trente mille (30.000) euros. EXCO ne saurait, en outre, être tenu responsable de la destruction accidentelle des données par le Client ou un tiers ayant accédé à l’Espace My Exco au moyen des identifiants remis au Client et/ou créés et/ou modifiés par lui ou l'un de ses Utilisateurs ayant accès à l’Espace My Exco. \n" +
  'Le Client est seul responsable des conséquences résultant des fautes, erreurs ou omissions des Utilisateurs et causant un dommage direct. De plus, le Client est seul responsable de l’utilisation et de l’exploitation qu’il fait de l’Espace My Exco ainsi que du contenu qui y est saisi. Le Client est responsable de l’utilisation de l’Espace My Exco qui est faite par lui et les Utilisateurs et du respect des CGU.\n' +
  '\n' +
  'A ce titre, il est notamment responsable, pour lui et ses Utilisateurs : \n' +
  '\bold(i)\bold du contenu produit par lui ou ses Utilisateurs et/ou qu’il valide par le biais de l’Espace My Exco, et notamment du respect des bonnes mœurs dudit contenu ;\n' +
  "\bold(ii)\bold de son adéquation aux lois et aux règlements notamment en matière de réglementation viti-vinicole et de vente de boissons alcooliques, de protection des mineurs, de répression de l'apologie des crimes contre l'humanité, d'incitation à la haine raciale ainsi que de pornographie enfantine, d'incitation à la violence, notamment l'incitation aux violences faites aux femmes, ainsi que des atteintes à la dignité humaine et du respect de la personne humaine et ;\n" +
  '\bold(iii)\bold du respect des droits des tiers notamment en matière de propriété intellectuelle.\n' +
  '\n' +
  'De même, l’Utilisateur garantit notamment que l’Espace My Exco ne sera aucunement utilisé pour renseigner des commentaires constituants : \n' +
  "\bold(i)\bold des messages à caractère pornographique, raciste, xénophobe, révisionniste, faisant l'apologie de crime de guerre, discriminant ou incitant à la haine qu’elle soit à l'encontre d'une personne, d'un groupe de personnes en raison de leur origine, leur genre, leur ethnie, leur croyance ou leur mode de vie ; \n" +
  '\bold(ii)\bold des messages à caractère injurieux, diffamatoire, violent, menaçant, au contenu choquant ou portant atteinte à la dignité humaine ;\n' +
  "\bold(iv)\bold des messages portant atteinte aux droits de la propriété intellectuelle, au droit à l'image et au respect à la vie privée ;\n" +
  '\bold(v)\bold de manière générale, des messages contraires aux lois et règlements en vigueur en France.\n' +
  '\n' +
  'Le Client s’interdit d’utiliser, y compris par l’intermédiaire de ses Utilisateurs, quelque faille ou bug informatique que ce soit pour obtenir des avantages de EXCO dans l’utilisation de l’Espace My Exco. De même, le Client s’engage à avertir immédiatement EXCO à l’adresse \blinkvosdonnees-fso@exco.fr\blink lorsque lui ou un de ses Utilisateurs constate une faille ou une erreur sur l’Espace My Exco.\n' +
  '\n' +
  "Le Client s’engage à ne pas utiliser, y compris par l’intermédiaire de ses Utilisateurs, l’Espace My Exco d'une manière qui puisse la rendre inaccessible, l’endommager ou l’empêcher de fonctionner.\n" +
  '\n' +
  'EXCO ne pourra être tenue responsable en cas d’atteinte à la législation ou aux droits des tiers dans le cadre de cette utilisation et de cette exploitation.\n' +
  '\n' +
  'EXCO ne sera pas responsable des dommages causés au Client et/ou aux Utilisateurs, à des tiers et/ou à leurs équipements du fait de la connexion ou de l’utilisation de l’Espace My Exco.\n' +
  '\n' +
  'Selon les Services souscrits, EXCO est susceptible de faire appel à des Prestataires afin de mettre en œuvre les Services. En cas de défaillance de ces Prestataires, EXCO fera ses meilleurs efforts afin de maintenir les Services. Toutefois, EXCO ne pourra être tenu responsable de toute inexécution ou imparfaite exécution menée par un Prestataire identifié ou identifiable par le Client dans le cadre de la mise en œuvre et l’exécution des missions réalisées par le Prestataire. \n' +
  '\n' +
  'Compte tenu des aléas techniques liés au fonctionnement décentralisé du réseau Internet, EXCO ne fournit aucune garantie de continuité de service ou d’absence d’erreurs de l’Espace My Exco.\n' +
  "En outre, EXCO ne pourra en aucun cas être tenue pour responsable de tout dommage en cas de préjudice causé par une interruption ou une baisse de service de l'opérateur de télécommunications, du fournisseur d'électricité, d’un hébergeur informatique ou en cas de force majeure. \n" +
  "Il en est de même dès lors que le fonctionnement correct de l’Espace My Exco serait empêché à raison de la présence d’un virus, malware, cheval de Troie ou autre programme malveillant installé sur le matériel de l’Utilisateur. Il appartient au Client de prendre toutes les mesures appropriées de nature à protéger ses propres matériels, données et logiciels de la contamination par d'éventuels virus informatiques et autres éléments invasifs et nuisibles au bon fonctionnement de son matériel et de l’Espace My Exco.\n" +
  'EXCO se réserve le droit de suspendre l’accès à l’Espace My Exco en tout ou partie notamment pour procéder à toute opération de correction, de mise à jour ou de maintenance. EXCO ne peut en aucun cas être tenue responsable de tout préjudice et/ou perte qui en résulterait pour le Client. Le Client veillera à effectuer ou faire effectuer une sauvegarde régulière de ses données stockées sur l’Espace My Exco.\n' +
  '\n' +
  '\bold1\bold\bold1\bold\bold° DUREE DU CONTRAT ET RESILIATION\bold\n' +
  'Le contrat est conclu pour une durée indéterminée à compter de la date de souscription des Services par le Client. \n' +
  '\n' +
  'Le Client pourra résilier à tout moment les Services complémentaires souscrits dans le cadre de son Abonnement au sein de l’onglet prévu à cet effet sur son Compte dirigeant sans justifier d’un quelconque motif. En cas de résiliation partielle des Services, les Services maintenus continueront d’être fournis et facturés dans les conditions des présentes.\n' +
  'Le Client devra respecter un délai de préavis de quarante-huit (48) heures avant la fin du mois en cours. La résiliation partielle ou totale des Services complémentaires sera effective au premier jour du mois suivant la résiliation. \n' +
  '\n' +
  'En cas de manquement du Client à ses obligations en vertu des présentes Conditions générales, du respect des Conditions générales d’utilisation, de respect des dispositions afférentes aux droits d’utilisation et aux droits de propriété intellectuelle, à la confidentialité, au paiement à l’échéance convenue et plus généralement pour toute utilisation abusive de l’Espace My Exco, EXCO se réserve le droit de suspendre les Services, laquelle suspension pourra être accompagnée d’une mise en demeure à destination du Client. Si le manquement n’est pas réparé dans un délai de huit (8) jours à compter de la réception de la lettre recommandée avec accusé de réception notifiant le manquement, EXCO pourra résilier immédiatement et de plein droit les Services.\n' +
  '\n' +
  'Par ailleurs, EXCO pourra procéder à la résiliation de l’Abonnement ou de toute ou partie des Services en cas de cessation des Services complémentaires fournis par les Partenaires ou de cessation du déploiement de l’Espace My Exco, sous réserve du respect d’un préavis d’un (1) mois. Cette résiliation sera transmise par tout moyen écrit par EXCO au Client.\n' +
  '\n' +
  'La résiliation interviendra sans préjudice de tout autre droit ou action dont la partie victime de la défaillance pourrait se prévaloir à l’encontre de la partie fautive.\n' +
  '\n' +
  'En cas de résiliation totale ou partielle des Services, le Client reste tenu du paiement de tous les Services antérieurs exécutés par EXCO jusqu’à la date d’effet de la résiliation et plus généralement du paiement de toutes factures dues à EXCO et restées impayées nonobstant la prise d’effet de la résiliation.\n' +
  '\n' +
  'En cas d’ouverture d’une procédure de sauvegarde, de redressement judiciaire ou de liquidation judiciaire du Client, les Services en cours pourront être résiliés par EXCO conformément aux dispositions légales en vigueur.\n' +
  '\n' +
  '\bold1\bold\bold2\bold\bold° IMPREVISION\bold\n' +
  "En cas de survenance d'événements imprévisibles ou exclus par les prévisions qu'ont admis les Parties et qui auraient pour effet de bouleverser le contexte politique, administratif, économique ou technique, imposant à l'une des Parties une charge inéquitable découlant des présentes Conditions générales, celles-ci se consulteront aux fins de trouver en commun les ajustements équitables ou les modifications éventuelles à apporter d'un commun accord aux présentes Conditions générales.\n" +
  '\n' +
  '\bold1\bold\bold3\bold\bold° FORCE MAJEURE\bold\n' +
  'En aucun cas, les Parties ne pourront être tenues pour responsables des manquements ou retards dans l’exécution des Services dus à l’un des cas de force majeure définis par l’article 1218 du Code civil et retenus par la jurisprudence des cours et tribunaux français.\n' +
  '\n' +
  "La survenance d’un cas de force majeure suspend les Services, à l'exception de l'obligation pour le Client de payer les sommes dues jusqu’au jour de la survenance du cas de force majeure.\n" +
  '\n' +
  'Si un cas de force majeure se poursuit au-delà d’une durée de trente (30) jours, la souscription des Services pourra être résiliée immédiatement et de plein droit, sans formalité judiciaire, par l’une ou l’autre des Parties, moyennant l’envoi d’une lettre recommandée avec accusé de réception.\n' +
  '\n' +
  '\bold1\bold\bold4\bold\bold°\bold\bold PROPRIETE INTELLECTUELLE\bold\bold \bold\n' +
  "En souscrivant les Services, EXCO concède au Client un droit d’utilisation personnel, non exclusif, non cessible et non transmissible de l’Espace My Exco. EXCO est et demeure titulaire de tous les droits de propriété intellectuelle et autres droits, y compris les droits d’auteur, les droits voisins, les droits de licence sur les marques, les dessins et modèles, les droits sur les bases de données relatifs à l’Espace My Exco, ainsi plus généralement que de l'infrastructure informatique logicielle et matérielle mise en œuvre ou développée au sein de l’Espace My Exco pour le monde entier, et ne peuvent être reproduits, utilisés ou représentés sans l’autorisation expresse de EXCO sous peine de poursuites judiciaires. L’accès à l’Espace My Exco ne confère au Client et/ou à l’Utilisateur aucun droit sur le contenu ou la présentation de l’Espace My Exco, lesquels restent la propriété exclusive d’EXCO. En conséquence, le Client s'interdit de reproduire tout élément de l’Espace My Exco, toute documentation la concernant, par quelque moyen que ce soit, sous quelque forme que ce soit et sur quelque support que ce soit.\n" +
  '\n' +
  '\bold1\bold\bold5\bold\bold° \bold\boldPROTECTION DES DONNEES PERSONNELLES\bold\n' +
  '\boldTraitement des données à caractère personnel du Client\bold\bold et/ou de ses préposés\bold\n' +
  'Le Client est informé qu’EXCO est amenée à traiter des données à caractère personnel concernant notamment ses dirigeants, associés et membres de son personnel dans le cadre de la mise à disposition de l’Espace My Exco. Pour plus d’informations sur les traitements de données à caractère personnel des personnes concernées précitées, les conditions générales d’utilisation de l’Espace My Exco peuvent être consultées. \n' +
  '\n' +
  '\boldTraitement de données à caractère personnel \bold\boldaux fins de réalisation des Services pour le Client\bold\n' +
  'Dans le cadre des Services fournis au Client, EXCO pourra être amené à collecter auprès du Client et traiter les données à caractère personnel notamment du personnel du Client, quel que soit son statut, de ses partenaires commerciaux et de ses clients. Selon les Services souscrits par le Client, les traitements de données à caractère personnel seront effectués comme suit :\n' +
  '\bold(i)\bold Lorsque l’Espace My Exco est utilisé aux fins de communication des informations nécessaires à la réalisation de la Mission, telle que définie par la lettre de mission signée entre EXCO et le Client, le statut des Parties au regard du règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après, « RGPD ») est déterminé conformément aux Conditions générales afférentes à la lettre de mission et le cas échéant, aux dispositions particulières de la lettre de mission qui prévalent en cas de contradiction. Pour plus d’informations sur la nature des traitements effectués dans ce contexte et le statut afférents des Parties, le Client peut consulter la lettre de mission signée avec EXCO et les Conditions générales attachées.\n' +
  '\bold(ii)\bold Lorsque les Services souscrits correspondent à des prestations complémentaires de la mission principale fournie par EXCO, en ce compris notamment lorsqu’EXCO traite, stocke, héberge et met à disposition des solutions pour la gestion et le paiement de ses factures et de la comptabilité du Client, EXCO traite des données à caractère personnel pour le compte du Client et est qualifié de sous-traitant au sens du RGPD. A cet égard, les traitements sont effectués conformément à l’Annexe RGPD attachées aux présentes Conditions générales. \n' +
  '\n' +
  '\bold1\bold\bold6\bold\bold° \bold\boldREFERENCE COMMERCIALE\bold\n' +
  'Sauf stipulation expresse contraire, le Client autorise EXCO à le citer à titre de référence dans l’ensemble de sa documentation commerciale, ainsi que sur son site Internet.\n' +
  '\n' +
  '\bold1\bold\bold7\bold\bold° \bold\boldINDIVI\bold\boldSI\bold\boldBILITE\bold\n' +
  'La souscription et les engagements pris dans le cadre des présentes Conditions générales sont indivisibles de la Mission fournie par EXCO.\n' +
  '\n' +
  'Par conséquent, le terme de la Mission d’EXCO, pour quelque raison que ce soit, entrainera la caducité de l’Abonnement, et ce dans l’ensemble de ses dispositions, sans préjudice de toute indemnisation éventuelle qui pourrait être accordée au bénéfice de l’une ou l’autre des Parties.\n' +
  '\n' +
  'Toutefois, la résiliation de l’Abonnement dans les conditions de l’article 11 précité, n’entrainera pas de façon systématique, à l’inverse, la caducité de la Mission d’EXCO.\n' +
  '\n' +
  '\bold1\bold\bold8\bold\bold° MODIFICATION\bold\n' +
  'EXCO peut modifier à tout moment les présentes Conditions Générales, notamment pour se conformer à une disposition légale. Dans un tel cas, pour les Services souscrits en cours, sauf obligation imposant une mise en œuvre immédiate, la version applicable sera celle en vigueur. \n' +
  '\n' +
  '\bold1\bold\bold9\bold\bold° \bold\boldLANGUE DU CONTRAT – DROIT APPLICABLE\bold\bold DIFFERENDS\bold\n' +
  'Les présentes Conditions Générales et les opérations qui en découlent sont régies par le droit français. Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige. Dans l’hypothèse où un litige surviendrait entre les Parties, ces dernières s’engagent à rechercher une solution amiable, prenant en compte les intérêts de chacune d’elles avant d’engager toute action judiciaire.\n' +
  'TOUT DIFFEREND RELATIF A LA VALIDITE, L’INTERPRETATION ET/OU A L’EXECUTION DES PRESENTES SERONT DE LA COMPETENCE EXCLUSIVE DES TRIBUNAUX DU RESSORT DE LA COUR D’APPEL DE TOULOUSE, MEME DANS LE CAS D’UN APPEL EN GARANTIE, D’UNE PLURALITE DE DEFENDEURS OU D’UNE PROCEDURE EN REFERE.\n' +
  '\n' +
  '\boldANNEXE RELATIVE AUX TRAITEMENTS DE DONNEES A CARACTERE PERSONNEL\bold\n' +
  '\n' +
  '\bold1°OBJET\bold\n' +
  '\n' +
  'Dans le cadre des présentes Conditions Générales, EXCO fournit des prestations de mise à disposition de l’Espace My Exco. Dans ce cadre, EXCO peut être amenée à traiter les données à caractère personnel notamment des salariés, fournisseurs, prestataires et/ou des clients du Client (ci-après les « \boldDonnées Confiées\bold »). \n' +
  'Dans ce contexte, le Client est le responsable de tout traitement des Données Confiées et EXCO est le sous-traitant au sens du RGPD. La présente annexe a pour objet de définir les conditions dans lesquelles EXCO s’engage à effectuer pour le compte du Client les opérations de traitement des Données Confiées nécessaires à l’exécution des Services. \n' +
  'A ce titre, chacune des Parties s’engage à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le RGPD.\n' +
  '\n' +
  '\bold2° DESCRIPTION DU TRAITEMENT FAISANT L’OBJET DE LA SOUS-TRAITANCE\bold\n' +
  'EXCO est autorisée à traiter pour le compte du Client les Données Confiées nécessaires pour fournir les services de mise à disposition de l’Espace My Exco et les Services complémentaires souscrits associés dans les conditions suivantes :\n' +
  '\bold(i)\bold Catégorie de personnes concernées par les traitements de données à caractère personnel : les personnes physiques salariés, fournisseurs, prestataires et/ou clients du Client, qu’il s’agisse de ces personnes elles-mêmes ou de leurs préposés (ci-après les « Personnes Concernées ») ;\n' +
  '\bold(ii)\bold Types de données concernées par le traitement : noms, prénoms, adresse courriel professionnelle, fonction au sein de la société, le cas échéant informations relatives à la paie des salariés, coordonnées bancaires ; \n' +
  '\bold(iii)\bold Nature des opérations réalisées sur les Données Confiées : hébergement des factures, fonctionnalités de gestion des factures, fonctionnalités de tenue et de consultation de la comptabilité en ligne ;\n' +
  '\bold(iv)\bold Finalités du traitement : fourniture d’une plateforme de gestion des factures et de paiement en ligne, de tenue de la comptabilité et de réalisation des bulletins de paie et déclarations de charges sociales des salariés du Client. \n' +
  '\n' +
  '\bold3° OBLIGATIONS DES PARTIES\bold\n' +
  '\bold3.1 Généralités\bold\n' +
  "EXCO s'engage à :\n" +
  '\bold(i)\bold Traiter les Données Confiées uniquement pour les finalités détaillées à l’article 2(iv) de la présente Annexe ;\n' +
  '\bold(ii)\bold Ne traiter les Données Confiées que conformément aux instructions documentées du Client figurant au sein des Conditions Générales, y compris en ce qui concerne les transferts de données à caractère personnel vers un pays tiers ou auprès d’une organisation internationale (à l’exception du cas où EXCO est tenue de procéder à un traitement des Données Confiées en vertu du droit de l’Union européenne ou d’un État membre) ; \n' +
  "\bold(iii)\bold Informer le Client, avant que le traitement ne soit mis en place, lorsqu’il est tenu de procéder à un transfert de données vers un pays tiers ou à une organisation internationale, en vertu du droit de l’Union européenne ou du droit de l’État membre auquel il est soumis, sauf si le droit concerné interdit une telle information pour des motifs importants d'intérêt public ;\n" +
  '\bold(iv)\bold Informer immédiatement le Client, s’il considère qu’une instruction de ce dernier constitue une violation du RGPD ou de toute autre disposition du droit de l’Union européenne ou du droit des États membres relative à la protection des données ; \n' +
  '\bold(v)\bold Veiller à ce que les personnes autorisées à traiter les données à caractère personnel en vertu du présent Contrat, s’engagent à respecter la confidentialité des Données Confiées ou soient soumises à une obligation légale appropriée de confidentialité et reçoivent la formation nécessaire en matière de protection des données à caractère personnel.\n' +
  '\n' +
  'Le Client s’engage à :\n' +
  '\bold(i)\bold Respecter toutes les obligations spécifiques lui incombant en sa qualité de responsable du traitement des Données Confiées, au titre de la réglementation application et notamment du RGPD ;\n' +
  '\bold(ii)\bold Documenter par écrit toute instruction concernant le traitement des données par EXCO ;\n' +
  '\bold(iii)\bold Veiller, au préalable et pendant toute la durée du traitement, au respect des obligations prévues par le RGPD sur la protection des données de la part de EXCO.\n' +
  '\n' +
  '\bold3.2\bold \boldSous-traitance ultérieure\bold\n' +
  'Afin de réaliser les traitements des Données Confiées nécessaires pour fournir au Client les Services, EXCO peut avoir recours à des sous-traitants ultérieurs, lesquels mèneront des activités de traitement spécifiques pour le compte du Client. Le Client reconnaît ce fait et donne son accord général au recours à des sous-traitants ultérieurs, dans le respect du présent article 3.2.\n' +
  'A ce titre, tout sous-traitant ultérieur sera tenu de respecter des obligations en matière de protection des données au moins équivalentes à celles prévues dans le présent Contrat.  A cet effet, ces obligations seront fixées au sein d’un contrat ou tout autre acte juridique entre EXCO et le sous-traitant ultérieur. Dans ce cadre, EXCO s’assurera que le sous-traitant ultérieur présente des garanties similaires à celles qui sont inscrites dans les présentes Conditions générales et en tout état de cause des garanties suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles appropriées, de manière que le traitement réponde aux exigences du RGPD. \n' +
  'En cas de modification prévue des sous-traitants ultérieurs, soit en raison du remplacement d’un sous-traitant ultérieur, soit en raison du recrutement d’un nouveau sous-traitant ultérieur, EXCO en informera le Client au préalable et le Client aura la possibilité d’émettre des objections à l’encontre des changements envisagés.\n' +
  "Lorsque le sous-traitant ultérieur ne remplit pas ses obligations en matière de protection des données, EXCO demeure pleinement responsable devant le Client de l'exécution par le sous-traitant ultérieur desdites obligations.\n" +
  '\n' +
  '\bold3.3 Base légale et collecte des Données Confiées\bold\n' +
  'En tant que responsable du traitement, il appartient au Client de s’assurer qu’il a une base légale valable au sens de la règlementation applicable permettant le traitement des Données Confiées et que toute Donnée Confiée qu’il communique à EXCO a été collectée de façon licite et loyale.\n' +
  '\n' +
  '\bold3.4 Droit d’information des \bold\boldP\bold\boldersonnes \bold\boldC\bold\boldoncernées\bold\n' +
  'Il appartient au Client, en tant que responsable du traitement, de fournir aux Personnes Concernées par les traitements une information complète en vertu de la règlementation applicable, et notamment, selon le cas, des articles 13 et/ou 14 du RGPD.\n' +
  '\n' +
  '\bold3.5 Exercice des droits des personnes\bold\n' +
  "Le Client est responsable de la gestion de toutes demandes des Personnes Concernées relatives à l'exercice de leurs droits en vertu de la règlementation applicable en matière de protection des données, notamment le droit d’accès, le droit de rectification, le droit d’effacement et d’opposition, le droit à la limitation du traitement, le droit à la portabilité des données et le droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).\n" +
  '\n' +
  'Dans toute la mesure du possible, raisonnable et proportionné, et dans la limite de ce qui le concerne, EXCO apportera son aide au Client afin que celui-ci puisse s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des Personnes Concernées. L’assistance fournie par EXCO dans ce cadre sera aux frais du Client.\n' +
  '\n' +
  '\bold3.6 Notification des violations de données à caractère personnel\bold\n' +
  'EXCO notifie au Client toute violation de données à caractère personnel dans les meilleurs délais après en avoir pris connaissance et par le moyen suivant : courriel au représentant légal, à l’adresse indiquée par le Client spécifiquement à cet effet. EXCO fournira une assistance raisonnable afin de permettre au Client de respecter ses éventuelles obligations de notification de l’autorité de contrôle compétente et/ou de communication à la personne concernée par rapport à cette violation. L’assistance fournie par EXCO dans ce cadre sera aux frais du Client.\n' +
  '\n' +
  '\bold3.7 Aide de EXCO dans le cadre du respect par le Client de ses obligations\bold\n' +
  'Compte-tenu de la nature du traitement et des informations à sa disposition, EXCO apportera son aide raisonnable au Client pour la réalisation d’analyses d’impact relatives à la protection des données et de la consultation préalable de l’autorité de contrôle. L’assistance fournie par EXCO dans ce cadre sera aux frais du Client.\n' +
  '\n' +
  '\bold3.8 Mesures de sécurité\bold\n' +
  'EXCO s’engage, dans les conditions de l’article 32 du RGPD, à prendre toutes les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté aux risques pour les droits et libertés des personnes concernées, ainsi qu’à aider le Client à satisfaire à ses propres obligations à cet égard en regard du traitement des Données Confiées en vertu du Contrat.\n' +
  '\n' +
  '\bold3.9 Durée de conservation et sort des Données Confiées\bold\n' +
  "Sauf instruction documentée contraire de la part du Client, au terme des Services, EXCO s’engage à supprimer toutes les Données Confiées dans les 90 jours suivant la fin des Services complémentaires (à moins que le droit de l'Union européenne ou le droit applicable d’un État membre n'exige la conservation desdites données). Sur demande écrite du Client, EXCO renverra au Client, aux frais de ce dernier, toutes les Données Confiées préalablement à leur suppression des systèmes de EXCO.\n" +
  '\n' +
  '\bold3.10 Contrôle du respect des obligations de EXCO\bold\n' +
  "EXCO met à la disposition du Client toutes les informations nécessaires pour démontrer le respect de ses obligations prévues dans les présentes Conditions générale et permettra à ces fins la réalisation d'audits dans les conditions suivantes :\n" +
  '\bold(i)\bold Le Client ne pourra pas mener plus d’un audit par période de douze (12) mois.\n' +
  '\bold(ii)\bold Le Client supportera seul tous les coûts de l’audit.\n' +
  '\bold(iii)\bold L’audit doit être effectué pendant les heures ouvrées et ne doit pas dépasser 2 jours ouvrés.\n' +
  '\bold(iv)\bold L’audit pourra être réalisé par le Client directement ou par l’intermédiaire d’un tiers indépendant. Dans le deuxième cas, la sélection des auditeurs externes sera convenue entre les Parties. Les auditeurs doivent être titulaires d’une assurance responsabilité professionnelle garantissant l’audit, leur responsabilité pouvant être recherchée par EXCO en cas de dommage causé à ce dernier.\n' +
  '\bold(v)\bold Le Client notifiera EXCO avec un préavis écrit de trente (30) jours minimum, afin que les Parties puissent convenir mutuellement d’une date d’audit.\n' +
  '\bold(vi)\bold Le Client doit s’assurer que le déroulement de l’audit ne perturbe pas l’exécution par EXCO de ses obligations au titre des Conditions Générales, ni l’activité de EXCO en général.\n' +
  '\bold(vii)\bold Au cas où l’audit entrainerait des interruptions de tout ou partie des prestations de EXCO, les Parties conviennent que ces interruptions ne seront pas prises en compte dans l’évaluation du respect des engagements de qualité de service de EXCO et que EXCO ne pourra en être tenu responsable.\n' +
  '\bold(viii)\bold Tous les documents, informations ou données, quel qu’en soit le support, confiés par EXCO aux auditeurs et/ou au Client pendant l’audit, ont un caractère confidentiel et devront être traités comme tels.\n' +
  '\bold(ix)\bold EXCO s’engage à contribuer aux audits, aux frais du Client. \n' +
  '\bold(x)\bold Le Client est responsable de toute perte ou dommage occasionné à EXCO en raison de l’audit, que l’audit soit effectué par le Client directement ou par des auditeurs externes.\n' +
  '\n' +
  '\bold3.1\bold\bold1\bold\bold Responsabilité\bold\n' +
  'En cas de manquement à ses obligations en vertu de la présente Annexe, la responsabilité d’EXCO sera encadrée conformément aux dispositions des Conditions Générales. \n' +
  '\n';
