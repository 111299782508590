import React from 'react';
import { Icon } from 'antd';

const CalendarSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.33337 2.66675V4.00008C2.33337 4.3537 2.47385 4.69284 2.7239 4.94289C2.97395 5.19294 3.31309 5.33341 3.66671 5.33341C4.02033 5.33341 4.35947 5.19294 4.60952 4.94289C4.85957 4.69284 5.00004 4.3537 5.00004 4.00008V2.66675H9.00004V4.00008C9.00004 4.3537 9.14052 4.69284 9.39057 4.94289C9.64061 5.19294 9.97975 5.33341 10.3334 5.33341C10.687 5.33341 11.0261 5.19294 11.2762 4.94289C11.5262 4.69284 11.6667 4.3537 11.6667 4.00008V2.66675C12.1971 2.66675 12.7058 2.87746 13.0809 3.25253C13.456 3.62761 13.6667 4.13631 13.6667 4.66675V6.00008H0.333374V4.66675C0.333374 4.13631 0.544088 3.62761 0.91916 3.25253C1.29423 2.87746 1.80294 2.66675 2.33337 2.66675ZM13.6667 7.33341V11.3334C13.6667 11.8638 13.456 12.3726 13.0809 12.7476C12.7058 13.1227 12.1971 13.3334 11.6667 13.3334H2.33337C1.80294 13.3334 1.29423 13.1227 0.91916 12.7476C0.544088 12.3726 0.333374 11.8638 0.333374 11.3334L0.333374 7.33341H13.6667ZM10.3334 0.666748C10.5102 0.666748 10.6798 0.736986 10.8048 0.86201C10.9298 0.987034 11 1.1566 11 1.33341V4.00008C11 4.17689 10.9298 4.34646 10.8048 4.47149C10.6798 4.59651 10.5102 4.66675 10.3334 4.66675C10.1566 4.66675 9.98699 4.59651 9.86197 4.47149C9.73695 4.34646 9.66671 4.17689 9.66671 4.00008V1.33341C9.66671 1.1566 9.73695 0.987034 9.86197 0.86201C9.98699 0.736986 10.1566 0.666748 10.3334 0.666748V0.666748ZM3.66671 0.666748C3.84352 0.666748 4.01309 0.736986 4.13811 0.86201C4.26314 0.987034 4.33337 1.1566 4.33337 1.33341V4.00008C4.33337 4.17689 4.26314 4.34646 4.13811 4.47149C4.01309 4.59651 3.84352 4.66675 3.66671 4.66675C3.4899 4.66675 3.32033 4.59651 3.1953 4.47149C3.07028 4.34646 3.00004 4.17689 3.00004 4.00008V1.33341C3.00004 1.1566 3.07028 0.987034 3.1953 0.86201C3.32033 0.736986 3.4899 0.666748 3.66671 0.666748V0.666748Z" />
  </svg>
);

export const CalendarMiniIcon = (props) => (
  <Icon component={CalendarSvg} {...props} />
);
