import React from 'react';
import checkMark from 'assets/icons/checkmark-icon.svg';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Styles from './ServiceCardDescription.module.css';
import { EXCO_SERVICES_FUNCTIONAL_ITEM } from '../../../const/excoServices';

const propTypes = {
  functionalArray: PropTypes.arrayOf(EXCO_SERVICES_FUNCTIONAL_ITEM),
  isMultiLink: PropTypes.bool,
  userModules: PropTypes.array,
};

/**
 * This component is block with description lines of ServiceCard Component
 * Description line can be either with link attached or not
 * @param functionalArray is an array of objects with following structure
 * {
 *      ID:number,
 *      TEXT:string - text of the description,
 *      LINK:string - link url(whether link will be attached depends of isMultiLink prop)
 *      MODULES_FOR_ACCESS - array of APP_MODULES user has to possess to have access for current link
 * }
 * @param isMultiLink : boolean - decides whether descriptions will be with link below or not
 * @param userModules: array of APP_MODULES - array of modules that user currently has
 */

export const ServiceCardDescription = ({
  functionalArray,
  isMultiLink,
  userModules,
}) => (
  <div
    className={clsx(Styles.functionalWrapper, {
      [Styles.multiLinkFunctionalWrapper]: isMultiLink,
    })}
  >
    {functionalArray.map((functionalItem) => {
      const { ID, TEXT, LINK } = functionalItem;
      return isMultiLink &&
        !functionalItem.MODULES_FOR_ACCESS.some((module) =>
          userModules.includes(module)
        ) ? null : (
        <div key={ID} className={Styles.functionalItem}>
          <img src={checkMark} alt="check-mark" />
          <div className={Styles.LinkAndTextWrapper}>
            <span>{TEXT}</span>
            {isMultiLink && <Link to={LINK.HREF}>{LINK.NAME}</Link>}
          </div>
        </div>
      );
    })}
  </div>
);

ServiceCardDescription.propTypes = propTypes;
