import { createSelector } from 'reselect';
import {
  getBranchesAddresses,
  getBranchesOptions,
} from '../../CompanySettings/utils';
import {
  getIsBetween,
  mapEmployerToTable,
  formatStringToFirstLetterCapital,
} from '../utils';

export const selectEmployees = (state) =>
  state.getIn(['employeesReducer', 'employeesList']);

const selectFiltersObject = (state) =>
  state.getIn(['employeesReducer', 'filters']);

export const selectSearchTerm = (state) =>
  state.getIn(['employeesReducer', 'searchTerm']);

export const selectCompanyBranches = (state) =>
  state.getIn(['employeesReducer', 'companyBranches']);

const selectDateRangeObject = (state) =>
  state.getIn(['employeesReducer', 'dateRange']);

export const selectEmployeesStatus = (state) =>
  state.getIn(['employeesReducer', 'status']);

export const selectCompanyBranchesOptions = createSelector(
  selectCompanyBranches,
  (branches) => {
    if (branches && branches.length) {
      return getBranchesOptions(branches);
    }

    return [];
  }
);

const selectBranchesAddresses = createSelector(
  selectCompanyBranches,
  (branches) => {
    if (branches && branches.length) {
      return getBranchesAddresses(branches);
    }

    return {};
  }
);

export const selectFilters = createSelector(selectFiltersObject, (filters) => {
  // TODO: need to fix it
  if (filters.toJS) {
    return filters.toJS();
  }
  return filters;
});

export const selectDateRange = createSelector(
  selectDateRangeObject,
  (dateRangeObject) => {
    // TODO: need to fix it
    if (dateRangeObject.toJS) {
      return dateRangeObject.toJS();
    }
    return dateRangeObject;
  }
);

export const selectIsFilterChosen = createSelector(
  selectFilters,
  selectDateRange,
  selectSearchTerm,
  (filters, dateRange, searchTerm) => {
    const isFilters =
      Object.values(filters).filter((value) => value !== '').length > 0;
    return isFilters || searchTerm.length > 0;
  }
);

const selectEmployeesInsideSelectedDateRange = createSelector(
  [selectEmployees, selectDateRange],
  (employees, dateRange) =>
    employees.filter((item) =>
      getIsBetween(item.startdate, item.enddate, dateRange)
    )
);

export const selectNatureEmploymentOptions = createSelector(
  selectEmployeesInsideSelectedDateRange,
  (employees) => {
    const natureEmploymentsArray = [...employees].map(({ employeeposition }) =>
      formatStringToFirstLetterCapital(employeeposition)
    );
    const uniqueNatureEmployments = Array.from(new Set(natureEmploymentsArray));
    return uniqueNatureEmployments.map((item) => ({
      value: item,
      label: item,
    }));
  }
);

export const selectClassificationOptions = createSelector(
  selectEmployeesInsideSelectedDateRange,
  (employees) => {
    const classificationsArray = [...employees].map(
      ({ classification }) => classification
    );
    const uniqueClassifications = Array.from(new Set(classificationsArray));
    return uniqueClassifications.map((item) => ({
      value: item,
      label: item,
    }));
  }
);

export const selectContractOptions = createSelector(
  selectEmployeesInsideSelectedDateRange,
  (employees) => {
    const contractsArray = [...employees]
      .map(({ typecontract }) => typecontract)
      .filter((contract) => !!contract);
    const uniqueContractsArray = Array.from(new Set(contractsArray));
    return uniqueContractsArray.map((item) => ({
      value: item,
      label: item,
    }));
  }
);

export const selectFilteredEmployees = createSelector(
  selectEmployees,
  selectFilters,
  selectSearchTerm,
  selectDateRange,
  selectBranchesAddresses,
  (employees, filters, searchTerm, dateRange, branchAddresses) => {
    // TODO: fix when mapping appears
    const employeesKeysMap = {
      establishments: 'siret',
      natureEmployments: 'employeeposition',
      classifications: 'classification',
      contracts: 'typecontract',
    };

    const existedFilters = Object.keys(filters)
      .filter((key) => filters[key] !== '')
      .reduce((list, key) => {
        const serverKey = employeesKeysMap[key];
        // eslint-disable-next-line no-param-reassign
        list[serverKey] = filters[key];
        return list;
      }, {});

    const filteredEmployeesList = employees.filter((item) => {
      const isBetween = getIsBetween(item.startdate, item.enddate, dateRange);
      if (!isBetween) {
        return false;
      }

      const compareItem = (key) =>
        key === 'employeeposition'
          ? item[key].toUpperCase() !== existedFilters[key].toUpperCase()
          : item[key] !== existedFilters[key];

      // I was trying to rewrite it using Object.keys but it
      // seemed less obvious and human-readable

      // eslint-disable-next-line no-restricted-syntax
      for (const key in existedFilters) {
        if (item[key] === undefined || compareItem(key)) return false;
      }

      return true;
    });

    const mappedEmployees = mapEmployerToTable(
      [...filteredEmployeesList],
      branchAddresses
    );
    return mappedEmployees.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
);
